import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { store } from "../../store";
import { Unsubscribe } from "redux";

interface IState {
    isLoading: boolean;
}

export class LoadingCoverIcon extends React.Component<{}, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(() => {
            const reducer = store.getState().loadingReducer;

            this.setState({
                isLoading:
                    reducer.isPrepareRenderData || reducer.isNetworkRequest,
            });
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <>
                {isLoading && (
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 9999999999,
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(146, 146, 146, 0.0)",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 9999999999,
                                top: "50%",
                                left: "50%",
                            }}
                        >
                            <Spinner
                                size={SpinnerSize.large}
                                label="loading..."
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}
