import React from "react";
import { Modal } from "@fluentui/react";
import {
    ColumnValueType,
    ImageVisualizer,
    MetricsType,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import {
    IMergedMetrics,
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations } from "../../Controls/TableHeader";
import {
    OcrBarcodePage,
    OcrMetrics,
    OcrPolygons,
} from "../../Pages/Scenarios/OcrBarcodePage";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import _ from "lodash";
import { FullScreen } from "../Common/FullScreen";

// prettier-ignore
const BARCODE_IMAGE_COLUMNS: TableColumn[] = [
    { key: "filename",           name: "Filename",                      fieldName: "filename",           valueType: ColumnValueType.String, minWidth: 300, maxWidth: 430, isResizable: true, distinctStr: true },
    { key: "category",           name: "Category",                      fieldName: "category",           valueType: ColumnValueType.String, minWidth: 100, maxWidth: 120, isResizable: true, distinctStr: true, filterable: true },
    { key: "correct",            name: "Correct",                       fieldName: "correct",            valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 120, isResizable: true, maxDecimalPlaces: 3 },
    { key: "deletion",           name: "Deletion Error",                fieldName: "deletion",           valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 120, isResizable: true, maxDecimalPlaces: 3 },
    { key: "insertion",          name: "Insertion Error",               fieldName: "insertion",          valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 120, isResizable: true, maxDecimalPlaces: 3 },
    { key: "substitute_format",  name: "Substitution Error (Type)",     fieldName: "substitute_format",  valueType: ColumnValueType.Number, minWidth: 120, maxWidth: 180, isResizable: true, maxDecimalPlaces: 3 },
    { key: "substitute_content", name: "Substitution Error (Content)",  fieldName: "substitute_content", valueType: ColumnValueType.Number, minWidth: 120, maxWidth: 180, isResizable: true, maxDecimalPlaces: 3 },
];

interface imageRaw {
    filename: string;
    category: string;
    correct: number;
    deletion: number;
    insertion: number;
    substitute_format: number;
    substitute_content: number;
    imageUrl?: string;
}

interface IProps extends IMetricProps {
    isReport: boolean | undefined;
    linkData?: {
        toSelectLanguage: string;
        toSelectCategory: string;
    };
}

interface IState extends IMetricState<imageRaw> {
    clickNumber?: number;
    filterDict?: Map<string | number, any[]>;
    imageVisItems?: any[];
    linkData?: any;
}

export class OcrBarcodeByImageView extends MetricsView<
    IProps,
    IState,
    imageRaw
> {
    constructor(props: IProps) {
        super(props);
        this._renderTableHeader = this._renderTableHeader.bind(this);

        let selectLanguage = props.linkData?.toSelectLanguage;
        const selectCategory = props.linkData?.toSelectCategory;

        const filterMap = new Map<string | number, any[]>();
        const languages = this.getLanguageList(
            store.getState().settingReducer.matchDatasetVersion
        );

        if (selectLanguage && languages.includes(selectLanguage)) {
            selectCategory && filterMap.set("category", [selectCategory]);
        } else {
            selectLanguage = languages[0];
        }

        this.state = {
            evalData: {},
            filterDict: filterMap,
            selectLanguage: selectLanguage,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrBarcode}_${Typename.GeneralMetrics}_ByImage`,
                columns: BARCODE_IMAGE_COLUMNS,
            })
        );
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.setState(
                {
                    selectLanguage: this.getLanguageList(
                        this.state.matchDatasetVersion
                    )[0],
                },
                () => {
                    this.onEvaluationRecordChanged();
                }
            );
        }
    }

    render(): React.ReactNode {
        return this._renderImageTable();
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<imageRaw>> {
        return recordDetail
            .fetchMetricsWithCamelCasing<IMetrics<imageRaw>>(metricName)
            .then((metrics) => {
                Object.entries(metrics).forEach(([key, _]) => {
                    const imageRawMetric = metrics[key];
                    imageRawMetric.imageUrl = recordDetail.dataset.getImageUrl(
                        `${imageRawMetric.category}/${imageRawMetric.filename}.jpg`
                    );
                });
                return metrics;
            });
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language =
                selectLanguage &&
                languageList.find((l) => l.includes(selectLanguage))
                    ? selectLanguage
                    : languageList[0];
            this._onOptionsChanged({
                selectLanguage: language,
            });
        } else {
            this.setState({ evalData: {} });
        }
    }

    showEvaluationResult(
        recordDetails: RecordDetail[],
        metricName: string,
        onSuccess?: () => void
    ) {
        Promise.all(
            recordDetails.map((detail) =>
                this.queryEvaluationResult(detail, metricName)
            )
        )
            .then((respArr) => {
                const respLen = respArr.length;
                const imgMap = new Map<string, IMetrics<imageRaw>[]>();
                respArr.forEach((resp: any, rIndex) => {
                    const imgRawArr = resp as IMetrics<imageRaw>[];
                    imgRawArr.forEach((imgRaw) => {
                        const key = `${imgRaw.filename}|${imgRaw.category}`;
                        const valArr = imgMap.has(key)
                            ? imgMap.get(key)!
                            : Array.from({ length: respLen }, () => {
                                  return {
                                      filename: imgRaw.filename,
                                      category: imgRaw.category,
                                  };
                              });

                        valArr[rIndex] = imgRaw;
                        imgMap.set(key, valArr);
                    });
                });

                const evalData: any = {};
                imgMap.forEach((v, k) => {
                    evalData[k] = v;
                });

                this.setState(
                    {
                        evalData: evalData as IMergedMetrics<imageRaw>,
                        entityList: [],
                    },
                    () => onSuccess && onSuccess()
                );
            })
            .catch((_err) =>
                this.setState({
                    evalData: {},
                    entityList: [],
                })
            );
    }

    private _renderImageTable(): React.ReactNode {
        const { isDarkTheme, records } = this.props;
        const {
            evalData,
            filterDict,
            imageVisItems,
            selectedColumns,
            selectImageId,
        } = this.state;

        let clickNumber: number = 0;

        const experiments = records.map((record) => record.name);
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );

        const columns: TableColumn[] = BARCODE_IMAGE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const categoryMap = new Map<string, string>();
        const dataMap = new Map<string, imageRaw[]>();
        const dataArr = Object.values(evalData) as imageRaw[][];
        if (dataArr && dataArr.length > 0) {
            dataArr.forEach((imageRawArr) => {
                const keyImageRaw = imageRawArr.find(
                    (imgRaw) => imgRaw?.filename
                );
                if (keyImageRaw) {
                    if (!dataMap.has(keyImageRaw.filename)) {
                        dataMap.set(keyImageRaw.filename, imageRawArr);
                    }

                    if (!categoryMap.has(keyImageRaw.filename)) {
                        categoryMap.set(
                            keyImageRaw.filename,
                            keyImageRaw.category
                        );
                    }
                }
            });
        }

        return (
            <>
                <FullScreen>
                    <TableList<imageRaw>
                        key={this.state.selectLanguage}
                        evalDataCount={records.length}
                        evalData={dataMap}
                        columns={columns}
                        initialFilterDict={filterDict}
                        isDarkTheme={isDarkTheme}
                        downloadTableTitle={this.state.selectLanguage}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageKey] = item;
                            this.setState({
                                selectImageId: imageKey as string,
                                clickNumber: clickNumber,
                            });
                        }}
                        getDisplayEvalData={(displayItems: any) => {
                            this.setState({
                                imageVisItems: displayItems,
                            });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        disableVirtualize
                    />
                </FullScreen>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    <ImageVisualizer
                        experiments={experiments}
                        fileId={selectImageId}
                        evalList={imageItemsForVis}
                        onLoadVisualizer={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                if (category) {
                                    return OcrBarcodePage.fetchImageUrl(
                                        dataMap,
                                        category,
                                        imageId
                                    );
                                }
                            }

                            return "";
                        }}
                        setImageMark={this.setImageMark}
                        onDismiss={(imageId: string) => {
                            this.setState({
                                selectImageId: imageId,
                            });
                        }}
                        clickNumber={this.state.clickNumber}
                        metricsType={MetricsType.barcodeMetrics}
                        onRequestPolygons={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                return this._requestPolygons(imageId, category);
                            } else {
                                return Promise.resolve([]);
                            }
                        }}
                        onRequestMetrics={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                return this._requestMetrics(imageId, category);
                            } else {
                                return Promise.resolve([]);
                            }
                        }}
                    />
                </Modal>
            </>
        );
    }

    private _requestPolygons(
        imageId?: string,
        category?: string
    ): Promise<OcrPolygons[]> {
        const { selectImageId, selectLanguage, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        const imgId = imageId ?? selectImageId;
        return OcrBarcodePage.fetchPolygons(details, imgId, category);
    }

    private _requestMetrics(
        imageId?: string,
        category?: string
    ): Promise<OcrMetrics[]> {
        const { selectImageId, selectLanguage, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        const imgId = imageId ?? selectImageId;
        return OcrBarcodePage.fetchMetrics(details, imgId, category);
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, matchDatasetVersion } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged(
                        {
                            selectLanguage: language.text,
                        },
                        true
                    );
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        clearFilter: boolean = false
    ) {
        const { filterDict } = this.state;
        let selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        if (clearFilter) {
            filterDict?.clear();
        }
        this.setState(
            {
                selectLanguage: selectLanguage,
            },
            () => {
                this._onQueryButtonClicked();
            }
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, matchDatasetVersion } = this.state;
        if (records && records.length > 0) {
            const details = this.filterRecordDetails(
                selectLanguage || "",
                matchDatasetVersion
            );

            this.showEvaluationResult(details, "accuracy/barcode-raw.json");
        }
    };
}
