import * as React from "react";
import {
    IIconProps,
    INavLink,
    INavLinkGroup,
    INavStyles,
    Nav,
    Stack,
} from "@fluentui/react";
import "./Navigate.scss";
import { WorkspaceGroupsNav, Workspaces } from "../DataContract";
import { Consumer } from "../Layout";

const OCRICON: IIconProps = {
    iconName: "LocaleLanguage",
    className: "redIcon",
};
const CHECKBOXICON: IIconProps = {
    iconName: "Trackers",
    className: "yellowIcon",
};
const OCRMATHICON: IIconProps = {
    iconName: "Calculator",
    className: "greenIcon",
};
const OCRBARCODE: IIconProps = {
    iconName: "GenericScan",
    className: "cyanIcon",
};
const OCRTABLEICON: IIconProps = {
    iconName: "Table",
    className: "blueIcon",
};
const OCRPODICON: IIconProps = {
    iconName: "NewsSearch",
    className: "magentaIcon",
};
const FIGUREICON: IIconProps = {
    iconName: "BarChartVertical",
    className: "grayIcon",
};
const KVPICON: IIconProps = {
    iconName: "Link",
    className: "redIcon",
};
const INVOICEICON: IIconProps = {
    iconName: "Script",
    className: "yellowIcon",
};
const CUSTOMFORMICON: IIconProps = {
    iconName: "ColumnOptions",
    className: "greenIcon",
};
const QUERYFIELDICON: IIconProps = {
    iconName: "AnalyticsQuery",
    className: "cyanIcon",
};
const DOC_CLASSIFIER_ICON: IIconProps = {
    iconName: "RowsGroup",
    className: "cyanIcon",
};
const RECEIPTICON: IIconProps = {
    iconName: "ReceiptCheck",
    className: "blueIcon",
};
const BUSINESSCARDICON: IIconProps = {
    iconName: "ContactCard",
    className: "magentaIcon",
};
const IDCARDICON: IIconProps = {
    iconName: "ContactCardSettings",
    className: "grayIcon",
};
const INDUSTRY_ICON: IIconProps = {
    iconName: "BuildDefinition",
    className: "redIcon",
};
const HEALTH_ICON: IIconProps = {
    iconName: "Health",
    className: "yellowIcon",
};
const ExperimentalPrebuilt_ICON: IIconProps = {
    iconName: "TestPlan",
    className: "greenIcon",
};

const VERTICALTELEMETRYICON: IIconProps = {
    iconName: "AnalyticsReport",
    className: "cyanIcon",
};

const RELEASEICON: IIconProps = {
    iconName: "ProductRelease",
    className: "blueIcon",
};
const CUSTOMDOCRELEASEICON: IIconProps = {
    iconName: "ProductRelease",
    className: "magentaIcon",
};

export interface IHeaderLink {
    display: string;
    url: string;
}

export interface IProps {
    onThemeChange: (isDarkTheme: boolean) => void;
    isDarkTheme: boolean;
}

interface IState {
    showPrivacy: boolean;
    basePath: string;
    expandState: any;
}

function parseBasePath() {
    const parts = window.location.pathname.split("/");
    return parts[1];
}

const NAV_STYLES: Partial<INavStyles> = {
    root: {
        zIndex: 99999999999,
        width: 201,
        boxSizing: "border-box",
        borderRight: "1px solid #eee",
        overflowY: "auto",
        fontSize: "14px",
        fontFamily: "Helvetica",
        cursor: "pointer",
    },
    chevronButton: {
        fontSize: "16px",
    },
};
const NAV_DARK_STYLES: Partial<INavStyles> = {
    root: {
        zIndex: 99999999999,
        width: 201,
        boxSizing: "border-box",
        borderRight: "1px solid #eee",
        overflowY: "auto",
        fontSize: "14px",
        fontFamily: "Helvetica",
        cursor: "pointer",
    },
    chevronButton: {
        fontSize: "16px",
    },
};

let updatedExpandState: any = {};
let url = window.location.href;
let startIndex = 0;
let endIndex = 0;
let extractedString = "";
if (url.includes("/id/")) {
    startIndex = url.indexOf("eval/") + 5;
    endIndex = url.indexOf("/id/");
    extractedString = url.substring(startIndex, endIndex);
} else if (url.includes("?")) {
    startIndex = url.lastIndexOf("/") + 1;
    endIndex = url.lastIndexOf("?");
    extractedString = url.substring(startIndex, endIndex);
} else if (!url.includes("/")) {
    extractedString = "ocr";
} else {
    startIndex = url.lastIndexOf("/") + 1;
    extractedString = url.substring(startIndex);
}
let expandGroupName: string = "";
if (
    extractedString === "" ||
    extractedString === "eval" ||
    WorkspaceGroupsNav["Content Extraction"].includes(extractedString) ||
    extractedString === "Content Extraction"
) {
    expandGroupName = "Content Extraction";
} else if (
    WorkspaceGroupsNav["Layout Analysis"].includes(extractedString) ||
    extractedString === "Layout Analysis"
) {
    expandGroupName = "Layout Analysis";
} else if (
    WorkspaceGroupsNav["KVP"].includes(extractedString) ||
    extractedString === "KVP"
) {
    expandGroupName = "KVP";
} else if (
    WorkspaceGroupsNav["Vertical"].includes(extractedString) ||
    extractedString === "Vertical"
) {
    expandGroupName = "Vertical";
} else if (
    WorkspaceGroupsNav["ReleaseTest"].includes(extractedString) ||
    extractedString === "ReleaseTest"
) {
    expandGroupName = "ReleaseTest";
}

updatedExpandState = {
    [expandGroupName]: true,
};
sessionStorage.setItem("navExpandState", JSON.stringify(updatedExpandState));
const navArray = sessionStorage.getItem("navExpandState");
const NAVLINK_GROUPS: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Content Extraction",
                url: "/eval/ocr",
                isExpanded: navArray
                    ? JSON.parse(navArray)["Content Extraction"]
                    : true,
                links: [
                    {
                        name: "OCR",
                        url: "/eval/ocr",
                        iconProps: OCRICON,
                        icon: "HomeSolid",
                        key: Workspaces.Ocr,
                    },
                    {
                        name: "Checkbox",
                        url: "/eval/ocrcheckbox",
                        iconProps: CHECKBOXICON,
                        key: Workspaces.OcrCheckbox,
                    },
                    {
                        name: "MATH_OCR",
                        url: "/eval/ocrmath",
                        iconProps: OCRMATHICON,
                        key: Workspaces.OcrMath,
                    },
                    {
                        name: "OCR_Barcode",
                        url: "/eval/ocrbarcode",
                        iconProps: OCRBARCODE,
                        key: Workspaces.OcrBarcode,
                    },
                ],
            },
            {
                name: "Layout Analysis",
                url: "/eval/ocrtable",
                isExpanded: navArray
                    ? JSON.parse(navArray)["Layout Analysis"]
                    : false,
                links: [
                    {
                        name: "Table",
                        url: "/eval/ocrtable",
                        iconProps: OCRTABLEICON,
                        key: Workspaces.OcrTable,
                    },
                    {
                        name: "OCR_POD",
                        url: "/eval/ocrpod",
                        iconProps: OCRPODICON,
                        key: Workspaces.OcrPod,
                    },
                    {
                        name: "OCR_Figure",
                        url: "/eval/ocrfigure",
                        iconProps: FIGUREICON,
                        key: Workspaces.OcrFigure,
                    },
                ],
            },
            {
                name: "KVP",
                url: "/eval/kvp",
                isExpanded: navArray ? JSON.parse(navArray)["KVP"] : false,
                links: [
                    {
                        name: "KVP",
                        url: "/eval/kvp",
                        iconProps: KVPICON,
                        key: Workspaces.Kvp,
                    },
                ],
            },
            {
                name: "Vertical",
                url: "/eval/invoice",
                isExpanded: navArray ? JSON.parse(navArray)["Vertical"] : false,
                links: [
                    {
                        name: "Invoice",
                        url: "/eval/invoice",
                        iconProps: INVOICEICON,
                        key: Workspaces.Invoice,
                    },
                    {
                        name: "CustomForm",
                        url: "/eval/customform",
                        iconProps: CUSTOMFORMICON,
                        key: Workspaces.CustomForm,
                    },
                    {
                        name: "QueryField",
                        url: "/eval/queryfield",
                        iconProps: QUERYFIELDICON,
                        key: Workspaces.QueryField,
                    },
                    {
                        name: "Doc_Classifier",
                        url: "/eval/docclassifier",
                        iconProps: DOC_CLASSIFIER_ICON,
                        key: Workspaces.DocClassifier,
                    },
                    {
                        name: "Receipt",
                        url: "/eval/receipt",
                        iconProps: RECEIPTICON,
                        key: Workspaces.Receipt,
                    },
                    {
                        name: "Business_Card",
                        url: "/eval/businesscard",
                        iconProps: BUSINESSCARDICON,
                        key: Workspaces.BusinessCard,
                    },
                    {
                        name: "ID_Card",
                        url: "/eval/idcard",
                        iconProps: IDCARDICON,
                        key: Workspaces.IdCard,
                    },
                    {
                        name: "Industry_Prebuilt",
                        url: "/eval/industryprebuilt",
                        iconProps: INDUSTRY_ICON,
                        key: Workspaces.IndustryPrebuilt,
                    },
                    {
                        name: "Health_Doc",
                        url: "/eval/healthdoc",
                        iconProps: HEALTH_ICON,
                        key: Workspaces.HealthDoc,
                    },
                    {
                        name: "Experimental_Prebuilt",
                        url: "/eval/experimentalprebuilt",
                        iconProps: ExperimentalPrebuilt_ICON,
                        key: Workspaces.ExperimentalPrebuilt,
                    },
                    {
                        name: "Vertical_Telemetry",
                        url: "/eval/verticaltlm",
                        iconProps: VERTICALTELEMETRYICON,
                        key: Workspaces.VerticalTelemetry,
                    },
                ],
            },
            {
                name: "ReleaseTest",
                url: "/eval/releasetest",
                isExpanded: navArray
                    ? JSON.parse(navArray)["ReleaseTest"]
                    : false,
                links: [
                    {
                        name: "ReleaseTest",
                        url: "/eval/releasetest",
                        iconProps: RELEASEICON,
                        key: Workspaces.ReleaseTest,
                    },
                    {
                        name: "CustomDocReleaseTest",
                        url: "/eval/customdocreleasetest",
                        iconProps: CUSTOMDOCRELEASEICON,
                        key: Workspaces.CustomDocReleaseTest,
                    },
                ],
            },
        ],
    },
];

export class Navigate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const basePath = parseBasePath();

        this.state = {
            showPrivacy: true,
            basePath: basePath,
            expandState: this.getStoredExpandState(),
        };
    }

    public render(): JSX.Element {
        const { expandState } = this.state;
        const parts = window.location.pathname.split("/");
        let selectedKey = parts[2];
        return (
            <Stack className="navigation-container" verticalFill>
                <Consumer>
                    {(value) => {
                        return (
                            <Nav
                                styles={value ? NAV_DARK_STYLES : NAV_STYLES}
                                groups={NAVLINK_GROUPS.map((group) => ({
                                    ...group,
                                    collapseByDefault: expandState[group.name!],
                                }))}
                                selectedKey={selectedKey}
                                onLinkClick={this.handleGroupClick}
                            />
                        );
                    }}
                </Consumer>
            </Stack>
        );
    }
    public handleGroupClick = (
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        if (item) {
            let expandGroupName: string = "";
            if (
                item.name === "OCR" ||
                item.name === "Checkbox" ||
                item.name === "MATH_OCR" ||
                item.name === "OCR_Barcode" ||
                item.name === "Content Extraction"
            ) {
                expandGroupName = "Content Extraction";
            } else if (
                item.name === "Table" ||
                item.name === "OCR_POD" ||
                item.name === "OCR_Figure" ||
                item.name === "Layout Analysis"
            ) {
                expandGroupName = "Layout Analysis";
            } else if (item.name === "KVP") {
                expandGroupName = "KVP";
            } else if (
                item.name === "Invoice" ||
                item.name === "CustomForm" ||
                item.name === "Doc_Classifier" ||
                item.name === "Receipt" ||
                item.name === "Business_Card" ||
                item.name === "ID_Card" ||
                item.name === "Industry_Prebuilt" ||
                item.name === "Health_Doc" ||
                item.name === "Experimental_Prebuilt" ||
                item.name === "Vertical_Telemetry" ||
                item.name === "Vertical"
            ) {
                expandGroupName = "Vertical";
            } else if (
                item.name === "ReleaseTest" ||
                item.name === "CustomDocReleaseTest" ||
                item.name === "ReleaseTest"
            ) {
                expandGroupName = "ReleaseTest";
            }

            const updatedExpandState = {
                [expandGroupName]: true,
            };
            this.setState({ expandState: updatedExpandState });
            sessionStorage.setItem(
                "navExpandState",
                JSON.stringify(updatedExpandState)
            );
        }
    };
    public getStoredExpandState() {
        const storedExpandState = sessionStorage.getItem("navExpandState");
        return storedExpandState ? JSON.parse(storedExpandState) : {};
    }
    public componentWillMount() {
        let url = window.location.href;
        let startIndex = 0;
        let endIndex = 0;
        let extractedString = "";
        if (url.includes("/id/")) {
            startIndex = url.indexOf("eval/") + 5;
            endIndex = url.indexOf("/id/");
            extractedString = url.substring(startIndex, endIndex);
        } else if (url.includes("?")) {
            startIndex = url.lastIndexOf("/") + 1;
            endIndex = url.lastIndexOf("?");
            extractedString = url.substring(startIndex, endIndex);
        } else if (!url.includes("/")) {
            extractedString = "ocr";
        } else {
            startIndex = url.lastIndexOf("/") + 1;
            extractedString = url.substring(startIndex);
        }
        let expandGroupName: string = "";
        if (
            extractedString === "" ||
            extractedString === "eval" ||
            WorkspaceGroupsNav["Content Extraction"].includes(
                extractedString
            ) ||
            extractedString === "Content Extraction"
        ) {
            expandGroupName = "Content Extraction";
        } else if (
            WorkspaceGroupsNav["Layout Analysis"].includes(extractedString) ||
            extractedString === "Layout Analysis"
        ) {
            expandGroupName = "Layout Analysis";
        } else if (
            WorkspaceGroupsNav["KVP"].includes(extractedString) ||
            extractedString === "KVP"
        ) {
            expandGroupName = "KVP";
        } else if (
            WorkspaceGroupsNav["Vertical"].includes(extractedString) ||
            extractedString === "Vertical"
        ) {
            expandGroupName = "Vertical";
        } else if (
            WorkspaceGroupsNav["ReleaseTest"].includes(extractedString) ||
            extractedString === "ReleaseTest"
        ) {
            expandGroupName = "ReleaseTest";
        }
        const updatedExpandState = {
            [expandGroupName]: true,
        };
        this.setState({ expandState: updatedExpandState });
        sessionStorage.setItem(
            "navExpandState",
            JSON.stringify(updatedExpandState)
        );
    }
}
