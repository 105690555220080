import {
    PAGE_SIZE,
    RecordFactory,
    WorkspaceGroups,
    InitRecords,
    Record,
    HttpStatusCode,
    StringCheckMode,
} from "../DataContract/";
import _ from "lodash";
import { indexDb } from "./IndexDB";
import { vdiRequest } from "./fetch";
import authUrl from "./AuthUrl";

const DEFAULT_COLORS = [
    "#4363d8",
    "#3cb44b",
    "#e6194B",
    "#ffe119",
    "#f58231",
    "#911eb4",
    "#42d4f4",
    "#f032e6",
    "#bfef45",
    "#fabed4",
    "#469990",
    "#dcbeff",
    "#9A6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#00FFFF",
    "#a9a9a9",
    "#E6E6FA",
    "#FFE4C4",
];

const TAG_COLORS = ["#ca5010", "#84a858", "#d29200", "#0078d4", "#69797e"];

export function getRandomColor(): string {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.5)";
}

export function getColorByIndex(index: number): string {
    if (index === -1) {
        return "#ffffff";
    }
    if (index < 0) {
        index = Math.abs(index);
    }

    const safeIndex = index % DEFAULT_COLORS.length;
    return DEFAULT_COLORS[safeIndex];
}

const TREND_COLORS = [
    "#4c8df6",
    "#e46962",
    "#f7ce52",
    "#1ea446",
    "#886cd5",
    "#aaffc3",
];
export function getColorByIndex4Trend(index: number): string {
    if (index === -1) {
        return "#ffffff";
    }
    if (index < 0) {
        index = Math.abs(index);
    }

    const safeIndex = index % TREND_COLORS.length;
    return TREND_COLORS[safeIndex];
}

export function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function getTagColor(length: number): string[] {
    if (length <= 0) {
        return [];
    } else if (length <= TAG_COLORS.length) {
        return TAG_COLORS.slice(0, length);
    } else {
        let colors: string[] = TAG_COLORS.slice(0, TAG_COLORS.length);
        for (let i = 0; i < length - TAG_COLORS.length; i++) {
            colors.push(getRandomColor());
        }
        return colors;
    }
}

export function colorByTheme(isDarkTheme?: boolean) {
    return !!isDarkTheme ? "white" : "black";
}

export function isDevEnvironment(): boolean {
    return process.env.NODE_ENV === "development";
}

export function arrayDistinct<T>(array: T[]): T[] {
    let distinctArr = [...array];
    if (distinctArr && distinctArr.length > 0) {
        distinctArr = distinctArr.filter((item, index, arr) => {
            return arr.indexOf(item) === index;
        });
    }

    return distinctArr;
}

export function intersectionFromMultiArray<T>(arrayList: T[][]): T[] {
    let intersectionArray: T[] = [];

    if (arrayList.length > 0) {
        intersectionArray = arrayDistinct(arrayList[0]);
        if (arrayList.length > 1) {
            for (let i = 1; i < arrayList.length; i++) {
                const distinctArr = arrayDistinct(arrayList[i]);
                intersectionArray = intersectionArray.filter((d) =>
                    distinctArr.includes(d)
                );
            }
        }
    }

    return intersectionArray;
}

export function GetExtensionWithoutDot(networkPath: string): string {
    let ext = GetExtension(networkPath);
    if (ext && ext.startsWith(".")) {
        ext = ext.slice(1);
    }

    return ext;
}

export function GetExtension(networkPath: string): string {
    let result = "";
    const reg = /\.[^.]+$/;
    var matches = reg.exec(networkPath);
    if (matches) {
        result = matches[0];
    }

    return result;
}

export function GetFileName(networkPath: string): string {
    return networkPath.replace(/^.*[\\/]/, "");
}

export function GetFileNameWithoutExtension(networkPath: string): string {
    let result = "";
    const reg = /([^/]+)(?=\.\w+$)/;
    const matches = reg.exec(networkPath);
    if (matches) {
        result = matches[0];
    }

    return result;
}

export async function loadRecords(
    workspace: string,
    pageIndex: number = 1,
    searchs: string,
    limit: number = PAGE_SIZE,
    favRecordIds: string[] | null = null
): Promise<Record[]> {
    return RecordFactory.fetchAll(
        workspace,
        pageIndex,
        searchs,
        limit,
        favRecordIds
    ).then((records) => {
        return WorkspaceGroups.Taipei.includes(workspace)
            ? InitRecords(records)
            : records;
    });
}

export async function loadTotal(
    workspace: string,
    searchs: string = ""
): Promise<number> {
    const url = `/api/eval/${workspace}/records/total?${searchs}`;
    return vdiRequest(url, { method: "GET" })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((data: number) => {
            return data;
        })
        .catch((_error) => {
            return 0;
        });
}

export async function fetchDataByPath(
    path: string,
    cacheMaxAge: number = 1200
): Promise<string> {
    const storageItem = await indexDb.getDataByKey(path);
    return !!storageItem
        ? sleep(50).then(() => {
              return JSON.parse(storageItem);
          })
        : vdiRequest(path, {
              headers: {
                  "Cache-Control": `public, max-age=${cacheMaxAge}`,
              },
              method: "GET",
          })
              .then((response) => {
                  if (!response.ok) {
                      if (response.status === 404) {
                          return "{}";
                      }
                      throw new Error(response.statusText);
                  }
                  return response.text();
              })
              .then((result) => {
                  indexDb.writeToStore({
                      key: path,
                      value: JSON.stringify(result),
                  });
                  return result;
              });
}

export async function fetchValidDataByPath(
    path: string,
    cacheMaxAge: number = 1200
): Promise<string> {
    const storageItem = await indexDb.getDataByKey(path);
    return !!storageItem
        ? sleep(50).then(() => {
              return JSON.parse(storageItem);
          })
        : vdiRequest(path, {
              headers: {
                  "Cache-Control": `public, max-age=${cacheMaxAge}`,
              },
              method: "GET",
          }).then(async (response) => {
              if (response.ok) {
                  const respText = await response.text();
                  indexDb.writeToStore({
                      key: path,
                      value: JSON.stringify(respText),
                  });
                  return respText;
              } else {
                  if (response.status === 404) {
                      return "{}";
                  } else {
                      console.log(response.status);
                      console.log(response.statusText);
                      console.log(response.URL);
                      throw new Error(response.statusText);
                  }
              }
          });
}

export enum FileType {
    Json,
    Xml,
    Image,
}
export function downloadAsFile(data: any, fileName: string, type: FileType) {
    const saveLink = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
    ) as HTMLAnchorElement;

    switch (type) {
        case FileType.Json: {
            saveLink.href = URL.createObjectURL(
                new Blob([data], { type: "application/json" })
            );
            break;
        }
        case FileType.Xml: {
            saveLink.href = URL.createObjectURL(
                new Blob([data], { type: "application/xml" })
            );
            break;
        }
        case FileType.Image: {
            saveLink.href = URL.createObjectURL(data);
            break;
        }
    }

    saveLink.target = "_blank";
    saveLink.download = fileName;
    saveLink.click();
    setTimeout(() => {
        saveLink.remove();
    }, 6000);
}

export function downloadAsFileWithAssurance(
    fileSrc: string,
    saveFileName: string,
    fileType: FileType
) {
    if (fileSrc && saveFileName) {
        vdiRequest(fileSrc, {
            method: "GET",
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.blob().then((blob: any) => {
                        downloadAsFile(blob, saveFileName, fileType);
                    });
                } else {
                    if (resp.status === 404) {
                        alert("File doesn't exist");
                    } else {
                        window.open(fileSrc, "_blank");
                    }
                }
            })
            .catch(() => {
                window.open(fileSrc, "_blank");
            });
    }
}

export function CheckFileWhetherExistInAzureBlob(
    blobFileLink: string
): boolean {
    let queryStr = "";
    const querySymbolIndex = blobFileLink.indexOf("?");
    if (querySymbolIndex > -1) {
        queryStr = blobFileLink.substring(querySymbolIndex);
        blobFileLink = blobFileLink.substring(0, querySymbolIndex);
    }

    const urlParams = queryStr
        ? new URLSearchParams(queryStr)
        : new URLSearchParams();

    urlParams.set("comp", "metadata");
    blobFileLink += `?${urlParams.toString()}`;

    const request = new XMLHttpRequest();
    request.open("get", blobFileLink, false);
    request.send(null);

    return request.status === HttpStatusCode.OK;
}

const LARGE_FILENAMES_WHICH_SHOULD_BE_IGNORED = [
    "alignment_polygons",
    "doc_results",
    "kvp-detailed",
    "latency",
    "overall_entity_image_metrics",
    "pod_evaluation_data",
    "prediction_value_detail",
    "receipt_evaluation",
    "table_evaluation_data",
    "textline_entity_metrics",
    "textline_word_metrics",
    "verticaltlm_evaluation_data",
];

export function addSessionStorage(key: string, result: string): void {
    const limitedSize = 524288; //0.5M
    const isIgnored =
        result?.length > limitedSize ||
        LARGE_FILENAMES_WHICH_SHOULD_BE_IGNORED.some((filename) =>
            key.includes(filename)
        );

    if (isIgnored) {
        return;
    }

    try {
        const storeObj = {
            result: result,
            timestamp: Date.now(),
        };
        sessionStorage.setItem(key, JSON.stringify(storeObj));
    } catch (e) {
        const err = e as any;
        // remove the oldest key if met QuotaExceededError
        if (err && err.name && err.name.toUpperCase().indexOf("QUOTA") >= 0) {
            let tempStorageList = Object.entries(sessionStorage);
            // if sessionStorage is empty, means this result is larger than quota, then abort it
            if (tempStorageList.length > 0) {
                tempStorageList.sort(
                    (a, b) =>
                        JSON.parse(a[1]).timestamp - JSON.parse(b[1]).timestamp
                );
                sessionStorage.removeItem(tempStorageList[0][0]);
                addSessionStorage(key, result);
            }
        }

        console.log(err);
    }
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

interface IJsonObject {
    [key: string]: any;
}
export function toCamel(o: IJsonObject) {
    var newO: IJsonObject, origKey: string, newKey: string, value: IJsonObject;
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value);
            }
            return value;
        });
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                if (origKey.toUpperCase() === origKey) {
                    newKey = origKey.toLowerCase();
                } else {
                    newKey = (
                        origKey.charAt(0).toLowerCase() + origKey.slice(1) ||
                        origKey
                    ).toString();
                }

                value = o[origKey];
                if (
                    value instanceof Array ||
                    (value !== null && value.constructor === Object)
                ) {
                    value = toCamel(value);
                }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}

export function trimAll(str: string) {
    return str?.replaceAll(" ", "");
}

export interface StringCheckPolicy {
    symbol: string;
    mode: StringCheckMode;
}

export function CheckStringWhetherFollowPolicy(
    str: string,
    strCheckPolicy: StringCheckPolicy
): boolean {
    switch (strCheckPolicy.mode) {
        case StringCheckMode.Includes: {
            return str.includes(strCheckPolicy.symbol);
        }

        case StringCheckMode.StartsWith: {
            return str.startsWith(strCheckPolicy.symbol);
        }

        case StringCheckMode.EndsWith: {
            return str.endsWith(strCheckPolicy.symbol);
        }
        default:
            return false;
    }
}

export enum calculateUnit {
    Number,
    Percentage,
}

export function calculateGap(
    original: number,
    target: number,
    decimalPlaces: number = 1,
    unit: calculateUnit = calculateUnit.Number
): string {
    const diff = calculateGapNum(original, target, decimalPlaces, unit);
    switch (unit) {
        case calculateUnit.Percentage: {
            return `${diff > 0 ? "+" : ""}${diff.toString()}%`;
        }
        case calculateUnit.Number:
        default:
            return `${diff > 0 ? "+" : ""}${diff.toString()}`;
    }
}

export function calculateGapNum(
    original: number,
    target: number,
    decimalPlaces: number = 1,
    unit: calculateUnit = calculateUnit.Number
): number {
    const roundedOriginal = +original.toFixed(decimalPlaces);
    let roundedTarget = +target.toFixed(decimalPlaces);
    switch (unit) {
        case calculateUnit.Percentage: {
            if (roundedOriginal === roundedTarget) {
                return 0;
            } else if (target === 0) {
                return 100;
            } else {
                const gapInPercentage =
                    ((roundedOriginal - roundedTarget) / roundedTarget) * 100;
                return optimizeDecimalPlaces(gapInPercentage, decimalPlaces);
            }
        }
        case calculateUnit.Number:
        default:
            return optimizeDecimalPlaces(
                roundedOriginal - roundedTarget,
                decimalPlaces
            );
    }
}

export function optimizeDecimalPlaces(
    value: number,
    maxDecimalPlaces: number
): number {
    if (value === null || isNaN(Number(value))) {
        return NaN;
    } else if (Number.isInteger(value)) {
        return value;
    } else {
        const str = value.toString();
        const dotIndex = str.indexOf(".") + 1;
        const decimalPlaces = str.length - dotIndex;
        return decimalPlaces > maxDecimalPlaces
            ? +value.toFixed(maxDecimalPlaces)
            : value;
    }
}

export function setParamsToUrl(paramsArr: Array<[string, any]>): string {
    if (paramsArr && paramsArr.length > 0) {
        const urlParams = new URLSearchParams(window.location.search);
        paramsArr.forEach(([paramName, paramValue]) => {
            if (urlParams.has(paramName)) {
                urlParams.set(paramName, paramValue);
            } else {
                urlParams.append(paramName, paramValue);
            }
        });

        return `${window.location.origin}${
            window.location.pathname
        }?${urlParams.toString()}`;
    } else {
        return window.location.href;
    }
}

export function formatNumberDisplay(val: number) {
    return isNaN(val) ? "NaN" : val ?? 0;
}

export function formatPercentDisplay<T, Key extends keyof T>(
    target: T,
    keys: Key[]
): T {
    const reg = /^(-|\+)?\d+\.\d*$/;
    const result = _.cloneDeep(target) as any;
    for (const key of Object.keys(result)) {
        if (keys.includes(key as Key)) {
            const value = result[key];
            result[key] =
                value === 1
                    ? 100
                    : reg.test(String(value))
                    ? Number((value * 100).toFixed(2))
                    : value;
        }
    }
    return result;
}

export function formatDateTime(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
}

export function saveToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
}

export function getFromLocalStorage(key: string) {
    return localStorage.getItem(key);
}

export function resetScrollBarOfScrollablePane(index: number = -1): void {
    const scrollCollection = document.getElementsByClassName(
        "ms-ScrollablePane--contentContainer"
    );

    if (scrollCollection.length > 0 && scrollCollection.length > index) {
        if (index > -1) {
            scrollCollection[index].scrollTop = 0;
            scrollCollection[index].scrollLeft = 0;
        } else {
            Array.from(scrollCollection).forEach((s) => {
                s.scrollTop = 0;
                s.scrollLeft = 0;
            });
        }
    }
}

export const openImageInNewWindow = (url: string, dataset?: string) => {
    authUrl(url, true).then((imageUrl) => {
        const win = window.open(imageUrl, "_blank");
        if (win) {
            const timer = setTimeout(() => {
                if (win.closed) {
                    clearInterval(timer);
                } else {
                    let title = `imageId:${_.last(url.split("/"))!}`;
                    if (dataset) {
                        title = `Dataset:${dataset} ${title}`;
                    }
                    const imageId = document.createElement("div");
                    imageId.style.color = "white";
                    imageId.append(title);
                    win.document.body.prepend(imageId);
                    win.document.title = _.last(url.split("/"))!;
                }
            }, 500);
        }
    });
};
