import React, { Component } from "react";
import * as d3 from "d3";
import {
    DocumentCardDetails,
    DocumentCardTitle,
    getTheme,
    Stack,
} from "@fluentui/react";
import "./VerticalPieChart.scss";

interface ILineChartDataPoint {
    x: number | Date;
    y: number;
}
interface ILineChartData {
    legend: string;
    data: ILineChartDataPoint[];
    color: string;
}

interface IState {
    isLoading: boolean;
    legendPoints: any[];
    showScrollX: boolean;
    visibleCalloutId: number;
}

export interface TestPie {
    x: string;
    y: number;
}
export interface IGroupedLineChartData {
    chartTitle: string;
    lineChartData: ILineChartData[];
}
interface IProps {
    id: string;
    data: any;
    height: number;
    keyIndex: number;
    width?: number;
    isDarkTheme?: boolean;
}

const CHART_MARGIN = {
    left: 45,
    top: 40,
    right: 10,
    bottom: 90,
};

const theme = getTheme();

export class VerticalPieChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);

        this.state = {
            isLoading: false,
            legendPoints: [],
            showScrollX: false,
            visibleCalloutId: -1,
        };
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }

    public render() {
        const displayPiedatas: any[] = this.props.data.pieChartData.map(
            (pieChart: any) => {
                return pieChart.content.filter(
                    (pie: any) => pie.legend !== "TotalMS"
                );
            }
        );
        let displayPieData = displayPiedatas[0];
        return (
            <DocumentCardDetails
                key={`document`}
                className={"documentcard_Latency"}
            >
                <Stack className="overview__mani_latency" horizontal>
                    <div className="seriesDesc">
                        {displayPieData.map((pieChart: any, pieIndex: any) => {
                            return (
                                <div
                                    key={`legend_${pieIndex}`}
                                    className="desc"
                                    style={{
                                        color: this.props.isDarkTheme
                                            ? "white"
                                            : "black",
                                    }}
                                >
                                    <i
                                        style={{
                                            backgroundColor: pieChart.color,
                                        }}
                                    ></i>
                                    <span>{pieChart.legend}</span>
                                </div>
                            );
                        })}
                    </div>
                </Stack>
                <Stack
                    style={{
                        backgroundColor: this.props.isDarkTheme
                            ? theme.palette.neutralDark
                            : theme.palette.neutralLighterAlt,
                    }}
                >
                    <div
                        className="drawingContainer"
                        style={{
                            color: this.props.isDarkTheme ? "white" : "black",
                        }}
                    >
                        {displayPiedatas.map(
                            (_displayPieData, displayIndex) => {
                                return (
                                    <div key={`documentCard_${displayIndex}`}>
                                        <DocumentCardTitle
                                            className="overview__title_latency"
                                            title={`${this.props.data.chartTitle}`}
                                        />
                                        <div
                                            id={this.props.id + displayIndex}
                                            className="drawingBoard"
                                        ></div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </Stack>
            </DocumentCardDetails>
        );
    }

    private _drawChart(): void {
        let drawingBoard: HTMLElement | null;
        const displayPiedatas: any[] = this.props.data.pieChartData.map(
            (pieChart: any, pieIndex: number) => {
                drawingBoard = document.getElementById(
                    this.props.id + pieIndex
                );
                if (drawingBoard && drawingBoard.hasChildNodes()) {
                    drawingBoard.innerHTML = "";
                }
                return pieChart.content.filter(
                    (pie: any) => pie.legend !== "TotalMS"
                );
            }
        );

        let height = 250;
        let tip = d3
            .select("body")
            .append("div")
            .attr("class", "tip")
            .style("border", "1px solid steelblue")
            .style("padding", "5px")
            .style("position", "absolute")
            .style("display", "none");
        displayPiedatas.forEach((displayPieData, displayIndex) => {
            const totalY = displayPieData
                .map((v: any) => v["data"]["y"])
                .reduce((pre: any, cur: any) => Number(pre) + Number(cur), 0);
            let width = this.props.width;
            if (width === undefined) {
                width = CHART_MARGIN.left + CHART_MARGIN.right;
                const container = drawingBoard?.parentElement;
                if (container && container?.offsetWidth > width) {
                    width = container.offsetWidth - 1;
                }
            }

            let radius = (Math.min(width, height) * 0.8) / 2;
            let arc = d3.arc().innerRadius(70).outerRadius(radius);
            const svg = d3
                .select("#" + this.props.id + displayIndex)
                .append("svg")
                .attr("width", width)
                .attr("height", height);
            let pieChartData = displayPieData.map(
                (pieContent: any) => pieContent
            );
            let pieChartDataDetail = displayPieData.map(
                (pieContent: any) => pieContent.data
            );

            let toolTipData = displayPieData.map(
                (pieChart: any) => pieChart.tooltip
            );

            let drawData = d3
                .pie()
                .value(function (d: any) {
                    return d.y;
                })
                .sort(null)
                .sortValues(null)
                .startAngle(0)
                .endAngle(Math.PI * 2)
                .padAngle(0.05)(pieChartDataDetail);

            svg.append("g")
                .attr("transform", "translate( 440, 30 )")
                .append("g")
                .attr(
                    "transform",
                    "translate( " + radius + ", " + radius + " )"
                )
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1)
                .selectAll("path")
                .data(drawData)
                .enter()
                .append("path")
                .attr("fill", function (d, i) {
                    return pieChartData[i].color;
                })
                .attr("d", function (d: any) {
                    d.outerRadius = radius;
                    return arc(d);
                })
                .data(toolTipData)
                .on("mouseover", function (e: any, d: any) {
                    if (tip && tip.style("display") === "none") {
                        VerticalPieChart._showTip(
                            tip,
                            e as MouseEvent,
                            d as string[]
                        );

                        setTimeout(() => {
                            document.addEventListener("mousemove", hideTip);
                        }, 600);
                    }
                });

            svg.append("g")
                .attr("transform", "translate(540, 130 )")
                .selectAll("text")
                .data(drawData)
                .enter()
                .append("text")
                .attr("transform", function (d: any, i: any) {
                    return "translate(" + arc.centroid(d) + ") ";
                })
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "central")
                .attr("font-size", "10px")
                .attr("font-weight", "bold")
                .attr("fill", this.props.isDarkTheme ? "white" : "black")

                .text(function (d, i) {
                    return (
                        (
                            (Number(pieChartDataDetail[i].y) * 100) /
                            totalY
                        ).toFixed(2) + "%"
                    );
                })
                .data(toolTipData)
                .on("mouseover", function (e: any, d: any) {
                    if (tip && tip.style("display") === "none") {
                        VerticalPieChart._showTip(
                            tip,
                            e as MouseEvent,
                            d as string[]
                        );

                        setTimeout(() => {
                            document.addEventListener("mousemove", hideTip);
                        }, 600);
                    }
                });

            const hideTip = () => {
                if (tip && tip.style("display") !== "none") {
                    tip.style("display", "none");
                    document.removeEventListener("mousemove", hideTip);
                }
            };
        });
    }

    private static _showTip(
        tip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
        event: MouseEvent,
        contents: string[],
        doubleCols: boolean = false
    ): void {
        const VISUAL_CORRECTION_FACTOR = 10;
        let pageX = event.pageX;
        let pageY = event.pageY;
        const scrollWidth = window.scrollX + window.innerWidth;
        const scrollHeight = window.scrollY + window.innerHeight;
        let tipHtml = "";
        if (!!doubleCols) {
            const midIndex = Math.ceil(contents.length / 2);
            const leftHalf = contents.slice(0, midIndex);
            const rightHalf = contents.slice(midIndex);
            const divsLayout = [leftHalf, rightHalf]
                .map((half) => {
                    return `<div style="margin-right: 5px;">${half.join(
                        "<br />"
                    )}</div>`;
                })
                .join("");

            tipHtml = `<div style="display: flex">${divsLayout}</div>`;
        } else {
            tipHtml = contents.join("<br />");
        }
        tip.style("display", "block")
            .style("background-color", "#ffffff")
            .style("color", "#0078D4")
            .html(tipHtml);
        const tipWidth = tip.node()?.offsetWidth;
        const tipHeight = tip.node()?.offsetHeight;
        if (tipWidth && tipHeight) {
            if (tipHeight > window.innerHeight && doubleCols === false) {
                VerticalPieChart._showTip(tip, event, contents, true);
                return;
            }

            if (scrollWidth - event.pageX < tipWidth) {
                pageX = scrollWidth - tipWidth - VISUAL_CORRECTION_FACTOR;
            }

            if (scrollHeight - event.pageY < tipHeight) {
                pageY = scrollHeight - tipHeight - VISUAL_CORRECTION_FACTOR;
            }
        }

        tip.style("top", pageY + "px").style("left", pageX + "px");
    }
}
