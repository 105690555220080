import "../Common/MetricStyle.scss";
import React from "react";

import { Label } from "@fluentui/react";

import { Record } from "../../DataContract";

interface IState {}

interface IProps {
    records: Record[];
}

export class LoadDetails extends React.Component<IProps, IState> {
    public render() {
        return (
            <div>
                <Label style={{ marginLeft: "10px" }}>
                    <h3>Load Test Page (Nothing here yet)</h3>
                </Label>
            </div>
        );
    }
}
