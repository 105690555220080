import React from "react";
import "../Common/MetricStyle.scss";
import {
    //MessageBar
    MessageBar,
    MessageBarType,
} from "@fluentui/react";

import { Typename } from "../../DataContract";

import { OverviewTable, TableColumn, ColumnValueType } from "../../Controls";
import { StatisticalEntity } from "./OcrDataContract";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { updateExpandAction } from "../../../store/reducers";
import { FullScreen } from "../Common/FullScreen";

interface OcrWordResult {
    category: string;
    wordCount: number;
    goodAlignPercent: number;
    goodAlignCount: number;
    recall: number;
    precision: number;
    overlap: number;
    recallLt95Perc: number;
    recallLt95Cnt: number;
    [key: string]: number | string;
}

const AGGREGATED_CATEGORIES = [
    "Overall Categories",
    "Documentation Categories",
];

// prettier-ignore
export const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",          name: "Category",           fieldName: "category",           valueType: ColumnValueType.String,  minWidth: 100,   maxWidth: 200, isResizable: true, distinctStr: true },
    { key: "wordCount",         name: "WordCount",          fieldName: "wordCount",          valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "goodAlignPercent",  name: "GoodAlignPercent",   fieldName: "goodAlignPercent",   valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "recall",            name: "Recall",             fieldName: "recall",             valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "precision",         name: "Precision",          fieldName: "precision",          valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "overlap",           name: "Overlap",            fieldName: "overlap",            valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "recallLt95Perc",    name: "RecallLt95%Perc",    fieldName: "recallLt95Perc",     valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "recallLt95Cnt",     name: "RecallLt95%Cnt",     fieldName: "recallLt95Cnt",      valueType: ColumnValueType.Number,  minWidth: 150,   maxWidth: 200, isResizable: true, },
];

interface IProps {
    selectedColumns?: string[];
    datas: any;
    alwaysShow?: boolean;
    setSelectedSubPivot?: (selectPivot: string, linkData?: any) => void;
    isDarkTheme?: boolean;
}

interface IState {
    expandItem?: Map<number, boolean>;
}

export class WordAlignMetricsOverview extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.deepLinkHandler = this.deepLinkHandler.bind(this);
        this._onAnalysisItemInvoked = this._onAnalysisItemInvoked.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.state = {
            expandItem: new Map<number, boolean>(),
        };
    }

    public render() {
        return (
            <FullScreen>
                <MessageBar
                    dismissButtonAriaLabel="Close"
                    messageBarType={MessageBarType.info}
                    messageBarIconProps={{ iconName: "InfoSolid" }}
                    styles={{
                        root: {
                            backgroundColor: "#eff6fc",
                        },
                        icon: {
                            color: "#0078d4",
                        },
                    }}
                >
                    Good alignment definition: recall &gt; 95% and overlap = 0%
                </MessageBar>
                <div style={{ overflow: "hidden auto", height: "100%" }}>
                    {this._renderAsTable()}
                </div>
            </FullScreen>
        );
    }

    private _renderAsTable() {
        const { expandItem } = this.state;
        const { selectedColumns, datas = [] } = this.props;

        const columns: TableColumn[] = OVERVIEW_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const result: JSX.Element[] = [];
        const tableArr = datas.map(
            ([datasetName, simplifyData]: [string, any], index: number) => {
                if (simplifyData && simplifyData.length > 0) {
                    return (
                        <div
                            className="overview__detail"
                            key={`table_${datasetName}`}
                        >
                            {this.props.alwaysShow ? (
                                <OverviewTable<OcrWordResult>
                                    evalData={simplifyData}
                                    columns={columns}
                                    tableTitle={datasetName}
                                    downloadTableTitle={datasetName}
                                    deepLinkHandler={this.deepLinkHandler}
                                    onItemInvoked={(item?: any) =>
                                        this._onItemInvoked(
                                            item,

                                            datasetName
                                        )
                                    }
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                            ) : (
                                <ExpandCard
                                    text={datasetName}
                                    expandAll={
                                        expandItem?.get(index) ??
                                        store.getState().globalReducer.expandAll
                                    }
                                    onExpandChange={(expand: boolean) => {
                                        expandItem?.set(index, expand);
                                        this.setState(
                                            { expandItem: expandItem },
                                            () =>
                                                this._expandCountChange(
                                                    simplifyData.length
                                                )
                                        );
                                    }}
                                    isDarkTheme={this.props.isDarkTheme}
                                >
                                    <OverviewTable<OcrWordResult>
                                        evalData={simplifyData}
                                        columns={columns}
                                        tableTitle={""}
                                        downloadTableTitle={datasetName}
                                        deepLinkHandler={this.deepLinkHandler}
                                        onItemInvoked={(item?: any) =>
                                            this._onItemInvoked(
                                                item,

                                                datasetName
                                            )
                                        }
                                        isDarkTheme={this.props.isDarkTheme}
                                    />
                                </ExpandCard>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }
            }
        );

        result.push(...tableArr);
        return result;
    }

    public _expandCountChange = (total: number) => {
        let expandCount = 0;
        for (const value of this.state.expandItem?.values() || []) {
            if (value) {
                expandCount++;
            }
        }
        if (
            (expandCount === 0 && this.state.expandItem?.size === total) ||
            expandCount === total
        ) {
            const expand = expandCount === total;
            if (expand !== store.getState().globalReducer.expandAll) {
                store.dispatch(updateExpandAction(expand));
            }
        }
    };

    componentDidMount() {
        store.dispatch(
            updateStateAction({
                saveKey: `${store.getState().globalReducer.workSpace}_${
                    Typename.WordAlignMetrics
                }_Overview`,
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    private _onAnalysisItemInvoked(item: StatisticalEntity): void {
        if (item && !AGGREGATED_CATEGORIES.includes(item.name)) {
            let linkData: { [key: string]: string | undefined } = {};
            linkData.toSelectLanguage = item.language;
            linkData.toSelectCategory = item.name;
            this.deepLinkHandler("ByImage", linkData);
        }
    }

    private _onItemInvoked(item: any, datasetName: string): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const fieldMetricsArr = values[1] as OcrWordResult[];
            if (fieldMetricsArr?.length > 0) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = datasetName;
                linkData.toSelectCategory = fieldMetricsArr[0].category;
                if (
                    AGGREGATED_CATEGORIES.indexOf(linkData.toSelectCategory) ===
                    -1
                ) {
                    this.deepLinkHandler!("ByImage", linkData);
                }
            }
        }
    }
}
