import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { NotFound } from "./NotFound";
import { Workspace } from "./Workspace";
import {
    URL_PARAM_NAME_SEARCH,
    URL_PARAM_NAME_VIEWTYPE,
    Workspaces,
} from "../DataContract";
import { Consumer } from "../Layout";

interface IRouteParam {
    workspace: string;
}

export class Dashboard extends React.Component<RouteComponentProps> {
    public render() {
        const workspace = (this.props.match.params as IRouteParam).workspace;
        const urlParams = new URLSearchParams(window.location.search);
        const search = urlParams.get(URL_PARAM_NAME_SEARCH);
        const viewType = urlParams.get(URL_PARAM_NAME_VIEWTYPE);

        if (
            [
                Workspaces.Ocr,
                Workspaces.OcrTable,
                Workspaces.OcrCheckbox,
                Workspaces.OcrBackend,
                Workspaces.ReleaseTest,
                Workspaces.Invoice,
                Workspaces.Kvp,
                Workspaces.CustomForm,
                Workspaces.QueryField,
                Workspaces.CustomDocReleaseTest,
                Workspaces.Receipt,
                Workspaces.BusinessCard,
                Workspaces.IdCard,
                Workspaces.IndustryPrebuilt,
                Workspaces.HealthDoc,
                Workspaces.ExperimentalPrebuilt,
                Workspaces.VerticalTelemetry,
                Workspaces.OcrPod,
                Workspaces.OcrMath,
                Workspaces.OcrBarcode,
                Workspaces.DocClassifier,
                Workspaces.OcrFigure,
            ].includes(workspace)
        ) {
            return (
                <Consumer>
                    {(value) => {
                        return (
                            <Workspace
                                isDarkTheme={value}
                                initSearchString={search}
                                workspace={workspace}
                                onItemClicked={(record) =>
                                    record.onNavigateTo()
                                }
                                viewType={viewType ?? undefined}
                            />
                        );
                    }}
                </Consumer>
            );
        } else {
            return <NotFound />;
        }
    }
}
