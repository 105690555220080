import {
    DirectionalHint,
    IColumn,
    IconButton,
    IIconProps,
    ITooltipHostStyles,
    TooltipHost,
} from "@fluentui/react";
import React from "react";
import {
    FAVOURITE,
    WorkspaceGroups,
    Workspaces,
    Record,
} from "../../DataContract/";
import { saveToLocalStorage } from "../../Utils";
import CopyToClipboard from "react-copy-to-clipboard";
import "./RecordColumn.scss";
import { EditTagsBar } from "./EditTagsBar";

export interface ICommonProps {
    workspace: string;
    onRemove?: (item: Record, index?: number) => void;
}

export interface ICommonState {
    displayItems: Record[];
    favoriteItems?: string[];
    visibleCalloutId?: string;
    showWarning?: boolean;
    editTags?: boolean;
}

const removeIcon: IIconProps = {
    iconName: "ChromeClose",
    styles: { root: { color: "#a4262c" } },
};

const calloutProps = { gapSpace: 5, isBeakVisible: false };

const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-flex", justifyContent: "middle" },
};

export abstract class RecordColumn<
    P extends ICommonProps,
    S extends ICommonState
> extends React.Component<P, S> {
    constructor(props: P) {
        super(props);
        this._onColumnRender = this._onColumnRender.bind(this);
    }

    public _onColumnRender(
        item: Record,
        _index?: number | undefined,
        column?: IColumn | undefined
    ) {
        if (!column) {
            return;
        }

        const val = item[column.fieldName as keyof Record];
        const idColumnStyle =
            this.props.workspace !== Workspaces.CustomDocReleaseTest &&
            this.props.workspace !== Workspaces.ReleaseTest
                ? "itemId__text"
                : "";
        if (Array.isArray(val)) {
            if (column.key === "tags") {
                return (
                    <EditTagsBar
                        edit={this.state.editTags}
                        values={val}
                        record={item}
                        visibleCalloutId={this.state.visibleCalloutId ?? "-1"}
                        setRecordTag={(tags: string[]) =>
                            this._setRecordTag(tags, item.id, _index)
                        }
                        onChangevisibleCalloutId={(id: string) =>
                            this.setState({ visibleCalloutId: id })
                        }
                    />
                );
            } else {
                return (
                    <TooltipHost
                        content={val.join("; ")}
                        calloutProps={calloutProps}
                        styles={hostStyles}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <div className="table__item">{val.join("; ")}</div>
                    </TooltipHost>
                );
            }
        } else {
            if (column.key === "id") {
                return (
                    <>
                        {WorkspaceGroups.ReleaseTests.includes(
                            this.props.workspace
                        ) ? (
                            <div className="table__item">
                                <div className="itemId">
                                    <div className={idColumnStyle}>{val}</div>
                                    <CopyToClipboard text={String(val)}>
                                        <IconButton
                                            className="itemId__copy"
                                            iconProps={{ iconName: "Copy" }}
                                            title="copy"
                                            ariaLabel="copy"
                                        />
                                    </CopyToClipboard>
                                </div>
                            </div>
                        ) : (
                            <CopyToClipboard text={String(val)}>
                                <IconButton
                                    className="itemId__copy"
                                    iconProps={{ iconName: "Copy" }}
                                    title={String(val)}
                                    ariaLabel="copy"
                                />
                            </CopyToClipboard>
                        )}
                    </>
                );
            } else if (column.key === "favorite") {
                return (
                    <div className="table__item">
                        {!!val && (
                            <div className="favorite">
                                <TooltipHost
                                    content="Remove from my favorites"
                                    directionalHint={
                                        DirectionalHint.rightBottomEdge
                                    }
                                    calloutProps={calloutProps}
                                >
                                    <IconButton
                                        style={{
                                            height: "12px",
                                        }}
                                        iconProps={{
                                            iconName: "FavoriteStarFill",
                                            styles: {
                                                root: {
                                                    color: "#ffbe00",
                                                },
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.removeFavorite(item);
                                        }}
                                    />
                                </TooltipHost>
                            </div>
                        )}
                        {!val && (
                            <div className="unfavorite">
                                <TooltipHost
                                    content="Add to my favorites"
                                    directionalHint={
                                        DirectionalHint.rightBottomEdge
                                    }
                                    calloutProps={calloutProps}
                                >
                                    <IconButton
                                        style={{
                                            height: "12px",
                                        }}
                                        iconProps={{
                                            iconName: "FavoriteStar",
                                            styles: {
                                                root: {
                                                    color: "#605e5c",
                                                },
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            e.nativeEvent.stopImmediatePropagation();
                                            this.addFavorite(item);
                                        }}
                                    />
                                </TooltipHost>
                            </div>
                        )}
                    </div>
                );
            } else if (column.key === "delete") {
                return (
                    <IconButton
                        iconProps={removeIcon}
                        onClick={() => {
                            if (this.props.onRemove) {
                                this.props.onRemove(item, _index);
                            }
                        }}
                    />
                );
            } else {
                return (
                    <TooltipHost
                        content={String(val)}
                        calloutProps={calloutProps}
                        styles={hostStyles}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <div className="table__item">{val}</div>
                    </TooltipHost>
                );
            }
        }
    }

    private _setRecordTag = (tags: string[], itemId: string, index: any) => {
        const { displayItems, favoriteItems } = this.state;
        const { workspace } = this.props;
        const sortedItems = this._sortDisplayItems(
            workspace,
            displayItems,
            favoriteItems
        );

        sortedItems[index].setRecordTags(itemId, tags, workspace);
        this.setState({
            displayItems: displayItems,
            visibleCalloutId: "-1",
            showWarning: false,
        });
    };

    public _sortDisplayItems(
        workspace: string,
        displayItems: Record[],
        favoriteItemIds?: string[]
    ): Record[] {
        if (!favoriteItemIds) {
            return displayItems;
        }
        let itemsWithFav = displayItems
            .filter((item) => favoriteItemIds.indexOf(item.id) !== -1)
            .map((item) => {
                item.favorite = true;
                return item;
            });

        let itemsWithUnFav = displayItems
            .filter((item) => favoriteItemIds.indexOf(item.id) === -1)
            .map((item) => {
                item.favorite = false;
                return item;
            });

        if (WorkspaceGroups.Taipei.includes(workspace)) {
            itemsWithFav = itemsWithFav.sort(
                (a, b) => b.createTimestamp - a.createTimestamp
            );

            itemsWithUnFav = itemsWithUnFav.sort(
                (a, b) => b.createTimestamp - a.createTimestamp
            );
        }

        return [...itemsWithFav, ...itemsWithUnFav];
    }

    private addFavorite(item: Record) {
        let newFavors = this.state.favoriteItems?.slice();
        newFavors?.push(item.id);
        saveToLocalStorage(FAVOURITE, newFavors?.join(",") || "");
        this.setState({
            favoriteItems: newFavors,
        });
    }

    private removeFavorite(item: Record) {
        let newFavors = this.state.favoriteItems?.slice();
        newFavors?.splice(newFavors.indexOf(item.id), 1);
        saveToLocalStorage(FAVOURITE, newFavors?.join(",") || "");
        this.setState({
            favoriteItems: newFavors,
        });
    }
}
