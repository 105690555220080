import React, { Component } from "react";
import * as d3 from "d3";

interface IState {
    pieElement?: any;
}

export interface PieData {
    title: string;
    value: number;
    color: any;
}

interface IProps {
    id?: string;
    data: PieData[];
    height?: number;
    width?: number;
    isDarkTheme?: boolean;
}

export class PieChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);

        this.state = {};
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }

    public render() {
        return (
            <div
                style={{ position: "relative", minHeight: "400px" }}
                dangerouslySetInnerHTML={{ __html: this.state.pieElement }}
            ></div>
        );
    }

    private _drawChart(): void {
        let data = this.props.data;
        // data = data.sort((a, b) => a.value - b.value);
        // const oddDatas: PieData[] = [];
        // const evenDatas: PieData[] = [];
        // data.forEach((data, index) => {
        //     if (index % 2 !== 0) {
        //         oddDatas.push(data);
        //     } else {
        //         evenDatas.push(data);
        //     }
        // });

        // data = [...oddDatas, ...evenDatas];
        const width = 400;
        const height = Math.min(width, 400);

        // Create the pie layout and arc generator.
        const pie = d3
            .pie<PieData>()
            .sort(null)
            .value((d: any) => d.value);

        const arc = d3
            .arc()
            .innerRadius(0)
            .outerRadius(Math.min(width, height) / 2 - 100)
            .padAngle(0.05);

        const arcs = pie(data);

        // Create the SVG container.
        const svg = d3
            .create("svg")
            .attr("width", width)
            .attr("height", height)
            .attr(
                "viewBox",
                `${-width / 2}, ${-height / 2}, ${width}, ${height}`
            )
            .attr(
                "style",
                "max-width: 100%; height: auto; font: 10px sans-serif;position:absolute;margin:auto;left:0;top:0;right:0;bottom:0"
            );

        // Add a sector path for each value.
        svg.append("g")
            .attr("stroke", "white")
            .selectAll()
            .data(arcs)
            .join("path")
            .attr("fill", (d: any) => d.data.color)
            .attr("d", (d: any) => arc(d))
            .append("title")
            .text(
                (d) => `${d.data.title}: ${this.percentageValue(d.data.value)}`
            );

        // Create a new arc generator to place a label close to the edge.
        // The label shows the value if there is enough room.
        let y: any = undefined;
        let curr = 0;
        let mar = 10;
        svg.append("g")
            .selectAll()
            .data(arcs)
            .join("text")
            .attr("text-anchor", (d: any) => {
                return d.startAngle >= Math.PI || d.endAngle >= Math.PI
                    ? "end"
                    : "start";
            })
            .attr("transform", (d: any) => {
                const labelRadius = arc.outerRadius()(d) * 1.05;
                const arcLabel = d3
                    .arc()
                    .innerRadius(labelRadius)
                    .outerRadius(labelRadius);
                const centroid = arcLabel.centroid(d);
                let translateY = centroid[1];
                if (
                    (y !== undefined && centroid[1] + 10 < y) ||
                    centroid[1] + 10 > y
                ) {
                    curr += 1;
                    if (translateY > 0) {
                        translateY += curr * mar;
                    } else {
                        translateY -= curr * mar;
                    }
                } else {
                    curr = 0;
                    translateY += mar;
                }
                y = centroid[1];
                return `translate(${[centroid[0], translateY]})`;
            })
            .attr("fill", this.props.isDarkTheme ? "white" : "black")
            .call((text) =>
                text
                    .append("tspan")
                    // .attr("y", "-0.4em")
                    // .attr("font-weight", "bold")
                    // .attr("font-size", "15px")
                    .text((d) => d.data.title)
            );

        svg.append("g")
            .attr("text-anchor", "middle")
            .selectAll()
            .data(arcs)
            .join("text")
            .attr("transform", (d: any) => {
                const labelRadius = arc.outerRadius()(d) * 0.8;
                const arcLabel = d3
                    .arc()
                    .innerRadius(labelRadius)
                    .outerRadius(labelRadius);
                return `translate(${arcLabel.centroid(d)})`;
            })
            .attr("fill", this.props.isDarkTheme ? "white" : "black")
            .call((text) =>
                text
                    .append("tspan")
                    .attr("y", "-0.4em")
                    // .attr("font-weight", "bold")
                    // .attr("font-size", "15px")
                    .text((d) => this.percentageValue(d.data.value))
            );

        this.setState({ pieElement: svg.node()?.outerHTML });
    }

    percentageValue = (value: number) => {
        const { data } = this.props;
        const total = data.reduce((a, b) => a + b.value, 0);

        return `${((value / total) * 100).toFixed(2)}%`;
    };
}
