import React from "react";
import { Checkbox, Label, Stack } from "@fluentui/react";

export interface ICheckableOption {
    key: string | number;
    text: string;
    checked?: boolean;
}

interface IProps {
    listKey: string;
    title: string;
    options: ICheckableOption[];
    optionsOnChange: (
        listKey: string,
        checkedOptionKeys: Array<string | number>
    ) => void;
}

interface IState {
    optionStatusDict: Map<string | number, boolean>;
}
export class CheckboxList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const checkedOptionKeys = props.options
            .filter((option) => !!option.checked)
            .map((option) => option.key);

        let currentOptionStatusDict = new Map<string | number, boolean>();
        if (checkedOptionKeys && checkedOptionKeys.length > 0) {
            checkedOptionKeys.forEach((key) =>
                currentOptionStatusDict.set(key, true)
            );
        }

        this.state = { optionStatusDict: currentOptionStatusDict };
    }

    render() {
        const { listKey, title, options } = this.props;
        const { optionStatusDict } = this.state;
        return (
            <Stack key={listKey}>
                <Label>{title}</Label>
                {options &&
                    options.length > 0 &&
                    options.map((option) => {
                        return (
                            <Checkbox
                                styles={{
                                    root: { marginTop: 5, marginBottom: 5 },
                                }}
                                key={option.key}
                                label={option.text}
                                checked={optionStatusDict.has(option.key)}
                                onChange={this._onChange.bind(this, option.key)}
                            ></Checkbox>
                        );
                    })}
            </Stack>
        );
    }

    private _onChange(
        optionKey: string | number,
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined
    ): void {
        const { optionStatusDict } = this.state;

        if (optionStatusDict.has(optionKey) && !!!checked) {
            optionStatusDict.delete(optionKey);
            this._updateState(optionStatusDict);
        } else if (!optionStatusDict.has(optionKey) && !!checked) {
            optionStatusDict.set(optionKey, true);
            this._updateState(optionStatusDict);
        }
    }

    private _updateState(optionStatusDict: Map<string | number, boolean>) {
        const { listKey, optionsOnChange } = this.props;

        const checkedOptionKeys = Array.from(optionStatusDict.keys());
        this.setState({ optionStatusDict: optionStatusDict });
        optionsOnChange(listKey, checkedOptionKeys);
    }
}
