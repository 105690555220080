import { IDropdownStyles, IStackTokens } from "@fluentui/react";

export const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
        width: 70,
    },
    title: {
        marginTop: "0px !important",
        height: "100% !important",
    },
};

export const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};
export const stackVisualizationTokens: IStackTokens = {
    childrenGap: 10,
};
