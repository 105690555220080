import React from "react";
import { Record } from "../../DataContract";
import { DocClassifierOverview } from "../../Views/DocClassifier/DocClassifierOverview";
import { DocClassifierImageMetrics } from "../../Views/DocClassifier/DocClassifierImageMetrics";

interface IProps {
    records: Record[];
    selectedKey: string;
    isDarkTheme?: boolean;
    isReport?: boolean;
    getSettingControl?: (isSettingOpen: boolean) => void;
}

export class DocClassifierPage extends React.Component<IProps> {
    render(): React.ReactNode {
        const { records, selectedKey } = this.props;
        return (
            <>
                {selectedKey === "docClassifierGeneralMetrics" && (
                    <DocClassifierOverview
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}

                {selectedKey === "docClassifierByImageMetrics" && (
                    <DocClassifierImageMetrics
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
            </>
        );
    }
}
