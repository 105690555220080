import * as React from "react";
import {
    Checkbox,
    DirectionalHint,
    Label,
    Panel,
    Stack,
    Toggle,
    TooltipHost,
    ITooltipHostStyles,
    ChoiceGroup,
    IChoiceGroupOption,
    IColumn,
} from "@fluentui/react";
import { store } from "../../store";
import { Unsubscribe } from "redux";
import { saveToLocalStorage } from "../Utils";
import {
    updateMathDatasetAction,
    updateStateLevel,
    updateselectedItemsAction,
} from "../../store/reducers/setting";

interface ITableConfiguration {
    key: string;
    text: string;
    options: string[];
    multiSelect?: boolean;
    selectedKey?: string;
    selectedKeys?: string[];
    shortName?: boolean;
    onChange?: (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ) => void;
}
export type ITableConfigurations = ITableConfiguration[];

interface IProps {
    toggleLabel?: string;
    toggleTooltips?: string;
    isSettingOpen: boolean;
    getSettingControl?: (isSettingOpen: boolean) => void;
    onLevelChange?: (option: IChoiceGroupOption) => void;
}

interface IState {
    columns: IColumn[];
    selectedItems: string[];
    matchDatasetVersion: boolean;
    saveKey?: string;
    levels?: IChoiceGroupOption[];
    selectedLevel?: string;
    vertical: boolean;
}
const CALLOUTPROPS = { gapSpace: 0 };
const HOSTSTYLES: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
};

export class Setting extends React.Component<IProps, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(props: IProps) {
        super(props);
        const reducer = store.getState().settingReducer;
        this.state = {
            columns: reducer.columns.filter((value) => !value.isIconOnly),
            selectedItems: reducer.selectedItems,
            saveKey: reducer.saveKey,
            matchDatasetVersion: reducer.matchDatasetVersion,
            levels: reducer.levels,
            selectedLevel: reducer.selectedLevel,
            vertical: reducer.vertical,
        };
    }

    componentWillMount(): void {
        this.unsubscribe = store.subscribe(() => {
            this.setState({
                columns: store
                    .getState()
                    .settingReducer.columns.filter(
                        (value) => !value.isIconOnly
                    ),
                selectedItems: store.getState().settingReducer.selectedItems,
                saveKey: store.getState().settingReducer.saveKey,
                matchDatasetVersion:
                    store.getState().settingReducer.matchDatasetVersion,
                levels: store.getState().settingReducer.levels,
                selectedLevel: store.getState().settingReducer.selectedLevel,
            });
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render() {
        const {
            columns,
            selectedItems,
            saveKey,
            matchDatasetVersion,
            levels,
            selectedLevel,
            vertical,
        } = this.state;
        return (
            <Stack>
                <Panel
                    isLightDismiss
                    isOpen={this.props.isSettingOpen}
                    onDismiss={() => {
                        if (saveKey) {
                            saveToLocalStorage(
                                saveKey,
                                selectedItems.join(",") || ""
                            );
                        }
                        this.props.getSettingControl!(false);
                    }}
                    closeButtonAriaLabel="Close"
                    headerText={"Setting"}
                    styles={{ main: { width: "auto !important" } }}
                >
                    <Stack verticalFill>
                        <Stack
                            verticalFill
                            styles={{ root: { marginTop: "5px" } }}
                        >
                            <Label>Columns:</Label>
                            {columns &&
                                columns.map((opt, optionIndex) => {
                                    return (
                                        <Stack
                                            verticalFill
                                            key={`option_${optionIndex}`}
                                        >
                                            <Checkbox
                                                label={opt.name}
                                                defaultChecked={
                                                    selectedItems.indexOf(
                                                        opt.key
                                                    ) !== -1
                                                }
                                                styles={{
                                                    root: {
                                                        marginBottom: "8px",
                                                    },
                                                }}
                                                onChange={(
                                                    ev?: any,
                                                    checked?: boolean
                                                ) =>
                                                    this.onColumnChange(
                                                        opt.key,
                                                        checked!
                                                    )
                                                }
                                            ></Checkbox>
                                        </Stack>
                                    );
                                })}
                        </Stack>
                        {!vertical && (
                            <Stack horizontal verticalAlign="baseline">
                                <TooltipHost
                                    content={
                                        this.props.toggleTooltips ??
                                        "Whether to match dataset version in addition to dataset name in compare mode."
                                    }
                                    calloutProps={CALLOUTPROPS}
                                    styles={HOSTSTYLES}
                                    directionalHint={
                                        DirectionalHint.bottomCenter
                                    }
                                >
                                    <Toggle
                                        label={
                                            this.props.toggleLabel ??
                                            "Match Dataset Version"
                                        }
                                        inlineLabel
                                        defaultChecked={matchDatasetVersion}
                                        onText="On"
                                        offText="Off"
                                        onChange={(event, checked) => {
                                            store.dispatch(
                                                updateMathDatasetAction(
                                                    checked!
                                                )
                                            );
                                        }}
                                    />
                                </TooltipHost>
                            </Stack>
                        )}
                        {levels && (
                            <ChoiceGroup
                                styles={{
                                    flexContainer: {
                                        span: {
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            width: "280px!important",
                                        },
                                    },
                                }}
                                defaultSelectedKey={selectedLevel}
                                options={levels}
                                onChange={(event, option) => {
                                    store.dispatch(
                                        updateStateLevel(option?.key)
                                    );
                                }}
                                label="Choose Metrics Level"
                            />
                        )}
                    </Stack>
                </Panel>
            </Stack>
        );
    }

    onColumnChange = (option?: any, isChecked?: boolean) => {
        const newSelectedItems = this.state.selectedItems!.slice();
        if (option) {
            if (isChecked) {
                // add the option if it's checked
                newSelectedItems.push(option);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }
            store.dispatch(updateselectedItemsAction(newSelectedItems));
        }
    };
}
