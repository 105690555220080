import React from "react";
import "../Common/MetricStyle.scss";
import "./OcrPodImageMetrics.scss";
import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";

import { OcrPodGeneralMetrics } from "./OcrPodGeneralMetrics";
import { OcrPodImageMetrics } from "./OcrPodImageMetrics";
import { Typename, Workspaces } from "../../DataContract";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { ICommonProps } from "../Common/CommonMetrics";
import { LogicalOverview } from "./Logical/LogicalOverview";
import { LogicalByImage } from "./Logical/LogicalByImage";
import { DEFINITION_NAME } from "./OcrPodDataInterface";

interface IProps extends ICommonProps {
    analysisOn?: boolean;
}

interface IState {
    selectedPivot: string;
    selectedDefinition: string;
}

export class OcrPodMetrics extends React.Component<IProps, IState> {
    private subType?: string;
    private selectLanguage?: string;
    private definitionname?: string;

    private groupName?: string;
    private predictionName?: string;
    constructor(props: IProps) {
        super(props);
        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            selectedPivot: "Overview",
            selectedDefinition: DEFINITION_NAME.region_grouping,
        };
    }
    public render() {
        const { isDarkTheme, records, saveSetKey } = this.props;
        const { selectedPivot, selectedDefinition } = this.state;

        return (
            <div className="overview">
                <Pivot
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={selectedPivot}
                    onLinkClick={this.onPivotItemChanged}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <OcrPodGeneralMetrics
                            saveSetKey={`${Workspaces.OcrPod}_${Typename.GeneralMetrics}_Overview`}
                            records={records}
                            isDarkTheme={isDarkTheme}
                            selectedDefinition={selectedDefinition}
                            onDefinitionChange={(definition) => {
                                this.setState({
                                    selectedDefinition: definition,
                                });
                            }}
                            onItemInvoked={(data: string[]) => {
                                [
                                    this.definitionname,
                                    this.selectLanguage,
                                    this.subType,
                                ] = data;
                                this.pivotItemChanged("ByImage");
                            }}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="By Image"
                        className="displayFlex"
                        itemKey="ByImage"
                    >
                        <OcrPodImageMetrics
                            records={records}
                            isDarkTheme={isDarkTheme}
                            definitionname={this.definitionname}
                            selectLanguage={this.selectLanguage}
                            subType={this.subType}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="Logical Role Overview"
                        className="displayFlex"
                        itemKey="LogicalOverview"
                    >
                        <LogicalOverview
                            records={records}
                            isDarkTheme={isDarkTheme}
                            saveSetKey={saveSetKey}
                            onItemInvoked={(data: string[]) => {
                                [
                                    this.selectLanguage,
                                    this.groupName,
                                    this.predictionName,
                                ] = data;
                                this.pivotItemChanged("LogicalByImage");
                            }}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Logical Role By Image"
                        className="displayFlex"
                        itemKey="LogicalByImage"
                    >
                        <LogicalByImage
                            records={records}
                            isDarkTheme={isDarkTheme}
                            selectLanguage={this.selectLanguage}
                            selectGroup={this.groupName}
                            selectPrediction={this.predictionName}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    public onPivotItemChanged(item?: PivotItem): any {
        this.definitionname = undefined;
        this.selectLanguage = undefined;
        this.subType = undefined;
        this.groupName = undefined;
        this.predictionName = undefined;
        this.pivotItemChanged(item?.props.itemKey!);
    }

    pivotItemChanged = (itemKey: string) => {
        if (itemKey === this.state.selectedPivot) {
            return;
        }
        this.setState({
            selectedPivot: itemKey!,
        });
        store.dispatch(updateSelectSubpivoteAction(itemKey!));
    };
}
