import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { ImageView, ImageViewType, OcrToyType } from "../Common/ImageView";
import { POD_IMAGE_METRICS_DEFINITIONS } from "./OcrPodDataInterface";
import { IPivotItemDarkStyles, IPivotItemStyles } from "../Common/CommonStyle";
import { FullScreen } from "../Common/FullScreen";

interface IProps {
    records: Record[];
    isDarkTheme?: boolean;
    subType?: string;
    selectLanguage?: string;
    definitionname?: string;
}
interface IState {
    definitionname?: string;
}

export class OcrPodImageMetrics extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            definitionname: this.props.definitionname,
        };
    }
    render() {
        const { definitionname } = this.state;
        return (
            <FullScreen>
                <Pivot
                    className="pivotContainer displayFlex"
                    selectedKey={definitionname}
                    onLinkClick={(item) =>
                        this.setState({
                            definitionname: item?.props.itemKey,
                        })
                    }
                    styles={
                        this.props.isDarkTheme
                            ? IPivotItemDarkStyles
                            : IPivotItemStyles
                    }
                >
                    {POD_IMAGE_METRICS_DEFINITIONS.map((meric) => {
                        return (
                            <PivotItem
                                key={`pivot_${meric.name}`}
                                headerText={meric.displayName}
                                itemKey={meric.name}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <ImageView
                                    key={`imgView_${meric.name}`}
                                    saveSetKey={`${Workspaces.OcrPod}_${Typename.GeneralMetrics}_ByImage_${meric.name}`}
                                    selectGroup={this.props.subType}
                                    selectLanguage={this.props.selectLanguage}
                                    dataSrcName={meric.name}
                                    records={this.props.records}
                                    imageViewType={ImageViewType.pod}
                                    ocrToyType={OcrToyType.ocr_results}
                                    metricDefinitionArray={
                                        POD_IMAGE_METRICS_DEFINITIONS
                                    }
                                    optimizeData={true}
                                    isDarkTheme={this.props.isDarkTheme}
                                ></ImageView>
                            </PivotItem>
                        );
                    })}
                </Pivot>
            </FullScreen>
        );
    }
}
