import { MetricDefinition } from "../../DataContract/MetricDefinition";

const TABLE_CELL_ACCURACY_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition = {
    name: "cell_type",
    displayName: "Table Cell Type Precision and Recall",
    tablefields: ["# gt", "# reco", "precision", "recall", "f1"],
    props: [
        {
            fieldName: "ColumnHeader",
            displayName: "Column Header",
        },
        {
            fieldName: "RowHeader",
            displayName: "Row Header",
        },
        {
            fieldName: "RowColumn",
            displayName: "Row Column (Stub)",
        },
        {
            fieldName: "DataCell",
            displayName: "Data Cell",
        },
        {
            fieldName: "Description",
            displayName: "Description",
        },
        {
            fieldName: "TableTitle",
            displayName: "Table Title",
        },
    ],
};

const TABLE_CELL_ADJACENCY_RELATIONSHIP_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition =
    {
        name: "adj",
        displayName: "Table Cell Adjacency Relationship",
        tablefields: ["# gt", "# reco", "precision", "recall", "f1"],
        props: [
            {
                fieldName: "0.6",
                displayName: "iou 0.6",
            },
            {
                fieldName: "0.7",
                displayName: "iou 0.7",
            },
            {
                fieldName: "0.8",
                displayName: "iou 0.8",
            },
            {
                fieldName: "0.9",
                displayName: "iou 0.9",
            },
        ],
    };

const TABLE_CELL_ADJACENCY_RELATIONSHIP_TSR_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition =
    {
        name: "adj_tsr",
        displayName: "Table Cell Adjacency Relationship - Recognizer Only",
        tablefields: ["# gt", "# reco", "precision", "recall", "f1"],
        props: [
            {
                fieldName: "0.6",
                displayName: "iou 0.6",
            },
            {
                fieldName: "0.7",
                displayName: "iou 0.7",
            },
            {
                fieldName: "0.8",
                displayName: "iou 0.8",
            },
            {
                fieldName: "0.9",
                displayName: "iou 0.9",
            },
        ],
    };

const TABLE_CELL_COMPLETENESS_AND_PURITY_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition =
    {
        name: "ccnp",
        displayName: "Table Cell Completeness and Purity",
        tablefields: ["# both", "# gt", "# reco", "% complete", "% pure"],
        props: [
            {
                fieldName: "binary",
                displayName: "Binary",
            },
        ],
    };

const TABLE_COMPLETENESS_AND_PURITY_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition =
    {
        name: "tcnp",
        displayName: "Table Completeness and Purity",
        tablefields: ["# both", "# gt", "# reco", "% complete", "% pure"],
        props: [
            {
                fieldName: "binary",
                displayName: "Binary",
            },
        ],
    };

const TABLE_DETECTION_METRIC_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition = {
    name: "det",
    displayName: "Table Detection",
    tablefields: ["# gt", "# reco", "precision", "recall", "f1"],
    props: [
        {
            fieldName: "0.6",
            displayName: "iou 0.6",
        },
        {
            fieldName: "0.7",
            displayName: "iou 0.7",
        },
        {
            fieldName: "0.8",
            displayName: "iou 0.8",
        },
        {
            fieldName: "0.9",
            displayName: "iou 0.9",
        },
    ],
};

const TABLE_EDIT_DISTANCE_SIMILARITY_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition =
    {
        name: "teds",
        displayName: "Table Edit Distance Similarity",
        tablefields: ["count", "mean", "median", "std"],
        props: [
            {
                fieldName: "teds-gt",
                displayName: "teds-gt",
            },
            {
                fieldName: "teds-reco",
                displayName: "teds-reco",
            },
        ],
    };

const TABLE_HEADER_ACCURACY_DEFINITION_FOR_IMAGE_METRICS: MetricDefinition = {
    name: "header_acc",
    displayName: "Table Header Precision and Recall",
    tablefields: ["# gt", "# reco", "precision", "recall", "f1"],
    props: [
        {
            fieldName: "isHeader",
            displayName: "Header Precision and Recall",
        },
    ],
};

export const TABLE_IMAGE_METRICS_DEFINITIONS = [
    TABLE_COMPLETENESS_AND_PURITY_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_CELL_COMPLETENESS_AND_PURITY_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_CELL_ACCURACY_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_CELL_ADJACENCY_RELATIONSHIP_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_CELL_ADJACENCY_RELATIONSHIP_TSR_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_DETECTION_METRIC_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_EDIT_DISTANCE_SIMILARITY_DEFINITION_FOR_IMAGE_METRICS,
    TABLE_HEADER_ACCURACY_DEFINITION_FOR_IMAGE_METRICS,
];
