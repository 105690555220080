import "./VisualizerBase.scss";
import React from "react";
import _ from "lodash";
import { Pagination } from "../Pagination";
import { Consumer } from "../../Layout";
import {
    FontIcon,
    getTheme,
    IconButton,
    IIconProps,
    Label,
    mergeStyles,
    Stack,
} from "@fluentui/react";

const pickExpIcon = mergeStyles({
    fontSize: 13,
    height: 13,
    width: 13,
    margin: "0 5px",
});

const ChromeClose: IIconProps = {
    iconName: "ChromeClose",
    style: {
        fontSize: 14,
    },
};

const theme = getTheme();

export interface VisualizerProps {
    onLoadVisualizer: (fileId?: string) => any;
    onDismiss?: (fileId: any) => void;
    fileId?: string;
    clickNumber?: number;
    evalList?: any[];
}

export interface VisualizerState {
    fileUrl: string;
    fileId?: string;
    currentPage?: number;
}

export abstract class VisualizerBase<
    P extends VisualizerProps,
    S extends VisualizerState
> extends React.Component<P, S> {
    protected viewrRef = React.createRef<HTMLDivElement>();

    constructor(props: P) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);
        this.debounceOnPageChange = _.debounce(
            this.debounceOnPageChange.bind(this),
            500
        );
        this.state = {
            fileUrl: this.props.onLoadVisualizer(this.props.fileId),
            fileId: props.fileId,
            currentPage: this.props.clickNumber ?? 0,
        } as S;
    }

    public render(): JSX.Element {
        const { currentPage, fileId } = this.state;
        const { onDismiss } = this.props;

        return (
            <>
                <div className="expPicker" ref={this.viewrRef}>
                    <div className="expPic">
                        <Consumer>
                            {(value) => {
                                return (
                                    <Stack
                                        styles={{
                                            root: {
                                                width: "100%",
                                                backgroundColor: value
                                                    ? theme.palette.neutralDark
                                                    : theme.palette
                                                          .neutralLighterAlt,
                                            },
                                        }}
                                        horizontal
                                        horizontalAlign="space-between"
                                    >
                                        <Stack
                                            horizontal
                                            verticalAlign="center"
                                            styles={{
                                                root: { marginLeft: "10px" },
                                            }}
                                        >
                                            <Consumer>
                                                {(value) => {
                                                    return (
                                                        <Stack
                                                            horizontal
                                                            verticalAlign="center"
                                                        >
                                                            <FontIcon
                                                                aria-label="Compass"
                                                                iconName="LineChart"
                                                                className={
                                                                    pickExpIcon
                                                                }
                                                                style={{
                                                                    color: value
                                                                        ? "white"
                                                                        : "black",
                                                                }}
                                                            />
                                                            <Label
                                                                style={{
                                                                    color: value
                                                                        ? "white"
                                                                        : "black",
                                                                }}
                                                            >
                                                                {fileId ??
                                                                    this.props
                                                                        .fileId}
                                                            </Label>
                                                        </Stack>
                                                    );
                                                }}
                                            </Consumer>
                                        </Stack>
                                        <Stack
                                            horizontal
                                            verticalAlign="center"
                                            styles={{
                                                root: { marginRight: "10px" },
                                            }}
                                        >
                                            <Consumer>
                                                {(value) => {
                                                    if (this.props.evalList) {
                                                        return (
                                                            <Pagination
                                                                currentPage={
                                                                    currentPage
                                                                }
                                                                pageCount={
                                                                    this.props
                                                                        .evalList
                                                                        .length
                                                                }
                                                                totalCount={
                                                                    this.props
                                                                        .evalList
                                                                        .length
                                                                }
                                                                onPageChange={
                                                                    this
                                                                        .onPageChange
                                                                }
                                                                paginationType={
                                                                    "imageView"
                                                                }
                                                                isDarkTheme={
                                                                    value
                                                                }
                                                            />
                                                        );
                                                    }
                                                }}
                                            </Consumer>
                                            <IconButton
                                                iconProps={ChromeClose}
                                                className={pickExpIcon}
                                                onClick={() => {
                                                    onDismiss &&
                                                        onDismiss(undefined);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                );
                            }}
                        </Consumer>
                    </div>
                    {this.renderVisualizer()}
                </div>
            </>
        );
    }

    public componentDidMount(): void {
        this.refreshCache();
    }

    public componentDidUpdate(
        prevProps: VisualizerProps,
        prevState: VisualizerState
    ) {
        if (
            prevState.currentPage !== this.state.currentPage &&
            prevState.fileId !== this.state.fileId
        ) {
            this.setState({
                fileUrl: this.props.onLoadVisualizer(this.state.fileId) ?? "",
            });
        }
        if (this.props.fileId !== prevProps.fileId) {
            this.refreshCache({
                selectedExpIndex: 0,
                clickedIndex: this.props.clickNumber,
            });
        }
    }

    protected onPageChange(page: number) {
        let { fileId } = this.props;
        let fileUrl: string = "";

        fileUrl = this.props.evalList![page].find((v: any) => v).imageUrl;

        fileId = fileUrl.substring(
            fileUrl.lastIndexOf("/") + 1,
            fileUrl.lastIndexOf(".")
        );

        this.setState({
            currentPage: page,
            fileUrl: fileUrl,
            fileId: fileId,
        });
        this.debounceOnPageChange(page);
    }

    protected debounceOnPageChange(page: number) {
        if (this.viewrRef.current && this.viewrRef.current.scrollTop > 0) {
            this.viewrRef.current.scrollTop = 0;
        }

        this.refreshCache({
            selectedExpIndex: 0,
            clickedIndex: page,
        });
    }

    protected abstract renderVisualizer(): JSX.Element;
    protected abstract refreshCache(paramObj?: any): void;
}
