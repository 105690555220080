import { Sortability } from "../../DataContract";

const CUSTOM_NUMBER = 100000000;

function formartNumber(num: any, defaultValue = Sortability.MIN_SAFE_VALUE) {
    if (!num || isNaN(num)) {
        return defaultValue;
    }
    return num;
}

export function computeNumericDiff<T>(
    data: (T | null)[],
    field: keyof T,
    isSortedDescending: boolean = true
): number {
    const left = data[0];
    const right = data[1];

    let diff: number = 0;
    if (!left) {
        diff = isSortedDescending ? CUSTOM_NUMBER : -CUSTOM_NUMBER;
    } else if (!right) {
        diff = isSortedDescending ? -CUSTOM_NUMBER : CUSTOM_NUMBER;
    } else {
        const pre = formartNumber(
            Number(left[field as keyof T]),
            isSortedDescending ? -CUSTOM_NUMBER : CUSTOM_NUMBER
        );
        const cur = formartNumber(
            Number(right[field as keyof T]),
            isSortedDescending ? CUSTOM_NUMBER : -CUSTOM_NUMBER
        );
        diff = pre - cur;
    }
    return diff;
}

export function sortItemsByDiffNumWhenCompare2Records<T>(
    a: (T | null)[],
    b: (T | null)[],
    sortBy: keyof T,
    isSortedDescending: boolean = true
): number {
    const pre = computeNumericDiff<T>(a, sortBy, isSortedDescending);
    const cur = computeNumericDiff<T>(b, sortBy, isSortedDescending);
    return (isSortedDescending ? pre < cur : pre > cur) ? 1 : -1;
}

export function sortItems<T>(
    a: (T | null)[],
    b: (T | null)[],
    sortBy: keyof T,
    isSortedDescending: boolean = true
): number {
    const defaultInvalidValue = 0;
    const pre = a[0] ? formartNumber(a[0][sortBy]) : defaultInvalidValue;
    const cur = b[0] ? formartNumber(b[0][sortBy]) : defaultInvalidValue;
    return (isSortedDescending ? pre < cur : pre > cur) ? 1 : -1;
}
