import React from "react";
import authUrl from "../Utils/AuthUrl";

export enum AuthUrlType {
    image = "src",
    link = "href",
}

export const AuthHoc = (MyComponent: any, type: AuthUrlType) => {
    return class Button extends React.Component<any, any> {
        constructor(prop: any) {
            super(prop);
            this.state = {
                url: "",
            };
        }

        render() {
            const newP = { ...this.props, ...{ [type]: this.state.url } };
            return <MyComponent {...newP} />;
        }

        componentDidMount(): void {
            this.getAuthUrl(this.props.url);
        }

        componentWillUnmount(): void {
            const { url } = this.state;
            if (url) {
                URL.revokeObjectURL(url);
            }
        }

        async componentWillReceiveProps(nextProps: any) {
            if (nextProps.url !== this.props.url) {
                this.getAuthUrl(nextProps.url);
            }
        }

        getAuthUrl = async (url: string) => {
            const newUrl = await authUrl(url);
            this.setState({ url: newUrl });
        };
    };
};
