import React from "react";
import _ from "lodash";
import {
    getColorByIndex,
    optimizeDecimalPlaces,
    trimAll,
} from "../../Utils/Utils";
import { RecordDetail, Workspaces } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    TableList,
    IGroupedBarChartData,
    VerticalBarChart,
} from "../../Controls";
import { KEY_SEPARATOR } from "../CustForm/CustFormDataInterface";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Label,
} from "@fluentui/react";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";
import { FieldMetrics } from "./QueryFieldDataInterface";
import { ColumsType, Options, getColumns } from "./OverviewConfig";

const CHART_DEFAULT_FIELD = ["Accuracy", "TrainingLatency", "InferenceLatency"];

const enum MetricKeys {
    DatasetName,
    queryCount_TrainingDocCount,
    randomSeed_TrainingDocIndex,
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: FieldMetrics;
}

interface IState extends ICommonState<any> {
    dataItems: IDataItem[];
    metricsData: Map<string, FieldMetrics[]>;
    avgMetricsData: Map<string, FieldMetrics[]>;
    finalAvgMetricsData: Map<string, FieldMetrics[]>;
}

interface IProps extends ICommonProps {
    imageViewDeepLinkHandler?: (key: string, linkData: any) => void;
}

const DATA_INDEX_OF_DATASETS = 1;
export class Overview extends CommonView<IProps, IState, FieldMetrics> {
    private optionKey1 = Options[this.workSpace].option1;
    private optionKey2 = Options[this.workSpace].option2;
    constructor(props: IProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            dataItems: [],
            metricsData: new Map(),
            avgMetricsData: new Map(),
            finalAvgMetricsData: new Map(),
        };
    }

    public render() {
        const { viewType } = this.state;
        return (
            <div className="overview">
                {(viewType === "Table" ||
                    this.workSpace === Workspaces.CustomForm) && (
                    <>{this._renderAsTable()}</>
                )}
                {viewType === "Chart" &&
                    this.workSpace === Workspaces.QueryField && (
                        <>
                            <FullScreen>
                                <div
                                    style={{
                                        overflow: "hidden auto",
                                        height: "100%",
                                    }}
                                >
                                    {this._renderAsChart()}
                                </div>
                            </FullScreen>
                        </>
                    )}
            </div>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("case_summary.json");
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (!_.isEqual(prevState.dataItems, this.state.dataItems)) {
            const metricsData = this._prepareRenderData();
            const avgMetricsData = this._prepareAverageDataByKeys(metricsData, [
                MetricKeys.DatasetName,
                MetricKeys.queryCount_TrainingDocCount,
            ]);
            const finalAvgMetricsData = this._prepareAverageData(metricsData, [
                MetricKeys.queryCount_TrainingDocCount,
            ]);

            this.setState({
                metricsData: metricsData,
                avgMetricsData: avgMetricsData,
                finalAvgMetricsData: finalAvgMetricsData,
            });
        }
    }

    private _renderAsTable() {
        const { records } = this.props;
        const { metricsData, avgMetricsData, finalAvgMetricsData } = this.state;

        if (this.state.dataItems.length > 0) {
            const nonNanCount = Array.from(finalAvgMetricsData.keys())
                .map((k) => k.split("|")[DATA_INDEX_OF_DATASETS])
                .join("/");

            return (
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        <Label>{`total number of Non-NaN dataset: ${nonNanCount}`}</Label>
                        <TableList<FieldMetrics>
                            key={`Overview_0`}
                            evalDataCount={records.length}
                            evalData={finalAvgMetricsData}
                            columns={getColumns(ColumsType.final_avg)}
                            tableTitle="Final Average Data"
                            downloadTableTitle="Final_Average_Data"
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                        <br />
                        <TableList<FieldMetrics>
                            key={`Overview_1`}
                            evalDataCount={records.length}
                            evalData={avgMetricsData}
                            columns={getColumns(ColumsType.avg)}
                            tableTitle="Average Data"
                            downloadTableTitle="Average_Data"
                            onItemInvoked={this._onItemInvoked}
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                        <br />
                        <TableList<FieldMetrics>
                            key={`Overview_2`}
                            evalDataCount={records.length}
                            evalData={metricsData}
                            columns={getColumns(ColumsType.normal)}
                            downloadTableTitle="Overview"
                            onItemInvoked={this._onItemInvoked}
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                    </div>
                </FullScreen>
            );
        } else {
            return <NoDataTip>No Metrics Generated</NoDataTip>;
        }
    }

    private _prepareRenderData(): Map<string, FieldMetrics[]> {
        // find all datasets
        const { records } = this.props;
        const { dataItems } = this.state;
        const dataMap = new Map<string, FieldMetrics[]>();

        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0
        ) {
            const details = records.flatMap((r) => r.getDetails());
            const compositeKeyArr = Array.from(
                new Set(
                    details.map((d) => {
                        const argu = d.getRawProp<any>("arguments");
                        return `${d.dataset.displayName}${KEY_SEPARATOR}${
                            argu[this.optionKey1]
                        }${KEY_SEPARATOR}${argu[this.optionKey2]}`;
                    })
                )
            );

            compositeKeyArr.forEach((compositeKey) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const datasetName = keys[0];
                const items = records.map((_, recordIndex) => {
                    const item = dataItems.find((item) => {
                        const argu =
                            item.recordDetail.getRawProp<any>("arguments");
                        const itemArgu = `${
                            item.recordDetail.dataset.displayName
                        }${KEY_SEPARATOR}${
                            argu[this.optionKey1]
                        }${KEY_SEPARATOR}${argu[this.optionKey2]}`;
                        return (
                            item.recordIndex === recordIndex &&
                            itemArgu === compositeKey
                        );
                    });

                    return item;
                });

                const metrics = items.map((item) => {
                    let fieldMetrics: FieldMetrics | undefined;
                    if (item !== undefined) {
                        fieldMetrics = item.metrics;
                        if (fieldMetrics !== null) {
                            const argu =
                                item.recordDetail.getRawProp<any>("arguments");

                            fieldMetrics[this.optionKey1] =
                                argu[this.optionKey1];
                            fieldMetrics[this.optionKey2] =
                                argu[this.optionKey2];
                            fieldMetrics.datasetFullName =
                                item.recordDetail.dataset.name;
                            fieldMetrics.Accuracy = optimizeDecimalPlaces(
                                fieldMetrics.Accuracy,
                                4
                            );
                            fieldMetrics.TrainingLatency =
                                optimizeDecimalPlaces(
                                    fieldMetrics.TrainingLatency,
                                    3
                                );
                            fieldMetrics.InferenceLatency =
                                optimizeDecimalPlaces(
                                    fieldMetrics.InferenceLatency,
                                    3
                                );
                        } else {
                            fieldMetrics = {
                                datasetFullName: datasetName,
                                Accuracy: NaN,
                                TrainingLatency: NaN,
                                InferenceLatency: NaN,
                                [this.optionKey1]: NaN,
                                [this.optionKey2]: NaN,
                            };
                        }
                    } else {
                        fieldMetrics = {
                            datasetFullName: "",
                            Accuracy: NaN,
                            TrainingLatency: NaN,
                            InferenceLatency: NaN,
                            [this.optionKey1]: NaN,
                            [this.optionKey2]: NaN,
                        };
                    }

                    return fieldMetrics;
                });

                dataMap.set(compositeKey, metrics);
            });
        }

        return dataMap;
    }

    private _prepareAverageDataByKeys(
        dataMap: Map<string, FieldMetrics[]>,
        keysArr: Array<MetricKeys>
    ): Map<string, FieldMetrics[]> {
        const avgMap = new Map<string, FieldMetrics[]>();
        if (dataMap.size > 0) {
            const { records } = this.props;
            const allMetrics = Array.from(dataMap);
            const avgKeySet = new Set<string>();
            allMetrics.forEach(([compositeKey, _]) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const avgKey = keysArr.map((i) => keys[i]).join(KEY_SEPARATOR);
                !avgKeySet.has(avgKey) && avgKeySet.add(avgKey);
            });

            avgKeySet.forEach((targetAvgKey) => {
                const targetMetrics = allMetrics.filter(([compositeKey, _]) => {
                    const keys = compositeKey.split(KEY_SEPARATOR);
                    const avgKey = keysArr
                        .map((i) => keys[i])
                        .join(KEY_SEPARATOR);
                    return avgKey === targetAvgKey;
                });

                if (targetMetrics && targetMetrics.length > 0) {
                    const defaultCompositeKey = targetMetrics[0][0];
                    const keys = defaultCompositeKey.split(KEY_SEPARATOR);

                    const calcCountArr = new Array<number>(records.length).fill(
                        0
                    );

                    const calcMetricsArr = Array.from(
                        { length: records.length },
                        () => {
                            return {
                                datasetFullName:
                                    keys && keys.length > 0 ? keys[0] : "",
                                [this.optionKey1]:
                                    keys && keys.length > 1
                                        ? Number(keys[1])
                                        : NaN,
                                [this.optionKey2]:
                                    keys && keys.length > 2
                                        ? Number(keys[2])
                                        : NaN,
                                Accuracy: NaN,
                                TrainingLatency: NaN,
                                InferenceLatency: NaN,
                            } as FieldMetrics;
                        }
                    );

                    targetMetrics.forEach(([_, metrics]) => {
                        metrics.forEach((metric, index) => {
                            if (metric) {
                                calcMetricsArr[index].Accuracy = Number.isNaN(
                                    calcMetricsArr[index].Accuracy
                                )
                                    ? metric.Accuracy
                                    : calcMetricsArr[index].Accuracy +
                                      metric.Accuracy;

                                calcMetricsArr[index].TrainingLatency =
                                    Number.isNaN(
                                        calcMetricsArr[index].TrainingLatency
                                    )
                                        ? metric.TrainingLatency
                                        : calcMetricsArr[index]
                                              .TrainingLatency +
                                          metric.TrainingLatency;

                                calcMetricsArr[index].InferenceLatency =
                                    Number.isNaN(
                                        calcMetricsArr[index].InferenceLatency
                                    )
                                        ? metric.InferenceLatency
                                        : calcMetricsArr[index]
                                              .InferenceLatency +
                                          metric.InferenceLatency;

                                calcCountArr[index]++;
                            }
                        });
                    });

                    const avgMetrics = calcCountArr.map((calcCount, index) => {
                        const avgMetric = calcMetricsArr[index];
                        if (calcCount !== 0) {
                            if (!Number.isNaN(avgMetric.Accuracy)) {
                                avgMetric.Accuracy = optimizeDecimalPlaces(
                                    avgMetric.Accuracy / calcCount,
                                    4
                                );
                            }

                            if (!Number.isNaN(avgMetric.TrainingLatency)) {
                                avgMetric.TrainingLatency =
                                    optimizeDecimalPlaces(
                                        avgMetric.TrainingLatency / calcCount,
                                        3
                                    );
                            }

                            if (!Number.isNaN(avgMetric.InferenceLatency)) {
                                avgMetric.InferenceLatency =
                                    optimizeDecimalPlaces(
                                        avgMetric.InferenceLatency / calcCount,
                                        3
                                    );
                            }
                        }

                        return avgMetric;
                    });

                    avgMap.set(targetAvgKey, avgMetrics);
                }
            });
        }

        return avgMap;
    }

    private _prepareAverageData(
        dataMap: Map<string, FieldMetrics[]>,
        keysArr: Array<MetricKeys>
    ): Map<string, FieldMetrics[]> {
        const DATA_INDEX_OF_ACCURACY = 1;
        const avgMap = new Map<string, FieldMetrics[]>();
        if (dataMap.size > 0) {
            const { records } = this.props;
            const allMetrics = Array.from(dataMap);
            const avgKeySet = new Set<string>();
            allMetrics.forEach(([compositeKey, _]) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const avgKey = keysArr.map((i) => keys[i]).join(KEY_SEPARATOR);
                !avgKeySet.has(avgKey) && avgKeySet.add(avgKey);
            });

            avgKeySet.forEach((targetAvgKey) => {
                const targetMetrics = allMetrics.filter(([compositeKey, _]) => {
                    const keys = compositeKey.split(KEY_SEPARATOR);
                    const avgKey = keysArr
                        .map((i) => keys[i])
                        .join(KEY_SEPARATOR);
                    return avgKey === targetAvgKey;
                });

                if (targetMetrics && targetMetrics.length > 0) {
                    const metrics = targetMetrics.map((v) => v[1]);
                    const countArr: number[] = [];
                    const avgMetrics = records.map((__, index) => {
                        const metrs = metrics.map((m) => [
                            m[index][this.optionKey1],
                            m[index].Accuracy,
                        ]);
                        countArr.push(metrs.length);
                        const filteredMetrs = metrs.filter(
                            (m) => !isNaN(m[DATA_INDEX_OF_ACCURACY])
                        );
                        if (filteredMetrs && filteredMetrs.length > 0) {
                            const accuracyArr = filteredMetrs.map(
                                (m) => m[DATA_INDEX_OF_ACCURACY]
                            );
                            const accuracySum = _.sum(accuracyArr);
                            return {
                                [this.optionKey1]: Number(targetAvgKey),
                                Accuracy: accuracySum / metrs.length,
                            } as FieldMetrics;
                        } else {
                            return {
                                [this.optionKey1]: Number(targetAvgKey),
                                Accuracy: NaN,
                            } as FieldMetrics;
                        }
                    });

                    const compositeKey = `${targetAvgKey}|${countArr.join(
                        "-"
                    )}`;

                    avgMap.set(compositeKey, avgMetrics);
                }
            });
        }

        return avgMap;
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { imageViewDeepLinkHandler } = this.props;
        if (imageViewDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as FieldMetrics[];
                if (fieldMetricsArr?.length > 0) {
                    let linkData: { [key: string]: string | undefined } = {};
                    const metric = fieldMetricsArr.find((m) => m);
                    if (metric) {
                        linkData.toSelectLanguage =
                            metric.datasetFullName.split(":")[1];
                        linkData.toSelectOption1 =
                            metric[this.optionKey1].toString();
                        linkData.toSelectOption2 =
                            metric[this.optionKey2].toString();

                        imageViewDeepLinkHandler(
                            this.workSpace === Workspaces.QueryField
                                ? "overviewQueryFieldDetailMetrics"
                                : "overviewCustFormDetailMetrics",
                            linkData
                        );
                    }
                }
            }
        }
    }

    _renderAsChart = () => {
        const jsxElementArr = this.getjsxElementArr();
        const averagejsxElementArr = this.getAveragejsxElementArr();
        if (jsxElementArr.length > 0 || averagejsxElementArr.length > 0) {
            return (
                <>
                    {averagejsxElementArr &&
                        averagejsxElementArr.length > 0 && (
                            <div>{averagejsxElementArr}</div>
                        )}
                    {jsxElementArr && jsxElementArr.length > 0 && (
                        <div>{jsxElementArr}</div>
                    )}
                </>
            );
        } else {
            return <NoDataTip>No Metrics Generated</NoDataTip>;
        }
    };

    getAveragejsxElementArr = () => {
        const { finalAvgMetricsData } = this.state;
        const jsxElementArr: any[] = [];
        if (this.state.dataItems.length > 0) {
            const fields = Array.from(finalAvgMetricsData.keys()).map(
                (key) => key
            );
            const metrics: any = {};
            fields.forEach((field) => {
                const values = finalAvgMetricsData
                    .get(field)
                    ?.map((m) => m["Accuracy"]);
                metrics[field.split("|")[0]] = values;
            });

            const subCharts = this._renderChartByMetricDefinition(
                trimAll("queryCount"),
                fields.map((field) => field.split("|")[0]),
                metrics,
                "QueryCount"
            );

            if (subCharts.length > 0) {
                const el = (
                    <div>
                        <div key={`barChartMetrics_container`}>
                            <Label>Average Accuracy</Label>
                            <DocumentCard
                                className="overview__card"
                                key={`barChartMetrics`}
                                type={DocumentCardType.compact}
                            >
                                {subCharts}
                            </DocumentCard>
                        </div>
                    </div>
                );
                jsxElementArr.push(el);
            }
        }
        return jsxElementArr;
    };

    getjsxElementArr = () => {
        const { metricsData } = this.state;
        const jsxElementArr: any[] = [];
        if (this.state.dataItems.length > 0) {
            let index = 0;
            metricsData.forEach((metricView, datasetname) => {
                const metrics: any = {};
                CHART_DEFAULT_FIELD.forEach((field) => {
                    const values = metricView.map((m) => m[field]);
                    metrics[field] = values;
                });

                const queryCount = metricView
                    .map((m) => m["queryCount"])
                    .join("_");
                const randomSeed = metricView
                    .map((m) => m["randomSeed"])
                    .join("_");

                const key = `queryCount_${queryCount}_randomSeed_${randomSeed}`;

                const subCharts = this._renderChartByMetricDefinition(
                    trimAll(`${key}__${index}`),
                    CHART_DEFAULT_FIELD,
                    metrics
                );

                if (subCharts.length > 0) {
                    const el = (
                        <div>
                            <Label>{datasetname}</Label>
                            <div key={`barChartMetrics_container`}>
                                <DocumentCard
                                    className="overview__card"
                                    key={`barChartMetrics`}
                                    type={DocumentCardType.compact}
                                >
                                    {subCharts}
                                </DocumentCard>
                            </div>
                        </div>
                    );
                    jsxElementArr.push(el);
                }
                index++;
            });
        }
        return jsxElementArr;
    };

    private _renderChartByMetricDefinition(
        id: string,
        fields: string[],
        metrics: any,
        xAxisName?: string
    ) {
        let subCharts: JSX.Element[] = [];
        if (!!metrics) {
            const chartData: IGroupedBarChartData[] = [];
            fields.forEach((prop: any) => {
                const values = metrics[prop] as any[];
                if (values && values.some((value) => !isNaN(value))) {
                    const table = values.map((value, recordIndex) => {
                        return {
                            key: `metricDef_${id}_${recordIndex}`,
                            data: value ? (value as number) : 0,
                            color: getColorByIndex(recordIndex),
                            legend: this.props.records[recordIndex].name,
                        };
                    });

                    chartData.push({
                        name: prop,
                        series: table,
                    });
                }
            });

            if (chartData && chartData.length > 0) {
                const subChart = (
                    <DocumentCardDetails key={`barChartMetrics_${id}`}>
                        {this._renderSingleChart(id, chartData, id, xAxisName)}
                    </DocumentCardDetails>
                );

                subCharts.push(subChart);
            }
        }

        return subCharts;
    }

    private _renderSingleChart(
        id: string,
        chartData: IGroupedBarChartData[],
        title: string,
        xAxisName?: string
    ) {
        return (
            <>
                <DocumentCardTitle className="overview__title" title={title} />
                <VerticalBarChart
                    id={id}
                    data={chartData}
                    height={400}
                    xAxisName={xAxisName}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </>
        );
    }
}
