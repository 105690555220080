import React from "react";

import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import _ from "lodash";
import {
    ColumnValueType,
    NumContrastPolicy,
    NumberFormat,
    TableColumn,
    TableList,
} from "../../Controls/TableList";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { Label } from "@fluentui/react";
import { FullScreen } from "../Common/FullScreen";

// prettier-ignore
const FIGURE_COLUMNS: TableColumn[] = [
    { key: "class",        name: "class",         fieldName: "class",          valueType: ColumnValueType.String, minWidth: 120, maxWidth: 160, isResizable: true, distinctStr: true },
    { key: "num_gt",       name: "num_gt",        fieldName: "num_gt",         valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "num_pred",     name: "num_pred",      fieldName: "num_pred",       valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "matched_pred", name: "matched_pred",  fieldName: "matched_pred",   valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "matched_gt",   name: "matched_gt",    fieldName: "matched_gt",     valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "precision",    name: "precision%",    fieldName: "precision",      valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 , numberFormat:NumberFormat.Percentage},
    { key: "recall",       name: "recall%",       fieldName: "recall",         valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 , numberFormat:NumberFormat.Percentage},
    { key: "f1",           name: "f1%",           fieldName: "f1",             valueType: ColumnValueType.Number, minWidth: 70,  maxWidth: 200, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 , numberFormat:NumberFormat.Percentage},
    ];

interface FigureMetrics {
    [key: string]: any;
}

interface IKvpDataItem {
    data?: IDataItem;
    fieldMetrics: FigureMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: FigureMetrics;
}

interface IState extends ICommonState<FigureMetrics> {
    dataItems: IDataItem[];
}

export class OcrFigureOverview extends CommonView<
    ICommonProps,
    IState,
    FigureMetrics
> {
    private exportCrossData?: any;
    constructor(props: ICommonProps) {
        super(props);

        this.state = {
            dataItems: [],
            matchDatasetVersion: true,
        };
    }

    public render() {
        return (
            <div className="overview">
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderAsCrossDatasetsTable()}
                        {this._renderAsTable()}
                    </div>
                </FullScreen>
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: FIGURE_COLUMNS,
                selectedItems: FIGURE_COLUMNS.filter(
                    (c) => !c.key.startsWith("matched_")
                ).map((c) => c.key),
                saveKey: `${Workspaces.OcrFigure}_${Typename.OverviewMetrics}`,
            })
        );
    }
    exportAction = () => {
        const exportD = _.cloneDeep(this.exportData);
        if (this.exportCrossData) {
            exportD.splice(0, 0, [
                "CrossDatasetMetrics",
                Array.from(this.exportCrossData.values()),
            ]);
        }
        exportOverviewListData(
            exportD,
            FIGURE_COLUMNS,
            `OcrFigureOverviewMetrics`
        );
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ): void {
        super.componentDidUpdate(prevProps, prevState);
    }

    queryMetricsResult() {
        this._queryMetricsResult("basic_metrics.json");
    }

    private _renderAsTable() {
        const { selectedColumns } = this.state;

        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderTableData();

            const showColumns: TableColumn[] = FIGURE_COLUMNS.filter(
                (value) =>
                    selectedColumns?.findIndex((col) => col === value.key) !==
                    -1
            );

            this.exportData = [];

            return data.map(([datasetName, recordMetrics], index) => {
                const tableData = recordMetrics.map(
                    ([_, fieldMetricsItems]) => {
                        return fieldMetricsItems.map((fieldMetricsItem) => {
                            const fieldMetrics = fieldMetricsItem.fieldMetrics;
                            // if (fieldMetrics) {
                            //     fieldMetrics.DatasetName = datasetName;
                            // }
                            return fieldMetrics;
                        });
                    }
                );
                this.exportData.push([datasetName, tableData]);

                const evalData = new Map();
                tableData.forEach((value, index) => {
                    evalData.set(index, value);
                });

                return (
                    <>
                        <Label>{datasetName}</Label>
                        <TableList
                            evalData={evalData}
                            tableTitle={datasetName}
                            downloadTableTitle={datasetName}
                            evalDataCount={this.props.records.length}
                            columns={showColumns}
                            disableFreezeHeader
                            hideHeader
                            verticalFill={false}
                            disableSorting
                            onItemInvoked={(item: any) => {
                                let subType = undefined;
                                if (item.length === 2) {
                                    subType = item[1].map(
                                        (value: any) => value && value.class
                                    )[0];
                                }
                                this.props.onItemInvoked &&
                                    this.props.onItemInvoked([
                                        datasetName,
                                        subType,
                                    ]);
                            }}
                        />
                    </>
                );
            });
        }
    }

    private _prepareRenderTableData() {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(datasets.map((dataset) => dataset.name))
        );

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.name === datasetName
                    );
                });
                return item;
            });

            let entities = Array.from(
                new Set(
                    items
                        .filter((item) => item)
                        .flatMap((item) => Object.keys(item?.metrics!))
                )
            );

            entities = this.classSort(entities);

            const groupData = entities.map((field) => {
                // retrieve field data
                const metricsItems = items
                    .map((item) => {
                        let fieldMetrics: any = {
                            class: field,
                            num_gt: NaN,
                            num_pred: NaN,
                            matched_pred: NaN,
                            matched_gt: NaN,
                            f1: NaN,
                            precision: NaN,
                            recall: NaN,
                            iou_threshold: NaN,
                        };
                        const metric = item?.metrics;
                        if (metric && metric[field]) {
                            fieldMetrics = metric[field];
                            fieldMetrics["class"] = field;
                        }

                        return {
                            data: item,
                            fieldMetrics: fieldMetrics,
                        } as IKvpDataItem;
                    })
                    .filter((metricsItem) => !_.isEmpty(metricsItem));
                return [field, metricsItems] as [string, IKvpDataItem[]];
            });

            return [datasetName, groupData] as [
                string,
                [string, IKvpDataItem[]][]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _renderAsCrossDatasetsTable() {
        const { selectedColumns, dataItems } = this.state;
        if (dataItems && dataItems.length > 0) {
            const showColumns: TableColumn[] = FIGURE_COLUMNS.filter(
                (value) =>
                    selectedColumns?.findIndex((col) => col === value.key) !==
                    -1
            );

            const data = this._prepareRenderCrossDatasetsTableData();

            this.exportCrossData = data;

            return (
                <>
                    <Label>
                        <h2>Cross dataset metrics</h2>
                    </Label>
                    <TableList
                        evalData={data}
                        evalDataCount={this.props.records.length}
                        columns={showColumns}
                        downloadTableTitle="Cross_dataset_metrics"
                        disableFreezeHeader
                        hideHeader
                        disableSorting
                        verticalFill={false}
                    />
                </>
            );
        }
        return <></>;
    }

    private _prepareRenderCrossDatasetsTableData() {
        let entities = Array.from(
            new Set(
                this.state.dataItems
                    .map((item) => item["metrics"])
                    .filter((item) => item)
                    .flatMap((v) => Object.keys(v))
            )
        );
        entities = this.classSort(entities);
        const dataItems = _.cloneDeep(this.state.dataItems);
        const evalData = new Map();
        entities.forEach((entity) => {
            const classM = this.props.records.map((_, recordIndex) => {
                const items = dataItems.filter((item) => {
                    return item.recordIndex === recordIndex;
                });

                const entityM = items
                    .map((item) => item["metrics"])
                    .filter((m) => m)
                    .map((m) => m[entity])
                    .filter((v) => v);
                const entityMReduce =
                    entityM.length !== 0
                        ? entityM.reduce((per: any, cur: any) => {
                              const keys = Object.keys(per);
                              for (const key of keys) {
                                  if (key !== "class") {
                                      per[key] += cur[key] ?? 0;
                                  }
                              }
                              return per;
                          })
                        : [];

                const aggregatedResult = this.calculateMetrics(entityMReduce);
                aggregatedResult.class = entity;
                return aggregatedResult;
            });

            evalData.set(entity, classM);
        });

        return evalData;
    }

    calculateMetrics = (metric: any) => {
        metric["recall"] =
            metric["num_gt"] === 0
                ? 0
                : metric["matched_gt"] / metric["num_gt"];
        metric["precision"] =
            metric["num_pred"] === 0
                ? 0
                : metric["matched_pred"] / metric["num_pred"];
        metric["f1"] =
            metric["recall"] + metric["precision"] === 0
                ? 0
                : (2.0 * metric["recall"] * metric["precision"]) /
                  (metric["recall"] + metric["precision"]);

        return metric;
    };

    classSort = (names: string[]) => {
        const normalSort = [
            "All_Type",
            "Diagram",
            "Picture",
            "Logo",
            "False_Alarm",
            "Icon",
            "Other",
            "Ignored",
        ];
        const interNames = _.intersection(normalSort, names);
        const diffNames = _.difference(names, normalSort);

        return [...interNames, ...diffNames];
    };
}
