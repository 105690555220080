const CHECKBOX_DETECTION_METRIC_DEFINITION: CheckboxMetricDefinition = {
    name: "CheckboxCOCOMetrics",
    displayName: "Checkbox Detection",
    props: [
        {
            name: "nococo_check_0.50_all",
            displayName: "real precision/recall/f1 on iou 0.5",
            props: ["mAP", "mAR", "mAF1", 'square', 'round', 'underline', 'grid', 'bracket', 'toggle', 'circlable', 'other']
        },
        {
            name: "nococo_box_0.50_all",
            displayName: "real (box only) precision/recall/f1 on iou 0.5",
            props: ["mAP", "mAR", "mAF1", 'square', 'round', 'underline', 'grid', 'bracket', 'toggle', 'circlable', 'other'],
        },
        {
            name: "0.50_all",
            displayName: "iou 0.5",
            props: ["mAP", "mAR", "mAF1"],
        },
        {
            name: "0.50:0.95_all",
            displayName: "iou [.5:0.05:.95]",
            props: ["mAP", "mAR", "mAF1"],
        },
        {
            name: "0.50_small",
            displayName: "iou 0.50 small object",
            props: ["mAP", "mAR", "mAF1"],
        },
        {
            name: "0.50_medium",
            displayName: "iou 0.50 medium object",
            props: ["mAP", "mAR", "mAF1"],
        },
        {
            name: "0.50_large",
            displayName: "iou 0.50 large object",
            props: ["mAP", "mAR", "mAF1"],
        }
    ],
};

export const CHECKBOX_METRICS_DEFINITIONS = [CHECKBOX_DETECTION_METRIC_DEFINITION];

export interface CheckboxMetricDefinition {
    name: string;
    displayName: string;
    props: { name: string; displayName?: string; props: string[] }[];
}