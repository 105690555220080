import React, { Component } from "react";
import * as d3 from "d3";
import {
    DocumentCardDetails,
    DocumentCardTitle,
    getTheme,
    Stack,
} from "@fluentui/react";
import "./LineChartWithXScaleBand.scss";
import { Consumer } from "../../Layout";

export interface ILineChartDataPoint {
    x: number | Date;
    y: number;
    name: string;
}

export interface ILineChartData {
    legend: string;
    color: string;
    data: ILineChartDataPoint[];
}

export interface IGroupedLineChartData {
    chartTitle: string;
    lineChartData: ILineChartData[];
}

const xValue = (d: ILineChartDataPoint): string =>
    `${d.name}|${d.x instanceof Date ? d.x.getTime() : d.x}`;
const yValue = (d: ILineChartDataPoint): number => d.y;

const CHART_MARGIN = { top: 30, right: 20, bottom: 250, left: 50 };
const NAME_PARAGRAPH_LINE_HEIGHT = 14;

interface IProps {
    id: string;
    data: IGroupedLineChartData;
    height: number;
    width?: number;
    keyIndex?: number;
    yMin?: number;
    yMax?: number;
    ySpecifier?: string;
    isDarkTheme?: boolean;
}
const theme = getTheme();
export class LineChartWithXScaleBand extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);

        this.state = {
            legendPoints: [],
            visibleCalloutId: -1,
        };
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }

    public render() {
        const { data, id, keyIndex } = this.props;
        return (
            <DocumentCardDetails
                key={`document_${keyIndex}`}
                className={"documentcard"}
                styles={{ root: { justifyContent: "none" } }}
            >
                <DocumentCardTitle
                    className="overview_lineChart_title"
                    title={`${data.chartTitle}`}
                />
                <Stack className="overview_detail" horizontal>
                    <div className="seriesDesc">
                        {this.props.data.lineChartData.map((series, index) => {
                            return (
                                <div key={`legend_${index}`} className="desc">
                                    <i
                                        style={{
                                            backgroundColor: series.color,
                                        }}
                                    ></i>
                                    <span
                                        style={{
                                            color: this.props.isDarkTheme
                                                ? theme.palette.white
                                                : theme.palette.black,
                                        }}
                                    >
                                        {series.legend}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </Stack>
                <Stack style={{ backgroundColor: "transparent" }}>
                    <Consumer>
                        {(value) => {
                            return (
                                <div
                                    className="drawingContainer"
                                    style={{ color: value ? "white" : "black" }}
                                >
                                    <div id={id} className="drawingBoard"></div>
                                </div>
                            );
                        }}
                    </Consumer>
                </Stack>
            </DocumentCardDetails>
        );
    }
    private _drawChart(): void {
        //#region Define & calculate basic variable
        const { id, yMax, yMin, ySpecifier } = this.props;
        const exactXfromKey = this._exactXfromKey;
        const height = this.props.height ?? 500;
        const lineChartData = this.props.data.lineChartData;
        const lineCount = lineChartData.length;
        const translateMarginLeftAndTop = `translate(${CHART_MARGIN.left},${CHART_MARGIN.top})`;

        let drawingBoard = document.getElementById(id);
        if (drawingBoard && drawingBoard.hasChildNodes()) {
            drawingBoard.innerHTML = "";
        }

        let width = this.props.width;
        if (width === undefined) {
            const container = drawingBoard?.parentElement;
            if (container && container?.offsetWidth) {
                width = container.offsetWidth - 1;
            } else {
                width = CHART_MARGIN.left + CHART_MARGIN.right;
            }
        }

        const innerWidth = width - CHART_MARGIN.left - CHART_MARGIN.right,
            innerHeight = height - CHART_MARGIN.top - CHART_MARGIN.bottom;

        const lineDataArr: ILineChartDataPoint[] = lineChartData.flatMap(
            (item) => {
                return item.data;
            }
        );

        const minY = yMin ?? (d3.min(lineDataArr, yValue) || 0);
        const maxY = yMax ?? (d3.max(lineDataArr, yValue) || 0);

        const xKeysArr = lineDataArr
            .sort((valL, valR) => {
                const x = valL.x instanceof Date ? valL.x.getTime() : valL.x;
                const y = valR.x instanceof Date ? valR.x.getTime() : valR.x;
                return x - y;
            })
            .map((val: ILineChartDataPoint) => {
                const xVal = val.x instanceof Date ? val.x.getTime() : val.x;
                return `${val.name}|${xVal}`;
            });
        //#endregion

        //#region d3 drawing
        const scale_x = d3.scaleBand().domain(xKeysArr).range([0, innerWidth]);
        const scale_y = d3
            .scaleLinear()
            .domain([maxY, minY])
            .range([0, innerHeight]);

        const line = d3
            .line<ILineChartDataPoint>()
            .x((d: ILineChartDataPoint) => {
                return scale_x(xValue(d)) || 0;
            })
            .y((d: ILineChartDataPoint) => {
                return scale_y(yValue(d) || 0);
            });

        const tip = d3
            .select("body")
            .append("div")
            .attr("class", "tip")
            .style("display", "none");

        const svg = d3
            .select("#" + id)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        //drawing background grid
        const xInner = d3
            .axisBottom(scale_x)
            .tickSize(-height)
            .tickFormat(() => {
                return "";
            });

        const yInner = d3
            .axisLeft(scale_y)
            .tickSize(-width)
            .tickFormat(() => {
                return "";
            });

        svg.append("g")
            .attr(
                "transform",
                `translate(${CHART_MARGIN.left},${height - CHART_MARGIN.top})`
            )
            .style("opacity", 0.08)
            .call(xInner);

        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .style("opacity", 0.08)
            .call(yInner);

        //drawing X/Y ray.
        const xAxis = d3.axisBottom(scale_x).tickFormat((val) => {
            return exactXfromKey(val);
        });

        const yAxis = d3.axisLeft(scale_y).ticks(4, ySpecifier);

        svg.append("g")
            .attr(
                "transform",
                `translate(${CHART_MARGIN.left},${height - CHART_MARGIN.bottom
                })`
            )
            .call(xAxis)
            .selectAll("text")
            .text("");

        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .call(yAxis);

        //drawing line
        for (let i = 0; i < lineCount; i++) {
            svg.append("g")
                .data(lineChartData)
                .attr("transform", translateMarginLeftAndTop)
                .append("path")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr("stroke", function () {
                    return lineChartData[i].color;
                })
                .attr("d", function () {
                    return line(lineChartData[i].data) || "";
                });
        }

        //drawing point
        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .selectAll("dot")
            .attr("transform", translateMarginLeftAndTop)
            .data(lineDataArr)
            .enter()
            .append("circle")
            .style("fill", this.props.isDarkTheme
                ? theme.palette.white
                : theme.palette.black,)
            .attr("r", 3)
            .attr("cx", function (d) {
                return scale_x(xValue(d)) || 0;
            })
            .attr("cy", function (d) {
                return scale_y(d.y);
            })
            .on("mouseover", function (e: any, d: any) {
                const event = e as MouseEvent;
                const data = d as ILineChartDataPoint;
                tip.style("top", event.pageY + "px")
                    .style("left", event.pageX + "px")
                    .style("background-color", lineChartData[0].color)
                    .style("display", "block")
                    .html(
                        `${data.name}<br/>${data.y}<br/>${data.x instanceof Date
                            ? d3.timeFormat("%y-%m-%d %H:%M")(data.x)
                            : data.x
                        }`
                    );
            })
            .on("mouseout", function (d) {
                tip.style("display", "none");
            });

        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .selectAll("dot")
            .attr("transform", translateMarginLeftAndTop)
            .data(xKeysArr)
            .enter()
            .append("g")
            .attr("transform", function (d) {
                return `translate(${(scale_x(d) ?? 0) + NAME_PARAGRAPH_LINE_HEIGHT / 2
                    },${innerHeight + 10})`;
            })
            .append("foreignObject")
            .attr("transform", "rotate(80, 0, 0)")
            .attr("font-size", 10)
            .attr("width", 200)
            .attr("height", NAME_PARAGRAPH_LINE_HEIGHT)
            .html(function (k) {
                return exactXfromKey(k);
            });
        //#endregion
    }

    private _exactXfromKey(key: string): string {
        const valArr = key.split("|");
        return valArr.length > 0 ? valArr[0] : key;
    }
}
