import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import {
    DatasetSet,
    IDataItem,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { ColumnValueType, TableColumn, TableList } from "../../Controls";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import CSV from "csvtojson";
import _ from "lodash";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";

// prettier-ignore
const DEF_PERF_OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "metrics",                                                            name: "metrics",                                                            fieldName: "category",                                                            valueType: ColumnValueType.String,    minWidth: 120,    maxWidth: 140,    isResizable: true, distinctStr: true},
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",                name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",                fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",                 valueType: ColumnValueType.Number,    minWidth: 120,    maxWidth: 140,    isResizable: true, maxDecimalPlaces: 3},
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",   name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",   fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",    valueType: ColumnValueType.Number,    minWidth: 120,    maxWidth: 140,    isResizable: true, maxDecimalPlaces: 3},
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount", name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount", fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount",  valueType: ColumnValueType.Number,    minWidth: 120,    maxWidth: 140,    isResizable: true, maxDecimalPlaces: 3},
];

interface IState extends ICommonState<any> {
    dataItems: IDataItem<any[]>[];
    diffLanguages: string[];
}

export class OcrBarcodePerfMetricsOverview extends CommonView<
    ICommonProps,
    IState,
    any
> {
    private perfOverivewColumns: TableColumn[] = [];

    constructor(props: ICommonProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._reflectPerfOverviewColumns =
            this._reflectPerfOverviewColumns.bind(this);

        this.state = {
            dataItems: [],
            diffLanguages: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: props.isReport ? false : true,
        };
    }

    render(): React.ReactNode {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "hidden auto" }}>
                    {this._renderOverviewTable()}
                </div>
            </FullScreen>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("performance/barcode-perf-overview.csv");
    }

    _queryMetricsResult(
        metricsFileName: string,
        selectLanguage?: string,
        matchDatasetVersion: boolean = true
    ) {
        const datasetsOfRecords = this.props.records.map((r) =>
            r.getDatasets()
        );
        const commonLangs = this._getLanguageList(matchDatasetVersion);
        const diffLangs = datasetsOfRecords.flatMap((datasets) =>
            datasets
                .filter(
                    (dataset) =>
                        !commonLangs.includes(
                            matchDatasetVersion
                                ? dataset.displayFullName
                                : dataset.displayName
                        )
                )
                .map((diff) =>
                    matchDatasetVersion
                        ? diff.displayFullName
                        : diff.displayName
                )
        );
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(datasetsOfRecords.flatMap((d) => d))
        );

        // find one dataset detail per dataset
        const detailsList = this.props.records.map((r) => {
            if (!selectLanguage) {
                return r
                    .getDetails()
                    .filter((d) => d !== undefined) as RecordDetail[];
            } else {
                return datasets
                    .map((d) =>
                        r
                            .getDetails()
                            .find((rd) =>
                                selectLanguage
                                    ? rd.dataset.equals(d) &&
                                      (matchDatasetVersion
                                          ? rd.dataset.displayFullName ===
                                            selectLanguage
                                          : rd.dataset.displayName ===
                                            selectLanguage)
                                    : rd.dataset.equals(d)
                            )
                    )
                    .filter((d) => d !== undefined) as RecordDetail[];
            }
        });

        Promise.all(
            detailsList.flatMap((details, index) => {
                return details.map((detail) =>
                    detail
                        .fetchRawMetrics(metricsFileName)
                        .then((metricsCSV) => {
                            return CSV({ flatKeys: true })
                                .fromString(metricsCSV)
                                .then((json) => {
                                    return {
                                        recordIndex: index,
                                        recordDetail: detail,
                                        metrics: json,
                                    };
                                });
                        })
                        .catch((_error) => {
                            this.setState({
                                diffLanguages: diffLangs,
                            });
                            return [];
                        })
                );
            })
        ).then((resultItems) => {
            const dataItems = resultItems as unknown as IDataItem<any[]>[];
            if (this.perfOverivewColumns.length === 0) {
                this._reflectPerfOverviewColumns(dataItems);
            }

            this.setState({
                dataItems: dataItems,
                diffLanguages: diffLangs,
            });
        });
    }

    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            DEF_PERF_OVERVIEW_COLUMNS,
            "BarcodePerformanceMetricsOverview"
        );
    };

    private _deepLinkHandler(key: string, linkData: any): void {
        const { setSelectedSubPivot } = this.props;
        setSelectedSubPivot && setSelectedSubPivot(key, linkData);
    }

    private _loadSelectedColumns(): string[] {
        const selectedColumns = DEF_PERF_OVERVIEW_COLUMNS.map((val) => val.key);

        if (!selectedColumns.includes("metrics")) {
            selectedColumns.push("metrics");
        }

        return selectedColumns;
    }

    private _onItemInvoked(datasetName: string): void {
        if (datasetName) {
            const linkData: { [key: string]: string | undefined } = {};
            linkData.toSelectLanguage = datasetName;
            this._deepLinkHandler("ByImage", linkData);
        }
    }

    private _prepareOverviewRenderData(matchDatasetVersion: boolean) {
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);
        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const keys = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const metrics = item?.metrics;
                        return metrics ? metrics.map((m) => m.metrics) : [];
                    })
                )
            );

            const groupData = keys.map((key) => {
                const perfMetrics = items.map((item) => {
                    let perfMetric: any | undefined;
                    if (item && item.metrics) {
                        const { metrics } = item;
                        perfMetric = metrics.find((m) => m.metrics === key);
                    }

                    if (perfMetric === undefined) {
                        perfMetric = {
                            metrics: key,
                        };
                    }

                    return perfMetric;
                });

                return [key, perfMetrics] as [string, any[]];
            });

            return [datasetName, groupData] as [string, [string, any[]][]];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _reflectPerfOverviewColumns(dataItems: IDataItem<any[]>[]): void {
        let sampleMetric: any = undefined;
        dataItems.some(
            (dataItem) =>
                dataItem &&
                dataItem.metrics &&
                dataItem.metrics.length > 0 &&
                dataItem.metrics.some((metric) => {
                    if (metric) {
                        sampleMetric = metric;
                        return true;
                    } else {
                        return false;
                    }
                })
        );

        if (sampleMetric) {
            const metricKeys = Object.keys(sampleMetric);
            const defColKeys = DEF_PERF_OVERVIEW_COLUMNS.map((col) => col.key);
            this.perfOverivewColumns = _.cloneDeep(DEF_PERF_OVERVIEW_COLUMNS);
            metricKeys.forEach((metricKey) => {
                if (!defColKeys.includes(metricKey)) {
                    this.perfOverivewColumns.push({
                        key: metricKey,
                        name: metricKey,
                        fieldName: metricKey,
                        valueType: ColumnValueType.Number,
                        maxDecimalPlaces: 3,
                        minWidth: 120,
                        maxWidth: 140,
                        isResizable: true,
                    });
                }
            });
        }

        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrBarcode}_${Typename.PerformanceMetrics}_Overview`,
                columns: this.perfOverivewColumns,
                selectedItems: this._loadSelectedColumns(),
            })
        );
    }

    private _renderOverviewTable(): React.ReactNode {
        const { isDarkTheme, records } = this.props;
        const {
            diffLanguages,
            expandItem,
            matchDatasetVersion = false,
            selectedColumns,
        } = this.state;
        const data = this._prepareOverviewRenderData(matchDatasetVersion);
        const columns: TableColumn[] = this.perfOverivewColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        this.exportData = [];

        const elementsToRender: JSX.Element[] = [];
        if (data && data.length > 0) {
            const overviewTables = data.map(
                ([datasetName, dataMetrics], index) => {
                    const evalData = new Map<string, any[]>();
                    dataMetrics.forEach(([key, metrics]) => {
                        evalData.set(key, metrics);
                    });
                    const evalValues = Array.from(evalData).map(([k, v]) => v);
                    this.exportData.push([datasetName, evalValues]);

                    return (
                        <>
                            <ExpandCard
                                key={`expandCard_${index}`}
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={isDarkTheme}
                            >
                                <TableList<any>
                                    key={`list_${index}`}
                                    evalDataCount={records.length}
                                    evalData={evalData}
                                    columns={columns}
                                    isDarkTheme={isDarkTheme}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    onItemInvoked={(
                                        item: any,
                                        index?: number
                                    ) => {
                                        this._onItemInvoked(datasetName);
                                    }}
                                    hideHeader
                                    disableFreezeHeader
                                    disableSorting
                                    disableVirtualize
                                />
                            </ExpandCard>
                        </>
                    );
                }
            );

            elementsToRender.push(...overviewTables);
        }

        if (diffLanguages && diffLanguages.length > 0) {
            elementsToRender.unshift(
                <MessageBar
                    dismissButtonAriaLabel="Close"
                    messageBarType={MessageBarType.warning}
                    messageBarIconProps={{ iconName: "InfoSolid" }}
                    styles={{
                        root: {
                            backgroundColor: "#eff6fc",
                        },
                        icon: {
                            color: "#0078d4",
                        },
                    }}
                >
                    <b>Data Difference Dataset:&nbsp;&nbsp;</b>
                    <pre
                        style={{
                            fontFamily: "inherit",
                            display: "inline-flex",
                        }}
                    >
                        {diffLanguages.join("   |   ")}
                    </pre>
                </MessageBar>
            );
        }

        if (elementsToRender.length === 0) {
            elementsToRender.push(<NoDataTip>No Data Generated</NoDataTip>);
        }

        return elementsToRender;
    }
}
