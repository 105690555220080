import {
    BrowserCacheLocation,
    Configuration,
    PopupRequest,
} from "@azure/msal-browser/dist";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID ?? "",
        authority: process.env.REACT_APP_AUTHORITY ?? "",
        redirectUri: process.env.REACT_APP_REDIRECTURI ?? "",
        postLogoutRedirectUri: "/",
        clientCapabilities: ["CP1"],
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["api://7cc85b49-e3d0-4033-804f-6bc27714828c/access_as_user"],
};
