import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import _ from "lodash";
import {
    Document,
    Packer,
    PageOrientation,
    SectionType,
    Table,
    TableCell,
    TableRow,
} from "docx";
import { formatDateTime } from "./Utils";

const FILETYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

type Range = [number, number];

const exportData = (wb: XLSX.WorkBook, fileName: string) => {
    const excelBuffer = workbook2blob(wb);
    const data = new Blob([excelBuffer], { type: FILETYPE });
    FileSaver.saveAs(data, fileName);
};

export const exportGeneralMetrics = (
    csvData: any,
    columns: any[],
    fileName: any,
    fileExtension: string = ".xlsx"
) => {
    if (_.isEmpty(csvData)) {
        return;
    }
    let wb: any = XLSX.utils.book_new();
    let columnKeys = columns.map((column) => {
        return column.key;
    });
    let sheetName: any;

    csvData.forEach(([datasetName, tableContent]: any, idx: any) => {
        let excelContent: any[] = [];
        tableContent.forEach(([_category, werItems]: any) => {
            let excelObj: any = {};
            werItems.forEach((_werItem: any, index: any) => {
                for (let item of columnKeys) {
                    if (item !== "datasetName") {
                        excelObj[`${item}_${index}`] =
                            werItems[index].wer[item];
                        Object.assign(excelObj);
                    }
                }
            });
            excelContent.push(excelObj);
        });
        const sheet = XLSX.utils.json_to_sheet(
            Array.from(new Set(excelContent))
        );
        sheetName = datasetName.substring(0, datasetName.indexOf(":")) + idx;
        XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    });
    exportData(wb, fileName + fileExtension);
};

export const exportOverviewListData = (
    csvData: any,
    columns: any[],
    fileName: any,
    fileExtension: string = ".xlsx"
) => {
    if (_.isEmpty(csvData)) {
        return;
    }
    let wb: any = XLSX.utils.book_new();
    let excelContent: any[] = [];
    let sheetName: any;

    csvData.forEach(([datasetName, tableContent]: any, idx: any) => {
        const firstFloodRanges: Range[] = [];
        const secFloodRanges: Range[] = [];
        excelContent = [];
        tableContent.forEach((items: any) => {
            let excelObj: any = {};

            items.forEach((_werItem: any, index: any) => {
                for (let column of columns) {
                    if (column.key !== "datasetName") {
                        excelObj[`${column.name}_${index}`] = formaterValue(
                            items[index]?.[column.key]
                        );
                        Object.assign(excelObj);
                    }
                }
            });
            excelContent.push(excelObj);

            const getChildItemsCount = (items: any) => {
                let count = 0;
                return () => {
                    if (items.childrens) {
                        count += items.childrens.length;
                        items.childrens.forEach((childItems: any) => {
                            count += getChildItemsCount(childItems)();
                        });
                    }
                    return count;
                };
            };

            const getChildItems = (items: any, childRanges: Range[]) => {
                if (items.childrens) {
                    let rangeRight =
                        getChildItemsCount(items)() + excelContent.length + 1;

                    const range: Range = [excelContent.length + 1, rangeRight];
                    childRanges.push(range);
                    items.childrens.forEach((childItems: any) => {
                        let excelObjChild: any = {};
                        childItems.forEach((_werItem: any, index: any) => {
                            for (let column of columns) {
                                if (column.key !== "datasetName") {
                                    excelObjChild[`${column.name}_${index}`] =
                                        childItems[index]?.[column.key];
                                    Object.assign(excelObjChild);
                                }
                            }
                        });
                        excelContent.push(excelObjChild);
                        getChildItems(childItems, secFloodRanges);
                    });
                }
            };

            getChildItems(items, firstFloodRanges);
        });
        const sheet = XLSX.utils.json_to_sheet(
            Array.from(new Set(excelContent))
        );

        if (firstFloodRanges.length > 0) {
            sheetAddStyle(firstFloodRanges, sheet);
        }
        if (secFloodRanges.length > 0) {
            sheetAddStyle(secFloodRanges, sheet, "808080");
        }
        const lastIndex =
            datasetName.indexOf(":") > -1
                ? datasetName.indexOf(":")
                : datasetName.length;
        sheetName = datasetName.substring(0, lastIndex).substring(0, 28) + idx;
        XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    });

    exportData(wb, fileName + fileExtension);
};

export const exportTableListData = (
    csvData: any,
    columns: any[],
    fileName: any,
    fileExtension: string = ".xlsx"
) => {
    if (_.isEmpty(csvData)) {
        return;
    }
    let wb: any = XLSX.utils.book_new();
    let excelContent: any[] = [];
    let sheetName: any;

    excelContent = [];
    Object.entries(csvData).forEach(([id, items]: any) => {
        let excelObj: any = {};
        items.forEach((_werItem: any, index: any) => {
            for (let column of columns) {
                if (column.key !== "datasetName") {
                    excelObj[`${column.key}_${index}`] = column.isKey
                        ? id
                        : items[index]?.[column.key] ?? "";
                    Object.assign(excelObj);
                }
            }
        });
        excelContent.push(excelObj);
    });
    const sheet = XLSX.utils.json_to_sheet(Array.from(new Set(excelContent)));
    sheetName = fileName;
    XLSX.utils.book_append_sheet(wb, sheet, sheetName);

    exportData(wb, fileName + fileExtension);
};

export const exportHighlightMetricsData = (
    csvData: any,
    columns: any[][],
    fileName: any,
    fileExtension: string = ".xlsx"
) => {
    if (_.isEmpty(csvData)) {
        return;
    }
    let wb: any = XLSX.utils.book_new();
    let excelContent: any[] = [];

    csvData.forEach(([datasetName, tableContent]: any, idx: any) => {
        const firstFloodRanges: Range[] = [];
        const secFloodRanges: Range[] = [];
        excelContent = [];
        let columnKeys = columns[idx].map((column) => {
            return column.key;
        });
        let sheetName: any;
        tableContent.forEach((items: any) => {
            let excelObj: any = {};

            items.forEach((_werItem: any, index: any) => {
                for (let key of columnKeys) {
                    if (key !== "datasetName") {
                        excelObj[`${key}_${index}`] = items[index]?.[key];
                        Object.assign(excelObj);
                    }
                }
            });
            excelContent.push(excelObj);

            const getChildItemsCount = (items: any) => {
                let count = 0;
                return () => {
                    if (items.childrens) {
                        count += items.childrens.length;
                        items.childrens.forEach((childItems: any) => {
                            count += getChildItemsCount(childItems)();
                        });
                    }
                    return count;
                };
            };

            const getChildItems = (items: any, childRanges: Range[]) => {
                if (items.childrens) {
                    let rangeRight =
                        getChildItemsCount(items)() + excelContent.length + 1;

                    const range: Range = [excelContent.length + 1, rangeRight];
                    childRanges.push(range);
                    items.childrens.forEach((childItems: any) => {
                        let excelObjChild: any = {};
                        childItems.forEach((_werItem: any, index: any) => {
                            for (let key of columnKeys) {
                                if (key !== "datasetName") {
                                    excelObjChild[`${key}_${index}`] =
                                        childItems[index]?.[key];
                                    Object.assign(excelObjChild);
                                }
                            }
                        });
                        excelContent.push(excelObjChild);
                        getChildItems(childItems, secFloodRanges);
                    });
                }
            };

            getChildItems(items, firstFloodRanges);
        });
        const sheet = XLSX.utils.json_to_sheet(
            Array.from(new Set(excelContent))
        );

        if (firstFloodRanges.length > 0) {
            sheetAddStyle(firstFloodRanges, sheet);
        }
        if (secFloodRanges.length > 0) {
            sheetAddStyle(secFloodRanges, sheet, "808080");
        }
        const lastIndex =
            datasetName.indexOf(":") > -1
                ? datasetName.indexOf(":")
                : datasetName.length;
        sheetName = datasetName.substring(0, lastIndex).substring(0, 28) + idx;
        XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    });

    exportData(wb, fileName + fileExtension);
};

export const exportPredictionData = (
    csvData: any,
    columns: any[],
    fileName: any,
    fileExtension: string = ".xlsx"
) => {
    if (_.isEmpty(csvData)) {
        return;
    }
    let wb: any = XLSX.utils.book_new();
    let excelContent: any[] = [];
    let clmExcelContent: any[] = [];
    let sheetName: any;

    csvData.forEach(
        ([datasetName, tableContent, clmContent]: any, idx: any) => {
            excelContent = [];
            tableContent.forEach((items: any) => {
                let excelObj: any = {};

                items.forEach((_werItem: any, index: any) => {
                    for (let column of columns) {
                        if (column.key !== "datasetName") {
                            excelObj[`${column.name}_${index}`] = formaterValue(
                                items[index]?.[column.key]
                            );
                            Object.assign(excelObj);
                        }
                    }
                });
                excelContent.push(excelObj);
            });

            clmExcelContent = [];
            clmContent.forEach((items: any) => {
                let excelObj: any = {};

                items.forEach((_werItem: any, index: any) => {
                    for (let key of Object.keys(_werItem)) {
                        if (key !== "entityName") {
                            excelObj[`${key}_${index}`] = formaterValue(
                                items[index]?.[key]
                            );
                            Object.assign(excelObj);
                        }
                    }
                });
                clmExcelContent.push(excelObj);
            });

            const sheet = XLSX.utils.json_to_sheet(
                Array.from(new Set(excelContent))
            );

            if (clmExcelContent.length > 0) {
                XLSX.utils.sheet_add_json(sheet, clmExcelContent, {
                    origin: `A${excelContent.length + 5}`,
                });
            }

            const lastIndex =
                datasetName.indexOf(":") > -1
                    ? datasetName.indexOf(":")
                    : datasetName.length;
            sheetName =
                datasetName.substring(0, lastIndex).substring(0, 28) + idx;
            XLSX.utils.book_append_sheet(wb, sheet, sheetName);
        }
    );

    exportData(wb, fileName + fileExtension);
};

const workbook2blob = (workbook: any) => {
    let wopts: any = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
    };
    let wbout = XLSX.write(workbook, wopts);

    var blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
    });
    return blob;
};

const s2ab = (s: any) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
};

const sheetAddStyle = (
    ranges: Range[],
    sheet: XLSX.WorkSheet,
    rgb: string = "F08080"
) => {
    for (const key in sheet) {
        if (key.indexOf("!") !== 0) {
            const cellRow = Number(key.substring(1, key.length));
            ranges.forEach((range) => {
                if (range[0] < cellRow && cellRow <= range[1]) {
                    sheet[key].s = {
                        fill: {
                            fgColor: { rgb: rgb },
                        },
                    };
                }
            });
        }
    }
};

const formaterValue = (value: any) => {
    if (value === undefined || value === null || Number.isNaN(value)) {
        return "";
    }
    if (typeof value === "string" && value.length > 32767) {
        return value.substring(0, 32767);
    }
    return value;
};

export const saveTableCellsToDocx = async (
    tableCellsArray: TableCell[][],
    title: string
) => {
    const doc = new Document({
        sections: [
            {
                properties: {
                    type: SectionType.CONTINUOUS,
                    page: {
                        size: {
                            orientation: PageOrientation.LANDSCAPE,
                        },
                    },
                },
                children: [
                    new Table({
                        rows: tableCellsArray.map(
                            (rowCells) =>
                                new TableRow({
                                    children: rowCells,
                                })
                        ),
                    }),
                ],
            },
        ],
    });

    const blob = await Packer.toBlob(doc);
    FileSaver.saveAs(
        blob,
        `${title}_${formatDateTime(new Date(Date.now()))}.docx`
    );
};
