import { IImageProps } from "@fluentui/react";
import React from "react";
import { Image } from "@fluentui/react/lib/Image";
import { AuthHoc, AuthUrlType } from "./AuthHoc";

export class CustomImage extends React.Component<IImageProps> {
    public render() {
        return <Image {...this.props} />;
    }
}

const AuthImage = AuthHoc(CustomImage, AuthUrlType.image);
export default AuthImage;
