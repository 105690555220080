import React, { Component } from "react";
import "./MultipleFilterBar.scss";

import {
    //DetailList
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    ConstrainMode,
    IDetailsColumnProps,
    DetailsRow,
    IDetailsHeaderStyles,

    //DropDown
    Dropdown,
    IDropdownOption,

    //button
    ActionButton,
    IconButton,
    IIconProps,

    //other
    Separator,
    Stack,
    IDetailsRowStyles,
    IDetailsListProps,
    Dialog,
    DialogType,
    getId,
    SearchBox,
    Checkbox,
    getTheme,
} from "@fluentui/react";
import {
    dialogContentStyles,
    searchBoxStyles,
} from "./MultipleFilterBar.style";
import { Consumer } from "../Layout";

interface SearchField {
    field: string;
    value: string;
    isExactMatch: boolean;
}

interface IState {
    items: SearchField[];
    hideDialog: boolean;
}

interface IProps {
    searchOptions: IDropdownOption[];
    isDarkTheme?: boolean;
    refreshList: (searchs: { key: string; value: string }[]) => void;
    clearList: () => void;
    initSearchString?: string | null;
}

const ADD_ICON: IIconProps = {
    iconName: "AddTo",
    style: {
        fontSize: 16,
    },
};

const DELETE_ICON: IIconProps = {
    iconName: "Blocked12",
    style: {
        fontSize: 16,
        color: "red",
    },
};

const RELOAD_ICON: IIconProps = {
    iconName: "Play",
    style: {
        fontSize: 14,
    },
};

const CLEAR_ICON: IIconProps = {
    iconName: "Delete",
    style: {
        fontSize: 14,
    },
};

// prettier-ignore
const FieldColumns: IColumn[] = [
    { key: "add",    name: "", minWidth: 20, maxWidth: 20, isResizable: true },
    { key: "delete", name: "", minWidth: 20, maxWidth: 20, isResizable: true },
    {
        key: "field",
        name: "Field",
        minWidth: 50,
        maxWidth: 200,
        isResizable: true,
        onRenderHeader: (props?: IDetailsColumnProps) => {
            if (props) {
                return (
                    <span key={props.column.name}>
                        {props.column.name}
                        <span style={{ color: "red" }}>*</span>
                    </span>
                );
            } else {
                return null;
            }
        },
    },
    {
        key: "value",
        name: "Value",
        minWidth: 200,
        maxWidth: 400,
        isResizable: true,
    },
];
const theme = getTheme();

export class MultipleFilterBar extends Component<IProps, IState> {
    // Dialogue
    private _labelId: string = getId("dialogLabel");
    private _subTextId: string = getId("subTextLabel");

    constructor(props: IProps) {
        super(props);

        this._onColumnRender = this._onColumnRender.bind(this);
        this._addFilterField = this._addFilterField.bind(this);
        this._deleteFilterField = this._deleteFilterField.bind(this);
        this._generateOptions = this._generateOptions.bind(this);
        this._refreshList = this._refreshList.bind(this);
        this._handleEnterKey = this._handleEnterKey.bind(this);

        let items = [
            {
                field: this.props.searchOptions[0].key.toString(),
                value: "",
                isExactMatch: false,
            },
        ];

        if (!!this.props.initSearchString) {
            const fileds = this.props.initSearchString.split("@");
            items = [
                { field: "modelInfo", value: fileds[0], isExactMatch: false },
                {
                    field: "runtimeVersion",
                    value: fileds[1],
                    isExactMatch: false,
                },
            ];
        }

        this.state = {
            items: items,
            hideDialog: true,
        };
    }
    public render() {
        const columns = this._generateColumns(FieldColumns);

        return (
            <div className="mulFilter">
                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={() => {
                        this.setState({
                            hideDialog: true,
                        });
                    }}
                    dialogContentProps={{
                        styles: dialogContentStyles,
                        type: DialogType.close,
                        title: "Warning Details",
                        closeButtonAriaLabel: "Close",
                        subText: "Field or value cannot be empty",
                    }}
                    modalProps={{
                        titleAriaId: this._labelId,
                        subtitleAriaId: this._subTextId,
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}
                ></Dialog>

                <Stack>
                    <DetailsList
                        items={this.state.items}
                        columns={columns}
                        className="catalog__table"
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderRow={this._onRenderRow}
                        onRenderDetailsHeader={this._onRenderDetailsHeader}
                    />
                    <Stack horizontal verticalAlign="center">
                        <ActionButton
                            iconProps={ADD_ICON}
                            text="Add New Clause"
                            onClick={() => this._addFilterField(undefined)}
                            disabled={
                                this.state.items.length ===
                                this.props.searchOptions.length
                            }
                        />
                        <ActionButton
                            iconProps={RELOAD_ICON}
                            text="Run query"
                            onClick={() => {
                                this._refreshList();
                            }}
                        />

                        <ActionButton
                            iconProps={CLEAR_ICON}
                            text="Clear"
                            onClick={() => {
                                this._clearList();
                            }}
                        />
                    </Stack>
                    <Separator />
                </Stack>
            </div>
        );
    }

    public componentDidMount() {
        document.addEventListener("keypress", this._handleEnterKey);
    }

    public componentWillUnmount() {
        document.removeEventListener("keypress", this._handleEnterKey);
    }

    private _handleEnterKey(e: KeyboardEvent) {
        if (e.code === "Enter") {
            this._refreshList();
        }
    }

    private _clearList() {
        this.setState({
            items: [
                {
                    field: this.props.searchOptions[0].key.toString(),
                    value: "",
                    isExactMatch: false,
                },
            ],
        });
        this.props.clearList();
    }

    private _refreshList() {
        const searchs = this.state.items
            .filter((item) => item.field && item.value)
            .map((item) => {
                const value = item.isExactMatch
                    ? `$${item.value}$`
                    : item.value.toLocaleLowerCase();
                return {
                    key: item.field,
                    value: value,
                };
            });
        if (searchs.length !== this.state.items.length) {
            this.setState({
                hideDialog: false,
            });
        } else {
            this.props.refreshList(searchs);
        }
    }

    private _generateColumns(column: IColumn[]): IColumn[] {
        let columns = column.map((col) => {
            return {
                ...col,
                isResizable: true,
                onRender: this._onColumnRender,
            };
        });
        return columns;
    }

    private _onColumnRender(
        item: SearchField,
        _index?: number | undefined,
        column?: IColumn | undefined
    ) {
        if (!column) {
            return;
        }

        if (column.key === "add") {
            return (
                <IconButton
                    iconProps={ADD_ICON}
                    onClick={() => this._addFilterField(_index)}
                    disabled={
                        this.state.items.length ===
                        this.props.searchOptions.length
                    }
                />
            );
        } else if (column.key === "delete") {
            return (
                <>
                    {this.state.items.length === 1 ? null : (
                        <IconButton
                            iconProps={DELETE_ICON}
                            onClick={() => this._deleteFilterField(_index!)}
                        />
                    )}
                </>
            );
        } else if (column.key === "field") {
            return (
                <Dropdown
                    styles={{
                        root: { height: "100%" },
                        dropdown: { height: "100%" },
                        title: {
                            border: "1px solid rgb(96, 94, 92) !important",
                            backgroundColor: `${
                                this.props.isDarkTheme
                                    ? theme.palette.neutralDark
                                    : theme.palette.neutralLighterAlt
                            }!important`,
                            marginTop: "0px !important",
                            height: "100% !important",
                        },
                    }}
                    selectedKey={item.field}
                    options={this._generateOptions(item.field)}
                    onChange={(_event, option) => {
                        const newItems = this.state.items.slice();
                        newItems[_index!].field = option?.key.toString() || "";
                        this.setState({ items: newItems });
                    }}
                ></Dropdown>
            );
        } else if (column.key === "value") {
            console.log(this.props.isDarkTheme);
            return (
                <Consumer>
                    {(value) => {
                        return (
                            <Stack
                                horizontal
                                verticalAlign="center"
                                style={{
                                    backgroundColor: value
                                        ? theme.palette.neutralDark
                                        : theme.palette.neutralLighter,
                                }}
                            >
                                <SearchBox
                                    styles={searchBoxStyles}
                                    value={item.value}
                                    onChange={(e, value) => {
                                        const newItems =
                                            this.state.items.slice();
                                        newItems[_index!].value =
                                            value?.toLowerCase() || "";
                                        this.setState({ items: newItems });
                                    }}
                                    onClear={() => {
                                        const newItems =
                                            this.state.items.slice();
                                        newItems[_index!].value = "";
                                        this.setState({ items: newItems });
                                    }}
                                />

                                <Checkbox
                                    styles={{
                                        root: {
                                            paddingLeft: "5px",
                                        },
                                    }}
                                    checked={item.isExactMatch}
                                    label="Exact Match"
                                    onChange={(
                                        ev?: React.FormEvent<
                                            HTMLElement | HTMLInputElement
                                        >,
                                        checked?: boolean
                                    ) => {
                                        this._onChange(
                                            checked || false,
                                            _index!
                                        );
                                    }}
                                />
                            </Stack>
                        );
                    }}
                </Consumer>
            );
        }
    }

    private _onChange = (checked: boolean, index: number) => {
        const newItems = this.state.items.slice();
        newItems[index!].isExactMatch = checked;
        this.setState({ items: newItems });
    };

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {
            cell: {
                paddingBottom: 5,
                paddingTop: 5,
            },
            root: {
                borderBottom: 0,
            },
        };
        if (props) {
            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    private _onRenderDetailsHeader: IDetailsListProps["onRenderDetailsHeader"] =
        (props, defaultRender) => {
            const customStyles: Partial<IDetailsHeaderStyles> = {
                root: {
                    paddingTop: 0,
                },
            };
            if (props) {
                props.styles = customStyles;
                return defaultRender!({
                    ...props,
                    ...{ styles: customStyles },
                });
            }
            return null;
        };

    private _generateOptions(selectedKey: string): IDropdownOption[] {
        const options = this.props.searchOptions.slice();
        const selectField = this.state.items
            .filter((item) => item.field && item.field !== selectedKey)
            .map((item) => item.field);

        return options.filter(
            (option) => !selectField.includes(option.key.toString())
        );
    }

    private _addFilterField(index: number | undefined) {
        const newItems = this.state.items.slice();
        const options = this._generateOptions("");
        if (index !== undefined) {
            newItems.splice(index + 1, 0, {
                field: options[0].key.toString(),
                value: "",
                isExactMatch: false,
            });
        } else {
            newItems.push({
                field: options[0].key.toString(),
                value: "",
                isExactMatch: false,
            });
        }
        this.setState({ items: newItems });
    }

    private _deleteFilterField(index: number) {
        const newItems = this.state.items.slice();
        newItems.splice(index, 1);
        this.setState({ items: newItems });
    }
}
