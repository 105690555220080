import { MessageBar, MessageBarType, Stack, Toggle } from "@fluentui/react";
import _ from "lodash";
import * as React from "react";
import {
    sortItems,
    sortItemsByDiffNumWhenCompare2Records,
} from "../../Controls/Common/DiffSort";
import { ChildArray, GroupList, IGroupResult } from "../../Controls/GroupList";
import { DatasetSet, RecordDetail } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    CHOICE_OPTIONS,
    DISTRIBUTION_COLUMNS,
    DISTRIBUTION_DES_COLUMNS,
    GROUP_TAGS,
    LINE_DELETION,
    SIMILAR_CHAR,
} from "./EntityConstants";
import { DATASET_NAME_SYMBOLS } from "./EntityConstants";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { NameSymbols } from "./NameSymbols";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import { CalloutTable, formartTarget } from "../../Controls/CalloutTable";

interface OcrEntityResult extends IGroupResult {
    tag: string;
    error_count: number;
    language?: string;
}

interface OcrDataset {
    tag: string;
    error_count: number;
    dataset: string;
    language: string;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: { [key: string]: ErrorDistributionMetric[] };
}

interface ErrorDistributionMetric {
    tag: string;
    error_count: number;
    [key: string]: string | number;
}

interface IState extends ICommonState<any> {
    dataItems: IDataItem[];
    showDescription?: boolean;
    target?: string;
    selectedCrossLanguages?: string[];
    chooseOption: string;
}

interface IProps extends ICommonProps {
    onClick?: (dataet: string, entityName: string, tags?: string) => void;
}

export class EntityErrorDistributionView extends CommonView<
    IProps,
    IState,
    any
> {
    private intersectLanguages: string[];

    constructor(props: IProps) {
        super(props);

        this.state = {
            dataItems: [],
            chooseOption: DATASET_NAME_SYMBOLS.Verticals,
            showDescription: true,
            matchDatasetVersion: true,
        };

        this.intersectLanguages = this._getLanguageList(true);
    }

    public render() {
        const { showDescription } = this.state;
        return (
            <FullScreen>
                <div className="displayFlex">
                    <NameSymbols
                        onSelectItemKey={(itemKey) =>
                            this.setState({ chooseOption: itemKey })
                        }
                    />

                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        <Toggle
                            label="Show entities under documental scenario only"
                            defaultChecked={showDescription ?? false}
                            inlineLabel
                            onText="On"
                            offText="Off"
                            onChange={(event, checked) => {
                                this.setState({
                                    showDescription: checked,
                                });
                            }}
                        />
                        {this._renderAsTable()}
                    </div>
                </div>
            </FullScreen>
        );
    }

    exportAction = () => {
        const opt = CHOICE_OPTIONS.filter(
            (op) => op.key === this.state.chooseOption
        )[0]?.text;
        exportOverviewListData(
            this.exportData,
            DISTRIBUTION_COLUMNS.filter((col) => col.key !== "childrenStat"),
            `EntityErrorDistributionMetrics_${opt}`
        );
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (
            this.props.records !== prevProps.records ||
            this.state.showDescription !== prevState.showDescription
        ) {
            this.intersectLanguages = this._getLanguageList(true);
            this.queryMetricsResult();
        }
        if (
            !_.isEqual(prevState.dataItems, this.state.dataItems) ||
            !_.isEqual(this.state.chooseOption, prevState.chooseOption)
        ) {
            this.setState({
                selectedCrossLanguages:
                    this._extractDatasetNameByChooseOption(),
            });
        }
    }

    queryMetricsResult() {
        let metricsFile = "overall_err_tag_analysis.json";
        if (this.state.showDescription) {
            metricsFile = "doc_err_tag_analysis.json";
        }
        this._queryMetricsResult(metricsFile);
    }

    private _renderAsTable() {
        this.intersectLanguages = this._getLanguageList(true);
        const { target } = this.state;
        const data = this._prepareRenderData();
        const dataset = this._prepareRenderDatasetData();
        this.exportData = data;

        return (
            <>
                {data ? (
                    <Stack>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            messageBarIconProps={{
                                iconName: "InfoSolid",
                            }}
                            styles={{
                                root: {
                                    backgroundColor: "#eff6fc",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                    width: "99%",
                                },
                                icon: {
                                    color: "#0078d4",
                                },
                            }}
                        >
                            <b>
                                Double click to check the entity data by dataset
                            </b>
                        </MessageBar>
                        {target && dataset && !_.isEmpty(dataset) && (
                            <CalloutTable
                                tableTitle={target.split("_")[0]}
                                targetId={formartTarget(target)}
                                columns={DISTRIBUTION_DES_COLUMNS}
                                evalData={dataset}
                                message="Double click to check by entity metrics for given dataset and entity"
                                evalDataCount={this.props.records.length}
                                onItemInvoked={this._calloutItemInvoked}
                                onDisMiss={() => {
                                    this.setState({
                                        target: undefined,
                                    });
                                }}
                            />
                        )}
                        <GroupList
                            style={{}}
                            columns={DISTRIBUTION_COLUMNS}
                            evalData={data}
                            targetKeys={["language", "tag"]}
                            onItemInvoked={(item) => {
                                const canClick = item.some(
                                    (ite: any) =>
                                        ite.tag !== GROUP_TAGS.LINE_DELETION &&
                                        ite.tag !== GROUP_TAGS.SIMILAR_CHAR
                                );
                                if (canClick) {
                                    const data = item[0];

                                    this.setState({
                                        target: `${data.language}-${data.tag}`,
                                    });
                                }
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </Stack>
                ) : (
                    <NoDataTip>{this.noDataPrompt()}</NoDataTip>
                )}
            </>
        );
    }

    noDataPrompt = () => {
        const { chooseOption } = this.state;
        const chooseOp = CHOICE_OPTIONS.find((op) => op.key === chooseOption);

        return `No ${chooseOp?.text} Data Generated`;
    };

    private _prepareRenderDatasetData = () => {
        const { target, dataItems } = this.state;
        if (!target || dataItems.length === 0) {
            return undefined;
        }
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(datasets.map((dataset) => dataset.displayFullName))
        );
        const [language, tag] = target.split("-");

        const data = allDatasetNames.map((datasetName) => {
            const all_items = this.props.records.flatMap((_, recordIndex) => {
                const item = this.state.dataItems.filter(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.displayFullName ===
                            datasetName
                );

                return item;
            });

            let items = this._extracRecordsByChooseOption(all_items);
            if (this.state.selectedCrossLanguages) {
                items = items.filter((item) =>
                    this.state.selectedCrossLanguages?.includes(
                        item.recordDetail.dataset.displayFullName
                    )
                );
            }

            return items.map((item) => {
                const tagItem =
                    item?.metrics[language]?.filter(
                        (tagItem) => tagItem.tag === tag
                    ) || [];
                return {
                    tag: tag,
                    dataset: datasetName,
                    language: language,
                    error_count:
                        tagItem.length > 0 ? tagItem[0].error_count : NaN,
                };
            });
        });

        const sortData = this._sortByCount(
            (data as OcrDataset[][]).filter((datasetItem) => {
                return datasetItem.some((item) => !isNaN(item.error_count));
            })
        );
        const resultArray = this._supplementDefaultValue(sortData);
        const result: any = {};
        resultArray.forEach((value, index) => {
            result[index] = value;
        });

        return result;
    };

    private _supplementDefaultValue = (data: OcrDataset[][]) => {
        const temp = _.cloneDeep(data);
        data.forEach((datasetItem, index) => {
            const diffCount = this.props.records.length - datasetItem.length;
            if (diffCount > 0) {
                for (let i = 0; i < diffCount; i++) {
                    const defaultValue = _.cloneDeep(datasetItem[0]);
                    defaultValue.error_count = 0;
                    temp[index] = temp[index].concat(defaultValue);
                }
            }
        });
        return temp;
    };

    private _sortByCount = <T extends unknown>(data: T[][] = []) => {
        if (this.props.records.length === 2) {
            return data.sort((a: T[], b: T[]) =>
                sortItemsByDiffNumWhenCompare2Records<T>(
                    a,
                    b,
                    "error_count" as keyof T
                )
            );
        } else {
            return data.sort((a: T[], b: T[]) =>
                sortItems<T>(a, b, "error_count" as keyof T)
            );
        }
    };

    _getLanguageList(matchDatasetVersion: boolean): string[] {
        const { dataItems } = this.state;
        if (dataItems && dataItems.length > 0) {
            const langStatsMap = new Map<string, Array<Boolean>>();
            dataItems.forEach((item) => {
                if (!_.isEmpty(item.metrics)) {
                    const lang = matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName;

                    let existStat = langStatsMap.get(lang);
                    if (existStat === undefined) {
                        existStat = new Array<Boolean>(
                            this.props.records.length
                        ).fill(false);
                    }

                    existStat[item.recordIndex] = true;
                    langStatsMap.set(lang, existStat);
                }
            });

            const languages = Array.from(langStatsMap)
                .filter(([_, stat]) => stat.every((s) => s === true))
                .map(([lang, _]) => lang)
                .sort();

            return languages;
        } else {
            return super._getLanguageList(matchDatasetVersion);
        }
    }

    //#region Calculate and construct data
    private _prepareRenderData = () => {
        const all_items = this.props.records.flatMap((_, recordIndex) => {
            const item = this.state.dataItems.filter(
                (item) => item.recordIndex === recordIndex
            );
            return item;
        });

        let tab_items = this._extracRecordsByChooseOption(all_items);
        if (this.state.selectedCrossLanguages) {
            tab_items = tab_items.filter((item) =>
                this.state.selectedCrossLanguages?.includes(
                    item.recordDetail.dataset.displayFullName
                )
            );
        }

        if (tab_items.length === 0) {
            return undefined;
        }

        const all_language = Array.from(
            new Set(
                tab_items.flatMap((item) => {
                    const metrics = item?.metrics;
                    return Object.keys(metrics);
                })
            )
        );

        const data = all_language.map((language) => {
            const all_entities = Array.from(
                new Set(
                    tab_items.flatMap((item) => {
                        const metrics = item?.metrics[language];
                        return metrics?.flatMap((m) => m.tag);
                    })
                )
            ).filter((en) => en);

            const groupData = all_entities
                .map((tag) => {
                    const result = this.props.records
                        .map((_, recordIndex) => {
                            const items = tab_items.filter(
                                (item) => item.recordIndex === recordIndex
                            );
                            const total_counts = items
                                .flatMap((item) => {
                                    const metrics = item?.metrics[
                                        language
                                    ]?.filter((met) => met.tag === tag);
                                    if (metrics && metrics.length > 0) {
                                        return metrics.reduce(
                                            (per, cur) => per + cur.error_count,
                                            0
                                        );
                                    } else {
                                        return NaN;
                                    }
                                })
                                .filter((count) => !isNaN(count));
                            let total_count = NaN;
                            if (total_counts.length > 0) {
                                total_count = total_counts.reduce(
                                    (per, cur) => per + cur,
                                    0
                                );
                            }

                            return {
                                tag: tag,
                                error_count: total_count,
                                language: language,
                                childrenStatKey: `${language}_${tag}`,
                            };
                        })
                        .filter((r) => r !== undefined);

                    return result;
                })
                .filter((g) => g.length > 0);

            const storGroupData = this._sortByCount(groupData);

            return [language, this._groupData(storGroupData)] as [
                string,
                ChildArray<OcrEntityResult>[][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    };

    private _groupData = (groupData: OcrEntityResult[][]) => {
        let result: ChildArray<any> = [];
        const other_groupDate = groupData.filter((items) => {
            return items.some((item) => item.tag === "OTHERS");
        });

        const total_groupDate = groupData.filter((items) => {
            return items.some((item) => item.tag === "TOTAL");
        });

        const createGroupData = (
            groupData: OcrEntityResult[][],
            tag: string
        ) => {
            const counts: any = {};
            groupData.forEach((items) => {
                items.forEach((item, index) => {
                    if (!counts[index]) {
                        counts[index] = item.error_count;
                    } else {
                        if (!isNaN(item.error_count)) {
                            counts[index] = counts[index] + item.error_count;
                        }
                    }
                });
            });
            const data: ChildArray<OcrEntityResult> = [];
            for (const value of Object.values(counts)) {
                const line: OcrEntityResult = {
                    tag: tag,
                    error_count: value as number,
                    language: groupData[0][0].language,
                    childrenStatKey: `${groupData[0][0].language}_${tag}`,
                };
                data.push(line);
            }

            return data;
        };

        const surplus_groupDate = groupData.filter((items) => {
            return items.some(
                (item) =>
                    !item.tag.startsWith(SIMILAR_CHAR) &&
                    !LINE_DELETION.includes(item.tag) &&
                    item.tag !== "OTHERS" &&
                    item.tag !== "TOTAL"
            );
        });

        if (surplus_groupDate.length > 0) {
            result = result.concat(surplus_groupDate);
        }
        if (other_groupDate.length > 0) {
            result = result.concat(other_groupDate);
        }

        const similar_groupDate = groupData.filter((items) => {
            return items.some((item) => item.tag.startsWith(SIMILAR_CHAR));
        });

        if (similar_groupDate.length > 0) {
            const similar_data = createGroupData(
                similar_groupDate,
                GROUP_TAGS.SIMILAR_CHAR
            );
            similar_data.childrens = similar_groupDate;
            result.unshift(similar_data);
        }
        const line_groupDate = groupData.filter((items) => {
            return items.some((item) => LINE_DELETION.includes(item.tag));
        });
        if (line_groupDate.length > 0) {
            const line_data = createGroupData(
                line_groupDate,
                GROUP_TAGS.LINE_DELETION
            );
            line_data.childrens = line_groupDate;

            result.unshift(line_data);
        }
        if (total_groupDate.length > 0) {
            return result.concat(total_groupDate);
        }

        return result;
    };

    private _extracRecordsByChooseOption(items: IDataItem[]) {
        switch (this.state.chooseOption) {
            case DATASET_NAME_SYMBOLS.OCR_Printed: {
                return items.filter(
                    (item) =>
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Verticals
                        ) &&
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Entity
                        ) &&
                        item.recordDetail.dataset.displayFullName.indexOf(
                            DATASET_NAME_SYMBOLS.OCR_Handwritten
                        ) === -1
                );
            }
            case DATASET_NAME_SYMBOLS.OCR_Handwritten: {
                return items.filter(
                    (item) =>
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Verticals
                        ) &&
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Entity
                        ) &&
                        item.recordDetail.dataset.displayFullName.indexOf(
                            DATASET_NAME_SYMBOLS.OCR_Handwritten
                        ) !== -1
                );
            }
            case DATASET_NAME_SYMBOLS.Entity: {
                return items.filter((item) =>
                    item.recordDetail.dataset.displayFullName.startsWith(
                        DATASET_NAME_SYMBOLS.Entity
                    )
                );
            }
            case DATASET_NAME_SYMBOLS.Verticals: {
                return items.filter((item) =>
                    item.recordDetail.dataset.displayFullName.startsWith(
                        DATASET_NAME_SYMBOLS.Verticals
                    )
                );
            }
            default:
                return [];
        }
    }

    private _extractDatasetNameByChooseOption(
        chooseOption: string | undefined = this.state.chooseOption,
        datasetNames: string[] = this.intersectLanguages
    ) {
        switch (chooseOption) {
            case DATASET_NAME_SYMBOLS.OCR_Printed: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) ===
                            -1
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.OCR_Handwritten: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) !==
                            -1
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.Entity: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Entity)
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.Verticals: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Verticals)
                );
                break;
            }
        }

        return datasetNames;
    }

    private _onItemInvoked = (item?: OcrEntityResult[]) => {
        if (item) {
            const canClick = item.some(
                (ite) =>
                    ite.tag !== GROUP_TAGS.LINE_DELETION &&
                    ite.tag !== GROUP_TAGS.SIMILAR_CHAR
            );
            if (canClick) {
                this.setState({
                    target: this._createDocumentId(item),
                });
            }
        }
    };

    private _calloutItemInvoked = (item?: any, index?: number, ev?: Event) => {
        const { onClick } = this.props;
        if (item && item.length === 2 && item[1].length > 0 && onClick) {
            const eneity = item[1][0];
            const dataset = eneity.dataset;
            const entityName = eneity.language;
            let tags: string | undefined = undefined;
            switch (eneity.tag) {
                case "OTHERS":
                    tags = "";
                    break;
                case "TOTAL":
                    tags = undefined;
                    break;
                default:
                    tags = eneity.tag;
                    break;
            }
            onClick(dataset, entityName, tags);
        }
    };

    private _createDocumentId = (items: OcrEntityResult[]) => {
        const item = items[0];
        return `${item.language}-${item.tag}`;
    };

    //#endregion
}
