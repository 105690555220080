import React, { Component } from "react";
import * as d3 from "d3";
import _ from "lodash";
import { getTheme, Icon, Label } from "@fluentui/react";
import "./VerticalBarChart.scss";

export interface ChartSeriesPoint {
    key: string;
    data: number;
    color: string;
    legend: string;
}

export interface IGroupedBarChartData {
    name: string;
    series: ChartSeriesPoint[];
}

interface IBarChartData {
    name: string;
    seriesPoint: ChartSeriesPoint;
}

enum Direction {
    Left,
    Right,
}

const BAR_GROUP_INTERVAL = 14;
const CHART_MARGIN = {
    left: 45,
    top: 40,
    right: 10,
    bottom: 100,
};

const DEFAULT_FONT_SIZE = 16;
const MAX_RATE = 1.2;
const SINGLE_BAR_WIDTH = 40;
const theme = getTheme();

interface IProps {
    id: string;
    data: IGroupedBarChartData[];
    height: number;
    width?: number;
    labelPrecision?: number;
    xAxisName?: string;
    yAxisName?: string;
    yAxisMax?: number;
    yAxisMin?: number;
    yAxisTicksCount?: number;
    isDarkTheme?: boolean;
}

interface IState {
    legendPoints: ChartSeriesPoint[];
    showScrollX: boolean;
}

export class VerticalBarChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);
        this._getPointData = this._getPointData.bind(this);
        this.state = {
            legendPoints: [],
            showScrollX: false,
        };
    }

    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
        this.setState = () => false;
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }

    public render() {
        return (
            <div className="drawingContainer">
                <div
                    id={this.props.id}
                    className="drawingBoard"
                    style={{
                        background: this.props.isDarkTheme
                            ? theme.palette.black
                            : theme.palette.neutralLighterAlt,
                        color: this.props.isDarkTheme
                            ? theme.palette.white
                            : theme.palette.neutralDark,
                    }}
                    onScroll={this._onScroll}
                >
                    <svg id={`${this.props.id}svg`}></svg>
                    <>
                        <button
                            className="leftBtn"
                            onClick={(e) =>
                                this._onScrollNavBtnClick(e, Direction.Left)
                            }
                            style={{ display: "none" }}
                            title=""
                        >
                            <Icon iconName="ChevronLeft" />
                        </button>
                        <button
                            className="rightBtn"
                            onClick={(e) =>
                                this._onScrollNavBtnClick(e, Direction.Right)
                            }
                            title=""
                        >
                            <Icon iconName="ChevronRight" />
                        </button>
                    </>
                </div>
                <Label
                    styles={{ root: { width: "100%", textAlign: "center" } }}
                >
                    {this.props.xAxisName ?? "Category"}
                </Label>
                {this.state.legendPoints && (
                    <div className="seriesDesc">
                        {this.state.legendPoints.map((series, index) => {
                            return (
                                <div
                                    key={this.props.id + index}
                                    className="desc"
                                >
                                    <i
                                        style={{
                                            backgroundColor: series.color,
                                            color: this.props.isDarkTheme
                                                ? theme.palette.white
                                                : theme.palette.black,
                                        }}
                                    ></i>
                                    <span
                                        style={{
                                            color: this.props.isDarkTheme
                                                ? theme.palette.white
                                                : theme.palette.black,
                                        }}
                                    >
                                        {series.legend}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    private _drawChart(): void {
        const { data, id, yAxisName, yAxisMax, yAxisMin, yAxisTicksCount } =
            this.props;
        let legendPoints: ChartSeriesPoint[] = [];
        const drawingBoard = document.getElementById(id);
        const svgEl = document.getElementById(`${id}svg`);
        if (svgEl && svgEl.hasChildNodes()) {
            svgEl.innerHTML = "";
        }

        let tipString = "";
        const tip = d3
            .select("body")
            .append("div")
            .attr("class", "barChartTip")
            .style("display", "none");

        const showTip = (
            mouseEvent: MouseEvent,
            tipElement: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
            tipString: string
        ) => {
            tipElement
                .style("top", mouseEvent.pageY - 40 + "px")
                .style("left", mouseEvent.pageX - 10 + "px")
                .style("display", "block")
                .html(tipString);
        };

        const showTipListener = (ev: MouseEvent) => {
            showTip(ev, tip, tipString);
        };

        if (data && data.length > 0) {
            const dataSample = data.find(
                (d) => d && d.series && d.series.length > 0
            );
            if (dataSample) {
                legendPoints = this._GetLegendSeriesPoints(data);
                const barChartData = data.flatMap((item) => {
                    return item.series.map((s) => {
                        return {
                            name: item.name,
                            seriesPoint: s,
                        } as IBarChartData;
                    });
                });
                const _getPointData = this._getPointData;
                const xColNames = data.map((item) => item.name);
                const xColCount = xColNames.length;
                const xKeys = dataSample.series.map((s) => s.key);
                const xKeyCount = xKeys.length;
                const barCount = xKeyCount * xColCount;
                let innerPadding = this._calculatePaddingInner(barCount);
                let width = this.props.width;
                if (width === undefined) {
                    width =
                        SINGLE_BAR_WIDTH * barCount +
                        BAR_GROUP_INTERVAL * xColCount +
                        CHART_MARGIN.left +
                        CHART_MARGIN.right;

                    const container = drawingBoard?.parentElement;
                    if (container && container?.offsetWidth > width) {
                        width = container.offsetWidth - 1;
                    }
                }

                const height =
                    this.props.height - CHART_MARGIN.top - CHART_MARGIN.bottom;

                const xGroupScale = d3
                    .scaleBand()
                    .domain(xColNames)
                    .range([CHART_MARGIN.left, width - CHART_MARGIN.right])
                    .paddingInner(innerPadding)
                    .paddingOuter(0.1);

                const xKeyScale = d3
                    .scaleBand()
                    .domain(xKeys)
                    .range([0, xGroupScale.bandwidth()]);

                const unifiedFontSize = this._calculateUnifiedFontSize(
                    barChartData,
                    xKeyScale.bandwidth()
                );

                const yScale = d3
                    .scaleLinear()
                    .domain([
                        yAxisMin ?? 0,
                        yAxisMax ??
                            d3.max(
                                barChartData,
                                function (data: IBarChartData) {
                                    return data.seriesPoint.data * MAX_RATE;
                                }
                            )!,
                    ])
                    .range([height - CHART_MARGIN.bottom, CHART_MARGIN.top]);

                const xAxis = d3.axisBottom(xGroupScale);
                const yAxis = d3
                    .axisLeft(yScale)
                    .ticks(yAxisTicksCount ?? 20, "r");

                const svg = d3
                    .select(`#${id}svg`)
                    .attr("width", width)
                    .attr("height", height);

                const paintGroup = svg
                    .selectAll("g")
                    .data(data)
                    .join("g")
                    .attr("transform", function (item) {
                        return "translate(" + xGroupScale(item.name) + ",0)";
                    });

                paintGroup
                    .selectAll("rect")
                    .data(function (item) {
                        return xKeys.map(function (key) {
                            return {
                                name: key,
                                seriesPoint: item.series.find(
                                    (s) => s.key === key
                                )!,
                            };
                        });
                    })
                    .join("rect")
                    .attr("x", function (item) {
                        return xKeyScale(item.seriesPoint.key)!;
                    })
                    .attr("y", function (item) {
                        return yScale(item.seriesPoint.data);
                    })
                    .attr("width", xKeyScale.bandwidth())
                    .attr("height", function (item) {
                        return (
                            height -
                            CHART_MARGIN.bottom -
                            yScale(item.seriesPoint.data)
                        );
                    })
                    .attr("fill", function (item) {
                        return item.seriesPoint.color;
                    })
                    .on("mouseover", function (e: any, d: any) {
                        const data = d as IBarChartData;
                        tipString = _getPointData(data).toString();
                        tip.style("color", data.seriesPoint.color);
                        document.addEventListener("mousemove", showTipListener);
                    })
                    .on("mouseout", function (e: any, d: any) {
                        document.removeEventListener(
                            "mousemove",
                            showTipListener
                        );
                        tip.style("display", "none");
                    });

                paintGroup
                    .selectAll("text")
                    .data(function (item) {
                        return xKeys.map(function (key) {
                            return {
                                name: key,
                                seriesPoint: item.series.find(
                                    (s) => s.key === key
                                )!,
                            };
                        });
                    })
                    .join("text")
                    .attr("x", function (item) {
                        return (
                            xKeyScale(item.seriesPoint.key)! +
                            xKeyScale.bandwidth() / 2
                        );
                    })
                    .attr("y", function (item) {
                        return yScale(item.seriesPoint.data) - 6;
                    })
                    .attr("font-size", unifiedFontSize)
                    .attr("font-weight", "600")
                    .attr("text-anchor", "middle")
                    .attr("fill", function (item) {
                        return item.seriesPoint.color;
                    })
                    .text(function (item) {
                        return _getPointData(item);
                    });

                svg.append("g")
                    .attr(
                        "transform",
                        "translate(0," + (height - CHART_MARGIN.bottom) + ")"
                    )
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("transform", "rotate(-25 0 -10)");

                svg.append("g")
                    .attr("transform", "translate(" + CHART_MARGIN.left + ",0)")
                    .call(yAxis)
                    .append("text")
                    .text(yAxisName ?? "Value")
                    .attr("transform", "rotate(-90)")
                    .attr("text-anchor", "end")
                    .attr("dy", "-1em")
                    .attr(
                        "style",
                        `fill:${this.props.isDarkTheme ? "white" : "black"}`
                    )
                    .attr("font-size", 12)
                    .attr("font-weight", "700");
            }
        }

        let isShowScrollX = false;
        const stateSetting = {
            legendPoints: legendPoints,
        };
        if (drawingBoard) {
            isShowScrollX =
                drawingBoard?.clientWidth < drawingBoard?.scrollWidth;
        }

        if (isShowScrollX) {
            this.setState({ ...stateSetting, showScrollX: isShowScrollX });
        } else {
            this.setState(stateSetting);
        }
    }

    private _calculateUnifiedFontSize(
        barChartData: IBarChartData[],
        xKeyScaleBandwidth: number
    ) {
        let unifiedFontSize = DEFAULT_FONT_SIZE;
        barChartData.forEach((item) => {
            const fontSizeAdjustModulus = 1.6;
            const pointData = this._getPointData(item);
            const pointStrLength =
                pointData === null ? 0 : pointData.toString()?.length;
            const suitableFontSize =
                (xKeyScaleBandwidth / pointStrLength) * fontSizeAdjustModulus;

            if (unifiedFontSize > suitableFontSize) {
                unifiedFontSize = suitableFontSize;
            }
        });

        return unifiedFontSize;
    }

    private _getPointData(item: IBarChartData): number {
        const { labelPrecision } = this.props;
        const pointData = item.seriesPoint.data;
        const dataType = typeof pointData;
        let result = 0;
        if (dataType === "number" && pointData) {
            result = +pointData.toFixed(labelPrecision ?? 2);
        } else if (dataType === "string" && pointData) {
            result = +parseFloat(pointData.toString()).toFixed(
                labelPrecision ?? 2
            );
        } else {
            result = pointData ?? 0;
        }
        return result;
    }

    private _GetLegendSeriesPoints(
        data: IGroupedBarChartData[]
    ): ChartSeriesPoint[] {
        if (data && data.length > 0) {
            const sample = data.find(
                (d) => d && d.series && d.series.length > 0
            );
            if (sample) {
                const count = sample.series.length;
                let legendPointArr: ChartSeriesPoint[] =
                    new Array<ChartSeriesPoint>(count).fill({
                        key: "",
                        data: 0,
                        color: "",
                        legend: "",
                    });

                data.some((item) => {
                    item.series.forEach((s, i) => {
                        if (
                            s.legend !== "" &&
                            legendPointArr[i].legend === ""
                        ) {
                            legendPointArr[i] = _.cloneDeep(s);
                        }
                    });

                    return (
                        legendPointArr.filter((p) => p.legend === "").length ===
                        0
                    );
                });

                return legendPointArr.filter((p) => p.legend !== "");
            }
        }

        return [];
    }

    private _calculatePaddingInner(barCount: number) {
        if (0 <= barCount && barCount <= 8) {
            if (barCount % 2 !== 0) {
                barCount += 1;
            }
            return 1 - barCount / 10;
        } else {
            return 0.1;
        }
    }

    private _onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        const thisElement = e.currentTarget;
        const scrollLeft = thisElement.scrollWidth - thisElement.clientWidth;
        const leftBtn = thisElement.getElementsByClassName(
            "leftBtn"
        )[0] as HTMLButtonElement;
        const rightBtn = thisElement.getElementsByClassName(
            "rightBtn"
        )[0] as HTMLButtonElement;

        if (leftBtn && leftBtn.style.display === "none") {
            leftBtn.style.display = "block";
        }
        if (rightBtn && rightBtn.style.display === "none") {
            rightBtn.style.display = "block";
        }

        if (thisElement.scrollLeft === 0) {
            if (leftBtn && leftBtn.style.display !== "none") {
                leftBtn.style.display = "none";
            }
        } else if (thisElement.scrollLeft === scrollLeft) {
            if (rightBtn && rightBtn.style.display !== "none") {
                rightBtn.style.display = "none";
            }
        }
    }

    private _onScrollNavBtnClick(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        direct: Direction
    ) {
        const parent = e.currentTarget.parentElement;
        const scrollWidth = parent?.scrollWidth;
        if (parent && scrollWidth !== undefined) {
            const scrollLeft = scrollWidth - parent.clientWidth;
            if (direct === Direction.Left) {
                if (parent.scrollLeft > 0) {
                    parent.scrollLeft -= 80;
                }
            } else if (direct === Direction.Right) {
                if (parent.scrollLeft < scrollLeft) {
                    parent.scrollLeft += 80;
                }
            }
        }
    }
}
