import React from "react";
import { Modal } from "@fluentui/react";

import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { TextlineCharMetric } from "./TextlineView";
import {
    ColumnValueType,
    TableColumn,
    TableList,
    ImageVisualizer,
    OcrPolygon,
    MetricsType,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { formatPercentDisplay } from "../../Utils";
import _ from "lodash";
import { Consumer } from "../../Layout";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { exportTableListData } from "../../Utils/ExportFile";
import { FullScreen } from "../Common/FullScreen";

interface DetectionImageCharMetric extends IMetricUnit {
    gtCount: number;
    fnCount: number;
    fnr: number;
    detectedCount: number;
    fdCount: number;
    fdr: number;
    imageUrl: string;
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<TextlineCharMetric[]>;

interface IState extends IMetricState<DetectionImageCharMetric> {
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
    categoryList: string[];
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    linkData?: {
        toSelectLanguage: string;
        toSelectCategory: string;
    };
    onDismiss?: (imageId: any) => void;
    matchDatasetVersion?: boolean;
}

// prettier-ignore
export const detectionImageColumns: TableColumn[] = [
    { key: "imageId",       name: "ImageId",        isKey: true,  valueType: ColumnValueType.String, minWidth: 100, maxWidth: 150, },
    { key: "gtCount",       name: "GT Count",       isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 150, },
    { key: "detectedCount", name: "Detected Count", isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 250, },
    { key: "fnCount",       name: "FN Count",       isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 250, },
    { key: "fnr",           name: "FNR(%)",         isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 250, },
    { key: "fdCount",       name: "FD Count",       isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 250, },
    { key: "fdr",           name: "FDR(%)",         isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 150, },
];

export class DetectionImageMetricView extends MetricsView<
    IProps,
    IState,
    DetectionImageCharMetric
> {
    constructor(props: IProps) {
        super(props);
        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            categoryList: [],
            selectLanguage: this.props.linkData
                ? this.props.linkData.toSelectLanguage
                : undefined,
            selectCategory: this.props.linkData
                ? this.props.linkData.toSelectCategory
                : undefined,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
        store.dispatch(
            updateStateAction({
                column: detectionImageColumns,
            })
        );
    }

    componentWillMount(): void {
        this.loadStateFromReduxStore();
        super.componentWillMount();
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectImageId, imageVisItems, selectedColumns } =
            this.state;
        const experiments = records.map((record) => record.name);
        let columns: TableColumn[] = detectionImageColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        const dataAfterAdress = _.cloneDeep(evalData);
        let clickNumber: any = "";
        if (!!dataAfterAdress) {
            Object.entries(dataAfterAdress).forEach(([id, content]: any) => {
                content.forEach((con: any, index: any) => {
                    if (!!con) {
                        dataAfterAdress[id][index] = formatPercentDisplay(
                            dataAfterAdress[id][index]!,
                            ["fdr", "fnr"]
                        );
                    }
                });
            });
        }
        this.exportData = dataAfterAdress;
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        return (
            <>
                {selectImageId && (
                    <Modal
                        styles={{
                            main: {
                                width: "100%!important",
                                height: "100%!important",
                            },
                        }}
                        isOpen={!!selectImageId}
                        containerClassName="modal"
                        onDismiss={() =>
                            this.setState({ selectImageId: undefined })
                        }
                    >
                        <ImageVisualizer
                            metricsType={MetricsType.detectionMetrics}
                            experiments={experiments}
                            fileId={selectImageId}
                            evalList={imageItemsForVis}
                            onLoadVisualizer={(imageId) => {
                                if (imageId && evalData[imageId]) {
                                    const mettic = evalData[imageId].find(
                                        (data) => data && data.imageUrl
                                    );
                                    return mettic ? mettic.imageUrl : "";
                                } else {
                                    return "";
                                }
                            }}
                            onRequestPolygons={(imageId?: string) =>
                                this._requestTextlinePolygons(imageId!)
                            }
                            onRequestMetrics={(imageId?: string) =>
                                this._requestTextlineMetrics(imageId!)
                            }
                            setImageMark={this.setImageMark}
                            onDismiss={(imageId: string) =>
                                this.onDismiss(imageId)
                            }
                            clickNumber={this.state.clickNumber}
                        />
                    </Modal>
                )}
                <FullScreen>
                    <Consumer>
                        {(value) => {
                            return (
                                <TableList<DetectionImageCharMetric>
                                    key={this.state.selectLanguage}
                                    evalDataCount={this.props.records.length}
                                    evalData={dataAfterAdress}
                                    columns={columns}
                                    isFullFilterMenu={true}
                                    downloadTableTitle={
                                        this.state.selectLanguage
                                    }
                                    onItemInvoked={(item: any, index: any) => {
                                        clickNumber = index;
                                        const [imageId] = item;
                                        this.setState({
                                            selectImageId: imageId,
                                            clickNumber: clickNumber,
                                        });
                                    }}
                                    renderTableHeader={this._renderTableHeader}
                                    getDisplayEvalData={(
                                        displayItems: any[]
                                    ) => {
                                        this.getDisplayEvalData(displayItems);
                                    }}
                                    isDarkTheme={value}
                                />
                            );
                        }}
                    </Consumer>
                </FullScreen>
            </>
        );
    }

    exportAction = () => {
        exportTableListData(
            this.exportData,
            detectionImageColumns,
            "DetectionImageMetrics"
        );
    };

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: detectionImageColumns,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.GeneralMetrics
                    }`
                }_ByImage`,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<DetectionImageCharMetric>> {
        const { selectCategory: category } = this.state;

        return Promise.all([
            recordDetail.fetchMetricsWithCamelCasing<
                IMetrics<DetectionImageCharMetric>
            >(metricName),
            recordDetail.dataset.fetchImageListByCategory(category!),
        ]).then(([records, imageList]) => {
            Object.entries(records).forEach(([key, val]) => {
                const imageName =
                    imageList.find((image) =>
                        image.toLowerCase().includes(key.toLowerCase())
                    ) ?? `${key}.jpg`;
                records[key].imageUrl = recordDetail.dataset.getImageUrl(
                    `${category}/${imageName}`
                );
            });
            return records;
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language = selectLanguage ?? languageList[0];
            this._onOptionsChanged({
                selectLanguage: language,
            });
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _requestTextlinePolygons(imageId?: string): Promise<OcrPolygons[]> {
        const {
            selectLanguage,
            selectCategory,
            selectImageId,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_alignment_polygons.json"
                        : "alignment_polygons.json";
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(imageId?: string): Promise<OcrMetrics[]> {
        const {
            selectLanguage,
            selectCategory,
            selectImageId,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_textline_word_metrics.json"
                        : "textline_word_metrics.json";
                return detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, selectCategory, matchDatasetVersion } =
            this.state;

        if (records.length > 0) {
            const details = this.filterRecordDetails(
                selectLanguage!,
                matchDatasetVersion!
            );
            this.showEvaluationResult(
                details,
                `${selectCategory}/detector_image_metrics.json`
            );
        }
    };

    private _renderTableHeader(): JSX.Element {
        const {
            categoryList,
            selectLanguage,
            selectCategory,
            matchDatasetVersion,
        } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: "categories",
                text: "Category:",
                options: categoryList,
                selectedKey: selectCategory ?? "BookCover",
                onChange: (catagory) => {
                    this._onOptionsChanged!({
                        selectCategory: catagory!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const { matchDatasetVersion } = this.state;
        let selectLanguage: any;
        let selectCategory: any;
        selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        selectCategory =
            "selectCategory" in newOptions
                ? newOptions["selectCategory"]
                : this.state.selectCategory;

        let categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );

        selectCategory =
            selectCategory && categories.indexOf(selectCategory) >= 0
                ? selectCategory
                : categories[0];

        this.setState(
            {
                selectCategory: selectCategory,
                selectLanguage: selectLanguage,
                categoryList: categories,
            },
            () => this._onQueryButtonClicked()
        );
    }
    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }
    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
