import React from "react";
import { OcrPolygon } from "../../Controls";
import {
    IMetrics,
    IMetricUnit,
    Record,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { OcrBarcodeGeneralMetrics } from "../../Views/OcrBarcode/OcrBarcodeGeneralMetrics";
import { OcrBarcodePerfMetrics } from "../../Views/OcrBarcode/OcrBarcodePerfMetrics";

interface bboxPolygonBase {
    bbox: number[][];
    format: string;
    text: string;
}

interface bboxPolygon extends bboxPolygonBase {
    gt: bboxPolygonBase;
}

export interface barcodePolygon {
    recognized: bboxPolygon[];
    unrecognized: bboxPolygonBase[];
}
export type OcrPolygons = IMetrics<OcrPolygon[]>;
export type OcrMetrics = IMetrics<IMetricUnit[]>;

interface IState {}

interface IProps {
    records: Record[];
    selectedKey: string;
    isDarkTheme?: boolean;
    isReport?: boolean;
}

//Todo: will continue this when data ready
export class OcrBarcodePage extends React.Component<IProps, IState> {
    render(): React.ReactNode {
        const { isDarkTheme, isReport, records, selectedKey } = this.props;
        return (
            <>
                {selectedKey === "ocrBarcodeGeneralMetrics" && (
                    <OcrBarcodeGeneralMetrics
                        saveSetKey={`${Workspaces.OcrBarcode}_${Typename.GeneralMetrics}`}
                        records={records}
                        isReport={isReport}
                        isDarkTheme={isDarkTheme}
                    />
                )}

                {selectedKey === "ocrBarcodePerfMetrics" && (
                    <OcrBarcodePerfMetrics
                        saveSetKey={`${Workspaces.OcrBarcode}_${Typename.PerformanceMetrics}`}
                        records={records}
                        isReport={isReport}
                        isDarkTheme={isDarkTheme}
                    />
                )}
            </>
        );
    }

    static fetchImageUrl(
        dataMap: Map<string, any[]>,
        selectCategory?: string,
        imageId?: string
    ): string {
        if (selectCategory && imageId) {
            const imageRawArr = dataMap.get(imageId);
            const keyImageRaw = imageRawArr?.find(
                (imageRaw) => imageRaw?.imageUrl
            );

            if (keyImageRaw && keyImageRaw.imageUrl) {
                return keyImageRaw.imageUrl;
            }
        }

        return "";
    }

    static fetchPolygons(
        details: RecordDetail[],
        imgId?: string,
        selectCategory?: string
    ): Promise<OcrPolygons[]> {
        if (imgId && selectCategory) {
            return Promise.all(
                details.map((detail) => {
                    return detail
                        .fetchMetrics<any>(`accuracy/barcode-visualize.json`)
                        .then((collection) => {
                            const ocrPolygons: any = {};
                            const bboxPolygonItem = collection[selectCategory][
                                imgId
                            ] as barcodePolygon;
                            if (bboxPolygonItem) {
                                let idNum = 0;
                                const recognizedPolygonArr =
                                    bboxPolygonItem.recognized.map((reco) => {
                                        const standardPolygon = {
                                            textline_id:
                                                this.generateTextlineId(idNum),
                                            recognize_words: [
                                                {
                                                    polygon: reco.bbox.map(
                                                        (numArr: Number[]) => {
                                                            const str =
                                                                numArr.join(
                                                                    ","
                                                                );
                                                            return str;
                                                        }
                                                    ),
                                                    text: reco.text,
                                                },
                                            ],
                                            reference_words: reco.gt
                                                ? [
                                                      {
                                                          polygon:
                                                              reco.gt.bbox.map(
                                                                  (
                                                                      numArr: Number[]
                                                                  ) => {
                                                                      const str =
                                                                          numArr.join(
                                                                              ","
                                                                          );
                                                                      return str;
                                                                  }
                                                              ),
                                                          text: reco.gt.text,
                                                      },
                                                  ]
                                                : [],
                                        };

                                        idNum++;
                                        return standardPolygon;
                                    });

                                const unrecognizedPolygonArr =
                                    bboxPolygonItem.unrecognized.map(
                                        (unReco) => {
                                            const standardPolygon = {
                                                textline_id:
                                                    this.generateTextlineId(
                                                        idNum
                                                    ),
                                                recognize_words: [],
                                                reference_words: [
                                                    {
                                                        polygon:
                                                            unReco.bbox.map(
                                                                (
                                                                    numArr: Number[]
                                                                ) => {
                                                                    const str =
                                                                        numArr.join(
                                                                            ","
                                                                        );
                                                                    return str;
                                                                }
                                                            ),
                                                        text: unReco.text,
                                                    },
                                                ],
                                            };

                                            idNum++;
                                            return standardPolygon;
                                        }
                                    );

                                ocrPolygons[imgId] = [
                                    ...recognizedPolygonArr,
                                    ...unrecognizedPolygonArr,
                                ];
                            }

                            return ocrPolygons;
                        });
                })
            );
        } else {
            return Promise.resolve([]);
        }
    }

    static fetchMetrics(
        details: RecordDetail[],
        imgId?: string,
        selectCategory?: string
    ): Promise<OcrMetrics[]> {
        if (imgId && selectCategory) {
            return Promise.all(
                details.map((detail) => {
                    return detail
                        .fetchMetrics<any>(`accuracy/barcode-visualize.json`)
                        .then((collection) => {
                            const metrics: OcrMetrics = {};
                            const bboxPolygonItem = collection[selectCategory][
                                imgId
                            ] as barcodePolygon;

                            if (bboxPolygonItem) {
                                let idNum = 0;
                                const recognizedMetricsArr =
                                    bboxPolygonItem.recognized.map((reco) => {
                                        const metric = {
                                            textline_id:
                                                this.generateTextlineId(idNum),
                                            Recognition:
                                                "===============================",
                                            RecogBoundingBox: reco.bbox
                                                .map(
                                                    (numArr: Number[]) =>
                                                        `(${numArr.join(",")})`
                                                )
                                                .join(),
                                            RecogType: reco.format,
                                            RecogContent: reco.text,

                                            Label: "===================================",
                                            LabelBoundingBox: reco.gt
                                                ? reco.gt.bbox
                                                      .map(
                                                          (numArr: Number[]) =>
                                                              `(${numArr.join(
                                                                  ","
                                                              )})`
                                                      )
                                                      .join()
                                                : "",
                                            LabelType: reco.gt
                                                ? reco.gt.format
                                                : "",
                                            LabelContent: reco.gt
                                                ? reco.gt.text
                                                : "",
                                        };

                                        idNum++;
                                        return metric;
                                    });

                                const unrecognizedMetricsArr =
                                    bboxPolygonItem.unrecognized.map(
                                        (unReco) => {
                                            const metric = {
                                                textline_id:
                                                    this.generateTextlineId(
                                                        idNum
                                                    ),
                                                Recognition:
                                                    "===============================",
                                                RecogBoundingBox: "",
                                                RecogType: "",
                                                RecogContent: "",

                                                Label: "===================================",
                                                LabelBoundingBox: unReco.bbox
                                                    .map(
                                                        (numArr: Number[]) =>
                                                            `(${numArr.join(
                                                                ","
                                                            )})`
                                                    )
                                                    .join(),
                                                LabelType: unReco.format,
                                                LabelContent: unReco.text,
                                            };

                                            idNum++;
                                            return metric;
                                        }
                                    );

                                metrics[imgId] = [
                                    ...recognizedMetricsArr,
                                    ...unrecognizedMetricsArr,
                                ];
                            }

                            return metrics;
                        });
                })
            );
        } else {
            return Promise.resolve([]);
        }
    }

    static generateTextlineId(idNum: number): string {
        return `temporary_id_${idNum}`;
    }
}
