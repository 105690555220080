import "../Common/MetricStyle.scss";
import React from "react";

import {
    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,

    //Group&DetailList
    DetailsList,
    IColumn,
    SelectionMode,

    //Others
    Stack,
    Label,
    buildColumns,
    mergeStyles,
    FontIcon,
    getTheme,
    Link,
} from "@fluentui/react";

import { Line } from "react-chartjs-2";

import { Record, RecordDetail } from "../../DataContract";

import { getRandomColor } from "../../Utils";
import { ExpandButton } from "../../Pages/common/ExpandButton";

import { ExpandCard } from "../../Controls/Common/ExpandCard";
import _ from "lodash";
import { Consumer } from "../../Layout";
import { store } from "../../../store";
import { updateExpandAction } from "../../../store/reducers";
import { FullScreen } from "../Common/FullScreen";

interface ChartData {
    [key: string]: string[];
}

interface TableData {
    [key: string]: { [key: string]: { [key: string]: string } };
}

export interface OverallMetrics {
    cogsResult: {};
    cogsOverallResult: TableData;
    loadResult: TableData;
    reliResult: TableData;
    perfResult: TableData;
    sanityResult: TableData;
    verticalSanityResult: TableData;
    formRecognizer30SanityResult: TableData;
    cpuUsages: ChartData;
    memUsages: ChartData;
    accuracyResult: {};
}

interface IState {
    data?: [RecordDetail, OverallMetrics];
    expandAll?: boolean;
    expandItem?: Map<number, boolean>;
}

interface IProps {
    records: Record[];
}

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "0 5px",
});

const theme = getTheme();

export class ReleaseTestOverview extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            expandAll: true,
            expandItem: new Map<number, boolean>(),
        };
    }

    public render() {
        const { data } = this.state;

        let fnCreateDocumentCard = (
            key: string,
            title: string,
            detailList?: JSX.Element
        ) => {
            if (detailList === undefined) {
                return <DocumentCard></DocumentCard>;
            } else {
                return (
                    <DocumentCard
                        key={key}
                        styles={{
                            root: { width: "100%" },
                        }}
                        type={DocumentCardType.normal}
                    >
                        <Stack
                            horizontal
                            verticalAlign="baseline"
                            gap={5}
                            styles={{
                                root: {
                                    marginTop: "5px",
                                    marginLeft: "25px",
                                    height: "10px",
                                    paddingTop: "18px",
                                },
                            }}
                        >
                            <Consumer>
                                {(value) => {
                                    return (
                                        <FontIcon
                                            aria-label="Compass"
                                            iconName="TestBeakerSolid"
                                            className={iconClass}
                                            style={{
                                                color: value
                                                    ? theme.palette.white
                                                    : theme.palette.black,
                                            }}
                                        />
                                    );
                                }}
                            </Consumer>

                            <DocumentCardTitle
                                styles={{ root: { padding: 0 } }}
                                title={title}
                            />
                        </Stack>
                        <DocumentCardDetails>{detailList}</DocumentCardDetails>
                    </DocumentCard>
                );
            }
        };
        return (
            <FullScreen>
                <Stack verticalFill style={{ overflow: "auto" }}>
                    <Consumer>
                        {(value) => {
                            return (
                                <>
                                    {this._renderLineChart(
                                        "CPU Usages",
                                        data && data[1].cpuUsages
                                            ? data[1].cpuUsages
                                            : {},
                                        value
                                    )}
                                    {this._renderLineChart(
                                        "MEMORY Usages",
                                        data && data[1].memUsages
                                            ? data[1].memUsages
                                            : {},
                                        value
                                    )}
                                </>
                            );
                        }}
                    </Consumer>

                    <Stack horizontal verticalAlign="center">
                        <Label>Test Records</Label>
                        <span style={{ marginLeft: "1%" }}>|</span>
                        <ExpandButton
                            workspace={"releasetest"}
                            expandAll={this.state.expandAll}
                            onChange={(value: any) => {
                                this.setState({ expandAll: value });
                            }}
                        />
                    </Stack>
                    {fnCreateDocumentCard(
                        "card_cogs_test",
                        "COGS TEST",
                        data && Object.keys(data[1].cogsOverallResult).length
                            ? this._renderDetailList(
                                  0,
                                  data[0].name,
                                  data[1].cogsOverallResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_perf_test",
                        "PERF TEST",
                        data && Object.keys(data[1].perfResult).length
                            ? this._renderDetailList(
                                  1,
                                  data[0].name,
                                  data[1].perfResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_reli_test",
                        "RELI TEST",
                        data && Object.keys(data[1].reliResult).length
                            ? this._renderDetailList(
                                  2,
                                  data[0].name,
                                  data[1].reliResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_load_test",
                        "LOAD TEST",
                        data && Object.keys(data[1].loadResult).length
                            ? this._renderDetailList(
                                  3,
                                  data[0].name,
                                  data[1].loadResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_sanity_test",
                        "SANITY TEST",
                        data && Object.keys(data[1].sanityResult).length
                            ? this._renderDetailList(
                                  4,
                                  data[0].name,
                                  data[1].sanityResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_versanity_test",
                        "VERTICAL SANITY TEST",
                        data && Object.keys(data[1].verticalSanityResult).length
                            ? this._renderDetailList(
                                  5,
                                  data[0].name,
                                  data[1].verticalSanityResult
                              )
                            : undefined
                    )}
                    {fnCreateDocumentCard(
                        "card_formRecognizer30sanity_test",
                        "FORM RECOGNIZER 30 SANITY TEST",
                        data &&
                            Object.keys(data[1].formRecognizer30SanityResult)
                                .length
                            ? this._renderDetailList(
                                  6,
                                  data[0].name,
                                  data[1].formRecognizer30SanityResult
                              )
                            : undefined
                    )}
                </Stack>
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryMetricsResult();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.records !== prevProps.records) {
            this._queryMetricsResult();
        }
        if (prevState.expandAll !== this.state.expandAll) {
            const expand_item = _.clone(this.state.expandItem);
            for (const key of this.state.expandItem?.keys() || []) {
                expand_item?.set(key, this.state.expandAll ?? true);
            }

            this.setState({
                expandItem: expand_item,
            });
        }
    }

    public _queryMetricsResult() {
        const { records } = this.props;
        if (records && records.length > 0) {
            const details = records[0].getDetails();
            if (details && details.length > 0) {
                details[0]
                    .fetchMetricsWithRecordDetail<OverallMetrics>("overall")
                    .then((metrics) => {
                        this.setState({
                            data: metrics,
                        });
                    });

                return;
            }
        }
    }

    private _renderDetailList(index: any, recordId: string, data: TableData) {
        const { expandItem } = this.state;
        const items = Object.entries(data).flatMap(([groupName, groupData]) => {
            return Object.entries(groupData).map(([name, val], index) => {
                return {
                    key: `${index}_${groupName}_${name}_items`,
                    metrics: name,
                    [recordId]: val,
                };
            });
        });

        const urlRegExp = new RegExp("^(http|https)://", "i");
        const columns: IColumn[] = buildColumns(items, true)
            .filter((column) => column.key !== "key")
            .map((col) => {
                col.onRender = (
                    item?: any,
                    index?: number,
                    column?: IColumn
                ) => {
                    if (item && column && column.fieldName) {
                        const val = item[column.fieldName];
                        return typeof val === "string" &&
                            column.fieldName !== "metrics" &&
                            urlRegExp.test(val) ? (
                            <Link
                                href={val}
                                target="_blank"
                                styles={{
                                    root: {
                                        color: "#75b6e7",
                                    },
                                }}
                            >
                                {val}
                            </Link>
                        ) : (
                            <>{val}</>
                        );
                    } else {
                        return <></>;
                    }
                };

                return col;
            });

        let curIdx = 0;
        const groups = Object.entries(data).map(
            ([groupName, groupData], groupIdx) => {
                const count = Object.keys(groupData).length;
                const data = {
                    count: count,
                    key: `${groupName}_${groupIdx}`,
                    name: groupName,
                    startIndex: curIdx,
                    level: 0,
                };
                curIdx += count;
                return data;
            }
        );

        return (
            <Consumer>
                {(value) => {
                    return (
                        <ExpandCard
                            display={false}
                            expandAll={
                                expandItem?.get(index) ?? this.state.expandAll
                            }
                            onExpandChange={(expand: boolean) => {
                                expandItem?.set(index, expand);
                                this.setState(
                                    { expandItem: expandItem },
                                    () => {
                                        let expandCount = 0;
                                        for (const value of this.state.expandItem?.values() ||
                                            []) {
                                            if (value) {
                                                expandCount++;
                                            }
                                        }
                                        const total = this.getDataCount();
                                        if (
                                            (expandCount === 0 &&
                                                this.state.expandItem?.size ===
                                                    total) ||
                                            expandCount === total
                                        ) {
                                            this.setState(
                                                {
                                                    expandAll:
                                                        expandCount === total,
                                                },
                                                () => {
                                                    store.dispatch(
                                                        updateExpandAction(
                                                            expandCount ===
                                                                total
                                                        )
                                                    );
                                                }
                                            );
                                        }
                                    }
                                );
                            }}
                            isDarkTheme={value}
                        >
                            <DetailsList
                                key={JSON.stringify(this.state.expandAll)}
                                items={items}
                                columns={columns}
                                groups={groups}
                                styles={{ root: { padding: 0, margin: 0 } }}
                                groupProps={{
                                    onRenderHeader: (props) => (
                                        <Label
                                            styles={{
                                                root: { marginLeft: "15px" },
                                            }}
                                        >
                                            {props!.group!.name.toUpperCase()}
                                        </Label>
                                    ),
                                }}
                                selectionMode={SelectionMode.none}
                            />
                        </ExpandCard>
                    );
                }}
            </Consumer>
        );
    }

    private getDataCount = () => {
        const { data } = this.state;
        if (data) {
            const res = data[1];
            const keys = [
                "cogsOverallResult",
                "loadResult",
                "reliResult",
                "perfResult",
                "sanityResult",
                "verticalSanityResult",
                "formRecognizer30SanityResult",
            ];
            const havevalue = keys.filter(
                (key: string) =>
                    Object.keys(res[key as keyof OverallMetrics]).length > 0
            );

            return havevalue.length;
        }

        return 0;
    };

    public _renderLineChart(title: string, chartData: ChartData, dark = false) {
        let max_len = 720;

        // This is to support sample rate more than 1min
        for (let value of Object.values(chartData)) {
            max_len = Math.max(max_len, value.length);
        }
        let labels = [...Array(max_len)].map((_, idx) => idx);

        const datasets = Object.entries(chartData).map(([name, value]) => {
            var index = name.lastIndexOf("@");
            const borderColor = getRandomColor();
            return {
                label: name.substring(0, index),
                fill: false,
                borderColor: borderColor,
                borderCapStyle: "butt",
                borderJoinStyle: "miter",
                pointBackgroundColor: borderColor,
                pointHoverRadius: 10,
                pointHoverBackgroundColor: borderColor,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                data: value,
            };
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };

        const axes = [
            {
                ticks: {
                    fontColor: dark ? "white" : "#666",
                },
                gridLines: {
                    color: dark ? "rgba(255,255,255,0.25)" : "rgba(0,0,0,0.1)",
                    zeroLineColor: dark ? "white" : "rgba(0,0,0,0.25)",
                },
            },
        ];

        const options = {
            legend: {
                labels: {
                    fontColor: dark ? "white" : "#666",
                },
            },

            scales: {
                xAxes: axes,
                yAxes: axes,
            },
        };

        return (
            <Consumer>
                {(value) => {
                    return (
                        <div>
                            <Label>
                                <h2>{title}</h2>
                            </Label>
                            <div
                                className="drawingContainer"
                                style={{
                                    color: value ? "white" : "black",
                                }}
                            >
                                <Line
                                    data={data}
                                    options={options}
                                    height={100}
                                />
                            </div>
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
