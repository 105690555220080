import * as React from "react";
import "./Catalog.scss";
import {
    //DetailList
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsListProps,
    IDetailsRowStyles,
    SelectionMode,
    Selection,

    //Sticky
    ConstrainMode,
    IRenderFunction,
    IDetailsHeaderProps,
    IDetailsColumnRenderTooltipProps,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,

    //Others
    Label,
    Stack,
    TooltipHost,
    getTheme,
    IDropdownOption,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    IObjectWithKey,
    SelectAllVisibility,
} from "@fluentui/react";
import {
    getFromLocalStorage,
    loadRecords,
    loadTotal,
    saveToLocalStorage,
} from "../Utils";
import { DatasetCreationPanel } from "../Views/Vertical/DatasetCreationPanel";
import {
    FAVOURITE,
    PAGE_SIZE,
    WorkspaceGroups,
    Workspaces,
    Record,
    PORTAL_VER,
    STORAGE_VER,
} from "../DataContract/";
import { MultipleFilterBar } from "./MultipleFilterBar";
import { MultipleSelectionBar } from "./MultipleSelectionBar";
import { RecordColumn } from "./Common/RecordColumn";
import { Pagination } from "./Pagination";
import _ from "lodash";
import { Consumer } from "../Layout";
import { LoadingCoverIcon } from "./LoadingcoverIcon";

interface IProps {
    onItemClicked: (item: Record) => void;
    initSearchString?: string | null;
    columns: IColumn[];
    title: string;
    workspace: string;
    isModal?: boolean;
    isDarkTheme?: boolean;
    clearCache?: boolean;
    getTagColor?: (tagColor: any) => void;
}

interface IState {
    displayItems: Record[];
    columns: IColumn[];
    favoriteItems?: string[];
    visibleCalloutId?: string;
    allRecordLoaded: boolean;
    searchSelect: string;
    searchOptions: IDropdownOption[];
    showPrivacy: boolean;
    showWarning: boolean;
    isOpenDatasetCreationPanel: boolean;
    selectedRecords: Record[];
    editTags: boolean;
    currentPage?: number;
    totalPage: number;
}

const SEARCH_OPTIONS: IDropdownOption[] = [
    { key: "modelInfo", text: "Model" },
    { key: "runtimeVersion", text: "Runtime" },
    { key: "buildSource", text: "BuildSource" },
    { key: "details#algorithm", text: "Algorithm" },
    { key: "testType", text: "TestType" },
    { key: "tag", text: "Tag" },
];

const VERTICAL_SEARCH_OPTIONS: IDropdownOption[] = [
    { key: "details#expInfo.expRunId", text: "ExpRunId" },
    { key: "details#expInfo.stepRunId", text: "StepRunId" },
    { key: "modelInfo", text: "Model" },
    { key: "runtimeVersion", text: "Runtime" },
    { key: "buildSource", text: "BuildSource" },
    { key: "tag", text: "Tag" },
];

const CUSTOM_FORM_SEARCH_OPTIONS: IDropdownOption[] = [
    { key: "modelInfo", text: "Model" },
    { key: "testType", text: "TestType" },
    { key: "tag", text: "Tag" },
];

const REALEASE_TEST_SEARCH_OPTIONS: IDropdownOption[] = [
    { key: "id", text: "ID" },
    { key: "tag", text: "Tag" },
];

const VERTICAL_TLM_SEARCH_OPTIONS: IDropdownOption[] = [
    { key: "tag", text: "Tag" },
];

const IGNORED_LOCAL_STORAGE_KEYS = [PORTAL_VER, STORAGE_VER, FAVOURITE];

const theme = getTheme();
const freezeStyles = mergeStyleSets({
    wrapper: {
        width: "100%",
        height: "100%",
        bottom: 0,
        position: "relative",
        top: 0,
        backgroundColor: "white",
    },
    wrapperModal: {
        width: "100%",
        height: "100%",
        bottom: 0,
        position: "relative",
        top: 0,
        backgroundColor: "white",
    },
});

export class Catalog extends RecordColumn<IProps, IState> {
    private _selection: Selection;
    private _searchsField: any[] = [];

    constructor(props: IProps) {
        super(props);

        const { workspace } = this.props;

        this._updateDisplayItems = this._updateDisplayItems.bind(this);
        this._onAddRecord = this._onAddRecord.bind(this);
        this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
        this._onSelectionChanged = this._onSelectionChanged.bind(this);
        this._removeSelectRecord = this._removeSelectRecord.bind(this);
        this._multipleFilterRecords = this._multipleFilterRecords.bind(this);
        this._getTotalPage = this._getTotalPage.bind(this);
        this._clearList = this._clearList.bind(this);
        this._onPageChange = this._onPageChange.bind(this);
        this._debounceOnPageChange = _.debounce(
            this._debounceOnPageChange.bind(this),
            1000
        );

        const columns = this._generateColumns(this.props.columns);

        const searchOptions = WorkspaceGroups.ReleaseTests.includes(workspace)
            ? REALEASE_TEST_SEARCH_OPTIONS
            : workspace === Workspaces.VerticalTelemetry
            ? VERTICAL_TLM_SEARCH_OPTIONS
            : workspace === Workspaces.CustomForm ||
              workspace === Workspaces.QueryField
            ? CUSTOM_FORM_SEARCH_OPTIONS
            : WorkspaceGroups.Taipei.includes(workspace)
            ? VERTICAL_SEARCH_OPTIONS
            : SEARCH_OPTIONS;

        this._selection = new Selection({
            onSelectionChanged: this._onSelectionChanged,
            getKey: (item: IObjectWithKey) => (item as Record).id,
        });

        this.state = {
            displayItems: [],
            columns: columns,
            favoriteItems: getFromLocalStorage(FAVOURITE)?.split(",") ?? [],
            allRecordLoaded: false,
            visibleCalloutId: "-1",
            searchSelect: searchOptions[0].key.toString(),
            searchOptions: searchOptions,
            showPrivacy: true,
            showWarning: false,
            isOpenDatasetCreationPanel: false,
            selectedRecords: [],
            editTags: true,
            currentPage: 1,
            totalPage: 0,
        };
    }

    public render() {
        const {
            columns,
            displayItems,
            favoriteItems,
            isOpenDatasetCreationPanel,
            showPrivacy,
            searchOptions,
            selectedRecords,
            currentPage,
            totalPage,
        } = this.state;

        const { isModal, title, workspace, onItemClicked, isDarkTheme } =
            this.props;

        let sortedItems = this._sortDisplayItems(
            workspace,
            displayItems,
            favoriteItems
        );

        const isVerticalTlmWorkspace =
            workspace === Workspaces.VerticalTelemetry;

        return (
            <Stack verticalFill className="title">
                <Stack horizontal verticalAlign="center">
                    <Label
                        style={{
                            color: this.props.isDarkTheme
                                ? theme.palette.white
                                : theme.palette.black,
                        }}
                        className="pageLabel"
                    >
                        {title}
                    </Label>

                    <Stack
                        horizontal
                        verticalAlign="end"
                        style={{
                            marginLeft: "auto",
                            alignItems: "center",
                        }}
                    >
                        {!!!isModal && isVerticalTlmWorkspace && (
                            <PrimaryButton
                                text="Create Base Dataset"
                                className="createBtn"
                                onClick={() => {
                                    this.setState({
                                        isOpenDatasetCreationPanel: true,
                                    });
                                }}
                            />
                        )}
                    </Stack>
                </Stack>

                <MultipleSelectionBar
                    columns={this.props.columns}
                    selectedRecords={selectedRecords}
                    workspace={workspace}
                    onRemove={this._removeSelectRecord}
                    isDarkTheme={isDarkTheme}
                />

                <MultipleFilterBar
                    isDarkTheme={isDarkTheme}
                    searchOptions={searchOptions}
                    initSearchString={this.props.initSearchString}
                    refreshList={(searchs: { key: string; value: string }[]) =>
                        this._multipleFilterRecords(searchs)
                    }
                    clearList={this._clearList}
                />

                {showPrivacy && (
                    <MessageBar
                        onDismiss={() => {
                            this.setState({ showPrivacy: false });
                        }}
                        dismissButtonAriaLabel="Close"
                        messageBarType={MessageBarType.info}
                        messageBarIconProps={{ iconName: "InfoSolid" }}
                        styles={{
                            root: {
                                backgroundColor: "#eff6fc",
                            },
                            icon: {
                                color: "#0078d4",
                            },
                        }}
                    >
                        <span>
                            Tip:&nbsp;&nbsp;Double click to open records
                        </span>
                    </MessageBar>
                )}
                {!isModal && (
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <LoadingCoverIcon />
                        <Stack verticalFill className={freezeStyles.wrapper}>
                            <ScrollablePane
                                scrollbarVisibility={ScrollbarVisibility.auto}
                                styles={{
                                    root: {
                                        zIndex: "99999",
                                        background: `${
                                            isDarkTheme
                                                ? theme.palette.black
                                                : theme.palette.white
                                        }`,
                                    },
                                    contentContainer: {
                                        height: "100%",
                                        overflowX: "hidden",

                                        ".ms-Viewport": {
                                            width: "100%",
                                            marginLeft: "0px",
                                        },
                                        "::-webkit-scrollbar": {
                                            width: 12,
                                            height: 12,
                                        },
                                        "::-webkit-scrollbar-thumb": {
                                            border: "4px solid transparent",
                                            boxShadow: "none",
                                            backgroundColor:
                                                "rgba(189,189,189,0.8)",
                                            backgroundClip: "padding-box",
                                        },

                                        "::-webkit-scrollbar-thumb:hover": {
                                            backgroundColor: "#bdbdbd",
                                        },

                                        "::-webkit-scrollbar-track": {
                                            boxShadow: "none",
                                        },
                                    },
                                }}
                            >
                                <DetailsList
                                    items={sortedItems}
                                    columns={columns}
                                    className="catalog__table"
                                    selectionMode={SelectionMode.multiple}
                                    selection={this._selection}
                                    enterModalSelectionOnTouch
                                    selectionPreservedOnEmptyClick
                                    selectionZoneProps={{
                                        selection: this._selection,
                                        selectionClearedOnSurfaceClick: false,
                                    }}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={
                                        this._onRenderDetailsHeader
                                    }
                                    onItemInvoked={onItemClicked}
                                    onRenderRow={this._onRenderRow}
                                    onColumnHeaderClick={(
                                        ev?: React.MouseEvent<HTMLElement>,
                                        column?: IColumn
                                    ) =>
                                        this._updateDisplayItems(
                                            this.state.displayItems,
                                            column
                                        )
                                    }
                                />
                                {sortedItems.length > 0 && (
                                    <Pagination
                                        isDarkTheme={this.props.isDarkTheme}
                                        currentPage={currentPage}
                                        pageCount={PAGE_SIZE}
                                        totalCount={totalPage}
                                        onPageChange={this._onPageChange}
                                    />
                                )}
                            </ScrollablePane>
                        </Stack>
                    </div>
                )}
                {isModal && (
                    <Stack verticalFill className={freezeStyles.wrapperModal}>
                        <Consumer>
                            {(value) => {
                                return (
                                    <ScrollablePane
                                        scrollbarVisibility={
                                            ScrollbarVisibility.auto
                                        }
                                        styles={{
                                            root: { zIndex: "99999" },
                                            contentContainer: {
                                                height: "100%",
                                                overflowX: "hidden",
                                                backgroundColor: value
                                                    ? "black"
                                                    : "white",
                                                ".ms-Viewport": {
                                                    width: "100%",
                                                    marginLeft: "0px",
                                                },
                                                "::-webkit-scrollbar": {
                                                    width: 12,
                                                    height: 12,
                                                },
                                                "::-webkit-scrollbar-thumb": {
                                                    border: "4px solid transparent",
                                                    boxShadow: "none",
                                                    backgroundColor:
                                                        "rgba(189,189,189,0.8)",
                                                    backgroundClip:
                                                        "padding-box",
                                                },

                                                "::-webkit-scrollbar-thumb:hover":
                                                    {
                                                        backgroundColor:
                                                            "#bdbdbd",
                                                    },

                                                "::-webkit-scrollbar-track": {
                                                    boxShadow: "none",
                                                },
                                            },
                                        }}
                                    >
                                        <DetailsList
                                            items={sortedItems}
                                            columns={columns}
                                            className="catalog__table"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={
                                                DetailsListLayoutMode.justified
                                            }
                                            constrainMode={
                                                ConstrainMode.unconstrained
                                            }
                                            onRenderDetailsHeader={
                                                this._onRenderDetailsHeader
                                            }
                                            onItemInvoked={onItemClicked}
                                            onRenderRow={this._onRenderRow}
                                            onColumnHeaderClick={(
                                                ev?: React.MouseEvent<HTMLElement>,
                                                column?: IColumn
                                            ) =>
                                                this._updateDisplayItems(
                                                    this.state.displayItems,
                                                    column
                                                )
                                            }
                                        />
                                        {sortedItems.length > 0 && (
                                            <Pagination
                                                isDarkTheme={
                                                    this.props.isDarkTheme
                                                }
                                                currentPage={currentPage}
                                                pageCount={PAGE_SIZE}
                                                totalCount={totalPage}
                                                onPageChange={
                                                    this._onPageChange
                                                }
                                            />
                                        )}
                                    </ScrollablePane>
                                );
                            }}
                        </Consumer>
                    </Stack>
                )}

                {workspace === Workspaces.VerticalTelemetry && (
                    <DatasetCreationPanel
                        isOpen={isOpenDatasetCreationPanel}
                        workspace={workspace}
                        onCloseModal={() =>
                            this.setState({
                                isOpenDatasetCreationPanel: false,
                            })
                        }
                        onAddRecord={this._onAddRecord}
                    ></DatasetCreationPanel>
                )}
            </Stack>
        );
    }

    public async componentDidMount() {
        const favItemsStr = getFromLocalStorage(FAVOURITE);
        const favRecordIds = favItemsStr
            ? favItemsStr.split(",").filter((id) => id)
            : null;

        let searchStr = "";
        if (!!this.props.initSearchString) {
            const fileds = this.props.initSearchString.split("@");
            searchStr = this._getSearchStr([
                { key: "modelInfo", value: fileds[0] },
                { key: "runtimeVersion", value: fileds[1] },
            ]);
        }

        loadRecords(
            this.props.workspace,
            1,
            searchStr,
            PAGE_SIZE,
            favRecordIds
        ).then((items) => {
            this.setState({
                displayItems: items,
            });
        });

        const totalPage = await loadTotal(this.props.workspace, searchStr);

        this.setState({
            totalPage: totalPage,
        });

        !!this.props.clearCache && this._clearCache();
    }
    public componentDidUpdate() {
        this.state.selectedRecords.map((record) =>
            this._selection.setKeySelected(record.id, true, false)
        );
    }

    private _onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
        props,
        defaultRender
    ) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<
            IDetailsColumnRenderTooltipProps
        > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        props.selectAllVisibility = SelectAllVisibility.hidden;
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    private _getSearchStr(searchs: { key: string; value: string }[]) {
        let searchString = "";

        const searchArr = searchs
            .filter((search) => !search.key.includes("details#"))
            .map(({ key, value }) => {
                let searchStr = "";
                searchStr = `${key}=${value}`;
                return searchStr;
            });

        const detailArr = searchs.filter((search) =>
            search.key.includes("details#")
        );

        if (detailArr.length > 0) {
            const detailKeys = detailArr.map((search) =>
                search.key.replace("details#", "")
            );
            const detailValues = detailArr.map((search) => search.value);

            searchArr.push(
                `detailKey=${detailKeys.join(
                    ","
                )}&detailValue=${detailValues.join(",")}`
            );
        }

        searchString = searchArr.join("&");
        return searchString;
    }

    private async _multipleFilterRecords(
        searchs: { key: string; value: string }[]
    ) {
        this._searchsField = searchs;
        this._debounceOnPageChange(1, this._getTotalPage);
    }

    private async _clearList() {
        this._searchsField = [];

        this._debounceOnPageChange(1, this._getTotalPage);
    }

    private async _getTotalPage() {
        const searchStr = this._getSearchStr(this._searchsField);

        const totalPage = await loadTotal(this.props.workspace, searchStr);
        this.setState({
            currentPage: 1,
            totalPage: totalPage,
        });
    }

    private async _onPageChange(page: number) {
        this.setState({
            currentPage: page,
        });

        this._debounceOnPageChange(page);
    }
    private _debounceOnPageChange(page: number, getTotalPage?: () => void) {
        const searchStr = this._getSearchStr(this._searchsField);
        const favItemsStr = getFromLocalStorage(FAVOURITE);
        const favRecordIds =
            favItemsStr && page === 1
                ? favItemsStr.split(",").filter((id) => id)
                : null;
        loadRecords(
            this.props.workspace,
            page,
            searchStr,
            PAGE_SIZE,
            favRecordIds
        ).then((items) => {
            this._updateDisplayItems(items, undefined);
        });
        if (getTotalPage) {
            getTotalPage();
        }
    }

    private _updateDisplayItems = (
        items: Record[],
        sortByColumn?: IColumn
    ): void => {
        let columns: IColumn[] = this.state.columns;
        if (sortByColumn) {
            columns = columns.map((col) => {
                const newCol = { ...col };
                if (col.key === sortByColumn!.key) {
                    newCol.isSorted = true;
                    newCol.isSortedDescending =
                        !sortByColumn!.isSortedDescending;
                } else {
                    newCol.isSorted = undefined;
                    newCol.isSortedDescending = undefined;
                }
                return newCol;
            });
        } else {
            const result = columns.filter((col) => !!col.isSorted);
            if (result.length > 0) {
                sortByColumn = columns[0];
            }
        }

        if (sortByColumn !== undefined) {
            items = items.sort((a: any, b: any) => {
                const key = sortByColumn!.key;
                let pre = a[key as keyof Record];
                let cur = b[key as keyof Record];

                pre = pre.toString();
                cur = cur.toString();
                let val = !!sortByColumn!.isSortedDescending
                    ? pre < cur
                    : pre > cur;
                return val ? 1 : -1;
            });
        }

        this.setState({
            displayItems: items,
            columns: columns,
        });
    };

    private _generateColumns(column: IColumn[]): IColumn[] {
        let columns = column.map((col) => {
            return {
                ...col,
                isResizable: true,
                onRender: this._onColumnRender,
            };
        });
        return columns;
    }

    private _onAddRecord(record: Record) {
        if (record) {
            const { displayItems, favoriteItems } = this.state;
            const { workspace } = this.props;
            displayItems.unshift(record);

            const sortedItems = this._sortDisplayItems(
                workspace,
                displayItems,
                favoriteItems
            );

            this.setState({
                displayItems: sortedItems,
                visibleCalloutId: "-1",
            });
        }
    }

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        customStyles.root = {
            "&:focus": {
                ".ms-DetailsRow-check": {
                    opacity: "none",
                },
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                customStyles.root.backgroundColor = this.props.isDarkTheme
                    ? theme.palette.neutralDark
                    : theme.palette.neutralLighterAlt;
            } else {
                customStyles.root.backgroundColor = this.props.isDarkTheme
                    ? theme.palette.neutralPrimary
                    : theme.palette.white;
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    private _onSelectionChanged() {
        this.setState((prevState) => {
            const preSelectedRecords = prevState.selectedRecords.slice();
            const newSelectedRecords = this._selection
                .getSelection()
                .slice() as Record[];

            const selectedRecordsInPrev = preSelectedRecords.filter(
                (record) => {
                    return newSelectedRecords.some((item) => {
                        if (item.id === record.id) {
                            return true;
                        }
                        return false;
                    });
                }
            );

            const unSelectedRecordsInPrev = newSelectedRecords.filter(
                (record) => {
                    return preSelectedRecords.every((item) => {
                        if (item.id === record.id) {
                            return false;
                        }
                        return true;
                    });
                }
            );

            const selectedRecordsNotInDisplayList = preSelectedRecords.filter(
                (record) => {
                    return this.state.displayItems.every((item) => {
                        if (item.id === record.id) {
                            return false;
                        }
                        return true;
                    });
                }
            );
            return {
                selectedRecords: selectedRecordsNotInDisplayList
                    .concat(selectedRecordsInPrev)
                    .concat(unSelectedRecordsInPrev),
            };
        });
    }

    private _removeSelectRecord(item: Record) {
        this.setState((perState) => {
            const selectedRecords = perState.selectedRecords;
            const index = selectedRecords.indexOf(item);
            if (index > -1) {
                selectedRecords.splice(index, 1);
                return { selectedRecords: selectedRecords };
            }
            return null;
        });

        this._selection.setKeySelected(item.id, false, false);
    }

    private _clearCache() {
        const portalVersion = getFromLocalStorage(PORTAL_VER);
        if (portalVersion) {
            const storageVersion = getFromLocalStorage(STORAGE_VER);
            if (portalVersion !== storageVersion) {
                const localStorageKeys = Object.keys(window.localStorage);
                localStorageKeys.forEach((key) => {
                    if (!IGNORED_LOCAL_STORAGE_KEYS.includes(key)) {
                        window.localStorage.removeItem(key);
                    }
                });

                saveToLocalStorage(STORAGE_VER, portalVersion);
            }
        }
    }
}
