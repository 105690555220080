import { store } from "../../store";
import { updateLoadingAction } from "../../store/reducers/loading";
import { HIGHLIGHT_LOADING_ERROR } from "../DataContract";

export const showLoading = () => {
    store.dispatch(updateLoadingAction({ isNetworkRequest: true }));
};

export const hideLoading = () => {
    store.dispatch(updateLoadingAction({ isNetworkRequest: false }));
};

export const showPrepareRenderDataLoading = () => {
    store.dispatch(updateLoadingAction({ isPrepareRenderData: true }));
};

export const hidePrepareRenderDataLoading = () => {
    store.dispatch(updateLoadingAction({ isPrepareRenderData: false }));
};

export const markHighlightLoadingError = () => {
    const isLoadingError = sessionStorage.getItem(HIGHLIGHT_LOADING_ERROR);
    if (isLoadingError !== "true") {
        sessionStorage.setItem(HIGHLIGHT_LOADING_ERROR, "true");
    }
};

export const checkHighlightLoadingError = () => {
    const isLoadingError = sessionStorage.getItem(HIGHLIGHT_LOADING_ERROR);
    return isLoadingError === "true";
};

export const resetHighlightLoadingError = () => {
    sessionStorage.removeItem(HIGHLIGHT_LOADING_ERROR);
};
