import * as React from "react";
import "./Pages.scss";
import { RouteComponentProps } from "react-router-dom";

import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    DocumentCardType,
    Label,
    Stack,
} from "@fluentui/react";

const releaseContent = [
    {
        releaseTitle:
            "Add/Delete the tags for each record by tapping the tag icon.",
        releaseActivity: "March 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/release1.png",
    },
    {
        releaseTitle: "Enable accuracy test in release test.",
        releaseActivity: "March 28 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/releaseaccuracy.png",
    },
];

export class ReleaseNote extends React.Component<RouteComponentProps> {
    public render() {
        return (
            <Stack verticalFill>
                <div className="release_tile">
                    <Label>
                        <h1>Release Note:</h1>
                    </Label>
                </div>
                <Stack tokens={{ childrenGap: 10 }}>
                    {releaseContent.map((release) => {
                        return (
                            <div className="ms-Viewport">
                                <DocumentCard
                                    type={DocumentCardType.compact}
                                    onClickHref={release.releaseUrl}
                                >
                                    <DocumentCardPreview
                                        previewImages={[
                                            {
                                                previewImageSrc:
                                                    release.previewImageSrc,
                                                width: 144,
                                            },
                                        ]}
                                    />
                                    <DocumentCardDetails>
                                        <DocumentCardTitle
                                            className="release__title"
                                            title="Feature"
                                        />
                                        <div className="release__content">
                                            <Label>
                                                {release.releaseTitle}
                                            </Label>
                                        </div>
                                        <div className="release__date">
                                            <span>
                                                Release Data:
                                                {release.releaseActivity}
                                            </span>
                                        </div>
                                    </DocumentCardDetails>
                                </DocumentCard>
                            </div>
                        );
                    })}
                </Stack>
            </Stack>
        );
    }
}
