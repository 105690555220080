import React from "react";
import "../Common/MetricStyle.scss";
import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";

import { ICommonProps } from "../Common/CommonMetrics";
import { OcrFigureOverview } from "./OcrFigureOverview";
import { OcrFigureImageView } from "./OcrFigureImageView";
import { Typename, Workspaces } from "../../DataContract";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

interface IProps extends ICommonProps {
    analysisOn?: boolean;
}

interface IState {
    selectedPivot: string;
}

export class OcrFigureMetrics extends React.Component<IProps, IState> {
    private subType?: string;
    private selectLanguage?: string;
    constructor(props: IProps) {
        super(props);
        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            selectedPivot: "Overview",
        };
    }
    public render() {
        const { isDarkTheme, records, saveSetKey } = this.props;

        return (
            <div className="overview">
                <Pivot
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    onLinkClick={this.onPivotItemChanged}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <OcrFigureOverview
                            records={records}
                            isDarkTheme={isDarkTheme}
                            saveSetKey={saveSetKey}
                            onItemInvoked={(data: any) => {
                                [this.selectLanguage, this.subType] = data;
                                this.setState({
                                    selectedPivot: "ByImage",
                                });
                            }}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="By Image"
                        className="displayFlex"
                        itemKey="ByImage"
                    >
                        <OcrFigureImageView
                            saveSetKey={`${Workspaces.OcrFigure}_${Typename.OcrFigureImageView}`}
                            records={records}
                            selectLanguage={this.selectLanguage}
                            subType={this.subType}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    public onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }
        this.selectLanguage = undefined;
        this.subType = undefined;
        store.dispatch(updateSelectSubpivoteAction(item!.props.itemKey!));
        this.setState({
            selectedPivot: item!.props.itemKey!,
        });
    }
}
