import { loadTheme } from "@fluentui/react";

export function onThemeChanged(checked: boolean | undefined) {
    if (checked) {
        loadTheme({
            palette: {
                themePrimary: "#0078d4",
                themeLighterAlt: "#eff6fc",
                themeLighter: "#deecf9",
                themeLight: "#c7e0f4",
                themeTertiary: "#71afe5",
                themeSecondary: "#2b88d8",
                themeDarkAlt: "#106ebe",
                themeDark: "#005a9e",
                themeDarker: "#004578",
                neutralLighterAlt: "#faf9f8",
                neutralLighter: "#f3f2f1",
                neutralLight: "#edebe9",
                neutralQuaternaryAlt: "#e1dfdd",
                neutralQuaternary: "#d0d0d0",
                neutralTertiaryAlt: "#c8c6c4",
                neutralTertiary: "#a19f9d",
                neutralSecondary: "#605e5c",
                neutralPrimaryAlt: "#3b3a39",
                neutralPrimary: "#323130",
                neutralDark: "#201f1e",
                black: "#000000",
                white: "#ffffff",
            },
        });
    } else {
        loadTheme({
            palette: {
                themePrimary: "#c8c8c8",
                themeLighterAlt: "#080808",
                themeLighter: "#202020",
                themeLight: "#3c3c3c",
                themeTertiary: "#777777",
                themeSecondary: "#afafaf",
                themeDarkAlt: "#cdcdcd",
                themeDark: "#d4d4d4",
                themeDarker: "#e0e0e0",
                neutralLighterAlt: "#323130",
                neutralLighter: "#31302f",
                neutralLight: "rgba(var(--palette-primary, 0, 120, 212), .1)",
                neutralQuaternaryAlt: "#2c2b2a",
                neutralQuaternary: "#2a2928",
                neutralTertiaryAlt: "#282726",
                neutralTertiary: "#c8c8c8",
                neutralSecondary: "#ffffff",
                neutralPrimaryAlt: "#dadada",
                neutralPrimary: "#ffffff",
                neutralDark: "#f4f4f4",
                black: "#f8f8f8",
                white: "#202020",
            },
        });
    }
}
