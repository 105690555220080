import { IconButton } from "@fluentui/react";
import React from "react";
import {
    FileType,
    GetFileName,
    downloadAsFileWithAssurance,
} from "../../Utils/Utils";

interface IProps {
    downloadUrl?: string;
}

export class DownloadButton extends React.Component<IProps, {}> {
    public render() {
        return (
            <IconButton
                iconProps={{
                    iconName: "DownloadDocument",
                }}
                disabled={!this.props.downloadUrl}
                onClick={() => {
                    this.downloadJson();
                }}
            />
        );
    }

    downloadJson = () => {
        const { downloadUrl } = this.props;

        if (downloadUrl) {
            const jsonName = GetFileName(downloadUrl);
            downloadAsFileWithAssurance(downloadUrl, jsonName, FileType.Json);
        }
    };
}
