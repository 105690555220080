import { LATENCY_SCRIPTS } from "./Config";

import { fetchValidDataByPath } from "../../../Utils";
import { scriptMap } from "../../Latency/Interface";
import { markHighlightLoadingError } from "../../../Utils/LoadingUtil";

export class LatencyInfo {
    private _ocrMs: any[];
    private _detectorMs: any[];
    private _recognizerMs: any[];

    constructor(ocrMs: any[], detectorMs: any[], recognizerMs: any[]) {
        this._ocrMs = ocrMs;
        this._detectorMs = detectorMs;
        this._recognizerMs = recognizerMs;
    }

    sortLatencyInfo() {
        this._ocrMs = this._ocrMs.sort((a, b) => (a.y > b.y ? 1 : -1));
        this._detectorMs = this._detectorMs.sort((a, b) =>
            a.y > b.y ? 1 : -1
        );
        this._recognizerMs = this._recognizerMs.sort((a, b) =>
            a.y > b.y ? 1 : -1
        );
    }

    notEmpty(): boolean {
        return (
            this._ocrMs.length +
                this._detectorMs.length +
                this._recognizerMs.length >
            0
        );
    }

    dictInfo(sort: boolean): any {
        try {
            sort && this.sortLatencyInfo();
            if (
                this._ocrMs.length +
                    this._detectorMs.length +
                    this._recognizerMs.length <=
                0
            ) {
                return null;
            }
            return {
                P50: {
                    OcrMS: this._ocrMs[
                        Math.round(((this._ocrMs.length - 1) / 100) * 50)
                    ]["y"],
                    DetectorMS:
                        this._detectorMs[
                            Math.round(
                                ((this._detectorMs.length - 1) / 100) * 50
                            )
                        ]["y"],
                    RecognizerMS:
                        this._recognizerMs[
                            Math.round(
                                ((this._recognizerMs.length - 1) / 100) * 50
                            )
                        ]["y"],
                },
                P90: {
                    OcrMS: this._ocrMs[
                        Math.round(((this._ocrMs.length - 1) / 100) * 90)
                    ]["y"],
                    DetectorMS:
                        this._detectorMs[
                            Math.round(
                                ((this._detectorMs.length - 1) / 100) * 90
                            )
                        ]["y"],
                    RecognizerMS:
                        this._recognizerMs[
                            Math.round(
                                ((this._recognizerMs.length - 1) / 100) * 90
                            )
                        ]["y"],
                },
                P99: {
                    OcrMS: this._ocrMs[
                        Math.round(((this._ocrMs.length - 1) / 100) * 99)
                    ]["y"],
                    DetectorMS:
                        this._detectorMs[
                            Math.round(
                                ((this._detectorMs.length - 1) / 100) * 99
                            )
                        ]["y"],
                    RecognizerMS:
                        this._recognizerMs[
                            Math.round(
                                ((this._recognizerMs.length - 1) / 100) * 99
                            )
                        ]["y"],
                },
                P100: {
                    OcrMS: this._ocrMs[this._ocrMs.length - 1]["y"],
                    DetectorMS:
                        this._detectorMs[this._detectorMs.length - 1]["y"],
                    RecognizerMS:
                        this._recognizerMs[this._recognizerMs.length - 1]["y"],
                },
            };
        } catch (err) {
            return null;
        }
    }

    add(info: LatencyInfo) {
        this._ocrMs = this._ocrMs.concat(info._ocrMs);
        this._detectorMs = this._detectorMs.concat(info._detectorMs);
        this._recognizerMs = this._recognizerMs.concat(info._recognizerMs);
    }
}

export class HighlightLatencyMetrics {
    private _storageMap = new Map<string, string>();

    constructor(storageMap: Map<string, string>) {
        this._storageMap = storageMap;
    }

    get note() {
        return "We don't have latency info for Receipt_NPD yet.";
    }

    getLatencyFile(language: string) {
        if (!this._storageMap.has(language)) {
            return null;
        }
        let compose_url = `/api/eval/blobs/${this._storageMap.get(language)}`;
        return `${compose_url}/overall_latency_metrics.json`;
    }

    async getMetricsByPath(file: any) {
        try {
            if (file) {
                let obj = await fetchValidDataByPath(file, 0);
                return obj ? JSON.parse(obj) : null;
            }
        } catch (error) {
            console.log(error);
            markHighlightLoadingError();
        }
        return null;
    }

    async getLatencyInfoWithMetric(metric: any) {
        let ocrMs = [];
        let detectorMs = [];
        let recognizerMs = [];
        if (metric && metric["percentile_latency"]) {
            for (let latency of metric["percentile_latency"]) {
                if (latency["chartname"] === "_ocrMS") {
                    ocrMs = latency["chartPoints"];
                } else if (
                    latency["chartname"] ===
                    "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs"
                ) {
                    detectorMs = latency["chartPoints"];
                } else if (
                    latency["chartname"] ===
                    "_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs"
                ) {
                    recognizerMs = latency["chartPoints"];
                }
            }
        }
        return new LatencyInfo(ocrMs, detectorMs, recognizerMs);
    }

    async getLatencyInfoByLanguage(lang: string) {
        let f = this.getLatencyFile(lang);
        let metric = await this.getMetricsByPath(f);
        return this.getLatencyInfoWithMetric(metric);
    }

    async getLatencyInfoParallel() {
        let LATENCY: { [key: string]: {} } = {};
        let SORTED_LATENCY: { [key: string]: {} } = {};
        await Promise.all(
            LATENCY_SCRIPTS.map(async (script) => {
                let totalLatencyInfo = new LatencyInfo([], [], []);
                let langs = scriptMap.has(script) ? scriptMap.get(script)! : [];
                // langs = langs.filter(
                //     (lang) => !(lang as string).includes("NPD")
                // );
                // Fetch metrics for langs.
                await Promise.all(
                    langs.map((lang) => {
                        return this.getLatencyInfoByLanguage(lang);
                    })
                ).then((result) => {
                    for (let res of result) {
                        if (res.notEmpty()) {
                            totalLatencyInfo.add(res);
                        }
                    }
                    let dictInfo = totalLatencyInfo.dictInfo(true);
                    dictInfo && (LATENCY[script] = dictInfo);
                });
            })
        ).then(() => {
            LATENCY_SCRIPTS.forEach((script) => {
                SORTED_LATENCY[script] = LATENCY[script];
            });
        });
        return SORTED_LATENCY;
    }
}
