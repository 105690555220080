import { Workspaces } from "../../DataContract";

const exclusiveMetricFields = new Set<string>([
    "outlier_page_cnt",
    "_totalms",
    "_parsepdfms",
    "_renderpdfms",
    "_checkboxms",
    "_pdfiimagetablems",
    "_pdfidigitaltablems",
    "_msratablems",
    "_mergeresultms",
    "_serializationms",
    "_storagems",
    "_messagingms",
    "_otherms",
    "_ocrnativeperftotalms",
    "_ocrnativeperfpreprocessms",
    "_ocrnativeperfoverheadms",
]);

const cbExclusiveMetricFields = new Set<string>([
    "outlier_page_cnt",
    "_totalms",
    "_parsepdfms",
    "_renderpdfms",
    "_pdfiimagetablems",
    "_pdfidigitaltablems",
    "_readorderms",
    "_mergeresultms",
    "_serializationms",
    "_storagems",
    "_messagingms",
    "_otherms",
    "_ocrnativeperftotalms",
    "_ocrnativeperfpreprocessms",
    "_ocrnativeperfoverheadms",
]);

export const LatencyExclusiveMetricField: { [key: string]: Set<string> } = {
    [Workspaces.Ocr]: exclusiveMetricFields,
    [Workspaces.ReleaseTest]: exclusiveMetricFields,
    [Workspaces.OcrCheckbox]: cbExclusiveMetricFields,
    [Workspaces.OcrMath]: exclusiveMetricFields,
    [Workspaces.OcrTable]: exclusiveMetricFields,
    [Workspaces.OcrPod]: exclusiveMetricFields,
    [Workspaces.OcrFigure]: exclusiveMetricFields,
    [Workspaces.Kvp]: exclusiveMetricFields,
};
