import React from "react";
import { IconButton, Label, Stack } from "@fluentui/react";
import {
    IMetricProps,
    IMetricState,
    MetricsView,
} from "../../Common/MetricView";
import { ColumnValueType, TableColumn, TableList } from "../../../Controls";
import {
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../../DataContract";
import {
    ITableConfigurations,
    TableHeader,
} from "../../../Controls/TableHeader";
import { ShortenMetricKeyName } from "../Interface";
import { store } from "../../../../store";
import { updateStateAction } from "../../../../store/reducers/setting";
import {
    DEFAULT_COL_KEY,
    IMAGE_ID_KEY,
    ImagePerfViewClumns,
    STRING_TYPE_KEYS,
    URLG_TYPE_KEYS,
} from "./ImagePerfViewColumn";
import { MathDataset } from "../../../DataContract/MathDataset";
import { FullScreen } from "../../Common/FullScreen";
import { NoDataTip } from "../../../Controls/NoDataTip";
import {
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../../Utils/LoadingUtil";
import _ from "lodash";
import { openImageInNewWindow } from "../../../Utils";

interface IState extends IMetricState<any> {
    allColumns: TableColumn[];
    categoryList: string[];
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
}

interface IProps extends IMetricProps {
    linkData?: any;
    matchDatasetVersion?: boolean;
}

export class ImagePerfView extends MetricsView<IProps, IState, any> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._onShowEvaluationSuccess =
            this._onShowEvaluationSuccess.bind(this);

        this.state = {
            allColumns: ImagePerfViewClumns[this.workSpace],
            evalData: {},
            categoryList: [],
            selectLanguage: props.linkData?.toSelectLanguage,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public render() {
        const { allColumns = [], selectedColumns, evalData } = this.state;
        const columns: TableColumn[] = allColumns?.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        return (
            <FullScreen>
                {!_.isEmpty(columns) ? (
                    <TableList<any>
                        key={this.state.selectLanguage}
                        evalDataCount={this.props.records.length}
                        evalData={evalData}
                        columns={columns}
                        isFullFilterMenu={true}
                        downloadTableTitle={this.state.selectLanguage}
                        onItemInvoked={(item, index) => {
                            const [imageId] = item;
                            this._onDownloadPicture(imageId);
                            this.setState({ selectImageId: imageId });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                ) : (
                    <NoDataTip>No Latency Image Metrics Generated</NoDataTip>
                )}
            </FullScreen>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();
        if ([Workspaces.OcrCheckbox].includes(this.workSpace)) {
            store.dispatch(
                updateStateAction({
                    columns: ImagePerfViewClumns[this.workSpace],
                    selectedItems: ImagePerfViewClumns[this.workSpace].map(
                        (val) => val.key
                    ),
                    saveKey: `${this.workSpace}_${Typename.LatencyMetrics}_ByImage`,
                })
            );
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.level !== prevState.level) {
            this.onEvaluationRecordChanged();
        }
        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<any>> {
        return Promise.all([
            recordDetail.fetchMetricsWithCamelCasing<IMetrics<any>>(metricName),
            recordDetail.dataset.fetchImageList(),
        ]).then(([records, imageList]) => {
            Object.entries(records).forEach(([imageId, val]: [string, any]) => {
                this.getImageUrl([imageId, val], imageList, recordDetail);
            });
            return records;
        });
    }

    getImageUrl = (
        [imageId, val]: [string, any],
        imageList: string[],
        recordDetail: RecordDetail
    ) => {
        if (
            this.workSpace === Workspaces.Ocr ||
            this.workSpace === Workspaces.ReleaseTest
        ) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}.jpg`;
            val.imageUrl = recordDetail.dataset.getImageUrl(
                `${val.category}/${imageName}`
            );
        } else if (this.workSpace === Workspaces.OcrCheckbox) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}`;
            val.imageUrl = `/api/datasets/${recordDetail.dataset.name}/versions/${recordDetail.dataset.version}/tree/images/${imageName}`;
        } else if (this.workSpace === Workspaces.OcrMath) {
            let imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}`;
            if (val["category"] !== "img") {
                imageName = `${val["category"]}/${imageName}`;
            }
            const mathDataset = new MathDataset(
                recordDetail.dataset.name,
                recordDetail.dataset.version,
                recordDetail.dataset.language,
                recordDetail.dataset.categories
            );
            val.imageUrl = mathDataset.getImageUrl(imageName);
        } else if (this.workSpace === Workspaces.OcrTable) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}.jpg`;
            val.imageUrl = `/api/datasets/${
                recordDetail.dataset.name
            }/versions/${
                recordDetail.dataset.version
            }/tree/e2e_test_images/${imageName.slice(
                imageName.lastIndexOf("/") + 1,
                imageName.length
            )}`;
        } else if (this.workSpace === Workspaces.OcrPod) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}.jpg`;

            val.imageUrl = `/api/datasets/${recordDetail.dataset.name}/versions/${recordDetail.dataset.version}/tree/JPEGImages/${imageName}`;
        } else if (this.workSpace === Workspaces.OcrFigure) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}.jpg`;
            val.imageUrl = `/api/datasets/${
                recordDetail.dataset.name
            }/versions/${
                recordDetail.dataset.version
            }/tree/JPEGImages/${imageName.slice(
                imageName.lastIndexOf("/") + 1,
                imageName.length
            )}`;
        } else if (this.workSpace === Workspaces.Kvp) {
            const imageName =
                imageList.find((image) =>
                    image.toLowerCase().includes(imageId.toLowerCase())
                ) ?? `${imageId}.pdf`;
            val.imageUrl = `/api/datasets/${recordDetail.dataset.name}/versions/${recordDetail.dataset.version}/tree/${imageName}`;
        }
    };

    onEvaluationRecordChanged() {
        showPrepareRenderDataLoading();
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language = this.state.selectLanguage ?? languageList[0];
            this._onOptionsChanged({
                selectLanguage: language,
            });
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;

        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );

            this.showEvaluationResult(
                details,
                `overall_latency_image_metrics.json`,
                this._onShowEvaluationSuccess
            );
        } else {
            hidePrepareRenderDataLoading();
        }
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _onShowEvaluationSuccess() {
        if (
            [
                Workspaces.Ocr,
                Workspaces.ReleaseTest,
                Workspaces.OcrMath,
                Workspaces.OcrTable,
                Workspaces.OcrPod,
                Workspaces.OcrFigure,
                Workspaces.Kvp,
            ].includes(this.workSpace)
        ) {
            const { evalData } = this.state;
            if (evalData) {
                const itemsArr = Object.values(evalData);
                let sampleItem: any = undefined;
                itemsArr.some((items) =>
                    items.find((item) => {
                        if (item) {
                            sampleItem = item;
                            return true;
                        }
                        return false;
                    })
                );

                if (sampleItem) {
                    const columns: TableColumn[] = [
                        {
                            key: IMAGE_ID_KEY,
                            name: IMAGE_ID_KEY,
                            isKey: true,
                            valueType: ColumnValueType.String,
                            minWidth: 150,
                            maxWidth: 450,
                        },
                    ];
                    const itemKeys = Object.keys(sampleItem);
                    if (
                        [
                            Workspaces.Ocr,
                            Workspaces.ReleaseTest,
                            Workspaces.OcrMath,
                        ].includes(this.workSpace)
                    ) {
                        DEFAULT_COL_KEY[this.workSpace].forEach((defKey) => {
                            let targetIndex: number = NaN;
                            const targetKey = itemKeys.find(
                                (itemKey, index) => {
                                    if (
                                        itemKey.toLowerCase() ===
                                        defKey.toLowerCase()
                                    ) {
                                        targetIndex = index;
                                        return true;
                                    }
                                    return false;
                                }
                            );

                            if (targetKey && targetIndex) {
                                const targetCol =
                                    this._generateColumn(targetKey);
                                columns.push(targetCol);
                                itemKeys.splice(targetIndex, 1);
                            }
                        });
                    } else if (
                        [
                            Workspaces.OcrTable,
                            Workspaces.OcrPod,
                            Workspaces.OcrFigure,
                            Workspaces.Kvp,
                        ].includes(this.workSpace)
                    ) {
                        const prefix: { [key: string]: string } = {
                            [Workspaces.OcrTable]: "TableNative",
                            [Workspaces.OcrPod]: "PODNative",
                            [Workspaces.OcrFigure]: "FigureNative",
                            [Workspaces.Kvp]: "_kvpNativePerf",
                        };

                        itemKeys
                            .filter((itemkey) => {
                                const shortName = ShortenMetricKeyName(itemkey);
                                return (
                                    shortName.startsWith(
                                        prefix[this.workSpace]
                                    ) ||
                                    shortName === "hierarchyAnalyzerMS" ||
                                    itemkey.startsWith(prefix[this.workSpace])
                                );
                            })
                            .forEach((defKey) => {
                                let targetIndex: number = NaN;
                                const targetKey = itemKeys.find(
                                    (itemKey, index) => {
                                        if (
                                            itemKey.toLowerCase() ===
                                            defKey.toLowerCase()
                                        ) {
                                            targetIndex = index;
                                            return true;
                                        }
                                        return false;
                                    }
                                );
                                if (targetKey && targetIndex) {
                                    const targetCol =
                                        this._generateColumn(targetKey);
                                    columns.push(targetCol);
                                    itemKeys.splice(targetIndex, 1);
                                }
                            });
                    }

                    const defaultSelectCols = columns.map((col) => col.key);

                    if (itemKeys && itemKeys.length > 0) {
                        itemKeys.forEach((key) => {
                            const targetCol = this._generateColumn(key);
                            if (defaultSelectCols.includes(targetCol.key)) {
                                targetCol.name = key;
                            }
                            columns.push(targetCol);
                        });

                        const statePick: Pick<IState, "allColumns"> = {
                            allColumns: columns,
                        };

                        this.setState(statePick);
                        store.dispatch(
                            updateStateAction({
                                saveKey: `${
                                    this.props.saveSetKey ??
                                    `${this.workSpace}_${Typename.LatencyMetrics}`
                                }_ByImage`,
                                columns: columns,
                                selectedItems: defaultSelectCols,
                            })
                        );
                    }
                }
            }
        }
        hidePrepareRenderDataLoading();
    }

    private _onDownloadButtonClicked = () => {
        const { evalData, selectLanguage } = this.state;

        const element = document.createElement("a");
        var blob = new Blob([JSON.stringify(evalData)], {
            type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(blob);
        element.download = `${selectLanguage}_image_latency_metrics.json`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    private _renderTableHeader(): JSX.Element {
        const { matchDatasetVersion } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion);
        let selectLanguage: string | undefined = "";

        selectLanguage = this.state.selectLanguage ?? languages[0];

        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage,
                onChange: (language) => {
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
        ];
        return (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <TableHeader
                    options={imageConfigurations}
                    onQueryButtonClick={this._onQueryButtonClicked}
                    onToggle={(checked: boolean) => {
                        this.setState({
                            matchDatasetVersion: checked,
                        });
                        this._onEvaluationRecordChanged(checked);
                    }}
                />
                <Stack horizontal verticalAlign="center">
                    <Label>Download raw json</Label>
                    <IconButton
                        className="download"
                        iconProps={{ iconName: "Download" }}
                        onClick={this._onDownloadButtonClicked}
                    />
                </Stack>
            </Stack>
        );
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this._onQueryButtonClicked(selectLanguage);
        this.setState({
            selectLanguage: selectLanguage,
        });
    }

    private _onDownloadPicture(imageId: string): void {
        const { evalData, selectLanguage } = this.state;
        const imageMetricsArr = evalData[imageId];

        if (imageMetricsArr && imageMetricsArr.length > 0) {
            const sampleImgMetric = imageMetricsArr.find(
                (imgMetric) => imgMetric && imgMetric.imageUrl
            );

            if (sampleImgMetric && sampleImgMetric.imageUrl) {
                openImageInNewWindow(sampleImgMetric.imageUrl, selectLanguage);

                return;
            }
        }

        alert("No url for this image");
    }

    private _generateColumn(key: string): TableColumn {
        const normalizeKey = ShortenMetricKeyName(key);
        return {
            key: key,
            name: ShortenMetricKeyName(normalizeKey),
            isKey: key === IMAGE_ID_KEY,
            valueType: URLG_TYPE_KEYS.includes(key)
                ? ColumnValueType.Url
                : STRING_TYPE_KEYS.includes(key)
                ? ColumnValueType.String
                : ColumnValueType.Number,
            minWidth: 100,
            maxWidth: 400,
        };
    }
}
