import { AnyAction } from "redux";
import { Workspaces } from "../../components/DataContract";
import { store } from "..";
import { updateFullscreenAction } from "./fullscreen";

const initialState = {
    disableSetting: false,
    viewType: "Chart",
    expandAll: true,
    disableExportingExcel: false,
    workSpace: Workspaces.Ocr,
    selectedKey: "",
    selectSubPivot: "Overview",
    releaseTestselectSubPivot: "generalMetrics",
};

export interface IGlobalState {
    disableSetting: boolean;
    viewType: string;
    expandAll: boolean;
    disableExportingExcel: boolean;
    workSpace: string;
    selectedKey: string;
    selectSubPivot: string;
    releaseTestselectSubPivot: string;
}

export class ActionType {
    public static readonly UpdateSettingOff = "UPDATE_SETTING_OFF";
    public static readonly UpdateViewType = "UPDATE_VIEWTYPE";
    public static readonly UpdateExpand = "UPDATE_EXPAND";
    public static readonly UpdateDisableExportingExcel =
        "UPDATE_DISABLE_EXPORTING_EXCEL";
    public static readonly UpdateWorkSpace = "UPDATE_WORKSPACE";
    public static readonly UpdateSelectKey = "UPDATE_SELECTKEY";
    public static readonly UpdateSelectSubpivot = "UPDATE_SELECTSUBPIVOT";
    public static readonly UpdateReleaseTestSelectSubpivot =
        "UPDATE_RELEASETEST_SELECTSUBPIVOT";
}

export const globalReducer = (
    state: IGlobalState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case ActionType.UpdateSettingOff:
            return { ...state, disableSetting: action.value };
        case ActionType.UpdateViewType:
            return { ...state, viewType: action.value };
        case ActionType.UpdateExpand:
            return { ...state, expandAll: action.value };
        case ActionType.UpdateDisableExportingExcel:
            return { ...state, disableExportingExcel: action.value };
        case ActionType.UpdateWorkSpace:
            return { ...state, workSpace: action.value };
        case ActionType.UpdateSelectSubpivot:
            return { ...state, selectSubPivot: action.value };
        case ActionType.UpdateSelectKey:
            return updateSelectKey(state, action.value);
        case ActionType.UpdateReleaseTestSelectSubpivot:
            return updateReleaseTestSelectSubpivot(state, action.value);
        default:
            return state;
    }
};

const updateReleaseTestSelectSubpivot = (state: IGlobalState, value: any) => {
    return {
        ...state,
        ...{ releaseTestselectSubPivot: value, selectSubPivot: "Overview" },
    };
};
const updateSelectKey = (state: IGlobalState, value: any) => {
    return { ...state, ...{ selectedKey: value, selectSubPivot: "Overview" } };
};

export const updateDisableSettingAction = (disableSetting: boolean) => {
    return {
        type: ActionType.UpdateSettingOff,
        value: disableSetting,
    };
};

export const updateViewTypeAction = (viewType: any) => {
    return {
        type: ActionType.UpdateViewType,
        value: viewType,
    };
};

export const updateExpandAction = (expand: any) => {
    return {
        type: ActionType.UpdateExpand,
        value: expand,
    };
};

export const updateDisableExportingExcelAction = (state: any) => {
    return {
        type: ActionType.UpdateDisableExportingExcel,
        value: state,
    };
};

export const updateWorkSpaceAction = (workspace: any) => {
    store.dispatch(updateFullscreenAction(false));
    return {
        type: ActionType.UpdateWorkSpace,
        value: workspace,
    };
};
export const updateSelectKeyAction = (selectedKey: any) => {
    return {
        type: ActionType.UpdateSelectKey,
        value: selectedKey,
    };
};
export const updateSelectSubpivoteAction = (selectSubPivot: any) => {
    return {
        type: ActionType.UpdateSelectSubpivot,
        value: selectSubPivot,
    };
};

export const updateReleaseTestSelectSubpivoteAction = (selectSubPivot: any) => {
    return {
        type: ActionType.UpdateReleaseTestSelectSubpivot,
        value: selectSubPivot,
    };
};
