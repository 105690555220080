import * as React from "react";
import _ from "lodash";
import {
    arrayDistinct,
    downloadAsFileWithAssurance,
    FileType,
    GetExtension,
    GetFileName,
    getRandomColor,
} from "../../Utils/Utils";
import ImageMapper from "./ImageMapper";
import { IMetrics, IMetricUnit } from "../../DataContract";
import { SettingsPanel } from "./SettingsPanel";
import { TextlineCharMetric } from "../../Views";
import { WordMetric } from "../../Views/Ocr/WordAlignImageVIew";
import { Consumer } from "../../Layout";
import { PredictionlineMetric } from "../../Views/Ocr/EntityPredictionLineView";
import {
    BARCODE_SETTINGS,
    DEFAULT_SETTINGS,
    DETECTION_SETTINGS,
    FIGURE_SETTINGS,
    PREDICTION_SETTINGS,
    WORDALIGN_SETTINGS,
} from "./ImageVisualizerData";
import {
    VisualizerBase,
    VisualizerProps,
    VisualizerState,
} from "./VisualizerBase";
import "./imageVisualizer.scss";

export interface TextArea {
    name: string;
    lineId: string;
    shape: string;
    coords: number[];
    polygon: string[];
    text: string;
    preFillColor?: string;
    lineWidth?: number;
    strokeColor?: string;
    center?: [number, number];
}

export interface OcrPolygon {
    //textline
    textline_id: string;
    cover_ratio: number;
    reference_words: OcrWord[];
    recognize_words: OcrWord[];
    //wordAlign
    word_id: string;
    overlapped_words: OcrWord[];
}

export interface ISetting {
    name: string;
    label: string;
    fill: string;
    strokeColor: string;
    isChecked?: boolean;
    lineWidth?: number;
}

export interface ISettings {
    [key: string]: ISetting;
}

export enum MetricsType {
    wordAlignMetrics,
    detectionMetrics,
    mathOcrMetrics,
    predictionlineMetrics,
    barcodeMetrics,
    figureMetrics,
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<IMetricUnit[]>;

interface OcrWord {
    polygon: string[];
    text: string;
}

interface IProps extends VisualizerProps {
    fileId?: string;
    metricsType?: MetricsType;
    onLoadVisualizer: (imageId?: string) => string;
    onRequestPolygons?: (
        imageId?: string,
        entity?: string,
        selectedCategory?: string
    ) => Promise<OcrPolygons[]>;
    onRequestMetrics?: (
        imageId?: string,
        entity?: string,
        selectedCategory?: string
    ) => Promise<OcrMetrics[]>;
    entity?: string;
    experiments?: string[];
    setImageMark?: (
        markInfo?: string[],
        textlineId?: string,
        content?: string
    ) => void;
    imageUrl?: string[];
    onDismiss?: (imageId: any) => void;
    clickNumber?: number;
    evalList?: any[];
}

interface IState extends VisualizerState {
    fileUrl: string;
    loading: boolean;
    selectedArea: TextArea | null;
    selectedItem: string;
    textareas: TextArea[];
    hoveredArea: TextArea | null;
    selectedExpIndex: number;
    ocrPolygonsList: OcrPolygons[];
    ocrMetricsList: OcrMetrics[];
    settings: ISettings;
    selectedSetting: [string, ISetting] | null;
    fileId?: string;
    currentPage?: number;
    selectEntity?: string;
    onClickArea?: any;
}

export class ImageVisualizer extends VisualizerBase<IProps, IState> {
    private _mapper: React.RefObject<ImageMapper>;
    private _settingPanel: React.RefObject<SettingsPanel>;

    constructor(props: IProps) {
        super(props);
        this._mapper = React.createRef();
        this._settingPanel = React.createRef();
        this._loadSettingsFromMetrics =
            this._loadSettingsFromMetrics.bind(this);
        this._onSettingsChanged = this._onSettingsChanged.bind(this);
        this._onSelectedSettingChanged =
            this._onSelectedSettingChanged.bind(this);
        this._onChoiceGroupChanged = this._onChoiceGroupChanged.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.debounceOnPageChange = _.debounce(
            this.debounceOnPageChange.bind(this),
            500
        );
        this.state = {
            loading: false,
            textareas: [],
            selectedArea: null,
            selectedItem: "",
            hoveredArea: null,
            selectedExpIndex: 0,
            ocrPolygonsList: [],
            ocrMetricsList: [],
            settings: this._getSettings(),
            selectedSetting: null,
            fileUrl: this.props.onLoadVisualizer(this.props.fileId),
            currentPage: this.props.clickNumber ?? 0,
        };
    }

    public componentDidMount(): void {
        this.refreshCache();
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (
            prevState.currentPage !== this.state.currentPage &&
            prevState.fileId !== this.state.fileId
        ) {
            this.setState({
                fileUrl: this.props.onLoadVisualizer(this.state.fileId) ?? "",
            });
        }
        if (
            this.props.fileId !== prevProps.fileId ||
            this.props?.entity !== prevProps?.entity
        ) {
            this.refreshCache({
                selectedExpIndex: 0,
                clickedIndex: this.props.clickNumber,
            });
        }
    }

    protected renderVisualizer(): JSX.Element {
        const { loading, textareas, currentPage, fileId, fileUrl } = this.state;
        const { experiments } = this.props;
        const map = {
            name: "map",
            areas: textareas,
        };

        return (
            <div className="viewer_par">
                <div className="viewer">
                    <div
                        className="viewer__container"
                        style={{ visibility: loading ? "hidden" : "visible" }}
                        key={fileId}
                    >
                        <ImageMapper
                            ref={this._mapper}
                            src={loading ? "" : fileUrl}
                            //src="/ViewerDemo/130.jpg"
                            map={map}
                            width={800}
                            lineWidth={4}
                            strokeColor={"white"}
                            imgWidth={this._mapper.current?.img?.naturalWidth}
                            onClick={(area: any) => this._clickImage(area)}
                            onMouseEnter={(area: TextArea) =>
                                this._enterArea(area)
                            }
                            onMouseLeave={(area: TextArea) =>
                                this._leaveArea(area)
                            }
                            onClickArea={this.state.onClickArea}
                        />
                        {this.state.hoveredArea && (
                            <span
                                className="viewer__tooltip"
                                style={{
                                    ...this._getTipPosition(
                                        this.state.hoveredArea
                                    ),
                                }}
                            >
                                {this.state.hoveredArea &&
                                    this.state.hoveredArea.text}
                            </span>
                        )}
                    </div>
                    <div className="viewer__setting">
                        <Consumer>
                            {(value) => {
                                return (
                                    <SettingsPanel
                                        experiments={experiments}
                                        ref={this._settingPanel}
                                        settings={this.state.settings}
                                        selectedSetting={
                                            this.state.selectedSetting
                                        }
                                        onSettingsChanged={
                                            this._onSettingsChanged
                                        }
                                        onSelectedSettingChanged={
                                            this._onSelectedSettingChanged
                                        }
                                        displayArea={this.state.selectedArea}
                                        displayContent={this.state.selectedItem}
                                        downloadLabel={() => {
                                            this._downloadLabel(fileUrl);
                                        }}
                                        downloadImage={() =>
                                            this._downloadImage(fileUrl)
                                        }
                                        setImageMark={this.props.setImageMark}
                                        textareas={this.state.textareas}
                                        selectedExpIndex={
                                            this.state.selectedExpIndex
                                        }
                                        _onChoiceGroupChanged={(id: any) =>
                                            this._onChoiceGroupChanged(id)
                                        }
                                        currentPage={currentPage}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    </div>
                </div>
            </div>
        );
    }

    onPageChange(page: number) {
        let { fileId } = this.props;
        let imageUrl: string = "";

        imageUrl = this.props.evalList![page].find(
            (v: any) => v.imageUrl
        ).imageUrl;

        fileId = imageUrl?.substring(
            imageUrl.lastIndexOf("/") + 1,
            imageUrl.lastIndexOf(".")
        );

        this.setState({
            loading: true,
            currentPage: page,
            fileUrl: imageUrl,
            fileId: fileId,
            textareas: [],
            selectedArea: null,
            selectedItem: "",
            onClickArea: undefined,
        });
        this.debounceOnPageChange(page);
    }

    refreshCache(paramObj?: { selectedExpIndex?: number; clickedIndex?: any }) {
        let { fileId } = this.props;
        let imageUrl: string = "";
        let selectEntity: string = "";

        if (paramObj?.clickedIndex !== undefined) {
            imageUrl = this.props.evalList![paramObj.clickedIndex].find(
                (v: any) => v.imageUrl
            ).imageUrl;
            selectEntity = this.props.evalList![paramObj.clickedIndex].find(
                (v: any) => v
            ).entity_name;

            fileId = imageUrl?.substring(
                imageUrl.lastIndexOf("/") + 1,
                imageUrl.lastIndexOf(".")
            );
        }

        if (!fileId) {
            this.setState({
                settings: this._getSettings(),
            });
            return;
        }
        const expIndex =
            paramObj?.selectedExpIndex ?? this.state.selectedExpIndex;
        this.setState({
            textareas: [],
            selectedArea: null,
            selectedItem: "",
            selectedExpIndex: expIndex,
            selectEntity: selectEntity,
        });

        if (this.props.onRequestPolygons) {
            this.setState({
                loading: true,
            });
            if (this.props.onRequestMetrics) {
                Promise.all([
                    this.props.onRequestPolygons(fileId),
                    this.props.onRequestMetrics(fileId),
                ]).then(([polygons, metrics]) => {
                    const [expPolygon, expMetric] = [
                        polygons[expIndex],
                        metrics[expIndex],
                    ];

                    let settings = this.state.settings;

                    settings = this._loadSettingsFromMetrics(
                        settings,
                        expMetric[fileId!] ?? []
                    );

                    this._setTextAreas(expPolygon, expMetric, settings);
                    this.setState({
                        ocrPolygonsList: polygons,
                        ocrMetricsList: metrics,
                        loading: false,
                    });
                });
            } else {
                this.props.onRequestPolygons(fileId).then((polygons) => {
                    this._setTextAreas(polygons[expIndex]);
                    this.setState({
                        ocrPolygonsList: polygons,
                        loading: false,
                    });
                });
            }
        }
    }

    //#region event handle
    private _onChoiceGroupChanged(selectedExpIndex: number) {
        const { ocrPolygonsList, ocrMetricsList } = this.state;
        this._setTextAreas(
            ocrPolygonsList[selectedExpIndex!],
            ocrMetricsList[selectedExpIndex!]
        );
        this.setState({
            selectedExpIndex: selectedExpIndex,
        });
    }

    private _onSelectedSettingChanged(
        selectedSetting: [string, ISetting] | null
    ) {
        this.setState({ selectedSetting: selectedSetting });
    }

    private _onSettingsChanged(settings: { [key: string]: ISetting }) {
        this._setTextAreas(undefined, undefined, settings);
    }
    //#endregion

    private _loadSettingsFromMetrics(
        settings: ISettings,
        metrics: IMetricUnit[]
    ) {
        let entityNames = metrics
            .map((metric: any) => {
                return metric.entity_name;
            })
            .filter((entityName) => entityName !== undefined);
        entityNames = arrayDistinct(entityNames);
        entityNames.forEach((entityName) => {
            const newSetting = {
                name: entityName,
                label: entityName,
                fill: getRandomColor(),
                strokeColor: "transparent",
                isChecked: false,
            };
            settings[entityName] = newSetting;
        });
        if (this.props.entity || this.state.selectEntity) {
            let selectedEntity =
                this.state.selectEntity === "" || !this.state.selectEntity
                    ? this.props.entity
                    : this.state.selectEntity;
            let defEnableSetting = settings[selectedEntity!];
            if (defEnableSetting) {
                defEnableSetting.isChecked = true;
                settings[selectedEntity!] = defEnableSetting;
            }
        }
        return settings;
    }

    private _enterArea(area: TextArea) {
        this.setState({
            hoveredArea: area,
        });
    }

    private _leaveArea(area: TextArea) {
        this.setState({
            hoveredArea: null,
        });
    }

    private _getTipPosition(area: TextArea) {
        return { top: `${area.center![1]}px`, left: `${area.center![0]}px` };
    }

    private _clickImage(area: TextArea) {
        let content = area.text;

        const { ocrMetricsList, selectedExpIndex } = this.state;
        if (ocrMetricsList && ocrMetricsList.length > 0) {
            const lineMetrics =
                ocrMetricsList[selectedExpIndex][
                    this.state.fileId ?? this.props.fileId!
                ];
            if (lineMetrics) {
                let metric = lineMetrics.filter(
                    (val: any) => val.textline_id === area.lineId
                );
                if (this.props.metricsType === MetricsType.wordAlignMetrics) {
                    metric = lineMetrics.filter(
                        (val: any) => val.word_id === area.lineId
                    );
                }

                metric && (content = JSON.stringify(metric, null, 2));
            }
        } else if (this.props.metricsType === MetricsType.barcodeMetrics) {
            content = "";
        } else if (this.props.metricsType === MetricsType.figureMetrics) {
            const metric: any = {
                textline_id: area.lineId,
                polygon: area.polygon,
            };
            const values = content.split(",");
            values.forEach((value: string) => {
                const [k, v] = value.split(":");
                metric[k] = v;
            });
            content = JSON.stringify([metric], null, 2);
        }

        this.setState({
            selectedArea: area,
            selectedItem: content,
            onClickArea: area,
        });
    }

    private _makeTextAreas(
        lineId: string,
        words: OcrWord[],
        settings: { [key: string]: ISetting },
        key: string,
        text: string = ""
    ): TextArea[] {
        const setting = settings[key];
        if (!setting.isChecked) {
            return [];
        }

        return words.map((word, idx) => {
            const coords = word.polygon
                .flatMap((pt) => pt.split(","))
                .map((val) => Number(val));
            let area: TextArea = {
                name: `${key}_${idx}`,
                lineId: lineId,
                shape: "poly",
                coords: coords,
                text:
                    this.props.metricsType === MetricsType.wordAlignMetrics
                        ? text
                        : word.text,
                preFillColor: setting.fill,
                polygon: word.polygon,
            };

            setting.lineWidth && (area.lineWidth = setting.lineWidth);
            setting.strokeColor && (area.strokeColor = setting.strokeColor);
            return area;
        });
    }

    private async _setTextAreas(
        polygons?: OcrPolygons,
        metrics?: OcrMetrics,
        settings?: { [key: string]: ISetting }
    ) {
        const { selectedExpIndex } = this.state;
        const { metricsType } = this.props;

        if (polygons === undefined) {
            polygons = this.state.ocrPolygonsList[selectedExpIndex];
        }

        if (metrics === undefined) {
            metrics = this.state.ocrMetricsList[selectedExpIndex];
        }

        if (settings === undefined) {
            settings = this.state.settings;
        }

        if (!polygons || !settings || !this.props.fileId) {
            return;
        }

        let lines = polygons[this.state.fileId ?? this.props.fileId];

        if (!lines) {
            lines = [];
        }

        let imageMetrics: IMetricUnit[] = [];
        if (
            metricsType !== MetricsType.barcodeMetrics &&
            metricsType !== MetricsType.figureMetrics
        ) {
            imageMetrics = metrics[this.state.fileId ?? this.props.fileId];
        }

        let textareas: TextArea[] = [];
        if (metricsType === MetricsType.barcodeMetrics) {
            textareas = this.setBarcodeGeneralImageAreas(lines, settings!);
        } else if (this.props.metricsType === MetricsType.figureMetrics) {
            textareas = this.setFigureGeneralImageAreas(lines, settings!);
        } else if (this.props.metricsType === MetricsType.wordAlignMetrics) {
            textareas = lines.flatMap((line) => {
                let textareas: TextArea[] = [];

                let processed = false;

                for (const special_key of ["detected"]) {
                    if (
                        line.textline_id &&
                        line.textline_id.endsWith(special_key)
                    ) {
                        textareas.push(
                            ...this._makeTextAreas(
                                line.textline_id,
                                line.recognize_words,
                                settings!,
                                special_key
                            )
                        );

                        processed = true;
                    }
                }

                if (!processed) {
                    const lineMetrics_s = imageMetrics.filter(
                        (val: IMetricUnit) =>
                            (val as WordMetric).word_id === line.word_id
                    );

                    lineMetrics_s.forEach((lineMetrics: IMetricUnit) => {
                        const metrics = lineMetrics as WordMetric;
                        const text = `${metrics.recall}%, ${metrics.precision}%, ${metrics.overlap}%`;

                        textareas.push(
                            ...this._makeTextAreas(
                                line.word_id,
                                line.recognize_words,
                                settings!,
                                "reco",
                                text
                            )
                        );

                        textareas.push(
                            ...this._makeTextAreas(
                                line.word_id,
                                line.reference_words,
                                settings!,
                                "ref",
                                text
                            )
                        );

                        textareas.push(
                            ...this._makeTextAreas(
                                line.word_id,
                                line.overlapped_words,
                                settings!,
                                "overlapped",
                                text
                            )
                        );

                        for (const item of [["overlap", 0]]) {
                            const key = item[0].toString();
                            const value = Number(item[1]);
                            const attr = settings![key].name;
                            if (
                                (metrics[attr as keyof WordMetric] as number) >
                                value
                            ) {
                                // For whole deleted textline, show reference words
                                const displayWords = _.isEmpty(
                                    line.recognize_words
                                )
                                    ? line.reference_words
                                    : line.recognize_words;
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.word_id,
                                        displayWords,
                                        settings!,
                                        key,
                                        text
                                    )
                                );
                            }
                        }
                        for (const item of [
                            ["recall", 95],
                            ["precision", 100],
                        ]) {
                            const key = item[0].toString();
                            const value = Number(item[1]);
                            const attr = settings![key].name;
                            if (
                                (metrics[attr as keyof WordMetric] as number) <
                                value
                            ) {
                                // For whole deleted textline, show reference words
                                const displayWords =
                                    line.recognize_words.length === 0
                                        ? line.reference_words
                                        : line.recognize_words;
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.word_id,
                                        displayWords,
                                        settings!,
                                        key,
                                        text
                                    )
                                );
                            }
                        }

                        if (metrics.entity_name && settings) {
                            const settingKeys = Object.keys(settings!);
                            if (
                                settingKeys &&
                                settingKeys.indexOf(metrics.entity_name) !== -1
                            ) {
                                // For whole deleted textline, show reference words
                                const displayWords =
                                    line.recognize_words.length === 0
                                        ? line.reference_words
                                        : line.recognize_words;
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.word_id,
                                        displayWords,
                                        settings!,
                                        metrics.entity_name,
                                        text
                                    )
                                );
                            }
                        }
                    });
                }

                return textareas;
            });
        } else if (
            this.props.metricsType === MetricsType.predictionlineMetrics
        ) {
            textareas = this.setPredictionLineTextAreas(
                lines,
                settings,
                imageMetrics
            );
        } else {
            textareas = lines.flatMap((line) => {
                let textareas: TextArea[] = [];

                let processed = false;

                for (const special_key of [
                    "filtered",
                    "early_rejected",
                    "rejected",
                    "detected",
                    "invalid",
                ]) {
                    if (line.textline_id.endsWith(special_key)) {
                        textareas.push(
                            ...this._makeTextAreas(
                                line.textline_id,
                                line.recognize_words,
                                settings!,
                                special_key
                            )
                        );
                        if (
                            this.props.metricsType ===
                                MetricsType.detectionMetrics &&
                            special_key === "detected"
                        ) {
                            const lineMetrics = imageMetrics.find(
                                (val: IMetricUnit) =>
                                    (val as TextlineCharMetric).textline_id ===
                                    line.textline_id
                            );
                            if (
                                lineMetrics &&
                                (lineMetrics as any)["cover_ratio"] <= 0.001
                            ) {
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.textline_id,
                                        line.recognize_words,
                                        settings!,
                                        "FD"
                                    )
                                );
                            }
                        }
                        if (
                            special_key === "detected" &&
                            line["cover_ratio"] <= 0.001
                        ) {
                            textareas.push(
                                ...this._makeTextAreas(
                                    line.textline_id,
                                    line.recognize_words,
                                    settings!,
                                    "FD"
                                )
                            );
                        }
                        processed = true;
                        break;
                    }
                }

                if (!processed) {
                    textareas.push(
                        ...this._makeTextAreas(
                            line.textline_id,
                            line.recognize_words,
                            settings!,
                            "reco"
                        )
                    );

                    textareas.push(
                        ...this._makeTextAreas(
                            line.textline_id,
                            line.reference_words,
                            settings!,
                            "ref"
                        )
                    );
                }

                if (!processed && imageMetrics) {
                    const lineMetrics_s = imageMetrics.filter(
                        (val: IMetricUnit) =>
                            (val as TextlineCharMetric).textline_id ===
                            line.textline_id
                    );

                    lineMetrics_s.forEach((lineMetrics: IMetricUnit) => {
                        const metrics = lineMetrics as TextlineCharMetric;
                        let correct = true;
                        for (const key of ["insert", "delete", "subs"]) {
                            const attr = settings![key].name;
                            if (
                                this.props.metricsType ===
                                MetricsType.mathOcrMetrics
                            ) {
                                const formula_attr = `formula_${attr}`;
                                if (
                                    (metrics[
                                        formula_attr as keyof TextlineCharMetric
                                    ] as number) > 0
                                ) {
                                    correct = false;
                                    // For whole deleted textline, show reference words
                                    const displayWords =
                                        line.recognize_words.length === 0
                                            ? line.reference_words
                                            : line.recognize_words;
                                    textareas.push(
                                        ...this._makeTextAreas(
                                            line.textline_id,
                                            displayWords,
                                            settings!,
                                            key
                                        )
                                    );
                                }
                            }
                            if (
                                (metrics[
                                    attr as keyof TextlineCharMetric
                                ] as number) > 0
                            ) {
                                correct = false;
                                // For whole deleted textline, show reference words
                                const displayWords =
                                    line.recognize_words.length === 0
                                        ? line.reference_words
                                        : line.recognize_words;
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.textline_id,
                                        displayWords,
                                        settings!,
                                        key
                                    )
                                );
                            }
                            if (
                                this.props.metricsType ===
                                    MetricsType.detectionMetrics &&
                                metrics["cover_ratio"] <= 0.6
                            ) {
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.textline_id,
                                        line.reference_words,
                                        settings!,
                                        "FN"
                                    )
                                );
                            }
                        }

                        if (correct) {
                            textareas.push(
                                ...this._makeTextAreas(
                                    line.textline_id,
                                    line.recognize_words,
                                    settings!,
                                    "correct"
                                )
                            );
                        }

                        if (metrics.entity_name && settings) {
                            const settingKeys = Object.keys(settings!);
                            if (
                                settingKeys &&
                                settingKeys.indexOf(metrics.entity_name) !== -1
                            ) {
                                // For whole deleted textline, show reference words
                                const displayWords =
                                    line.recognize_words.length === 0
                                        ? line.reference_words
                                        : line.recognize_words;
                                textareas.push(
                                    ...this._makeTextAreas(
                                        line.textline_id,
                                        displayWords,
                                        settings!,
                                        metrics.entity_name
                                    )
                                );
                            }
                        }
                    });
                }

                return textareas;
            });
        }

        this.setState({
            settings: settings,
            textareas: textareas,
            loading: false,
        });
    }

    private setBarcodeGeneralImageAreas(
        lines: OcrPolygon[],
        settings: {
            [key: string]: ISetting;
        }
    ): TextArea[] {
        const textareas: TextArea[] = [];
        lines.forEach((line) => {
            textareas.push(
                ...this._makeTextAreas(
                    line.textline_id,
                    line.recognize_words,
                    settings,
                    "reco"
                )
            );

            textareas.push(
                ...this._makeTextAreas(
                    line.textline_id,
                    line.reference_words,
                    settings,
                    "gt"
                )
            );
        });

        return textareas;
    }

    deduplicationPolygon = (
        lines: OcrPolygon[],
        key: "recognize_words" | "reference_words"
    ) => {
        const deduplicationDatas = _.uniqBy(lines, (line) =>
            line[key][0].polygon.join("_")
        );
        const diffDatas = _.difference(lines, deduplicationDatas);
        let resultData = deduplicationDatas;
        if (diffDatas.length > 0) {
            const differentPolygon = deduplicationDatas.filter(
                (line) =>
                    line[key][0].polygon.join("_") !==
                    diffDatas[0][key][0].polygon.join("_")
            );
            const samePolygon = deduplicationDatas.filter(
                (line) =>
                    line[key][0].polygon.join("_") ===
                    diffDatas[0][key][0].polygon.join("_")
            );
            const allSomePolygon = diffDatas
                .concat(samePolygon[0])
                .sort((a, b) => (a[key][0].text > b[key][0].text ? 1 : 0));

            resultData = differentPolygon.concat(allSomePolygon[0]);
        }
        return resultData;
    };

    private setFigureGeneralImageAreas(
        lines: OcrPolygon[],
        settings: {
            [key: string]: ISetting;
        }
    ): TextArea[] {
        const textareas: TextArea[] = [];

        const recs = this.deduplicationPolygon(lines, "recognize_words");
        recs.forEach((line) => {
            line.recognize_words[0].polygon.length > 0 &&
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.recognize_words,
                        settings,
                        "reco"
                    )
                );
        });

        const refs = this.deduplicationPolygon(lines, "reference_words");

        refs.forEach((line) => {
            line.reference_words[0].polygon.length > 0 &&
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.reference_words,
                        settings,
                        "ref"
                    )
                );
        });

        lines.forEach((line) => {
            if (
                line.recognize_words[0].polygon.length > 0 &&
                line.reference_words[0].polygon.length === 0
            ) {
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.recognize_words,
                        settings,
                        "falsealarm"
                    )
                );
            }

            if (
                line.recognize_words[0].polygon.length === 0 &&
                line.reference_words[0].polygon.length > 0
            ) {
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.reference_words,
                        settings,
                        "missdetection"
                    )
                );
            }
            if (line.recognize_words[0].text.includes("Matched Multi GTs")) {
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.reference_words,
                        settings,
                        "gts"
                    )
                );
            }
            if (line.reference_words[0].text.includes("Matched Multi Preds")) {
                textareas.push(
                    ...this._makeTextAreas(
                        line.textline_id,
                        line.reference_words,
                        settings,
                        "preds"
                    )
                );
            }
        });

        return textareas;
    }

    private setPredictionLineTextAreas = (
        lines: OcrPolygon[],
        settings: { [key: string]: ISetting },
        imageMetrics: IMetricUnit[]
    ) => {
        return lines.flatMap((line) => {
            let textareas: TextArea[] = [];

            if (imageMetrics) {
                const lineMetrics_s = imageMetrics.filter(
                    (val: IMetricUnit) =>
                        (val as PredictionlineMetric).textline_id ===
                        line.textline_id
                );

                lineMetrics_s.forEach((lineMetrics: IMetricUnit) => {
                    const metrics = lineMetrics as PredictionlineMetric;

                    if (metrics.entity_pred_result) {
                        textareas.push(
                            ...this._makeTextAreas(
                                line.textline_id,
                                line.recognize_words,
                                settings!,
                                "correct"
                            )
                        );
                    } else {
                        textareas.push(
                            ...this._makeTextAreas(
                                line.textline_id,
                                line.recognize_words,
                                settings!,
                                "error"
                            )
                        );
                    }

                    if (metrics.entity_name && settings) {
                        const settingKeys = Object.keys(settings!);
                        if (
                            settingKeys &&
                            settingKeys.indexOf(metrics.entity_name) !== -1
                        ) {
                            // For whole deleted textline, show reference words
                            const displayWords =
                                line.recognize_words.length === 0
                                    ? line.reference_words
                                    : line.recognize_words;
                            textareas.push(
                                ...this._makeTextAreas(
                                    line.textline_id,
                                    displayWords,
                                    settings!,
                                    metrics.entity_name
                                )
                            );
                        }
                    }
                });
            }

            return textareas;
        });
    };

    private _getSettings = () => {
        const { metricsType } = this.props;
        let setting = DEFAULT_SETTINGS;
        switch (metricsType) {
            case MetricsType.detectionMetrics:
                setting = DETECTION_SETTINGS;
                break;
            case MetricsType.wordAlignMetrics:
                setting = WORDALIGN_SETTINGS;
                break;
            case MetricsType.predictionlineMetrics:
                setting = PREDICTION_SETTINGS;
                break;
            case MetricsType.barcodeMetrics:
                setting = BARCODE_SETTINGS;
                break;
            case MetricsType.figureMetrics:
                setting = FIGURE_SETTINGS;
                break;
            default:
                setting = DEFAULT_SETTINGS;
        }

        return _.cloneDeep(setting);
    };

    private _downloadImage(imageSrc: string) {
        if (imageSrc) {
            const imageName = GetFileName(imageSrc);
            downloadAsFileWithAssurance(imageSrc, imageName, FileType.Image);
        }
    }

    private _downloadLabel(imageSrc: string) {
        if (imageSrc) {
            const ext = GetExtension(imageSrc);
            const labelSrc = imageSrc
                .replace("/Images/", "/Labels/v2.0/")
                .replace(ext, ".xml");
            const labelName = GetFileName(labelSrc);
            downloadAsFileWithAssurance(labelSrc, labelName, FileType.Xml);
        }
    }
}
