import * as React from "react";

import {
    IMetrics,
    IMergedMetrics,
    Record,
    RecordDetail,
    mergeMetrics,
    Workspaces,
} from "../../DataContract";
import { getFromLocalStorage, saveToLocalStorage } from "../../Utils";
import { from } from "linq-to-typescript";
import _ from "lodash";
import { Unsubscribe } from "redux";
import { Subscription } from "rxjs";
import { store } from "../../../store";
import exportExcelObservable from "../../Pages/common/ExportExcelObservable";

export interface IMetricProps {
    saveSetKey?: string;
    records: Record[];

    selectedColumns?: string[];
    isDarkTheme?: boolean;
    deepLinkHandler?: (key: string, linkData: any) => void;
}

export interface IMetricState<M> {
    evalData: IMergedMetrics<M>;
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
    entityList?: string[];
    selectedColumns?: string[];
    matchDatasetVersion?: boolean;
    level?: string;
}

export abstract class MetricsView<
    P extends IMetricProps,
    S extends IMetricState<M>,
    M
> extends React.Component<P, S> {
    public workSpace = store.getState().globalReducer.workSpace;

    constructor(props: P) {
        super(props);
        this.loadStateFromReduxStore = this.loadStateFromReduxStore.bind(this);
        this.setImageMark = this.setImageMark.bind(this);
        this.state = {
            matchDatasetVersion: true,
            level: "lv2",
        } as S;
    }
    private unsubscribe?: Unsubscribe;
    public exportData?: any;
    private exportSub?: Subscription;
    exportAction = () => {};
    componentWillMount(): void {
        this.unsubscribe = store.subscribe(this.loadStateFromReduxStore);
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        if (this.exportSub) {
            this.exportSub.unsubscribe();
        }
    }
    public componentDidMount() {
        this.exportSub = exportExcelObservable.subscribe(() => {
            this.exportAction && this.exportAction();
        });
        this.onEvaluationRecordChanged();
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<M>
    ) {
        if (!_.isEqual(this.props.records, prevProps.records)) {
            this.onEvaluationRecordChanged();
        }
    }

    abstract queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<M>>;

    abstract onEvaluationRecordChanged(): void;

    protected getLanguageList(matchDatasetVersion?: boolean): string[] {
        const { records } = this.props;

        if (records.length === 0) {
            return [];
        }
        let languageList = records[0]
            .getDetails()
            .map((detail) =>
                matchDatasetVersion
                    ? detail.dataset.displayFullName
                    : detail.dataset.displayName
            );
        records.forEach((recordItem) => {
            const compareLanguageList = recordItem
                .getDetails()
                .map((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName
                        : detail.dataset.displayName
                );

            languageList = languageList?.filter(
                (v) => compareLanguageList!.indexOf(v) > -1
            );
        });
        return Array.from(new Set(languageList)).sort();
    }

    protected showEvaluationResult(
        recordDetails: RecordDetail[],
        metricName: string,
        onSuccess?: () => void,
        slice: boolean = true
    ) {
        Promise.all(
            recordDetails.map((detail) =>
                this.queryEvaluationResult(detail, metricName)
            )
        )
            .then((data) => {
                const duplicateMetrics = data.map((d) => {
                    let duplicateMetric: any = {};
                    Object.entries(d).forEach(([key, val]) => {
                        let currKey = key;
                        if (slice) {
                            currKey = key.slice(
                                key.lastIndexOf("/") + 1,
                                key.length
                            );
                        }
                        duplicateMetric[currKey] = val;
                    });
                    return duplicateMetric;
                });

                const merged = mergeMetrics<M>(duplicateMetrics);
                const entityList = this.getEntityList(merged);
                this.setState(
                    {
                        evalData: merged,
                        entityList: entityList,
                    },
                    () => onSuccess && onSuccess()
                );
            })
            .catch((_err) =>
                this.setState({
                    evalData: {},
                    entityList: [],
                })
            );
    }

    protected filterRecordDetails(
        languageInfo: string,
        matchDatasetVersion?: boolean
    ): RecordDetail[] {
        return this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName === languageInfo
                        : detail.dataset.displayName === languageInfo
                )[0];
        });
    }

    protected getCategoryList(
        languageInfo: string,
        matchDatasetVersion?: boolean
    ): string[] {
        const filteredRecordDetail = this.filterRecordDetails(
            languageInfo,
            matchDatasetVersion
        )[0];

        const categories = filteredRecordDetail?.dataset.categories;
        return categories && categories.length > 0
            ? from(categories)
                  .orderBy((c) => c)
                  .toArray()
            : [];
    }

    protected getEntityList(evalData: IMergedMetrics<M>): string[] {
        return [];
    }

    protected loadSelectedLanguage(
        strOfSelectedLang: string | undefined,
        languages: string[]
    ): string {
        let selectedLanguage = languages[0];
        if (strOfSelectedLang) {
            const filteredLangs = languages.filter((lang) =>
                lang.includes(strOfSelectedLang)
            );
            if (filteredLangs?.length > 0) {
                selectedLanguage = filteredLangs[0];
            }
        }

        return selectedLanguage;
    }

    protected loadStateFromReduxStore() {
        const reducer = store.getState().settingReducer;
        const selectedItems = reducer.selectedItems;
        if (!_.isEqual(selectedItems, this.state.selectedColumns)) {
            this.setState({
                selectedColumns: selectedItems,
            });
        }
        if (
            !_.isEqual(
                reducer.matchDatasetVersion,
                this.state.matchDatasetVersion
            )
        ) {
            this.setState({
                matchDatasetVersion: reducer.matchDatasetVersion,
            });
        }
        if (!_.isEqual(reducer.selectedLevel, this.state.level)) {
            this.setState({
                level: reducer.selectedLevel,
            });
        }
    }

    public _onColumnChange(option?: any, isChecked?: boolean): void {
        const newSelectedItems = this.state.selectedColumns?.slice() || [];
        if (option) {
            if (isChecked) {
                // add the option if it's checked
                newSelectedItems.push(option);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }
            this.setState({ selectedColumns: newSelectedItems });
        }
    }

    protected _saveSetting(key?: string) {
        const saveKey = this._getSaveKey(key);
        saveToLocalStorage(
            saveKey,
            this.state.selectedColumns?.join(",") || ""
        );
    }

    protected _getSelectedColumns(key?: string) {
        const saveKey = this._getSaveKey(key);
        return getFromLocalStorage(saveKey)?.split(",");
    }

    protected getSessionStateKey(
        records: Record[],
        workspaceName: string = "",
        tabName: string = "",
        subTapName: string = ""
    ): string {
        const recordIds = records.map((r) => r.id).join("_");
        return `${recordIds}_${workspaceName}_${tabName}_${subTapName}`;
    }

    protected setImageMark(
        markInfo?: string[],
        textlineId?: string,
        content?: string
    ) {
        const { selectLanguage, selectCategory, selectImageId } = this.state;
        const { records } = this.props;
        if (selectLanguage && selectCategory && selectImageId && textlineId) {
            const language = selectLanguage.split(":")[0];
            const dataset = selectLanguage.replace(language + ":", "");
            records[0].setRecordError(
                language,
                dataset,
                selectCategory!,
                selectImageId!,
                textlineId,
                content!,
                markInfo!,
                Workspaces.Ocr
            );
        }
    }

    private _getSaveKey(key?: string): string {
        let saveKey = "";

        if (this.props.saveSetKey) {
            saveKey += `${this.props.saveSetKey}`;
        }
        if (key) {
            saveKey += `_${key}`;
        }

        return saveKey;
    }
}
