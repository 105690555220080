import React from "react";

import {
    KvpOverview,
    KvpDocMetricsView,
    KvpTextlineView,
    LatencyMetrics,
} from "../../Views";
import {
    CANNOTCOMPARE,
    Record,
    Typename,
    Workspaces,
} from "../../DataContract";
import { Label } from "@fluentui/react";

export interface KvpStateInSession {
    selectLanguage?: string;
}

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    linkData?: any;
    deepLinkHandler?: (key: string, linkData: any) => void;
    storageVersion?: string;
    display?: boolean;
    isDarkTheme?: boolean;
}

export class KvpPage extends React.Component<IProps, IState> {
    public render() {
        const { deepLinkHandler, linkData, records, selectedKey, isDarkTheme } =
            this.props;

        const { storageVersion, display } = this.props;

        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };
        return (
            <>
                {display ? (
                    <>
                        {selectedKey === "overviewKvpMetrics" && (
                            <KvpOverview
                                saveSetKey={`${Workspaces.Kvp}_${Typename.KvpOverview}`}
                                records={records}
                                storageVersion={storageVersion}
                                kvpDocMetricsDeepLinkHandler={deepLinkHandler}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        )}
                        {selectedKey === "overviewKvpDocumentMetrics" && (
                            <KvpDocMetricsView
                                saveSetKey={`${Workspaces.Kvp}_${Typename.KvpDocMetricsView}`}
                                records={records}
                                toSelectLanguage={linkSetting?.toSelectLanguage}
                                deepLinkHandler={deepLinkHandler}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        )}
                        {selectedKey === "overviewKvpDetailMetrics" && (
                            <KvpTextlineView
                                saveSetKey={`${Workspaces.Kvp}_${Typename.KvpTextlineView}`}
                                records={records}
                                toSelectLanguage={linkSetting?.toSelectLanguage}
                                queryString={linkSetting?.queryString}
                                isDarkTheme={this.props.isDarkTheme}
                                deepLinkHandler={deepLinkHandler}
                            />
                        )}
                        {selectedKey === "ocrKvpLatencyMetrics" && (
                            <LatencyMetrics
                                saveSetKey={`${Workspaces.Kvp}_${Typename.LatencyMetrics}`}
                                records={records}
                                isDarkTheme={isDarkTheme}
                                defaultCrossLanguage="Overall"
                            ></LatencyMetrics>
                        )}
                    </>
                ) : (
                    <Label>{CANNOTCOMPARE}</Label>
                )}
            </>
        );
    }
}
