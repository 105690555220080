import React from "react";
import {
    DatasetSet,
    IDataItem,
    Record,
    Typename,
    Workspaces,
} from "../../DataContract";

import _ from "lodash";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Label,
} from "@fluentui/react";
import { getColorByIndex } from "../../Utils";
import {
    ColumnValueType,
    IGroupedBarChartData,
    TableColumn,
    TableList,
    VerticalBarChart,
} from "../../Controls";
import { Consumer } from "../../Layout";
import { NoDataTip } from "../../Controls/NoDataTip";
import { Unsubscribe } from "redux";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "macro_teds", name: "macro teds",   fieldName: "macro_teds",   valueType: ColumnValueType.Number, minWidth: 5, maxWidth:300,  isResizable: true, },
    { key: "micro_teds", name: "micro teds",   fieldName: "micro_teds",   valueType: ColumnValueType.Number, minWidth: 5, maxWidth:300,  isResizable: true, },
   ];

interface IProps {
    dataItems: IDataItem<any>[];
    records: Record[];
    viewType?: string;
    onItemInvoked?: (data: any) => void;
}

interface IState {
    dataItems: IDataItem<any>[];
    matchDatasetVersion?: boolean;
    selectedColumns?: string[];
}

export class HDSAView extends React.Component<IProps, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(p: IProps) {
        super(p);
        this.state = {
            dataItems: p.dataItems,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };

        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrPod}_${Typename.GeneralMetrics}_Overview_hdsa`,
                selectedItems: OVERVIEW_COLUMNS.map((col) => col.key),
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    componentWillMount(): void {
        this.unsubscribe = store.subscribe(() => {
            const reducer = store.getState().settingReducer;
            const selectedItems = reducer.selectedItems;
            if (!_.isEqual(selectedItems, this.state.selectedColumns)) {
                this.setState({
                    selectedColumns: selectedItems,
                });
            }
            const matchDatasetVersion = reducer.matchDatasetVersion;
            if (
                !_.isEqual(matchDatasetVersion, this.state.matchDatasetVersion)
            ) {
                this.setState({
                    matchDatasetVersion: matchDatasetVersion,
                });
            }
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }
    componentWillReceiveProps(nextProps: IProps): void {
        if (!_.isEqual(nextProps, this.props)) {
            this.setState({ dataItems: nextProps.dataItems });
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        if (!_.isEqual(prevState, this.state)) {
            this._prepareRenderChartData();
        }
    }
    private _renderChartByMetricDefinition(id: string, metrics: any) {
        let subCharts: JSX.Element[] = [];
        if (!!metrics) {
            const defProp = ["macro_teds", "micro_teds"];

            const chartData: IGroupedBarChartData[] = [];
            defProp.forEach((prop) => {
                const values = (metrics.table as any)[prop] as any[];
                if (values && values.some((value) => !isNaN(value))) {
                    const table = (values as any[]).map(
                        (value, recordIndex) => {
                            return {
                                key: `metricDef_${id}_${recordIndex}`,
                                data: value
                                    ? Number(Number(value).toFixed(2))
                                    : (0 as number),
                                color: getColorByIndex(recordIndex),
                                legend: this.props.records[recordIndex].name,
                            };
                        }
                    );

                    chartData.push({
                        name: prop,
                        series: table,
                    });
                }
            });

            if (chartData && chartData.length > 0) {
                const subChart = (
                    <DocumentCardDetails key={`barChartMetrics_hdsa`}>
                        {this.renderSingleChart(id, chartData, "hdsa")}
                    </DocumentCardDetails>
                );

                subCharts.push(subChart);
            }
        }

        return subCharts;
    }
    protected renderSingleChart(
        id: string,
        chartData: IGroupedBarChartData[],
        title: string
    ) {
        return (
            <>
                <DocumentCardTitle className="overview__title" title={title} />
                <Consumer>
                    {(value) => {
                        return (
                            <VerticalBarChart
                                id={id}
                                data={chartData}
                                height={400}
                                isDarkTheme={value}
                            />
                        );
                    }}
                </Consumer>
            </>
        );
    }
    private _renderAsChart() {
        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderChartData();

            const definitionName = "hdsa";
            const jsxElementArr: any[] = data
                .map(([datasetname, metricView], index) => {
                    if (!!metricView[definitionName].table) {
                        const subCharts = this._renderChartByMetricDefinition(
                            `HDSA${index}`,
                            metricView[definitionName]
                        );

                        if (subCharts.length > 0) {
                            return (
                                <div key={index}>
                                    <Label>{datasetname}</Label>
                                    <div
                                        key={`barChartMetrics_${definitionName}_container`}
                                    >
                                        <DocumentCard
                                            className="overview__card"
                                            key={`barChartMetrics_${definitionName}`}
                                            type={DocumentCardType.compact}
                                        >
                                            {subCharts}
                                        </DocumentCard>
                                    </div>
                                </div>
                            );
                        }
                    }

                    return null;
                })
                .filter((element) => element);

            return jsxElementArr && jsxElementArr.length > 0 ? (
                <>{jsxElementArr}</>
            ) : (
                <NoDataTip>No HDSA Data</NoDataTip>
            );
        }
    }

    private _prepareRenderChartData() {
        const { records } = this.props;

        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );
        const datasetFullNames = Array.from(
            new Set(datasets.map((dataset) => dataset.fullName))
        );

        const data = datasetFullNames.map((fullName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item: any) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.fullName === fullName
                    );
                });
                return item;
            });

            const groups = ["hdsa"];

            const tableData: any = {};
            groups.forEach((group) => {
                let data = [] as any[];
                let tableArr: any = {};
                items.forEach((item) => {
                    if (!!item && (item.metrics as any)[group]) {
                        const { metrics } = item;

                        let metricItem = (metrics as any)[group]
                            ? (metrics as any)[group]
                            : {};

                        for (const key in metricItem) {
                            if (!!!tableArr[key]) {
                                tableArr[key] = new Array(records.length).fill(
                                    undefined
                                );
                            }

                            tableArr[key][item.recordIndex] = this.getValue(
                                metricItem[key]
                            );
                        }
                    }
                    data.push(item);
                });
                const categoryItems = {
                    data: data,
                    table: tableArr,
                };
                tableData[group] = categoryItems;
            });
            return [fullName, tableData];
        });
        return data;
    }

    private _prepareRenderTableData() {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;

        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const data = datasetNames.map((name) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName === name
                            : item.recordDetail.dataset.displayName === name)
                    );
                });
                return item;
            });

            const groups = ["hdsa"];

            const tableData: any = {};
            groups.forEach((group) => {
                const dataMap = new Map<string, any[]>();
                items.forEach((item, itemIndex) => {
                    if (!!item && (item.metrics as any)[group]) {
                        const obj = (item.metrics as any)[group];
                        let valArr = dataMap.get("hdsa");
                        if (valArr === undefined) {
                            valArr = Array.from(
                                { length: items.length },
                                () => {
                                    return {} as any;
                                }
                            );
                        }

                        const tempV: any = _.cloneDeep(obj);

                        for (const key of Object.keys(tempV)) {
                            tempV[key] = this.getValue(tempV[key]);
                        }
                        valArr[itemIndex] = tempV;
                        dataMap.set("hdsa", valArr);
                    }
                });

                tableData[group] = dataMap;
            });

            return [name, tableData];
        });

        const filterData = data.filter(([_, tableData]) => tableData !== null);
        return filterData;
    }

    private _renderAsTable() {
        if (this.state.dataItems.length > 0) {
            const { records, onItemInvoked } = this.props;
            const { selectedColumns } = this.state;
            const data = this._prepareRenderTableData();
            let showColumns = OVERVIEW_COLUMNS;
            if (selectedColumns) {
                showColumns = OVERVIEW_COLUMNS.filter(
                    (value) =>
                        selectedColumns?.findIndex(
                            (col) => col === value.key
                        ) !== -1
                );
            }

            const jsxElementArr = data
                .map(([datasetname, metricView], index) => {
                    if (!!metricView["hdsa"]) {
                        const evalData = metricView["hdsa"] as Map<
                            string,
                            any[]
                        >;

                        if (evalData && evalData.size > 0) {
                            return (
                                <div key={index}>
                                    <Label>{datasetname}</Label>
                                    <TableList
                                        evalData={evalData}
                                        evalDataCount={records.length}
                                        columns={showColumns}
                                        downloadTableTitle={datasetname}
                                        disableFreezeHeader
                                        hideHeader
                                        onItemInvoked={(item: any) => {
                                            onItemInvoked &&
                                                onItemInvoked([
                                                    "hdsa",
                                                    datasetname,
                                                ]);
                                        }}
                                    />
                                </div>
                            );
                        }
                        return null;
                    }

                    return null;
                })
                .filter((element) => element);

            return jsxElementArr && jsxElementArr.length > 0 ? (
                <>{jsxElementArr}</>
            ) : (
                <NoDataTip>No HDSA Data</NoDataTip>
            );
        }
    }

    getValue = (value: any) => {
        if (value && !isNaN(value)) {
            return Number(Number(value) * 100).toFixed(2);
        }
        return value;
    };

    render(): React.ReactNode {
        const { viewType } = this.props;
        return (
            <>
                {viewType === "Chart" && this._renderAsChart()}
                {viewType === "Table" && this._renderAsTable()}
            </>
        );
    }
}
