import { IDropdownOption } from "@fluentui/react";
import * as React from "react";
import { DatasetSet, Record, RecordDetail } from "../../DataContract";
import { getFromLocalStorage, saveToLocalStorage } from "../../Utils";
import _ from "lodash";
import { store } from "../../../store";
import { Unsubscribe } from "redux";
import { Subscription } from "rxjs";
import { updateExpandAction } from "../../../store/reducers";
import exportExcelObservable from "../../Pages/common/ExportExcelObservable";

export interface ICommonProps {
    saveSetKey?: string;
    records: Record[];
    isReport?: boolean;
    alwaysShow?: boolean;
    setSelectedSubPivot?: (selectPivot: string, linkData?: any) => void;
    isDarkTheme?: boolean;
    onItemInvoked?: (data: any) => void;
}

export interface ICommonState<M> {
    selectedColumns?: string[];
    matchDatasetVersion?: boolean;
    dataItems: any;
    expandAll?: boolean;
    expandItem?: Map<number, boolean>;
    level?: string;
    viewType?: string;
}

export abstract class CommonView<
    P extends ICommonProps,
    S extends ICommonState<M>,
    M
> extends React.Component<P, S> {
    private unsubscribe?: Unsubscribe;
    private exportSub?: Subscription;
    public exportData?: any;
    public workSpace = store.getState().globalReducer.workSpace;

    exportAction = () => {};
    componentWillMount(): void {
        if (this.isInitializeWithReduxStore()) {
            this.unsubscribe = store.subscribe(() => {
                if (this.state) {
                    const reducer = store.getState().settingReducer;
                    const selectedItems = reducer.selectedItems;
                    if (
                        !_.isEqual(
                            selectedItems?.sort(),
                            this.state.selectedColumns?.sort()
                        )
                    ) {
                        this.setState({
                            selectedColumns: selectedItems,
                        });
                    }
                    const matchDatasetVersion = reducer.matchDatasetVersion;
                    if (
                        !_.isEqual(
                            matchDatasetVersion,
                            this.state.matchDatasetVersion
                        )
                    ) {
                        this.setState({
                            matchDatasetVersion: matchDatasetVersion,
                        });
                    }
                    if (!_.isEqual(reducer.selectedLevel, this.state.level)) {
                        this.setState({
                            level: reducer.selectedLevel,
                        });
                    }
                    const globalReducer = store.getState().globalReducer;
                    if (
                        !_.isEqual(globalReducer.viewType, this.state.viewType)
                    ) {
                        this.setState({
                            viewType: globalReducer.viewType,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.expandAll,
                            this.state.expandAll
                        )
                    ) {
                        const expand_item = _.clone(this.state.expandItem);
                        for (const key of this.state.expandItem?.keys() || []) {
                            expand_item?.set(
                                key,
                                globalReducer.expandAll ?? true
                            );
                        }

                        this.setState({
                            expandAll: globalReducer.expandAll,
                            expandItem: expand_item,
                        });
                    }
                }
            });
        }
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        if (this.exportSub) {
            this.exportSub.unsubscribe();
        }
    }

    public componentDidMount() {
        this.exportSub = exportExcelObservable.subscribe(() => {
            this.exportAction && this.exportAction();
        });
        this.queryMetricsResult();
    }

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<M>
    ) {
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            (prevState?.matchDatasetVersion !== undefined &&
                !_.isEqual(
                    this.state?.matchDatasetVersion,
                    prevState?.matchDatasetVersion
                ))
        ) {
            this.queryMetricsResult();
        }
    }

    abstract queryMetricsResult(): void;

    protected isInitializeWithReduxStore(): boolean {
        return true;
    }

    protected _queryMetricsResult(
        metricsFileName: string,
        selectLanguage?: string
    ) {
        const { matchDatasetVersion } = this.state;
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        // find one dataset detail per dataset
        const detailsList = this.props.records.map((r) => {
            if (!selectLanguage) {
                return r
                    .getDetails()
                    .filter((d) => d !== undefined) as RecordDetail[];
            } else {
                return datasets
                    .map((d) =>
                        r
                            .getDetails()
                            .find((rd) =>
                                selectLanguage
                                    ? rd.dataset.equals(d) &&
                                      (matchDatasetVersion
                                          ? rd.dataset.displayFullName ===
                                            selectLanguage
                                          : rd.dataset.displayName ===
                                            selectLanguage)
                                    : rd.dataset.equals(d)
                            )
                    )
                    .filter((d) => d !== undefined) as RecordDetail[];
            }
        });

        Promise.all(
            detailsList.flatMap((details, index) => {
                return details.map((detail) =>
                    detail
                        .fetchMetricsWithRecordDetail<M>(metricsFileName)
                        .then((metrics) => {
                            return {
                                recordIndex: index,
                                recordDetail: metrics[0],
                                metrics: metrics[1],
                            };
                        })
                        .catch((_error) => {
                            return undefined;
                        })
                );
            })
        )
            .then((dataItems) => {
                this.setState({
                    dataItems: dataItems.filter((v) => v),
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    protected _onColumnDropDownChange(option?: IDropdownOption): void {
        const newSelectedItems = this.state.selectedColumns!.slice();

        if (option) {
            if (option.selected) {
                // add the option if it's checked
                newSelectedItems.push(option.text);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option.text);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }

            this.setState({ selectedColumns: newSelectedItems });
        }
    }

    public _onColumnChange(option?: any, isChecked?: boolean): void {
        const newSelectedItems = this.state.selectedColumns!.slice();
        if (option) {
            if (isChecked) {
                // add the option if it's checked
                newSelectedItems.push(option);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }
            this.setState({ selectedColumns: newSelectedItems });
        }
    }

    protected _getLanguageList(matchDatasetVersion: boolean): string[] {
        const { records } = this.props;
        if (records.length === 0) {
            return [];
        }
        let languageList = records[0]
            .getDetails()
            .map((detail) =>
                matchDatasetVersion
                    ? detail.dataset.displayFullName
                    : detail.dataset.displayName
            );
        records.forEach((recordItem) => {
            const compareLanguageList = recordItem
                .getDetails()
                .map((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName
                        : detail.dataset.displayName
                );
            languageList = languageList?.filter(
                (v) => compareLanguageList!.indexOf(v) > -1
            );
        });
        return Array.from(new Set(languageList)).sort();
    }

    protected _getUnionDistinctLangList(
        matchDatasetVersion: boolean
    ): string[] {
        const { records } = this.props;
        if (records.length === 0) {
            return [];
        }

        const languageSet = new Set<string>();
        records.forEach((record) => {
            const langList = record
                .getDetails()
                .map((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName
                        : detail.dataset.displayName
                );

            langList.forEach(
                (lang) => !languageSet.has(lang) && languageSet.add(lang)
            );
        });

        return Array.from(languageSet).sort();
    }

    protected _loadSelectedLanguage(
        strOfSelectedLang: string | undefined,
        languages: string[]
    ): string {
        let selectedLanguage = languages[0];
        if (strOfSelectedLang) {
            const filteredLangs = languages.filter((lang) =>
                lang.includes(strOfSelectedLang!)
            );
            if (filteredLangs?.length > 0) {
                selectedLanguage = filteredLangs[0];
            }
        }

        return selectedLanguage;
    }

    protected _filterDatasetsForReport(datasetNames: string[]) {
        if (this.props.isReport) {
            const reportDatasetNames = datasetNames.filter((datasetName) => {
                return this._filterExcludeLanguages(datasetName);
            });
            return reportDatasetNames;
        }
        return datasetNames;
    }

    private _filterExcludeLanguages(datasetName: string): boolean {
        const excludedLanguages = [
            "coverage",
            "unofficial",
            "singlechar",
            "symbol",
        ];
        let result = true;
        excludedLanguages.forEach((language) => {
            if (datasetName.toLowerCase().includes(language)) {
                result = false;
            }
        });
        return result;
    }

    protected _saveSetting(key?: string) {
        const saveKey = this._getSaveKey(key);
        saveToLocalStorage(
            saveKey,
            this.state.selectedColumns?.join(",") || ""
        );
    }

    protected _getSelectedColumns(key?: string) {
        const saveKey = this._getSaveKey(key);
        return getFromLocalStorage(saveKey)?.split(",");
    }

    private _getSaveKey(key?: string): string {
        let saveKey = "";

        if (this.props.saveSetKey) {
            saveKey += `${this.props.saveSetKey}`;
        }
        if (key) {
            saveKey += `_${key}`;
        }
        return saveKey;
    }

    public componentWillReceiveProps(nextProps: ICommonProps) {
        if (!_.isEqual(this.props.records, nextProps.records)) {
            this.setState({ dataItems: [] });
        }
    }

    public _expandCountChange = (total: number) => {
        let expandCount = 0;
        for (const value of this.state.expandItem?.values() || []) {
            if (value) {
                expandCount++;
            }
        }
        if (
            (expandCount === 0 && this.state.expandItem?.size === total) ||
            expandCount === total
        ) {
            const expand = expandCount === total;
            if (expand !== store.getState().globalReducer.expandAll) {
                store.dispatch(updateExpandAction(expand));
            }
        }
    };

    public filterRecordDetails(
        languageInfo: string,
        matchDatasetVersion: boolean
    ): RecordDetail[] {
        return this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName === languageInfo
                        : detail.dataset.displayName === languageInfo
                )[0];
        });
    }
}
