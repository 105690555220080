import React from "react";
import { from } from "linq-to-typescript";
import {
    ColumnValueType,
    NumberFormat,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import { CustFormArguments, KEY_SEPARATOR } from "./CustFormDataInterface";
import {
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations } from "../../Controls/TableHeader";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";

// prettier-ignore
const CUSTOMFORM_TABLE_DETAIL_COLUMNS: TableColumn[] = [
    { key: "TableName",         name: "TableName",          fieldName: "TableName",         isKey: false,    valueType: ColumnValueType.String,   minWidth: 300,  maxWidth: 350, isResizable: true, },
    { key: "DocName",           name: "DocName",            fieldName: "DocName",           isKey: false,    valueType: ColumnValueType.String,   minWidth: 300,  maxWidth: 350, isResizable: true, },
    { key: "ErrorRate",         name: "ErrorRate (%)",      fieldName: "ErrorRate",         isKey: false,    valueType: ColumnValueType.Number,   minWidth: 300,  maxWidth: 350, isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces:2},
    { key: "OcrToyUrl",         name: "OcrToyUrl",          fieldName: "OcrToyUrl",         isKey: false,    valueType: ColumnValueType.Url,      minWidth: 200,  maxWidth: 250, isResizable: true, },
];

interface CustomFormImageMetricWithTableError {
    DocName: string;
    Accuracy: string;
    InferenceLatency: string;
    TableCellErrorRate?: { [key: string]: number };
}

interface CustomFormTableDetailMetric {
    TableName: string;
    DatasetName: string;
    DocName: string;
    TrainingDocCount: number;
    TrainingDocIndex: number;
    ErrorRate: number;
    OcrToyUrl: string;
}

interface IState extends IMetricState<CustomFormTableDetailMetric> {
    selectLanguage?: string;
    selectTrainingDocCount?: string;
    selectTrainingDocIndex?: string;
    selectTableName?: string;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
    toSelectDocCount?: string;
    toSelectDocIndex?: string;
    toSelectTableName?: string;
}

export class CustFormTableDetailMetrics extends MetricsView<
    IProps,
    IState,
    CustomFormTableDetailMetric
> {
    private _tableNameOptions: Array<string> = [];
    private _isDrillDown: boolean = false;
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this._isDrillDown = props.toSelectTableName !== undefined;
        this.state = {
            evalData: {},
            selectLanguage: props.toSelectLanguage,
            selectTrainingDocCount: props.toSelectDocCount,
            selectTrainingDocIndex: props.toSelectDocIndex,
            selectTableName: props.toSelectTableName,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.CustomForm}_${Typename.CustFormTableDetailMetrics}`,
                columns: CUSTOMFORM_TABLE_DETAIL_COLUMNS,
            })
        );
    }

    render(): React.ReactNode {
        const { records } = this.props;
        const { selectedColumns } = this.state;
        const columns: TableColumn[] = CUSTOMFORM_TABLE_DETAIL_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        const dataMap = this._prepareData();

        return (
            <>
                <TableList<CustomFormTableDetailMetric>
                    key={this.state.selectLanguage}
                    evalDataCount={records.length}
                    evalData={dataMap}
                    columns={columns}
                    downloadTableTitle={this.state.selectLanguage}
                    renderTableHeader={this._renderTableHeader}
                    isWiderCell={true}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </>
        );
    }

    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged();
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<CustomFormTableDetailMetric>> {
        const thisInstance = this;
        const { selectTableName } = this.state;

        return recordDetail
            .fetchMetricsWithRecord<
                IMetrics<CustomFormImageMetricWithTableError>
            >(metricName)
            .then(([record, metrics]) => {
                const items: any = {};
                const tableNameSet = new Set<string>();
                Object.entries(metrics).forEach(([_, val]) => {
                    const evalAlgo =
                        recordDetail.getRawProp<string>("algorithm");
                    const argu =
                        recordDetail.getRawProp<CustFormArguments>("arguments");

                    const evaluator = `${evalAlgo}(${argu.trainingDocCount},${argu.trainingDocIndex})`;

                    if (val.TableCellErrorRate) {
                        const errorRateArr = Object.entries(
                            val.TableCellErrorRate
                        );
                        errorRateArr.forEach(([tableName, errorRate]) => {
                            const tableDetailMetric = {
                                TableName: tableName,
                                DocName: val.DocName,
                                TrainingDocCount: argu.trainingDocCount,
                                TrainingDocIndex: argu.trainingDocIndex,
                                ErrorRate: errorRate,
                                OcrToyUrl:
                                    val.DocName.endsWith(".tif") ||
                                    val.DocName.endsWith(".tiff") ||
                                    val.DocName.endsWith(".pdf")
                                        ? `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.page1.html`
                                        : `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.html`,
                            } as CustomFormTableDetailMetric;

                            items[
                                `${tableName}${KEY_SEPARATOR}${val.DocName}`
                            ] = tableDetailMetric;

                            !tableNameSet.has(tableName) &&
                                tableNameSet.add(tableName);
                        });
                    }
                });

                thisInstance._tableNameOptions =
                    Array.from(tableNameSet).sort();

                if (
                    selectTableName === undefined ||
                    !(
                        thisInstance._isDrillDown &&
                        thisInstance._tableNameOptions.includes(selectTableName)
                    )
                ) {
                    thisInstance.setState(
                        {
                            selectTableName:
                                thisInstance._tableNameOptions.length > 0
                                    ? thisInstance._tableNameOptions[0]
                                    : undefined,
                        },
                        () => {
                            if (thisInstance._isDrillDown) {
                                thisInstance._isDrillDown = false;
                            }
                        }
                    );
                }

                return items;
            });
    }

    private _prepareData(): Map<
        string,
        (CustomFormTableDetailMetric | null)[]
    > {
        const { evalData, selectTableName } = this.state;
        const dataMap = new Map<
            string,
            (CustomFormTableDetailMetric | null)[]
        >();
        const keyValueArr = Object.entries(evalData);
        const displayKeyValueArr = keyValueArr.filter(([k, _]) => {
            const displayTableName = selectTableName
                ? selectTableName
                : this._tableNameOptions.length > 0
                ? this._tableNameOptions[0]
                : undefined;
            return k.split(KEY_SEPARATOR)[0] === displayTableName;
        });

        displayKeyValueArr.forEach(([k, v]) => {
            if (!dataMap.has(k)) {
                dataMap.set(k, v);
            }
        });

        return dataMap;
    }

    private _filterCustomFormRecordDetails(
        selectLanguage: string,
        selectTrainingDocCount: string,
        selectTrainingDocIndex: string
    ): RecordDetail[] {
        return this.props.records.map((record) => {
            return record.getDetails().filter((detail) => {
                const argu = detail.getRawProp<CustFormArguments>("arguments");
                return (
                    detail.dataset.name === selectLanguage &&
                    String(argu.trainingDocCount) === selectTrainingDocCount &&
                    String(argu.trainingDocIndex) === selectTrainingDocIndex
                );
            })[0];
        });
    }

    private _getTrainingArgumentList(
        selectLanguage: string,
        metricsName: string,
        selectTrainingDocCount: string = ""
    ) {
        const trainingArguements = this._getTrainingArguments(selectLanguage);
        const distinctArgues = trainingArguements.flatMap(
            (trainingArgue) => trainingArgue
        );

        if (metricsName === "TrainingDocCount") {
            return Array.from(
                new Set(
                    distinctArgues.map((argue) =>
                        String(argue.trainingDocCount)
                    )
                )
            );
        } else if (metricsName === "TrainingDocIndex") {
            const argueListWithGivenDocCount = distinctArgues.filter(
                (argue) =>
                    String(argue.trainingDocCount) === selectTrainingDocCount
            );

            return from(
                new Set(
                    argueListWithGivenDocCount.map(
                        (argue) => argue.trainingDocIndex
                    )
                )
            )
                .orderBy((i) => i)
                .toArray()
                .map((i) => String(i));
        }
        return [];
    }

    private _getTrainingArguments(selectLanguage: string) {
        const filteredRecordDetails = this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) => detail.dataset.name === selectLanguage);
        });
        return filteredRecordDetails.map((details) => {
            return details.map((d) => {
                return d.getRawProp<CustFormArguments>("arguments");
            });
        });
    }

    private _onEvaluationRecordChanged() {
        const { toSelectLanguage, toSelectDocCount, toSelectDocIndex } =
            this.props;
        const languageList = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        if (languageList.length > 0) {
            const language =
                toSelectLanguage && languageList.includes(toSelectLanguage)
                    ? toSelectLanguage
                    : languageList[0];

            const trainingDocCountList = this._getTrainingArgumentList(
                language,
                "TrainingDocCount"
            );
            const trainingDocIndexList = this._getTrainingArgumentList(
                language,
                "TrainingDocIndex",
                trainingDocCountList[0]
            );

            const stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: language,
                selectTrainingDocCount:
                    toSelectDocCount ?? trainingDocCountList[0],
                selectTrainingDocIndex:
                    toSelectDocIndex ?? trainingDocIndexList[0],
            };

            this._onOptionsChanged(stateSettings);
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _onOptionsChanged(
        newOptions: {
            [key: string]: string | undefined;
        },
        timeout: number = 1000
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        let selectTrainingDocCount =
            "selectTrainingDocCount" in newOptions
                ? newOptions["selectTrainingDocCount"]
                : this.state.selectTrainingDocCount;
        let selectTrainingDocIndex =
            "selectTrainingDocIndex" in newOptions
                ? newOptions["selectTrainingDocIndex"]
                : this.state.selectTrainingDocIndex;
        setTimeout(() => {
            if (
                selectTrainingDocIndex === this.state.selectTrainingDocIndex &&
                selectTrainingDocCount === this.state.selectTrainingDocCount &&
                selectLanguage === this.state.selectLanguage
            ) {
                this._onQueryButtonClicked();
            }
        }, timeout);
        this.setState({
            selectTrainingDocIndex: selectTrainingDocIndex,
            selectTrainingDocCount: selectTrainingDocCount,
            selectLanguage: selectLanguage,
        });
    }

    private _onQueryButtonClicked = () => {
        const {
            selectTrainingDocCount,
            selectTrainingDocIndex,
            selectLanguage,
        } = this.state;
        const { records } = this.props;
        if (records.length > 0 && selectLanguage) {
            const details = this._filterCustomFormRecordDetails(
                selectLanguage,
                selectTrainingDocCount!,
                selectTrainingDocIndex!
            );
            this.showEvaluationResult(details, "doc_results.json");
        }
    };

    private _renderTableHeader(): JSX.Element {
        const {
            selectLanguage,
            selectTrainingDocCount,
            selectTrainingDocIndex,
            selectTableName,
        } = this.state;
        let languages = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        const trainingDocCountList = this._getTrainingArgumentList(
            selectLanguage ?? languages[0],
            "TrainingDocCount"
        );
        const trainingDocIndexList = this._getTrainingArgumentList(
            selectLanguage ?? languages[0],
            "TrainingDocIndex",
            selectTrainingDocCount ?? trainingDocCountList[0]
        );

        const imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "DatasetFullName:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
            {
                key: "trainingDocCount",
                text: "TrainingDocCount:",
                options: trainingDocCountList,
                selectedKey: selectTrainingDocCount ?? trainingDocCountList[0],
                onChange: (docCount) => {
                    this._onOptionsChanged({
                        selectTrainingDocCount: docCount.text,
                    });
                },
            },
            {
                key: "trainingDocIndex",
                text: "TrainingDocIndex:",
                options: trainingDocIndexList,
                selectedKey: selectTrainingDocIndex ?? trainingDocIndexList[0],
                onChange: (docIndex) => {
                    this._onOptionsChanged({
                        selectTrainingDocIndex: docIndex.text,
                    });
                },
            },
            {
                key: "tableName",
                text: "TableName:",
                options: this._tableNameOptions,
                selectedKey: selectTableName,
                onChange: (option) => {
                    selectTableName !== option.key &&
                        this.setState({ selectTableName: option.key });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }
}
