import * as React from "react";
import "./MultipleSelectionBar.scss";
import {
  Label,
  Stack,
  IColumn,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  ConstrainMode,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  TooltipHost,
  Sticky,
  StickyPositionType,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  getTheme,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  mergeStyleSets,
  ActionButton,
  IIconProps,
} from "@fluentui/react";
import { Record } from "../DataContract/Record";
import { RecordColumn } from "./Common/RecordColumn";
import { ChangeOrderBar, OrderType } from "./Common/ChangeOrderBar";

interface IProps {
  columns: IColumn[];
  selectedRecords: Record[];
  workspace: string;
  isDarkTheme?: boolean;
  onRemove?: (item: Record, index?: number) => void;
}

interface IState {
  displayItems: Record[];
  showRecordList: boolean;
}

const theme = getTheme();

const freezeStyles = mergeStyleSets({
  wrapper: {
    width: "100%",
    height: "265px",
    bottom: 0,
    position: "relative",
    top: 0,
    backgroundColor: "white",
  },
});

const Expand_ICON: IIconProps = {
  iconName: "DoubleChevronDown",
  style: {
    fontSize: 14,
  },
};

const COLLAPSE_ICON: IIconProps = {
  iconName: "DoubleChevronUp",
  style: {
    fontSize: 14,
  },
};

export class MultipleSelectionBar extends RecordColumn<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this._onColumnRender = this._onColumnRender.bind(this);
    this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
    this._compare = this._compare.bind(this);
    this._columnRender = this._columnRender.bind(this);
    this._onChangeOrder = this._onChangeOrder.bind(this);
    this.state = {
      displayItems: this.props.selectedRecords,
      showRecordList: false,
    };
  }

  public render() {
    const { displayItems, showRecordList } = this.state;
    return (
      <>
        <Stack horizontal verticalAlign="center">
          <Label className="selectcount">{`Selected(${
            displayItems.length ?? 0
          }) Records`}</Label>
          <ActionButton
            iconProps={!showRecordList ? Expand_ICON : COLLAPSE_ICON}
            onClick={() => {
              this._showRecordList();
            }}
          />
          {displayItems.length !== 0 && (
            <PrimaryButton
              text="Compare"
              onClick={this._compare}
              style={{ marginRight: "10px" }}
            />
          )}
        </Stack>
        {this.state.showRecordList && (
          <Stack className="multipleSelection">
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            ></Stack>

            {this._renderList()}
          </Stack>
        )}
      </>
    );
  }

  private _renderList() {
    const { displayItems } = this.state;
    const columns = this._generateColumns(this.props.columns);
    const detailsList = (
      <DetailsList
        items={displayItems}
        columns={columns}
        className="catalog__table"
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        constrainMode={ConstrainMode.unconstrained}
        onRenderDetailsHeader={this._onRenderDetailsHeader}
        onRenderRow={this._onRenderRow}
      />
    );
    return (
      <>
        {displayItems.length > 3 ? (
          <Stack verticalFill className={freezeStyles.wrapper}>
            <ScrollablePane
              scrollbarVisibility={ScrollbarVisibility.auto}
              styles={{
                root: { zIndex: "99" },
                contentContainer: {
                  height: "100%",
                  overflowX: "hidden",

                  "::-webkit-scrollbar": {
                    width: 12,
                    height: 12,
                  },
                  "::-webkit-scrollbar-thumb": {
                    border: "4px solid transparent",
                    boxShadow: "none",
                    backgroundColor: "rgba(189,189,189,0.8)",
                    backgroundClip: "padding-box",
                  },

                  "::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#bdbdbd",
                  },

                  "::-webkit-scrollbar-track": {
                    boxShadow: "none",
                  },
                },
              }}
            >
              {detailsList}
            </ScrollablePane>
          </Stack>
        ) : (
          <>{detailsList}</>
        )}
      </>
    );
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.selectedRecords.length !== state.displayItems.length) {
      return { displayItems: props.selectedRecords };
    }
    return null;
  }

  private _onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<
      IDetailsColumnRenderTooltipProps
    > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  };

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = {
          backgroundColor: this.props.isDarkTheme
            ? theme.palette.neutralDark
            : theme.palette.neutralLighterAlt,
        };
      } else {
        customStyles.root = {
          backgroundColor: this.props.isDarkTheme
            ? theme.palette.neutralPrimary
            : theme.palette.white,
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  private _generateColumns(column: IColumn[]): IColumn[] {
    const deleteC: IColumn = {
      key: "delete",
      name: "",
      fieldName: "favorite",
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
    };
    const orderC: IColumn = {
      key: "order",
      name: "",
      fieldName: "order",
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
    };
    const newColumns = column.filter((col) => col.key !== "favorite");
    newColumns.push(deleteC);
    newColumns.push(orderC);
    let columns = newColumns.map((col) => {
      return {
        ...col,
        isResizable: true,
        onRender: this._columnRender,
      };
    });
    return columns;
  }

  private _compare() {
    const selectedRecord = this.state.displayItems.slice();
    const front = selectedRecord.splice(0, 1)[0];
    const compareTo = selectedRecord;
    front.onCompareWith(compareTo);
  }

  private _columnRender(
    item: Record,
    _index?: number | undefined,
    column?: IColumn | undefined
  ) {
    if (!column) {
      return;
    }

    if (column.key === "order") {
      return (
        <ChangeOrderBar
          showup={_index !== 0}
          showdown={_index !== this.state.displayItems.length - 1}
          existence
          onChangeOrder={(type: OrderType) =>
            this._onChangeOrder(type, _index!)
          }
        />
      );
    }

    return this._onColumnRender(item, _index, column);
  }

  private _onChangeOrder(type: OrderType, index: number) {
    const changeIndex = type + index;
    const items = this.state.displayItems.slice();
    const tempItem = items[index];
    items[index] = items[changeIndex];
    items[changeIndex] = tempItem;

    this.setState({
      displayItems: items,
    });
  }

  private _showRecordList() {
    this.setState((preState) => {
      return {
        showRecordList: !preState.showRecordList,
      };
    });
  }
}
