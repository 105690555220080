import React from "react";
import {
    ColumnValueType,
    NumberFormat,
    NumContrastPolicy,
    TableColumn,
    TableList,
} from "../../Controls";
import {
    CustFormArguments,
    CustomFormFieldMetrics,
    KEY_SEPARATOR,
} from "./CustFormDataInterface";
import { IDataItem } from "../../DataContract";
import { optimizeDecimalPlaces } from "../../Utils";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

// prettier-ignore
const TABLE_ERROR_RATE_COLUMNS: TableColumn[] = [
    { key: "TableName",         name: "TableName",          fieldName: "TableName",         valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 200,  isResizable: true,  distinctStr: true },
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "TrainingDocCount",  name: "TrainingDocCount",   fieldName: "TrainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "TrainingDocIndex",  name: "TrainingDocIndex",   fieldName: "TrainingDocIndex",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "ErrorRate",         name: "ErrorRate (%)",      fieldName: "ErrorRate",         valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

const AVG_ERROR_RATE_COLUMNS = TABLE_ERROR_RATE_COLUMNS.filter(
    (col) => col.key !== "TrainingDocIndex"
);

const FINAL_AVG_ERROR_RATE_COLUMNS = AVG_ERROR_RATE_COLUMNS.filter(
    (col) => !["TableName", "datasetFullName"].includes(col.key)
);

const enum ErrorRateKeys {
    TableName,
    DatasetName,
    TrainingDocCount,
    TrainingDocIndex,
}

interface CustomFormFieldMetricsWithTableError extends CustomFormFieldMetrics {
    TableCellErrorRate?: { [key: string]: number };
}

interface TableErrorMetric extends CustomFormFieldMetrics {
    TableName: string;
    ErrorRate: number;
}

interface IProps extends ICommonProps {
    customFormOverviewTableDeepLinkHandler?: (
        key: string,
        linkData: any
    ) => void;
}

interface IState extends ICommonState<CustomFormFieldMetricsWithTableError> {
    dataItems: IDataItem<CustomFormFieldMetricsWithTableError>[];
}

export class CustFormOverviewTableMetrics extends CommonView<
    IProps,
    IState,
    CustomFormFieldMetricsWithTableError
> {
    constructor(props: IProps) {
        super(props);

        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            dataItems: [],
        };
    }

    render(): React.ReactNode {
        const { records } = this.props;
        const tableErrorMetrics = this._prepareRenderData();
        const avgTraningCountMetrics = this._prepareAverageDataByKeys(
            tableErrorMetrics,
            [
                ErrorRateKeys.TableName,
                ErrorRateKeys.DatasetName,
                ErrorRateKeys.TrainingDocCount,
            ]
        );
        const finalAvgMetrics = this._prepareAverageDataByKeys(
            tableErrorMetrics,
            [ErrorRateKeys.TrainingDocCount]
        );

        if (
            (finalAvgMetrics && finalAvgMetrics.size > 0) ||
            (avgTraningCountMetrics && avgTraningCountMetrics.size > 0) ||
            (tableErrorMetrics && tableErrorMetrics.size > 0)
        ) {
            return (
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        <TableList<TableErrorMetric>
                            key={"finalAvgMetrics"}
                            evalDataCount={records.length}
                            evalData={finalAvgMetrics}
                            columns={FINAL_AVG_ERROR_RATE_COLUMNS}
                            downloadTableTitle="finalAvgMetrics"
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />

                        <TableList<TableErrorMetric>
                            key={"avgTraningCountMetrics"}
                            evalDataCount={records.length}
                            evalData={avgTraningCountMetrics}
                            columns={AVG_ERROR_RATE_COLUMNS}
                            downloadTableTitle="avgTraningCountMetrics"
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            onItemInvoked={this._onItemInvoked}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />

                        <TableList<TableErrorMetric>
                            key={"tableErrorMetrics"}
                            evalDataCount={records.length}
                            evalData={tableErrorMetrics}
                            columns={TABLE_ERROR_RATE_COLUMNS}
                            downloadTableTitle="tableErrorMetrics"
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            onItemInvoked={this._onItemInvoked}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                    </div>
                </FullScreen>
            );
        } else {
            return <NoDataTip>No Metrics Generated</NoDataTip>;
        }
    }

    queryMetricsResult(): void {
        this._queryMetricsResult("case_summary.json");
    }

    private _prepareRenderData(): Map<string, TableErrorMetric[]> {
        const { records } = this.props;
        const { dataItems } = this.state;
        const dataMap = new Map<string, TableErrorMetric[]>();

        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0
        ) {
            const details = records.flatMap((r) => r.getDetails());
            const compositeKeyArr = Array.from(
                new Set(
                    details.map((d) => {
                        const argu =
                            d.getRawProp<CustFormArguments>("arguments");
                        return `${d.dataset.name}${KEY_SEPARATOR}${argu.trainingDocCount}${KEY_SEPARATOR}${argu.trainingDocIndex}`;
                    })
                )
            );

            compositeKeyArr.forEach((compositeKey) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const datasetName = keys[0];
                const items = records.map((_, recordIndex) => {
                    const item = dataItems.find((item) => {
                        const argu =
                            item.recordDetail.getRawProp<CustFormArguments>(
                                "arguments"
                            );
                        const itemArgu = `${item.recordDetail.dataset.name}${KEY_SEPARATOR}${argu.trainingDocCount}${KEY_SEPARATOR}${argu.trainingDocIndex}`;
                        return (
                            item.recordIndex === recordIndex &&
                            itemArgu === compositeKey
                        );
                    });

                    return item;
                });

                const metrics = items.map((item) => {
                    let fieldMetrics:
                        | CustomFormFieldMetricsWithTableError
                        | undefined;
                    if (item !== undefined) {
                        fieldMetrics = item.metrics;
                        if (fieldMetrics !== null) {
                            const argu =
                                item.recordDetail.getRawProp<CustFormArguments>(
                                    "arguments"
                                );

                            fieldMetrics.TrainingDocCount =
                                argu.trainingDocCount;
                            fieldMetrics.TrainingDocIndex =
                                argu.trainingDocIndex;
                            fieldMetrics.datasetFullName =
                                item.recordDetail.dataset.name;
                            fieldMetrics.Accuracy = fieldMetrics.Accuracy
                                ? optimizeDecimalPlaces(
                                      fieldMetrics.Accuracy,
                                      4
                                  )
                                : NaN;
                            fieldMetrics.TrainingLatency =
                                fieldMetrics.TrainingLatency
                                    ? optimizeDecimalPlaces(
                                          fieldMetrics.TrainingLatency,
                                          3
                                      )
                                    : NaN;
                            fieldMetrics.InferenceLatency =
                                fieldMetrics.InferenceLatency
                                    ? optimizeDecimalPlaces(
                                          fieldMetrics.InferenceLatency,
                                          3
                                      )
                                    : NaN;
                            fieldMetrics.TableCellErrorRate =
                                item.metrics.TableCellErrorRate;
                        } else {
                            fieldMetrics = {
                                datasetFullName: datasetName,
                                TrainingDocCount: NaN,
                                TrainingDocIndex: NaN,
                                Accuracy: NaN,
                                TrainingLatency: NaN,
                                InferenceLatency: NaN,
                            };
                        }
                    } else {
                        fieldMetrics = {
                            datasetFullName: "",
                            TrainingDocCount: NaN,
                            TrainingDocIndex: NaN,
                            Accuracy: NaN,
                            TrainingLatency: NaN,
                            InferenceLatency: NaN,
                        };
                    }

                    return fieldMetrics;
                });

                metrics.forEach((metric, index) => {
                    if (metric.TableCellErrorRate) {
                        const errorRateArr = Object.entries(
                            metric.TableCellErrorRate
                        );

                        if (errorRateArr && errorRateArr.length > 0) {
                            errorRateArr.forEach(([tableName, rate]) => {
                                const key = `${tableName}${KEY_SEPARATOR}${compositeKey}`;
                                const tableMetricArr = dataMap.has(key)
                                    ? dataMap.get(key)!
                                    : Array.from(
                                          { length: records.length },
                                          () => {
                                              return {
                                                  datasetFullName: datasetName,
                                                  TableName: tableName,
                                                  Accuracy: NaN,
                                                  ErrorRate: NaN,
                                                  InferenceLatency: NaN,
                                                  TrainingDocCount: NaN,
                                                  TrainingDocIndex: NaN,
                                                  TrainingLatency: NaN,
                                              } as TableErrorMetric;
                                          }
                                      );

                                tableMetricArr[index].Accuracy =
                                    metric.Accuracy;
                                tableMetricArr[index].ErrorRate =
                                    optimizeDecimalPlaces(rate, 4);
                                tableMetricArr[index].InferenceLatency =
                                    metric.InferenceLatency;
                                tableMetricArr[index].TrainingDocCount =
                                    metric.TrainingDocCount;
                                tableMetricArr[index].TrainingDocIndex =
                                    metric.TrainingDocIndex;
                                tableMetricArr[index].TrainingLatency =
                                    metric.TrainingLatency;

                                dataMap.set(key, tableMetricArr);
                            });
                        }
                    }
                });
            });
        }

        return dataMap;
    }

    private _prepareAverageDataByKeys(
        dataMap: Map<string, TableErrorMetric[]>,
        keysArr: Array<ErrorRateKeys>
    ) {
        const avgMap = new Map<string, TableErrorMetric[]>();
        if (dataMap.size > 0) {
            const { records } = this.props;
            const allTableMetrics = Array.from(dataMap);
            const avgKeySet = new Set<string>();
            allTableMetrics.forEach(([compositeKey, _]) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const avgKey = keysArr.map((i) => keys[i]).join(KEY_SEPARATOR);
                !avgKeySet.has(avgKey) && avgKeySet.add(avgKey);
            });

            avgKeySet.forEach((targetAvgKey) => {
                const targetMetrics = allTableMetrics.filter(
                    ([compositeKey, _]) => {
                        const keys = compositeKey.split(KEY_SEPARATOR);
                        const avgKey = keysArr
                            .map((i) => keys[i])
                            .join(KEY_SEPARATOR);
                        return avgKey === targetAvgKey;
                    }
                );

                if (targetMetrics && targetMetrics.length > 0) {
                    const calcCountArr = new Array<number>(records.length).fill(
                        0
                    );
                    const totalErrorRateArr = new Array<number>(
                        records.length
                    ).fill(NaN);

                    targetMetrics.forEach(([_, metrics]) => {
                        metrics.forEach((meric, index) => {
                            if (!Number.isNaN(meric.ErrorRate)) {
                                totalErrorRateArr[index] = Number.isNaN(
                                    totalErrorRateArr[index]
                                )
                                    ? meric.ErrorRate
                                    : totalErrorRateArr[index] +
                                      meric.ErrorRate;

                                calcCountArr[index]++;
                            }
                        });
                    });

                    const defaultCompositeKey = targetMetrics[0][0];
                    const keys = defaultCompositeKey.split(KEY_SEPARATOR);
                    const avgTableMetrics = calcCountArr.map(
                        (calcCount, index) => {
                            const avgMetric = {
                                TableName:
                                    keys && keys.length > 0 ? keys[0] : "",
                                datasetFullName:
                                    keys && keys.length > 1 ? keys[1] : "",
                                TrainingDocCount:
                                    keys && keys.length > 2
                                        ? Number(keys[2])
                                        : NaN,
                                TrainingDocIndex:
                                    keys && keys.length > 3
                                        ? Number(keys[3])
                                        : NaN,
                                Accuracy: NaN,
                                ErrorRate: NaN,
                                InferenceLatency: NaN,
                                TrainingLatency: NaN,
                            };

                            if (
                                calcCount !== 0 &&
                                !Number.isNaN(totalErrorRateArr[index])
                            ) {
                                avgMetric.ErrorRate =
                                    totalErrorRateArr[index] / calcCount;
                            }

                            return avgMetric;
                        }
                    );

                    avgMap.set(targetAvgKey, avgTableMetrics);
                }
            });
        }

        return avgMap;
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { customFormOverviewTableDeepLinkHandler } = this.props;
        if (customFormOverviewTableDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as TableErrorMetric[];
                if (fieldMetricsArr.length > 0) {
                    const linkData: { [key: string]: string | undefined } = {};
                    const metric = fieldMetricsArr.find((m) => m);
                    if (metric) {
                        linkData.toSelectLanguage = metric.datasetFullName;
                        linkData.toSelectDocCount =
                            metric.TrainingDocCount.toString();
                        linkData.toSelectDocIndex =
                            metric.TrainingDocIndex.toString();
                        linkData.toSelectTableName = metric.TableName;

                        customFormOverviewTableDeepLinkHandler(
                            "custFormTableDetailsMetrics",
                            linkData
                        );
                    }
                }
            }
        }
    }
}
