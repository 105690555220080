import React from "react";
import _ from "lodash";
import { optimizeDecimalPlaces } from "../../Utils/Utils";
import { RecordDetail } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
    TableList,
    NumberFormat,
} from "../../Controls";
import {
    CustFormArguments,
    CustomFormFieldMetrics,
    KEY_SEPARATOR,
} from "./CustFormDataInterface";
import { Label } from "@fluentui/react";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

const enum CustFormMetricKeys {
    DatasetName,
    TrainingDocCount,
    TrainingDocIndex,
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: CustomFormFieldMetrics;
}

interface IState extends ICommonState<CustFormArguments> {
    dataItems: IDataItem[];
}

interface IProps extends ICommonProps {
    customFormImageViewDeepLinkHandler?: (key: string, linkData: any) => void;
}

// prettier-ignore
const CUSTOMFORM_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "TrainingDocCount",  name: "TrainingDocCount",   fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "TrainingDocIndex",  name: "TrainingDocIndex",   fieldName: "trainingDocIndex",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const AVG_CUSTOMFORM_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "TrainingDocCount",  name: "TrainingDocCount",   fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const FINAL_AVG_COLUMNS: TableColumn[] = [
    { key: "TrainingDocCount",  name: "TrainingDocCount",     fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true },
    { key: "Accuracy",          name: "Average Accuracy (%)", fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "_",                 name: "",                     fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

const DATA_INDEX_OF_DATASETS = 1;
export class CustFormOverview extends CommonView<
    IProps,
    IState,
    CustomFormFieldMetrics
> {
    constructor(props: IProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            dataItems: [],
        };
    }

    public render() {
        return <div className="overview">{this._renderAsTable()}</div>;
    }

    queryMetricsResult() {
        this._queryMetricsResult("case_summary.json");
    }

    private _renderAsTable() {
        const { records } = this.props;

        if (this.state.dataItems.length > 0) {
            const metricsData = this._prepareRenderData();
            const avgMetricsData = this._prepareAverageDataByKeys(metricsData, [
                CustFormMetricKeys.DatasetName,
                CustFormMetricKeys.TrainingDocCount,
            ]);
            const finalAvgMetricsData = this._prepareAverageData(metricsData, [
                CustFormMetricKeys.TrainingDocCount,
            ]);
            const nonNanCount = Array.from(finalAvgMetricsData.keys())
                .map((k) => k.split("|")[DATA_INDEX_OF_DATASETS])
                .join("/");

            return (
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        <Label>{`total number of Non-NaN dataset: ${nonNanCount}`}</Label>
                        <TableList<CustomFormFieldMetrics>
                            key={`CustomForm_0`}
                            evalDataCount={records.length}
                            evalData={finalAvgMetricsData}
                            columns={FINAL_AVG_COLUMNS}
                            tableTitle="Final Average Data"
                            downloadTableTitle="Final_Average_Data"
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                        <br />
                        <TableList<CustomFormFieldMetrics>
                            key={`CustomForm_1`}
                            evalDataCount={records.length}
                            evalData={avgMetricsData}
                            columns={AVG_CUSTOMFORM_COLUMNS}
                            tableTitle="Average Data"
                            downloadTableTitle="Average_Data"
                            onItemInvoked={this._onItemInvoked}
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                        <br />
                        <TableList<CustomFormFieldMetrics>
                            key={`CustomForm_2`}
                            evalDataCount={records.length}
                            evalData={metricsData}
                            columns={CUSTOMFORM_COLUMNS}
                            downloadTableTitle="CustomForm"
                            onItemInvoked={this._onItemInvoked}
                            disableFreezeHeader={true}
                            disableVirtualize={true}
                            hideHeader={true}
                            isWiderCell={true}
                            isDarkTheme={this.props.isDarkTheme}
                            verticalFill={false}
                        />
                    </div>
                </FullScreen>
            );
        } else {
            return <NoDataTip>No Metrics Generated</NoDataTip>;
        }
    }

    private _prepareRenderData(): Map<string, CustomFormFieldMetrics[]> {
        // find all datasets
        const { records } = this.props;
        const { dataItems } = this.state;
        const dataMap = new Map<string, CustomFormFieldMetrics[]>();

        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0
        ) {
            const details = records.flatMap((r) => r.getDetails());
            const compositeKeyArr = Array.from(
                new Set(
                    details.map((d) => {
                        const argu =
                            d.getRawProp<CustFormArguments>("arguments");
                        return `${d.dataset.displayName}${KEY_SEPARATOR}${argu.trainingDocCount}${KEY_SEPARATOR}${argu.trainingDocIndex}`;
                    })
                )
            );

            compositeKeyArr.forEach((compositeKey) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const datasetName = keys[0];
                const items = records.map((_, recordIndex) => {
                    const item = dataItems.find((item) => {
                        const argu =
                            item.recordDetail.getRawProp<CustFormArguments>(
                                "arguments"
                            );
                        const itemArgu = `${item.recordDetail.dataset.displayName}${KEY_SEPARATOR}${argu.trainingDocCount}${KEY_SEPARATOR}${argu.trainingDocIndex}`;
                        return (
                            item.recordIndex === recordIndex &&
                            itemArgu === compositeKey
                        );
                    });

                    return item;
                });

                const metrics = items.map((item) => {
                    let fieldMetrics: CustomFormFieldMetrics | undefined;
                    if (item !== undefined) {
                        fieldMetrics = item.metrics;
                        if (fieldMetrics !== null) {
                            const argu =
                                item.recordDetail.getRawProp<CustFormArguments>(
                                    "arguments"
                                );

                            fieldMetrics.TrainingDocCount =
                                argu.trainingDocCount;
                            fieldMetrics.TrainingDocIndex =
                                argu.trainingDocIndex;
                            fieldMetrics.datasetFullName =
                                item.recordDetail.dataset.name;
                            fieldMetrics.Accuracy = fieldMetrics.Accuracy
                                ? optimizeDecimalPlaces(
                                      fieldMetrics.Accuracy,
                                      4
                                  )
                                : NaN;
                            fieldMetrics.TrainingLatency =
                                fieldMetrics.TrainingLatency
                                    ? optimizeDecimalPlaces(
                                          fieldMetrics.TrainingLatency,
                                          3
                                      )
                                    : NaN;
                            fieldMetrics.InferenceLatency =
                                fieldMetrics.InferenceLatency
                                    ? optimizeDecimalPlaces(
                                          fieldMetrics.InferenceLatency,
                                          3
                                      )
                                    : NaN;
                        } else {
                            fieldMetrics = {
                                datasetFullName: datasetName,
                                TrainingDocCount: NaN,
                                TrainingDocIndex: NaN,
                                Accuracy: NaN,
                                TrainingLatency: NaN,
                                InferenceLatency: NaN,
                            };
                        }
                    } else {
                        fieldMetrics = {
                            datasetFullName: "",
                            TrainingDocCount: NaN,
                            TrainingDocIndex: NaN,
                            Accuracy: NaN,
                            TrainingLatency: NaN,
                            InferenceLatency: NaN,
                        };
                    }

                    return fieldMetrics;
                });

                dataMap.set(compositeKey, metrics);
            });
        }

        return dataMap;
    }

    private _prepareAverageDataByKeys(
        dataMap: Map<string, CustomFormFieldMetrics[]>,
        keysArr: Array<CustFormMetricKeys>
    ): Map<string, CustomFormFieldMetrics[]> {
        const avgMap = new Map<string, CustomFormFieldMetrics[]>();
        if (dataMap.size > 0) {
            const { records } = this.props;
            const allMetrics = Array.from(dataMap);
            const avgKeySet = new Set<string>();
            allMetrics.forEach(([compositeKey, _]) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const avgKey = keysArr.map((i) => keys[i]).join(KEY_SEPARATOR);
                !avgKeySet.has(avgKey) && avgKeySet.add(avgKey);
            });

            avgKeySet.forEach((targetAvgKey) => {
                const targetMetrics = allMetrics.filter(([compositeKey, _]) => {
                    const keys = compositeKey.split(KEY_SEPARATOR);
                    const avgKey = keysArr
                        .map((i) => keys[i])
                        .join(KEY_SEPARATOR);
                    return avgKey === targetAvgKey;
                });

                if (targetMetrics && targetMetrics.length > 0) {
                    const defaultCompositeKey = targetMetrics[0][0];
                    const keys = defaultCompositeKey.split(KEY_SEPARATOR);

                    const calcCountArr = new Array<number>(records.length).fill(
                        0
                    );

                    const calcMetricsArr = Array.from(
                        { length: records.length },
                        () => {
                            return {
                                datasetFullName:
                                    keys && keys.length > 0 ? keys[0] : "",
                                TrainingDocCount:
                                    keys && keys.length > 1
                                        ? Number(keys[1])
                                        : NaN,
                                TrainingDocIndex:
                                    keys && keys.length > 2
                                        ? Number(keys[2])
                                        : NaN,
                                Accuracy: NaN,
                                TrainingLatency: NaN,
                                InferenceLatency: NaN,
                            } as CustomFormFieldMetrics;
                        }
                    );

                    targetMetrics.forEach(([_, metrics]) => {
                        metrics.forEach((metric, index) => {
                            if (metric) {
                                calcMetricsArr[index].Accuracy = Number.isNaN(
                                    calcMetricsArr[index].Accuracy
                                )
                                    ? metric.Accuracy
                                    : calcMetricsArr[index].Accuracy +
                                      metric.Accuracy;

                                calcMetricsArr[index].TrainingLatency =
                                    Number.isNaN(
                                        calcMetricsArr[index].TrainingLatency
                                    )
                                        ? metric.TrainingLatency
                                        : calcMetricsArr[index]
                                              .TrainingLatency +
                                          metric.TrainingLatency;

                                calcMetricsArr[index].InferenceLatency =
                                    Number.isNaN(
                                        calcMetricsArr[index].InferenceLatency
                                    )
                                        ? metric.InferenceLatency
                                        : calcMetricsArr[index]
                                              .InferenceLatency +
                                          metric.InferenceLatency;

                                calcCountArr[index]++;
                            }
                        });
                    });

                    const avgMetrics = calcCountArr.map((calcCount, index) => {
                        const avgMetric = calcMetricsArr[index];
                        if (calcCount !== 0) {
                            if (!Number.isNaN(avgMetric.Accuracy)) {
                                avgMetric.Accuracy = optimizeDecimalPlaces(
                                    avgMetric.Accuracy / calcCount,
                                    4
                                );
                            }

                            if (!Number.isNaN(avgMetric.TrainingLatency)) {
                                avgMetric.TrainingLatency =
                                    optimizeDecimalPlaces(
                                        avgMetric.TrainingLatency / calcCount,
                                        3
                                    );
                            }

                            if (!Number.isNaN(avgMetric.InferenceLatency)) {
                                avgMetric.InferenceLatency =
                                    optimizeDecimalPlaces(
                                        avgMetric.InferenceLatency / calcCount,
                                        3
                                    );
                            }
                        }

                        return avgMetric;
                    });

                    avgMap.set(targetAvgKey, avgMetrics);
                }
            });
        }

        return avgMap;
    }

    private _prepareAverageData(
        dataMap: Map<string, CustomFormFieldMetrics[]>,
        keysArr: Array<CustFormMetricKeys>
    ): Map<string, CustomFormFieldMetrics[]> {
        const DATA_INDEX_OF_ACCURACY = 1;
        const avgMap = new Map<string, CustomFormFieldMetrics[]>();
        if (dataMap.size > 0) {
            const { records } = this.props;
            const allMetrics = Array.from(dataMap);
            const avgKeySet = new Set<string>();
            allMetrics.forEach(([compositeKey, _]) => {
                const keys = compositeKey.split(KEY_SEPARATOR);
                const avgKey = keysArr.map((i) => keys[i]).join(KEY_SEPARATOR);
                !avgKeySet.has(avgKey) && avgKeySet.add(avgKey);
            });

            avgKeySet.forEach((targetAvgKey) => {
                const targetMetrics = allMetrics.filter(([compositeKey, _]) => {
                    const keys = compositeKey.split(KEY_SEPARATOR);
                    const avgKey = keysArr
                        .map((i) => keys[i])
                        .join(KEY_SEPARATOR);
                    return avgKey === targetAvgKey;
                });

                if (targetMetrics && targetMetrics.length > 0) {
                    const metrics = targetMetrics.map((v) => v[1]);
                    const countArr: number[] = [];
                    const avgMetrics = records.map((__, index) => {
                        const metrs = metrics.map((m) => [
                            m[index].TrainingDocCount,
                            m[index].Accuracy,
                        ]);
                        countArr.push(metrs.length);
                        const filteredMetrs = metrs.filter(
                            (m) => !isNaN(m[DATA_INDEX_OF_ACCURACY])
                        );
                        if (filteredMetrs && filteredMetrs.length > 0) {
                            const accuracyArr = filteredMetrs.map(
                                (m) => m[DATA_INDEX_OF_ACCURACY]
                            );
                            const accuracySum = _.sum(accuracyArr);
                            return {
                                TrainingDocCount: Number(targetAvgKey),
                                Accuracy: accuracySum / metrs.length,
                            } as CustomFormFieldMetrics;
                        } else {
                            return {
                                TrainingDocCount: Number(targetAvgKey),
                                Accuracy: NaN,
                            } as CustomFormFieldMetrics;
                        }
                    });

                    const compositeKey = `${targetAvgKey}|${countArr.join(
                        "-"
                    )}`;

                    avgMap.set(compositeKey, avgMetrics);
                }
            });
        }

        return avgMap;
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { customFormImageViewDeepLinkHandler } = this.props;
        if (customFormImageViewDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as CustomFormFieldMetrics[];
                if (fieldMetricsArr?.length > 0) {
                    let linkData: { [key: string]: string | undefined } = {};
                    const metric = fieldMetricsArr.find((m) => m);
                    if (metric) {
                        linkData.toSelectLanguage =
                            metric.datasetFullName.split(":")[1];
                        linkData.toSelectDocCount =
                            metric.TrainingDocCount.toString();
                        linkData.toSelectDocIndex =
                            metric.TrainingDocIndex.toString();

                        customFormImageViewDeepLinkHandler(
                            "overviewCustFormDetailMetrics",
                            linkData
                        );
                    }
                }
            }
        }
    }
}
