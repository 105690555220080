import React from "react";
export class HtmlConvertorPage extends React.Component {
    public render() {
        return (
            <iframe
                id="ifr"
                name="ifr"
                title="data format service"
                src="https://data-format-srv.azurewebsites.net/"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    top: 85,
                    left: 0,
                    margin: "auto",
                    border: "none",
                    background: "#ffffff",
                    zIndex: 99,
                }}
            />
        );
    }

    public componentDidMount(): void {
        const iframe = document.getElementById("ifr");
        if (iframe) {
            const conatiner = iframe?.parentNode?.parentNode?.parentNode;
            if (conatiner) {
                (conatiner as HTMLDivElement).style.overflowY = "hidden";
            }
        }

        window.addEventListener(
            "message",
            this.receiveMessageFromIframePage,
            false
        );
    }

    public componentWillUnmount(): void {
        window.removeEventListener(
            "message",
            this.receiveMessageFromIframePage,
            false
        );
    }

    private receiveMessageFromIframePage(event: any) {
        const iframe = document.getElementById("ifr");
        if (event && event.data && iframe) {
            const iframeElement = iframe as HTMLIFrameElement;
            iframeElement.width = `${event.data.width}px`;
            iframeElement.height = `${event.data.height}px`;
        }
    }
}
