import React from "react";
import { Modal } from "@fluentui/react";

import { CommonView, ICommonState } from "../Common/CommonMetrics";

import { IMetricProps } from "../Common/MetricView";
import {
    ColumnValueType,
    ImageVisualizer,
    ITableConfigs,
    MetricsType,
    OcrPolygon,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import {
    DatasetSet,
    IMetrics,
    IMetricUnit,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import { MathDataset } from "../../DataContract/MathDataset";
import { Consumer } from "../../Layout";
import { METRICS_LVL_OPTIONS } from "../../Pages/Scenarios/OcrMathPage";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import _ from "lodash";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: any;
    imageList?: string[];
}

interface IMathState extends ICommonState<any> {
    // dataItems: IDataItem[];
    selectLanguage?: string;
    inspectImgUrl: string;
    selectImageId?: string;
    clickNumber?: number;
    imageVisItems?: any[];
    mathByImgColumns: TableColumn[];
}

interface MathFieldMetrics {
    [key: string]: number | string;
}

export interface MathMetric extends IMetricUnit {
    entity_name: string;
    debug_info: string[];

    textline_id: string;
    insert_error: number;
    delete_error: number;
    subs_error: number;
    complexity: number;
    confidence: string;
    ref: string;
    hyp: string;
    distance: string;
    [key: string]: string | number | string[];
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<MathMetric[]>;

interface IProps extends IMetricProps {
    storageVersion?: string;
}

export class OcrMathImageView extends CommonView<IProps, IMathState, {}> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            dataItems: [],
            selectLanguage: this._getLanguageList(true)[0],
            inspectImgUrl: "",
            mathByImgColumns: [],
            level: "lv2",
            matchDatasetVersion: true,
        };
    }

    render() {
        return <>{this._renderData()}</>;
    }

    private _columnsUpdate(columnNames: string[]) {
        const columns = columnNames.map((col) => {
            let valueType = ColumnValueType.Number;
            let distinctStr = false;
            let isKey = false;
            if (col === "id") {
                valueType = ColumnValueType.String;
                distinctStr = true;
                isKey = true;
            }
            return {
                key: col.replaceAll("-", "_"),
                name: col,
                minWidth: 100,
                maxWidth: 180,
                fieldName: col.replaceAll("-", "_"),
                isResizable: true,
                valueType: valueType,
                distinctStr: distinctStr,
                maxDecimalPlaces: 3,
                isKey: isKey,
            };
        });

        return columns.filter((column: any) => column.name !== "category");
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrMath}_${Typename.GeneralMetrics}
                _ByImage`,
                levels: METRICS_LVL_OPTIONS,
                selectedLevel: "lv2",
            })
        );
    }

    public componentWillUnmount(): void {
        store.dispatch(
            updateStateAction({
                levels: undefined,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IMathState) {
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            this.state.level !== prevState.level
        ) {
            this.queryMetricsResult();
        }
        if (!_.isEqual(this.state.dataItems, prevState.dataItems)) {
            const columns = this._columnsUpdate(
                Array.from(
                    new Set(
                        this.state.dataItems
                            .map((item: any) => {
                                const header =
                                    item.length !== 0
                                        ? (item.metrics.header as string[])
                                        : [];
                                return header ? header : [];
                            })
                            .flatMap((col: any) => col)
                    )
                )
            );
            this.setState({
                mathByImgColumns: columns,
            });
            store.dispatch(
                updateStateAction({
                    columns: columns,
                    selectedItems: columns.map((column) => column.name),
                })
            );
        }
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.setState(
                {
                    selectLanguage: this._getLanguageList(
                        this.state.matchDatasetVersion!
                    )[0],
                },
                () => {
                    this.queryMetricsResult();
                }
            );
        }
    }

    private _renderData() {
        const { records } = this.props;
        const experiments = records.map((record) => record.name);
        const {
            selectImageId,
            imageVisItems,
            selectedColumns,
            mathByImgColumns,
        } = this.state;
        if (this.state.dataItems.length > 0) {
            const [data] = this._prepareRenderNewData();
            let clickNumber: number = 0;

            const imageItemsForVis = imageVisItems?.map(
                (imageVisItem) => imageVisItem[1]
            );
            const evalData: any = data;
            const showColumns: TableColumn[] = mathByImgColumns.filter(
                (value: any) =>
                    selectedColumns?.findIndex((col) => col === value.name) !==
                    -1
            );
            return (
                <>
                    <Modal
                        styles={{
                            main: {
                                width: "100%!important",
                                height: "100%!important",
                            },
                        }}
                        isOpen={!!selectImageId}
                        onDismiss={() =>
                            this.setState({ selectImageId: undefined })
                        }
                        containerClassName="modal"
                    >
                        {!!selectImageId && (
                            <ImageVisualizer
                                metricsType={MetricsType.mathOcrMetrics}
                                experiments={experiments}
                                fileId={selectImageId.substring(
                                    0,
                                    selectImageId.lastIndexOf(".")
                                )}
                                evalList={imageItemsForVis}
                                onLoadVisualizer={(imageId: any) => {
                                    if (imageId) {
                                        const imageIds = Object.keys(evalData);
                                        const id = imageIds.find((id) =>
                                            id.startsWith(imageId)
                                        );
                                        if (
                                            id &&
                                            evalData[id] &&
                                            evalData[id].length > 0
                                        ) {
                                            return evalData[id][0]!.imageUrl;
                                        } else {
                                            return "";
                                        }
                                    } else {
                                        return "";
                                    }
                                }}
                                onRequestPolygons={(imageId?: string) =>
                                    this._requestTextlinePolygons()
                                }
                                onRequestMetrics={(imageId?: string) =>
                                    this._requestTextlineMetrics()
                                }
                                onDismiss={(imageId: any) =>
                                    this.onDismiss(imageId)
                                }
                                clickNumber={this.state.clickNumber}
                            />
                        )}
                    </Modal>
                    <FullScreen>
                        <Consumer>
                            {(value) => {
                                return (
                                    <TableList<MathFieldMetrics>
                                        key={this.state.selectLanguage}
                                        columns={showColumns}
                                        evalData={data}
                                        evalDataCount={records.length}
                                        downloadTableTitle={
                                            this.state.selectLanguage
                                        }
                                        renderTableHeader={
                                            this._renderTableHeader
                                        }
                                        isWiderCell={true}
                                        onItemInvoked={(
                                            item: any,
                                            index: any
                                        ) => {
                                            clickNumber = index;
                                            const [imageId] = item;

                                            this.setState({
                                                selectImageId: imageId,
                                                clickNumber: clickNumber,
                                            });
                                        }}
                                        getDisplayEvalData={(
                                            displayItems: any[]
                                        ) => {
                                            this.getDisplayEvalData(
                                                displayItems
                                            );
                                        }}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    </FullScreen>
                </>
            );
        }
        return <></>;
    }

    queryMetricsResult(): void {
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;
        this._queryMetricsResult(`details${lvl_str}.json`);
    }

    protected _queryMetricsResult(
        metricsFileName: string,
        selectLanguage?: string
    ) {
        const { matchDatasetVersion } = this.state;
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        // find one dataset detail per dataset
        const detailsList = this.props.records.map((r) => {
            if (!selectLanguage) {
                return r
                    .getDetails()
                    .filter((d) => d !== undefined) as RecordDetail[];
            } else {
                return datasets
                    .map((d) =>
                        r
                            .getDetails()
                            .find((rd) =>
                                selectLanguage
                                    ? rd.dataset.equals(d) &&
                                      (matchDatasetVersion
                                          ? rd.dataset.displayFullName ===
                                            selectLanguage
                                          : rd.dataset.displayName ===
                                            selectLanguage)
                                    : rd.dataset.equals(d)
                            )
                    )
                    .filter((d) => d !== undefined) as RecordDetail[];
            }
        });

        Promise.all(
            detailsList.flatMap((details, index) => {
                return details.map((detail) => {
                    const mathDataset = new MathDataset(
                        detail.dataset.name,
                        detail.dataset.version,
                        detail.dataset.language,
                        detail.dataset.categories
                    );
                    return Promise.all([
                        detail.fetchMetricsWithRecordDetail<any>(
                            metricsFileName
                        ),
                        mathDataset.fetchImageListByCategory(""),
                    ])
                        .then(([metrics, imageList]) => {
                            return {
                                recordIndex: index,
                                recordDetail: metrics[0],
                                metrics: metrics[1],
                                imageList: imageList,
                            };
                        })
                        .catch((_error) => {
                            return [];
                        });
                });
            })
        ).then((dataItems) => {
            this.setState({
                dataItems: dataItems,
            });
        });
    }

    private _prepareRenderNewData = () => {
        const { matchDatasetVersion, selectLanguage } = this.state;

        const items = this.props.records.map((_, recordIndex) => {
            const item = this.state.dataItems.find((item: IDataItem) => {
                return (
                    item.recordIndex === recordIndex &&
                    selectLanguage ===
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName
                            : item.recordDetail.dataset.displayName)
                );
            });
            return item;
        });

        const ids = Array.from(
            new Set(
                items.flatMap((item) => {
                    const rows = item?.metrics.rows as string[];
                    return rows ? rows.map((row) => row[0]) : [];
                })
            )
        );

        const map: any = {};
        ids.forEach((id) => {
            // retrieve field data
            const metricsItems = items.map((item) => {
                let fieldMetrics: MathFieldMetrics = {};
                const rows = item?.metrics.rows as string[][];
                const header = item?.metrics.header as string[];
                if (rows && rows.length > 0) {
                    const row = rows.filter((row) => row[0] === id);
                    if (row.length > 0) {
                        for (const key of header) {
                            const keyIndex = header.indexOf(key);
                            fieldMetrics[key.replaceAll("-", "_")] =
                                this._formaterNumber(row[0][keyIndex]);
                        }
                        const mathDataset = new MathDataset(
                            item!.recordDetail.dataset.name,
                            item!.recordDetail.dataset.version,
                            item!.recordDetail.dataset.language,
                            item!.recordDetail.dataset.categories
                        );
                        let imageName =
                            item?.imageList?.find(
                                (image: string) =>
                                    image.toLowerCase() ===
                                    fieldMetrics.id.toString().toLowerCase()
                            ) ?? fieldMetrics.id.toString();
                        if (fieldMetrics["category"] !== "img") {
                            imageName = `${fieldMetrics["category"]}/${imageName}`;
                        }
                        fieldMetrics.imageUrl =
                            mathDataset?.getImageUrl(imageName) || "";
                    }
                }

                return fieldMetrics;
            });
            map[id] = metricsItems;
        });

        return [map, this.state.mathByImgColumns];
    };

    private _formaterNumber(number: any) {
        if (Number(number) === -1) {
            return NaN;
        }
        return number;
    }
    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this._onQueryButtonClicked(selectLanguage);
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const { matchDatasetVersion, selectLanguage } = this.state;
        const languages = this._getLanguageList(matchDatasetVersion!);
        const selectLangKey = this._loadSelectedLanguage(
            selectLanguage,
            languages
        );

        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                styles: { root: { width: "auto" } },
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _requestTextlinePolygons(): Promise<OcrPolygons[]> {
        const { selectLanguage } = this.state;
        const details = this.filterRecordDetails(selectLanguage!, true);
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;

        return Promise.all(
            details.map((detail) => {
                const filename = `alignment_polygons${lvl_str}.json`;
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(): Promise<OcrMetrics[]> {
        const { selectLanguage } = this.state;
        const details = this.filterRecordDetails(selectLanguage!, true);
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;

        return Promise.all(
            details.map((detail) => {
                const filename = `formula_metrics${lvl_str}.json`;
                return detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                    `${filename}`
                );
            })
        );
    }

    private onDismiss = (imageId: any) => {
        this.setState({
            selectImageId: imageId,
        });
    };

    private getDisplayEvalData = (displayItems: any[]) => {
        this.setState({
            imageVisItems: displayItems,
        });
    };
}
