import React from "react";
import { Modal } from "@fluentui/react";
import { ColumnValueType, TableColumn, TableList } from "../../Controls";
import {
    IMetrics,
    IMetricUnit,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { Consumer } from "../../Layout";
import { OcrGeneralStateInSession } from "./GeneralMetrics";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import AuthImage from "../../AuthComponent/AuthImage";

export interface TextlineCharMetric extends IMetricUnit {
    textline_id: string;
    insert_error: number;
    delete_error: number;
    subs_error: number;
    total_error: number;
    ref: string;
    hyp: string;
    eval: string;
    textline_url: string;
    entity_name: string;
    debug_info: string[];
    cover_ratio: number;
}

interface IProps extends IMetricProps {}

interface IState extends IMetricState<TextlineCharMetric> {
    categoryList: string[];
    selectCategory?: string;
    selectLanguage?: string;
    selectTextLine?: string;
    selectTextLineUrl?: string;
}

// prettier-ignore
export const textlineColumns: TableColumn[] = [
    { key: "textline_id",  name: "TextlineID",  fieldName: "textline_id",  isKey: true, valueType: ColumnValueType.String,  minWidth: 100, maxWidth: 400, },
    { key: "insert_error", name: "InsertError", fieldName: "insert_error", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 200, filterName: "Show insert error (>0) lines", },
    { key: "delete_error", name: "DeleteError", fieldName: "delete_error", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 200, filterName: "Show delete error (>0) lines", },
    { key: "subs_error",   name: "SubError",    fieldName: "subs_error",   isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 200, filterName: "Show subs error (>0) lines", },
    { key: "total_error",  name: "TotalError",  fieldName: "total_error",  isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 200, },
    { key: "ref",          name: "REF",         fieldName: "ref",          isKey: false, valueType: ColumnValueType.String, minWidth: 100, maxWidth: 200, },
    { key: "hyp",          name: "HYP",         fieldName: "hyp",          isKey: false, valueType: ColumnValueType.String, minWidth: 100, maxWidth: 200, },
    { key: "eval",         name: "Eval",        fieldName: "eval",         isKey: false, valueType: ColumnValueType.String, minWidth: 100, maxWidth: 200, },
    { key: "tags",         name: "Tags",        fieldName: "tags",         isKey: false, valueType: ColumnValueType.String, minWidth: 100, maxWidth: 200, filterable: true, type: ColumnValueType.List },
];

export class TextlineMetricView extends MetricsView<
    IProps,
    IState,
    TextlineCharMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        const initialState = {
            categoryList: [],
            evalData: {},
            matchDatasetVersion: true,
        };

        const stateKey = this.getSessionStateKey(
            props.records,
            Workspaces.Ocr,
            Typename.GeneralMetrics
        );

        const stateStr = sessionStorage.getItem(stateKey);
        if (stateStr) {
            const sessionState = JSON.parse(
                stateStr
            ) as OcrGeneralStateInSession;
            this.state = {
                ...initialState,
                selectLanguage: sessionState.selectLanguage
                    ? sessionState.selectLanguage
                    : undefined,
                selectCategory: sessionState.selectCategory
                    ? sessionState.selectCategory
                    : undefined,
            };
        } else {
            this.state = initialState;
        }
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: textlineColumns,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.GeneralMetrics
                    }`
                }_ByTextline`,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    public render() {
        const { evalData, selectTextLine, selectTextLineUrl, selectedColumns } =
            this.state;
        const columns: TableColumn[] = textlineColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const tableKey = `${this.state.selectLanguage}_${this.state.selectCategory}`;
        return (
            <>
                <Modal
                    isOpen={!!selectTextLine}
                    onDismiss={() =>
                        this.setState({
                            selectTextLine: undefined,
                        })
                    }
                    containerClassName="modal"
                >
                    <div className="header">
                        <div className="modal__title">
                            <Consumer>
                                {(value) => {
                                    return (
                                        <span
                                            style={{
                                                color: value
                                                    ? "white"
                                                    : "black",
                                            }}
                                        >
                                            {selectTextLine}
                                        </span>
                                    );
                                }}
                            </Consumer>
                        </div>
                        <div className="modal__imagecontainer">
                            <AuthImage
                                url={selectTextLineUrl}
                                className="modal__image"
                            />
                        </div>
                    </div>
                </Modal>
                <FullScreen>
                    <TableList<TextlineCharMetric>
                        key={tableKey}
                        evalDataCount={this.props.records.length}
                        evalData={evalData}
                        columns={columns}
                        downloadTableTitle={tableKey}
                        renderTableHeader={this._renderTableHeader}
                        isFullFilterMenu={true}
                        onItemInvoked={(item) => {
                            const textlineId = item[0];
                            const data = item[1];
                            const textlineUrl = data[0]?.textline_url ?? "";
                            this.setState({
                                selectTextLine: textlineId,
                                selectTextLineUrl: textlineUrl,
                            });
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </FullScreen>
            </>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<TextlineCharMetric>> {
        return recordDetail
            .fetchMetricsWithCamelCasing<IMetrics<TextlineCharMetric[]>>(
                metricName
            )
            .then((records) => {
                let items: IMetrics<TextlineCharMetric> = {};
                for (const textlines of Object.values(records)) {
                    for (const textline of textlines) {
                        items[textline.textline_id] = textline;
                        textline.total_error =
                            textline.insert_error +
                            textline.delete_error +
                            textline.subs_error;
                    }
                }
                return items;
            });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage, selectCategory } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            const language = selectLanguage ?? languageList[0];
            this._onOptionsChanged(
                {
                    selectLanguage: language,
                    selectCategory: selectCategory,
                },
                matchDatasetVersion,
                500
            );
        }
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { matchDatasetVersion, selectCategory, selectLanguage } =
            this.state;

        if (records.length > 0 && selectCategory && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion
            );

            this.showEvaluationResult(
                details,
                `${selectCategory}/textline_word_metrics.json`
            );
        } else {
            this.setState({ evalData: {} });
        }
    };

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion?: boolean,
        timeout: number = 1000
    ) {
        let selectCategory =
            "selectCategory" in newOptions
                ? newOptions["selectCategory"]
                : this.state.selectCategory;
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        const categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );
        selectCategory =
            categories.indexOf(selectCategory!) >= 0
                ? selectCategory
                : categories[0];
        setTimeout(() => {
            if (
                selectCategory === this.state.selectCategory &&
                selectLanguage === this.state.selectLanguage
            ) {
                this._onQueryButtonClicked();
            }
        }, timeout);
        this.setState(
            {
                selectCategory: selectCategory,
                selectLanguage: selectLanguage,
                categoryList: categories,
            },
            () => {
                const { records } = this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Ocr,
                    Typename.GeneralMetrics
                );
                const stateInSession: OcrGeneralStateInSession = {
                    selectLanguage: selectLanguage,
                    selectCategory: selectCategory,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);
            }
        );
    }

    private _renderTableHeader(): JSX.Element {
        const {
            selectLanguage,
            selectCategory,
            categoryList,
            matchDatasetVersion,
        } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion);

        let textlineOptions: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (selectLanguage) => {
                    this._onOptionsChanged(
                        {
                            selectLanguage: selectLanguage?.text,
                        },
                        matchDatasetVersion,
                        1000
                    );
                },
            },
            {
                key: "categories",
                text: "Category:",
                options: categoryList,
                selectedKey: selectCategory,
                onChange: (selectCategory) => {
                    this._onOptionsChanged(
                        {
                            selectCategory: selectCategory?.text,
                        },
                        matchDatasetVersion,
                        1000
                    );
                },
            },
        ];

        return (
            <TableHeader
                options={textlineOptions}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }
}
