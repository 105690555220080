import React from "react";
import {
    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,

    //Others
    Label,
} from "@fluentui/react";

import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { getColorByIndex, trimAll } from "../../Utils";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    IGroupedBarChartData,
    VerticalBarChart,
} from "../../Controls/D3/VerticalBarChart";

import {
    CHECKBOX_METRICS_DEFINITIONS,
    CheckboxMetricDefinition,
} from "./OcrCheckboxDataInterface";
import _ from "lodash";
import { OverviewTable } from "../../Controls/OverviewTable";
import { ColumnValueType, TableColumn } from "../../Controls/TableList";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

const COCO_SCORE_KEYS: (keyof COCOScore)[] = [
    "mAP",
    "mAR",
    "mAF1",
    "square",
    "round",
    "underline",
    "grid",
    "bracket",
    "toggle",
    "circlable",
    "other",
];

interface COCOScore {
    mAP: number;
    mAR: number;
    mAF1: number;
    square: number;
    round: number;
    underline: number;
    grid: number;
    bracket: number;
    toggle: number;
    circlable: number;
    other: number;
}

interface CheckboxDetection {
    [iou: string]: COCOScore;
}

interface CheckboxMetrics {
    CheckboxCOCOMetrics: CheckboxDetection;
    // new data
    header: string[];
    rows: any[];
}

interface CheckBoxFieldMetrics {
    [key: string]: string | number;
}

interface IKvpDataItem {
    data?: IDataItem;
    fieldMetrics: CheckBoxFieldMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: CheckboxMetrics;
}

interface IState extends ICommonState<CheckboxMetrics> {
    dataItems: IDataItem[];
    columns?: TableColumn[];
}

interface IProps extends ICommonProps {
    storageVersion?: string;
}

export class OcrCheckboxOverview extends CommonView<
    IProps,
    IState,
    CheckboxMetrics
> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            dataItems: [],
            matchDatasetVersion: true,
        };
    }

    public render() {
        const { storageVersion = "" } = this.props;
        return (
            <div className="overview">
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {storageVersion === "v2"
                            ? this._renderAsTable()
                            : this._renderAsChart()}
                    </div>
                </FullScreen>
            </div>
        );
    }

    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            this.state.columns?.filter((col) => col.key !== "childrenStat") ||
                [],
            `OcrCheckboxOverviewMetrics`
        );
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ): void {
        super.componentDidUpdate(prevProps, prevState);

        if (!_.isEqual(prevState.dataItems, this.state.dataItems)) {
            if (
                this.state.dataItems.length > 0 &&
                this.props.storageVersion === "v2"
            ) {
                const item = this.state.dataItems.filter(
                    (item) =>
                        !_.isEmpty(item.metrics) &&
                        item.metrics.header &&
                        item.metrics.header.length > 0
                );
                const columnNames = item[0].metrics.header;
                const columns = this._columnsUpdate(columnNames || []);
                if (columns.length > 0) {
                    this.setState({
                        columns: columns,
                    });

                    store.dispatch(
                        updateStateAction({
                            columns: columns,
                            saveKey: `${Workspaces.OcrCheckbox}_${Typename.CheckboxGeneralMetrics}_Overview`,
                        })
                    );
                }
            }
        }
    }

    private _columnsUpdate(columnNames: string[]) {
        const columns = columnNames.map((col) => {
            let valueType = ColumnValueType.Number;
            let distinctStr = false;
            if (col === "class") {
                valueType = ColumnValueType.String;
                distinctStr = true;
            }
            return {
                key: col,
                name: col,
                minWidth: 100,
                maxWidth: 180,
                fieldName: col,
                isResizable: true,
                valueType: valueType,
                distinctStr: distinctStr,
                maxDecimalPlaces: 3,
            };
        });

        return columns;
    }

    private _renderAsChart() {
        if (this.state.dataItems.length > 0) {
            let data = this._prepareRenderData();

            return (
                <>
                    {CHECKBOX_METRICS_DEFINITIONS.map(
                        (definition, definitionIndex) => {
                            return (
                                <div key={`defition_${definitionIndex}`}>
                                    <Label>
                                        <h2>{definition.displayName}</h2>
                                    </Label>
                                    {data.map(
                                        ([datasetname, metricView], index) => {
                                            return (
                                                <div key={index}>
                                                    <Label>{datasetname}</Label>
                                                    {this._renderSingleChart(
                                                        trimAll(
                                                            definition.displayName +
                                                                index
                                                        ),
                                                        definition,
                                                        metricView
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            );
                        }
                    )}
                </>
            );
        }
    }

    private _renderSingleChart(
        id: string,
        definition: CheckboxMetricDefinition,
        metrics: any[]
    ) {
        let subCharts: JSX.Element[] = [];

        metrics.forEach(([groupName, metricData], index) => {
            if (metricData !== undefined) {
                const chartData: IGroupedBarChartData[] = [];
                Object.entries(metricData.checkbox).forEach(
                    ([prop, values]) => {
                        if ((values as any[]).find((value) => !isNaN(value))) {
                            const checkbox = (values as any[]).map(
                                (value, recordIndex) => {
                                    return {
                                        key: `group_${recordIndex}`,
                                        data: value
                                            ? (value as number)
                                            : (0 as number),
                                        color: getColorByIndex(recordIndex),
                                        legend: this.props.records[recordIndex]
                                            .name,
                                    };
                                }
                            );
                            chartData.push({
                                name: prop,
                                series: checkbox,
                            });
                        }
                    }
                );

                const subChart = (
                    <DocumentCardDetails
                        key={`checkbox_${definition.name}_${groupName}`}
                    >
                        <DocumentCardTitle
                            className="overview__title"
                            title={groupName}
                        />
                        <VerticalBarChart
                            id={id + index}
                            data={chartData}
                            height={400}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </DocumentCardDetails>
                );
                subCharts.push(subChart);
            }
        });
        if (subCharts.length > 0) {
            return (
                <div key={`checkbox_${definition.name}_container`}>
                    <DocumentCard
                        className="overview__card"
                        key={`checkbox_${definition.name}`}
                        type={DocumentCardType.compact}
                    >
                        {subCharts}
                    </DocumentCard>
                </div>
            );
        }
    }

    queryMetricsResult() {
        const { storageVersion = "" } = this.props;

        if (storageVersion === "v2") {
            this._queryMetricsResult("basic_metrics.json");
        } else {
            this._queryMetricsResult("checkbox_coco.json");
        }
    }

    private _prepareRenderData() {
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(datasets.map((dataset) => dataset.name))
        );

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.name === datasetName
                    );
                });
                return item;
            });

            const groups = CHECKBOX_METRICS_DEFINITIONS.flatMap(
                (definition) => {
                    return definition.props.map((prop) => prop.name);
                }
            );
            const groupData = groups.map((group) => {
                let data = [] as any[];
                let checkbox = {
                    mAP: new Array<number>(items.length).fill(NaN),
                    mAR: new Array<number>(items.length).fill(NaN),
                    mAF1: new Array<number>(items.length).fill(NaN),
                    square: new Array<number>(items.length).fill(NaN),
                    round: new Array<number>(items.length).fill(NaN),
                    underline: new Array<number>(items.length).fill(NaN),
                    grid: new Array<number>(items.length).fill(NaN),
                    bracket: new Array<number>(items.length).fill(NaN),
                    toggle: new Array<number>(items.length).fill(NaN),
                    circlable: new Array<number>(items.length).fill(NaN),
                    other: new Array<number>(items.length).fill(NaN),
                };
                let noData = true;
                items.forEach((item, index) => {
                    if (item) {
                        const { metrics } = item;
                        if (
                            metrics.CheckboxCOCOMetrics &&
                            group in metrics.CheckboxCOCOMetrics
                        ) {
                            noData = false;
                            COCO_SCORE_KEYS.forEach((scoreKey) => {
                                checkbox[scoreKey][index] =
                                    metrics.CheckboxCOCOMetrics[group][
                                        scoreKey
                                    ];
                            });
                        }
                    }
                    data.push(item);
                });
                const checkboxItems = {
                    data: data,
                    checkbox: checkbox,
                };
                if (noData) {
                    return [group, undefined] as [string, any];
                } else {
                    return [group, checkboxItems] as [string, any];
                }
            });
            return [datasetName, groupData] as [string, [string, any[]][]];
        });
        return data;
    }

    private _renderAsTable() {
        const { columns, selectedColumns } = this.state;

        if (this.state.dataItems.length > 0 && columns) {
            const data = this._prepareRenderTableData();
            this.exportData = [];
            return data.map(([datasetName, recordMetrics], index) => {
                const tableData = recordMetrics.map(
                    ([_, fieldMetricsItems]) => {
                        return fieldMetricsItems.map((fieldMetricsItem) => {
                            const fieldMetrics = fieldMetricsItem.fieldMetrics;
                            // if (fieldMetrics) {
                            //     fieldMetrics.DatasetName = datasetName;
                            // }
                            return fieldMetrics;
                        });
                    }
                );
                this.exportData.push([datasetName, tableData]);
                const showColumns: TableColumn[] = columns.filter(
                    (value) =>
                        selectedColumns?.findIndex(
                            (col) => col === value.name
                        ) !== -1
                );

                return (
                    <OverviewTable<CheckBoxFieldMetrics>
                        key={`CheckBox_${index}`}
                        evalData={tableData}
                        columns={showColumns}
                        tableTitle={datasetName}
                        downloadTableTitle={datasetName}
                        cellWidth={100}
                        displayNaN={false}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                );
            });
        }
    }

    private _prepareRenderTableData() {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(datasets.map((dataset) => dataset.name))
        );

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.name === datasetName
                    );
                });
                return item;
            });

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const rows = item?.metrics.rows;
                        return rows ? rows.map((row) => row[0]) : [];
                    })
                )
            );

            const groupData = entities.map((field, index) => {
                // retrieve field data
                const metricsItems = items
                    .map((item) => {
                        let fieldMetrics: CheckBoxFieldMetrics = {};
                        const rows = item?.metrics.rows;
                        const header = item?.metrics.header as string[];
                        if (rows && rows.length > 0) {
                            for (const key of header) {
                                const keyIndex = header.indexOf(key);
                                if (rows[index]) {
                                    fieldMetrics[key] = this._formaterNumber(
                                        rows[index][keyIndex]
                                    );
                                } else {
                                    fieldMetrics[key] = "";
                                }
                            }
                        }

                        return {
                            data: item,
                            fieldMetrics: fieldMetrics,
                        } as IKvpDataItem;
                    })
                    .filter((metricsItem) => !_.isEmpty(metricsItem));
                return [field, metricsItems] as [string, IKvpDataItem[]];
            });

            return [datasetName, groupData] as [
                string,
                [string, IKvpDataItem[]][]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _formaterNumber(number: any) {
        if (Number(number) === -1) {
            return NaN;
        }
        return number;
    }
}
