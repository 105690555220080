import "../Common/MetricStyle.scss";
import React from "react";
import _ from "lodash";

import {
    //Group&DetailList
    DetailsList,
    IColumn,
    SelectionMode,

    //Others
    Label,
    Stack,
    mergeStyleSets,
    IDetailsListProps,
    IDetailsRowStyles,
    getTheme,
    DetailsRow,
} from "@fluentui/react";

import { Record, Workspaces } from "../../DataContract";
import { hexToRGB } from "../../Utils";
import { FullScreen } from "../Common/FullScreen";
import AuthLink from "../../AuthComponent/AuthLink";

const theme = getTheme();

interface BaseLineResults {
    result: BaseLine;
    reports: string[];
}
interface BaseLine {
    TestName: string;
    ReleaseName: string;
    BaselineTag: string;
    Overall: string;
    FailureRate: string;
    Failures: any;
    Details: { string: BaseLineDetailResults };
}

interface BaseLineDetailResults {
    Filename: string;
    ModelId: string;
    Overall: string;
    Analyze: string;
    Baseline: string;
    DiffPath: string;
    Error: string;
}

interface IState {
    data?: BaseLine;
}

interface IProps {
    workspace: string;
    records: Record[];
}

const classNames = mergeStyleSets({
    title: { marginLeft: "1%" },
    tip: { fontSize: "12px", color: "#0078d4" },
    headerAndFooter: {
        padding: 8,
        margin: "8px,0",
        // Overlay the sizer bars
        position: "relative",
        zIndex: 100,
    },
    headerTitle: [
        {
            padding: "4px 0",
        },
    ],
    headerLink: {
        margin: "0 8px",
    },
    iconClass: {
        fontSize: 10,
        height: 10,
        width: 10,
        margin: "0 25px",
    },
});

const CONFIG_COLUMNS: IColumn[] = [
    {
        key: "BaselineTag",
        name: "BaselineTag",
        fieldName: "BaselineTag",
        minWidth: 310,
        maxWidth: 500,
        isResizable: true,
    },
    {
        key: "Overall",
        name: "Overall",
        fieldName: "Overall",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "FailureRate",
        name: "Failure Rate",
        fieldName: "FailureRate",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
];

const DETAILS_COLUMNS: IColumn[] = [
    {
        key: "Filename",
        name: "Filename",
        fieldName: "Filename",
        minWidth: 300,
        maxWidth: 350,
        isResizable: true,
    },
    {
        key: "ModelId",
        name: "Model Id",
        fieldName: "ModelId",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "Overall",
        name: "Overall",
        fieldName: "Overall",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "Analyze",
        name: "Analyze",
        fieldName: "Analyze",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "Baseline",
        name: "Baseline",
        fieldName: "Baseline",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "Error",
        name: "Message",
        fieldName: "Error",
        minWidth: 300,
        maxWidth: 550,
        isResizable: true,
        isMultiline: true,
    },
    {
        key: "DiffPath",
        name: "Link",
        fieldName: "DiffPath",
        minWidth: 300,
        maxWidth: 350,
        isResizable: true,
        onRender: (item) => (
            <>
                {item.DiffPath && (
                    <>
                        <AuthLink
                            url={item.DiffPath}
                            target="_blank"
                            styles={{
                                root: {
                                    color: "#75b6e7",
                                },
                            }}
                        >
                            Diff
                        </AuthLink>
                        &nbsp;&nbsp;
                    </>
                )}
            </>
        ),
    },
];

export class BaseLineDetails extends React.Component<IProps, IState> {
    private readonly detailsColumns: IColumn[] = DETAILS_COLUMNS;

    constructor(props: IProps) {
        super(props);
        if (props.workspace === Workspaces.CustomDocReleaseTest) {
            this.detailsColumns = DETAILS_COLUMNS.map((col) => {
                if (col.key === "DiffPath") {
                    col.onRender =
                        this._onColumnRenderCompatibleWithHistoryData;
                }

                return col;
            });
        }
        this.state = {};
    }

    public render() {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "auto" }}>
                    <Stack>
                        <Label className={classNames.title}>
                            <h3>Summary</h3>
                        </Label>
                        {this._onRenderConfigDom()}
                    </Stack>
                    <Stack>
                        <Label className={classNames.title}>
                            <h3>Details</h3>
                        </Label>
                        {this._onRenderTableDom()}
                    </Stack>
                </div>
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryOverallResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryOverallResult();
        }
    }

    public _queryOverallResult() {
        const { records } = this.props;
        if (records.length > 0) {
            records[0]
                .getDetails()[0]
                .fetchMetricsWithRecordDetail<BaseLineResults>("baseline")
                .then((data) => {
                    this.setState({
                        data: data[1].result,
                    });
                });
        }
    }

    private _onColumnRenderCompatibleWithHistoryData(item?: any) {
        let element = <></>;
        if (item.DiffPath) {
            const fixedPath = item.DiffPath.replace(
                "mnt/oneocrdevstorage/customdoc-release-test/archive",
                "api/eval/blobs/customdoc_releasetest_data"
            );

            element = (
                <>
                    <AuthLink
                        url={fixedPath}
                        target="_blank"
                        styles={{
                            root: {
                                color: "#75b6e7",
                            },
                        }}
                    >
                        Diff
                    </AuthLink>
                    &nbsp;&nbsp;
                </>
            );
        }

        return element;
    }

    private _onRenderConfigDom() {
        const { data } = this.state;
        if (data) {
            return (
                <DetailsList
                    columns={CONFIG_COLUMNS}
                    items={[data]}
                    selectionMode={SelectionMode.none}
                    onRenderRow={this._onRenderRow}
                    onShouldVirtualize={() => false}
                />
            );
        }
    }

    private _onRenderTableDom() {
        const { data } = this.state;
        if (data && !_.isEmpty(data)) {
            const datesils = data.Details;
            const items = Object.entries(datesils).map((detail) => {
                const item = _.cloneDeep(detail[1]);
                item.Filename = detail[0];
                return item;
            });
            return (
                <DetailsList
                    columns={this.detailsColumns}
                    items={items}
                    selectionMode={SelectionMode.none}
                    onRenderRow={this._onRenderRow}
                    onShouldVirtualize={() => false}
                />
            );
        }
    }

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (props?.item.Overall === "Fail") {
                customStyles.root = {
                    backgroundColor: hexToRGB(theme.palette.red, 0.2),
                };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };
}
