import {
    //Others
    MessageBar,
    MessageBarType,
    Stack,
    Toggle,
} from "@fluentui/react";
import _ from "lodash";
import * as React from "react";
import {
    sortItems,
    sortItemsByDiffNumWhenCompare2Records,
} from "../../Controls/Common/DiffSort";
import { DatasetSet, RecordDetail } from "../../DataContract";
import { exportPredictionData } from "../../Utils/ExportFile";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    CLM_COLUMNS,
    CLM_DES_COLUMNS,
    PREDICTION_COLUMNS,
    PREDICTION_DES_COLUMNS,
} from "./EntityConstants";
import { DATASET_NAME_SYMBOLS } from "./EntityConstants";
import { GroupListItem } from "../../Controls/GroupListItem";
import { OverviewTable } from "../../Controls";
import { NameSymbols } from "./NameSymbols";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import {
    CalloutTable,
    CalloutType,
    formartTarget,
} from "../../Controls/CalloutTable";

enum PopType {
    prediction = "PREDICTION",
    clm = "CLM",
}

interface OcrDataset {
    pred_entity: string;
    pred_count: number;
    dataset: string;
    language: string;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: { [key: string]: PredictionMetric[] };
    clmDatas: { [key: string]: ClmSnalysis[] };
}

interface PredictionMetric {
    pred_entity: string;
    pred_count: number;
    entityName: string;
    [key: string]: string | number;
}

interface ClmSnalysis {
    clm_status: string;
    count: number;
    entityName: string;
}

interface IState extends ICommonState<any> {
    dataItems: IDataItem[];
    chooseOption: string;
    target?: string;
    showDescription?: boolean;
    selectedCrossLanguages?: string[];
    popType?: PopType;
}

interface IProps extends ICommonProps {
    onClick?: (
        dataset?: string,
        entityName?: string,
        selectEntityPrediction?: string,
        clmStatus?: string
    ) => void;
}

export class EntityPredictionMetricsView extends CommonView<
    IProps,
    IState,
    any
> {
    private intersectLanguages: string[];

    constructor(props: IProps) {
        super(props);

        this.state = {
            dataItems: [],
            chooseOption: DATASET_NAME_SYMBOLS.Verticals,
            showDescription: true,
            matchDatasetVersion: true,
        };

        this.intersectLanguages = this._getLanguageList(true);
    }

    public render() {
        const { showDescription } = this.state;
        return (
            <FullScreen>
                <div className="displayFlex">
                    <NameSymbols
                        onSelectItemKey={(itemKey) =>
                            this.setState({ chooseOption: itemKey })
                        }
                    />

                    <Toggle
                        label="Show entities under documental scenario only"
                        defaultChecked={showDescription ?? false}
                        inlineLabel
                        onText="On"
                        offText="Off"
                        onChange={(event, checked) => {
                            this.setState({
                                showDescription: checked,
                            });
                        }}
                    />
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderAsTable()}
                    </div>
                </div>
            </FullScreen>
        );
    }

    exportAction = () => {
        exportPredictionData(
            this.exportData,
            PREDICTION_COLUMNS,
            "EntityByPredictionMetrics"
        );
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (
            this.props.records !== prevProps.records ||
            this.state.showDescription !== prevState.showDescription
        ) {
            this.intersectLanguages = this._getLanguageList(true);
            this.queryMetricsResult();
        }
        if (
            !_.isEqual(prevState.dataItems, this.state.dataItems) ||
            !_.isEqual(this.state.chooseOption, prevState.chooseOption)
        ) {
            this.setState({
                selectedCrossLanguages:
                    this._extractDatasetNameByChooseOption(),
            });
        }
    }

    queryMetricsResult() {
        let metricsFile = "entity_prediction_analysis.json";
        if (this.state.showDescription) {
            metricsFile = "doc_entity_prediction_analysis.json";
        }
        this._queryMetricsResult(metricsFile);
    }

    protected _queryMetricsResult(
        metricsFileName: string,
        selectLanguage?: string,
        matchDatasetVersion: boolean = true
    ) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        // find one dataset detail per dataset
        const detailsList = this.props.records.map((r) => {
            if (!selectLanguage) {
                return r
                    .getDetails()
                    .filter((d) => d !== undefined) as RecordDetail[];
            } else {
                return datasets
                    .map((d) =>
                        r
                            .getDetails()
                            .find((rd) =>
                                selectLanguage
                                    ? rd.dataset.equals(d) &&
                                      (matchDatasetVersion
                                          ? rd.dataset.displayFullName ===
                                            selectLanguage
                                          : rd.dataset.displayName ===
                                            selectLanguage)
                                    : rd.dataset.equals(d)
                            )
                    )
                    .filter((d) => d !== undefined) as RecordDetail[];
            }
        });

        const getEntityPredictionAnalysis = () => {
            const result = Promise.all(
                detailsList.flatMap((details, index) => {
                    return details.map((detail) =>
                        detail
                            .fetchMetricsWithRecordDetail<{
                                [key: string]: PredictionMetric[];
                            }>(metricsFileName)
                            .then((metrics) => {
                                return {
                                    recordIndex: index,
                                    recordDetail: metrics[0],
                                    metrics: metrics[1],
                                } as IDataItem;
                            })
                            .catch((_error) => {
                                return {
                                    recordIndex: index,
                                    recordDetail: detail,
                                    metrics: {},
                                } as IDataItem;
                            })
                    );
                })
            );
            return result;
        };

        const getClmnAnalysis = () => {
            const result = Promise.all(
                detailsList.flatMap((details, index) => {
                    return details.map((detail) =>
                        detail
                            .fetchMetricsWithRecordDetail<{
                                [key: string]: ClmSnalysis[];
                            }>("clm_result_analysis.json")
                            .then((metrics) => {
                                return {
                                    recordIndex: index,
                                    recordDetail: metrics[0],
                                    metrics: metrics[1],
                                };
                            })
                            .catch((_error) => {
                                return {
                                    recordIndex: index,
                                    recordDetail: detail,
                                    metrics: undefined,
                                };
                            })
                    );
                })
            );
            return result;
        };

        Promise.all([getEntityPredictionAnalysis(), getClmnAnalysis()]).then(
            ([datas, clmItems]) => {
                const dataItems = datas
                    .filter((data) => data.metrics)
                    .map((data) => {
                        const clmData = clmItems
                            .filter((item) => item.metrics)
                            .find(
                                (item) =>
                                    item.recordIndex === data.recordIndex &&
                                    item.recordDetail.dataset
                                        .displayFullName ===
                                        data.recordDetail.dataset
                                            .displayFullName
                            );
                        data.clmDatas = clmData?.metrics || {};
                        return data;
                    });
                this.setState({
                    dataItems: dataItems as IDataItem[],
                });
            }
        );
    }

    private _renderAsTable() {
        this.intersectLanguages = this._getLanguageList(true);
        const { target, popType } = this.state;
        const data = this._prepareRenderData();
        const dataset = this._prepareRenderDatasetData();
        this.exportData = data;
        let popColumn = PREDICTION_DES_COLUMNS;
        switch (popType) {
            case PopType.prediction:
                popColumn = PREDICTION_DES_COLUMNS;
                break;
            case PopType.clm:
                popColumn = CLM_DES_COLUMNS;
                break;

            default:
                break;
        }
        return (
            <>
                {data ? (
                    <Stack>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            messageBarIconProps={{
                                iconName: "InfoSolid",
                            }}
                            styles={{
                                root: {
                                    backgroundColor: "#eff6fc",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                    width: "99%",
                                },
                                icon: {
                                    color: "#0078d4",
                                },
                            }}
                        >
                            <b>
                                Double click to check the entity data by dataset
                            </b>
                        </MessageBar>

                        {target && dataset && popType && (
                            <CalloutTable
                                tableTitle={target.split("-")[0]}
                                targetId={formartTarget(target)}
                                columns={popColumn}
                                evalData={dataset}
                                message="Double click to check by entity metrics for given dataset and entity"
                                evalDataCount={this.props.records.length}
                                onItemInvoked={this._calloutItemInvoked}
                                onDisMiss={() => {
                                    this.setState({
                                        target: undefined,
                                    });
                                }}
                            />
                        )}

                        <>
                            {data?.map((item) => {
                                return (
                                    <>
                                        <GroupListItem
                                            style={{}}
                                            columns={PREDICTION_COLUMNS}
                                            evalData={[item[0], item[1]]}
                                            targetKeys={[
                                                "entityName",
                                                "pred_entity",
                                            ]}
                                            onItemInvoked={this.onItemInvoked}
                                            isDarkTheme={this.props.isDarkTheme}
                                        />

                                        {item[2] && item[2].length > 0 && (
                                            <OverviewTable<ClmSnalysis>
                                                evalData={item[2]}
                                                columns={CLM_COLUMNS}
                                                targetKeys={[
                                                    "entityName",
                                                    "clm_status",
                                                ]}
                                                calloutType={CalloutType.row}
                                                tableTitle={""}
                                                downloadTableTitle={
                                                    "Prediction_Metrics​"
                                                }
                                                prefixIcon={false}
                                                onItemInvoked={
                                                    this._clmStatusonItemInvoked
                                                }
                                                isDarkTheme={
                                                    this.props.isDarkTheme
                                                }
                                            />
                                        )}
                                    </>
                                );
                            })}
                        </>
                    </Stack>
                ) : (
                    <NoDataTip>No Entity Metrics Generated</NoDataTip>
                )}
            </>
        );
    }

    private _sortByEntity = (entities: string[]) => {
        const sortEntity = entities.sort();
        const partitionEntity = _.partition(sortEntity, function (entity) {
            return entity !== "TOTAL" && entity !== "CORRECT";
        });
        return partitionEntity[0].concat(partitionEntity[1]);
    };

    _getLanguageList(matchDatasetVersion: boolean): string[] {
        const { dataItems } = this.state;
        if (dataItems && dataItems.length > 0) {
            const langStatsMap = new Map<string, Array<Boolean>>();
            dataItems.forEach((item) => {
                if (!_.isEmpty(item.metrics)) {
                    const lang = matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName;

                    let existStat = langStatsMap.get(lang);
                    if (existStat === undefined) {
                        existStat = new Array<Boolean>(
                            this.props.records.length
                        ).fill(false);
                    }

                    existStat[item.recordIndex] = true;
                    langStatsMap.set(lang, existStat);
                }
            });

            const languages = Array.from(langStatsMap)
                .filter(([_, stat]) => stat.every((s) => s === true))
                .map(([lang, _]) => lang)
                .sort();

            return languages;
        } else {
            return super._getLanguageList(matchDatasetVersion);
        }
    }

    //#region Calculate and construct data
    private _prepareRenderData = () => {
        const all_items = this.props.records.flatMap((_, recordIndex) => {
            const item = this.state.dataItems.filter(
                (item) => item.recordIndex === recordIndex
            );
            return item;
        });

        let tab_items = this._extracRecordsByChooseOption(all_items);
        if (this.state.selectedCrossLanguages) {
            tab_items = tab_items.filter((item) =>
                this.state.selectedCrossLanguages?.includes(
                    item.recordDetail.dataset.displayFullName
                )
            );
        }

        if (tab_items.length === 0) {
            return undefined;
        }

        const all_language = Array.from(
            new Set(
                tab_items.flatMap((item) => {
                    const metrics = item?.metrics;
                    return Object.keys(metrics);
                })
            )
        );

        const data = all_language.map((language) => {
            const all_entities = this._sortByEntity(
                Array.from(
                    new Set(
                        tab_items.flatMap((item) => {
                            const metrics = item?.metrics[language];
                            return metrics?.flatMap((m) => m.pred_entity);
                        })
                    )
                ).filter((en) => en)
            );

            const groupData = all_entities
                .map((pred_entity) => {
                    const result = this.props.records
                        .map((_, recordIndex) => {
                            const items = tab_items.filter(
                                (item) => item.recordIndex === recordIndex
                            );
                            const total_counts = items
                                .flatMap((item) => {
                                    const metrics = item?.metrics[
                                        language
                                    ]?.filter(
                                        (met) => met.pred_entity === pred_entity
                                    );
                                    if (metrics && metrics.length > 0) {
                                        return metrics.reduce(
                                            (per, cur) => per + cur.pred_count,
                                            0
                                        );
                                    } else {
                                        return NaN;
                                    }
                                })
                                .filter((count) => !isNaN(count));
                            let total_count = NaN;
                            if (total_counts.length > 0) {
                                total_count = total_counts.reduce(
                                    (per, cur) => per + cur,
                                    0
                                );
                            }

                            return {
                                pred_entity: pred_entity,
                                pred_count: total_count,
                                entityName: language,
                            };
                        })
                        .filter((r) => r !== undefined);

                    return result;
                })
                .filter((g) => g.length > 0);

            const all_clm_status = this._sortByEntity(
                Array.from(
                    new Set(
                        tab_items.flatMap((item) => {
                            const metrics = item?.clmDatas[language];
                            return metrics?.flatMap(
                                (m: ClmSnalysis) => m.clm_status
                            );
                        })
                    )
                ).filter((en) => en)
            );

            const clmDatas = all_clm_status
                .map((clm_status) => {
                    const result = this.props.records
                        .map((_, recordIndex) => {
                            const items = tab_items.filter(
                                (item) => item.recordIndex === recordIndex
                            );
                            const total_counts = items
                                .flatMap((item) => {
                                    const clm = item?.clmDatas[
                                        language
                                    ]?.filter(
                                        (met) =>
                                            met &&
                                            met?.clm_status === clm_status
                                    );
                                    if (clm && clm.length > 0) {
                                        return clm.reduce(
                                            (per, cur) => per + cur.count,
                                            0
                                        );
                                    } else {
                                        return NaN;
                                    }
                                })
                                .filter((count) => !isNaN(count));
                            let total_count = NaN;
                            if (total_counts.length > 0) {
                                total_count = total_counts.reduce(
                                    (per, cur) => per + cur,
                                    0
                                );
                            }

                            return {
                                clm_status: clm_status,
                                count: total_count,
                                entityName: language,
                            };
                        })
                        .filter((r) => r !== undefined);

                    return result;
                })
                .filter((g) => g.length > 0);

            return [language, groupData, clmDatas] as [
                string,
                PredictionMetric[][],
                ClmSnalysis[][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    };

    private _prepareRenderDatasetData = () => {
        const { target, dataItems } = this.state;
        if (!target || dataItems.length === 0) {
            return undefined;
        }
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(datasets.map((dataset) => dataset.displayFullName))
        );
        const [language, predictionOrClmStatus] = target.split("-");

        const data = allDatasetNames.map((datasetName) => {
            const all_items = this.props.records.flatMap((_, recordIndex) => {
                const item = this.state.dataItems.filter(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.displayFullName ===
                            datasetName
                );

                return item;
            });

            let items = this._extracRecordsByChooseOption(all_items);
            if (this.state.selectedCrossLanguages) {
                items = items.filter((item) =>
                    this.state.selectedCrossLanguages?.includes(
                        item.recordDetail.dataset.displayFullName
                    )
                );
            }

            const predictionResutl = () => {
                return items.map((item) => {
                    const tagItem =
                        item?.metrics[language]?.filter(
                            (tagItem) =>
                                tagItem.pred_entity === predictionOrClmStatus
                        ) || [];
                    return {
                        pred_entity: predictionOrClmStatus,
                        dataset: datasetName,
                        language: language,
                        pred_count:
                            tagItem.length > 0 ? tagItem[0].pred_count : NaN,
                    };
                });
            };

            const clmResutl = () => {
                return items.map((item) => {
                    const tagItem =
                        item?.clmDatas[language]?.filter(
                            (tagItem) =>
                                tagItem.clm_status === predictionOrClmStatus
                        ) || [];
                    return {
                        clm_status: predictionOrClmStatus,
                        dataset: datasetName,
                        language: language,
                        pred_count: tagItem.length > 0 ? tagItem[0].count : NaN,
                    };
                });
            };

            switch (this.state.popType) {
                case PopType.prediction:
                    return predictionResutl();
                case PopType.clm:
                    return clmResutl();
                default:
                    return undefined;
            }
        });

        const sortData = this._sortByCount(
            (data as any[][]).filter((datasetItem) => {
                return datasetItem.some((item) => !isNaN(item.pred_count));
            })
        );
        const resultArray = this._supplementDefaultValue(sortData);
        const result: any = {};
        resultArray.forEach((value, index) => {
            result[index] = value;
        });

        return result;
    };

    private _supplementDefaultValue = (data: OcrDataset[][]) => {
        const temp = _.cloneDeep(data);
        data.forEach((datasetItem, index) => {
            const diffCount = this.props.records.length - datasetItem.length;
            if (diffCount > 0) {
                for (let i = 0; i < diffCount; i++) {
                    const defaultValue = _.cloneDeep(datasetItem[0]);
                    defaultValue.pred_count = 0;
                    temp[index] = temp[index].concat(defaultValue);
                }
            }
        });
        return temp;
    };

    private _sortByCount = <T extends unknown>(data: T[][] = []) => {
        if (this.props.records.length === 2) {
            return data.sort((a: T[], b: T[]) =>
                sortItemsByDiffNumWhenCompare2Records<T>(
                    a,
                    b,
                    "pred_count" as keyof T
                )
            );
        } else {
            return data.sort((a: T[], b: T[]) =>
                sortItems<T>(a, b, "pred_count" as keyof T)
            );
        }
    };

    private _extracRecordsByChooseOption(items: IDataItem[]) {
        switch (this.state.chooseOption) {
            case DATASET_NAME_SYMBOLS.OCR_Printed: {
                return items.filter(
                    (item) =>
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Verticals
                        ) &&
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Entity
                        ) &&
                        item.recordDetail.dataset.displayFullName.indexOf(
                            DATASET_NAME_SYMBOLS.OCR_Handwritten
                        ) === -1
                );
            }
            case DATASET_NAME_SYMBOLS.OCR_Handwritten: {
                return items.filter(
                    (item) =>
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Verticals
                        ) &&
                        !item.recordDetail.dataset.displayFullName.startsWith(
                            DATASET_NAME_SYMBOLS.Entity
                        ) &&
                        item.recordDetail.dataset.displayFullName.indexOf(
                            DATASET_NAME_SYMBOLS.OCR_Handwritten
                        ) !== -1
                );
            }
            case DATASET_NAME_SYMBOLS.Entity: {
                return items.filter((item) =>
                    item.recordDetail.dataset.displayFullName.startsWith(
                        DATASET_NAME_SYMBOLS.Entity
                    )
                );
            }
            case DATASET_NAME_SYMBOLS.Verticals: {
                return items.filter((item) =>
                    item.recordDetail.dataset.displayFullName.startsWith(
                        DATASET_NAME_SYMBOLS.Verticals
                    )
                );
            }
            default:
                return [];
        }
    }

    private _extractDatasetNameByChooseOption(
        chooseOption: string | undefined = this.state.chooseOption,
        datasetNames: string[] = this.intersectLanguages
    ) {
        switch (chooseOption) {
            case DATASET_NAME_SYMBOLS.OCR_Printed: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) ===
                            -1
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.OCR_Handwritten: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) !==
                            -1
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.Entity: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Entity)
                );
                break;
            }
            case DATASET_NAME_SYMBOLS.Verticals: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Verticals)
                );
                break;
            }
        }

        return datasetNames;
    }

    private onItemInvoked = (item: PredictionMetric[]) => {
        if (item) {
            this.setState({
                target: this._createDocumentId(item),
                popType: PopType.prediction,
            });
        }
    };

    private _clmStatusonItemInvoked = (item: any) => {
        if (item && item.length === 2) {
            const clmDatas: ClmSnalysis[] = item[1];
            const data = clmDatas.filter((clm) => clm.clm_status);
            let target = undefined;
            if (data.length > 0) {
                target = `${data[0].entityName}-${data[0].clm_status}`;
            }
            this.setState({
                target: target,
                popType: PopType.clm,
            });
        }
    };

    private _createDocumentId = (items: PredictionMetric[]) => {
        const item = items[0];
        return `${item.entityName}-${item.pred_entity}`;
    };

    private _calloutItemInvoked = (item?: any, index?: number, ev?: Event) => {
        const { onClick } = this.props;
        if (item && item.length === 2 && item[1].length > 0 && onClick) {
            const predictionInvkked = () => {
                const eneity = item[1][0];
                const dataset = eneity.dataset;
                const entityName = eneity.language;
                let entityPrddiction = eneity.pred_entity;
                switch (eneity.pred_entity) {
                    case "TOTAL":
                        entityPrddiction = undefined;
                        break;
                    default:
                        entityPrddiction = eneity.pred_entity;
                        break;
                }
                onClick(dataset, entityName, entityPrddiction);
            };

            const clmInvkked = () => {
                const clm = item[1][0];
                const dataset = clm.dataset;
                const entityName = clm.language;
                let clmStatus = clm.clm_status;

                onClick(dataset, entityName, undefined, clmStatus);
            };

            switch (this.state.popType) {
                case PopType.prediction:
                    predictionInvkked();
                    break;
                case PopType.clm:
                    clmInvkked();
                    break;
                default:
                    break;
            }
        }
    };

    //#endregion
}
