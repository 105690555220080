import React from "react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import {
    ColumnValueType,
    TableColumn,
    TableList,
    NumContrastPolicy,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import "../Common/MetricStyle.scss";
import { IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { KvpStateInSession } from "../../Pages/Scenarios";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import _ from "lodash";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

// prettier-ignore
const KVP_DOC_COLUMNS: TableColumn[] = [
    { key: "fileName",                                name: "FileName",                                 fieldName: "fileName",                               isKey: true,   valueType: ColumnValueType.String,  minWidth: 100,  maxWidth: 300, isResizable: true},
    { key: "textTotalGTKeys",                         name: "t.GTKeys",                                 fieldName: "textTotalGTKeys",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textTotalRecoKeys",                       name: "t.RecoKeys",                               fieldName: "textTotalRecoKeys",                      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textTotalGTValues",                       name: "t.GTValues",                               fieldName: "textTotalGTValues",                      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textTotalRecoValues",                     name: "t.RecoValues",                             fieldName: "textTotalRecoValues",                    isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textCorrectKeys",                         name: "t.CorrectKeys",                            fieldName: "textCorrectKeys",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textPartialMatchKeys",                    name: "t.PartialMatchKeys",                       fieldName: "textPartialMatchKeys",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textFalsePositives",                      name: "t.FalsePositives",                         fieldName: "textFalsePositives",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textCorrectPairs",                        name: "t.CorrectPairs",                           fieldName: "textCorrectPairs",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textFalsePosValues",                      name: "t.FalsePositivesValues",                   fieldName: "textFalsePosValues",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textCorrectValues",                       name: "t.CorrectValues",                          fieldName: "textCorrectValues",                      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "textKeyPrecision",                        name: "t.K.Precision",                            fieldName: "textKeyPrecision",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textKeyRecall",                           name: "t.K.Recall",                               fieldName: "textKeyRecall",                          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textKeyF1",                               name: "t.K.F1",                                   fieldName: "textKeyF1",                              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textValueF1",                             name: "t.V.F1",                                   fieldName: "textValueF1",                            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textPairPrecision",                       name: "t.P.Precision",                            fieldName: "textPairPrecision",                      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textPairRecall",                          name: "t.P.Recall",                               fieldName: "textPairRecall",                         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textValuePrecision",                      name: "t.V.Precision",                            fieldName: "textValuePrecision",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textValueRecall",                         name: "t.V.Recall",                               fieldName: "textValueRecall",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textPairF1",                              name: "t.P.F1",                                   fieldName: "textPairF1",                             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBTotalGTKeys",                           name: "cb.GTKeys",                                fieldName: "cBTotalGTKeys",                          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBTotalRecoKeys",                         name: "cb.RecoKeys",                              fieldName: "cBTotalRecoKeys",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBTotalGTValues",                         name: "cb.GTValues",                              fieldName: "cBTotalGTValues",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBTotalRecoValues",                       name: "cb.RecoValues",                            fieldName: "cBTotalRecoValues",                      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBCorrectKeys",                           name: "cb.Keys",                                  fieldName: "cBCorrectKeys",                          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBPartialMatchKeys",                      name: "cb.PartialMatchKeys",                      fieldName: "cBPartialMatchKeys",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBFalsePositives",                        name: "cb.FalsePositives",                        fieldName: "cBFalsePositives",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBCorrectValues",                         name: "cb.Values",                                fieldName: "cBCorrectValues",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBFalsePosValues",                        name: "cb.FalsePositivesValues",                  fieldName: "cBFalsePosValues",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true},
    { key: "cBCorrectPairs",                          name: "cb.CorrectPairs",                          fieldName: "cBCorrectPairs",                         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBKeyPrecision",                          name: "cb.K.Precision",                           fieldName: "cBKeyPrecision",                         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBKeyRecall",                             name: "cb.K.Recall",                              fieldName: "cBKeyRecall",                            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBKeyF1",                                 name: "cb.K.F1",                                  fieldName: "cBKeyF1",                                isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBValuePrecision",                        name: "cb.V.Precision",                           fieldName: "cBValuePrecision",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBValueRecall",                           name: "cb.V.Recall",                              fieldName: "cBValueRecall",                          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBValueF1",                               name: "cb.V.F1",                                  fieldName: "cBValueF1",                              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBPairPrecision",                         name: "cb.P.Precision",                           fieldName: "cBPairPrecision",                        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBPairRecall",                            name: "cb.P.Recall",                              fieldName: "cBPairRecall",                           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBPairF1",                                name: "cb.P.F1",                                  fieldName: "cBPairF1",                               isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 90,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "ocrToyUrl",                               name: "OcrToyUrl",                                fieldName: "ocrToyUrl",                              isKey: false,  valueType: ColumnValueType.Url,     minWidth: 100,  maxWidth: 250, isResizable: true},
    { key: "detect_val_to_wrong_key_numKvps",         name: "detect.val.to.wrong.key.numKvps",          fieldName: "detect_val_to_wrong_key_numKvps",        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "detect_missing_val_numKvps",              name: "detect.missing.val.numKvps",               fieldName: "detect_missing_val_numKvps",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "detect_key_maps_to_value_numKvps",        name: "detect.key.maps.to.value.numKvps",         fieldName: "detect_key_maps_to_value_numKvps",       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "detect_n_keys_to_one_numKvps",            name: "detect.n.keys.to.one.numKvps",             fieldName: "detect_n_keys_to_one_numKvps",           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 90,   maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCorrectCommonNames",                    name: "cb.CorrectCommonNames",                    fieldName: "cBCorrectCommonNames",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCorrectCommonNamesGolden",              name: "cb.CorrectCommonNamesGolden",              fieldName: "cBCorrectCommonNamesGolden",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBTotalRecoCommonNames",                  name: "cb.TotalRecoCommonNames",                  fieldName: "cBTotalRecoCommonNames",                 isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBTotalRecoCommonNamesGolden",            name: "cb.TotalRecoCommonNamesGolden",            fieldName: "cBTotalRecoCommonNamesGolden",           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBTotalGTCommonNames",                    name: "cb.TotalGTCommonNames",                    fieldName: "cBTotalGTCommonNames",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBTotalGTCommonNamesGolden",              name: "cb.TotalGTCommonNamesGolden",              fieldName: "cBTotalGTCommonNamesGolden",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNamePrecision",                   name: "cb.CommonNamePrecision",                   fieldName: "cBCommonNamePrecision",                  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNameGoldenPrecision",             name: "cb.CommonNameGoldenPrecision",             fieldName: "cBCommonNameGoldenPrecision",            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNameRecall",                      name: "cb.CommonNameRecall",                      fieldName: "cBCommonNameRecall",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNameGoldenRecall",                name: "cb.CommonNameGoldenRecall",                fieldName: "cBCommonNameGoldenRecall",               isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNameF1",                          name: "cb.CommonNameF1",                          fieldName: "cBCommonNameF1",                         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "cBCommonNameGoldenF1",                    name: "cb.CommonNameGoldenF1",                    fieldName: "cBCommonNameGoldenF1",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCorrectCommonNames",                  name: "t.CorrectCommonNames",                     fieldName: "textCorrectCommonNames",                 isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCorrectCommonNamesGolden",            name: "t.CorrectCommonNamesGolden",               fieldName: "textCorrectCommonNamesGolden",           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textTotalRecoCommonNames",                name: "t.TotalRecoCommonNames",                   fieldName: "textTotalRecoCommonNames",               isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textTotalRecoCommonNamesGolden",          name: "t.TotalRecoCommonNamesGolden",             fieldName: "textTotalRecoCommonNamesGolden",         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textTotalGTCommonNames",                  name: "t.TotalGTCommonNames",                     fieldName: "textTotalGTCommonNames",                 isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textTotalGTCommonNamesGolden",            name: "t.TotalGTCommonNamesGolden",               fieldName: "textTotalGTCommonNamesGolden",           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNamePrecision",                 name: "t.CommonNamePrecision",                    fieldName: "textCommonNamePrecision",                isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNameGoldenPrecision",           name: "t.CommonNameGoldenPrecision",              fieldName: "textCommonNameGoldenPrecision",          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNameRecall",                    name: "t.CommonNameRecall",                       fieldName: "textCommonNameRecall",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNameGoldenRecall",              name: "t.CommonNameGoldenRecall",                 fieldName: "textCommonNameGoldenRecall",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNameF1",                        name: "t.CommonNameF1",                           fieldName: "textCommonNameF1",                       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "textCommonNameGoldenF1",                  name: "t.CommonNameGoldenF1",                     fieldName: "textCommonNameGoldenF1",                 isKey: false,  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 190, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
];

interface KvpDocMetric extends IMetrics<IMetricUnit> {
    rootDir: string;
    fileName: string;
    totalGTKeys: number;
    totalRecoKeys: number;
    correctKeys: number;
    partialMatchKeys: number;
    falsePositives: number;
    correctPairs: number;
    keyPrecision: number;
    keyRecall: number;
    keyF1: number;
    pairPrecision: number;
    pairRecall: number;
    pairF1: number;
    textTotalGTKeys: number;
    textTotalRecoKeys: number;
    textCorrectKeys: number;
    textPartialMatchKeys: number;
    textFalsePositives: number;
    textCorrectPairs: number;
    textKeyPrecision: number;
    textKeyRecall: number;
    textKeyF1: number;
    textPairPrecision: number;
    textPairRecall: number;
    textPairF1: number;
    textTotalGTValues: number;
    textTotalRecoValues: number;
    textCorrectValues: number;
    textFalsePosValues: number;
    textValuePrecision: number;
    textValueRecall: number;
    textValueF1: number;
    cBTotalGTKeys: number;
    cBTotalRecoKeys: number;
    cBCorrectKeys: number;
    cBPartialMatchKeys: number;
    cBFalsePositives: number;
    cBCorrectPairs: number;
    cBKeyPrecision: number;
    cBKeyRecall: number;
    cBKeyF1: number;
    cBPairPrecision: number;
    cBPairRecall: number;
    cBPairF1: number;
    cBTotalGTValues: number;
    cBTotalRecoValues: number;
    cBCorrectValues: number;
    cBFalsePosValues: number;
    cBValuePrecision: number;
    cBValueRecall: number;
    cBValueF1: number;
    datasetName: string;
    ocrToyUrl: string;
    detect_val_to_wrong_key_numKvps: number;
    detect_missing_val_numKvps: number;
    detect_key_maps_to_value_numKvps: number;
    detect_n_keys_to_one_numKvps: number;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
}

interface IState extends IMetricState<KvpDocMetric> {
    evalData: any;
    selectLanguage?: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" },
};

const DEFAULT_COLUMNS = [
    "fileName",
    "textTotalRecoKeys",
    "textKeyPrecision",
    "textKeyRecall",
    "textValuePrecision",
    "textValueRecall",
    "cBTotalRecoKeys",
    "cBKeyPrecision",
    "cBKeyRecall",
    "cBValuePrecision",
    "cBValueRecall",
    "ocrToyUrl",
    "detect_val_to_wrong_key_numKvps",
    "detect_missing_val_numKvps",
    "detect_key_maps_to_value_numKvps",
    "detect_n_keys_to_one_numKvps",
    "textCommonNameGoldenPrecision",
    "textCommonNameGoldenRecall",
    "textCommonNameGoldenF1",
];

export class KvpDocMetricsView extends MetricsView<
    IProps,
    IState,
    KvpDocMetric
> {
    constructor(props: IProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);

        let selectLanguage = props.toSelectLanguage;
        if (selectLanguage === undefined) {
            const stateKey = this.getSessionStateKey(
                props.records,
                Workspaces.Kvp
            );
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(stateStr) as KvpStateInSession;
                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }
            }
        }

        this.state = {
            evalData: {},
            selectLanguage: selectLanguage,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: KVP_DOC_COLUMNS,
                saveKey: `${Workspaces.Kvp}_${Typename.KvpDocMetricsView}`,
                selectedItems: DEFAULT_COLUMNS,
            })
        );
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.onEvaluationRecordChanged();
        }
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectedColumns } = this.state;
        const columns: TableColumn[] = KVP_DOC_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        return (
            <FullScreen>
                {Object.keys(evalData).length > 0 && (
                    <TableList<KvpDocMetric>
                        key={this.state.selectLanguage}
                        columns={columns}
                        evalDataCount={records.length}
                        evalData={evalData}
                        downloadTableTitle={this.state.selectLanguage}
                        renderTableHeader={this._renderTableHeader}
                        onItemInvoked={this._onItemInvoked}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}

                {Object.keys(evalData).length === 0 && (
                    <NoDataTip>No KVP data generated</NoDataTip>
                )}
            </FullScreen>
        );
    }

    private _renderTableHeader(): JSX.Element {
        const { matchDatasetVersion, selectLanguage } = this.state;
        const languages = this.getLanguageList(matchDatasetVersion).filter(
            (language) => !language.toLowerCase().includes("combined")
        );
        const selectLangKey = this.loadSelectedLanguage(
            selectLanguage,
            languages
        );
        const imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                styles: dropdownStyles,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    //#region Subclass implementation
    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<KvpDocMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<KvpDocMetric>>(metricName)
            .then(([record, metrics]) => {
                Object.entries(metrics).forEach(([fileName, docMetric]) => {
                    docMetric.fileName = fileName;
                    docMetric.datasetName = recordDetail.dataset.name;
                    if (
                        (!docMetric.textKeyF1 || isNaN(docMetric.textKeyF1)) &&
                        !isNaN(docMetric.keyF1)
                    ) {
                        docMetric.textTotalGTKeys = docMetric.totalGTKeys;
                        docMetric.textTotalRecoKeys = docMetric.totalRecoKeys;
                        docMetric.textCorrectKeys = docMetric.correctKeys;
                        docMetric.textPartialMatchKeys =
                            docMetric.partialMatchKeys;
                        docMetric.textFalsePositives = docMetric.falsePositives;
                        docMetric.textCorrectPairs = docMetric.correctPairs;
                        docMetric.textKeyPrecision = docMetric.keyPrecision;
                        docMetric.textKeyRecall = docMetric.keyRecall;
                        docMetric.textKeyF1 = docMetric.keyF1;
                        docMetric.textPairPrecision = docMetric.pairPrecision;
                        docMetric.textPairRecall = docMetric.pairRecall;
                        docMetric.textPairF1 = docMetric.pairF1;
                    }
                    const modelInfo = record.modelInfo;
                    const datasetName = recordDetail.dataset.name;
                    const rootDir = docMetric.rootDir;
                    if (modelInfo.startsWith("Release")) {
                        // Vertical legacy url.
                        docMetric.ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/kvp/${modelInfo}/${datasetName}/${docMetric.fileName.replace(
                            ".kvp.json",
                            ""
                        )}/1?root_dataset=${rootDir}`;
                    } else {
                        const algo =
                            recordDetail.getRawProp<string>("algorithm");
                        const path = `kvp/${record.modelInfo}/${
                            record.runtimeVersion
                        }/${record.buildSource}/${algo}/${
                            recordDetail.dataset.name
                        }/${recordDetail.dataset.name}__${
                            recordDetail.dataset.version
                        }/ocr_results/${docMetric.fileName.replace(
                            ".json",
                            ".pdf"
                        )}`;
                        docMetric.ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/${path}`;
                    }
                });

                return metrics;
            });
    }

    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }
    //#endregion

    //#region Event handler
    private _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { toSelectLanguage } = this.props;
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion).filter(
            (language) => !language.toLowerCase().includes("combined")
        );
        if (languageList.length > 0) {
            const selectLangKey = this.loadSelectedLanguage(
                selectLanguage ?? toSelectLanguage,
                languageList
            );

            const stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: selectLangKey,
            };

            this._onOptionsChanged(stateSettings);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { deepLinkHandler } = this.props;

        if (deepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as KvpDocMetric[];
                if (fieldMetricsArr?.length > 0) {
                    const fieldMetric = fieldMetricsArr[0];
                    const lowerDatasetName =
                        fieldMetric.datasetName?.toLowerCase();
                    if (!lowerDatasetName?.includes("combined")) {
                        let linkData: { [key: string]: string | undefined } =
                            {};
                        linkData.toSelectLanguage = fieldMetric.datasetName;
                        linkData.queryString = fieldMetric.fileName;
                        deepLinkHandler("overviewKvpDetailMetrics", linkData);
                    }
                }
            }
        }
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this._onQueryButtonClicked(selectLanguage);
        this.setState(
            {
                selectLanguage: selectLanguage,
            },
            () => {
                const { records, toSelectLanguage, deepLinkHandler } =
                    this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Kvp
                );
                const stateInSession: KvpStateInSession = {
                    selectLanguage: selectLanguage,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                if (deepLinkHandler && toSelectLanguage !== undefined) {
                    deepLinkHandler("overviewKvpDocumentMetrics", {
                        toSelectLanguage: undefined,
                    });
                }
            }
        );
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion
            );

            this.showEvaluationResult(details, "per-document.json");
        }

        this.setState({
            selectLanguage: selectLanguage,
        });
    };
    //#endregion
}
