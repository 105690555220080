import { vdiRequest } from "../../Utils/fetch";

const sqlRawImagesFields = [
    "imageid",
    "language",
    "category",
    "source",
    "tags",
    "imageblobpath",
    "usages",
    "checks",
    "processes",
    "bboxlabel",
    "testlabel",
    "reserved",
    "date",
];

const sqlTextlinesFields = [
    "textlineid",
    "language",
    "category",
    "texttag",
    "style",
    "checks",
    "_text",
    "_initaltext",
    "_categorytag",
    "_textdirectiontag",
    "_tag",
    "_polygon",
    "_keypoints",
    "_info",
    "_angle",
    "_lines",
    "timestamp",
    "texlinepath",
    "usages",
];

export interface IOcrDataRawImage {
    language: string;
    category: string;
    imageid: string;
    source: string;
    tags: string;
    imageblobpath: string;
    usages: string;
    checks: string;
    processes: string;
    bboxlabel: string;
    testlabel: string;
    reserved: string;
    date: string;
}

export interface IOcrDataTextLine {
    textlineid: string;
    language: string;
    category: string;
    texttag: string;
    style: string;
    checks: string;
    _text: string;
    _initaltext: string;
    _categorytag: string;
    _textdirectiontag: string;
    _tag: string;
    _polygon: string;
    _keypoints: string;
    _info: string;
    _angle: string;
    _lines: string;
    timestamp: string;
    texlinepath: string;
    usages: string;
}

export interface IOcrDatasetInfo {
    name: string;
    version: string;
    description: string;
    datasetType: string;
    isFileDataset: boolean;
    fields: string[];
    obj?: any;
}

export interface IOcrDatasetCatalog {
    datasets: {
        name: string;
        defaultVersion: string;
        versions: string[];
    }[];
    lastsync: string;
}

export interface IOcrDatasetDisplayInfo {
    name: string;
    defaultVersion: string;
    versions: string[];
}

export interface IOcrDatasetItem {
    [key: string]: any;
}

export interface IOcrDatasetItemList {
    [key: string]: IOcrDatasetItem;
}

export interface IOcrDatasetFileList {
    folders: IOcrDatasetItem[];
    files: IOcrDatasetItem[];
}

export interface IErrorMessage {
    message: string;
    exception?: string;
}

export interface IOcrDatasetDetails {
    [key: string]: any;
}

export const SQLRawImagesDatasetInfo = {
    id: "",
    name: "sql_rawimages",
    version: "",
    description: "",
    datasetType: "",
    isFileDataset: false,
    fields: sqlRawImagesFields,
    obj: {},
};

export class CacheNotReadyError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "CacheNotReadyError";
    }
}

export class UnauthorizedError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "UnauthorizedError";
    }
}

export async function formatErrorMessage(response: Response) {
    if (response.status === 401) {
        const error = await response.statusText;
        return new UnauthorizedError(error);
    } else {
        try {
            const error = await (response.json() as Promise<IErrorMessage>);
            if (
                response.status === 404 &&
                error.message.search(" is not cached.") > 0
            ) {
                return new CacheNotReadyError(error.message);
            } else {
                return Error(error.message);
            }
        } catch (error) {
            let errorMsg = await response.text();
            return Error(errorMsg);
        }
    }
}

export async function queryDatasetCatalog() {
    const response = await vdiRequest("api/datasets", { method: "GET" });
    if (response.ok) {
        let catalog = response.json() as Promise<IOcrDatasetCatalog>;
        return catalog;
    } else {
        throw await formatErrorMessage(response);
    }
}

export async function syncDatasetCatalog() {
    const response = await vdiRequest("api/datasets/sync", { method: "POST" });
    if (response.ok) {
        let message: string = await response.json();
        return message;
    } else {
        throw await formatErrorMessage(response);
    }
}

export async function queryDatasetFields(
    name: string,
    version: string
): Promise<string[]> {
    if (name === "sql_rawimages") {
        return sqlRawImagesFields;
    } else if (name === "sql_textlines") {
        return sqlTextlinesFields;
    } else {
        const url =
            "api/datasets/" + name + "/versions/" + version + "/fieldnames";
        const response = await vdiRequest(url, { method: "GET" });
        if (response.ok) {
            return response.json() as Promise<string[]>;
        } else {
            throw await formatErrorMessage(response);
        }
    }
}

export async function queryDatasetInfo(
    name: string,
    version: string
): Promise<IOcrDatasetInfo> {
    const url = "api/datasets/" + name + "/versions/" + version;
    const [response1, response2] = await Promise.all([
        vdiRequest(url, { method: "GET" }),
        vdiRequest(url + "/fieldnames", { method: "GET" }),
    ]);
    if (!response1.ok) {
        throw await formatErrorMessage(response1);
    }
    if (!response2.ok) {
        throw await formatErrorMessage(response2);
    }

    const obj = await response1.json();
    const fields = (await response2.json()) as string[];
    const info: IOcrDatasetInfo = {
        name: obj.name,
        version: obj.version,
        description: obj.description,
        datasetType: obj.tags.datasetType,
        isFileDataset: obj.tags.datasetType === "FileDataset",
        fields: fields,
        obj: obj,
    };
    return info;
}

export async function queryDatasetDetails(
    name: string,
    version: string
): Promise<IOcrDatasetDetails> {
    if (!name || !version) {
        throw Error("You must select a dataset before making a query.");
    }
    let url: string;
    url = "api/datasets/" + name + "/versions/" + version + "/details";
    const response = await vdiRequest(url, { method: "GET" });
    if (response.ok) {
        let detail: IOcrDatasetDetails[] = await response.json();
        return detail;
    } else {
        throw await formatErrorMessage(response);
    }
}

export async function queryDatasetItems(
    name: string,
    version: string,
    filter: string = "",
    itemCount: number = 1000,
    skipCount: number = 0
): Promise<IOcrDatasetItem[]> {
    if (!name || !version) {
        throw Error("You must select a dataset before making a query.");
    }

    let url: string;

    if (name === "sql_rawimages") {
        url = "api/data/rawimages?prefix=0";
    } else if (name === "sql_textlines") {
        url = "api/data/textlinelabels?prefix=0";
    } else {
        url = `api/datasets/${name}/versions/${version}/items`;

        let params = [];
        if (filter) {
            params.push(`filter=${encodeURIComponent(filter)}`);
        }
        if (itemCount) {
            params.push(`itemCount=${itemCount}`);
        }
        if (skipCount) {
            params.push(`skipCount=${skipCount}`);
        }
        if (params.length > 0) {
            url += `?${params.join("&")}`;
        }
    }

    const response = await vdiRequest(url, { method: "GET" });
    if (response.ok) {
        var items: IOcrDatasetItem[] = [];
        const itemsList = (await response.json()) as IOcrDatasetItemList[];
        for (var id in itemsList) {
            items.push({ _id: id, ...itemsList[id] });
        }
        return items;
    } else {
        throw await formatErrorMessage(response);
    }
}

export async function queryDatasetFiles(
    name: string,
    version: string,
    path: string = "",
    prefix: string = "",
    itemCount: number = 1000,
    skipCount: number = 0
): Promise<IOcrDatasetItem[]> {
    let url = `api/datasets/${name}/versions/${version}/tree`;
    if (path) {
        url += `/${encodeURIComponent(path)}`;
    }

    let params = [];
    if (prefix) {
        params.push(`prefix=${encodeURIComponent(prefix)}`);
    }
    if (itemCount) {
        params.push(`itemCount=${itemCount}`);
    }
    if (skipCount) {
        params.push(`skipCount=${skipCount}`);
    }
    if (params.length > 0) {
        url += `?${params.join("&")}`;
    }

    const response = await vdiRequest(url, { method: "GET" });
    if (response.ok) {
        var items: IOcrDatasetItem[] = [];
        const filelist = (await response.json()) as IOcrDatasetFileList;
        filelist.folders.forEach((folder, index) =>
            items.push({ _id: index, ...folder, isDir: true })
        );

        const foldersCount = filelist.folders.length;
        filelist.files.forEach((file, index) =>
            items.push({ _id: index + foldersCount, ...file, isDir: false })
        );
        return items;
    } else {
        throw await formatErrorMessage(response);
    }
}
