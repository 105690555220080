const DB_NAME = "RECORDS";
const DB_STORE_NAME = "record";

export class IndexDb {
    private db?: IDBDatabase;
    constructor() {
        this.openDB();
    }

    public openDB() {
        if (window.indexedDB) {
            const request = indexedDB.open(DB_NAME, 1);
            request.onerror = (event: Event) => {
                console.log(
                    "Why didn't you allow my web app to use IndexedDB?!"
                );
            };
            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
            };

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                var store = (
                    event.target as IDBOpenDBRequest
                ).result.createObjectStore(DB_STORE_NAME, {
                    keyPath: "key",
                    autoIncrement: true,
                });

                store.createIndex("key", "key", { unique: true });
            };
        }
    }

    public getObjectStore(
        store_name: string,
        mode?: IDBTransactionMode
    ): IDBObjectStore {
        var tx = this.db?.transaction(store_name, mode);
        return tx?.objectStore(store_name)!;
    }

    public getDataByKey(key: string) {
        return new Promise<string | undefined>((resolve) => {
            const objectStore = this.getObjectStore(DB_STORE_NAME);
            if (objectStore) {
                const request = objectStore.get(key);
                request.onsuccess = (event: Event) => {
                    const result = (event.target as IDBRequest).result;
                    resolve(result && result["value"]);
                };
            } else {
                resolve(undefined);
            }
        });
    }

    public writeToStore(obj: any) {
        const objectStore = this.getObjectStore(DB_STORE_NAME, "readwrite");
        if (objectStore) {
            const request = objectStore.add(obj);
            request.onsuccess = (event: Event) => {};
            request.onerror = (event: Event) => {};
        }
    }

    public deleteALl() {
        const objectStore = this.getObjectStore(DB_STORE_NAME, "readwrite");
        if (objectStore) {
            const request = objectStore.clear();
            request.onsuccess = (event: Event) => {};
            request.onerror = (event: Event) => {};
        }
    }
}

export const indexDb = new IndexDb();
