import React from "react";
import {
    ColumnValueType,
    ITableConfigs,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import {
    IDataItem,
    IMetricUnit,
    MetricDefinition,
    Record,
} from "../../DataContract";
import {
    openImageInNewWindow,
    resetScrollBarOfScrollablePane,
} from "../../Utils";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import _ from "lodash";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";

export const GROUP_SYMBOL_SEPARATOR = " | ";
const OCR_TOY_FIELD_NAME = "ocrToyUrl";

export enum ImageViewType {
    table,
    pod,
}

export enum OcrToyType {
    cropped_prediction_oneocr,
    prediction_oneocr,
    ocr_results,
}

const NAME_MAP = new Map<string, string>([
    ["similarity_score", "similarity score"],
    ["matched_group_similarity_score", "matched group similarity score"],
]);

type ITableItem<T extends IMetricUnit> = [string, (T | null)[]];
interface IState extends ICommonState<any> {
    dataItems: IDataItem<any>[];
    selectLanguage?: string;
    selectGroup?: string;
    groupNames?: string[];
    selectImageId?: string;
}

interface IProps<T extends IMetricUnit> extends ICommonProps {
    metricDefinitionArray: MetricDefinition[];
    dataSrcName: string;
    imageViewType: ImageViewType;
    ocrToyType: OcrToyType;
    saveSetKey?: string;
    optimizeData?: boolean;
    selectLanguage?: string;
    selectGroup?: string;
    onItemInvoked?: (item: ITableItem<T>, index?: number) => void;
}

export class ImageView<IMetric extends IMetricUnit> extends CommonView<
    IProps<IMetric>,
    IState,
    any
> {
    private allColumns: TableColumn[] = [];
    evalData: any;
    constructor(props: IProps<IMetric>) {
        super(props);

        this._loadDefaultColumns = this._loadDefaultColumns.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);
        const matchDatasetVersion =
            store.getState().settingReducer.matchDatasetVersion;
        this.state = {
            dataItems: [],
            matchDatasetVersion: matchDatasetVersion,
            selectLanguage:
                this.props.selectLanguage ||
                this._getUnionDistinctLangList(matchDatasetVersion)[0],
            selectGroup: this.props.selectGroup,
        };
    }

    render() {
        const { dataSrcName, records } = this.props;
        const { selectGroup, selectedColumns } = this.state;
        const targetDefinition = this.props.metricDefinitionArray.find(
            (definition) => definition.name === dataSrcName
        );

        if (targetDefinition) {
            let displayColumns = this.allColumns;
            if (selectedColumns && selectedColumns.length > 0) {
                displayColumns = this.allColumns.filter((col) =>
                    selectedColumns.includes(col.key)
                );
            }
            this.evalData = new Map<string, any[]>();
            const itemsMap =
                dataSrcName !== "hdsa"
                    ? this._prepareRenderData(selectGroup)
                    : this._prepareRenderHDSAData();
            if (itemsMap && itemsMap.size > 0) {
                const itemArr = Array.from(itemsMap);
                if (itemArr && itemArr.length > 0) {
                    itemArr.forEach(([key, values]) => {
                        this.evalData.set(
                            selectGroup + GROUP_SYMBOL_SEPARATOR + key,
                            values
                        );
                    });
                }
            }

            const tableKey = `${this.state.selectLanguage}_${this.state.selectGroup}`;
            return (
                <TableList<any>
                    key={tableKey}
                    columns={displayColumns}
                    evalData={this.evalData}
                    evalDataCount={records.length}
                    downloadTableTitle={tableKey}
                    renderTableHeader={this._renderTableHeader}
                    isDarkTheme={this.props.isDarkTheme}
                    onItemInvoked={
                        this.props.dataSrcName !== "hdsa"
                            ? this._onItemInvoked
                            : undefined
                    }
                />
            );
        }

        return <></>;
    }

    _onItemInvoked = (item: ITableItem<any>, index?: number) => {
        if (this.props.dataSrcName !== "hdsa") {
            const [imageId, datas] = item;
            const itemImageUrl = datas.find((data) => data.imageUrl)?.imageUrl;
            if (itemImageUrl) {
                this._onDownloadPicture(
                    itemImageUrl +
                        item[0].substring(
                            item[0].indexOf("|") + 2,
                            item[0].length
                        )
                );
                this.setState({ selectImageId: imageId });
            } else {
                alert("No url for this image");
            }
        }
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ) {
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            !_.isEqual(
                this.state?.matchDatasetVersion,
                prevState?.matchDatasetVersion
            )
        ) {
            this.setState(
                {
                    selectLanguage: this._getUnionDistinctLangList(
                        this.state.matchDatasetVersion!
                    )[0],
                },
                () => this.queryMetricsResult()
            );
        }

        if (!_.isEqual(this.state.dataItems, prevState.dataItems)) {
            const groupNames = Array.from(
                new Set(
                    this.state.dataItems.flatMap((item) =>
                        Object.values(item.metrics["details"] ?? {}).flatMap(
                            (v: any) => Object.keys(v)
                        )
                    )
                )
            );
            const { selectGroup } = this.state;
            if (groupNames.length > 0) {
                const groupStr =
                    selectGroup && groupNames.includes(selectGroup)
                        ? selectGroup
                        : groupNames[0];

                this._loadDefaultColumns(groupStr);

                this.setState({
                    groupNames: groupNames,
                    selectGroup: groupStr,
                });
            } else {
                this._loadDefaultColumns(undefined);
                this.setState({
                    groupNames: undefined,
                    selectGroup: undefined,
                });
            }
        }
    }

    componentWillUnmount() {
        this.setState = () => false;
    }

    queryMetricsResult(): void {
        const { selectLanguage } = this.state;
        const srcFileName = this.props.dataSrcName + ".json";
        if (selectLanguage) {
            this._queryMetricsResult(srcFileName, selectLanguage);
        }
    }

    private _loadDefaultColumns(selectGroup: string | undefined) {
        const { dataSrcName, metricDefinitionArray, ocrToyType, saveSetKey } =
            this.props;

        const targetDefinition = metricDefinitionArray.find(
            (definition) => definition.name === dataSrcName
        );

        if (targetDefinition) {
            this.allColumns = [
                {
                    key: "image",
                    name: "Image",
                    fieldName: "image",
                    isKey: true,
                    valueType: ColumnValueType.String,
                    minWidth: 300,
                    maxWidth: 500,
                    isResizable: true,
                },
            ];

            targetDefinition.tablefields.forEach((p) => {
                const col = {
                    key: p,
                    name: this._mapDisplayName(p),
                    fieldName: p,
                    isKey: false,
                    valueType: ColumnValueType.Number,
                    maxDecimalPlaces: 3,
                    minWidth: 100,
                    maxWidth: 300,
                    isResizable: true,
                };
                this.allColumns.push(col);
            });

            if (dataSrcName !== "hdsa") {
                this.allColumns.push({
                    key: OCR_TOY_FIELD_NAME,
                    name:
                        ocrToyType === OcrToyType.cropped_prediction_oneocr
                            ? "cropped ocr toy url"
                            : "ocr toy url",
                    fieldName: OCR_TOY_FIELD_NAME,
                    isKey: false,
                    valueType: ColumnValueType.Url,
                    minWidth: 150,
                    maxWidth: 200,
                    isResizable: true,
                });
            }

            if (dataSrcName === "hdsa") {
                this.allColumns.push({
                    key: "jsonurl",
                    name: "",
                    fieldName: "jsonurl",
                    isKey: false,
                    valueType: ColumnValueType.Download,
                    minWidth: 150,
                    maxWidth: 200,
                    isResizable: true,
                });
            }

            const selectedCols = this.allColumns.map((col) => col.key);

            if (saveSetKey) {
                store.dispatch(
                    updateStateAction({
                        saveKey: saveSetKey,
                        columns: this.allColumns,
                        selectedItems: selectedCols,
                    })
                );
            } else {
                this.setState({ selectedColumns: selectedCols });
            }
        }
    }

    private _mapDisplayName(name: string) {
        const displayName = NAME_MAP.get(name);
        return displayName ?? name;
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const statePick: Pick<IState, "selectLanguage" | "selectGroup"> = {};

        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : undefined;
        if (selectLanguage) {
            statePick.selectLanguage = selectLanguage;
            this.setState(statePick, this.queryMetricsResult);
        } else {
            const selectGroup =
                "selectGroup" in newOptions
                    ? newOptions["selectGroup"]
                    : undefined;
            if (selectGroup) {
                statePick.selectGroup = selectGroup;
                this.setState(statePick, resetScrollBarOfScrollablePane);
            }
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const { dataSrcName } = this.props;
        const { matchDatasetVersion, selectGroup, groupNames, selectLanguage } =
            this.state;
        const languages = this._getUnionDistinctLangList(matchDatasetVersion!);
        const selectLangKey = this._loadSelectedLanguage(
            selectLanguage,
            languages
        );

        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];

        if (groupNames && groupNames.length > 0 && dataSrcName !== "hdsa") {
            imageConfigurations.push({
                key: "subType",
                text: "SubType:",
                options: groupNames,
                selectedKey: selectGroup ?? groupNames[0],
                onChange: (group) => {
                    this._onOptionsChanged({
                        selectGroup: group.text,
                    });
                },
            });
        }

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _prepareRenderData(renderingGroup?: string): Map<string, any[]> {
        const { records } = this.props;
        const { dataItems, matchDatasetVersion, selectLanguage } = this.state;
        const itemsDict = new Map<string, any[]>();
        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0 &&
            selectLanguage &&
            renderingGroup
        ) {
            const colCount = records.length;
            const dataField = "details";

            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        selectLanguage ===
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName)
                    );
                });
                return item;
            });

            let defaultItem: any = {};
            items.forEach((item) => {
                if (item && item.metrics && item.metrics[dataField]) {
                    const colIndex = item.recordIndex;
                    const metricsArr = Object.entries<any>(
                        item.metrics[dataField]
                    );

                    metricsArr.forEach(([imgKey, obj]) => {
                        const dataItem = obj[renderingGroup];
                        if (
                            dataItem &&
                            Object.keys(dataItem).includes("edit_distance")
                        ) {
                            dataItem["similarity_score"] =
                                dataItem["edit_distance"];
                        }
                        if (dataItem) {
                            if (JSON.stringify(defaultItem) === "{}") {
                                Object.keys(dataItem).forEach((key) => {
                                    if (
                                        key !== OCR_TOY_FIELD_NAME &&
                                        key !== "imageUrl"
                                    ) {
                                        defaultItem[key] = "NaN";
                                    }
                                });
                            }

                            let itemArr = itemsDict.has(imgKey)
                                ? itemsDict.get(imgKey)
                                : Array.from({ length: colCount }, () => {
                                      return defaultItem;
                                  });
                            const record = records[item.recordIndex];

                            dataItem.ocrToyUrl = this._generateOcrtoyUrl(
                                record,
                                item,
                                this.props.imageViewType,
                                this.props.ocrToyType,
                                imgKey
                            );
                            dataItem.imageUrl = this.getImageUrl(
                                this.props.imageViewType,
                                item.recordDetail.dataset.name,
                                item.recordDetail.dataset.version,
                                ""
                            );
                            itemArr![colIndex] = dataItem;
                            itemsDict.set(imgKey, itemArr!);
                        }
                    });
                }
            });
        }

        return itemsDict;
    }

    private _prepareRenderHDSAData = () => {
        const { records, dataSrcName } = this.props;
        const { dataItems, matchDatasetVersion, selectLanguage } = this.state;
        const itemsDict = new Map<string, any[]>();
        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0 &&
            selectLanguage &&
            dataSrcName === "hdsa"
        ) {
            const colCount = records.length;
            const dataField = "details";

            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        selectLanguage ===
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName)
                    );
                });
                return item;
            });

            let defaultItem: any = {};
            items.forEach((item) => {
                if (item && item.metrics && item.metrics[dataField]) {
                    const colIndex = item.recordIndex;
                    const metricsArr = Object.entries<any>(
                        item.metrics[dataField]
                    );

                    metricsArr.forEach(([imgKey, obj]) => {
                        const dataItem = obj;
                        dataItem.jsonurl = item.recordDetail.composeMetricUrl(
                            `hdsa_results/${imgKey}.json`
                        );

                        if (dataItem) {
                            if (JSON.stringify(defaultItem) === "{}") {
                                Object.keys(dataItem).forEach((key) => {
                                    if (key === "jsonurl") {
                                        defaultItem[key] = undefined;
                                    } else {
                                        defaultItem[key] = "NaN";
                                    }
                                });
                            }

                            let itemArr = itemsDict.has(imgKey)
                                ? itemsDict.get(imgKey)
                                : Array.from({ length: colCount }, () => {
                                      return defaultItem;
                                  });

                            itemArr![colIndex] = dataItem;
                            itemsDict.set(imgKey, itemArr!);
                        }
                    });
                }
            });
        }

        return itemsDict;
    };

    private _generateOcrtoyUrl(
        record: Record,
        item: IDataItem<any>,
        imageViewType: ImageViewType,
        ocrToyType: OcrToyType,
        imgFileName: string
    ): string {
        const algo = item.recordDetail.getRawProp<string>("algorithm");
        const path = `${ImageViewType[imageViewType]}/${record.modelInfo}/${record.runtimeVersion}/${record.buildSource}/${algo}/${item.recordDetail.dataset.name}/${item.recordDetail.dataset.name}__${item.recordDetail.dataset.version}/${OcrToyType[ocrToyType]}/${imgFileName}`;
        let ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/${path}`;
        if (ocrToyType !== OcrToyType.ocr_results) {
            ocrToyUrl += ".jpg";
        }

        return ocrToyUrl;
    }

    private _onDownloadPicture(item: string): void {
        if (item) {
            if (ImageViewType[this.props.imageViewType] === "table") {
                item += ".jpg";
            }
            openImageInNewWindow(item, this.state.selectLanguage);

            return;
        }
        alert("No url for this image");
    }

    getImageUrl(
        imageViewType: ImageViewType,
        datasetName: string,
        version: string,
        imageName: string
    ): string {
        let fileDic: string = "";
        if (ImageViewType[imageViewType] === "table") {
            fileDic = "e2e_test_images";
        } else if (ImageViewType[imageViewType] === "pod") {
            fileDic = "GT_VIS";
        } else {
            fileDic = "JPEGImages";
        }
        return `/api/datasets/${datasetName}/versions/${version}/tree/${fileDic}/${imageName}`;
    }
}
