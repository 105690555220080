import React, { Component } from "react";
import * as d3 from "d3";
import {
    DocumentCardDetails,
    DocumentCardTitle,
    getTheme,
    IconButton,
    IIconProps,
    Label,
    Slider,
    Stack,
} from "@fluentui/react";
import "./VerticalScatterChart.scss";

export interface IScatterChartDataPoint {
    x: number | Date;
    y: number;
}
export interface IScatterChartData {
    legend: string;
    data: IScatterChartDataPoint[];
    color: string;
}
export interface IGroupLineChartData {
    name: string;
    series: IScatterChartData[];
}

export interface IGroupedScatterChartData {
    chartTitle: string;
    scatterChartData: IScatterChartData[];
}

interface IState {
    legendPoints: any[];
    showScrollX: boolean;
    visibleCalloutId: number;
}

interface IProps {
    chartTitle: string;
    height: number;
    id: string;
    data?: any;
    keyIndex?: number;
    width?: number;
    YMax?: number;
    xRange?: number;
    yAxisFrom?: number;
    yAxisTo?: number;
    xMax?: number;
    isDarkTheme?: boolean;
    changeYAxisRange?: (from: number, to: number) => void;
    sliderOnChange?: (slideVal: number) => void;
}

const CHART_MARGIN = {
    left: 45,
    top: 40,
    right: 10,
    bottom: 90,
};

const menuIcon: IIconProps = {
    iconName: "Edit",
    style: {
        fontSize: 18,
    },
};

const theme = getTheme();
export class VerticalScatterChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);
        this.state = {
            legendPoints: [],
            showScrollX: false,
            visibleCalloutId: -1,
        };
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }
    private _calloutTarget: HTMLElement | null | undefined;
    public render() {
        return (
            <DocumentCardDetails
                key={`document_${this.props.keyIndex}`}
                className={"documentcard_Latency"}
            >
                <DocumentCardTitle
                    className="overview__title_latency"
                    title={`${this.props.chartTitle}`}
                />
                <Stack className="overview__mani_latency" horizontal>
                    <div className="seriesDesc">
                        {this.props.data!.map((series: any, index: any) => {
                            return (
                                <div
                                    key={`legend_${index}`}
                                    className="desc"
                                    style={{
                                        color: this.props.isDarkTheme
                                            ? "white"
                                            : "black",
                                    }}
                                >
                                    <i
                                        style={{
                                            backgroundColor: series.color,
                                        }}
                                    ></i>
                                    <span>{series.legend}</span>
                                </div>
                            );
                        })}
                    </div>

                    <Stack horizontal verticalAlign="center">
                        <div
                            style={{
                                marginRight: "10px",
                                color: "grey",
                            }}
                        >
                            |
                        </div>
                        <Label>X Range:</Label>
                        <Slider
                            step={10}
                            min={0}
                            max={this.props.xMax}
                            defaultValue={this.props.xRange}
                            styles={{
                                root: {
                                    width: "280px",
                                    marginRight: "0",
                                },
                                valueLabel: {
                                    width: "auto",
                                },
                            }}
                            onChange={(value: number) =>
                                this.props.sliderOnChange!(value)
                            }
                        />
                    </Stack>
                </Stack>

                <Stack
                    style={{
                        backgroundColor: this.props.isDarkTheme
                            ? theme.palette.neutralDark
                            : theme.palette.neutralLighterAlt,
                    }}
                >
                    <IconButton
                        menuIconProps={menuIcon}
                        className="editYIcon"
                        onClick={(evt) => {
                            this._calloutTarget = evt.target as HTMLElement;
                            this.setState({
                                visibleCalloutId: this.props.keyIndex!,
                            });
                        }}
                    />
                    <div
                        className="drawingContainer"
                        style={{
                            color: this.props.isDarkTheme ? "white" : "black",
                        }}
                    >
                        <div
                            id={this.props.id}
                            className="drawingBoard"
                            style={{
                                color: this.props.isDarkTheme
                                    ? "white"
                                    : "black",
                            }}
                        ></div>
                    </div>
                </Stack>
            </DocumentCardDetails>
        );
    }
    private _drawChart(): void {
        let drawingBoard = document.getElementById(this.props.id);

        if (drawingBoard && drawingBoard.hasChildNodes()) {
            drawingBoard.innerHTML = "";
        }

        let width = this.props.width;
        if (width === undefined) {
            width = CHART_MARGIN.left + CHART_MARGIN.right;
            const container = drawingBoard?.parentElement;
            if (container && container?.offsetWidth > width) {
                width = container.offsetWidth - 1;
            }
        }

        const scatterData = this.props.data.map((item: any) => {
            let itemWithFilter = item.data.filter((it: any) => {
                return (
                    it.x >= 0 &&
                    it.x <= this.props.xRange! &&
                    it.y >= this.props.yAxisFrom! &&
                    it.y <= this.props.yAxisTo!
                );
            });
            return {
                color: item.color,
                legend: item.legend,
                data: itemWithFilter,
            };
        });

        const scatterChartData: IScatterChartDataPoint[][] = [];
        this.props.data.flatMap((item: any, index: number) => {
            scatterChartData[index] = [];
            scatterChartData[index].push(item.data);
            return scatterChartData;
        });
        let height = 250;
        let margin = { top: 30, right: 20, bottom: 20, left: 50 },
            innerWidth = width - margin.left - margin.right,
            innerHeight = height - margin.top - margin.bottom;

        let minX = 0;
        let maxX = this.props.xRange || 0;
        let minY = this.props.yAxisFrom || 0;
        let maxY = this.props.yAxisTo || 0;
        let scale_x = d3
            .scaleLinear()
            .domain([minX, maxX])
            .range([0, innerWidth]);
        let scale_y = d3
            .scaleLinear()
            .domain([maxY, minY])
            .range([0, innerHeight]);

        const svg = d3
            .select("#" + this.props.id)
            .append("svg")
            .attr("width", width)
            .attr("height", height);
        for (let i = 0; i < scatterData.length; i++) {
            svg.append("g")
                .attr("transform", "translate(50,30)")
                .selectAll("dot")
                .attr("transform", "translate(50,30)")
                .data(scatterData[i].data)
                .enter()
                .append("circle")
                .attr("r", 6)
                .attr("cx", function (d: any, j) {
                    return scale_x(d.x);
                })
                .attr("cy", function (d: any, j) {
                    return scale_y(d.y);
                })
                .attr("fill", function (d, j) {
                    return scatterData[i].color;
                });
        }

        let xAxis = d3.axisBottom(scale_x);
        let yAxis = d3.axisLeft(scale_y).ticks(20, "s");
        svg.append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" +
                    margin.left +
                    "," +
                    (height - margin.bottom) +
                    ")"
            )
            .call(xAxis);

        svg.append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" + margin.left + "," + margin.top + ")"
            )
            .call(yAxis);
        let yInner = d3.axisLeft(scale_y).tickSize(-width);

        svg.append("g")
            .attr("class", "inner_line inner_line_y")
            .attr("transform", "translate(" + margin.left + ",30)")
            .style("opacity", 0.08)
            .call(yInner);
        let xInner = d3.axisBottom(scale_x).tickSize(-height);
        svg.append("g")
            .attr("div", "inner_line_x")
            .attr(
                "transform",
                "translate(" +
                    margin.left +
                    "," +
                    (height - margin.top + 15) +
                    ")"
            )
            .style("opacity", 0.08)
            .call(xInner);
    }
}
