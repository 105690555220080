import React from "react";
import { VerticalBasePage } from "./VerticalBasePage";
import { VerticalErrorViewWithRedux, VerticalOverview } from "../../Views";
import { Typename, Workspaces } from "../../DataContract";

export class BusinessCardPage extends VerticalBasePage {
    public render() {
        const { linkData, records, selectedKey, isForReport, deepLinkHandler } =
            this.props;

        const { dataItems } = this.state;
        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };

        return (
            <>
                {selectedKey === "overviewBusinessCardMetrics" && (
                    <VerticalOverview
                        dataItems={dataItems}
                        records={records}
                        isForReport={isForReport}
                        VerticalImageViewDeepLinkHandler={deepLinkHandler}
                        workspace={Workspaces.BusinessCard}
                        saveSetKey={`${Workspaces.BusinessCard}_${Typename.VerticalOverview}`}
                        isDarkTheme={this.props.isDarkTheme}
                        pageName="BusinessCardOverview"
                    />
                )}
                {selectedKey === "overviewBusinessCardFieldMetrics" && (
                    <VerticalErrorViewWithRedux
                        saveSetKey={`${Workspaces.BusinessCard}_${Typename.VerticalErrorView}`}
                        records={records}
                        metricItems={dataItems}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectField={linkSetting?.toSelectField}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
            </>
        );
    }
}
