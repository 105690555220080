import { IPivotStyles } from "office-ui-fabric-react";

export const IPivotItemStyles: Partial<IPivotStyles> = {
    root: {
        background: "#f3f2f1",
        display: "flex",
        justifyContent: "space-between",
        overflowX: "auto",
        overflowY: "hidden",
        paddingBottom: "6px",
    },
    linkIsSelected: {
        backgroundColor: "#ffffff",
        color: "rgb(0, 120, 212)",
        fontWeight: "bold",
    },
    itemContainer: {
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
    },
};

export const IPivotItemDarkStyles: Partial<IPivotStyles> = {
    root: {
        background: "#848484",
        display: "flex",
        justifyContent: "space-between",
        overflowX: "auto",
        overflowY: "hidden",
        paddingBottom: "6px",
    },
    linkIsSelected: {
        backgroundColor: "#000000",
        color: "white",
        fontWeight: "bold",
    },
    itemContainer: {
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
    },
};
