import React from "react";
import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import _ from "lodash";
import {
    ColumnValueType,
    TableColumn,
    TableList,
} from "../../Controls/TableList";
import { Consumer } from "../../Layout";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

interface MathMetrics {
    header: string[];
    rows: any[];
}

interface MathFieldMetrics {
    [key: string]: string | number;
}

interface IKvpDataItem {
    data?: IDataItem;
    fieldMetrics: MathFieldMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: MathMetrics;
}

interface IState extends ICommonState<MathMetrics> {
    renderData?: any; // [string, [string, IDataItem[]][]][]
    dataItems: IDataItem[];
    mathSymErrColumns: TableColumn[];
    selectedColumns: string[];
}

interface IProps extends ICommonProps {
    storageVersion?: string;
}

export class OcrMathSymbolErrorOverview extends CommonView<
    IProps,
    IState,
    MathMetrics
> {
    private exportColumns: TableColumn[] = [];
    constructor(props: IProps) {
        super(props);

        this.state = {
            dataItems: [],
            mathSymErrColumns: [],
            selectedColumns: [],
            matchDatasetVersion: true,
        };
    }

    public render() {
        return (
            <div className="overview">
                <FullScreen>{this._renderAsTable()}</FullScreen>
            </div>
        );
    }

    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            this.exportColumns,
            "MathOcrOverview"
        );
    };

    componentDidUpdate(prevProps: ICommonProps, prevState: IState) {
        if (!_.isEqual(this.props.records, prevProps.records)) {
            this.queryMetricsResult();
        }
        if (
            !_.isEqual(this.state.dataItems, prevState.dataItems) ||
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            const data = this._prepareRenderTableData();
            const columns = this._columnsUpdate(
                Array.from(
                    new Set(
                        this.state.dataItems
                            .map((item) => {
                                const header = item?.metrics.header as string[];
                                return header ? header : [];
                            })
                            .flatMap((col) => col)
                    )
                )
            );
            this.setState({
                renderData: data,
                mathSymErrColumns: columns,
            });
            store.dispatch(
                updateStateAction({
                    saveKey: `${Workspaces.OcrMath}_${Typename.MATHSYMBOLMetrics}`,
                    columns: columns,
                    selectedItems: columns.map((column) => column.name),
                })
            );
        }
    }

    private _columnsUpdate(columnNames: string[]) {
        const columns = columnNames.map((col) => {
            let valueType = ColumnValueType.Number;
            let distinctStr = false;
            if (col === "symbol") {
                valueType = ColumnValueType.String;
                distinctStr = true;
            }
            return {
                key: col,
                name: col,
                minWidth: 100,
                maxWidth: 200,
                fieldName: col,
                isResizable: true,
                valueType: valueType,
                distinctStr: distinctStr,
                maxDecimalPlaces: 3,
            };
        });

        return columns as TableColumn[];
    }

    queryMetricsResult() {
        this._queryMetricsResult("symbol_error_rate.json");
    }

    private _renderAsTable() {
        const { records } = this.props;
        this.exportColumns = [];
        this.exportData = [];
        if (this.state.dataItems.length > 0) {
            const data = this.state.renderData || [];
            return data.length !== 0 ? (
                data.map(
                    (
                        [datasetName, recordMetrics, columns]: any,
                        index: any
                    ) => {
                        const tableData = recordMetrics.map(
                            ([_, fieldMetricsItems]: any) => {
                                let symbol = "";
                                const fieldMetricsArr = fieldMetricsItems.map(
                                    (fieldMetricsItem: any) => {
                                        const fieldMetrics =
                                            fieldMetricsItem.fieldMetrics;
                                        if (
                                            fieldMetrics &&
                                            fieldMetrics.symbol &&
                                            symbol === ""
                                        ) {
                                            symbol =
                                                fieldMetrics.symbol as string;
                                        }
                                        return fieldMetrics;
                                    }
                                );

                                fieldMetricsArr.forEach((fieldMetric: any) => {
                                    if (!fieldMetric.symbol) {
                                        fieldMetric.symbol = symbol;
                                    }
                                });

                                return fieldMetricsArr;
                            }
                        );

                        if (tableData && tableData.length > 0) {
                            if (this.exportColumns.length === 0) {
                                this.exportColumns = columns;
                            }

                            this.exportData.push([datasetName, tableData]);
                        }

                        const showColumns: TableColumn[] =
                            this.state.mathSymErrColumns.filter(
                                (value) =>
                                    this.state.selectedColumns.findIndex(
                                        (col) => col === value.name
                                    ) !== -1
                            );

                        return (
                            <Consumer key={`consumerKey_${index}`}>
                                {(value) => {
                                    return (
                                        <TableList<MathFieldMetrics>
                                            key={`Math_${index}`}
                                            evalData={tableData}
                                            columns={showColumns}
                                            tableTitle={datasetName}
                                            downloadTableTitle={datasetName}
                                            isWiderCell={true}
                                            evalDataCount={records.length}
                                            isDarkTheme={value}
                                        />
                                    );
                                }}
                            </Consumer>
                        );
                    }
                )
            ) : (
                <NoDataTip>No OCR Math Symbol Metrics Generated </NoDataTip>
            );
        } else {
            return <NoDataTip>No OCR Math Symbol Metrics Generated</NoDataTip>;
        }
    }

    private _prepareRenderTableData() {
        const { matchDatasetVersion } = this.state;
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    const dataset = matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName;
                    return (
                        item.recordIndex === recordIndex &&
                        dataset === datasetName
                    );
                });
                return item;
            });

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const rows = item?.metrics.rows;
                        return rows ? rows.map((row) => row[0]) : [];
                    })
                )
            );

            const groupData = entities.map((field, index) => {
                // retrieve field data
                const metricsItems = items
                    .map((item) => {
                        let fieldMetrics: MathFieldMetrics = {};
                        const rows = item?.metrics.rows;
                        const header = item?.metrics.header as string[];
                        if (rows && rows.length > 0) {
                            for (const key of header) {
                                const keyIndex = header.indexOf(key);
                                if (rows[index]) {
                                    fieldMetrics[key] = this._formaterNumber(
                                        rows[index][keyIndex]
                                    );
                                } else {
                                    fieldMetrics[key] = "";
                                }
                            }
                        }

                        return {
                            data: item,
                            fieldMetrics: fieldMetrics,
                        } as IKvpDataItem;
                    })
                    .filter((metricsItem) => !_.isEmpty(metricsItem));
                return [field, metricsItems] as [string, IKvpDataItem[]];
            });

            return [datasetName, groupData, this.state.mathSymErrColumns] as [
                string,
                [string, IKvpDataItem[]][],
                TableColumn[]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _formaterNumber(number: any) {
        if (Number(number) === -1) {
            return NaN;
        }
        return number;
    }
}
