import * as React from "react";
import "./Pages.scss";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    Modal,
    Pivot,
    PivotItem,
    PivotLinkFormat,
    Stack,
    getTheme,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DialogType,
} from "@fluentui/react";
import { capitalize } from "lodash";
import { mapStateToProps } from "../../store/mapFuncs";
import {
    OcrBackendPage,
    OcrCheckboxPage,
    OcrPage,
    OcrTablePage,
    ReleaseTestPage,
    InvoicePage,
    KvpPage,
    CustFormPage,
    CustomDocReleaseTestPage,
    ReceiptPage,
    BusinessCardPage,
    IdCardPage,
    VerticalTelemetry,
    OcrMathPage,
    OcrPodPage,
    QueryFieldPage,
} from "./Scenarios";
import {
    EvalInfoKeys,
    Record,
    RecordFactory,
    URL_PARAM_NAME_REPORTONLY,
    URL_PARAM_NAME_TABKEY,
    Workspaces,
} from "../DataContract";
import { setParamsToUrl } from "../Utils";

import { Workspace } from "./Workspace";
import { OrderType } from "../Controls/Common/ChangeOrderBar";
import _ from "lodash";
import Expandbutton from "./common/ExpandButton";
import ExportExcelbutton from "./common/ExportExcel";
import { Consumer } from "../Layout";
import { IndustryPrebuiltPage } from "./Scenarios/IndustryPrebuiltPage";
import { HealthDocPage } from "./Scenarios/HealthDocPage";
import { OcrBarcodePage } from "./Scenarios/OcrBarcodePage";
import { DocClassifierPage } from "./Scenarios/DocClassifierPage";
import { Setting } from "../Controls/Setting";
import { store } from "../../store";
import { updateStateAction } from "../../store/reducers/setting";
import ViewTypeButton from "./common/ViewType";
import SettinbButton from "./common/SettingButton";
import {
    updateExpandAction,
    updateSelectKeyAction,
    updateSelectSubpivoteAction,
    updateWorkSpaceAction,
} from "../../store/reducers";
import { ExperimentalPrebuiltPage } from "./Scenarios/ExperimentalPrebuiltPage";
import { OcrFigurePage } from "./Scenarios/OcrFigurePage";
import { hideLoading } from "../Utils/LoadingUtil";
import { SCENARIO_PIVOTS } from "./common/MainPageConfig";
import { RecordsSelector } from "./common/MainPageRecordsSelector";
import { Container } from "../Controls/Container";

interface IState {
    records: Record[];
    isReport: boolean;
    isSettingOpen: boolean;
    showModal: boolean;
    selectedAlgoArr: string[];
    selectedKey: string;
    visibleCalloutId: string;
    linkData?: any;
    hasSameRecord: boolean;
}

interface IRouteParam {
    id: string;
    workspace: string;
}

const theme = getTheme();

const modalPropsStyles = { main: { maxWidth: 600 } };
const dialogContentProps = {
    type: DialogType.normal,
    title: "Warning Details",
    subText: "You've selected duplicate records, and we'll remove them.",
    showCloseButton: false,
};

const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles,
};

type PropsType = RouteComponentProps & ReturnType<typeof mapStateToProps>;

class MainPage extends React.Component<PropsType, IState> {
    private _recordIds: string[] = [];

    private _workspace: string = "";

    private selectedRecordIds: Set<string> = new Set();

    constructor(props: PropsType) {
        super(props);

        const params = this.props.match.params as IRouteParam;

        const decodeIds = decodeURIComponent(params.id);
        this._recordIds = decodeIds.split(",");
        this._workspace = params.workspace;

        const isForReport = new URLSearchParams(this.props.location.search).get(
            URL_PARAM_NAME_REPORTONLY
        );

        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.deepLinkHandler = this.deepLinkHandler.bind(this);
        this.updateTabTitle = this.updateTabTitle.bind(this);

        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get(URL_PARAM_NAME_TABKEY);

        store.dispatch(
            updateStateAction({
                isReport: isForReport === "true",
            })
        );
        store.dispatch(updateWorkSpaceAction(this._workspace));

        this.state = {
            records: [],
            showModal: false,
            selectedKey: query ?? SCENARIO_PIVOTS[this._workspace][0].itemKey,
            isSettingOpen: false,
            isReport: isForReport === "true",
            visibleCalloutId: "-1",
            selectedAlgoArr: [],
            hasSameRecord:
                new Set(this._recordIds).size !== this._recordIds.length,
        };
    }

    public render() {
        const { showModal, selectedKey, hasSameRecord } = this.state;

        this.updateTabTitle();

        return (
            <>
                {hasSameRecord ? (
                    <Dialog
                        hidden={false}
                        dialogContentProps={dialogContentProps}
                        modalProps={modalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton
                                onClick={this.removeDuplicatesRecord}
                                text="Remove"
                            />
                        </DialogFooter>
                    </Dialog>
                ) : (
                    <>
                        <Consumer>
                            {(value) => {
                                return (
                                    <Modal
                                        isOpen={showModal}
                                        onDismiss={() =>
                                            this.setState({ showModal: false })
                                        }
                                        containerClassName="catalogContainer"
                                        styles={{
                                            scrollableContent: {
                                                overflowY: "initial",
                                                height: "100%",
                                            },
                                        }}
                                    >
                                        <Workspace
                                            isDarkTheme={value}
                                            isModal={true}
                                            workspace={this._workspace}
                                            onItemClicked={(record) => {
                                                this._addNewRecord(record);
                                            }}
                                        />
                                    </Modal>
                                );
                            }}
                        </Consumer>
                        <>
                            <Stack
                                id="selectMenu"
                                horizontal
                                verticalAlign="center"
                                styles={{
                                    root: {
                                        borderBottom: `1px solid ${theme.palette.neutralLighter}`,
                                    },
                                }}
                            >
                                <Pivot
                                    id="selectPivot"
                                    selectedKey={selectedKey}
                                    onLinkClick={this.handleLinkClick}
                                    linkFormat={PivotLinkFormat.links}
                                >
                                    {this._renderPivotByWorkspace()}
                                </Pivot>

                                <ViewTypeButton selectedKey={selectedKey} />

                                {this._renderExpandButton()}
                                {this._renderExportExcelButton()}
                                <Stack
                                    horizontal
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "6%",
                                    }}
                                >
                                    {this._renderSettingButton()}
                                </Stack>
                            </Stack>
                            <>{this._renderRecordsSelector()}</>
                            {<Container>{this._renderPage()}</Container>}
                            {this.state.isSettingOpen && this.settingPanel()}
                        </>
                    </>
                )}
            </>
        );
    }

    private _renderRecordsSelector() {
        const { records, selectedAlgoArr } = this.state;

        return (
            <RecordsSelector
                records={records}
                workspace={this._workspace}
                selectedRecordIds={this.selectedRecordIds}
                selectedAlgoArr={selectedAlgoArr}
                visibleCalloutId={this.state.visibleCalloutId}
                onAdd={() =>
                    this.setState({
                        showModal: true,
                    })
                }
                removeRecord={this._removeRecord}
                onRecordOptionChanged={this._onRecordOptionChanged}
                onChangevisibleCalloutId={(id) => {
                    this.setState({
                        visibleCalloutId: id,
                    });
                }}
                onChangeOrder={this._onChangeOrder}
                onCalloutDismiss={this._onCalloutDismiss}
                setRecordTag={this._setRecordTag}
                onSelected={this._onSelected}
            />
        );
    }

    private _renderPage() {
        const { isReport, linkData, selectedKey } = this.state;

        let records = _.cloneDeep(this.state.records);
        if (this.selectedRecordIds) {
            records = records.filter((r) => this.selectedRecordIds.has(r.id));
        }

        let storageVersion = "v1";

        const detailVersionArray = records.map((r) => {
            return (
                r
                    .getDetails()
                    .filter(
                        (d) => d.getRawProp<string>("storageVersion") === "v2"
                    ).length > 0
            );
        });

        const detailsV2 = records
            .map((r) => r.getDetails())
            .flatMap((d) => d)
            .filter((o) => o.getRawProp<string>("storageVersion") === "v2");

        if (detailsV2.length > 0) {
            storageVersion = "v2";
        }

        const isDisplay =
            detailVersionArray.filter((v) => v === true).length ===
                records.length ||
            detailVersionArray.filter((v) => v === false).length ===
                records.length;

        if (records && records.length > 0) {
            switch (this._workspace) {
                case Workspaces.Ocr:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.Invoice:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <InvoicePage
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.Kvp:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <KvpPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        storageVersion={storageVersion}
                                        display={isDisplay}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.Receipt:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <ReceiptPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.BusinessCard:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <BusinessCardPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.IdCard:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <IdCardPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.IndustryPrebuilt:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <IndustryPrebuiltPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.HealthDoc:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <HealthDocPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.ExperimentalPrebuilt:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <ExperimentalPrebuiltPage
                                        isForReport={isReport}
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.CustomForm:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <CustFormPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );
                case Workspaces.QueryField:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <QueryFieldPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        linkData={linkData}
                                        deepLinkHandler={this.deepLinkHandler}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );
                case Workspaces.OcrTable:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrTablePage
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.OcrCheckbox:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrCheckboxPage
                                        records={records}
                                        selectedKey={selectedKey}
                                        storageVersion={storageVersion}
                                        display={isDisplay}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.OcrBackend:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrBackendPage
                                        isDarkTheme={value}
                                        records={records}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.ReleaseTest:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <ReleaseTestPage
                                        selectedKey={this.state.selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.CustomDocReleaseTest:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <CustomDocReleaseTestPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.VerticalTelemetry:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <VerticalTelemetry
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.OcrPod:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrPodPage
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.OcrMath:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrMathPage
                                        records={records}
                                        selectedKey={selectedKey}
                                        storageVersion={storageVersion}
                                        display={isDisplay}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.OcrBarcode:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrBarcodePage
                                        selectedKey={selectedKey}
                                        isReport={isReport}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                case Workspaces.DocClassifier:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <DocClassifierPage
                                        selectedKey={selectedKey}
                                        isReport={isReport}
                                        records={records}
                                        isDarkTheme={value}
                                    ></DocClassifierPage>
                                );
                            }}
                        </Consumer>
                    );
                case Workspaces.OcrFigure:
                    return (
                        <Consumer>
                            {(value: any) => {
                                return (
                                    <OcrFigurePage
                                        selectedKey={selectedKey}
                                        records={records}
                                        isDarkTheme={value}
                                    />
                                );
                            }}
                        </Consumer>
                    );

                default:
                    return <></>;
            }
        }
    }

    removeDuplicatesRecord = () => {
        this._recordIds = Array.from(new Set(this._recordIds));
        this.setState({ hasSameRecord: false }, () => {
            this.loadRecords();
        });

        const id = this._recordIds.join(",");
        const oldUrl = _.cloneDeep(window.location.href);
        const newUrl = oldUrl.replace(
            window.location.pathname,
            `/eval/${this._workspace}/id/${id}`
        );
        window.history.replaceState("", "", newUrl);
    };

    public async componentDidMount() {
        document.addEventListener("scroll", this._onCalloutDismiss);

        if (!this.state.hasSameRecord) {
            this.loadRecords();
        }
    }

    loadRecords = () => {
        const { isReport, selectedKey } = this.state;
        Promise.all(
            this._recordIds.map((id) =>
                RecordFactory.fetch(this._workspace, id)
            )
        ).then((records) => {
            const urlParams = new URLSearchParams(window.location.search);
            const query = urlParams.get(URL_PARAM_NAME_TABKEY);

            const newUrl = setParamsToUrl([
                [URL_PARAM_NAME_TABKEY, selectedKey],
                [URL_PARAM_NAME_REPORTONLY, isReport],
            ]);

            window.history.replaceState({}, selectedKey, newUrl);

            const selectedAlgos = records.map((r) => {
                let selectedAlgo = "";
                const details = r.getDetails();
                if (details && details.length > 0) {
                    selectedAlgo = details[0].getRawProp(
                        EvalInfoKeys.Algorithm.toLowerCase()
                    ) as string;
                }

                return selectedAlgo;
            });

            this.selectedRecordIds = new Set(records.map((r) => r.id));
            this.setState({
                records: records,
                selectedAlgoArr: selectedAlgos,
                selectedKey:
                    query ?? SCENARIO_PIVOTS[this._workspace][0].itemKey,
            });
        });
    };

    public componentWillUnmount() {
        document.removeEventListener("scroll", this._onCalloutDismiss);
        hideLoading();
    }

    private _renderPivotByWorkspace() {
        const pivotList = SCENARIO_PIVOTS[this._workspace];
        return pivotList.map((pivot, index) => {
            return (
                <PivotItem
                    headerText={pivot.headerText}
                    alwaysRender={pivot.alwaysRender}
                    itemKey={pivot.itemKey}
                    key={index}
                />
            );
        });
    }

    private async _addNewRecord(record: Record) {
        const arr = [...this.state.records, record];

        const front = arr.splice(0, 1)[0];
        const compareTo = arr;
        front.onCompareWith(compareTo);
    }

    private _removeRecord = (recordIndex: number) => {
        let arr = this.state.records.slice();
        arr.splice(recordIndex, 1);

        const id = arr.map((record) => encodeURIComponent(record.id)).join(",");
        const oldUrl = _.cloneDeep(window.location.href);
        const newUrl = oldUrl.replace(
            window.location.pathname,
            `/eval/${this._workspace}/id/${id}`
        );
        window.history.replaceState("", "", newUrl);
        const ids = arr.map((r) => r.id);
        if (ids.length === 1) {
            this.selectedRecordIds = new Set(ids);
        } else {
            this.selectedRecordIds = new Set(
                [...this.selectedRecordIds].filter((x) => new Set(ids).has(x))
            );
        }

        this.setState({
            records: arr,
        });
    };

    private _onRecordOptionChanged = (
        recordIndex: number,
        name: string,
        value: string
    ) => {
        const { selectedAlgoArr } = this.state;
        const records = this.state.records.slice();
        let record = records[recordIndex];
        record.setDetailsFilter(
            (recordDetail) =>
                recordDetail.getRawProp<string>(name.toLowerCase()) === value
        );

        if (
            name === EvalInfoKeys.Algorithm &&
            recordIndex < selectedAlgoArr.length
        ) {
            selectedAlgoArr[recordIndex] = value;
            this.setState({
                records: records,
                selectedAlgoArr: selectedAlgoArr,
            });
        } else {
            this.setState({ records: records });
        }
    };

    private handleLinkClick(item?: PivotItem): any {
        if (item) {
            const newUrl = setParamsToUrl([
                [URL_PARAM_NAME_TABKEY, item.props.itemKey],
                [URL_PARAM_NAME_REPORTONLY, this.state.isReport],
            ]);

            window.history.replaceState(null, "", newUrl);
            this.setState({
                visibleCalloutId: "-1",
                selectedKey: item.props.itemKey!,
                linkData: undefined,
            });
            store.dispatch(updateSelectSubpivoteAction("Overview"));
            store.dispatch(updateSelectKeyAction(item.props.itemKey));
            store.dispatch(updateExpandAction(true));
        }
    }
    private getSettingControl = (isSettingOpen: boolean): any => {
        this.setState({
            isSettingOpen: isSettingOpen,
        });
    };

    private deepLinkHandler(key: string, linkData: any): void {
        const newUrl = setParamsToUrl([
            [URL_PARAM_NAME_TABKEY, key],
            [URL_PARAM_NAME_REPORTONLY, this.state.isReport],
        ]);
        window.history.replaceState({}, key, newUrl);
        this.setState({
            selectedKey: key,
            linkData: linkData,
        });
    }

    private updateTabTitle(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get(URL_PARAM_NAME_TABKEY);
        let metrics = "";
        if (!query) {
            metrics =
                this._workspace === "ocr"
                    ? SCENARIO_PIVOTS[this._workspace][1].headerText
                    : SCENARIO_PIVOTS[this._workspace][0].headerText; //This will be removed after data highlight page data is ready
        } else {
            const selectMetrics = SCENARIO_PIVOTS[this._workspace].filter(
                (pivot) => pivot.itemKey === query
            );
            metrics =
                selectMetrics.length > 0 ? selectMetrics[0].headerText : "";
        }
        const tagArr = this.state.records.map((record) =>
            record.name.substring(0, 15)
        );
        const tagStr = tagArr.join(" & ");
        document.title = `${capitalize(this._workspace)}-${metrics} (${
            this.state.records.length
        } - ${tagStr})`;
    }
    private _onCalloutDismiss = (): void => {
        if (this.state.visibleCalloutId !== "-1") {
            this.setState({
                visibleCalloutId: "-1",
            });
        }
    };
    private _setRecordTag = (
        record: Record,
        tags: string[],
        itemId: string
    ) => {
        record.setRecordTags(itemId, tags, this._workspace);
        this.setState({
            visibleCalloutId: "-1",
        });
    };

    private _onSelected = (id: string, isChecked: boolean) => {
        if (isChecked) {
            this.selectedRecordIds.add(id);
        } else {
            const index = this.selectedRecordIds.has(id);
            if (index) {
                this.selectedRecordIds.delete(id);
            }
        }

        this.forceUpdate();
    };

    private _onChangeOrder = (type: OrderType, recordId: string) => {
        const items = this.state.records.slice();
        const currentIndex = items.findIndex(
            (record) => record.id === recordId
        );
        const changeIndex = type + currentIndex;
        const tempItem = items[currentIndex];
        items[currentIndex] = items[changeIndex];
        items[changeIndex] = tempItem;
        const id = items
            .map((record) => encodeURIComponent(record.id))
            .join(",");
        const oldUrl = _.cloneDeep(window.location.href);
        const newUrl = oldUrl.replace(
            window.location.pathname,
            `/eval/${this._workspace}/id/${id}`
        );
        window.history.replaceState("", "", newUrl);

        const selectedAlgos = items.map((r) => {
            let selectedAlgo = "";
            const details = r.getDetails();
            if (details && details.length > 0) {
                selectedAlgo = details[0].getRawProp(
                    EvalInfoKeys.Algorithm.toLowerCase()
                ) as string;
            }

            return selectedAlgo;
        });
        this.setState({
            records: items,
            selectedAlgoArr: selectedAlgos,
        });
    };

    private _renderExpandButton = () => {
        const { selectedKey } = this.state;

        return (
            <Expandbutton
                workspace={this._workspace}
                selectedKey={selectedKey}
            />
        );
    };

    private _renderExportExcelButton = () => {
        const { selectedKey } = this.state;

        return (
            <ExportExcelbutton
                workspace={this._workspace}
                selectedKey={selectedKey}
            />
        );
    };

    private _renderSettingButton = () => {
        const { selectedKey } = this.state;

        return (
            <SettinbButton
                disableSetting={this.props.disableSetting}
                workspace={this._workspace}
                selectedKey={selectedKey}
                onClick={() => {
                    this.setState({
                        isSettingOpen: true,
                    });
                }}
            />
        );
    };

    private settingPanel = () => {
        return (
            <Setting
                isSettingOpen={this.state.isSettingOpen}
                getSettingControl={this.getSettingControl}
            ></Setting>
        );
    };
}

export const MainPageWithRedux = connect(mapStateToProps)(MainPage);
