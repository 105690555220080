import { ColumnValueType, TableColumn } from "../../../Controls/TableList";
import { Workspaces } from "../../../DataContract";

export const IMAGE_ID_KEY = "imageId";
export const STRING_TYPE_KEYS = [IMAGE_ID_KEY, "category"];
export const URLG_TYPE_KEYS = ["imageUrl"];

const OCR_DEFAULT_COL_KEY = [
    "_ocrNativePerfNativeMetrics.LineCount",
    "_ocrMs",
    "_ocrNativePerfNativeMetrics.TotalMs",
    "_ocrNativePerfNativeMetrics.DetectedLineCount",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.InferenceModelMs",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.InferenceOtherMs",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.SeglinkProposal",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.AdaptiveScalingPrepMs",
    "_ocrNativePerfNativeMetrics.DetectorMetrics.AdaptiveScalingWorkMs",
    "_ocrNativePerfNativeMetrics.NormalizerMetrics.TotalMs",
    "_ocrNativePerfNativeMetrics.AnalyzerMetrics.TotalMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedTotalMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedCnnLstmOtherMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedDecoderMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedLatticeMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedRejectionMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedConfidenceMs",
    "_ocrNativePerfNativeMetrics.RecognizerMetrics.AccumulatedCharacterMapMs",
];

const MATH_DEFAULT_COL_KEY = [
    "_ocrNativePerf.NativeMetrics.LineCount",
    "_ocrMs",
    "_ocrNativePerf.NativeMetrics.TotalMs",
    "_ocrNativePerf.NativeMetrics.DetectedLineCount",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.TotalMs",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.InferenceModelMs",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.InferenceOtherMs",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.SeglinkProposal",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.AdaptiveScalingPrepMs",
    "_ocrNativePerf.NativeMetrics.DetectorMetrics.AdaptiveScalingWorkMs",
    "_ocrNativePerf.NativeMetrics.NormalizerMetrics.TotalMs",
    "_ocrNativePerf.NativeMetrics.AnalyzerMetrics.TotalMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.WallTimeMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedTotalMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedCnnLstmOtherMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedDecoderMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedLatticeMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedRejectionMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedConfidenceMs",
    "_ocrNativePerf.NativeMetrics.RecognizerMetrics.AccumulatedCharacterMapMs",
];

export const DEFAULT_COL_KEY: { [key: string]: string[] } = {
    [Workspaces.Ocr]: OCR_DEFAULT_COL_KEY,
    [Workspaces.ReleaseTest]: OCR_DEFAULT_COL_KEY,
    [Workspaces.OcrMath]: MATH_DEFAULT_COL_KEY,
};

// prettier-ignore
const checkboxImageColumns: TableColumn[] = [
    { key: "imageId", name: "ImageId", isKey: true, valueType: ColumnValueType.String, minWidth: 150, maxWidth: 450, },
    { key: "_checkboxNativePerfTotalMS", name: "PerfTotalMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 400, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.CheckboxImageResizeMS", name: "CheckboxImageResizeMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 400, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.InferenceModelMS", name: "InferenceModelMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 400, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.InferenceOtherMS", name: "InferenceOtherMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 190, maxWidth: 400 },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.TotalMS", name: "TotalMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 190, maxWidth: 400 },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.UnaccountedMS", name: "UnaccountedMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 180, maxWidth: 400 },
    { key: "_ocrMS", name: "OCRMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 400, },
    { key: "_msraTableMS", name: "MsraTableMS", isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 400, },
];

export const ImagePerfViewClumns: { [key: string]: TableColumn[] } = {
    [Workspaces.OcrCheckbox]: checkboxImageColumns,
};
