import { Subject } from "rxjs";

interface ViewOptions {
    selectKey: string;
    disabledKey: string;
}

const mainPageObservanle = new Subject<ViewOptions>();

export default mainPageObservanle;
