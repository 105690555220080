import React from "react";

import {
    //Pivot
    Pivot,
    PivotItem,

    //Others
    Stack,
} from "@fluentui/react";

import { Record } from "../../DataContract";
import { BackendTextlineView } from "../../Views/OcrBackend/BackendTextlineView";
import { OcrBackendOverview } from "../../Views/OcrBackend/OcrBackendOverview";

interface IState {}

interface IProps {
    records: Record[];
    isDarkTheme?: boolean;
}

export class OcrBackendPage extends React.Component<IProps, IState> {
    private _backendtextlineInfo: React.RefObject<BackendTextlineView>;

    constructor(props: IProps) {
        super(props);

        this._backendtextlineInfo = React.createRef();
    }

    public render() {
        const { records } = this.props;
        return (
            <Stack verticalFill>
                <Pivot>
                    <PivotItem
                        headerText="Overview"
                        alwaysRender={true}
                        style={{ width: "100%" }}
                    >
                        <OcrBackendOverview records={records} />
                    </PivotItem>

                    <PivotItem headerText="By TextLine" alwaysRender={true}>
                        <BackendTextlineView
                            ref={this._backendtextlineInfo}
                            records={records}
                        />
                    </PivotItem>
                </Pivot>
            </Stack>
        );
    }
}
