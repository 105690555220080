import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";

import { ICommonProps } from "../Common/CommonMetrics";
import { OcrBarcodePerfByImageView } from "./OcrBarcodePerfByImageView";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { OcrBarcodePerfMetricsOverview } from "./OcrBarcodePerfMetricsOverview";

interface IState {
    selectedPivot: string;
}

export class OcrBarcodePerfMetrics extends React.Component<
    ICommonProps,
    IState
> {
    private linkData?: any;

    constructor(props: ICommonProps) {
        super(props);
        this._onPivotItemChanged = this._onPivotItemChanged.bind(this);

        this.state = {
            selectedPivot: "Overview",
        };
    }

    render(): React.ReactNode {
        const { isDarkTheme, isReport, records, saveSetKey } = this.props;

        return (
            <Pivot
                onLinkClick={this._onPivotItemChanged}
                selectedKey={this.state.selectedPivot}
                className="displayFlex"
                styles={{
                    itemContainer: {
                        height: "100%",
                        overflow: "hidden",
                    },
                }}
            >
                <PivotItem
                    headerText="Overview"
                    itemKey="Overview"
                    className="displayFlex"
                >
                    <OcrBarcodePerfMetricsOverview
                        {...this.props}
                        setSelectedSubPivot={this.setSelectedSubPivot}
                    />
                </PivotItem>
                <PivotItem
                    headerText="By Image"
                    itemKey="ByImage"
                    className="displayFlex"
                >
                    <OcrBarcodePerfByImageView
                        records={records}
                        saveSetKey={saveSetKey}
                        isDarkTheme={isDarkTheme}
                        isReport={isReport}
                        linkData={this.linkData}
                    />
                </PivotItem>
            </Pivot>
        );
    }

    private _onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }
        if (item) {
            this.linkData = undefined;
            this.changeSelectedSubPivot(item?.props.itemKey!);
        }
    }

    setSelectedSubPivot = (selectPivot: string, linkData?: any) => {
        this.linkData = linkData;
        this.changeSelectedSubPivot(selectPivot);
    };

    changeSelectedSubPivot = (selectPivot: string) => {
        this.setState({
            selectedPivot: selectPivot,
        });

        store.dispatch(updateSelectSubpivoteAction(selectPivot));
    };
}
