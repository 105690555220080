import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { ColumnValueType } from "../../Controls";
import { DatasetSet } from "../../DataContract";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { mergeDataItems, tryFindMatchedItems } from "./LatencyUtil";
import {
    IChartDataItem,
    ILatencyDataItem,
    languageToScript,
    OcrLatencyChartData,
    percentileChartPoints,
    ShortenMetricKeyName,
} from "./Interface";
import { MetricAnalysisDefaultColumns } from "./MetricAnalysisColumn";

interface IProps extends ICommonProps {
    dataItems: ILatencyDataItem[];
    includesLongTail: boolean;
    crossLanguage: boolean;
    defaultCrossLanguage?: string;
    defaultCategory?: string;
}

export enum LatencyType {
    Overview = 1,
    MetricAnalysis,
    ComponentLatency,
    ByImage,
}

export abstract class CommonAnalysisView<
    S extends ICommonState<M>,
    M
> extends CommonView<IProps, S, M> {
    _prepareAnalysisRenderData(
        matchDatasetVersion: boolean,
        crossLanguage: boolean,
        latencyType: LatencyType = 2
    ) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const { defaultCrossLanguage = "Unknown" } = this.props;

        const dataRecordNames = Array.from(
            new Set(
                datasets.map((dataset) => {
                    if (!crossLanguage) {
                        return matchDatasetVersion
                            ? dataset.displayFullName
                            : dataset.displayName;
                    } else {
                        let language = dataset.displayFullName.split(":")[0];
                        return (
                            languageToScript[language] ?? defaultCrossLanguage
                        );
                    }
                })
            )
        );

        const allColumnsSet = new Set<string>();

        const data = dataRecordNames.map((dataRecordName) => {
            const chartnames = new Array<string>();
            const items = this.props.records.map((_, recordIndex) => {
                let items = tryFindMatchedItems(
                    this.props.dataItems,
                    crossLanguage,
                    recordIndex,
                    matchDatasetVersion,
                    dataRecordName,
                    defaultCrossLanguage
                );
                // use the first item as merged target, use cloned data incase dup merge
                let mergedItem: any = items[0];
                if (crossLanguage) {
                    mergedItem = mergeDataItems(
                        items,
                        this.workSpace!,
                        this.props.defaultCategory,
                        latencyType
                    );
                }
                const latencyDataItem = percentileChartPoints(mergedItem);

                const percentiles = latencyDataItem?.metrics.percentile_latency;
                if (percentiles && percentiles.length > 0) {
                    for (const percentile of percentiles) {
                        if (!chartnames.includes(percentile.chartname)) {
                            chartnames.push(percentile.chartname);
                        }
                        if (
                            latencyType === 2 &&
                            !allColumnsSet.has(percentile.chartname)
                        ) {
                            allColumnsSet.add(percentile.chartname);
                        }
                    }
                }

                return latencyDataItem;
            });

            let showchartnames = chartnames;

            const groupData = showchartnames.map((chartname) => {
                // retrieve latency data
                const chartItems = items.map((item) => {
                    let chartData: OcrLatencyChartData | undefined;
                    if (!!item) {
                        const { metrics } = item;
                        if (!!metrics.percentile_latency) {
                            chartData = metrics.percentile_latency.find(
                                (chartData) => chartData.chartname === chartname
                            );
                        }
                    }

                    if (chartData === undefined) {
                        chartData = {
                            chartname: ShortenMetricKeyName(chartname),
                            chartYLabel: "",
                            chartXLabel: "",
                            chartYMaxAxis: 0,
                            chartPoints: [],
                        };
                    }

                    return {
                        data: item,
                        chartData: chartData,
                    } as IChartDataItem;
                });
                return [chartname, chartItems] as [string, IChartDataItem[]];
            });

            return [dataRecordName, groupData] as [
                string,
                [string, IChartDataItem[]][]
            ];
        });

        if (latencyType === 2) {
            const allColumns = Array.from(allColumnsSet).map((name) => {
                return {
                    key: name,
                    name: ShortenMetricKeyName(name),
                    fieldName: name,
                    valueType: ColumnValueType.Number,
                };
            });

            store.dispatch(
                updateStateAction({
                    columns: allColumns,
                    selectedItems: MetricAnalysisDefaultColumns[this.workSpace],
                })
            );
        }

        return data.filter(([_, groupData]) => groupData.length > 0);
    }
}
