import React from "react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import {
    ITableConfigs,
    ImageVisualizer,
    MetricsType,
    NumContrastPolicy,
    NumberFormat,
    TableHeader,
} from "../../Controls";
import { TableColumn, TableList, ColumnValueType } from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    IMergedMetrics,
    Workspaces,
    Typename,
} from "../../DataContract";
import "../Common/MetricStyle.scss";
import { IDropdownStyles, Modal } from "@fluentui/react";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import _ from "lodash";
import { OcrMetrics, OcrPolygons } from "../../Pages/Scenarios/OcrBarcodePage";
import { FigureDataset } from "../../DataContract/FigureDataset";
import { exportTableListData } from "../../Utils/ExportFile";
import { FullScreen } from "../Common/FullScreen";

interface FigureImageMetric extends IMetrics<IMetricUnit> {
    imageUrl: string;
}

interface IState extends IMetricState<FigureImageMetric> {
    entityList: string[]; // fieldList actually
    selectLanguage?: string;
    selectSubtype?: string;
    selectImageId?: string;
    clickNumber?: number;
    imageVisItems?: any[];
}

// prettier-ignore
const FIGURE_IMAGE_COLUMNS: TableColumn[] = [
    { key: "imageId",      name: "imageId",      fieldName: "imageId",      valueType: ColumnValueType.String, minWidth: 120, maxWidth: 160, isResizable: true, isKey: true, distinctStr: true, },
    { key: "num_gt",       name: "num_gt",       fieldName: "num_gt",       valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "num_pred",     name: "num_pred",     fieldName: "num_pred",     valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "matched_pred", name: "matched_pred", fieldName: "matched_pred", valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 200,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "matched_gt",   name: "matched_gt",   fieldName: "matched_gt",   valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 200,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "precision",    name: "precision%",   fieldName: "precision",    valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2, numberFormat: NumberFormat.Percentage },
    { key: "recall",       name: "recall%",      fieldName: "recall",       valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2, numberFormat: NumberFormat.Percentage },
    { key: "f1",           name: "f1%",          fieldName: "f1",           valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2, numberFormat: NumberFormat.Percentage },
    { key: "iou_mean",     name: "iou_mean",     fieldName: "iou_mean",     valueType: ColumnValueType.Number, minWidth: 70, maxWidth: 160,  isResizable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 },
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" },
};

interface IProps extends IMetricProps {
    selectLanguage?: string;
    subType?: string;
}

export class OcrFigureImageView extends MetricsView<
    IProps,
    IState,
    FigureImageMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            entityList: [],
            selectLanguage:
                this.props.selectLanguage ?? this.getLanguageList(true)[0],
            selectSubtype: this.props.subType,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: FIGURE_IMAGE_COLUMNS,
                selectedItems: FIGURE_IMAGE_COLUMNS.filter(
                    (c) => !c.key.startsWith("matched_")
                ).map((c) => c.key),
                saveKey: `${Workspaces.OcrFigure}_${Typename.OcrFigureImageView}`,
            })
        );
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.onEvaluationRecordChanged();
        }
    }

    public render() {
        const { records } = this.props;
        const {
            evalData,
            selectSubtype,
            entityList,
            selectedColumns,
            selectImageId,
            imageVisItems,
        } = this.state;
        const experiments = records.map((record) => record.name);
        const field =
            selectSubtype && entityList.includes(selectSubtype)
                ? selectSubtype
                : entityList[0];
        let entityDict: any = {};

        const columns: TableColumn[] = FIGURE_IMAGE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        if (evalData[field]) {
            const keys = new Set(
                evalData[field]
                    .filter((m) => m)
                    .flatMap((metric: any) => Object.keys(metric))
            );
            [...keys].forEach((key) => {
                entityDict[key] = evalData[field].map((metric: any) => {
                    return metric && key in metric ? metric[key] : null;
                });
            });
        }
        this.exportData = entityDict;

        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        let clickNumber: number = 0;

        const tableKey = `${this.state.selectLanguage}_${this.state.selectSubtype}`;
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                    containerClassName="modal"
                >
                    {!!selectImageId && (
                        <ImageVisualizer
                            metricsType={MetricsType.figureMetrics}
                            experiments={experiments}
                            fileId={selectImageId}
                            evalList={imageItemsForVis}
                            onLoadVisualizer={(imageId) => {
                                if (imageId && entityDict[imageId]) {
                                    const mettic = entityDict[imageId].find(
                                        (data: any) => data && data.imageUrl
                                    );
                                    return mettic ? mettic.imageUrl : "";
                                } else {
                                    return "";
                                }
                            }}
                            onRequestPolygons={(imageId?: string) =>
                                this._requestTextlinePolygons()
                            }
                            onDismiss={(imageId: any) =>
                                this.setState({
                                    selectImageId: imageId,
                                })
                            }
                            clickNumber={this.state.clickNumber}
                        />
                    )}
                </Modal>
                <FullScreen>
                    <TableList<FigureImageMetric>
                        key={tableKey}
                        evalDataCount={records.length}
                        evalData={entityDict}
                        columns={columns}
                        downloadTableTitle={tableKey}
                        renderTableHeader={this._renderTableHeader}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageId] = item;

                            this.setState({
                                selectImageId: imageId,
                                clickNumber: clickNumber,
                            });
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </FullScreen>
            </>
        );
    }

    exportAction = () => {
        exportTableListData(
            this.exportData,
            FIGURE_IMAGE_COLUMNS,
            "FigureImageMetrics"
        );
    };

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<FigureImageMetric>> {
        const mathDataset = new FigureDataset(
            recordDetail.dataset.name,
            recordDetail.dataset.version,
            recordDetail.dataset.language,
            recordDetail.dataset.categories
        );

        return Promise.all([
            recordDetail.fetchMetricsWithRecord<IMetrics<FigureImageMetric>>(
                metricName
            ),
            mathDataset.fetchImageListByCategory(),
        ]).then(([[_, metrics], imageList]) => {
            Object.entries(metrics).forEach(([key, val]) => {
                Object.entries(val).forEach(([key1, val1]) => {
                    const imageName =
                        imageList.find((image) =>
                            image.toLowerCase().includes(key1.toLowerCase())
                        ) ?? `${key1}.jpg`;
                    (val[key1] as any)["imageUrl"] =
                        mathDataset.getImageUrl(imageName);
                });
            });
            return metrics;
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage, selectSubtype } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            const language = this.loadSelectedLanguage(
                selectLanguage,
                languageList
            );

            const stateSettings: { [key: string]: string | undefined } =
                selectSubtype
                    ? {
                          selectLanguage: language,
                          selectType: selectSubtype,
                      }
                    : { selectLanguage: language };

            this._onOptionsChanged(stateSettings);
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );
            this.showEvaluationResult(details, "image_metrics.json");
        }
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        const selectType =
            "selectType" in newOptions
                ? newOptions["selectType"]
                : this.state.selectSubtype;
        this._onQueryButtonClicked(selectLanguage);
        this.setState({
            selectLanguage: selectLanguage,
            selectSubtype: selectType,
        });
    }

    private _renderTableHeader(): JSX.Element {
        const {
            entityList,
            matchDatasetVersion,
            selectSubtype,
            selectLanguage,
        } = this.state;
        const languages = this.getLanguageList(matchDatasetVersion!);
        const selectLangKey = this.loadSelectedLanguage(
            selectLanguage,
            languages
        );
        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                styles: dropdownStyles,
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: "subtype",
                text: "SubType:",
                options: entityList,
                selectedKey:
                    selectSubtype && entityList.includes(selectSubtype)
                        ? selectSubtype
                        : entityList[0],
                onChange: (field) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectType: field!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<FigureImageMetric>
    ): string[] {
        return Object.keys(evalData).sort();
    }

    private getDisplayEvalData = (displayItems: any[]) => {
        this.setState({
            imageVisItems: displayItems,
        });
    };

    private _requestTextlinePolygons(): Promise<OcrPolygons[]> {
        const { selectLanguage, matchDatasetVersion } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion
        );

        return Promise.all(
            details.map((detail) => {
                const filename = `polygons.json`;
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(): Promise<OcrMetrics[]> {
        const { selectLanguage, matchDatasetVersion } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion
        );

        return Promise.all(
            details.map((detail) => {
                const filename = `formula_metrics.json`;
                return detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                    `${filename}`
                );
            })
        );
    }
}
