import * as React from "react";
import "./OverviewTable.scss";
import "./TableList.scss";

import {
    // Stack
    Stack,

    // others
    IconButton,
    MessageBar,
    MessageBarType,
    FocusTrapCallout,
    DirectionalHint,
} from "@fluentui/react";

import { TableColumn, TableList } from ".";
import { IMergedMetrics, IMetricUnit } from "../DataContract";
import _ from "lodash";
import { Consumer } from "../Layout";

interface IProps<T extends IMetricUnit> {
    evalData: IMergedMetrics<T> | Map<string, T[]>;
    columns: TableColumn[];
    tableTitle: string;
    targetId: string;
    evalDataCount: number;
    width?: number;
    message?: string;
    highlightKey?: string[];
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    onDisMiss?: () => void;
}

interface IState {}

export class CalloutTable<T extends IMetricUnit> extends React.Component<IProps<T>, IState> {
    constructor(props: IProps<T>) {
        super(props);

        this.state = {
            columns: this.props.columns,
        };
    }

    public render() {
        const {
            tableTitle,
            columns,
            evalData,
            message,
            highlightKey,
            targetId,
            evalDataCount,
            width = document.body.clientWidth * 0.4,
            onDisMiss,
        } = this.props;
        return (
            <Consumer>
                {(isDarkTheme) => (
                    <FocusTrapCallout
                        // className={styles.callout}
                        styles={{
                            root: {
                                paddingRight: "15px",
                                display: "flex",
                                flexDirection: "column",
                            },
                            container: {
                                display: "flex",
                                flexDirection: "column",
                            },
                        }}
                        style={{ display: "flex", flexDirection: "column" }}
                        gapSpace={0}
                        directionalHint={DirectionalHint.bottomCenter}
                        directionalHintForRTL={DirectionalHint.bottomCenter}
                        calloutWidth={width}
                        calloutMinWidth={500}
                        calloutMaxHeight={500}
                        finalHeight={500}
                        hideOverflow
                        role="alertdialog"
                        target={`#${targetId}`}
                        setInitialFocus
                        // doNotLayer
                        preventDismissOnEvent={() => false}
                        onDismiss={() => {
                            if (onDisMiss) {
                                onDisMiss();
                            }
                        }}
                    >
                        {message && (
                            <MessageBar
                                messageBarType={MessageBarType.info}
                                messageBarIconProps={{
                                    iconName: "InfoSolid",
                                }}
                                styles={{
                                    root: {
                                        backgroundColor: "#eff6fc",
                                        width: "100%",
                                    },
                                    icon: {
                                        color: "#0078d4",
                                    },
                                }}
                            >
                                <b>{message}</b>
                            </MessageBar>
                        )}
                        <Stack
                            horizontal
                            horizontalAlign="space-between"
                            verticalAlign="center"
                        >
                            <label
                                style={{
                                    marginLeft: "10px",
                                    color: isDarkTheme ? "white" : "black",
                                }}
                            >
                                {tableTitle}
                            </label>

                            <IconButton
                                iconProps={{
                                    iconName: "Cancel",
                                    styles: {
                                        root: {
                                            marginTop: "5px",
                                            color: isDarkTheme
                                                ? "white"
                                                : "#323130",
                                        },
                                    },
                                }}
                                onClick={() => {
                                    if (onDisMiss) {
                                        onDisMiss();
                                    }
                                }}
                            ></IconButton>
                        </Stack>
                        <div
                            style={{
                                width: "100%",
                                maxHeight: "436px",
                                overflow: "auto",
                            }}
                        >
                            <TableList<any>
                                columns={columns}
                                evalDataCount={evalDataCount}
                                evalData={evalData}
                                downloadTableTitle={tableTitle}
                                // freezeHeight={500}
                                hideHeader
                                verticalFill={false}
                                disableFreezeHeader
                                highlightKey={highlightKey}
                                onItemInvoked={this.props.onItemInvoked}
                                isDarkTheme={isDarkTheme}
                                className={""}
                            />
                        </div>
                    </FocusTrapCallout>
                )}
            </Consumer>
        );
    }

    componentWillReceiveProps(nextProps: IProps<T>, nextContext: any) {
        if (!_.isEqual(nextProps, this.props)) {
            this.setState({
                evalData: nextProps.evalData,
                columns: nextProps.columns,
            });
        }
    }
}

export const formartTarget = (targetId: string) => {
    return targetId.replace(/[^\w]/g, "");
};

export enum CalloutType {
    row = "row",
    cell = "cell",
}
