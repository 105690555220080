import React from "react";
import { Modal } from "@fluentui/react";

import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import {
    ColumnValueType,
    TableColumn,
    TableList,
    ImageVisualizer,
    OcrPolygon,
    MetricsType,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { Consumer } from "../../Layout";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { exportTableListData } from "../../Utils/ExportFile";
import { FullScreen } from "../Common/FullScreen";

interface ImageCharMetric extends IMetricUnit {
    category: string;
    wordCount: number;
    goodAlignPercent: number;
    goodAlignCount: number;
    recall: number;
    precision: number;
    overlap: number;
    recallLt95Perc: number;
    recallLt95Cnt: number;
    imageUrl: string;
    [key: string]: number | string;
}

export interface WordMetric extends IMetricUnit {
    word_id: string;
    recall: number;
    precision: number;
    overlap: number;
    debug_info: string[];
    entity_name: string;
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<WordMetric[]>;

interface IState extends IMetricState<ImageCharMetric> {
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
    clickNumber?: number;
    categoryList: string[];
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    ignoreLanguages?: string[];
    linkData?: {
        toSelectLanguage: string;
        toSelectCategory: string;
    };
    matchDatasetVersion?: boolean;
}

// prettier-ignore
export const imageColumns: TableColumn[] = [
    { key: "imageId",          name: "ImageId",          isKey: true,                   valueType: ColumnValueType.String, minWidth: 100, maxWidth: 150, isResizable: true, distinctStr: true },
    { key: "wordCount",        name: "WordCount",        fieldName: "wordCount",        valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "goodAlignPercent", name: "GoodAlignPercent", fieldName: "goodAlignPercent", valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "goodAlignCount",   name: "GoodAlignCount",   fieldName: "goodAlignCount",   valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "recall",           name: "Recall",           fieldName: "recall",           valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "precision",        name: "Precision",        fieldName: "precision",        valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "overlap",          name: "Overlap",          fieldName: "overlap",          valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 180, isResizable: true, },
    { key: "recallLt95%Perc",  name: "RecallLt95%Perc",  fieldName: "recallLt95%Perc",  valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 200, isResizable: true, },
    { key: "recallLt95%Cnt",   name: "RecallLt95%Cnt",   fieldName: "recallLt95%Cnt",   valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 200, isResizable: true, },
];

export class WordAlignImageView extends MetricsView<
    IProps,
    IState,
    ImageCharMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            categoryList: [],
            selectLanguage: this.props.linkData
                ? this.props.linkData.toSelectLanguage
                : undefined,
            selectCategory: this.props.linkData
                ? this.props.linkData.toSelectCategory
                : undefined,
            matchDatasetVersion: props.matchDatasetVersion ?? true,
        };
        store.dispatch(
            updateStateAction({
                matchDatasetVersion: props.matchDatasetVersion ?? true,
                columns: imageColumns,
            })
        );
    }

    componentWillMount(): void {
        this.loadStateFromReduxStore();
        super.componentWillMount();
    }

    public render() {
        const { records } = this.props;
        const {
            evalData,
            selectImageId,
            imageVisItems,
            selectCategory,
            categoryList,
            selectedColumns,
        } = this.state;
        const experiments = records.map((record) => record.name);
        let columns: TableColumn[] = imageColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        let clickNumber: number = 0;
        let entity = selectCategory ?? categoryList[0];

        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                >
                    {!!selectImageId && (
                        <ImageVisualizer
                            metricsType={MetricsType.wordAlignMetrics}
                            entity={entity}
                            experiments={experiments}
                            fileId={selectImageId}
                            evalList={imageItemsForVis}
                            onLoadVisualizer={(imageId: any) => {
                                if (
                                    imageId &&
                                    evalData[imageId] &&
                                    evalData[imageId].length > 0
                                ) {
                                    return evalData[imageId][0]!.imageUrl;
                                } else {
                                    return "";
                                }
                            }}
                            onRequestPolygons={(imageId?: string) =>
                                this._requestTextlinePolygons(imageId!, entity)
                            }
                            onRequestMetrics={(imageId?: string) =>
                                this._requestTextlineMetrics(imageId!, entity)
                            }
                            setImageMark={this.setImageMark}
                            onDismiss={(imageId: any) =>
                                this.onDismiss(imageId)
                            }
                            clickNumber={this.state.clickNumber}
                        />
                    )}
                </Modal>
                <FullScreen>
                    <Consumer>
                        {(value) => {
                            return (
                                <TableList<ImageCharMetric>
                                    key={this.state.selectLanguage}
                                    evalDataCount={this.props.records.length}
                                    evalData={this.state.evalData}
                                    columns={columns}
                                    downloadTableTitle={
                                        this.state.selectLanguage
                                    }
                                    isFullFilterMenu={true}
                                    onItemInvoked={(item, index) => {
                                        clickNumber = index!;
                                        const [imageId] = item;
                                        this.setState({
                                            selectImageId: imageId,
                                            clickNumber: clickNumber,
                                        });
                                    }}
                                    renderTableHeader={this._renderTableHeader}
                                    getDisplayEvalData={(
                                        displayItems: any[]
                                    ) => {
                                        this.getDisplayEvalData(displayItems);
                                    }}
                                    isDarkTheme={value}
                                />
                            );
                        }}
                    </Consumer>
                </FullScreen>
            </>
        );
    }

    exportAction = () => {
        exportTableListData(
            this.state.evalData,
            imageColumns,
            "WordAlignImageVIew"
        );
    };

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${store.getState().globalReducer.workSpace}_${
                    Typename.WordAlignMetrics
                }_ByImage`,
                columns: imageColumns,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<ImageCharMetric>> {
        const { selectCategory: category } = this.state;
        return Promise.all([
            recordDetail.fetchMetricsWithCamelCasing<IMetrics<ImageCharMetric>>(
                metricName
            ),
            recordDetail.dataset.fetchImageListByCategory(category!),
        ]).then(([records, imageList]) => {
            Object.entries(records).forEach(([key, val]) => {
                const imageName =
                    imageList.find((image) =>
                        image.toLowerCase().includes(key.toLowerCase())
                    ) ?? `${key}.jpg`;
                records[key].imageUrl = recordDetail.dataset.getImageUrl(
                    `${category}/${imageName}`
                );
            });
            return records;
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { ignoreLanguages } = this.props;
        const { selectLanguage } = this.state;
        let languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            languageList = languageList.filter(
                (lang) => !(ignoreLanguages && ignoreLanguages.includes(lang))
            );
            const language = selectLanguage ?? languageList[0];
            this._onOptionsChanged(
                {
                    selectLanguage: language,
                },
                matchDatasetVersion
            );
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _requestTextlinePolygons(
        imageId: string,
        entity: string
    ): Promise<OcrPolygons[]> {
        const {
            selectLanguage,
            selectImageId,
            selectCategory,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const filename =
                    (imageId ?? selectImageId!).charAt(
                        (imageId ?? selectImageId!).length - 1
                    ) + "_word_alignment_polygons.json";
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(
        imageId: string,
        entity: string
    ): Promise<OcrMetrics[]> {
        const {
            selectLanguage,
            selectCategory,
            selectImageId,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const filename =
                    (imageId ?? selectImageId!).charAt(
                        (imageId ?? selectImageId!).length - 1
                    ) + "_word_alignment_metrics.json";
                return detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, selectCategory, matchDatasetVersion } =
            this.state;
        if (records.length > 0) {
            const details = this.filterRecordDetails(
                selectLanguage!,
                matchDatasetVersion!
            );
            this.showEvaluationResult(
                details,
                `${selectCategory!}/image_word_alignment_metrics.json`
            );
        }
    };

    private _renderTableHeader(): JSX.Element {
        const {
            categoryList,
            selectLanguage,
            selectCategory,
            matchDatasetVersion,
        } = this.state;
        const { ignoreLanguages } = this.props;
        let languages = this.getLanguageList(matchDatasetVersion!);
        if (languages && languages.length > 0) {
            languages = languages.filter(
                (lang) => !(ignoreLanguages && ignoreLanguages.includes(lang))
            );
        }
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged!(
                        {
                            selectLanguage: language!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
            {
                key: "categories",
                text: "Category:",
                options: categoryList,
                selectedKey: selectCategory ?? categoryList[0],
                onChange: (catagory) => {
                    this._onOptionsChanged!(
                        {
                            selectCategory: catagory!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion: boolean
    ) {
        let selectLanguage: any;
        let selectCategory: any;
        selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        selectCategory =
            "selectCategory" in newOptions
                ? newOptions["selectCategory"]
                : this.state.selectCategory;

        let categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        ).sort();

        selectCategory =
            selectCategory && categories.indexOf(selectCategory) >= 0
                ? selectCategory
                : categories[0];

        this.setState(
            {
                selectCategory: selectCategory,
                selectLanguage: selectLanguage,
                categoryList: categories,
            },
            () => this._onQueryButtonClicked()
        );
    }

    private onDismiss = (imageId: any) => {
        this.setState({
            selectImageId: imageId,
        });
    };

    private getDisplayEvalData = (displayItems: any[]) => {
        this.setState({
            imageVisItems: displayItems,
        });
    };
}
