import React from "react";
import { VerticalBasePage } from "./VerticalBasePage";
import { VerticalErrorViewWithRedux, VerticalOverview } from "../../Views";
import { Typename, Workspaces } from "../../DataContract";

export class HealthDocPage extends VerticalBasePage {
    public render() {
        const {
            isDarkTheme,
            linkData,
            records,
            selectedKey,
            isForReport,
            deepLinkHandler,
        } = this.props;

        const { dataItems } = this.state;
        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };

        return (
            <>
                {selectedKey === "overviewHealthMetrics" && (
                    <VerticalOverview
                        dataItems={dataItems}
                        records={records}
                        isForReport={isForReport}
                        VerticalImageViewDeepLinkHandler={deepLinkHandler}
                        workspace={Workspaces.HealthDoc}
                        saveSetKey={`${Workspaces.HealthDoc}_${Typename.VerticalOverview}`}
                        isDarkTheme={isDarkTheme}
                        pageName="HealthOverviewMetrics"
                    />
                )}
                {selectedKey === "overviewHealthFieldMetrics" && (
                    <VerticalErrorViewWithRedux
                        saveSetKey={`${Workspaces.HealthDoc}_${Typename.VerticalErrorView}`}
                        records={records}
                        metricItems={dataItems}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectField={linkSetting?.toSelectField}
                        isDarkTheme={isDarkTheme}
                    />
                )}
            </>
        );
    }
}
