import { BarChartMetricsBasePage } from "../Common/BarChartMetricsBasePage";
import { MetricDefinition } from "../../DataContract";

const DEFAULT_FIELD = ["f1", "precision", "recall"];

interface CompletenessPurityScore {
    complete: number;
    pure: number;
}

interface FMeasureScore {
    precision: number;
    recall: number;
    f1: number;
}

interface StatisticNumber {
    mean: number;
    median: number;
    std: number;
}

interface TableCellAdjacencyRelationship {
    [iou: string]: FMeasureScore;
}

interface TableCellAdjacencyRelationshipTSR {
    [iou: string]: FMeasureScore;
}

interface TableCellCompletenessAndPurity {
    binary: CompletenessPurityScore;
    completeness: StatisticNumber;
    std: StatisticNumber;
}

interface TableCellType {
    [cellType: string]: FMeasureScore;
}

interface TableCompletenessAndPurity {
    binary: CompletenessPurityScore;
    completeness: StatisticNumber;
    purity: StatisticNumber;
}

interface TableEditDistanceSimilarity {
    gt: StatisticNumber;
    reco: StatisticNumber;
}

interface TableDetection {
    [iou: string]: FMeasureScore;
}

interface TableHeader {
    [isHeader: string]: FMeasureScore;
}

interface TableMetrics {
    det: TableDetection;
    tcnp: TableCompletenessAndPurity;
    teds: TableEditDistanceSimilarity;
    ccnp: TableCellCompletenessAndPurity;
    adj: TableCellAdjacencyRelationship;
    adj_tsr: TableCellAdjacencyRelationshipTSR;
    header_acc: TableHeader;
    cell_type: TableCellType;
}

const TABLE_CELL_ACCURACY_DEFINITION: MetricDefinition = {
    name: "cell_type",
    displayName: "Table Cell Type Precision and Recall",
    tablefields: DEFAULT_FIELD,
    props: [
        {
            fieldName: "ColumnHeader",
            displayName: "Column Header",
        },
        {
            fieldName: "RowHeader",
            displayName: "Row Header",
        },
        {
            fieldName: "RowColumn",
            displayName: "Row Column (Stub)",
        },
        {
            fieldName: "DataCell",
            displayName: "Data Cell",
        },
        {
            fieldName: "Description",
            displayName: "Description",
        },
        {
            fieldName: "TableTitle",
            displayName: "Table Title",
        },
    ],
};

const TABLE_CELL_ADJACENCY_RELATIONSHIP_DEFINITION: MetricDefinition = {
    name: "adj",
    displayName: "Table Cell Adjacency Relationship",
    tablefields: DEFAULT_FIELD,
    props: [
        {
            fieldName: "0.6",
            displayName: "iou 0.6",
        },
        {
            fieldName: "0.7",
            displayName: "iou 0.7",
        },
        {
            fieldName: "0.8",
            displayName: "iou 0.8",
        },
        {
            fieldName: "0.9",
            displayName: "iou 0.9",
        },
    ],
};

const TABLE_CELL_ADJACENCY_RELATIONSHIP_TSR_DEFINITION: MetricDefinition = {
    name: "adj_tsr",
    displayName: "Table Cell Adjacency Relationship - Recognizer Only",
    tablefields: DEFAULT_FIELD,
    props: [
        {
            fieldName: "0.6",
            displayName: "iou 0.6",
        },
        {
            fieldName: "0.7",
            displayName: "iou 0.7",
        },
        {
            fieldName: "0.8",
            displayName: "iou 0.8",
        },
        {
            fieldName: "0.9",
            displayName: "iou 0.9",
        },
    ],
};

const TABLE_CELL_COMPLETENESS_AND_PURITY_DEFINITION: MetricDefinition = {
    name: "ccnp",
    displayName: "Table Cell Completeness and Purity",
    tablefields: ["complete", "pure", "mean", "median", "std"],
    props: [
        {
            fieldName: "binary",
            displayName: "Binary",
        },
        {
            fieldName: "completeness",
            displayName: "Completeness",
        },
        {
            fieldName: "purity",
            displayName: "Purity",
        },
    ],
};

const TABLE_COMPLETENESS_AND_PURITY_DEFINITION: MetricDefinition = {
    name: "tcnp",
    displayName: "Table Completeness and Purity",
    tablefields: ["complete", "pure", "mean", "median", "std"],
    props: [
        {
            fieldName: "binary",
            displayName: "Binary",
        },
        {
            fieldName: "completeness",
            displayName: "Completeness",
        },
        {
            fieldName: "purity",
            displayName: "Purity",
        },
    ],
};

const TABLE_DETECTION_METRIC_DEFINITION: MetricDefinition = {
    name: "det",
    displayName: "Table Detection",
    tablefields: DEFAULT_FIELD,
    props: [
        {
            fieldName: "0.6",
            displayName: "iou 0.6",
        },
        {
            fieldName: "0.7",
            displayName: "iou 0.7",
        },
        {
            fieldName: "0.8",
            displayName: "iou 0.8",
        },
        {
            fieldName: "0.9",
            displayName: "iou 0.9",
        },
    ],
};

const TABLE_EDIT_DISTANCE_SIMILARITY_DEFINITION: MetricDefinition = {
    name: "teds",
    displayName: "Table Edit Distance Similarity",
    tablefields: ["mean", "median", "std"],
    props: [
        {
            fieldName: "gt",
            displayName: "teds-gt",
        },
        {
            fieldName: "reco",
            displayName: "teds-reco",
        },
    ],
};

const TABLE_HEADER_ACCURACY_DEFINITION: MetricDefinition = {
    name: "header_acc",
    displayName: "Table Header Precision and Recall",
    tablefields: DEFAULT_FIELD,
    props: [
        {
            fieldName: "isHeader",
            displayName: "Header Precision and Recall",
        },
    ],
};

export class OcrTableOverview extends BarChartMetricsBasePage<TableMetrics> {
    get MetricDefinitionArray(): MetricDefinition[] {
        return [
            TABLE_COMPLETENESS_AND_PURITY_DEFINITION,
            TABLE_CELL_COMPLETENESS_AND_PURITY_DEFINITION,
            TABLE_CELL_ACCURACY_DEFINITION,
            TABLE_CELL_ADJACENCY_RELATIONSHIP_DEFINITION,
            TABLE_CELL_ADJACENCY_RELATIONSHIP_TSR_DEFINITION,
            TABLE_DETECTION_METRIC_DEFINITION,
            TABLE_EDIT_DISTANCE_SIMILARITY_DEFINITION,
            TABLE_HEADER_ACCURACY_DEFINITION,
        ];
    }

    queryMetricsResult() {
        this._queryMetricsResult("basic_metrics.json");
    }

    onItemInvoked = (data: any) => {
        this.props.onItemInvoked && this.props.onItemInvoked(data);
    };
}
