import * as React from "react";
import "./Catalog.scss";

import {
    Callout,
    DefaultButton,
    DirectionalHint,
    ITag,
    Label,
    PrimaryButton,
    Stack,
    TagPicker,
} from "@fluentui/react";

import { Record } from "../DataContract";

interface IProps {
    item?: Record;
    target?: HTMLElement;
    visibleCalloutId?: string;
    showWarning?: boolean;
    onDismiss?: () => void;
    onSaveTags?: (tags: any | null) => void;
}

interface IState {
    tagItems?: any[];
}

export class TagCallout extends React.Component<IProps, IState> {
    public render() {
        let showTags: ITag[] = [];
        this.props.item!.tags.forEach((tag) => {
            showTags.push({ key: tag, name: tag });
        });

        return (
            <Callout
                target={this.props.target}
                directionalHint={DirectionalHint.bottomCenter}
                isBeakVisible={true}
                gapSpace={0}
                setInitialFocus={true}
                hidden={this.props.visibleCalloutId === "-1"}
                styles={{ root: { width: "350px" } }}
            >
                <Stack verticalFill>
                    <Label style={{ margin: "1px 0px 0px 10px" }}>
                        Manage Tags
                    </Label>

                    {this.props.showWarning && (
                        <span style={{ color: "red", marginLeft: "10px" }}>
                            *Please don't input empty content!
                        </span>
                    )}
                    <div className={"callout_inner"}>
                        <TagPicker
                            onResolveSuggestions={(text, selectedItems) => {
                                let pickerItem = selectedItems!.map(
                                    (item) => item.name
                                );
                                return pickerItem?.indexOf(text) !== -1
                                    ? []
                                    : [{ name: text, key: text }];
                            }}
                            defaultSelectedItems={
                                showTags!.length === 0 ? undefined : showTags
                            }
                            onChange={(items?: any[]) => {
                                return this._onTagItemsChange(items!);
                            }}
                        />

                        <Stack
                            horizontal
                            gap={5}
                            style={{
                                marginTop: "4%",
                            }}
                        >
                            <PrimaryButton
                                onClick={() => {
                                    if (this.state && this.state.tagItems) {
                                        this.props.onSaveTags!([
                                            ...this.state.tagItems!,
                                        ]);
                                    } else {
                                        this.props.onDismiss!();
                                    }
                                }}
                                text="Save"
                            />
                            <DefaultButton
                                onClick={this.props.onDismiss}
                                text="Cancel"
                            />
                        </Stack>
                    </div>
                </Stack>
            </Callout>
        );
    }

    private _onTagItemsChange = (items: ITag[]) => {
        let tagItems: string[] = [];
        tagItems = items!.map((item) => item.name);

        if (tagItems.length === 0) {
            this.setState({
                tagItems: [],
            });
        } else {
            this.setState({
                tagItems: [...new Set(tagItems)],
            });
        }
    };
}
