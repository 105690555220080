import React from "react";
import { store } from "../../../store";
import { Unsubscribe } from "redux";
import _ from "lodash";

export const SubscribeHoc = (MyButton: any) => {
    return class Button extends React.Component<any, any> {
        constructor(prop: any) {
            super(prop);
            this.state = {
                viewType: store.getState().globalReducer.viewType,
                selectSubPivot: store.getState().globalReducer.selectSubPivot,
                releaseTestselectSubPivot:
                    store.getState().globalReducer.releaseTestselectSubPivot,
                expandAll: store.getState().globalReducer.expandAll,
                disableSetting: store.getState().globalReducer.disableSetting,
                disableExportingExcel:
                    store.getState().globalReducer.disableExportingExcel,
            };
        }

        private unsubscribe?: Unsubscribe;
        componentWillMount(): void {
            this.unsubscribe = store.subscribe(() => {
                if (this.state) {
                    const globalReducer = store.getState().globalReducer;
                    if (
                        !_.isEqual(globalReducer.viewType, this.state.viewType)
                    ) {
                        this.setState({
                            viewType: globalReducer.viewType,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.selectSubPivot,
                            this.state.selectSubPivot
                        )
                    ) {
                        this.setState({
                            selectSubPivot: globalReducer.selectSubPivot,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.releaseTestselectSubPivot,
                            this.state.releaseTestselectSubPivot
                        )
                    ) {
                        this.setState({
                            releaseTestselectSubPivot:
                                globalReducer.releaseTestselectSubPivot,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.expandAll,
                            this.state.expandAll
                        )
                    ) {
                        this.setState({
                            expandAll: globalReducer.expandAll,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.disableSetting,
                            this.state.disableSetting
                        )
                    ) {
                        this.setState({
                            disableSetting: globalReducer.disableSetting,
                        });
                    }
                    if (
                        !_.isEqual(
                            globalReducer.disableExportingExcel,
                            this.state.disableExportingExcel
                        )
                    ) {
                        this.setState({
                            disableExportingExcel:
                                globalReducer.disableExportingExcel,
                        });
                    }
                }
            });
        }

        componentWillUnmount(): void {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
        }

        render() {
            const otherP = {
                disableSetting: this.state.disableSetting,
                disableExportingExcel: this.state.disableExportingExcel,
                viewType: this.state.viewType,
                selectSubPivot: this.state.selectSubPivot,
                releaseTestselectSubPivot: this.state.releaseTestselectSubPivot,
                expandAll: this.state.expandAll,
            };
            return <MyButton {...this.props} {...otherP} />;
        }
    };
};
