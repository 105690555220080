import React from "react";
import {
    DetailsRow,
    getTheme,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IDetailsRowStyles,
    IRenderFunction,
    IScrollablePaneStyleProps,
    IScrollablePaneStyles,
    IStyleFunctionOrObject,
    Sticky,
    StickyPositionType,
    TooltipHost,
} from "@fluentui/react";
import { IEqualityComparer } from "linq-to-typescript";

//#region ScrollablePane Overwrite
export const ScrollablePane_Styles_ThickScrollBar: IStyleFunctionOrObject<
    IScrollablePaneStyleProps,
    IScrollablePaneStyles
> = {
    root: {
        zIndex: "9999",
    },
    contentContainer: {
        overflowX: "hidden",
        height: "100%",
        "::-webkit-scrollbar": {
            width: 12,
            height: 12,
        },
        "::-webkit-scrollbar-thumb": {
            border: "4px solid transparent",
            boxShadow: "none",
            backgroundColor: "rgba(189,189,189,0.8)",
            backgroundClip: "padding-box",
        },

        "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#bdbdbd",
        },

        "::-webkit-scrollbar-track": {
            boxShadow: "none",
        },
    },
};
//#endregion

//#region DetailsList Overwrite
export const DetailsList_onRenderDetailsHeader_FreezeHeader: IRenderFunction<
    IDetailsHeaderProps
> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<
        IDetailsColumnRenderTooltipProps
    > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

export const DetailsList_onRenderRow_InterlaceColor: IRenderFunction<
    IDetailsRowProps
> = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.itemIndex % 2 === 0) {
            customStyles.root = {
                backgroundColor: getTheme().palette.neutralLighterAlt,
            };
        }

        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};
//#endregion

//#region Ling.js Extension
export const EqualityComparer_IgnoreCase: IEqualityComparer<any> = (
    x: any,
    y: any
) => {
    return x.toLowerCase() === y.toLowerCase();
};
//#endregion
