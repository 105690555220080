import React from "react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigs, TableHeader } from "../../Controls";
import { TableColumn, TableList, ColumnValueType } from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    IMergedMetrics,
    mergeMetrics,
    fetchMetricsFromBE,
    Workspaces,
    COLUMN_KEY_SEPARATOR,
    Typename,
} from "../../DataContract";
import "../Common/MetricStyle.scss";
import { IDropdownStyles } from "@fluentui/react";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import _ from "lodash";
import { FullScreen } from "../Common/FullScreen";

interface InvoiceImageMetric extends IMetrics<IMetricUnit> {
    fileName: string;
    ocrToyUrl: string;
    predictionText: string;
    groundtruthText: string;
    textResult: string;
    matchingScore: number;
    predictionInstanceId: number;
    groundtruthInstanceId: number;
    predictionInstanceBBox: string;
    predictionPageNumber: number;
    predictionConfidence: number;
    bBoxIncludeResult: string;
    bBoxOverlappingRate: number;
    bBoxIncludePredictionPageNumber: number;
    bBoxIncludePredictionConfidence: number;
}

interface IState extends IMetricState<InvoiceImageMetric> {
    entityList: string[]; // fieldList actually
    selectLanguage?: string;
    selectField?: string;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
    toSelectField?: string;
}

// prettier-ignore
const INVOICE_IMAGE_COLUMNS: TableColumn[] = [
    { key: "itemID",                          name: "FileName/ItemID",                 fieldName: "itemID",                          isKey: true,  valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, ignoreWhenComparing: true },
    { key: "predictionText",                  name: "PredictionText",                  fieldName: "predictionText",                  isKey: false, valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "groundtruthText",                 name: "GroundtruthText",                 fieldName: "groundtruthText",                 isKey: false, valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "textResult",                      name: "TextResult",                      fieldName: "textResult",                      isKey: false, valueType: ColumnValueType.String, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, filterable: true },
    { key: "predictionPageNumber",            name: "PredictionPageNumber",            fieldName: "predictionPageNumber",            isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, maxDecimalPlaces: 3 },
    { key: "predictionConfidence",            name: "PredictionConfidence",            fieldName: "predictionConfidence",            isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, maxDecimalPlaces: 3 },
    { key: "bBoxIncludeResult",               name: "BBoxIncludeResult",               fieldName: "bBoxIncludeResult",               isKey: false, valueType: ColumnValueType.String, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true },
    { key: "bBoxOverlappingRate",             name: "BBoxOverlappingRate",             fieldName: "bBoxOverlappingRate",             isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, maxDecimalPlaces: 3 },
    { key: "bBoxIncludePredictionPageNumber", name: "BBoxIncludePredictionPageNumber", fieldName: "bBoxIncludePredictionPageNumber", isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, maxDecimalPlaces: 3 },
    { key: "bBoxIncludePredictionConfidence", name: "BBoxIncludePredictionConfidence", fieldName: "bBoxIncludePredictionConfidence", isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true, maxDecimalPlaces: 3 },
    { key: "ocrToyUrl",                       name: "OcrToyUrl",                       fieldName: "ocrToyUrl",                       isKey: false, valueType: ColumnValueType.Url,    minWidth: 200, maxWidth: 250, isResizable: true, ignoreWhenComparing: true },
];

const DEFAULT_HIDEN_COLUMN_KEY_LIST = [
    "predictionPageNumber",
    "bBoxIncludeResult",
    "bBoxOverlappingRate",
    "bBoxIncludePredictionPageNumber",
    "bBoxIncludePredictionConfidence",
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" },
};

export class InvoiceImageView extends MetricsView<
    IProps,
    IState,
    InvoiceImageMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            entityList: [],
            selectLanguage: this.props.toSelectLanguage,
            selectField: this.props.toSelectField,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: INVOICE_IMAGE_COLUMNS,
                saveKey: `${Workspaces.Invoice}_${Typename.InvoiceImageView}`,
                selectedItems: INVOICE_IMAGE_COLUMNS.filter(
                    (val) => !DEFAULT_HIDEN_COLUMN_KEY_LIST.includes(val.key)
                ).map((column) => column.key),
            })
        );
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.onEvaluationRecordChanged();
        }
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectField, entityList, selectedColumns } =
            this.state;

        const field =
            selectField && entityList.includes(selectField)
                ? selectField
                : entityList[0];
        let entityDict: any = {};

        const columns: TableColumn[] = INVOICE_IMAGE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        if (evalData[field]) {
            const keys = new Set(
                evalData[field].flatMap((metric: any) => Object.keys(metric))
            );
            [...keys].forEach((key) => {
                entityDict[key] = evalData[field].map((metric: any) => {
                    return key in metric ? metric[key] : null;
                });
            });
        }

        const tableKey = `${this.state.selectLanguage}_${this.state.selectField}`;
        return (
            <FullScreen>
                <TableList<InvoiceImageMetric>
                    key={tableKey}
                    evalDataCount={records.length}
                    evalData={entityDict}
                    downloadTableTitle={tableKey}
                    columns={columns}
                    renderTableHeader={this._renderTableHeader}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </FullScreen>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<InvoiceImageMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<InvoiceImageMetric>>(metricName)
            .then(([record, metrics]) => {
                Object.entries(metrics).forEach(([_, fieldValues]: any) => {
                    Object.entries(fieldValues).forEach(
                        ([filename, val]: any) => {
                            const modelInfo = record.modelInfo;
                            val.FileName = filename;
                            val.OcrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/files/evalrecord/${modelInfo}/vis/invoice/${filename}.page1.invoice_fott.html?visibleLayers=byv`;
                        }
                    );
                });
                return metrics;
            });
    }

    protected showEvaluationResult(
        recordDetails: RecordDetail[],
        metricName: string
    ) {
        const metricsRootList = recordDetails.map(
            (detail) =>
                `${detail.getRawProp<string>("storageRoot")}/${metricName}`
        );

        fetchMetricsFromBE<IMetrics<InvoiceImageMetric>>(
            Workspaces.Invoice,
            metricsRootList
        ).then((data) => {
            const groupedData = data.map((d, index) => {
                let results: IMetrics<InvoiceImageMetric> = {};
                Object.entries(d).forEach(
                    ([fieldName, fieldValues]: [string, any]) => {
                        let items: any = {};
                        Object.values(fieldValues).forEach(
                            (val: any, i: number) => {
                                const itemID = fieldName.includes("Items")
                                    ? `${val.fileName}_${val.groundtruthInstanceId}${COLUMN_KEY_SEPARATOR}${i}`
                                    : `${val.fileName}${COLUMN_KEY_SEPARATOR}${i}`;
                                const language =
                                    recordDetails[index].getRawProp<string>(
                                        "language"
                                    );
                                const datasetName =
                                    recordDetails[index].dataset.name;
                                val.ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/files/evalrecord/${this.props.records[index].runtimeVersion}/vis/invoice/${language}/${datasetName}/${val.fileName}.page1.invoice_fott.html?visibleLayers=byv`;
                                val["itemID"] = itemID;
                                items[itemID] = val;
                            }
                        );
                        results[fieldName] = items;
                    }
                );
                return results;
            });

            const merged = mergeMetrics<InvoiceImageMetric>(groupedData);
            const entityList = this.getEntityList(merged);
            this.setState({
                evalData: merged,
                entityList: entityList,
            });
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { toSelectLanguage, toSelectField } = this.props;
        const languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            const language = this.loadSelectedLanguage(
                toSelectLanguage,
                languageList
            );

            const stateSettings: { [key: string]: string | undefined } =
                toSelectField
                    ? {
                          selectLanguage: language,
                          selectField: toSelectField,
                      }
                    : { selectLanguage: language };

            this._onOptionsChanged(stateSettings);
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );
            this.showEvaluationResult(details, "prediction_value_detail.json");
        }
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        const selectField =
            "selectField" in newOptions
                ? newOptions["selectField"]
                : this.state.selectField;
        this._onQueryButtonClicked(selectLanguage);
        this.setState({
            selectLanguage: selectLanguage,
            selectField: selectField,
        });
    }

    private _renderTableHeader(): JSX.Element {
        const { entityList, matchDatasetVersion, selectField, selectLanguage } =
            this.state;
        const languages = this.getLanguageList(matchDatasetVersion!);
        const selectLangKey = this.loadSelectedLanguage(
            selectLanguage,
            languages
        );
        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                styles: dropdownStyles,
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: "fields",
                text: "Field:",
                options: entityList,
                selectedKey:
                    selectField && entityList.includes(selectField)
                        ? selectField
                        : entityList[0],
                onChange: (field) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectField: field!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<InvoiceImageMetric>
    ): string[] {
        return Object.keys(evalData).sort();
    }
}
