import * as React from "react";
import "./OverviewTable.scss";
import "./TableList.scss";

import {
    // DetailsList
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsRowStyles,
    SelectionMode,
    ConstrainMode,

    // Stack
    Stack,

    // others
    getTheme,
    Label,
    IDetailsRowProps,
} from "@fluentui/react";

import { calculateGap, formatNumberDisplay } from "../Utils";
import _ from "lodash";
import { ColumnValueType, TableColumn } from "./TableList";
import { formartTarget } from "./CalloutTable";

export interface ChildArray<T> extends Array<T> {
    childrens?: T[][];
}

export interface IGroupResult {
    childrenStat?: string;
    childrenStatKey?: string;
    [key: string]: any;
}

interface IProps {
    columns: IColumn[];
    evalData: [string, ChildArray<IGroupResult>[]];
    highlightKey?: string[];
    style?: React.CSSProperties;
    isDarkTheme?: boolean;
    hiddenTitle?: boolean;
    targetKeys?: string[];
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    onCellDoubleClick?: (item: any, column: IColumn, ev?: Event) => void;
}

interface IState {
    columns: IColumn[];
    childExpandStat: Map<string, boolean>;
    evalData: [string, ChildArray<IGroupResult>[]];
}

const theme = getTheme();
const WIDTH_ADJUSTMENT_FACTOR = 20;

export class GroupListItem extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            evalData: this.props.evalData,
            columns: this.props.columns,
            childExpandStat: new Map<string, boolean>(),
        };
    }

    public render() {
        return <>{this._renderList()}</>;
    }

    private _renderList = () => {
        const { evalData, columns } = this.state;
        const { style = { marginTop: "20px" }, hiddenTitle = false } =
            this.props;
        const sortItems = _.sortBy(evalData[1], (item) => item[0]?.entity);
        return (
            <div key={evalData[0]} style={style}>
                <Stack verticalFill className="expand">
                    {evalData[0] && !hiddenTitle && (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Label>{evalData[0]}</Label>
                        </Stack>
                    )}
                    <DetailsList
                        columns={columns}
                        items={sortItems as any}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderItemColumn={this._renderItemColumn}
                        onRenderRow={this._onRenderRow}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => false}
                        onItemInvoked={(
                            item?: any,
                            index?: number,
                            ev?: Event
                        ) => {
                            if (this.props.onItemInvoked) {
                                this.props.onItemInvoked(item, index, ev);
                            }
                        }}
                    />
                </Stack>
            </div>
        );
    };

    componentWillReceiveProps(nextProps: IProps, nextContext: any) {
        if (!_.isEqual(nextProps, this.props)) {
            this.setState({
                evalData: nextProps.evalData,
                columns: nextProps.columns,
            });
        }
    }

    shouldComponentUpdate(
        nextProps: IProps,
        nextState: IState,
        nextContext: any
    ): boolean {
        if (
            _.isEqual(nextProps, this.props) &&
            _.isEqual(nextState, this.state)
        ) {
            return false;
        }
        return true;
    }

    private _renderItemColumn = (
        item: IGroupResult[],
        index?: number,
        column?: IColumn
    ) => {
        const {
            highlightKey = [],
            targetKeys = [],
            onCellDoubleClick,
        } = this.props;
        const key = column!.fieldName;
        const metricColumn = column as TableColumn;
        if (key === "childrenStat") {
            const childrenStat = item[0]?.childrenStat;
            if (childrenStat && childrenStat !== "") {
                return (
                    <span
                        className="table__item"
                        key={`${key}_${index}_${JSON.stringify(item)}`}
                        style={{ cursor: "pointer" }}
                        onClick={(
                            event: React.MouseEvent<
                                Element,
                                globalThis.MouseEvent
                            >
                        ) => {
                            this._onRowItemInvoked(item, index);
                        }}
                    >
                        {childrenStat}
                    </span>
                );
            } else {
                return <></>;
            }
        } else {
            let displayText: any = <></>;
            if (metricColumn.valueType === ColumnValueType.String) {
                if (item && item.length > 0) {
                    const avlOcrEntity = item.find(
                        (result) =>
                            result &&
                            result[key as keyof IGroupResult]?.toString() !==
                                "NaN"
                    );

                    if (avlOcrEntity) {
                        displayText = (
                            <span className="table__item">
                                {avlOcrEntity[key as keyof IGroupResult]}
                            </span>
                        );
                    }
                }
            } else {
                const cellClass = highlightKey.includes(key as string)
                    ? "table__item_highlight"
                    : "table__item";
                const values = item.map((v) => {
                    return v[key as keyof IGroupResult] as number;
                });
                const base = values[values.length - 1];

                const defCellWidth = 100;
                const defCompareCount = 1;
                const compareCount =
                    values.length === 0 ? defCompareCount : values.length;
                const cellWidth = column?.maxWidth
                    ? (column.maxWidth - WIDTH_ADJUSTMENT_FACTOR) / compareCount
                    : defCellWidth;
                const cellStyle: React.CSSProperties = {
                    padding: "2px",
                    width: `${cellWidth}px`,
                    textAlign: "left",
                };
                displayText = values.map((val, idx) => {
                    if (val === base) {
                        return (
                            <span
                                className={cellClass}
                                style={cellStyle}
                                key={`${key}_${idx}`}
                            >
                                {formatNumberDisplay(val)}
                            </span>
                        );
                    } else {
                        return (
                            <span
                                className={cellClass}
                                style={cellStyle}
                                key={`${key}_${idx}`}
                            >
                                {val}
                                <span
                                    className={cellClass}
                                    style={{
                                        color: val > base ? "red" : "green",
                                        fontSize: "10px",
                                        position: "relative",
                                        marginTop: "0px",
                                    }}
                                >
                                    &nbsp;({calculateGap(val, base, 2)})
                                </span>
                            </span>
                        );
                    }
                });
            }

            if (metricColumn.supportsDoubleClick) {
                const targetValues = targetKeys.map((key) => item[0][key]);
                const targetId = formartTarget(
                    `${targetValues.join("-")}-${column?.key}`
                );
                return (
                    <div
                        id={targetId}
                        onDoubleClick={(ev) => {
                            onCellDoubleClick &&
                                onCellDoubleClick(
                                    item,
                                    metricColumn,
                                    ev as unknown as Event
                                );
                        }}
                    >
                        {displayText}
                    </div>
                );
            }

            return displayText;
        }
    };

    private _onRowItemInvoked = (item?: any, index?: number, ev?: Event) => {
        const items = item as ChildArray<IGroupResult>;

        if (items?.length > 0) {
            const tag = items[0].childrenStatKey || index!.toString();
            let expandStat = _.cloneDeep(this.state.childExpandStat);

            if (expandStat.has(tag)) {
                const stat = expandStat.get(tag);
                expandStat.set(tag, !stat);
            } else {
                expandStat.set(tag, true);
            }

            this.setState({
                childExpandStat: expandStat,
            });
        }
    };

    private _onRenderRow = (
        rowProps: IDetailsRowProps | undefined
    ): JSX.Element => {
        if (rowProps !== undefined) {
            const [customStyles, isExpanded, hasChildren, childrenEntity2dArr] =
                this._renderRowByChildrenLv(rowProps);
            const { targetKeys } = this.props;
            let newProps = rowProps;
            if (targetKeys) {
                const targetValues = targetKeys.map(
                    (key) => rowProps.item[0][key]
                );
                const targetId = formartTarget(targetValues.join("-"));
                newProps = { ...rowProps, ...{ id: targetId } };
            }

            return (
                <>
                    <DetailsRow {...newProps} styles={customStyles} />
                    {isExpanded && hasChildren && (
                        <DetailsList
                            columns={this.props.columns}
                            items={childrenEntity2dArr}
                            onRenderItemColumn={this._renderItemColumn}
                            selectionMode={SelectionMode.none}
                            constrainMode={ConstrainMode.unconstrained}
                            layoutMode={DetailsListLayoutMode.justified}
                            onRenderRow={this._onRenderRow}
                            onShouldVirtualize={() => false}
                            onItemInvoked={(
                                item?: any,
                                index?: number,
                                ev?: Event
                            ) => {
                                if (this.props.onItemInvoked) {
                                    this.props.onItemInvoked(item, index, ev);
                                }
                            }}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    };

    private _renderRowByChildrenLv = (
        rowProps: IDetailsRowProps
    ): [Partial<IDetailsRowStyles>, boolean, boolean, IGroupResult[][]] => {
        let childrenEntity2dArr: IGroupResult[][] = [];
        const rowItems = rowProps?.item as ChildArray<IGroupResult>;
        const tag = rowItems[0].childrenStatKey || "";

        if (rowItems.length > 0 && rowItems.childrens) {
            childrenEntity2dArr = rowItems.childrens;
        }

        const isExpanded = this.state.childExpandStat.get(tag) === true;

        const hasChildren =
            childrenEntity2dArr && childrenEntity2dArr.length > 0;
        if (hasChildren) {
            rowItems[0].childrenStat = isExpanded ? "▼" : "▶";
        }
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rowProps.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralDark
                    : theme.palette.neutralLighterAlt,
            };
        } else {
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralPrimary
                    : theme.palette.white,
            };
        }

        return [customStyles, isExpanded, hasChildren, childrenEntity2dArr];
    };
}
