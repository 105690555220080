import * as React from "react";
import "./EvalInfoCard.scss";
import {
    DirectionalHint,
    Dropdown,
    IconButton,
    IIconProps,
    ITooltipHostStyles,
    ITextFieldProps,
    Link,
    Stack,
    TextField,
    TooltipHost,
    SwatchColorPicker,
    Callout,
    mergeStyleSets,
    getTheme,
    Checkbox,
} from "@fluentui/react";

import CopyToClipboard from "react-copy-to-clipboard";
import { ExpInfo } from "../DataContract/RecordImpl";
import {
    EvalInfoKeys,
    Record,
    WorkspaceGroups,
    Workspaces,
} from "../DataContract";
import { getColorByIndex } from "../Utils";
import { ChangeOrderBar, OrderType } from "./Common/ChangeOrderBar";
import { EditTagsBar } from "./Common/EditTagsBar";
import _ from "lodash";

interface IProps {
    recordIndex: number;
    showLabel: boolean;
    showAddButton: boolean;
    showRemoveButton: boolean;
    checked?: boolean;
    selectedAlgo?: string;
    showup?: boolean;
    showdown?: boolean;
    workspace?: string;
    visibleCalloutId?: string;
    record?: Record;
    isDarkTheme?: boolean;
    showCheckbox?: boolean;
    onRemove?: (record: Record) => void;
    onAdd?: (record: Record) => void;
    onOptionChange?: (name: string, value: string) => void;
    onChangevisibleCalloutId?: (id: string) => void;
    onChangeOrder?: (type: OrderType, recordId: string) => void;
    onCalloutDismiss?: () => void;
    setRecordTag?: (record: Record, tags: string[], itemId: string) => void;
    onSelected?: (id: string, isChecked: boolean) => void;
}

interface IState {
    isCalloutVisible: boolean;
    visibleCalloutId?: string;
    showWarning: boolean;
}

const addIcon: IIconProps = {
    iconName: "Add",
    styles: {
        root: {
            fontWeight: "bold",
        },
    },
};
const removeIcon: IIconProps = {
    iconName: "ChromeClose",
    styles: { root: { color: "#a4262c" } },
};

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
};

const styles = mergeStyleSets({
    callout: {
        width: 225,
        padding: "20px 24px",
        wordBreak: "break-word",
    },
});
const theme = getTheme();
export class EvalInfoCard extends React.Component<IProps, IState> {
    private _calloutTarget: HTMLElement | null | undefined;
    constructor(props: IProps) {
        super(props);
        this.onRenderlabel = this.onRenderlabel.bind(this);
        this.checkBoxOnChange = _.debounce(
            this.checkBoxOnChange.bind(this),
            500
        );
        this.state = {
            isCalloutVisible: false,
            visibleCalloutId: this.props.visibleCalloutId,
            showWarning: false,
        };
    }

    private checkBoxOnChange(id: string, isChecked: boolean) {
        if (this.props.onSelected) {
            this.props.onSelected(id, isChecked!);
        }
    }

    public render() {
        const {
            record,
            recordIndex,
            selectedAlgo,
            showLabel,
            showAddButton,
            showRemoveButton,
            workspace,
            onChangevisibleCalloutId,
            onChangeOrder,
            onOptionChange,
            checked,
            showup = true,
            showdown = true,
            showCheckbox = false,
        } = this.props;
        const VERTICAL_EXPINFO_FIELD_NAME = "expInfo";
        let stepRunId = "";
        if (workspace && WorkspaceGroups.Taipei.includes(workspace)) {
            const details = record!.getDetails();
            details
                .filter((d) => d)
                .forEach((detail) => {
                    const expInfo = detail.getRawProp<ExpInfo>(
                        VERTICAL_EXPINFO_FIELD_NAME
                    );
                    if (expInfo && expInfo.stepRunId !== null) {
                        stepRunId += expInfo.stepRunId + ";";
                    }
                });
        }
        return (
            <Stack
                horizontal
                verticalAlign="end"
                tokens={{ childrenGap: 4 }}
                style={
                    workspace && WorkspaceGroups.Taipei.includes(workspace)
                        ? { margin: "10px" }
                        : { marginBottom: "10px" }
                }
            >
                {showCheckbox && (
                    <Checkbox
                        styles={{ root: { marginBottom: "6px" } }}
                        inputProps={{ value: record?.id }}
                        checked={checked}
                        onChange={(
                            ev?: React.FormEvent<
                                HTMLElement | HTMLInputElement
                            >,
                            isChecked?: boolean
                        ) => {
                            this.checkBoxOnChange(
                                (ev?.target as HTMLInputElement).value,
                                isChecked!
                            );
                        }}
                    />
                )}
                <SwatchColorPicker
                    styles={{
                        root: { margin: 0 },
                        focusedContainer: { minWidth: "0" },
                    }}
                    cellWidth={16}
                    cellHeight={16}
                    columnCount={1}
                    cellShape={"square"}
                    colorCells={[
                        {
                            id: `color_${recordIndex}`,
                            color: getColorByIndex(recordIndex),
                        },
                    ]}
                />
                {/* TODO: {record?.modelInfo.length !== 0 && (
                {record?.runtimeVersion.length !== 0 && (
                {record?.amlRunId && (
                {record?.buildType && (
                {record?.testType && (
                {record?.algorithm.length !== 0 && (
                {record?.vertical && (
                {record?.id !== null && ( */}
                {(workspace && WorkspaceGroups.Taipei.includes(workspace)
                    ? record?.asEvalInfoCard().concat({
                          name: EvalInfoKeys.StepRunId,
                          value: stepRunId,
                          width: 355,
                      })
                    : record?.asEvalInfoCard().filter((c) => {
                          switch (workspace) {
                              case Workspaces.Invoice:
                                  return ![
                                      EvalInfoKeys.BuildSource,
                                      EvalInfoKeys.TestType,
                                  ].includes(c.name);
                              case Workspaces.Kvp:
                                  return ![EvalInfoKeys.ExpLink].includes(
                                      c.name
                                  );
                              default:
                                  return true;
                          }
                      }))!.map((field, index) => {
                    if (Array.isArray(field.value)) {
                        if (field.name === EvalInfoKeys.ExpLink) {
                            const links = field.value
                                .filter((v) => v)
                                .map((link, idx) => {
                                    return (
                                        <Link
                                            href={link}
                                            style={{
                                                margin: "5px",
                                                color: "#75b6e7",
                                            }}
                                            target="_blank"
                                            key={`link_${idx}`}
                                        >
                                            Link
                                        </Link>
                                    );
                                });
                            if (links && links.length > 0) {
                                return (
                                    <div key={`linkDiv_${index}`}>
                                        {showLabel && (
                                            <label
                                                className={
                                                    "ms-Label label-root"
                                                }
                                                style={{
                                                    color: this.props
                                                        .isDarkTheme
                                                        ? theme.palette.white
                                                        : theme.palette.black,
                                                }}
                                            >
                                                {field.name}
                                            </label>
                                        )}
                                        <div
                                            className={"fieldGroup"}
                                            style={{
                                                minWidth: field.width,
                                                textAlign: "left",
                                            }}
                                        >
                                            <Link
                                                style={{
                                                    margin: "5px",
                                                    color: "#75b6e7",
                                                }}
                                                target="_blank"
                                                onClick={(evt) => {
                                                    this._calloutTarget =
                                                        evt.target as HTMLElement;
                                                    this.setState({
                                                        isCalloutVisible: true,
                                                    });
                                                }}
                                            >
                                                Link
                                            </Link>
                                            {this.state.isCalloutVisible && (
                                                <Callout
                                                    directionalHint={
                                                        DirectionalHint.bottomCenter
                                                    }
                                                    className={styles.callout}
                                                    target={this._calloutTarget}
                                                    onDismiss={() => {
                                                        this.setState({
                                                            isCalloutVisible:
                                                                false,
                                                        });
                                                    }}
                                                    role="alert"
                                                >
                                                    {links.map((link: any) => {
                                                        return (
                                                            <Link
                                                                href={
                                                                    link.props
                                                                        .href
                                                                }
                                                                target="_blank"
                                                                style={{
                                                                    marginRight:
                                                                        "4px",
                                                                    color: "#75b6e7",
                                                                }}
                                                            >
                                                                Link
                                                            </Link>
                                                        );
                                                    })}
                                                </Callout>
                                            )}
                                        </div>
                                    </div>
                                );
                            } else if (workspace === Workspaces.Invoice) {
                                return <></>;
                            } else {
                                return <div style={{ width: "70px" }}></div>;
                            }
                        } else if (field.name === EvalInfoKeys.Tag) {
                            return (
                                <Stack key={`tagStack_${index}`} verticalFill>
                                    {showLabel && (
                                        <label
                                            className={"ms-Label label-root"}
                                            style={{
                                                color: this.props.isDarkTheme
                                                    ? theme.palette.white
                                                    : theme.palette.black,
                                            }}
                                        >
                                            {field.name}
                                        </label>
                                    )}

                                    <EditTagsBar
                                        edit
                                        existence
                                        classSuffix="_eval"
                                        values={field.value}
                                        record={record!}
                                        visibleCalloutId={
                                            this.state.visibleCalloutId ?? "-1"
                                        }
                                        setRecordTag={(tags: string[]) =>
                                            this._setRecordTag(
                                                tags,
                                                record!,
                                                record!.id
                                            )
                                        }
                                        onChangevisibleCalloutId={(
                                            id: string
                                        ) => {
                                            onChangevisibleCalloutId!(id);
                                        }}
                                    />
                                </Stack>
                            );
                        } else {
                            return (
                                <Dropdown
                                    key={`dropdown_${field.name}`}
                                    label={showLabel ? field.name : undefined}
                                    options={field.value.map((val) => {
                                        return {
                                            key: val,
                                            text: val,
                                        };
                                    })}
                                    multiSelect={false}
                                    style={{
                                        width: field.width,
                                    }}
                                    styles={{
                                        title: {
                                            backgroundColor: this.props
                                                .isDarkTheme
                                                ? theme.palette.neutralDark
                                                : theme.palette
                                                      .neutralLighterAlt,
                                        },
                                    }}
                                    defaultSelectedKey={
                                        field.name === EvalInfoKeys.Algorithm
                                            ? selectedAlgo ?? field.value[0]
                                            : field.value[0]
                                    }
                                    onChange={(_event, option) => {
                                        if (onOptionChange) {
                                            onOptionChange(
                                                field.name,
                                                option!.text
                                            );
                                        }
                                    }}
                                />
                            );
                        }
                    } else {
                        return (
                            <TooltipHost
                                content={field.value}
                                key={`toolTip_${index}`}
                                directionalHint={DirectionalHint.bottomCenter}
                                styles={tooltipStyles}
                                style={{
                                    width: field.width,
                                }}
                            >
                                <TextField
                                    styles={{
                                        wrapper: {
                                            width: field.width,
                                        },
                                    }}
                                    key={`text_${field.name}`}
                                    label={showLabel ? field.name : undefined}
                                    onRenderSuffix={this.onRenderlabel}
                                    value={record ? field.value : undefined}
                                />
                            </TooltipHost>
                        );
                    }
                })}
                {showRemoveButton && (
                    <IconButton
                        iconProps={removeIcon}
                        onClick={() => {
                            if (this.props.onRemove) {
                                this.props.onRemove(this.props.record!);
                            }
                        }}
                    />
                )}
                {showAddButton && (
                    <IconButton
                        iconProps={addIcon}
                        onClick={() => {
                            if (this.props.onAdd) {
                                this.props.onAdd(this.props.record!);
                            }
                        }}
                    />
                )}

                <ChangeOrderBar
                    showup={showup}
                    showdown={showdown}
                    onChangeOrder={(type) => {
                        if (onChangeOrder) {
                            onChangeOrder(type, record!.id);
                        }
                    }}
                />
            </Stack>
        );
    }
    static getDerivedStateFromProps(props: IProps, state: IState) {
        return { visibleCalloutId: props.visibleCalloutId };
    }
    private onRenderlabel(
        labelProps: ITextFieldProps | undefined
    ): JSX.Element {
        return (
            <CopyToClipboard text={labelProps!.value!}>
                <IconButton
                    className="itemKey"
                    iconProps={{
                        iconName: "Copy",
                        styles: { root: { color: "#4f4e4e" } },
                    }}
                />
            </CopyToClipboard>
        );
    }

    private _setRecordTag = (
        tags: string[],
        record: Record,
        itemId: string
    ) => {
        this.setState({
            showWarning: false,
        });
        const { setRecordTag } = this.props;
        if (setRecordTag) {
            setRecordTag(record, tags, itemId);
        }
    };
}
