import { ISettings } from "./ImageVisualizer";

// prettier-ignore
export const DEFAULT_SETTINGS: ISettings = {
    reco:           {name: "reco",          label: "Recognized",        fill: "#004e8c88",   strokeColor: "transparent", lineWidth: 2,},
    ref:            {name: "ref",           label: "Reference",         fill: "#8cbd1888",   strokeColor: "transparent", },
    filtered:       {name: "filtered",      label: "Filtered",          fill: "#a8324e88",   strokeColor: "transparent", },
    rejected:       {name: "rejected",      label: "Rejected",          fill: "#a8623288",   strokeColor: "transparent", },
    early_rejected: {name: "early_rejected",label: "EarlyRejected",     fill: "#a89b3288",   strokeColor: "transparent", },
    detected:       {name: "detected",      label: "Detected",          fill: "#02333288",   strokeColor: "transparent", },
    invalid:        {name: "invalid",       label: "Invalid(Notsure)",  fill: "#bfbfba88",   strokeColor: "transparent", },
    insert:         {name: "insert_error",  label: "InsertError",       fill: "transparent", strokeColor: "#ed1532",     isChecked: true,},
    delete:         {name: "delete_error",  label: "DeleteError",       fill: "transparent", strokeColor: "#55e612",     isChecked: true,},
    subs:           {name: "subs_error",    label: "SubsError",         fill: "transparent", strokeColor: "#1b58d1",     isChecked: true,},
    correct:        {name: "correct",       label: "Correct",           fill: "transparent", strokeColor: "black", },
};

// prettier-ignore
export const DETECTION_SETTINGS: ISettings = {
    reco:           {name: "reco",          label: "Recognized",        fill: "#004e8c88",   strokeColor: "transparent", lineWidth: 2,},
    ref:            {name: "ref",           label: "Reference",         fill: "#8cbd1888",   strokeColor: "transparent", },
    filtered:       {name: "filtered",      label: "Filtered",          fill: "#a8324e88",   strokeColor: "transparent", },
    rejected:       {name: "rejected",      label: "Rejected",          fill: "#a8623288",   strokeColor: "transparent", },
    early_rejected: {name: "early_rejected",label: "EarlyRejected",     fill: "#a89b3288",   strokeColor: "transparent", },
    detected:       {name: "detected",      label: "Detected",          fill: "#02333288",   strokeColor: "transparent", },
    invalid:        {name: "invalid",       label: "Invalid(Notsure)",  fill: "#bfbfba88",   strokeColor: "transparent", },
    FN:             {name: "FN",            label: "FN_0.6",            fill: "#faf43e88",   strokeColor: "transparent", isChecked: true},
    FD:             {name: "FD",            label: "FD_0.001",          fill: "#c129e388",   strokeColor: "transparent", isChecked: true},
    insert:         {name: "insert_error",  label: "InsertError",       fill: "transparent", strokeColor: "#ed1532",},
    delete:         {name: "delete_error",  label: "DeleteError",       fill: "transparent", strokeColor: "#55e612",},
    subs:           {name: "subs_error",    label: "SubsError",         fill: "transparent", strokeColor: "#1b58d1",},
    correct:        {name: "correct",       label: "Correct",           fill: "transparent", strokeColor: "black", },
};

// prettier-ignore
export const WORDALIGN_SETTINGS: ISettings = {
    reco:          {name: "reco",          label: "RecognizedWord",        fill: "#004e8c88",   strokeColor: "transparent", lineWidth: 2},
    ref:           {name: "ref",           label: "ReferenceWord",         fill: "#8cbd1888",   strokeColor: "transparent", },
    overlapped:    { name: "overlapped",   label: "OverlappedWords",       fill: "#faf43e88",   strokeColor: "transparent",},
    recall:        {name: "recall",        label: "recall_lt_95",          fill: "#a8324e88",   strokeColor: "transparent", isChecked: true,},
    overlap:       {name: "overlap",       label: "Overlap_gt_0",          fill: "#a8623288",   strokeColor: "transparent", isChecked: true,},
    precision:     {name: "precision",     label: "Precision_lt_100",      fill: "#a89b3288",   strokeColor: "transparent", },
    detected:      {name: "detected",      label: "Detected",              fill: "#02333288",   strokeColor: "transparent", },
};

// prettier-ignore
export const PREDICTION_SETTINGS: ISettings = {
    correct:     {name: "correct",        label: "PredCorrect",          fill: "transparent",   strokeColor: "#a8324e88", isChecked: true,},
    error:       {name: "error",          label: "PredError",            fill: "transparent",   strokeColor: "#a8623288", isChecked: true,},
};

// prettier-ignore
export const BARCODE_SETTINGS: ISettings = {
    reco: {name: "reco",  label: "Recognized",    fill: "#004e8c88",  strokeColor: "transparent",  isChecked: true },
    gt:   {name: "gt",    label: "Ground Truth",  fill: "#8cbd1888",  strokeColor: "transparent"},
};

// prettier-ignore
export const FIGURE_SETTINGS: ISettings = {
    reco:               {name: "reco",            label: "Recognized",            fill: "#004e8c88",   strokeColor: "transparent", lineWidth: 2},
    ref:                {name: "ref",             label: "Reference",             fill: "#8cbd1888",   strokeColor: "transparent", },
    falsealarm:         {name: "false_alarm",     label: "FalseAlarm",            fill: "transparent", strokeColor: "#ed1532", isChecked: true,},
    missdetection:      {name: "miss_detection",  label: "MissDetection",         fill: "transparent", strokeColor: "#55e612", isChecked: true,},
    gts:                {name: "gts",             label: "MultiGTs",              fill: "transparent", strokeColor: "#1b58d1", isChecked: true,},
    preds:              {name: "preds",           label: "MultiPreds",            fill: "transparent", strokeColor: "#a8324e", isChecked: true,},

};
