import React from "react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { OcrPodMetrics } from "../../Views/OcrPod/OcrPodMetrics";
import { LatencyMetrics } from "../../Views/Latency/LatencyMetrics";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class OcrPodPage extends React.Component<IProps, IState> {
    render(): React.ReactNode {
        const { isDarkTheme, records, selectedKey } = this.props;

        return (
            <>
                {selectedKey === "ocrPodGeneralMetrics" && (
                    <OcrPodMetrics
                        records={records}
                        isDarkTheme={isDarkTheme}
                    ></OcrPodMetrics>
                )}

                {selectedKey === "ocrPodLatencyMetrics" && (
                    <LatencyMetrics
                        saveSetKey={`${Workspaces.OcrPod}_${Typename.LatencyMetrics}`}
                        records={records}
                        isDarkTheme={isDarkTheme}
                        defaultCrossLanguage="Overall"
                    ></LatencyMetrics>
                )}
            </>
        );
    }
}
