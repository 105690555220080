import React, { Component } from "react";
import {
    Dropdown,
    getTheme,
    IDropdownOption,
    SearchBox,
} from "@fluentui/react";
import { from } from "linq-to-typescript";
import { ColumnValueType, TableColumn } from ".";
import _ from "lodash";
import "./FilterBar.scss";
import { Consumer } from "../Layout";

interface FitlerUnit {
    col: TableColumn;
    dropdownOptions: IDropdownOption[];
    selectedKeys: any[];
    dropdownWidth: number;
}

interface IState {
    queryString: string;
    fitlerUnitArr: FitlerUnit[];
}

interface IProps {
    allItems: any[];
    columns: TableColumn[];
    filterDict: Map<string | number, any[]>;
    queryString: string;
    isDarkTheme?: boolean;
    hiddenSearch?: boolean;
    updateDisplayItemsAndFilterDict: (
        items: any[],
        filterDict: Map<string | number, any[]>,
        queryString: string
    ) => void;
}

const DROPDOWN_DEF_WIDTH: number = 200;
const DROPDOWN_MAX_WIDTH: number = 500;
const KEY_ARRAY_PAIR_LENGTH: number = 2;
const KEY_OF_SElECT_ALL: string = "~$@_all_@$~";
const theme = getTheme();

export class FilterBar extends Component<IProps, IState> {
    private filterDict: Map<string | number, any[]>;
    private timeoutId: NodeJS.Timeout | null;

    constructor(props: IProps) {
        super(props);
        this.timeoutId = null;
        this._calcFilterUnits = this._calcFilterUnits.bind(this);
        this._filterItems = this._filterItems.bind(this);
        this._onSearchTextChanged = this._onSearchTextChanged.bind(this);

        this.filterDict =
            _.cloneDeep(this.props.filterDict) ??
            new Map<string | number, any[]>();

        this.state = {
            queryString: this.props.queryString,
            fitlerUnitArr: this._calcFilterUnits(),
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            const { queryString } = this.props;
            this.filterDict =
                _.cloneDeep(this.props.filterDict) ??
                new Map<string | number, any[]>();
            this.setState({ fitlerUnitArr: this._calcFilterUnits() });
            if (queryString !== this.state.queryString) {
                this.setState({
                    queryString: queryString,
                });
            }
        }
    }

    public render() {
        const { hiddenSearch = false } = this.props;
        return (
            <Consumer>
                {(value) => {
                    return (
                        <div
                            className="filterBox"
                            style={{
                                backgroundColor: value
                                    ? theme.palette.neutralDark
                                    : theme.palette.neutralLighter,
                            }}
                        >
                            <>
                                {!hiddenSearch && (
                                    <SearchBox
                                        value={this.state.queryString}
                                        onChange={this._onSearchTextChanged}
                                        className="searchInput"
                                        placeholder="Search"
                                    ></SearchBox>
                                )}
                            </>

                            {this.state.fitlerUnitArr &&
                                this.state.fitlerUnitArr.length > 0 &&
                                this.state.fitlerUnitArr.map((u, index) => {
                                    return (
                                        <FilterBarDropdown
                                            key={`filterDropdown_${index}`}
                                            className={
                                                !hiddenSearch
                                                    ? "dropdownCtrl"
                                                    : "dropdownCtrl2"
                                            }
                                            name={u.col.name}
                                            dropdownOptions={u.dropdownOptions}
                                            selectedKeys={u.selectedKeys}
                                            multiSelect={true}
                                            dropdownWidth={u.dropdownWidth}
                                            onChange={this._onDropdownChange.bind(
                                                this,
                                                u.col.name,
                                                u.dropdownOptions.map(
                                                    (o) => o.key
                                                )
                                            )}
                                        />
                                    );
                                })}
                        </div>
                    );
                }}
            </Consumer>
        );
    }

    public static filterItemsByColumnEnumAndQueryString(
        allItems: any[],
        columns: TableColumn[],
        filterDict: Map<string | number, any[]>,
        queryString: string
    ): any[] {
        const keyIndex = 0;
        const valueIndex = 1;
        let dataItems = _.cloneDeep(allItems);
        if (filterDict && filterDict.size > 0) {
            for (let [field, selectValues] of filterDict) {
                const column = columns.find((col) => col.fieldName === field);
                dataItems = dataItems.filter((item) => {
                    if (
                        Array.isArray(item) &&
                        item.length === KEY_ARRAY_PAIR_LENGTH
                    ) {
                        return item[valueIndex].some((subItem: any) => {
                            return (
                                subItem &&
                                field in subItem &&
                                selectValues.some((value) => {
                                    if (Array.isArray(subItem[field])) {
                                        return subItem[field].some(
                                            (fieldValue: any) =>
                                                this._equalOrInclude(
                                                    fieldValue,
                                                    value,
                                                    column
                                                )
                                        );
                                    } else {
                                        return this._equalOrInclude(
                                            subItem[field],
                                            value,
                                            column
                                        );
                                    }
                                })
                            );
                        });
                    } else {
                        return (
                            field in item &&
                            selectValues.some((value) => {
                                if (Array.isArray(item[field])) {
                                    return item[field].some((fieldValue: any) =>
                                        this._equalOrInclude(
                                            fieldValue,
                                            value,
                                            column
                                        )
                                    );
                                } else {
                                    return this._equalOrInclude(
                                        item[field],
                                        value,
                                        column
                                    );
                                }
                            })
                        );
                    }
                });
            }
        }

        if (queryString) {
            dataItems = dataItems.filter((item) => {
                if (Array.isArray(item) && item.length === 2) {
                    const keyObj = item[keyIndex];
                    if (keyObj) {
                        const keyStr = String(keyObj).toLowerCase();
                        if (keyStr.includes(queryString.toLowerCase())) {
                            return true;
                        }
                    }

                    return item[valueIndex].some((subItem: any) => {
                        return this._checkObjectWhetherIncludeQueryString(
                            queryString,
                            subItem,
                            columns
                        );
                    });
                } else {
                    return this._checkObjectWhetherIncludeQueryString(
                        queryString,
                        item,
                        columns
                    );
                }
            });
        }

        return dataItems;
    }

    //#region Event handler
    private _onDropdownChange(
        colName: string,
        allOptionKeys: (string | number)[],
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void {
        const { columns, updateDisplayItemsAndFilterDict } = this.props;

        let fieldName: string = "";
        let operateCols = columns.filter((col) => col.name === colName);
        if (operateCols?.length > 0) {
            fieldName = operateCols[0].fieldName!;
        }

        if (option?.selected === true) {
            if (this.filterDict.has(fieldName)) {
                let selectedOptions = this.filterDict.get(fieldName);
                if (selectedOptions && !selectedOptions.includes(option.key)) {
                    if (option.key === KEY_OF_SElECT_ALL) {
                        selectedOptions = allOptionKeys;
                    } else {
                        selectedOptions.push(option.key);

                        if (
                            selectedOptions.length + 1 ===
                            allOptionKeys.length
                        ) {
                            selectedOptions.push(KEY_OF_SElECT_ALL);
                        }
                    }
                    this.filterDict.set(fieldName, selectedOptions);
                }
            } else {
                if (option.key === KEY_OF_SElECT_ALL) {
                    this.filterDict.set(fieldName, allOptionKeys);
                } else {
                    const selectedOptions =
                        allOptionKeys.length === 2
                            ? allOptionKeys
                            : [option.key];

                    this.filterDict.set(fieldName, selectedOptions);
                }
            }
        } else if (option?.selected === false) {
            if (this.filterDict.has(fieldName)) {
                if (option.key === KEY_OF_SElECT_ALL) {
                    this.filterDict.delete(fieldName);
                } else {
                    let selectedOptions = this.filterDict.get(fieldName);
                    if (
                        selectedOptions &&
                        selectedOptions.includes(option.key)
                    ) {
                        selectedOptions = selectedOptions.filter(
                            (o) => o !== option.key && o !== KEY_OF_SElECT_ALL
                        );

                        if (selectedOptions.length > 0) {
                            this.filterDict.set(fieldName, selectedOptions);
                        } else {
                            this.filterDict.delete(fieldName);
                        }
                    }
                }
            }
        }

        let filterItems = this._filterItems();
        if (updateDisplayItemsAndFilterDict) {
            updateDisplayItemsAndFilterDict(
                filterItems,
                this.filterDict,
                this.state.queryString
            );
        }
    }

    private _onSearchTextChanged(
        event?: React.ChangeEvent<HTMLInputElement>,
        newValue?: string
    ): void {
        const queryStr = newValue ?? "";
        this.setState({ queryString: queryStr });

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            let filterItems = this._filterItems();
            if (this.props.updateDisplayItemsAndFilterDict) {
                this.props.updateDisplayItemsAndFilterDict(
                    filterItems,
                    this.filterDict,
                    queryStr
                );
            }

            this.timeoutId = null;
        }, 600);
    }
    //#endregion

    //#region Help method
    private _calcFilterUnits(): FitlerUnit[] {
        const { allItems, columns, updateDisplayItemsAndFilterDict } =
            this.props;
        if (allItems.length > 0 && columns.length > 0) {
            return columns
                .filter((col) => col && col.name && col?.filterable === true)
                .map((col) => {
                    const options = this._extractOptions(
                        allItems,
                        col.fieldName!,
                        col.type
                    );

                    let optionsArr = Array.from(new Set(options)).filter(
                        (o) => o !== null
                    );

                    optionsArr = from(optionsArr)
                        .orderBy((o) => o)
                        .toArray();

                    let selectedKeys: any[] = [];
                    let dropdownOptions: IDropdownOption[] = [];
                    if (
                        this.filterDict &&
                        optionsArr &&
                        optionsArr.length > 0
                    ) {
                        let selectedValuesInDict = this.filterDict.get(
                            col.fieldName!
                        );

                        if (selectedValuesInDict) {
                            const countableSelectedVals =
                                selectedValuesInDict.filter(
                                    (v) => v !== KEY_OF_SElECT_ALL
                                );

                            const availableSelectedValues =
                                countableSelectedVals.filter((s) =>
                                    optionsArr.includes(s)
                                );

                            if (
                                countableSelectedVals.length >
                                    availableSelectedValues.length ||
                                (selectedValuesInDict.includes(
                                    KEY_OF_SElECT_ALL
                                ) &&
                                    optionsArr.length >
                                        availableSelectedValues.length) ||
                                (!selectedValuesInDict.includes(
                                    KEY_OF_SElECT_ALL
                                ) &&
                                    optionsArr.length ===
                                        availableSelectedValues.length)
                            ) {
                                if (
                                    optionsArr.length ===
                                    availableSelectedValues.length
                                ) {
                                    availableSelectedValues.push(
                                        KEY_OF_SElECT_ALL
                                    );
                                }

                                if (availableSelectedValues.length > 0) {
                                    this.filterDict.set(
                                        col.fieldName!,
                                        availableSelectedValues
                                    );
                                } else {
                                    this.filterDict.delete(col.fieldName!);
                                }

                                const filterItems = this._filterItems();
                                if (updateDisplayItemsAndFilterDict) {
                                    updateDisplayItemsAndFilterDict(
                                        filterItems,
                                        this.filterDict,
                                        this.state.queryString
                                    );
                                }
                            }
                        }

                        dropdownOptions = optionsArr.map((option) => {
                            if (
                                selectedValuesInDict &&
                                selectedValuesInDict.includes(option)
                            ) {
                                selectedKeys.push(option);
                            }

                            return {
                                key: option,
                                text: option,
                            } as IDropdownOption;
                        });

                        dropdownOptions = [
                            {
                                key: KEY_OF_SElECT_ALL,
                                text: "Select All",
                            },
                            ...dropdownOptions,
                        ];

                        if (selectedValuesInDict?.includes(KEY_OF_SElECT_ALL)) {
                            selectedKeys.push(KEY_OF_SElECT_ALL);
                        }
                    }

                    let _dropdownWidth = col.maxWidth
                        ? col.maxWidth < DROPDOWN_MAX_WIDTH
                            ? col.maxWidth
                            : DROPDOWN_MAX_WIDTH
                        : DROPDOWN_DEF_WIDTH;

                    return {
                        col: col,
                        dropdownOptions: dropdownOptions,
                        selectedKeys: selectedKeys,
                        dropdownWidth: _dropdownWidth,
                    } as FitlerUnit;
                });
        } else {
            return [];
        }
    }

    private _extractOptions(
        items: any[],
        fieldName: string,
        type?: ColumnValueType
    ): any[] {
        if (type === ColumnValueType.List) {
            const values = items.flatMap((item) => {
                if (Array.isArray(item)) {
                    return this._extractOptions(item, fieldName, type);
                } else {
                    if (item && typeof item === "object" && fieldName in item) {
                        const value = item[fieldName];
                        return Array.isArray(value) ? value.join(" ") : value;
                    } else {
                        return null;
                    }
                }
            });
            return Array.from(new Set(values.join(" ").split(" ")));
        } else {
            return items.flatMap((item) => {
                if (Array.isArray(item)) {
                    return this._extractOptions(item, fieldName);
                } else {
                    if (item && typeof item === "object" && fieldName in item) {
                        const value = item[fieldName];
                        return Array.isArray(value) ? value.join(";") : value;
                    } else {
                        return null;
                    }
                }
            });
        }
    }

    private _filterItems(): any[] {
        const { allItems, columns } = this.props;
        return FilterBar.filterItemsByColumnEnumAndQueryString(
            allItems,
            columns,
            this.filterDict,
            this.state.queryString
        );
    }

    private static _checkObjectWhetherIncludeQueryString(
        queryStr: string,
        fromObj: any,
        columns: TableColumn[]
    ) {
        return columns.some((col) => {
            if (fromObj && col.fieldName && col.fieldName in fromObj) {
                const val = fromObj[col.fieldName];
                if (val) {
                    const lowerVal = String(val).toLowerCase();
                    return lowerVal.includes(queryStr.toLowerCase());
                }
            }

            return false;
        });
    }

    private static _equalOrInclude(
        fieldVal: any,
        selectedVal: any,
        column: TableColumn | undefined
    ) {
        return column?.valueType === ColumnValueType.List
            ? this._includeStr(fieldVal, selectedVal)
            : _.isEqual(fieldVal, selectedVal);
    }

    private static _includeStr(parent: any, child: any): boolean {
        return parent.toString().includes(child.toString());
    }
    //#endregion
}

interface BarDropdownIProps {
    className: string;
    name: string;
    dropdownOptions: IDropdownOption[];
    selectedKeys?: string[] | number[] | undefined;
    multiSelect?: boolean;
    dropdownWidth?: number | "auto" | undefined;
    onChange?:
        | ((
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption,
              index?: number
          ) => void)
        | undefined;
}

class FilterBarDropdown extends Component<BarDropdownIProps> {
    shouldComponentUpdate(
        nextProps: Readonly<BarDropdownIProps>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        if (
            _.isEqual(this.props.dropdownOptions, nextProps.dropdownOptions) &&
            _.isEqual(this.props.selectedKeys, nextProps.selectedKeys)
        ) {
            return false;
        }
        return true;
    }

    render() {
        const {
            className,
            name,
            dropdownOptions,
            selectedKeys,
            multiSelect,
            dropdownWidth,
            onChange,
        } = this.props;
        return (
            <Consumer>
                {(value) => (
                    <Dropdown
                        className={className}
                        styles={{
                            dropdownItems: {
                                color: value ? "white" : "black",
                            },
                        }}
                        key={name}
                        placeholder={name}
                        options={dropdownOptions}
                        defaultSelectedKeys={selectedKeys}
                        multiSelect={multiSelect}
                        dropdownWidth={dropdownWidth}
                        onRenderTitle={this._onRenderTitle}
                        onChange={onChange}
                    />
                )}
            </Consumer>
        );
    }

    private _onRenderTitle = (
        props?: IDropdownOption[],
        defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null
    ): JSX.Element | null => {
        if (!props) {
            return null;
        }

        return defaultRender!(
            props.filter((option) => option.key !== KEY_OF_SElECT_ALL)
        );
    };
}
