import { Workspaces } from "../../DataContract";
import { LatencyType } from "./CommonAnalysisView";
import {
    ILatencyDataItem,
    cloneIDataItem,
    languageToScript,
} from "./Interface";
import _ from "lodash";

// export const shortenMetricKeyName = (latency: any) => {
//     for (let key of Object.keys(latency)) {
//         if (typeof key === "string" && key !== "page_cnt") {
//             let value = latency[key];
//             delete latency[key];
//             latency[ShortenMetricKeyName(key as string)] = value;
//         }
//     }
// };

export const tryFindMatchedItems = (
    dataItems: ILatencyDataItem[],
    crossLanguage: boolean,
    recordIndex: number,
    matchDatasetVersion: boolean,
    dataRecordName: string,
    defaultCrossLanguage: string = "Unknown"
) => {
    // all item in this list will be merged into one single item
    const items = dataItems
        .filter((item) => {
            let match = false;
            if (!crossLanguage) {
                match = matchDatasetVersion
                    ? item.recordDetail.dataset.displayFullName ===
                      dataRecordName
                    : item.recordDetail.dataset.displayName === dataRecordName;
            } else {
                let language = item.recordDetail.dataset.language;
                match =
                    (languageToScript[language] ?? defaultCrossLanguage) ===
                    dataRecordName;
            }

            return match && item.recordIndex === recordIndex;
        })
        .filter((item) => !_.isEmpty(item.metrics.category_latency));

    return items;
};

const calculationDataItems = (
    category_latency: any,
    itemsToMerge: Array<ILatencyDataItem>,
    mergedItem: ILatencyDataItem,
    defaultCategory: string = "Total"
) => {
    if (category_latency) {
        let mergedPageCnt = category_latency["page_cnt"];
        itemsToMerge.forEach((element, index) => {
            if (index > 0 && !!element.metrics.category_latency) {
                let categoryLatencyToMerge: any;
                if (element.metrics.category_latency instanceof Array) {
                    categoryLatencyToMerge =
                        element.metrics.category_latency.find(
                            (e) => e["category"] === defaultCategory
                        );
                } else {
                    categoryLatencyToMerge = _.cloneDeep(
                        element.metrics.category_latency
                    );
                }
                if (!!categoryLatencyToMerge) {
                    for (let key of Object.keys(categoryLatencyToMerge)) {
                        if (key.toLowerCase().includes("linecount")) {
                            category_latency[key] +=
                                categoryLatencyToMerge[key];
                        } else if (key !== "category" && key !== "page_cnt") {
                            category_latency[key] =
                                category_latency[key] * mergedPageCnt +
                                categoryLatencyToMerge[key] *
                                    categoryLatencyToMerge["page_cnt"];
                            category_latency[key] =
                                category_latency[key] /
                                (category_latency["page_cnt"] +
                                    categoryLatencyToMerge["page_cnt"]);
                            category_latency[key] =
                                category_latency[key].toFixed(2);
                        }
                        // Use `key` and `value`
                    }
                    category_latency["page_cnt"] +=
                        categoryLatencyToMerge["page_cnt"];
                    mergedPageCnt = category_latency["page_cnt"];
                }
                let toMergePercentile = element.metrics.percentile_latency;
                if (toMergePercentile) {
                    toMergePercentile.forEach((percentileData, index) => {
                        if (mergedItem.metrics.percentile_latency[index]) {
                            // assume all language shares same percentile chart list
                            mergedItem.metrics.percentile_latency[
                                index
                            ].chartPoints =
                                mergedItem.metrics.percentile_latency[
                                    index
                                ].chartPoints.concat(
                                    percentileData.chartPoints
                                );
                        }
                    });
                }
            }
        });
    }
};

const mergeDataItemsOld = (
    itemsToMerge: Array<ILatencyDataItem>,
    defaultCategory: string = "Total"
) => {
    let mergedItem = cloneIDataItem(itemsToMerge[0]);
    if (mergedItem === undefined) {
        return mergedItem;
    }
    // only merge total category since different language might have different categories

    if (mergedItem.metrics.category_latency instanceof Array) {
        const category_latency: any = mergedItem.metrics.category_latency.find(
            (e) => e["category"] === defaultCategory
        );

        calculationDataItems(
            category_latency,
            itemsToMerge,
            mergedItem,
            defaultCategory
        );
    } else {
        const category_latency: any = mergedItem.metrics.category_latency;
        calculationDataItems(
            category_latency,
            itemsToMerge,
            mergedItem,
            defaultCategory
        );
    }

    return mergedItem;
};

const mergeDataItemsNew = (itemsToMerge: Array<any>, workSpace: string) => {
    let mergedItem = cloneIDataItem(itemsToMerge.find((m) => m.metrics));
    if (!mergedItem) return cloneIDataItem(itemsToMerge[0]);
    const categorylatencys = _.cloneDeep(
        itemsToMerge
            .flatMap((element) => element.metrics.category_latency)
            .filter((categoryLatency) => categoryLatency)
    );

    const result = categorylatencys.reduce((pre, cur) => {
        const keys = Object.keys(cur);

        if (!keys.includes("page_cnt")) {
            keys.push("page_cnt");
        }

        for (let key of keys) {
            if (key !== "category" && key !== "page_cnt") {
                pre[key as any] =
                    (pre[key as any] ?? 0) + cur[key] * (cur["page_cnt"] ?? 1);
            } else if (key === "page_cnt") {
                pre[key as any] = (pre[key as any] ?? 0) + (cur[key] ?? 1);
            } else {
                pre[key as any] = cur[key];
            }
        }

        return pre;
    }, {});

    for (let key in result) {
        if (key !== "category" && key !== "page_cnt") {
            let temp_obj: any = result;

            temp_obj[key] = temp_obj[key] / temp_obj["page_cnt"];
            temp_obj[key] = temp_obj[key].toFixed(2);
        }
    }
    if (mergedItem.metrics.category_latency instanceof Array) {
        mergedItem.metrics.category_latency = [result];
    } else {
        mergedItem.metrics.category_latency = result;
    }

    return mergedItem;
};

export const mergeDataItems = (
    itemsToMerge: Array<ILatencyDataItem>,
    workSpace: string,
    defaultCategory?: string,
    latencyType: LatencyType = 2
) => {
    if (
        latencyType === 2 ||
        ((latencyType === 3 || latencyType === 1) &&
            [
                Workspaces.Ocr,
                Workspaces.ReleaseTest,
                Workspaces.OcrCheckbox,
                Workspaces.OcrMath,
            ].includes(workSpace))
    ) {
        return mergeDataItemsOld(itemsToMerge, defaultCategory);
    } else if (
        (latencyType === 3 || latencyType === 1) &&
        [
            Workspaces.OcrTable,
            Workspaces.OcrPod,
            Workspaces.OcrFigure,
            Workspaces.Kvp,
        ].includes(workSpace)
    ) {
        return mergeDataItemsNew(itemsToMerge, workSpace);
    }
};

export const initLatency = (workSpace: string, category: string) => {
    if (workSpace === Workspaces.Ocr || workSpace === Workspaces.ReleaseTest) {
        return {
            category: category,
            page_cnt: NaN,
            _ocrms: NaN,
            "_ocrnativeperfnativemetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.linecount": NaN,

            "_ocrnativeperfnativemetrics.detectormetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.normalizermetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.analyzermetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.recognizermetrics.walltimems": NaN,
            datasetName: "",
        };
    } else if (workSpace === Workspaces.OcrCheckbox) {
        return {
            category: category,
            page_cnt: NaN,
            _checkboxMS: NaN,
            "checkboxnative.TotalMS": NaN,
            "checkboxnative.CheckboxImageResizeMS": NaN,

            "checkboxnative.InferenceModelMS": NaN,
            "checkboxnative.InferenceOtherMS": NaN,
            "checkboxnative.UnaccountedMS": NaN,
            "checkboxnative.NativeImgDecodeMS": NaN,
            _ocrMS: NaN,
            _msraTableMS: NaN,
        };
    } else if (workSpace === Workspaces.OcrMath) {
        return {
            category: category,
            page_cnt: NaN,
            _ocrms: NaN,
            "_ocrnativeperfnativemetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.linecount": NaN,

            "_ocrnativeperfnativemetrics.detectormetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.normalizermetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.analyzermetrics.totalms": NaN,
            "_ocrnativeperfnativemetrics.recognizermetrics.walltimems": NaN,
            datasetName: "",
        };
    } else if (workSpace === Workspaces.OcrTable) {
        return {
            "TableNative.DetectorMs.ImageResizeMs": NaN,
            "TableNative.DetectorMs.ImageRotationMs": NaN,
            "TableNative.DetectorMs.ModelMs": NaN,
            "TableNative.DetectorMs.TotalMs": NaN,
            "TableNative.DetectorMs.UnaccountedMs": NaN,
            "TableNative.RecognizerMs.ImageResizeMs": NaN,
            "TableNative.RecognizerMs.ModelMs": NaN,
            "TableNative.RecognizerMs.TotalMs": NaN,
            "TableNative.TableCount": NaN,
            "TableNative.TotalMs": NaN,
            "TableNative.UnaccountedMs": NaN,
            category: category,
            datasetname: "",
        };
    } else if (workSpace === Workspaces.OcrFigure) {
        return {
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.ImageResizeMs":
                NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.ImageRotationMs":
                NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.ModelMs": NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.PostProcessMs":
                NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.PreprocessMs":
                NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.TotalMs": NaN,
            "_figureNativePerf.FigureNativeMetrics.DetectorMs.UnaccountedMs":
                NaN,
            "_figureNativePerf.FigureNativeMetrics.FigureCount": NaN,
            "_figureNativePerf.FigureNativeMetrics.NativeImgDecodeMs": NaN,
            "_figureNativePerf.FigureNativeMetrics.TotalMs": NaN,
            "_figureNativePerf.FigureNativeMetrics.UnaccountedMs": NaN,
            _totalMS: NaN,
            category: category,
            datasetname: "",
        };
    } else if (workSpace === Workspaces.Kvp) {
        return {
            category: category,
            page_cnt: NaN,

            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.AdapterTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.AnalyzeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.BatchCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.EncodeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.LabelTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.LoadAdapterTotalMs":
                NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.QueryCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.SampleCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.TokenCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.TokenizeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.WordCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.TotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.AdapterTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.AnalyzeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.BatchCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.EncodeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.LabelTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.LoadAdapterTotalMs":
                NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.QueryCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.SampleCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.TokenCount": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.TokenizeTotalMs": NaN,
            "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.WordCount": NaN,
            _totalMS: NaN,
        };
    }
};
