import React from "react";
import "../Common/MetricStyle.scss";
import "../Common/ImageMetrics.scss";
import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";

import { OcrTableOverview } from "./OcrTableOverview";
import { OcrTableImageMetrics } from "./OcrTableImageMetrics";
import { Typename, Workspaces } from "../../DataContract";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { ICommonProps } from "../Common/CommonMetrics";

interface IProps extends ICommonProps {
    analysisOn?: boolean;
}

interface IState {
    selectedPivot: string;
}

export class OcrTableMetrics extends React.Component<IProps, IState> {
    private subType?: string;
    private selectLanguage?: string;
    private definitionname?: string;
    constructor(props: IProps) {
        super(props);
        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            selectedPivot: "Overview",
        };
    }
    public render() {
        const { isDarkTheme, records, saveSetKey } = this.props;

        return (
            <div className="overview">
                <Pivot
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    onLinkClick={this.onPivotItemChanged}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <OcrTableOverview
                            records={records}
                            isDarkTheme={isDarkTheme}
                            saveSetKey={`${Workspaces.OcrTable}_${Typename.GeneralMetrics}_Overview`}
                            onItemInvoked={(data: string[]) => {
                                [
                                    this.definitionname,
                                    this.selectLanguage,
                                    this.subType,
                                ] = data;
                                if (data[0] === "det") {
                                    this.pivotItemChanged("ByImage");
                                }
                            }}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="By Image"
                        className="displayFlex"
                        itemKey="ByImage"
                    >
                        <OcrTableImageMetrics
                            records={records}
                            isDarkTheme={isDarkTheme}
                            saveSetKey={saveSetKey}
                            definitionname={this.definitionname}
                            selectLanguage={this.selectLanguage}
                            subType={this.subType}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    public onPivotItemChanged(item?: PivotItem): any {
        this.definitionname = undefined;
        this.selectLanguage = undefined;
        this.subType = undefined;
        this.pivotItemChanged(item?.props.itemKey!);
    }

    pivotItemChanged = (itemKey: string) => {
        if (itemKey === this.state.selectedPivot) {
            return;
        }
        this.setState({
            selectedPivot: itemKey!,
        });

        store.dispatch(updateSelectSubpivoteAction(itemKey!));
    };
}
