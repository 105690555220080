import React from "react";

import {
    //Others
    Label,
    Stack,
} from "@fluentui/react";

import {
    DatasetSet,
    Record,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { OverviewTable, TableColumn, ColumnValueType } from "../../Controls";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import AuthLink from "../../AuthComponent/AuthLink";

interface OcrCharResult {
    category: string;
    coverage: string;
    total: string;
}

interface OcrCharMetrics {
    character_coverage: Array<OcrCharResult>;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: OcrCharMetrics;
    url: string;
}

interface ICharDataItem {
    data?: IDataItem;
    char: OcrCharResult;
    coverageCount?: number;
}

interface IState extends ICommonState<OcrCharMetrics> {
    dataItems: IDataItem[];
}

interface IProps {
    records: Record[];
}

// prettier-ignore
const CHAR_COLUMNS: TableColumn[] = [
    { key: "category", name: "Category", fieldName: "category", valueType: ColumnValueType.String, minWidth: 300, maxWidth: 380, isResizable: true, distinctStr: true },
    { key: "coverage", name: "Coverage", fieldName: "coverage", valueType: ColumnValueType.String, minWidth: 300, maxWidth: 380, isResizable: true, },
    { key: "total", name: "Total", fieldName: "total", valueType: ColumnValueType.String, minWidth: 300, maxWidth: 380, isResizable: true, }
];

export class CharMetrics extends CommonView<
    ICommonProps,
    IState,
    OcrCharMetrics
> {
    constructor(props: IProps) {
        super(props);

        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);

        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: true,
        };
    }
    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${
                    this.props.saveSetKey ??
                    `${Workspaces.Ocr}_${Typename.CharMetrics}`
                }`,
                columns: CHAR_COLUMNS,
            })
        );
    }

    public render() {
        return (
            <FullScreen>
                <div style={{ overflow: "hidden auto", height: "100%" }}>
                    {this._renderAsTable()}
                </div>
            </FullScreen>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("char_coverage.json");
    }

    private _renderAsTable() {
        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;
        const columns: TableColumn[] = CHAR_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const data = this._prepareRenderData(matchDatasetVersion!);

        return data
            .sort((a: any, b: any) => b[1].length - a[1].length)
            .map(([datasetName, groupData, coverageCount], index) => {
                datasetName =
                    datasetName.slice(0, datasetName.indexOf(":")) +
                    "    Dataset: " +
                    datasetName.slice(
                        datasetName.indexOf(":") + 1,
                        datasetName.length
                    );

                const tableData = groupData.map(([_, charItems]) => {
                    return charItems.map((charItem) => charItem.char);
                });

                if (tableData) {
                    return (
                        <div
                            className="overview__detail"
                            key={`table_${datasetName}`}
                        >
                            <ExpandCard
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            >
                                {coverageCount.map((count) => {
                                    return (
                                        <Label>{`Coverage Occupy: ${count} of ${tableData.length}`}</Label>
                                    );
                                })}

                                <OverviewTable<OcrCharResult>
                                    evalData={tableData}
                                    columns={columns}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                            </ExpandCard>

                            {groupData[0][1].map((item, idx) => {
                                return (
                                    <Stack
                                        horizontal
                                        styles={{ root: { marginLeft: "1%" } }}
                                    >
                                        <AuthLink
                                            key={`charMetric_${idx}`}
                                            style={{
                                                marginLeft: "2px",
                                                color: "#75b6e7",
                                            }}
                                            url={item!.data?.url}
                                            target="_blank"
                                        >
                                            Overall CER Per Char
                                        </AuthLink>
                                    </Stack>
                                );
                            })}
                        </div>
                    );
                } else {
                    return (
                        <NoDataTip>
                            No Char Coverage Metrics Generated
                        </NoDataTip>
                    );
                }
            });
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    const url = item.recordDetail.composeMetricUrl(
                        "overall_cer_per_char.json"
                    );
                    item.url = url;
                    return (
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                    );
                });
                return item;
            });

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const chars = item?.metrics.character_coverage;
                        return chars ? chars.map((char) => char.category) : [];
                    })
                )
            ).filter(
                (entity) =>
                    entity !== "special character overall" &&
                    !entity.startsWith("Random")
            );

            const groupData = entities.map((category) => {
                // retrieve wer data
                const werItems = items.map((item) => {
                    let char: OcrCharResult | undefined;
                    if (item !== undefined) {
                        const { metrics } = item;

                        if (metrics && metrics.character_coverage) {
                            char = metrics.character_coverage.find(
                                (char) => char.category === category
                            );
                        }
                    }

                    if (char === undefined) {
                        char = {
                            category: category,
                            coverage: "0",
                            total: "0",
                        };
                    }
                    return {
                        data: item,
                        char: char,
                    } as ICharDataItem;
                });
                return [category, werItems] as [string, ICharDataItem[]];
            });
            const coverageCount = this.props.records.map((_, recordIndex) => {
                return groupData
                    .filter(([_, charItems]) => {
                        return ["100.00%", "--"].includes(
                            charItems[recordIndex].char.coverage
                        );
                    })
                    .filter((v) => v).length;
            });

            return [datasetName, groupData, coverageCount] as [
                string,
                [string, ICharDataItem[]][],
                number[]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }
}
