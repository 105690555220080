import { vdiRequest } from "./fetch";

const authUrl = async (url: string, loading = false) => {
    const authUrl = await (
        await vdiRequest(
            url,
            { method: "GET", header: { responseType: "blob" } },
            loading
        )
    )?.blob();
    return URL.createObjectURL(authUrl);
};
export default authUrl;
