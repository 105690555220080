import React from "react";
import {
    ColumnValueType,
    ITableConfigs,
    TableColumn,
    TableHeader,
    TableList,
} from "../../../Controls";
import {
    IDataItem,
    IMetricUnit,
    Record,
    Typename,
    Workspaces,
} from "../../../DataContract";
import {
    openImageInNewWindow,
    resetScrollBarOfScrollablePane,
} from "../../../Utils";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../../Common/CommonMetrics";
import _ from "lodash";
import { NoDataTip } from "../../../Controls/NoDataTip";
import { FullScreen } from "../../Common/FullScreen";
import { store } from "../../../../store";
import { updateStateAction } from "../../../../store/reducers/setting";

// prettier-ignore
export const BYIMAGE_COLUMNS: TableColumn[] = [
    { key: "image",      name: "Image",       fieldName: "image",       valueType: ColumnValueType.String, minWidth: 100, maxWidth: 250, isResizable: true, isIconOnly: false, distinctStr: true, },
    { key: "gt",         name: "gt",          fieldName: "gt",          valueType: ColumnValueType.String, minWidth: 80,  maxWidth: 180, isResizable: true, isIconOnly: false, distinctStr: true, },
    { key: "prediction", name: "Prediction​",  fieldName: "prediction",  valueType: ColumnValueType.String, minWidth: 100, maxWidth: 250, isResizable: true, isIconOnly: false, distinctStr: true, },
    { key: "count",      name: "Count",       fieldName: "count",       valueType: ColumnValueType.Number, minWidth: 80,  maxWidth: 180, isResizable: true, isIconOnly: false },
    { key: "ocrtoy",     name: "ocr toy url", fieldName: "ocrtoy",      valueType: ColumnValueType.Url,    minWidth: 100, maxWidth: 250, isResizable: true, isIconOnly: false, },
];

type ITableItem<T extends IMetricUnit> = [string, (T | null)[]];
interface IState extends ICommonState<any> {
    dataItems: IDataItem<any>[];
    selectGroup?: string;
    selectPrediction?: string;
    selectLanguage?: string;
    groupNames?: string[];
    predictionNames?: string[];
    byImageData?: Map<string, any[]>;
}

interface IProps extends ICommonProps {
    selectGroup?: string;
    selectPrediction?: string;
    selectLanguage?: string;
}

export class LogicalByImage extends CommonView<IProps, IState, any> {
    constructor(props: IProps) {
        super(props);

        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);
        const matchDatasetVersion =
            store.getState().settingReducer.matchDatasetVersion;
        this.state = {
            dataItems: [],
            matchDatasetVersion: matchDatasetVersion,
            selectLanguage:
                props.selectLanguage ||
                this._getUnionDistinctLangList(matchDatasetVersion)[0],
            selectGroup: props.selectGroup,
            selectPrediction: props.selectPrediction,
        };
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrPod}_${Typename.GeneralMetrics}_LogicalByImage`,
                selectedItems: BYIMAGE_COLUMNS.map((col) => col.key),
                columns: BYIMAGE_COLUMNS,
            })
        );
    }

    render() {
        const { records } = this.props;
        const { byImageData, selectedColumns } = this.state;
        let showColumns = BYIMAGE_COLUMNS;
        if (selectedColumns) {
            showColumns = BYIMAGE_COLUMNS.filter(
                (value) =>
                    selectedColumns.findIndex((col) => col === value.key) !== -1
            );
        }

        const tableKey = `${this.state.selectLanguage}_${this.state.selectGroup}_${this.state.selectPrediction}`;
        return (
            <FullScreen>
                {!_.isEmpty(byImageData) ? (
                    <TableList<any>
                        key={tableKey}
                        downloadTableTitle={tableKey}
                        columns={showColumns}
                        evalData={byImageData ?? {}}
                        evalDataCount={records.length}
                        renderTableHeader={this._renderTableHeader}
                        isDarkTheme={this.props.isDarkTheme}
                        onItemInvoked={this._onItemInvoked}
                    />
                ) : (
                    <NoDataTip>No Logical Role Data</NoDataTip>
                )}
            </FullScreen>
        );
    }

    _onItemInvoked = (item: ITableItem<any>, index?: number) => {
        this._onDownloadPicture(item[1][0].imageUrl + item[0]);
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ) {
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            (prevState?.matchDatasetVersion !== undefined &&
                !_.isEqual(
                    this.state?.matchDatasetVersion,
                    prevState?.matchDatasetVersion
                ))
        ) {
            this.setState(
                {
                    selectLanguage: this._getUnionDistinctLangList(
                        this.state.matchDatasetVersion!
                    )[0],
                },
                () => this.queryMetricsResult()
            );
        }

        if (!_.isEqual(this.state.dataItems, prevState.dataItems)) {
            const { selectGroup, selectPrediction } = this.state;
            let groupNames = this.getGroupNames();
            let groupStr: any = undefined;
            let predictionNames: any = undefined;
            let predictionName: any = undefined;
            if (groupNames && groupNames.length > 0) {
                groupStr =
                    selectGroup && groupNames.includes(selectGroup)
                        ? selectGroup
                        : groupNames[0];

                predictionNames = this.getPredictonNames(groupStr);
                if (predictionNames && groupNames.length > 0) {
                    predictionName =
                        selectPrediction &&
                        predictionNames.includes(selectPrediction)
                            ? selectPrediction
                            : predictionNames[0];
                }
            }

            this.setState(
                {
                    groupNames: groupNames,
                    selectGroup: groupStr,
                    predictionNames: predictionNames,
                    selectPrediction: predictionName,
                },
                () => {
                    this._prepareRenderData();
                }
            );
        }
    }

    getGroupNames = () => {
        return Array.from(
            new Set(
                this.state.dataItems.flatMap((item) =>
                    Object.values(item.metrics["details"] ?? {}).flatMap(
                        (v: any) => Object.keys(v ?? {})
                    )
                )
            )
        );
    };

    getPredictonNames = (groupName: string) => {
        return Array.from(
            new Set(
                this.state.dataItems.flatMap((item) =>
                    Object.values(item.metrics["details"] ?? {}).flatMap(
                        (v: any) => Object.keys(v[groupName] ?? {})
                    )
                )
            )
        );
    };

    componentWillUnmount() {
        this.setState = () => false;
    }

    queryMetricsResult(): void {
        const { selectLanguage } = this.state;
        if (selectLanguage) {
            this._queryMetricsResult(
                "logical_role_distribution.json",
                selectLanguage
            );
        }
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const statePick: Pick<
            IState,
            "selectLanguage" | "selectGroup" | "selectPrediction"
        > = {};

        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : undefined;
        if (selectLanguage) {
            statePick.selectLanguage = selectLanguage;
            this.setState(statePick, this.queryMetricsResult);
        } else {
            const selectGroup =
                "selectGroup" in newOptions
                    ? newOptions["selectGroup"]
                    : this.state.selectGroup;

            const selectPre =
                "selectPrediction" in newOptions
                    ? newOptions["selectPrediction"]
                    : this.state.selectPrediction;

            statePick.selectGroup = selectGroup;
            statePick.selectPrediction = selectPre;
            this.setState(statePick, () => {
                this._prepareRenderData();
                resetScrollBarOfScrollablePane();
            });
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const {
            matchDatasetVersion,
            selectGroup,
            groupNames,
            selectLanguage,
            predictionNames,
            selectPrediction,
        } = this.state;
        const languages = this._getUnionDistinctLangList(matchDatasetVersion!);
        const selectLangKey = this._loadSelectedLanguage(
            selectLanguage,
            languages
        );

        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];

        if (groupNames && groupNames.length > 0) {
            imageConfigurations.push({
                key: "gt",
                text: "Gt:",
                options: groupNames,
                selectedKey: selectGroup ?? groupNames[0],
                onChange: (group) => {
                    this._onOptionsChanged({
                        selectGroup: group.text,
                    });
                },
            });
        }

        if (predictionNames && predictionNames.length > 0) {
            imageConfigurations.push({
                key: "prediction",
                text: "Prediction:",
                options: predictionNames,
                selectedKey: selectPrediction ?? predictionNames[0],
                onChange: (group) => {
                    this._onOptionsChanged({
                        selectPrediction: group.text,
                    });
                },
            });
        }

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _prepareRenderData = () => {
        const { records } = this.props;
        const {
            dataItems,
            matchDatasetVersion,
            selectLanguage,
            selectGroup,
            selectPrediction,
        } = this.state;
        const itemsDict = new Map<string, any[]>();
        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0 &&
            selectLanguage &&
            selectGroup &&
            selectPrediction
        ) {
            const colCount = records.length;
            const dataField = "details";

            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        selectLanguage ===
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName)
                    );
                });
                return item;
            });

            items.forEach((item) => {
                if (item && item.metrics && item.metrics[dataField]) {
                    const colIndex = item.recordIndex;
                    const metricsArr = Object.entries<any>(
                        item.metrics[dataField]
                    );

                    const filterMetricsArr = metricsArr.filter(
                        ([imgKey, obj]) => {
                            return obj?.[selectGroup]?.[selectPrediction];
                        }
                    );

                    filterMetricsArr.forEach(([imgKey, obj]) => {
                        const dataItem: any = {};
                        dataItem["image"] = imgKey;
                        dataItem["gt"] = selectGroup;
                        dataItem["prediction"] = selectPrediction;

                        const record = records[item.recordIndex];
                        dataItem["ocrtoy"] = this._generateOcrtoyUrl(
                            record,
                            item,
                            imgKey
                        );

                        dataItem["imageUrl"] = this.getImageUrl(
                            item.recordDetail.dataset.name,
                            item.recordDetail.dataset.version,
                            ""
                        );
                        let defaultItem: any = {};
                        Object.keys(dataItem).forEach((key) => {
                            defaultItem[key] = dataItem[key];
                        });

                        dataItem["count"] =
                            obj[selectGroup]?.[selectPrediction];

                        let itemArr = itemsDict.has(imgKey)
                            ? itemsDict.get(imgKey)
                            : Array.from({ length: colCount }, () => {
                                  return defaultItem;
                              });

                        itemArr![colIndex] = dataItem;
                        itemsDict.set(imgKey, itemArr!);
                    });
                }
            });
        }

        this.setState({ byImageData: itemsDict });
    };

    private _generateOcrtoyUrl(
        record: Record,
        item: IDataItem<any>,
        imgFileName: string
    ): string {
        const algo = item.recordDetail.getRawProp<string>("algorithm");
        const path = `pod/${record.modelInfo}/${record.runtimeVersion}/${record.buildSource}/${algo}/${item.recordDetail.dataset.name}/${item.recordDetail.dataset.name}__${item.recordDetail.dataset.version}/ocr_results/${imgFileName}`;
        let ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/${path}`;

        return ocrToyUrl;
    }

    private _onDownloadPicture(item: string): void {
        if (item) {
            openImageInNewWindow(item, this.state.selectLanguage);
            return;
        }
        alert("No url for this image");
    }

    getImageUrl(
        datasetName: string,
        version: string,
        imageName: string
    ): string {
        let fileDic: string = "GT_VIS";

        return `/api/datasets/${datasetName}/versions/${version}/tree/${fileDic}/${imageName}`;
    }
}
