import React from "react";
import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import { OverviewTable } from "../../Controls/OverviewTable";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
    VerticalBarChart,
} from "../../Controls";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
} from "office-ui-fabric-react";
import { formatPercentDisplay, getColorByIndex } from "../../Utils";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface InvoiceFieldMetrics {
    FieldName: string;
    TruePos: number;
    TrueNeg: number;
    FalsePosGtNull: number;
    FalsePosGtNotNull: number;
    FalseNeg: number;
    Precision: number;
    Recall: number;
    F1: number;
    Accuracy: number;
    DatasetName?: string;
}

interface InvoiceConfMetrics {
    category: string;
    "ece%": number;
    "ace%": number;
}

interface InvoiceMetrics {
    fieldMetrics: Array<InvoiceFieldMetrics>;
    confMetrics?: Array<InvoiceConfMetrics>;
}

interface IInvoiceFieldDataItem {
    data?: IDataItem;
    fieldMetrics: InvoiceFieldMetrics;
}

interface IInvoiceConfDataItem {
    data?: IDataItem;
    confMetrics: InvoiceConfMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: InvoiceMetrics;
}

interface IState extends ICommonState<InvoiceMetrics> {
    dataItems: IDataItem[];
    selectedPivot: string;
}

interface IProps extends ICommonProps {
    invoiceImageViewDeepLinkHandler?: (key: string, linkData: any) => void;
}

// prettier-ignore
const INVOICE_FIELD_COLUMNS: TableColumn[] = [
    { key: "FieldName",          name: "FieldName",          fieldName: "FieldName",          valueType: ColumnValueType.String,  minWidth: 120, maxWidth: 160,  isResizable: true,  distinctStr: true, },
    { key: "TruePos",            name: "TruePos",            fieldName: "TruePos",            valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "TrueNeg",            name: "TrueNeg",            fieldName: "TrueNeg",            valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "FalsePosGtNull",     name: "FalsePosGtNull",     fieldName: "FalsePosGtNull",     valueType: ColumnValueType.Number,  minWidth: 120, maxWidth: 140,  isResizable: true,  },
    { key: "FalsePosGtNotNull",  name: "FalsePosGtNotNull",  fieldName: "FalsePosGtNotNull",  valueType: ColumnValueType.Number,  minWidth: 120, maxWidth: 140,  isResizable: true,  },
    { key: "FalseNeg",           name: "FalseNeg",           fieldName: "FalseNeg",           valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  },
    { key: "Precision",          name: "Precision",          fieldName: "Precision",          valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 },
    { key: "Recall",             name: "Recall",             fieldName: "Recall",             valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 },
    { key: "F1",                 name: "F1",                 fieldName: "F1",                 valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 },
    { key: "Accuracy",           name: "Accuracy",           fieldName: "Accuracy",           valueType: ColumnValueType.Number,  minWidth: 60,  maxWidth: 140,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, maxDecimalPlaces: 2 },
];

// prettier-ignore
const INVOICE_CONF_COLUMNS: TableColumn[] = [
    { key: "category",  name: "Category",  fieldName: "category",  valueType: ColumnValueType.String,  minWidth: 120,  maxWidth: 240,  isResizable: true,  distinctStr: true, },
    { key: "ece%",      name: "ece%",      fieldName: "ece%",      valueType: ColumnValueType.Number,  minWidth: 60,   maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "ace%",      name: "ace%",      fieldName: "ace%",      valueType: ColumnValueType.Number,  minWidth: 60,   maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
];

export class InvoiceOverview extends CommonView<
    IProps,
    IState,
    InvoiceMetrics
> {
    constructor(props: IProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            matchDatasetVersion: true,
            dataItems: [],
            selectedPivot: "overviewInvoiceMetrics",
            expandItem: new Map<number, boolean>(),
            viewType: store.getState().globalReducer.viewType,
        };
    }

    public render() {
        const { viewType } = this.state;

        return (
            <div className="overview">
                <FullScreen>
                    {" "}
                    {viewType === "Table" && (
                        <div
                            style={{ overflow: "hidden auto", height: "100%" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}
                    {viewType === "Chart" && (
                        <div
                            style={{ overflow: "hidden auto", height: "100%" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </FullScreen>
            </div>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: INVOICE_FIELD_COLUMNS,
                saveKey: `${Workspaces.Invoice}_${Typename.InvoiceOverview}`,
            })
        );
    }
    exportAction = () => {
        exportOverviewListData(
            this.exportData.fieldMetrics,
            INVOICE_FIELD_COLUMNS,
            "InvoiceOverviewFieldMetrics"
        );

        exportOverviewListData(
            this.exportData.confMetrics,
            INVOICE_CONF_COLUMNS,
            "InvoiceOverviewConfMetrics"
        );
    };

    private _renderAsChart() {
        const { matchDatasetVersion } = this.state;

        const data = this._prepareRenderData(matchDatasetVersion!);

        return data.map(
            ([datasetName, recordFieldMetrics, recordConfMetrics], index) => {
                datasetName =
                    datasetName.slice(0, datasetName.indexOf(":")) +
                    "    Dataset: " +
                    datasetName.slice(
                        datasetName.indexOf(":") + 1,
                        datasetName.length
                    );

                const aceChartData = recordConfMetrics.map(
                    ([fieldName, metricsItems]) => {
                        const series = metricsItems.map(
                            (metricsItem, metricsIndex) => {
                                const wer = metricsItem.confMetrics["ace%"]
                                    ? metricsItem.confMetrics["ace%"]
                                    : 0;

                                return {
                                    key: `series_${metricsIndex}`,
                                    data: wer,
                                    color: getColorByIndex(metricsIndex),
                                    legend:
                                        metricsItem.data?.recordDetail.name ??
                                        "",
                                };
                            }
                        );
                        return {
                            name: fieldName,
                            series: series,
                        };
                    }
                );

                const f1ChartData = recordFieldMetrics.map(
                    ([fieldName, metricsItems]) => {
                        const series = metricsItems.map(
                            (metricsItem, metricsIndex) => {
                                const wer = metricsItem.fieldMetrics.F1
                                    ? metricsItem.fieldMetrics.F1
                                    : 0;

                                return {
                                    key: `series_${metricsIndex}`,
                                    data: wer,
                                    color: getColorByIndex(metricsIndex),
                                    legend:
                                        metricsItem.data?.recordDetail.name ??
                                        "",
                                };
                            }
                        );
                        return {
                            name: fieldName,
                            series: series,
                        };
                    }
                );

                return (
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card"
                        type={DocumentCardType.compact}
                    >
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                className="overview__title"
                                title={datasetName}
                            />

                            {aceChartData && aceChartData.length > 0 && (
                                <VerticalBarChart
                                    id={"conf" + index.toString()}
                                    xAxisName="Category"
                                    yAxisName="ace%"
                                    height={600}
                                    data={aceChartData}
                                    isDarkTheme={this.props.isDarkTheme}
                                ></VerticalBarChart>
                            )}
                            <VerticalBarChart
                                id={"root" + index.toString()}
                                xAxisName="FieldName"
                                yAxisName="F1"
                                height={600}
                                data={f1ChartData}
                                isDarkTheme={this.props.isDarkTheme}
                            ></VerticalBarChart>
                        </DocumentCardDetails>
                    </DocumentCard>
                );
            }
        );
    }

    private _renderAsTable() {
        this.exportData = { fieldMetrics: [], confMetrics: [] };

        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;
        const columns: TableColumn[] = INVOICE_FIELD_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderData(matchDatasetVersion!);
            return data.map(
                (
                    [datasetName, recordFieldMetrics, recordConfMetrics],
                    index
                ) => {
                    const fieldTableData = recordFieldMetrics.map(
                        ([_fieldName, fieldMetricsItems]) => {
                            return fieldMetricsItems.map((fieldMetricsItem) => {
                                let fieldMetrics =
                                    fieldMetricsItem.fieldMetrics;
                                if (fieldMetrics) {
                                    fieldMetrics.DatasetName = datasetName;
                                    fieldMetrics = formatPercentDisplay(
                                        fieldMetrics,
                                        [
                                            "F1",
                                            "Recall",
                                            "Accuracy",
                                            "Precision",
                                        ]
                                    );
                                }

                                return fieldMetrics;
                            });
                        }
                    );

                    if (fieldTableData && fieldTableData.length > 0) {
                        this.exportData.fieldMetrics.push([
                            `${datasetName}_FieldMetrics`,
                            fieldTableData,
                        ]);
                    }

                    const confTableData = recordConfMetrics.map(
                        ([_fieldName, confMetricsItems]) => {
                            return confMetricsItems.map(
                                (confMetricsItem) => confMetricsItem.confMetrics
                            );
                        }
                    );

                    if (confTableData && confTableData.length > 0) {
                        this.exportData.confMetrics.push([
                            `${datasetName}_ConfMetrics`,
                            confTableData,
                        ]);
                    }

                    return (
                        <div
                            className="overview__detail"
                            key={`table_${datasetName}`}
                        >
                            <ExpandCard
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            >
                                {confTableData && confTableData.length > 0 && (
                                    <OverviewTable<InvoiceConfMetrics>
                                        evalData={confTableData}
                                        columns={INVOICE_CONF_COLUMNS}
                                        tableTitle={""}
                                        downloadTableTitle={datasetName}
                                        isCompactStyle={true}
                                        isDarkTheme={this.props.isDarkTheme}
                                    />
                                )}
                                <OverviewTable<InvoiceFieldMetrics>
                                    evalData={fieldTableData}
                                    columns={columns}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    onItemInvoked={this._onItemInvoked}
                                    isCompactStyle={true}
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                            </ExpandCard>
                        </div>
                    );
                }
            );
        }
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const fieldNames = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const fieldMetrics = item?.metrics.fieldMetrics;
                        return fieldMetrics
                            ? fieldMetrics.map(
                                  (fieldMetric) => fieldMetric.FieldName
                              )
                            : [];
                    })
                )
            );

            const fieldGroupData = fieldNames.map((fieldName) => {
                // retrieve field data
                const werItems = items.map((item) => {
                    let fieldMetrics: InvoiceFieldMetrics | undefined;
                    if (item !== undefined) {
                        const { metrics } = item;
                        if (metrics && metrics.fieldMetrics) {
                            fieldMetrics = metrics.fieldMetrics.find(
                                (f) => f.FieldName === fieldName
                            );
                        }
                    }

                    if (fieldName === undefined || fieldMetrics === undefined) {
                        fieldMetrics = {
                            FieldName: fieldName,
                            TruePos: NaN,
                            TrueNeg: NaN,
                            FalsePosGtNull: NaN,
                            FalsePosGtNotNull: NaN,
                            FalseNeg: NaN,
                            Precision: NaN,
                            Recall: NaN,
                            F1: NaN,
                            Accuracy: NaN,
                        };
                    }

                    return {
                        data: item,
                        fieldMetrics: fieldMetrics,
                    } as IInvoiceFieldDataItem;
                });
                return [fieldName, werItems] as [
                    string,
                    IInvoiceFieldDataItem[]
                ];
            });

            let confGroupData: [string, IInvoiceConfDataItem[]][] = [];
            const confCategories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const confMetrics = item?.metrics.confMetrics;
                        return confMetrics && confMetrics.length > 0
                            ? confMetrics.map((c) => c.category)
                            : [];
                    })
                )
            );
            if (confCategories && confCategories.length > 0) {
                confGroupData = confCategories.map((confCategory) => {
                    // retrieve conf data
                    const werItems = items.map((item) => {
                        let confMetrics: InvoiceConfMetrics | undefined;
                        if (item !== undefined) {
                            const { metrics } = item;
                            if (metrics && metrics.confMetrics) {
                                confMetrics = metrics.confMetrics.find(
                                    (c) => c.category === confCategory
                                );
                            }
                        }

                        if (
                            confCategory === undefined ||
                            confMetrics === undefined
                        ) {
                            confMetrics = {
                                category: confCategory,
                                "ece%": NaN,
                                "ace%": NaN,
                            };
                        }

                        return {
                            data: item,
                            confMetrics: confMetrics,
                        } as IInvoiceConfDataItem;
                    });

                    return [confCategory, werItems] as [
                        string,
                        IInvoiceConfDataItem[]
                    ];
                });
            }

            return [datasetName, fieldGroupData, confGroupData] as [
                string,
                [string, IInvoiceFieldDataItem[]][],
                [string, IInvoiceConfDataItem[]][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    queryMetricsResult() {
        this._queryMetricsResult("prediction_accuracy_summary.json");
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { invoiceImageViewDeepLinkHandler } = this.props;

        if (invoiceImageViewDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as InvoiceFieldMetrics[];
                if (fieldMetricsArr?.length > 0) {
                    let linkData: { [key: string]: string | undefined } = {};
                    linkData.toSelectLanguage = fieldMetricsArr[0].DatasetName;
                    linkData.toSelectField = fieldMetricsArr[0].FieldName;
                    invoiceImageViewDeepLinkHandler(
                        "overviewImageMetrics",
                        linkData
                    );
                }
            }
        }
    }
}
