import * as React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
} from "react-router-dom";

import { Dashboard, MainPageWithRedux, NotFound } from "../Pages";
import { Layout } from "../Layout";
import { DataHomePage } from "../Controls/Data/DataHomePage";
import { ReleaseNote } from "../Pages/ReleaseNote";
import { ImageVisualizer } from "../Controls";
import { isDevEnvironment } from "../Utils/Utils";
import { OcrToy } from "../Pages/OcrToy";
import { indexDb } from "../Utils/IndexDB";
import { PowerBIHomePage } from "../Controls/PowerBI/PowerBIHomePage";
import { HtmlConvertorPage } from "../Controls/HtmlConvertor/HtmlConvertorPage";
import { IPublicClientApplication } from "@azure/msal-browser/dist";
import { MsalProvider } from "@azure/msal-react";
import { RedisClearPage } from "../Pages/RedisClear";
type AppProps = {
    pca: IPublicClientApplication;
};

class App extends React.Component<AppProps> {
    displayName = App.name;

    public componentDidMount() {
        window.addEventListener("beforeunload", this.onBeforeUnload);
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }
    private onBeforeUnload = () => {
        indexDb.deleteALl();
    };

    public render() {
        return (
            <MsalProvider instance={this.props.pca}>
                <Router>
                    <Switch>
                        <Route exact path="/ocrtoy" component={OcrToy} />

                        <Route exact>
                            <Layout>
                                <Router>
                                    <Switch>
                                        <Redirect
                                            exact
                                            path="/eval"
                                            to="/eval/ocr"
                                        />
                                        <Route
                                            path="/eval/ocrreleasetests"
                                            render={(props) => {
                                                // alias ocreleasetests to releasetest
                                                const pathname =
                                                    props.location.pathname.replace(
                                                        "/ocrreleasetests",
                                                        "/releasetest"
                                                    );
                                                return (
                                                    <Redirect
                                                        to={{
                                                            ...props.location,
                                                            pathname: pathname,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/eval/:workspace"
                                            component={Dashboard}
                                        />
                                        <Route
                                            exact
                                            path="/eval/:workspace/id/:id"
                                            component={MainPageWithRedux}
                                        />
                                        <Route
                                            exact
                                            path="/data"
                                            component={DataHomePage}
                                        />
                                        <Route
                                            exact
                                            path="/powerbi"
                                            component={PowerBIHomePage}
                                        />

                                        <Route
                                            exact
                                            path="/htmlConvertor"
                                            component={HtmlConvertorPage}
                                        />
                                        <Route
                                            exact
                                            path="/redisclear"
                                            component={RedisClearPage}
                                        />
                                        <Route
                                            exact
                                            path="/releaseNote"
                                            component={ReleaseNote}
                                        />

                                        {isDevEnvironment() && (
                                            <Route
                                                exact
                                                path="/viewer"
                                                render={() =>
                                                    this.renderViewer()
                                                }
                                            />
                                        )}
                                        <Route component={NotFound} />
                                    </Switch>
                                </Router>
                            </Layout>
                        </Route>
                    </Switch>
                </Router>
            </MsalProvider>
        );
    }

    renderViewer() {
        const experiments = ["test-exp"];
        return (
            <ImageVisualizer
                fileId="bizcard006929"
                experiments={experiments}
                onLoadVisualizer={() => "/ViewerDemo/bizcard006929.jpg"}
                onRequestPolygons={() =>
                    fetch("/ViewerDemo/polygons.json", {
                        method: "GET",
                    }).then((response) => response.json())
                }
                onRequestMetrics={() =>
                    fetch("/ViewerDemo/metrics.json", {
                        method: "GET",
                    }).then((response) => response.json())
                }
            />
        );
    }
}
export default App;
