import { vdiRequest } from "../Utils/fetch";
import { OcrEvaluationDataset } from "./OcrRecord";

export class FigureDataset extends OcrEvaluationDataset {
    getImageUrl(imageName: string): string {
        return `/api/datasets/${this.name}/versions/${this.version}/tree/JPEGImages/${imageName}`;
    }

    fetchImageListByCategory(category?: string): Promise<string[]> {
        let treeUrl = `/api/datasets/${this.name}/versions/${this.version}/tree/JPEGImages`;
        if (category) {
            treeUrl = `/api/datasets/${this.name}/versions/${this.version}/tree/JPEGImages/${category}`;
        }
        return vdiRequest(treeUrl, {
            method: "GET",
            headers: {
                "Cache-Control": "public, max-age=3600",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        // expect to return empty list rather than throw exception when not found
                        return undefined;
                    } else {
                        throw Error(response.statusText);
                    }
                }
                return response.json();
            })
            .then((tree: { files: { name: string }[] }) => {
                return tree ? tree.files.map((file) => file.name) : [];
            });
    }
}
