import React from "react";
import "../Common/ImageMetrics.scss";
import { Pivot, PivotItem, PivotLinkFormat } from "@fluentui/react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { ImageView, ImageViewType, OcrToyType } from "../Common/ImageView";
import { TABLE_IMAGE_METRICS_DEFINITIONS } from "./OcrTableDataInterface";
import { IMetricProps } from "../Common/MetricView";
import { IPivotItemDarkStyles, IPivotItemStyles } from "../Common/CommonStyle";
import { FullScreen } from "../Common/FullScreen";

const IGNORE_METRICS_NAMES = ["cell_type"];

interface IProps extends IMetricProps {
    records: Record[];
    isDarkTheme?: boolean;
    subType?: string;
    selectLanguage?: string;
    definitionname?: string;
}

interface IState {
    definitionname?: string;
}

export class OcrTableImageMetrics extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            definitionname: this.props.definitionname,
        };
    }
    render() {
        const { definitionname } = this.state;
        return (
            <FullScreen>
                <Pivot
                    className="pivotContainer displayFlex"
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={definitionname}
                    onLinkClick={(item) =>
                        this.setState({ definitionname: item?.props.itemKey })
                    }
                    styles={
                        this.props.isDarkTheme
                            ? IPivotItemDarkStyles
                            : IPivotItemStyles
                    }
                >
                    {TABLE_IMAGE_METRICS_DEFINITIONS.filter(
                        (metric) => !IGNORE_METRICS_NAMES.includes(metric.name)
                    ).map((meric) => {
                        return (
                            <PivotItem
                                key={`pivot_${meric.name}`}
                                headerText={meric.displayName}
                                itemKey={meric.name}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <ImageView
                                    key={`imgView_${meric.name}`}
                                    saveSetKey={`${Workspaces.OcrTable}_${Typename.GeneralMetrics}_ByImage_${meric.name}`}
                                    selectGroup={this.props.subType}
                                    selectLanguage={this.props.selectLanguage}
                                    dataSrcName={meric.name}
                                    records={this.props.records}
                                    imageViewType={ImageViewType.table}
                                    metricDefinitionArray={
                                        TABLE_IMAGE_METRICS_DEFINITIONS
                                    }
                                    ocrToyType={
                                        ["adj_tsr"].includes(meric.name)
                                            ? OcrToyType.cropped_prediction_oneocr
                                            : OcrToyType.prediction_oneocr
                                    }
                                    isDarkTheme={this.props.isDarkTheme}
                                ></ImageView>
                            </PivotItem>
                        );
                    })}
                </Pivot>
            </FullScreen>
        );
    }
}
