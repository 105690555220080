import "../Common/MetricStyle.scss";
import React from "react";

import {
    //Group&DetailList
    DetailsList,
    IColumn,
    SelectionMode,
    Label,
    Stack,
} from "@fluentui/react";

import { Record, RecordDetail } from "../../DataContract";
import { FullScreen } from "../Common/FullScreen";

interface ReliMetric {
    name: string;
    restarts: string;
    starts_at: string;
}

interface ReliRawResult {
    overviewReport: string;
    detailReports: string[];
}

interface IState {
    columns: IColumn[];
    data?: [RecordDetail, ReliMetric[]];
    displayItems?: ReliMetric[];
}

interface IProps {
    records: Record[];
}

const COLUMNS: IColumn[] = [
    {
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 500,
        maxWidth: 650,
        isResizable: true,
    },
    {
        key: "restarts",
        name: "Restarts",
        fieldName: "restarts",
        minWidth: 500,
        maxWidth: 650,
        isResizable: true,
    },
    {
        key: "start_at",
        name: "Start At",
        fieldName: "start_at",
        minWidth: 500,
        maxWidth: 650,
        isResizable: true,
    },
];

export class ReliDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._updateDisplayItems = this._updateDisplayItems.bind(this);
        this.state = {
            columns: COLUMNS,
        };
    }

    public render() {
        const { data, displayItems } = this.state;
        return (
            <FullScreen>
                {data && (
                    <Stack verticalFill style={{ overflow: "auto" }}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Label style={{ paddingLeft: "10px" }}>
                                Dashboard deployed from release:
                                {this.props.records.map((record) => {
                                    return record.id;
                                })}
                            </Label>
                            <Label>
                                {
                                    data[1].filter(
                                        (item) => Object.keys(item).length !== 0
                                    ).length
                                }
                                &nbsp;&nbsp;reliability test records
                            </Label>
                        </Stack>

                        <DetailsList
                            columns={this.state.columns}
                            items={
                                displayItems ??
                                data[1].filter(
                                    (item) => Object.keys(item).length !== 0
                                )
                            }
                            selectionMode={SelectionMode.none}
                            onColumnHeaderClick={(
                                ev?: React.MouseEvent<HTMLElement>,
                                column?: IColumn
                            ) => this._updateDisplayItems(data[1], column)}
                        />
                    </Stack>
                )}
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryMetricsResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryMetricsResult();
        }
    }

    public _queryMetricsResult() {
        if (this.props.records.length > 0) {
            const recordDetails = this.props.records[0].getDetails();
            if (recordDetails.length > 0) {
                const reliTestRawResult =
                    recordDetails[0].getRawProp<ReliRawResult>("reliTest");
                const report = reliTestRawResult?.detailReports.find((name) =>
                    name.endsWith("kubectlPods.txt")
                );
                if (report) {
                    recordDetails[0]
                        .fetchMetricsWithRecordDetail<ReliMetric[]>(report)
                        .then((data) => {
                            this.setState({
                                data: data,
                            });
                        });
                }
            }
        }
    }

    private _updateDisplayItems = (
        items: any[],
        sortByColumn?: IColumn
    ): void => {
        let columns: IColumn[] = COLUMNS;
        if (sortByColumn) {
            columns = COLUMNS.map((col) => {
                const newCol = { ...col };
                if (col.key === sortByColumn!.key) {
                    newCol.isSorted = true;
                    newCol.isSortedDescending =
                        !sortByColumn!.isSortedDescending;
                } else {
                    newCol.isSorted = undefined;
                    newCol.isSortedDescending = undefined;
                }
                return newCol;
            });
        } else {
            const result = columns.filter((col) => !!col.isSorted);
            if (result.length > 0) {
                sortByColumn = columns[0];
            }
        }

        if (sortByColumn !== undefined) {
            items = items
                .filter((it) => Object.keys(it).length !== 0)
                .sort((a: any, b: any) => {
                    const key = sortByColumn!.key;
                    let pre = String(a[key]);
                    let cur = String(b[key]);
                    let val = !!sortByColumn!.isSortedDescending
                        ? pre < cur
                        : pre > cur;
                    return val ? -1 : 1;
                });
        }

        this.setState({
            displayItems: items,
            columns: columns,
        });
    };
}
