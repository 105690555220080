import { SCRIPTS, EER_SCRIPTS, ENTITY_MAP, GENERAL_SCRIPTS } from "./Config";
import { fetchValidDataByPath } from "../../../Utils";
import { markHighlightLoadingError } from "../../../Utils/LoadingUtil";

export class EerInfo {
    public _eerCount: number;
    public _entityCount: number;
    public _ignoreSpaceEerCount: number;
    public _ignoreSpaceEntityCount: number;

    constructor(
        eerCount: number,
        entityCount: number,
        ignoreSpaceEerCount: number,
        ignoreSpaceEntityCount: number
    ) {
        this._eerCount = eerCount;
        this._entityCount = entityCount;
        this._ignoreSpaceEerCount = ignoreSpaceEerCount;
        this._ignoreSpaceEntityCount = ignoreSpaceEntityCount;
    }

    get empty(): EerInfo {
        return EerInfo.Empty();
    }

    dictInfo(): any {
        let eer = null;
        let ignoreSpaceEer = null;
        if (this._entityCount > 0) {
            eer = Number(
                ((this._eerCount / this._entityCount) * 100).toFixed(1)
            );
        }
        if (this._ignoreSpaceEntityCount > 0) {
            ignoreSpaceEer = Number(
                (
                    (this._ignoreSpaceEerCount / this._ignoreSpaceEntityCount) *
                    100
                ).toFixed(1)
            );
        }
        return {
            EER: eer,
            EEC: this._eerCount,
            IgnoreSpaceEER: ignoreSpaceEer,
            IgnoreSpaceEEC: this._ignoreSpaceEerCount,
        };
    }

    add(info: EerInfo) {
        this._eerCount += info._eerCount;
        this._entityCount += info._entityCount;
        this._ignoreSpaceEerCount += info._ignoreSpaceEerCount;
        this._ignoreSpaceEntityCount += info._ignoreSpaceEntityCount;
    }

    static Empty() {
        return new EerInfo(0, 0, 0, 0);
    }
}

export class HighlightEerMetrics {
    private _storageMap = new Map<string, string>();

    constructor(storageMap: Map<string, string>) {
        this._storageMap = storageMap;
    }

    get note(): string {
        return "We only calculate document scenario for entity in highlight page";
    }

    getEerFile(language: string, is_doc: boolean) {
        if (!this._storageMap.has(language)) {
            return [null, null];
        }
        let stem = is_doc ? "doc_entities_wer_cer" : "overall_entities_wer_cer";
        let compose_url = `/api/eval/blobs/${this._storageMap.get(language)}`;
        let eer_file = `${compose_url}/${stem}.json`;
        let ignoreSpaceEer_file = `${compose_url}/${stem}_lv1.json`;
        return [eer_file, ignoreSpaceEer_file];
    }

    async getEerMetricsByPath(file: any) {
        if (file) {
            try {
                let eerObj = await fetchValidDataByPath(file, 0);
                return eerObj ? JSON.parse(eerObj) : null;
            } catch (error) {
                console.log(error);
                markHighlightLoadingError();
                return null;
            }
        }
        return null;
    }

    getEerInfoWithMetric(
        eerMetrics: any,
        validEntities: Set<string>,
        checkValid: boolean
    ) {
        let eerCount = 0;
        let entityCount = 0;
        if (eerMetrics && eerMetrics["entity_wer"]) {
            for (let eer of eerMetrics["entity_wer"]) {
                if (!checkValid || validEntities.has(eer["entity"])) {
                    eerCount += Math.round(
                        (eer["sentenceCount"] * eer["sentenceErrorRate"]) / 100
                    );
                    entityCount += eer["sentenceCount"];
                }
            }
        }
        return [eerCount, entityCount];
    }

    async getEerInfoWithLanguage(
        language: string,
        validEntites: Set<string>,
        checkValid: boolean,
        isDoc: boolean
    ) {
        let [fEer, fIgnoreSpaceEer] = this.getEerFile(language, isDoc);
        let eerMetrics = await this.getEerMetricsByPath(fEer);
        let ignoreSpaceEerMetrics = await this.getEerMetricsByPath(
            fIgnoreSpaceEer
        );
        let eerRes = this.getEerInfoWithMetric(
            eerMetrics,
            validEntites,
            checkValid
        );
        let ignoreSpaceEerRes = this.getEerInfoWithMetric(
            ignoreSpaceEerMetrics,
            validEntites,
            checkValid
        );
        return new EerInfo(
            eerRes[0],
            eerRes[1],
            ignoreSpaceEerRes[0],
            ignoreSpaceEerRes[1]
        );
    }

    getEerInfoWithMetrics(
        metrics: any,
        ignoreSpaceMetrics: any,
        validEntites: Set<string>,
        checkValid: boolean
    ) {
        let eerRes = this.getEerInfoWithMetric(
            metrics,
            validEntites,
            checkValid
        );
        let ignoreSpaceEerRes = this.getEerInfoWithMetric(
            ignoreSpaceMetrics,
            validEntites,
            checkValid
        );
        return new EerInfo(
            eerRes[0],
            eerRes[1],
            ignoreSpaceEerRes[0],
            ignoreSpaceEerRes[1]
        );
    }

    async getVerticalEerInfoParallel(verticalEntities: {
        [key: string]: string[];
    }) {
        const VERTICAL_EER: { [key: string]: {} } = {};
        const scenarios = [];
        for (const scenario in verticalEntities) {
            scenarios.push(scenario);
        }
        const storageKeys = Array.from(this._storageMap.keys());
        const eerInfoArr = await Promise.all(
            scenarios.map(async (scenario) => {
                const highlights = verticalEntities[scenario];
                const eerInfo = EerInfo.Empty();
                const eerInfoDict = new Map<string, EerInfo>();
                const similarScenarios = storageKeys.filter((key) =>
                    key.startsWith(scenario)
                );

                if (similarScenarios.length > 0) {
                    for (const similarScenario of similarScenarios) {
                        const [similarEer, similarIgnoreSpaceEer] =
                            this.getEerFile(similarScenario, true);

                        if (
                            similarEer !== null &&
                            similarIgnoreSpaceEer !== null
                        ) {
                            const [
                                similarEerMetrics,
                                similarIgnoreSpaceEerMetrics,
                            ] = await Promise.all([
                                this.getEerMetricsByPath(similarEer),
                                this.getEerMetricsByPath(similarIgnoreSpaceEer),
                            ]);

                            const similarEerInfo = this.getEerInfoWithMetrics(
                                similarEerMetrics,
                                similarIgnoreSpaceEerMetrics,
                                new Set(),
                                false
                            );

                            eerInfo.add(similarEerInfo);

                            for (const entityDes of highlights) {
                                this.sumHighlightEerInfo(
                                    entityDes,
                                    similarEerMetrics,
                                    similarIgnoreSpaceEerMetrics,
                                    eerInfoDict
                                );
                            }
                        }
                    }
                }

                eerInfoDict.set(
                    `All_${scenario.split("_").slice(1).join("_")}`,
                    eerInfo
                );

                return eerInfoDict;
            })
        );

        const totalInfoDict = new Map<string, EerInfo>();
        while (eerInfoArr.length > 0) {
            const errInfo = eerInfoArr.pop()!;
            errInfo.forEach((info, des) => {
                if (totalInfoDict.has(des)) {
                    const existInfo = totalInfoDict.get(des)!;
                    existInfo.add(info);
                    totalInfoDict.set(des, existInfo);
                } else {
                    totalInfoDict.set(des, info);
                }
            });
        }

        if (totalInfoDict.size > 0) {
            totalInfoDict.forEach((info, des) => {
                VERTICAL_EER[des] = info.dictInfo();
            });
        }
        return Object.keys(VERTICAL_EER)
            .sort()
            .reduce((acc: { [key: string]: any }, key) => {
                acc[key] = VERTICAL_EER[key];
                return acc;
            }, {});
    }

    getLangs(isPrinted: boolean) {
        let langs: string[] = [];
        let key = isPrinted ? "Printed" : "Handwritten";
        for (let script of GENERAL_SCRIPTS) {
            langs = langs.concat(SCRIPTS[script][key]);
        }
        return langs;
    }

    async getGeneralEerInfoParallel(entities: string[], isPrinted: boolean) {
        let langs = this.getLangs(isPrinted);
        let EER_INFOS: { [key: string]: {} } = {};
        let metricsMap: { [key: string]: any[] } = {};
        await Promise.all(
            langs.map(async (lang) => {
                let [fEer, fIgnoreSpaceEer] = this.getEerFile(lang, true);
                let eerMetrics = await this.getEerMetricsByPath(fEer);
                let ignoreSpaceEerMetrics = await this.getEerMetricsByPath(
                    fIgnoreSpaceEer
                );
                metricsMap[lang] = [eerMetrics, ignoreSpaceEerMetrics];
            })
        );

        await Promise.all(
            entities.map((entity) => {
                let eerInfo = new EerInfo(0, 0, 0, 0);
                langs.forEach((lang) => {
                    eerInfo.add(
                        this.getEerInfoWithMetrics(
                            metricsMap[lang][0],
                            metricsMap[lang][1],
                            new Set([entity]),
                            true
                        )
                    );
                });
                let entityDes = entity.replace("TextAnalyticsAPI", "TA");
                if (isPrinted) {
                    EER_INFOS[entityDes] = eerInfo.dictInfo();
                } else {
                    EER_INFOS[`Hwr_${entityDes}`] = eerInfo.dictInfo();
                }
                return null;
            })
        );
        return Object.keys(EER_INFOS)
            .sort()
            .reduce((acc: { [key: string]: any }, key) => {
                acc[key] = EER_INFOS[key];
                return acc;
            }, {});
    }

    async getEerInfoParallel() {
        let EER: { [key: string]: {} } = {};
        let keys: string[] = ["Vertical Entity", "General Entity"];

        let printedEntities = EER_SCRIPTS["GeneralEntity"][
            "PrintedHandwritten"
        ].concat(EER_SCRIPTS["GeneralEntity"]["PrintedOnly"]);
        let handwrittenEntities = EER_SCRIPTS["GeneralEntity"][
            "PrintedHandwritten"
        ].concat(EER_SCRIPTS["GeneralEntity"]["HandwrittenOnly"]);

        let entitiesList = [
            EER_SCRIPTS["VerticalEntity"],
            printedEntities,
            handwrittenEntities,
        ];
        await Promise.all(
            entitiesList.map((_, index) => {
                switch (index) {
                    case 0:
                        return this.getVerticalEerInfoParallel(
                            EER_SCRIPTS["VerticalEntity"]
                        );
                    case 1:
                        return this.getGeneralEerInfoParallel(
                            printedEntities,
                            true
                        );
                    case 2:
                        return this.getGeneralEerInfoParallel(
                            handwrittenEntities,
                            false
                        );
                    default:
                        return this.getVerticalEerInfoParallel(
                            EER_SCRIPTS["VerticalEntity"]
                        );
                }
            })
        ).then((result) => {
            EER[keys[0]] = result[0]!;
            EER[keys[1]] = Object.assign({}, result[1]!, result[2]!);
        });

        return EER;
    }

    sumHighlightEerInfo(
        entityDes: string,
        eerMetrics: any,
        ignoreSpaceEerMetrics: any,
        eerInfoDict: Map<string, EerInfo>
    ) {
        const validEntities = new Set<string>();
        let prefix = entityDes.split("_", 1)[0] + "_";
        prefix = entityDes.includes("ReceiptNPD") ? "Receipt_" : prefix;
        prefix = entityDes === "IdDocument_mrz" ? "" : prefix;
        prefix.replace("IdDocument", "DriverLicense");
        for (let validEntity of ENTITY_MAP[entityDes]) {
            validEntities.add(`${prefix}${validEntity}`);
        }
        const highlightEerInfo = this.getEerInfoWithMetrics(
            eerMetrics,
            ignoreSpaceEerMetrics,
            validEntities,
            true
        );

        if (eerInfoDict.has(entityDes)) {
            const existHighlightErrInfo = eerInfoDict.get(entityDes)!;
            existHighlightErrInfo.add(highlightEerInfo);
            eerInfoDict.set(entityDes, existHighlightErrInfo);
        } else {
            eerInfoDict.set(entityDes, highlightEerInfo);
        }
    }
}
