import "./DataHomePage.scss";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import SplitPane from "react-split-pane";
import {
    INavLink,
    MessageBar,
    MessageBarType,
    Nav,
    SearchBox,
    StickyPositionType,
    Sticky,
    ScrollablePane,
    IconButton,
    IIconProps,
} from "@fluentui/react";

import { Content } from "./Content";
import {
    queryDatasetCatalog,
    syncDatasetCatalog,
    IOcrDatasetCatalog,
    IOcrDatasetDisplayInfo,
    UnauthorizedError,
} from "./Request";
import { Consumer } from "../../Layout";

interface DatasetInfoParams {
    name: string;
    version?: string;
}

export interface IHomePageState {
    datasetCatalog: IOcrDatasetCatalog;
    displayCatalog: IOcrDatasetDisplayInfo[];
    errorMessage: string;
    selectedNavKey: string;
}

export class DataHomePage extends React.Component<
    RouteComponentProps,
    IHomePageState
> {
    private _content: React.RefObject<Content>;
    private _timer?: NodeJS.Timeout;

    constructor(props: RouteComponentProps) {
        super(props);
        this._onChanged = this._onChanged.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onNavLinkClick = this._onNavLinkClick.bind(this);
        this._refreshDatasetCatalog = this._refreshDatasetCatalog.bind(this);
        this._parseURLSearchParams = this._parseURLSearchParams.bind(this);

        this._content = React.createRef();

        const defaultCatalog: IOcrDatasetCatalog = {
            datasets: [],
            lastsync: "N/A",
        };

        this.state = {
            datasetCatalog: defaultCatalog,
            displayCatalog: [],
            errorMessage: "",
            selectedNavKey: "",
        };
    }
    public render() {
        const { displayCatalog, selectedNavKey } = this.state;

        return (
            <>
                <Consumer>
                    {(value) => {
                        console.log(value);
                        return (
                            <SplitPane
                                style={{ position: "relative" }}
                                split="vertical"
                                minSize={200}
                                defaultSize={280}
                                pane1Style={{
                                    overflowY: "auto",
                                    backgroundColor: value ? "black" : "white",
                                    color: value ? "white" : "black",
                                }}
                                pane2Style={{
                                    overflow: "auto",
                                    backgroundColor: value ? "black" : "white",
                                    color: value ? "white" : "black",
                                }}
                            >
                                <ScrollablePane>
                                    <Sticky
                                        stickyPosition={
                                            StickyPositionType.Header
                                        }
                                    >
                                        {this.getMessageBar()}
                                        <SearchBox
                                            placeholder="Search"
                                            onChanged={(newValue: any) => {
                                                if (this._timer) {
                                                    clearTimeout(this._timer);
                                                }
                                                this._timer = setTimeout(() => {
                                                    this._onChanged(newValue);
                                                }, 300);
                                            }}
                                            underlined={true}
                                        />
                                    </Sticky>
                                    <Nav
                                        onLinkClick={this._onNavLinkClick}
                                        selectedKey={selectedNavKey}
                                        ariaLabel="Nav basic example"
                                        groups={[
                                            // TODO: temporarliy disable SQL table support
                                            // {
                                            //   name: 'SQL TABLES',
                                            //   links: [
                                            //     {
                                            //       name: 'RawImages',
                                            //       links: [],
                                            //       url: '',
                                            //       datasetName: 'sql_rawimages',
                                            //       datasetVersion: '',
                                            //       id: 'sql_rawimages',
                                            //       key: 'key-sql_rawimgaes'
                                            //     },
                                            //     {
                                            //       name: 'TextLines',
                                            //       links: [],
                                            //       url: '',
                                            //       datasetName: 'sql_textlines',
                                            //       datasetVersion: '',
                                            //       id: 'sql_textlines',
                                            //       key: 'key-sql_textlines'
                                            //     }
                                            //   ]
                                            // },
                                            {
                                                name: "DATASETS",
                                                links: displayCatalog.map(
                                                    (dataset, index) => {
                                                        return {
                                                            name: dataset.name,
                                                            url: "",
                                                            links: dataset.versions.map(
                                                                (
                                                                    version: string
                                                                ) => {
                                                                    return {
                                                                        name:
                                                                            "version " +
                                                                            version,
                                                                        url: "",
                                                                        key:
                                                                            "key-" +
                                                                            dataset.name +
                                                                            "-" +
                                                                            version,
                                                                        datasetName:
                                                                            dataset.name,
                                                                        datasetVersion:
                                                                            version,
                                                                        id:
                                                                            index +
                                                                            "-" +
                                                                            version,
                                                                    };
                                                                }
                                                            ),
                                                            id: index.toString(),
                                                            key:
                                                                "key-" +
                                                                dataset.name,
                                                            datasetName:
                                                                dataset.name,
                                                            datasetVersion:
                                                                dataset.defaultVersion,
                                                            isExpanded:
                                                                selectedNavKey.indexOf(
                                                                    dataset.name
                                                                ) > -1
                                                                    ? true
                                                                    : false,
                                                        };
                                                    }
                                                ),
                                            },
                                        ]}
                                    />
                                </ScrollablePane>
                                <Content ref={this._content} />
                            </SplitPane>
                        );
                    }}
                </Consumer>
            </>
        );
    }

    public componentDidMount(): void {
        queryDatasetCatalog()
            .then((response) => {
                let arr = [...response.datasets];
                this.setState({
                    datasetCatalog: response,
                    displayCatalog: arr,
                });
                if (
                    this._content.current &&
                    this.props.location.search.substr(1)
                ) {
                    let params = this._parseURLSearchParams(
                        this.props.location.search.substr(1)
                    );
                    this._content.current.setDataset(
                        params.name,
                        params.version!
                    );
                    this.setState({
                        selectedNavKey:
                            "key-" +
                            params.name +
                            (params.version ? "-" + params.version : ""),
                    });
                }
            })
            .catch((error) => {
                const defaultCatalog: IOcrDatasetCatalog = {
                    datasets: [],
                    lastsync: "N/A",
                };
                if (error instanceof UnauthorizedError) {
                    this.setState({
                        datasetCatalog: defaultCatalog,
                        errorMessage:
                            "Authentication token expired. Please refresh the page to login again.",
                    });
                }
            });
    }

    private _parseURLSearchParams(param: string) {
        const { displayCatalog } = this.state;
        let dataSetInfo = new URLSearchParams(param);
        let urlparams: DatasetInfoParams = { name: "" };
        if (dataSetInfo.has("name") && dataSetInfo.get("name")) {
            urlparams.name = dataSetInfo.get("name")!.toString();
        }
        let selectedDataset = displayCatalog.filter(
            (val) => val.name === urlparams.name
        );
        const version = dataSetInfo.get("version");
        if (version) {
            if (version === "latest") {
                urlparams.version = selectedDataset[0].defaultVersion;
            } else {
                urlparams.version = version;
            }
        } else {
            urlparams.version = selectedDataset[0].defaultVersion;
        }
        return urlparams;
    }

    private _onNavLinkClick(
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) {
        const { displayCatalog } = this.state;
        if (item && item.id !== undefined && item.key !== undefined) {
            if (this._content.current) {
                let viewVersion = "";
                let selectedDataset = displayCatalog.filter(
                    (val) => val.name === item.datasetName
                );
                if (item.datasetVersion === selectedDataset[0].defaultVersion) {
                    viewVersion = "latest";
                } else {
                    viewVersion = item.datasetVersion;
                }

                let newUrl = `${window.location.origin}${window.location.pathname}?name=${item.datasetName}&version=${viewVersion}`;
                window.history.replaceState(
                    { page: 1 },
                    item.datasetName,
                    newUrl
                );
                this._content.current.setDataset(
                    item.datasetName,
                    item.datasetVersion
                );
                this.setState({ selectedNavKey: item.key });
            }
        }
    }

    private _onItemInvoked(item: string): void {
        console.log(`Item invoked: ${item}`);
    }

    private _onChanged(newValue: any) {
        const result = this.state.datasetCatalog.datasets.filter(
            (item: any) =>
                item.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );
        if (newValue.length === 0) {
            this.setState({
                displayCatalog: this.state.displayCatalog,
            });
        }
        this.setState({
            displayCatalog: result,
        });
    }

    private _prettyDateTime(date: string): string {
        // Get a datetime object or a int() Epoch timestamp and return a
        // pretty string like 'an hour ago', 'Yesterday', '3 months ago',
        // 'just now', etc

        if (!Date.parse(date)) {
            return "NaN";
        }
        const second_diff = (Date.now() - Date.parse(date)) / 1000;
        if (second_diff < 10) {
            return "just now";
        }
        if (second_diff < 60) {
            return Math.round(second_diff) + " seconds ago";
        }
        if (second_diff < 120) {
            return "a minute ago";
        }
        if (second_diff < 3600) {
            return Math.round(second_diff / 60) + " minutes ago";
        }
        if (second_diff < 7200) {
            return "an hour ago";
        }
        return Math.round(second_diff / 3600) + " hours ago";
    }

    private _refreshDatasetCatalog(): void {
        syncDatasetCatalog().then((res) => {
            if (res) {
                queryDatasetCatalog()
                    .then((response) => {
                        let arr = [...response.datasets];
                        this.setState({
                            datasetCatalog: response,
                            displayCatalog: arr,
                        });
                    })
                    .catch((error) => {
                        const defaultCatalog: IOcrDatasetCatalog = {
                            datasets: [],
                            lastsync: "N/A",
                        };
                        if (error instanceof UnauthorizedError) {
                            this.setState({
                                datasetCatalog: defaultCatalog,
                                errorMessage:
                                    "Authentication token expired. Please refresh the page to login again.",
                            });
                        }
                    });
            }
        });
    }

    private getMessageBar() {
        const { errorMessage, datasetCatalog } = this.state;
        const refreshIcon: IIconProps = { iconName: "Refresh" };
        if (errorMessage) {
            const ErrorMessage = () => (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    style={{ whiteSpace: "break-spaces" }}
                >
                    {errorMessage}
                </MessageBar>
            );
            return <div>{<ErrorMessage />}</div>;
        } else {
            return (
                <MessageBar messageBarType={MessageBarType.success}>
                    Last synced: {this._prettyDateTime(datasetCatalog.lastsync)}
                    .
                    <IconButton
                        iconProps={refreshIcon}
                        title="Refresh"
                        style={{
                            height: "16px",
                            position: "absolute",
                            right: "5px",
                        }}
                        ariaLabel="Refresh"
                        onClick={this._refreshDatasetCatalog}
                    />
                </MessageBar>
            );
        }
    }
}
