import React from "react";

import { InvoiceOverview, InvoiceImageView } from "../../Views";
import { Record, Typename, Workspaces } from "../../DataContract";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    linkData?: any;
    isDarkTheme?: boolean;
    deepLinkHandler?: (key: string, linkData: any) => void;
}

export class InvoicePage extends React.Component<IProps, IState> {
    public render() {
        const { deepLinkHandler, linkData, records, selectedKey } = this.props;

        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };
        return (
            <>
                {selectedKey === "overviewInvoiceMetrics" && (
                    <InvoiceOverview
                        saveSetKey={`${Workspaces.Invoice}_${Typename.InvoiceOverview}`}
                        records={records}
                        invoiceImageViewDeepLinkHandler={deepLinkHandler}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "overviewImageMetrics" && (
                    <InvoiceImageView
                        saveSetKey={`${Workspaces.Invoice}_${Typename.InvoiceImageView}`}
                        records={records}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectField={linkSetting?.toSelectField}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
            </>
        );
    }
}
