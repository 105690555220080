import {
    IDialogContentStyles,
    IDropdownStyles,
    ISearchBoxStyles,
} from "@fluentui/react";

export const dropdownStyle: Partial<IDropdownStyles> = {
    root: { height: "100%" },
    dropdown: { height: "100%" },
    title: {
        border: "1px solid rgb(96, 94, 92) !important",
        backgroundColor: "white !important",
        marginTop: "0px !important",
        height: "100% !important",
    },
};

export const dialogContentStyles: Partial<IDialogContentStyles> = {
    title: {
        textAlign: "center",
    },
    subText: {
        textAlign: "center",
    },
};

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        borderColor: "rgb(96, 94, 92)",
        width: "100%",
    },
    iconContainer: {
        display: "none",
    },
};
