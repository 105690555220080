export const KEY_SEPARATOR = "~|~";

export interface CustFormArguments {
    trainingDocCount: number;
    trainingDocIndex: number;
}

export interface CustomFormFieldMetrics {
    datasetFullName: string;
    TrainingDocCount: number;
    TrainingDocIndex: number;
    Accuracy: number;
    TrainingLatency: number;
    InferenceLatency: number;
}
