import React from "react";
import "./TelemetryOverview.scss";
import moment from "moment";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Dropdown,
    FontIcon,
    getTheme,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    IDocumentCardTitleStyleProps,
    IDocumentCardTitleStyles,
    IDropdownOption,
    IObjectWithKey,
    IStyleFunctionOrObject,
    ITag,
    MarqueeSelection,
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    SearchBox,
    Selection,
    SelectionMode,
    Stack,
    TagPicker,
} from "@fluentui/react";
import {
    BucketFieldAggregation,
    FilterItem,
} from "../../DataContract/VerticalTlmEntity";
import { fetchDataByPath, getColorByIndex } from "../../Utils";
import { from } from "linq-to-typescript";
import { VerticalTlmRecord } from "../../DataContract/VerticalTlmRecord";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { IGroupedBarChartData, VerticalBarChart } from "../../Controls";
import {
    DetailsList_onRenderDetailsHeader_FreezeHeader,
    EqualityComparer_IgnoreCase,
    ScrollablePane_Styles_ThickScrollBar,
} from "../../Utils/LibraryOverwriteAndExtension";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

//#region Data entities which mapping to monthly telemety data in Blob
interface SubDetail {
    id: string;
    name: string;
}

interface Condiction {
    //subscriptionId
    subId: string;

    //requestLocale
    reqLoc: string;

    //detectedLocale
    dtcLoc: string;

    fileType: string;
}

interface Appearance {
    //fieldType
    ft: string;

    //field_appearance_dcnt
    fac: number;
}

interface BucketRequest {
    //bucket_request_dcnt
    bc: number;

    data: Appearance[];
}

interface Bucket {
    //encoded_bucket
    cond: Condiction;

    bd: BucketRequest[];
}

interface DataInDay {
    day: string;
    day_data: Bucket[];
}

interface Slicer {
    //subscriptionId
    subId: string[];

    //requestLocale
    reqLoc: string[];

    //detectedLocale
    dtcLoc: string[];

    fileType: string[];
}

interface MonthlyItem {
    subDetails: SubDetail[];
    slicer: Slicer;
    data: DataInDay[];
}
//#endregion

const ASCII_A: number = 65;
const THEME = getTheme();
const PRIMARY_CARD_TITLE_STYLES: IStyleFunctionOrObject<
    IDocumentCardTitleStyleProps,
    IDocumentCardTitleStyles
> = { root: { height: 25 } };

// prettier-ignore
const FIELD_STATS_COLUMNS: IColumn[] = [
    { key: "field",        name: "Field",        fieldName: "FieldNameWithGroup",  minWidth: 100,   maxWidth: 400, isResizable: true},
    { key: "mean",         name: "Mean",         fieldName: "Mean",                minWidth: 100,   maxWidth: 250, isResizable: true},
    { key: "appearance",   name: "Appearance",   fieldName: "Appearance",          minWidth: 100,   maxWidth: 250, isResizable: true},
    { key: "request_cnt",  name: "RequestCount", fieldName: "RequestCount",        minWidth: 100,   maxWidth: 250, isResizable: true}
];

// prettier-ignore
const Filter_COLUMNS: IColumn[] = [
    { key: "value",       name: "Value",               fieldName: "Name",               minWidth: 100, maxWidth: 400, isResizable: true},
    { key: "trafficPer",  name: "Traffic Percentage",  fieldName: "RequestPercentage",  minWidth: 100, maxWidth: 250, isResizable: true},
    { key: "reqCnt",      name: "Request Count",       fieldName: "TotalRequestCount",  minWidth: 100, maxWidth: 250, isResizable: true},
];

// prettier-ignore
const Filter_COLUMNS_BY_SUBID: IColumn[] = [
    { key: "subId",    name: "Subscription Id",     fieldName: "Name",                minWidth: 100, maxWidth: 400, isResizable: true},
    { key: "reqPer",   name: "Request Percentage",  fieldName: "RequestPercentage",   minWidth: 100, maxWidth: 250, isResizable: true},
    { key: "reqCnt",   name: "Request Count",       fieldName: "TotalRequestCount",   minWidth: 100, maxWidth: 250, isResizable: true},
    { key: "cusName",  name: "Customer Name",       fieldName: "CustomerName",        minWidth: 100, maxWidth: 250, isResizable: true}
];

const FILTER_CATEGORY_NAMES: { [key: string]: string } = {
    subId: "Subscription Id",
    reqLoc: "Request Locale",
    dtcLoc: "Detected Locale",
    fileType: "File Type",
};

interface IState extends ICommonState<MonthlyItem> {
    allBucketsArr: Array<Bucket>[];
    filterCategoryMapArr: Map<string, string[]>[];
    filterCategoryOptions: IDropdownOption[];
    filterColumns: IColumn[];
    filterListItems: FilterItem[];
    filterSelectedCategoryMapArr: Map<string, string[]>[];
    subDetailsMap: Map<string, string>;
    selectedCategoryKey: string;
    queryString: string;
}

export class TelemetryOverview extends CommonView<
    ICommonProps,
    IState,
    MonthlyItem
> {
    private _displayListItems: FilterItem[];
    private _filterListSelection: Selection;
    private _isEnabledFilterListSelectionChanged: boolean;
    private _timeoutId: NodeJS.Timeout | null;
    constructor(prop: ICommonProps) {
        super(prop);
        this._displayListItems = [];
        this._isEnabledFilterListSelectionChanged = true;
        this._timeoutId = null;

        this._getFilterListItems = this._getFilterListItems.bind(this);
        this._onClearAllButtonClick = this._onClearAllButtonClick.bind(this);
        this._onFilterCategorySelectChange =
            this._onFilterCategorySelectChange.bind(this);
        this._onFilterListSelectionChanged =
            this._onFilterListSelectionChanged.bind(this);
        this._onLoadFilterList = this._onLoadFilterList.bind(this);
        this._onStatsListRenderRow = this._onStatsListRenderRow.bind(this);
        this._onSearchTextChanged = this._onSearchTextChanged.bind(this);
        this._onSelectedTagPickerChange =
            this._onSelectedTagPickerChange.bind(this);

        this._filterListSelection = new Selection({
            onSelectionChanged: this._onFilterListSelectionChanged,
            getKey: (item: IObjectWithKey) => item.key ?? "",
            canSelectItem: () => true,
            selectionMode: SelectionMode.multiple,
        });

        this.state = {
            dataItems: [],
            allBucketsArr: [[]],
            filterCategoryMapArr: [],
            filterCategoryOptions: [],
            filterColumns: [],
            filterListItems: [],
            filterSelectedCategoryMapArr: [],
            subDetailsMap: new Map<string, string>(),
            selectedCategoryKey: "",
            queryString: "",
        };
    }

    render(): React.ReactNode {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "auto" }}>
                    {this.renderData()}
                </div>
            </FullScreen>
        );
    }

    renderData = () => {
        const {
            allBucketsArr,
            filterColumns,
            filterCategoryOptions,
            filterListItems,
            filterSelectedCategoryMapArr,
            queryString,
            selectedCategoryKey,
        } = this.state;

        const dataNotReady = allBucketsArr.every(
            (buckets) => !!!buckets || buckets.length === 0
        );

        if (dataNotReady) {
            return (
                <NoDataTip>
                    Data is not ready, try again after 24h since this dataset
                    was created please.
                </NoDataTip>
            );
        } else {
            const filterSelectedTags = this._getFilterSelectedTags(
                filterSelectedCategoryMapArr
            );

            this._displayListItems = this._getDisplayFilterListItems(
                selectedCategoryKey,
                filterListItems,
                filterSelectedCategoryMapArr
            );

            const fieldDictArr = this._aggregateBucketsByField(allBucketsArr);
            const chartDataArr = this._mergeDataForBarChart(fieldDictArr);
            const fieldStatsItems = this._mergeDataForStats(fieldDictArr);

            return (
                <Stack className="tlmOverview">
                    <DocumentCardTitle
                        title={"Slicer"}
                        styles={PRIMARY_CARD_TITLE_STYLES}
                    />

                    <Stack horizontal>
                        <TagPicker
                            inputProps={{ readOnly: true }}
                            selectedItems={filterSelectedTags}
                            onChange={this._onSelectedTagPickerChange}
                            onResolveSuggestions={() => []}
                            className="selectedTag"
                        ></TagPicker>
                        <PrimaryButton
                            className="clearBtn"
                            onClick={this._onClearAllButtonClick}
                        >
                            <FontIcon iconName="ClearFormattingEraser" />
                            &nbsp;Clear All
                        </PrimaryButton>
                    </Stack>

                    <Stack className="searchBar" horizontal>
                        <Dropdown
                            className="filterCategory"
                            multiSelect={false}
                            options={filterCategoryOptions}
                            defaultSelectedKey={selectedCategoryKey}
                            onChange={this._onFilterCategorySelectChange}
                            placeholder={"Select filter category"}
                        ></Dropdown>

                        <SearchBox
                            className="searchBox"
                            value={queryString}
                            onChange={this._onSearchTextChanged}
                        ></SearchBox>
                    </Stack>
                    {filterColumns && filterColumns.length > 0 && (
                        <Stack className="scrollableContainer filterList">
                            <ScrollablePane
                                scrollbarVisibility={ScrollbarVisibility.auto}
                                styles={ScrollablePane_Styles_ThickScrollBar}
                            >
                                {this._displayListItems &&
                                    this._displayListItems.length > 0 && (
                                        <MarqueeSelection
                                            selection={
                                                this._filterListSelection
                                            }
                                        >
                                            <DetailsList
                                                items={this._displayListItems}
                                                columns={filterColumns}
                                                selection={
                                                    this._filterListSelection
                                                }
                                                selectionMode={
                                                    SelectionMode.multiple
                                                }
                                                constrainMode={
                                                    ConstrainMode.unconstrained
                                                }
                                                layoutMode={
                                                    DetailsListLayoutMode.justified
                                                }
                                                onRenderDetailsHeader={
                                                    DetailsList_onRenderDetailsHeader_FreezeHeader
                                                }
                                                onRenderRow={
                                                    this._onStatsListRenderRow
                                                }
                                                onShouldVirtualize={
                                                    //Fluent UI detail list issue that rendering blank row (Version: ^7.123.9)
                                                    //Here is workaround for that. (Mar 1th, 2022)
                                                    () => false
                                                }
                                            />
                                        </MarqueeSelection>
                                    )}

                                {(!!!this._displayListItems ||
                                    this._displayListItems.length === 0) && (
                                    <span className="filterListPrompt">
                                        No optional items
                                    </span>
                                )}
                            </ScrollablePane>
                        </Stack>
                    )}

                    {((chartDataArr && chartDataArr.length > 0) ||
                        (fieldStatsItems && fieldStatsItems.length > 0)) && (
                        <>
                            <br />
                            <DocumentCardTitle
                                title={"Field Appearance Statistics"}
                                styles={PRIMARY_CARD_TITLE_STYLES}
                            />
                        </>
                    )}

                    {chartDataArr && chartDataArr.length > 0 && (
                        <DocumentCard
                            key={"docCardForBarChart"}
                            type={DocumentCardType.normal}
                        >
                            <DocumentCardDetails>
                                <DocumentCardTitle
                                    title={"Field Appearance Statistics (Mean)"}
                                    showAsSecondaryTitle={true}
                                    styles={{ root: { height: 20 } }}
                                />
                                <VerticalBarChart
                                    id={"meanBarChart"}
                                    height={450}
                                    data={chartDataArr}
                                    xAxisName=""
                                    yAxisName=""
                                    isDarkTheme={this.props.isDarkTheme}
                                ></VerticalBarChart>
                            </DocumentCardDetails>
                        </DocumentCard>
                    )}

                    {fieldStatsItems && fieldStatsItems.length > 0 && (
                        <>
                            <br />
                            <DocumentCard
                                key={"docCardForFieldStats"}
                                type={DocumentCardType.normal}
                            >
                                <DocumentCardDetails
                                    styles={{ root: { height: 700 } }}
                                >
                                    <DocumentCardTitle
                                        title={"Field Appearance Stats"}
                                        showAsSecondaryTitle={true}
                                        styles={{ root: { height: 10 } }}
                                    />
                                    <Stack className="scrollableContainer statList">
                                        <ScrollablePane
                                            scrollbarVisibility={
                                                ScrollbarVisibility.auto
                                            }
                                            styles={
                                                ScrollablePane_Styles_ThickScrollBar
                                            }
                                        >
                                            <DetailsList
                                                items={fieldStatsItems}
                                                columns={FIELD_STATS_COLUMNS}
                                                selectionMode={
                                                    SelectionMode.none
                                                }
                                                constrainMode={
                                                    ConstrainMode.unconstrained
                                                }
                                                layoutMode={
                                                    DetailsListLayoutMode.justified
                                                }
                                                onRenderDetailsHeader={
                                                    DetailsList_onRenderDetailsHeader_FreezeHeader
                                                }
                                                onRenderRow={
                                                    this._onStatsListRenderRow
                                                }
                                                onShouldVirtualize={
                                                    //Fluent UI detail list issue that rendering blank row (Version: ^7.123.9)
                                                    //Here is workaround for that. (Mar 1th, 2022)
                                                    () => false
                                                }
                                            />
                                        </ScrollablePane>
                                    </Stack>
                                </DocumentCardDetails>
                            </DocumentCard>
                        </>
                    )}
                </Stack>
            );
        }
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: IState
    ): void {
        super.componentDidUpdate(prevProps, prevState);

        if (
            prevState.selectedCategoryKey !== this.state.selectedCategoryKey ||
            prevState.filterSelectedCategoryMapArr !==
                this.state.filterSelectedCategoryMapArr
        ) {
            this._onLoadFilterList();
        }
    }

    queryMetricsResult(): void {
        const tlmRecords = this.props.records as Array<VerticalTlmRecord>;
        const dataPromisesArr = tlmRecords.map((record) => {
            const details = record.getDetails();
            if (details && details.length > 0) {
                const startDateNum = details[0].getRawProp(
                    "startDate"
                ) as number;
                const endDateNum = details[0].getRawProp("endDate") as number;

                const fileNames = this._calcMonthlyFileNames(
                    new Date(startDateNum),
                    new Date(endDateNum)
                );

                const fileDataPromises = fileNames.map((fileName) => {
                    const filePath = `/api/eval/blobs/${record.storageRoot}/${fileName}`;
                    return fetchDataByPath(filePath);
                });

                return fileDataPromises;
            } else {
                return [];
            }
        });

        const awaitingArr = dataPromisesArr.map((dataPromises) => {
            return Promise.all(dataPromises);
        });

        Promise.all(awaitingArr)
            .then((monthlyItemsStringsArr) => {
                if (
                    monthlyItemsStringsArr &&
                    monthlyItemsStringsArr.length > 0
                ) {
                    const monthlyItemsArr = monthlyItemsStringsArr.map(
                        (monthlyItemsStr) => {
                            return monthlyItemsStr.map((monthlyItemStr) => {
                                return JSON.parse(
                                    monthlyItemStr
                                ) as MonthlyItem;
                            });
                        }
                    );

                    const subDetailMap = new Map<string, string>();
                    const filterCategoryMapArr: Map<string, string[]>[] = [];
                    const bucketsArr = monthlyItemsArr.map((monthlyItems) => {
                        const filterCategoryMap = new Map<string, string[]>();
                        const buckets = monthlyItems.flatMap((monthlyItem) => {
                            if (JSON.stringify(monthlyItem) === "{}") {
                                return [];
                            } else {
                                Object.entries(FILTER_CATEGORY_NAMES).forEach(
                                    ([cKey]) => {
                                        const valArr =
                                            monthlyItem.slicer[
                                                cKey as keyof Slicer
                                            ];
                                        const catItems = filterCategoryMap.has(
                                            cKey
                                        )
                                            ? filterCategoryMap.get(cKey)!
                                            : new Array<string>();

                                        valArr.forEach((val) => {
                                            if (
                                                !from(catItems).contains(
                                                    val,
                                                    EqualityComparer_IgnoreCase
                                                )
                                            ) {
                                                catItems.push(val);
                                            }
                                        });

                                        filterCategoryMap.set(cKey, catItems);
                                    }
                                );

                                monthlyItem.subDetails.forEach((subDetail) => {
                                    if (!subDetailMap.has(subDetail.id)) {
                                        subDetailMap.set(
                                            subDetail.id,
                                            subDetail.name
                                        );
                                    }
                                });

                                return monthlyItem.data.flatMap((dataInDay) => {
                                    return dataInDay.day_data;
                                });
                            }
                        });

                        filterCategoryMapArr.push(filterCategoryMap);
                        return buckets;
                    });

                    const filterCategoryOptions =
                        this._generateFilterCategoryOptions(bucketsArr.length);

                    const filterSelectedCategoryMapArr = Array.from(
                        { length: bucketsArr.length },
                        () => new Map<string, string[]>()
                    );

                    this.setState({
                        allBucketsArr: bucketsArr,
                        filterCategoryMapArr: filterCategoryMapArr,
                        filterCategoryOptions: filterCategoryOptions,
                        filterSelectedCategoryMapArr:
                            filterSelectedCategoryMapArr,
                        subDetailsMap: subDetailMap,
                    });
                }
            })
            .catch((reason) => {
                console.warn(reason);
            });
    }

    //#region Event
    private _onClearAllButtonClick(): void {
        const { filterSelectedCategoryMapArr } = this.state;
        filterSelectedCategoryMapArr.forEach((map) => map.clear());

        this.setState(
            {
                filterSelectedCategoryMapArr: filterSelectedCategoryMapArr,
            },
            this._onLoadFilterList
        );
    }

    private _onFilterCategorySelectChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ): void {
        if (option) {
            const [groupIndexStr, key] = option.key.toString().split("|");
            const groupIndex = parseInt(groupIndexStr);
            const listItems = this._getFilterListItems(groupIndex, key);

            this._isEnabledFilterListSelectionChanged = false;
            this.setState(
                {
                    selectedCategoryKey: option.key as string,
                    filterColumns:
                        key === "subId"
                            ? Filter_COLUMNS_BY_SUBID
                            : Filter_COLUMNS,
                    filterListItems: listItems,
                },
                () => {
                    this._isEnabledFilterListSelectionChanged = true;
                }
            );
        }
    }

    private _onFilterListSelectionChanged() {
        if (this._isEnabledFilterListSelectionChanged) {
            const { filterSelectedCategoryMapArr, selectedCategoryKey } =
                this.state;

            const selectItems =
                this._filterListSelection.getSelection() as FilterItem[];
            if (selectItems.length > 0) {
                const [indexStr, key] = selectedCategoryKey
                    .toString()
                    .split("|");
                const groupIndex = parseInt(indexStr);
                const selectValArr = selectItems.map((item) => item.Name);
                const selectedValArr =
                    filterSelectedCategoryMapArr[groupIndex].get(key);
                if (selectedValArr) {
                    selectValArr.forEach((val) => {
                        if (!selectedValArr.includes(val)) {
                            selectedValArr.push(val);
                        }
                    });

                    filterSelectedCategoryMapArr[groupIndex].set(
                        key,
                        selectedValArr
                    );
                } else {
                    filterSelectedCategoryMapArr[groupIndex].set(
                        key,
                        selectValArr
                    );
                }

                this.setState({
                    filterSelectedCategoryMapArr: filterSelectedCategoryMapArr,
                });
            }
        }
    }

    private _onSelectedTagPickerChange(items?: ITag[] | undefined): void {
        const { filterSelectedCategoryMapArr } = this.state;
        filterSelectedCategoryMapArr.forEach((map) => {
            map.clear();
        });
        if (items && items.length > 0) {
            items.forEach((item) => {
                const keyStr = item.key as string;
                const [indexStr, categoryKey, value] = keyStr.split("|");
                const groupIndex = parseInt(indexStr);
                let valueArr =
                    filterSelectedCategoryMapArr[groupIndex].get(categoryKey);
                if (valueArr) {
                    !valueArr.includes(value) && valueArr.push(value);
                } else {
                    valueArr = [value];
                }
                filterSelectedCategoryMapArr[groupIndex].set(
                    categoryKey,
                    valueArr
                );
            });
        }

        this.setState(
            {
                filterSelectedCategoryMapArr: filterSelectedCategoryMapArr,
            },
            this._onLoadFilterList
        );
    }

    private _onSearchTextChanged(
        event?: React.ChangeEvent<HTMLInputElement>,
        newValue?: string
    ): void {
        const queryStr = newValue ?? "";
        this.setState({ queryString: queryStr });

        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
        }

        this._timeoutId = setTimeout(() => {
            const { selectedCategoryKey } = this.state;
            const [index, key] = selectedCategoryKey.split("|");
            const listItems = this._getFilterListItems(parseInt(index), key);

            this.setState(
                {
                    filterListItems: listItems,
                },
                () => {
                    this._timeoutId = null;
                }
            );
        }, 600);
    }

    private _onStatsListRenderRow(
        props: IDetailsRowProps | undefined
    ): JSX.Element {
        const { allBucketsArr } = this.state;
        const comparisonCount = allBucketsArr.length;
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props && comparisonCount > 0) {
            if (props.itemIndex % (comparisonCount * 2) < comparisonCount) {
                customStyles.root = {
                    backgroundColor: THEME.palette.neutralLighterAlt,
                };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        } else {
            return <></>;
        }
    }

    private _onLoadFilterList() {
        this._filterListSelection.setChangeEvents(false);
        this._filterListSelection.setItems(this._displayListItems, true);
        this._filterListSelection.setChangeEvents(true);
    }
    //#endregion

    //#region private calculate func
    private _calcMonthlyFileNames(startDate: Date, endDate: Date): string[] {
        const fileNames: string[] = [];
        const endMoment = moment(endDate);
        let extractMoment = moment(startDate);

        do {
            const fileName = `${extractMoment.format("yyyyMM")}.json`;
            fileNames.push(fileName);

            extractMoment = extractMoment.add(1, "month");
        } while (extractMoment.isBefore(endMoment));

        return fileNames;
    }

    private _aggregateBucketsByField(
        bucketsArr: Bucket[][]
    ): Map<string, BucketFieldAggregation>[] {
        const { filterSelectedCategoryMapArr } = this.state;

        return bucketsArr.map((buckets, groupIndex) => {
            const agrDict = new Map<string, BucketFieldAggregation>();
            const groupName = String.fromCharCode(ASCII_A + groupIndex);
            const selectedMap = filterSelectedCategoryMapArr[groupIndex];

            const filteredBuckets = buckets.filter((bucket) => {
                return selectedMap.size > 0
                    ? Array.from(selectedMap).every(([key, selectedValArr]) => {
                          const propVal = bucket.cond[key as keyof Condiction];
                          return selectedValArr && selectedValArr.length > 0
                              ? selectedValArr.includes(propVal)
                              : true;
                      })
                    : true;
            });

            filteredBuckets.forEach((bucket) => {
                const bucketData = bucket.bd[0];
                bucketData.data.forEach((appear) => {
                    let fieldAggregation = agrDict.get(appear.ft);
                    if (fieldAggregation) {
                        fieldAggregation.Appearance += appear.fac;
                        fieldAggregation.RequestCount += bucketData.bc;
                    } else {
                        fieldAggregation = new BucketFieldAggregation(
                            appear.ft,
                            appear.fac,
                            bucketData.bc,
                            groupName
                        );
                    }
                    agrDict.set(appear.ft, fieldAggregation);
                });
            });

            return agrDict;
        });
    }

    private _mergeDataForStats(
        fieldDictArr: Map<string, BucketFieldAggregation>[]
    ): BucketFieldAggregation[] {
        const fieldAggregationArr = fieldDictArr.flatMap((fieldDict) => {
            return Array.from(fieldDict.values());
        });

        return from(fieldAggregationArr)
            .orderBy((a) => a.FieldName)
            .orderBy((a) => a.GroupName)
            .toArray();
    }

    private _mergeDataForBarChart(
        fieldDictArr: Map<string, BucketFieldAggregation>[]
    ): IGroupedBarChartData[] {
        let distinctKeys: string[] = [];
        fieldDictArr.forEach((fieldDict) => {
            Array.from(fieldDict.keys()).forEach((key) => {
                if (!distinctKeys.includes(key)) {
                    distinctKeys.push(key);
                }
            });
        });

        const tlmRecords = this.props.records as Array<VerticalTlmRecord>;
        const chartDataArr = distinctKeys.sort().map((key) => {
            const series = fieldDictArr.map((fieldDict, dictIndex) => {
                const fieldAggregation = fieldDict.get(key);
                const groupName = `${String.fromCharCode(
                    ASCII_A + dictIndex
                )} : ${tlmRecords[dictIndex].alias}`;

                return {
                    key: `series_${dictIndex}`,
                    data: fieldAggregation ? fieldAggregation.Mean : 0,
                    color: getColorByIndex(dictIndex),
                    legend: groupName,
                };
            });

            return {
                name: key,
                series: series,
            };
        });

        return chartDataArr;
    }

    private _generateFilterCategoryOptions(
        comparisonCount: number
    ): IDropdownOption[] {
        const filterCategoryOptions: IDropdownOption[] = [];
        for (let i = 0; i < comparisonCount; i++) {
            const options = Object.entries(FILTER_CATEGORY_NAMES).map(
                ([cKey, cName]) => {
                    return {
                        key: `${i}|${cKey}`,
                        text: `${String.fromCharCode(ASCII_A + i)}: ${cName}`,
                    };
                }
            );

            filterCategoryOptions.push(...options);
        }

        return filterCategoryOptions;
    }

    private _getFilterSelectedTags(mapArr: Map<string, string[]>[]): ITag[] {
        return mapArr.flatMap((map, index) => {
            return Array.from(map).flatMap(([key, vals]) => {
                const valArr = vals as string[];
                return valArr.flatMap((val) => {
                    const groupName = String.fromCharCode(ASCII_A + index);
                    const categoryName =
                        FILTER_CATEGORY_NAMES[
                            key as keyof { [key: string]: string }
                        ];

                    return {
                        key: `${index}|${key}|${val}`,
                        name: `${groupName} : ${categoryName} > ${val}`,
                    } as ITag;
                });
            });
        });
    }

    private _getFilterListItems(
        groupIndex: number,
        mapKey: string
    ): FilterItem[] {
        const {
            allBucketsArr,
            filterCategoryMapArr,
            queryString,
            subDetailsMap,
        } = this.state;

        const valArr = filterCategoryMapArr[groupIndex].get(mapKey)!;
        const filterItems = valArr
            .map((val) => {
                const filterItem =
                    mapKey === "subId"
                        ? new FilterItem(
                              val,
                              0,
                              0,
                              groupIndex,
                              mapKey,
                              subDetailsMap.get(val) ?? ""
                          )
                        : new FilterItem(val, 0, 0, groupIndex, mapKey);

                allBucketsArr[groupIndex].forEach((bucket) => {
                    if (bucket.cond[mapKey as keyof Slicer] === val) {
                        filterItem.RequestCount += bucket.bd[0].bc;
                    }

                    filterItem.TotalRequestCount += bucket.bd[0].bc;
                });

                return filterItem;
            })
            .filter(
                (item) =>
                    item.Name.toLowerCase().indexOf(queryString.toLowerCase()) >
                    -1
            );

        return filterItems;
    }

    private _getDisplayFilterListItems(
        selectedCategoryKey: string,
        filterListItems: FilterItem[],
        filterSelectedCategoryMapArr: Map<string, string[]>[]
    ) {
        if (selectedCategoryKey) {
            const [groupIndexStr, key] = selectedCategoryKey.split("|");
            const groupIndex = parseInt(groupIndexStr);
            const selectedValArr =
                filterSelectedCategoryMapArr[groupIndex].get(key);

            if (selectedValArr && selectedValArr.length > 0) {
                return filterListItems.filter(
                    (item) => !selectedValArr.includes(item.Name)
                );
            }
        }

        return filterListItems;
    }
    //#endregion
}
