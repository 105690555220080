import React from "react";
import "./imageVisualizer.scss";
import {
    // Color
    ColorPicker,
    IColor,
    SwatchColorPicker,

    // Panel
    Panel,
    PanelType,

    // DetailList
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,

    //depthEffect
    DefaultEffects,

    // Others
    Checkbox,
    Label,
    Stack,
    mergeStyleSets,
    DefaultButton,
    TextField,
    IStackProps,
    TooltipHost,
    IStackTokens,
    PrimaryButton,
    ChoiceGroup,
    FontIcon,
    mergeStyles,
    Dropdown,
    IDropdownOption,
    buildColumns,
    getTheme,
} from "@fluentui/react";
import { ISetting, ISettings, TextArea } from "./ImageVisualizer";
import { mergeMetrics } from "../../DataContract";
import { MathComponent } from "mathjax-react";

interface IProps {
    settings: ISettings;
    selectedSetting: [string, ISetting] | null;
    onSettingsChanged: (settings: ISettings) => void;
    onSelectedSettingChanged: (
        selectedSetting: [string, ISetting] | null
    ) => void;
    displayArea: TextArea | null;
    displayContent?: string;
    downloadImage?: () => void;
    downloadLabel?: () => void;
    setImageMark?: (
        markInfo?: string[],
        textlineId?: string,
        content?: string
    ) => void;
    textareas: any[];
    experiments?: string[];
    selectedExpIndex?: number;
    currentPage?: number;
    isDarkTheme?: boolean;
    _onChoiceGroupChanged: (id: any) => void;
}
interface IState {
    invokeTarget: string | null;
    showMardked: boolean;
    markInfo: string[];
    font: string;
    initializeContent?: any;
}

// prettier-ignore
var SETTING_COLUMNS: IColumn[] = [
    { key: 'enable', name: 'Enable', minWidth: 30,   maxWidth: 50,   isResizable: true },
    { key: 'label',  name: 'Type',   minWidth: 190,  maxWidth: 220,  isResizable: true },
    { key: 'fill',   name: 'Fill',   minWidth: 190,  maxWidth: 200,  isResizable: true, fieldName: "fill" },
    { key: 'border', name: 'Border', minWidth: 190,  maxWidth: 200,  isResizable: true, fieldName: "strokeColor"},
    
];

const styles = mergeStyleSets({
    label: {
        fontSize: "14px",
        padding: "1px",
    },
    title: {
        paddingRight: "5px",
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    colorPicker: {
        bottom: "10px",
    },
    recognize: {
        boxShadow: DefaultEffects.elevation4,
        marginTop: "10px",
        padding: "5px",
        fontSize: "14px",
        color: "#b8b8b8",
        height: "100%",
        overflowWrap: "break-word",
        maxWidth: "800px",
    },
    textField: {
        width: "100%!important",
        border: "1px solid #000000",
    },
});
const languageOptions: IDropdownOption[] = [
    { key: "normal", text: "Segoe UI" },
    { key: "wideLatin", text: "Wide Latin" },
];
const errorLabel: IStackTokens = { childrenGap: 20 };
const pickExpIcon = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});
const stackTokens: IStackTokens = {
    childrenGap: 15,
    padding: 15,
};
const buttonProps: Partial<IStackProps> = {
    styles: { root: { width: "auto", marginTop: "2%" } },
};

const theme = getTheme();

const settings = {
    tex: {
        packages: { "[+]": ["amsmath"] },
        Macros: {
            amscd: "\\require{enclose}\\begin{CD}",
            action: "\\require{action}\\action",
            boldsymbol: ["{\\boldsymbol #1}", 1],
            bbox: "\\bbox",
            braket: "\\braket",
            color: "\\color",
            enclose: "\\enclose",
            extpfeil: "\\extpfeil",
            html: "\\html",
            mhchem: "\\mhchem",
            newcommand: "\\newcommand",
            unicode: "\\unicode{xVALUE}",
            verb: ["\\text{\\verb`#1`}", 1],
            operatorname: "\\text",
            hbar: "\\unicode{x0127}",
            mu: "\\textmu",
        },
    },
    autoload: {
        action: ["toggle", "mathtip", "texttip"],
        operatorname: ["operatorname"],
        amscd: ["enclose"],
        boldsymbol: ["boldsymbol"],
        bbox: ["bbox"],
        braket: [
            "bra",
            "ket",
            "braket",
            "set",
            "Bra",
            "Ket",
            "Braket",
            "Set",
            "ketbra",
            "Ketbra",
        ],
        cancel: ["cancel", "bcancel", "xcancel", "cancelto"],
        color: ["color", "definecolor", "textcolor", "colorbox", "fcolorbox"],
        enclose: ["enclose"],
        extpfeil: [
            "xtwoheadrightarrow",
            "xtwoheadleftarrow",
            "xmapsto",
            "xlongequal",
            "xtofrom",
            "Newextarrow",
        ],
        html: ["href", "class", "style", "cssId"],
        mhchem: ["ce", "pu"],
        newcommand: [
            "newcommand",
            "renewcommand",
            "newenvironment",
            "renewenvironment",
            "def",
            "let",
        ],
        unicode: ["unicode"],
        verb: ["verb"],
        hbar: ["hbar"],
        mu: ["mu"],
    },
};

export class SettingsPanel extends React.Component<IProps, IState> {
    private _selectedColor: IColor | null;
    private baseReferences: any[];
    constructor(props: IProps) {
        super(props);
        this._selectedColor = null;
        this.baseReferences = [];
        this._renderColumn = this._renderColumn.bind(this);
        this._markClicked = this._markClicked.bind(this);
        this._submitMark = this._submitMark.bind(this);
        this._renderSpeicalColumn = this._renderSpeicalColumn.bind(this);
        this.state = {
            invokeTarget: null,
            showMardked: false,
            markInfo: [],
            font: "Segoe UI",
        };
    }

    public render() {
        const { invokeTarget } = this.state;
        const { experiments, selectedExpIndex, downloadImage, downloadLabel } =
            this.props;
        const rowNum: number = 5;
        const selectedSetting = this.props.selectedSetting;
        let initialColor = "#ffffff";
        if (selectedSetting && invokeTarget) {
            if (invokeTarget === "fill") {
                initialColor = selectedSetting[1].fill;
            } else {
                initialColor = selectedSetting[1].strokeColor;
            }
        }

        let showContent = this.props.displayContent
            ? (JSON.parse(this.props.displayContent) as any[])
            : [];
        const specialContentMerged = mergeMetrics<any>(showContent);

        const specialList =
            this.renderDataForSpecialColumn(specialContentMerged);
        const formulaList = this.renderDataForMathFormula(specialContentMerged);

        let columns: IColumn[] = buildColumns(specialList!, true)
            .filter((column) => column.key !== "key")
            .map((column) => {
                if (column.key === "name") {
                    column.minWidth = 10;
                    column.maxWidth = 20;
                    column.name = "";
                }
                if (column.key.includes("con_")) {
                    column.name = "";
                    column.minWidth = 20;
                    column.maxWidth = 100;
                }

                return column;
            });
        let mathColumns: IColumn[] = buildColumns(formulaList!, true)
            .filter((column) => column.key !== "key")
            .map((column) => {
                if (column.key === "name") {
                    column.minWidth = 30;
                    column.maxWidth = 50;
                    column.name = "";
                }
                if (column.key.includes("mathJax")) {
                    column.name = "";
                    column.minWidth = 20;
                    column.maxWidth = 100;
                }
                return column;
            });
        const detailInfo = showContent.map((content) =>
            Object.keys(content).flatMap((item) => {
                return (
                    <div>
                        <span>{item}</span>:
                        <span
                            style={{
                                marginLeft: "10px",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {String(content[item])}
                        </span>
                    </div>
                );
            })
        );
        let submitRule =
            "If the label error for current image already exist, clicking submit button will overwrite this error record";

        return (
            <Stack verticalFill styles={{ root: { height: "auto" } }}>
                {experiments?.length !== 0 && (
                    <div style={{ display: "block" }}>
                        <Stack
                            horizontal
                            horizontalAlign="space-between"
                            styles={{ root: { height: "32px" } }}
                        >
                            <Stack horizontal verticalAlign="center">
                                <FontIcon
                                    aria-label="Compass"
                                    iconName="SpeedHigh"
                                    className={pickExpIcon}
                                    style={{
                                        color: this.props.isDarkTheme
                                            ? theme.palette.white
                                            : theme.palette.black,
                                    }}
                                />
                                <Label
                                    styles={{
                                        root: { marginLeft: "10px!important" },
                                    }}
                                >
                                    Pick evaluation experiment:
                                </Label>
                            </Stack>
                            <Stack horizontal verticalAlign="center">
                                {downloadImage && (
                                    <PrimaryButton
                                        text="Download Image"
                                        onClick={downloadImage}
                                        allowDisabledFocus
                                    />
                                )}
                                {downloadLabel && (
                                    <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <PrimaryButton
                                            text="Download Label"
                                            onClick={downloadLabel}
                                            allowDisabledFocus
                                        />
                                    </>
                                )}
                            </Stack>
                        </Stack>
                        <ChoiceGroup
                            style={{
                                position: "relative",
                                left: "-10px",
                            }}
                            options={experiments?.map((value, index) => ({
                                key: `exp_${index}`,
                                text: value,
                                id: index.toString(),
                            }))}
                            selectedKey={`exp_${selectedExpIndex}`}
                            onChange={(_event, option) => {
                                this.props._onChoiceGroupChanged(
                                    Number(option!.id)
                                );
                            }}
                        />
                    </div>
                )}
                <Stack horizontal verticalAlign="center">
                    <FontIcon
                        iconName="TVMonitor"
                        className={pickExpIcon}
                        style={{
                            color: this.props.isDarkTheme
                                ? theme.palette.white
                                : theme.palette.black,
                        }}
                    />
                    <Label styles={{ root: { marginLeft: "10px!important" } }}>
                        Display
                    </Label>
                </Stack>

                <DetailsList
                    key={`11`}
                    styles={{ root: { boxShadow: DefaultEffects.elevation4 } }}
                    items={Object.entries(this.props.settings)}
                    columns={SETTING_COLUMNS}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    onRenderItemColumn={this._renderColumn}
                />
                <Panel
                    isOpen={!!selectedSetting}
                    isLightDismiss={true}
                    type={PanelType.smallFixedFar}
                    onDismiss={this._hidePanel}
                    headerText="Color picker"
                >
                    <ColorPicker
                        color={initialColor}
                        onChange={(_ev, color) => {
                            this._selectedColor = color;
                        }}
                    />
                </Panel>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    tokens={stackTokens}
                    styles={{ root: { maxWidth: "1800px" } }}
                >
                    <Stack horizontal verticalAlign="center">
                        <FontIcon
                            iconName="DietPlanNotebook"
                            className={pickExpIcon}
                            style={{
                                color: this.props.isDarkTheme
                                    ? theme.palette.white
                                    : theme.palette.black,
                            }}
                        />
                        <Label
                            styles={{ root: { marginLeft: "10px!important" } }}
                        >
                            Content:
                        </Label>
                    </Stack>
                    <Dropdown
                        styles={{
                            root: { height: "100%" },
                            dropdown: { height: "100%" },
                            title: {
                                border: "1px solid rgb(96, 94, 92) !important",
                                backgroundColor: this.props.isDarkTheme
                                    ? theme.palette.neutralDark
                                    : theme.palette.neutralLighterAlt,
                                marginTop: "0px !important",
                                height: "100% !important",
                            },
                        }}
                        defaultSelectedKey={"normal"}
                        options={languageOptions}
                        onChange={(_event, option) => {
                            this.setState({
                                font: option!.text,
                            });
                        }}
                    ></Dropdown>
                </Stack>
                {specialList &&
                    formulaList &&
                    specialContentMerged.raw_REF &&
                    specialContentMerged.raw_HYP &&
                    this.renderMathTable(formulaList, mathColumns)}
                {specialList && this.renderRegTable(specialList, columns)}
                <Stack verticalFill grow={5} className={styles.recognize}>
                    <div
                        style={{
                            fontSize: "13px",
                            padding: "4px",
                        }}
                    >
                        {detailInfo}
                    </div>
                </Stack>
                <Stack verticalFill grow={rowNum} {...buttonProps}>
                    {!!this.props.displayArea && !this.state.showMardked && (
                        <DefaultButton
                            text="Report Label Error"
                            onClick={this._markClicked}
                            allowDisabledFocus
                        />
                    )}
                    {this.state.showMardked && (
                        <Stack verticalFill grow={rowNum}>
                            <TextField
                                label="Report Label Error"
                                multiline
                                rows={rowNum}
                                value={this.state.markInfo.toString()}
                                onChange={(_event, val) => {
                                    this.setState({
                                        markInfo: [val!],
                                    });
                                }}
                                styles={{
                                    fieldGroup: {
                                        width: "100%!important",
                                        marginBottom: "2%",
                                        border: "1px solid #f4f4f4!important",
                                        color: this.props.isDarkTheme
                                            ? theme.palette.white
                                            : theme.palette.black,
                                    },
                                }}
                            />
                            <Stack horizontal tokens={errorLabel}>
                                <TooltipHost content={submitRule}>
                                    <PrimaryButton
                                        text="Submit"
                                        onClick={this._submitMark}
                                        allowDisabledFocus
                                    />
                                </TooltipHost>
                                <DefaultButton
                                    text="Cancel"
                                    allowDisabledFocus
                                    onClick={() => {
                                        this.setState({
                                            showMardked: false,
                                        });
                                    }}
                                />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        );
    }

    private _renderColumn(
        item: [string, ISetting],
        _index?: number,
        column?: IColumn
    ) {
        if (!column) {
            return <span>Error</span>;
        }

        const [key, data] = item;

        switch (column.key) {
            case "label":
                return (
                    <div className={styles.title} title={data.label}>
                        {data.label}
                    </div>
                );
            case "fill":
            case "border":
                const color = data[
                    column.fieldName as keyof ISetting
                ] as string;
                return (
                    <div style={{ position: "absolute", top: "0px" }}>
                        <SwatchColorPicker
                            className={styles.colorPicker}
                            cellShape={"square"}
                            columnCount={1}
                            cellHeight={15}
                            cellWidth={15}
                            colorCells={[{ id: key, color: color }]}
                            isControlled={true}
                            onColorChanged={() =>
                                this._invokePanel(key, column.key)
                            }
                        />
                    </div>
                );
            case "enable":
                return (
                    <Checkbox
                        boxSide="end"
                        checked={data.isChecked}
                        onChange={() => this._toggleCheckBox(key)}
                    />
                );
        }
    }

    private _renderMathFormulaColumn(
        item: any,
        _index?: number,
        column?: IColumn,
        data?: any[]
    ) {
        if (!column) {
            return <span>Error</span>;
        }

        for (let con in item) {
            switch (column.key) {
                case "name":
                    return <span title={item[`name`]}>{item[`name`]}</span>;
                case con:
                    return (
                        <span
                            style={{
                                marginRight: "1%",
                                paddingRight: "2%",
                            }}
                            title={item["mathJax"]}
                        >
                            <MathComponent
                                tex={String.raw`${item["mathJax"]}`}
                                settings={settings}
                            />
                        </span>
                    );
            }
        }
    }

    private _renderSpeicalColumn(
        item: any,
        _index?: number,
        column?: IColumn,
        data?: any[]
    ) {
        if (!column) {
            return <span>Error</span>;
        }
        for (let con in item) {
            const baseData = data || this.baseReferences;
            let baseCompared: any = baseData[0][con];
            switch (column.key) {
                case "name":
                    return <span title={item[`name`]}>{item[`name`]}</span>;

                case con:
                    return _index === 0 ||
                        (_index === 1 && item[con] === baseCompared) ? (
                        <span
                            style={{
                                fontFamily: `${this.state.font}`,
                                color: this.props.isDarkTheme
                                    ? "white"
                                    : "black",
                                marginRight: "1%",
                                paddingRight: "2%",
                            }}
                            title={item[con]}
                        >
                            {item[con]}
                        </span>
                    ) : (
                        <span
                            style={{
                                fontFamily: `${this.state.font}`,
                                color: "red",
                                marginRight: "1%",
                                paddingRight: "2%",
                            }}
                            title={item[con]}
                        >
                            {item[con]}
                        </span>
                    );
            }
        }
    }

    private _onSettingsUpdated(key: string, value: ISetting): void {
        let settings = this.props.settings;
        settings[key] = value;

        this.props.onSettingsChanged(settings);
    }

    private _toggleCheckBox(key: string) {
        let setting = this.props.settings[key];
        setting.isChecked = !setting.isChecked;
        this._onSettingsUpdated(key, setting);
    }

    private _invokePanel = (key: string, target: string) => {
        this.setState({
            invokeTarget: target,
        });

        this.props.onSelectedSettingChanged([key, this.props.settings[key]]);
    };

    private _hidePanel = () => {
        const { invokeTarget } = this.state;
        if (this._selectedColor && this.props.selectedSetting) {
            let [key, setting] = this.props.selectedSetting;
            if (invokeTarget === "fill") {
                setting.fill = this._selectedColor.str;
            }

            if (invokeTarget === "border") {
                setting.strokeColor = this._selectedColor.str;
            }

            let settings = this.props.settings;
            settings[key] = setting;
            this.setState({
                invokeTarget: null,
            });

            this.props.onSelectedSettingChanged(null);
            this.props.onSettingsChanged(settings);
        } else {
            this.setState({
                invokeTarget: null,
            });
            this.props.onSelectedSettingChanged(null);
        }
    };
    private _markClicked() {
        this.setState({
            showMardked: true,
        });
    }
    private _submitMark() {
        if (!this.props.displayArea) {
            alert("No textline selected!");
            return;
        }
        const { markInfo } = this.state;
        if (markInfo.length === 0) {
            alert("Please input some description!");
            return;
        }
        let showContent = this.props.displayContent
            ? (JSON.parse(this.props.displayContent) as any[])
            : [];
        if (this.props.setImageMark) {
            this.props.setImageMark(
                markInfo,
                this.props.displayArea.lineId,
                showContent.length > 0
                    ? showContent[showContent.length - 1]["ref"]
                    : ""
            );
        }
        alert("You feedback is successfully submited! Thanks for your work!");
        this.setState({
            showMardked: false,
        });
    }
    private renderDataForMathFormula(content: any) {
        if (
            content &&
            content.raw_REF &&
            content.raw_HYP.filter((v: any) => v !== null && v !== undefined)
                .length > 0
        ) {
            if (content.raw_REF || content.raw_HYP) {
                let specialList: any[] = [];
                const refList = content.raw_REF;
                const hypList = content.raw_HYP;
                let specialMark: any = {
                    operatorname: "mathrm",
                    boldsymbol: "pmb",
                    mathbf: "pmb ",
                    Re: "R",
                    vec: "overrightarrow",
                    aligned: "array",
                };
                let result: any = { ref: refList, hyp: hypList };
                let ref: any = {};
                let hyp: any = {};
                let preparedRef: string = result.ref[0].toString();
                for (let mark in specialMark) {
                    if (preparedRef.indexOf(mark) !== -1) {
                        preparedRef = preparedRef
                            .replaceAll(/\t|\n|\v|\r|\f/g, "")
                            .replaceAll(`${mark}`, `${specialMark[mark]}`);
                    }
                }

                ref.name = "raw_ref";
                hyp.name = "raw_hyp";
                ref[`mathJax`] = preparedRef;
                hyp[`mathJax`] = result.hyp[0];
                specialList.push(ref);
                specialList.push(hyp);
                return specialList;
            }
        }
    }
    private renderDataForSpecialColumn(content: any) {
        if (
            content &&
            content.ref &&
            content.ref.filter((v: any) => v !== null && v !== undefined)
                .length > 0
        ) {
            let specialList: any[] = [];
            if (content.entity_name && content.ref) {
                const markIndex = content.entity_name.length - 1;
                let regEx = /\s+/g;
                const refList = content.ref[markIndex]
                    .replace(regEx, " ")
                    .split(" ");
                const hypList = content.hyp[markIndex]
                    .replace(regEx, " ")
                    .split(" ");
                let result: any = {
                    ref: refList,
                    hyp: hypList,
                };
                let tableLen: number = Math.max(
                    result.ref.length,
                    result.hyp.length
                );
                let ref: any = {};
                let hyp: any = {};
                for (let i = 0; i < tableLen; i++) {
                    ref.name = "ref";
                    hyp.name = "hyp";
                    ref[`con_${i}`] = result.ref[i];
                    hyp[`con_${i}`] = result.hyp[i];
                }
                specialList.push(ref);
                specialList.push(hyp);

                return specialList;
            } else if (content.ref) {
                let regEx = /\s+/g;
                const refList =
                    content.ref[0]?.replace(regEx, " ").split(" ") || [];
                const hypList =
                    content.hyp[0]?.replace(regEx, " ").split(" ") || [];
                let result: any = {
                    ref: refList,
                    hyp: hypList,
                };
                let tableLen: number = Math.max(
                    result.ref.length,
                    result.hyp.length
                );
                let ref: any = {};
                let hyp: any = {};
                for (let i = 0; i < tableLen; i++) {
                    ref.name = "ref";
                    hyp.name = "hyp";
                    ref[`con_${i}`] = result.ref[i];
                    hyp[`con_${i}`] = result.hyp[i];
                }
                specialList.push(ref);
                specialList.push(hyp);
                return specialList;
            }
        }
    }
    private renderRegTable(specialList: any[] | undefined, columns: any[]) {
        const FIXCOLUMNNUM = 8;

        if (specialList && columns.length < FIXCOLUMNNUM) {
            this.baseReferences = specialList;
            return (
                <DetailsList
                    key={`22`}
                    styles={{
                        root: {
                            boxShadow: DefaultEffects.elevation4,
                        },
                        headerWrapper: {
                            height: "12px",
                            lineHeight: "12px",
                            paddingTop: 0,
                            color: this.props.isDarkTheme
                                ? theme.palette.white
                                : theme.palette.black,
                        },
                    }}
                    items={specialList}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderItemColumn={this._renderSpeicalColumn}
                />
            );
        } else if (specialList) {
            const refArr = Object.values(specialList[0]);
            const hypArr = Object.values(specialList[1]);

            let arr = [refArr, hypArr];
            const createObj = (list: any) => {
                const o: any = {};
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];
                    if (index === 0) {
                        o["name"] = element;
                    } else {
                        const key = `con_${index}`;
                        o[key] = element;
                    }
                }
                return o;
            };

            const addressDataList = [];
            for (let index = 0; index < arr.length; index++) {
                const element: any = arr[index];
                if (element.length <= FIXCOLUMNNUM) {
                    addressDataList.push(createObj(element));
                } else {
                    const new_e: any[] = element.slice(1, element.length);
                    const maxNum: any = Math.ceil(new_e.length / 7);

                    for (let i = 0; i < maxNum; i++) {
                        const el3: any[] = new_e.slice(
                            i * 7,
                            i * 7 + 7 < new_e.length ? i * 7 + 7 : new_e.length
                        );

                        el3.unshift(element[0]);
                        addressDataList.push(createObj(el3));
                    }
                }
            }
            const leepColumns = [];
            const num = Math.ceil(addressDataList.length / 2);

            for (let index = 0; index < num; index++) {
                const ref_hyp = [
                    addressDataList[index],
                    addressDataList[index + num],
                ];

                leepColumns.push(ref_hyp);
            }
            this.baseReferences = leepColumns;
            return leepColumns.map((leepColumn, idx) => {
                let columns: IColumn[] = buildColumns(leepColumn!, true)
                    .filter((column) => column.key !== "key")
                    .map((column) => {
                        if (column.key === "name") {
                            column.minWidth = 10;
                            column.maxWidth = 20;
                            column.name = "";
                        }
                        if (column.key.includes("con_")) {
                            column.name = "";
                            column.minWidth = 20;
                            column.maxWidth = 100;
                        }
                        return column;
                    });

                return (
                    <DetailsList
                        key={`detailList_${idx}`}
                        styles={{
                            root: { boxShadow: DefaultEffects.elevation4 },
                            headerWrapper: {
                                height: "12px",
                                lineHeight: "12px",
                                paddingTop: 0,
                            },
                        }}
                        items={leepColumn}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        onRenderItemColumn={(
                            item: any,
                            _index?: number,
                            column?: IColumn
                        ) => {
                            return this._renderSpeicalColumn(
                                item,
                                _index,
                                column,
                                leepColumn
                            );
                        }}
                    />
                );
            });
        } else {
            return <></>;
        }
    }
    private renderMathTable(mathList: any[], columns: any[]) {
        return (
            <DetailsList
                key={`33`}
                styles={{
                    root: {
                        boxShadow: DefaultEffects.elevation4,
                    },
                    headerWrapper: {
                        height: "12px",
                        lineHeight: "12px",
                        paddingTop: 0,
                        color: this.props.isDarkTheme
                            ? theme.palette.white
                            : theme.palette.black,
                    },
                }}
                items={mathList}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={this._renderMathFormulaColumn}
            />
        );
    }
}
