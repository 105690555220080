import React from "react";

import { Record, Typename, Workspaces } from "../../DataContract";
import { DetailView } from "../../Views/QueryField/DetailView";
import { Overview } from "../../Views/QueryField/Overview";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    linkData?: any;
    deepLinkHandler?: (key: string, linkData: any) => void;
    isDarkTheme?: boolean;
}

export class QueryFieldPage extends React.Component<IProps, IState> {
    public render() {
        const { deepLinkHandler, linkData, records, selectedKey } = this.props;

        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };
        return (
            <>
                {selectedKey === "overviewQueryFieldMetrics" && (
                    <Overview
                        records={records}
                        imageViewDeepLinkHandler={deepLinkHandler}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "overviewQueryFieldDetailMetrics" && (
                    <DetailView
                        saveSetKey={`${Workspaces.QueryField}_${Typename.DetailView}`}
                        records={records}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectOption1={linkSetting?.toSelectOption1}
                        toSelectOption2={linkSetting?.toSelectOption2}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
            </>
        );
    }
}
