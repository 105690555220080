import React from "react";
import "../Common/MetricStyle.scss";
import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";

import { DatasetSet, IDataItem } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { IWerDataItem, MetricsAnalysis } from "./OcrDataContract";
import { WordAlignImageView } from "./WordAlignImageVIew";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import {
    OVERVIEW_COLUMNS,
    WordAlignMetricsOverview,
} from "./WordAlignMetricsOverview";
import _ from "lodash";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

interface OcrWordResult {
    category: string;
    wordCount: number;
    goodAlignPercent: number;
    goodAlignCount: number;
    recall: number;
    precision: number;
    overlap: number;
    recallLt95Perc: number;
    recallLt95Cnt: number;
    [key: string]: number | string;
}

interface OcrWordAlignMetrics {
    word_alignment_metrics: Array<OcrWordResult>;
}

interface IProps extends ICommonProps {
    analysisOn?: boolean;
    updateAnalysisState?: (
        metricsAnalysis: MetricsAnalysis,
        isOn: boolean
    ) => void;
}

interface IState extends ICommonState<OcrWordAlignMetrics> {
    dataItems: IDataItem<OcrWordAlignMetrics>[];
    selectedPivot: string;
}

export class WordAlignMetrics extends CommonView<
    IProps,
    IState,
    OcrWordAlignMetrics
> {
    private _ignoreLanguages: string[] = [];
    private linkData?: any;
    constructor(props: IProps) {
        super(props);

        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            dataItems: [],
            selectedPivot: "Overview",
            matchDatasetVersion: true,
        };
    }

    public render() {
        return (
            <div className="overview">
                <Pivot
                    onLinkClick={this.onPivotItemChanged}
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <WordAlignMetricsOverview
                            selectedColumns={this.state.selectedColumns}
                            datas={this.exportData}
                            alwaysShow={this.props.alwaysShow}
                            isDarkTheme={this.props.isDarkTheme}
                            setSelectedSubPivot={this.setSelectedSubPivot}
                        />
                    </PivotItem>
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By Image"
                            className="displayFlex"
                            itemKey="ByImage"
                        >
                            <WordAlignImageView
                                records={this.props.records}
                                matchDatasetVersion={
                                    this.state.matchDatasetVersion
                                }
                                linkData={this.linkData}
                                ignoreLanguages={this._ignoreLanguages}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                </Pivot>
            </div>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("overall_word_alignment_metrics.json");
    }

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (!_.isEqual(this.state?.dataItems, prevState?.dataItems)) {
            this._renderDataItems();
        }
    }

    _renderDataItems = () => {
        const { matchDatasetVersion } = this.state;

        const data = this._prepareRenderData(matchDatasetVersion!);
        this.exportData = [];
        this._ignoreLanguages = [];
        data.forEach(([datasetName, groupData]) => {
            const tableData = groupData.map(([_, werItems]) => {
                return werItems.map((werItem) => werItem.wer);
            });

            const simplifyData = tableData.filter((dataArr) =>
                dataArr.some((data) => data.wordCount > 0)
            );

            if (simplifyData && simplifyData.length > 0) {
                this.exportData.push([datasetName, simplifyData]);
            } else {
                this._ignoreLanguages.push(datasetName);
            }
        });
        this.forceUpdate();
    };

    exportAction = () => {
        if (this.state.selectedPivot === "Overview") {
            exportOverviewListData(
                this.exportData,
                OVERVIEW_COLUMNS,
                `WordAlignMetrics`
            );
        }
    };

    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const wers = item?.metrics.word_alignment_metrics;
                        return wers ? wers.map((wer) => wer.category) : [];
                    })
                )
            );

            const groupData = categories.map((category) => {
                // retrieve wer data
                const werItems = items.map((item) => {
                    let wordMetrice: OcrWordResult | undefined;
                    if (
                        item &&
                        item.metrics &&
                        item.metrics.word_alignment_metrics &&
                        item.metrics.word_alignment_metrics.length > 0
                    ) {
                        const { metrics } = item;
                        wordMetrice = metrics.word_alignment_metrics.find(
                            (wer) => wer.category === category
                        );
                    }
                    if (wordMetrice) {
                        wordMetrice.recallLt95Cnt = Number(
                            wordMetrice["recallLt95%Cnt"]
                        );
                        wordMetrice.recallLt95Perc = Number(
                            wordMetrice["recallLt95%Perc"]
                        );
                    }

                    if (wordMetrice === undefined) {
                        wordMetrice = {
                            category: category,
                            wordCount: NaN,
                            goodAlignPercent: NaN,
                            goodAlignCount: NaN,
                            recall: NaN,
                            precision: NaN,
                            overlap: NaN,
                            recallLt95Perc: NaN,
                            recallLt95Cnt: NaN,
                        };
                    }

                    return {
                        data: item,
                        wer: wordMetrice,
                    } as IWerDataItem<
                        IDataItem<OcrWordAlignMetrics>,
                        OcrWordResult
                    >;
                });
                return [category, werItems] as [
                    string,
                    IWerDataItem<
                        IDataItem<OcrWordAlignMetrics>,
                        OcrWordResult
                    >[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [
                    string,
                    IWerDataItem<
                        IDataItem<OcrWordAlignMetrics>,
                        OcrWordResult
                    >[]
                ][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    public onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }

        this.linkData = undefined;
        this.changeSelectedSubPivot(item?.props.itemKey!);
    }

    setSelectedSubPivot = (selectPivot: string, linkData?: any) => {
        this.linkData = linkData;
        this.changeSelectedSubPivot(selectPivot);
    };

    changeSelectedSubPivot = (selectPivot: string) => {
        this.setState({
            selectedPivot: selectPivot,
        });

        store.dispatch(updateSelectSubpivoteAction(selectPivot));
    };
}
