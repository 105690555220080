import { legacy_createStore as createStore, combineReducers } from "redux";
import { globalReducer } from "./reducers";
import { settingReducer } from "./reducers/setting";
import { loadingReducer } from "./reducers/loading";
import { fullscreenReducer } from "./reducers/fullscreen";

const reducer = combineReducers({
    globalReducer,
    settingReducer,
    loadingReducer,
    fullscreenReducer,
});
export const store = createStore(reducer);
export type rootState = ReturnType<typeof store.getState>;
