import React from "react";
import { CustFormErrorAnalysis, IState } from "./CustFormErrorAnalysis";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
} from "@fluentui/react";
import _ from "lodash";
import { PieChart, PieData } from "../../Controls/D3/PieChart";
import { getColorByIndex } from "../../Utils";
import { NoDataTip } from "../../Controls/NoDataTip";
import { IMetricProps } from "../Common/MetricView";
import { CustomDropdowns } from "./CustomDropdown";
import { Consumer } from "../../Layout";

interface ListType {
    errorType: string;
    counts: number;
    percentage: string;
}

// prettier-ignore
const CUSTOMFORM_ERROR_OVERVIEW_COLUMNS: IColumn[] = [
    { key: "errorType",         name: "error type",       fieldName: "errorType",         minWidth: 100,  maxWidth: 200, isResizable: true, },
    { key: "counts",            name: "Counts",           fieldName: "counts",            minWidth: 100,  maxWidth: 200, isResizable: true, },
    { key: "percentage",        name: "Percentage ",      fieldName: "percentage",        minWidth: 100,  maxWidth: 200, isResizable: true, },
    

];

export class CustFormErrorAnalysisOverall extends CustFormErrorAnalysis {
    componentDidUpdate(prevProps: IMetricProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (!_.isEqual(prevState.evalData, this.state.evalData)) {
            this.setState({ showEvalData: this.state.evalData });
        }
    }
    renderDataView(): React.ReactNode {
        const { showEvalData } = this.state;

        const datas = this.prepareData();
        return (
            <div
                className="displayFlex"
                style={{ paddingTop: "5px", overflowY: "auto" }}
            >
                {this.renderDropdown()}
                {_.isEmpty(showEvalData) ? (
                    <NoDataTip>No Error Analysis Data</NoDataTip>
                ) : (
                    <div className="displayFlex" style={{ overflowY: "auto" }}>
                        {datas.map((data) => this.renderPieAndList(data))}
                    </div>
                )}
            </div>
        );
    }

    prepareData = (): [any[], ListType[]][] => {
        const { showEvalData = {} } = this.state;
        const recordDatas = this.props.records.map((_, index) => {
            return Object.values(showEvalData).map((v) => v[index]);
        });
        const datas = recordDatas.map((metrics) => {
            const filterMetrics = metrics.filter((v) => v);
            const groupMetrics = _.groupBy(
                filterMetrics,
                (m) => m?.["error type"]
            );
            const errorTypes = Object.keys(groupMetrics);

            let totalCounts = 0;
            let listDatas = errorTypes.map((errortype) => {
                const values = groupMetrics[errortype];
                const count = values.length;
                totalCounts += count;

                return { errorType: errortype, counts: count } as ListType;
            });

            listDatas = listDatas.map((data) => {
                const per = `${((data.counts * 100) / totalCounts).toFixed(
                    2
                )}%`;
                data.percentage = per;
                return data;
            });

            const chartDatas = listDatas.map((data, index) => {
                return {
                    title: data.errorType,
                    value: data.counts,
                    color: getColorByIndex(index),
                } as PieData;
            });

            return [chartDatas, listDatas] as [any[], ListType[]];
        });
        return datas;
    };

    renderList = (listdata: any) => {
        return (
            <DetailsList
                items={listdata}
                columns={CUSTOMFORM_ERROR_OVERVIEW_COLUMNS}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
            />
        );
    };

    renderPieChar = (charData: PieData[]) => {
        return (
            <Consumer>
                {(value) => (
                    <PieChart data={charData} isDarkTheme={value}></PieChart>
                )}
            </Consumer>
        );
    };

    renderDropdown = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <CustomDropdowns
                    keys={["error type", "doc", "field type", "field name"]}
                    labels={["Error Type", "Doc", "Field Type", "Field Name"]}
                    evalData={this.state.evalData}
                    onFilter={(filtrtData) => {
                        this.setState({ showEvalData: filtrtData });
                    }}
                />
            </div>
        );
    };

    renderPieAndList = (datas: [PieData[], ListType[]]) => {
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            minWidth: "400px",
                        }}
                    >
                        {this.renderPieChar(datas[0])}
                    </div>
                    <div style={{ flex: 1 }}>{this.renderList(datas[1])}</div>
                </div>
            </div>
        );
    };
}
