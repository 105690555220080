import { AnyAction } from "redux";

const initialState = {
    isPrepareRenderData: false,
    isNetworkRequest: false,
};

export interface LoadingState {
    isPrepareRenderData: boolean;
    isNetworkRequest: boolean;
}

export class ActionType {
    public static readonly UpdateLoading = "UPDATE_LOADING";
}

export const loadingReducer = (
    state: LoadingState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case ActionType.UpdateLoading:
            return { ...state, ...action.value };

        default:
            return state;
    }
};

export const updateLoadingAction = (state: any) => {
    return {
        type: ActionType.UpdateLoading,
        value: state,
    };
};
