import {
    IPivotStyles,
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";
import React from "react";
import { CHOICE_OPTIONS, DATASET_NAME_SYMBOLS } from "./EntityConstants";

const IPivotItemStyles: Partial<IPivotStyles> = {
    root: {
        background: "#f3f2f1",
        display: "flex",
        justifyContent: "space-between",
    },
    linkIsSelected: {
        backgroundColor: "#ffffff",
        color: "rgb(0, 120, 212)",
        fontWeight: "bold",
    },
};

interface IProps {
    onSelectItemKey?: (itemKey: string) => void;
}

export class NameSymbols extends React.Component<IProps> {
    render() {
        const { onSelectItemKey } = this.props;
        return (
            <Pivot
                linkFormat={PivotLinkFormat.tabs}
                onLinkClick={(pivotprops) => {
                    onSelectItemKey &&
                        onSelectItemKey(pivotprops?.props.itemKey!);
                }}
                className={"entityList"}
                styles={IPivotItemStyles}
            >
                {CHOICE_OPTIONS.map((option, index) => {
                    return (
                        <PivotItem
                            key={`pivot_${index}`}
                            headerText={option.text}
                            itemKey={option.key}
                            alwaysRender={
                                option.key === DATASET_NAME_SYMBOLS.OCR_Printed
                                    ? true
                                    : false
                            }
                        ></PivotItem>
                    );
                })}
            </Pivot>
        );
    }
}
