import React from "react";

import { Label } from "@fluentui/react";

import { Record, Workspaces } from "../../DataContract";

import { LoadDetails } from "../../Views/OcrRelease/LoadDetails";
import { ReliDetails } from "../../Views/OcrRelease/ReliDetails";
import { ReleaseTestOverview } from "../../Views/OcrRelease/OcrReleaseOverview";
import { BaseLineDetails } from "../../Views/OcrRelease/BaseLineDetails";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class CustomDocReleaseTestPage extends React.Component<IProps, IState> {
    public render() {
        const { records, selectedKey } = this.props;
        return (
            <div className="displayFlex">
                {selectedKey !== "reliaReleaseMetrics" && (
                    <Label style={{ paddingLeft: "10px" }}>
                        Dashboard deployed from release:
                        {records.map((record) => {
                            return record.id;
                        })}
                    </Label>
                )}

                {selectedKey === "overviewReleaseMetrics" && (
                    <ReleaseTestOverview records={records} />
                )}
                {selectedKey === "reliaReleaseMetrics" && (
                    <ReliDetails records={records} />
                )}
                {selectedKey === "loadReleaseMetrics" && (
                    <LoadDetails records={records} />
                )}
                {selectedKey === "baselineReleaseMetrics" && (
                    <BaseLineDetails
                        records={records}
                        workspace={Workspaces.CustomDocReleaseTest}
                    />
                )}
            </div>
        );
    }
}
