import React from "react";
import { Dictionary } from "lodash";
import "../Common/MetricStyle.scss";

import { IGroupedVerticalBarChartData } from "@uifabric/charting";

import {
    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
} from "@fluentui/react";

import { calculateUnit, getColorByIndex } from "../../Utils";
import { DatasetSet, Typename, Workspaces } from "../../DataContract";
import { CommonView, ICommonProps } from "../Common/CommonMetrics";
import { VerticalBarChart } from "../../Controls/D3/VerticalBarChart";
import { ColumnValueType, OverviewTable, TableColumn } from "../../Controls";
import {
    OcrLatencyPerfMetrics,
    ILatencyDataItem,
    IState,
    ShortenMetricKeyName,
    MatchFieldNameIgnoreCase,
    languageToScript,
} from "./Interface";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { exportOverviewListData } from "../../Utils/ExportFile";
import _ from "lodash";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import {
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../Utils/LoadingUtil";
import {
    initLatency,
    mergeDataItems,
    tryFindMatchedItems,
} from "./LatencyUtil";
import { LatencyType } from "./CommonAnalysisView";
import { ColumnPrefix, LatencyColumns } from "./LatencyColumn";
import { LatencyExclusiveMetricField } from "./LatencyExclusiveMetricField";
import { NoDataTip } from "../../Controls/NoDataTip";

const CLIENT_WIDTH = document.documentElement.clientHeight - 133;

interface IProps extends ICommonProps {
    crossLanguage: boolean;
    defaultCrossLanguage?: string;
    getDataItems: (dataItems: any) => void;
    deepLinkHandler?: (selectPivot: string, linlData: any) => void;
}

export class LatencyMetricsOverview extends CommonView<
    IProps,
    IState,
    OcrLatencyPerfMetrics
> {
    private OVERVIEW_COLUMNS: TableColumn[];
    private selectedItems?: string[];
    private defaultCategory: string = "Total";
    constructor(props: IProps) {
        super(props);
        this.OVERVIEW_COLUMNS =
            this.workSpace === "ocrmath" ? [] : LatencyColumns[this.workSpace];
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.OVERVIEW_COLUMNS.forEach((value) => {
            value.name = ShortenMetricKeyName(value.name);
        });

        if ([Workspaces.OcrFigure, Workspaces.Kvp].includes(this.workSpace)) {
            this.defaultCategory = "Overall";
        }

        this.state = {
            dataItems: [],
            selectedColumns: [],
            includesLongTail: true,
            crossLanguage: this.props.crossLanguage,
            selectedPivot: "Overview",
            allColumns: this.OVERVIEW_COLUMNS.slice(),
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: true,
            viewType: store.getState().globalReducer.viewType,
        };
    }

    public render() {
        const { viewType } = this.state;

        if (
            this.state.dataItems[0] !== undefined &&
            this.state.dataItems[0].metrics !== undefined &&
            this.state.dataItems[0].metrics.category_latency !== undefined
        ) {
            return this.state.dataItems[0].metrics.category_latency.length !==
                0 ? (
                <>
                    {viewType === "Table" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}
                    {viewType === "Chart" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </>
            ) : (
                <NoDataTip>No Latency Metrics Generated</NoDataTip>
            );
        } else {
            return <NoDataTip>No Latency Metrics Generated</NoDataTip>;
        }
    }
    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: this._getSettingSaveKey(),
                vertical: false,
            })
        );
    }

    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            this.state.allColumns,
            `${this.workSpace}_LactencyOverviewMetrics`
        );
    };

    queryMetricsResult() {
        showPrepareRenderDataLoading();
        this._queryMetricsResult("overall_latency_metrics.json");
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            ) ||
            !_.isEqual(this.props.crossLanguage, prevProps.crossLanguage) ||
            !_.isEqual(this.state.dataItems, prevState.dataItems)
        ) {
            const data = this._prepareRenderData(
                this.state.matchDatasetVersion!,
                this.props.crossLanguage
            );
            this.setState({ renderData: data });
        }

        if (!_.isEqual(this.state.dataItems, prevState.dataItems)) {
            const { allColumns } = this.state;
            this.props.getDataItems(this.state.dataItems);
            if (
                this.state.dataItems[0] !== undefined &&
                this.state.dataItems[0].metrics !== undefined &&
                this.state.dataItems[0].metrics.category_latency !== undefined
            ) {
                let overall_latency_metric: any;
                if (
                    this.state.dataItems[0].metrics.category_latency instanceof
                    Array
                ) {
                    overall_latency_metric =
                        this.state.dataItems[0].metrics.category_latency.find(
                            (element) =>
                                element["category"] === this.defaultCategory
                        );
                } else {
                    overall_latency_metric =
                        this.state.dataItems[0].metrics.category_latency;
                }

                for (let metric_key in overall_latency_metric) {
                    if (
                        allColumns.find((element) =>
                            MatchFieldNameIgnoreCase(element.key, metric_key)
                        ) === undefined
                    ) {
                        if (
                            !LatencyExclusiveMetricField[this.workSpace].has(
                                metric_key.toLowerCase()
                            )
                        ) {
                            allColumns.push({
                                key: metric_key,
                                name: ShortenMetricKeyName(metric_key),
                                fieldName: metric_key,
                                valueType: ColumnValueType.Number,
                                minWidth: 5,
                                maxWidth: Number(`${CLIENT_WIDTH / 10}`),
                                isResizable: true,
                            });
                        }
                    }
                }

                this.setState({
                    allColumns: allColumns,
                });
                if (
                    [
                        Workspaces.Ocr,
                        Workspaces.ReleaseTest,
                        Workspaces.OcrCheckbox,
                        Workspaces.Kvp,
                    ].includes(this.workSpace)
                ) {
                    this.selectedItems = this.OVERVIEW_COLUMNS.map(
                        (val) => val.key
                    );
                    store.dispatch(
                        updateStateAction({
                            columns: allColumns,
                            selectedItems: this.selectedItems,
                        })
                    );
                } else {
                    this.updateSettingSelectedItemsAndColumns(allColumns);
                }
            }
        }
        if (!_.isEqual(this.state.viewType, prevState.viewType)) {
            store.dispatch(
                updateStateAction({
                    saveKey: this._getSettingSaveKey(),
                    selectedItems: this.selectedItems,
                })
            );
        }
    }

    protected updateSettingSelectedItemsAndColumns = (
        columns?: TableColumn[]
    ) => {
        const allColumns = columns ?? this.state.allColumns;
        if (allColumns.length > 0) {
            const noCategoryKey = allColumns.filter(
                (column) => column.key !== "category"
            );
            const categoryKey = allColumns.filter(
                (column) => column.key === "category"
            );
            const newColumns = [...categoryKey, ...noCategoryKey];
            const newChartColumns = [...noCategoryKey];

            const selectedItems: string[] = newColumns
                .filter((col) => {
                    const lowerColName = col.name.toLowerCase();
                    return ColumnPrefix[this.workSpace](lowerColName);
                })

                .map((val) => val.key);

            this.selectedItems = selectedItems;

            store.dispatch(
                updateStateAction({
                    columns:
                        this.state.viewType === "Chart"
                            ? newChartColumns
                            : newColumns,
                    selectedItems: selectedItems,
                    saveKey: this._getSettingSaveKey(),
                })
            );
        }
    };

    private _renderAsTable() {
        const { selectedColumns, allColumns, expandItem, renderData } =
            this.state;
        let newColumns = allColumns;
        if (
            [
                Workspaces.OcrTable,
                Workspaces.OcrPod,
                Workspaces.OcrFigure,
            ].includes(this.workSpace)
        ) {
            const noCategoryKey = allColumns.filter(
                (column) => column.key !== "category"
            );
            const categoryKey = allColumns.filter(
                (column) => column.key === "category"
            );
            newColumns = [...categoryKey, ...noCategoryKey];
        }
        const columns: TableColumn[] = newColumns
            .map((col: any) => {
                return this._generateColumn(col);
            })
            .filter(
                (value) =>
                    selectedColumns?.findIndex((col) => col === value.key) !==
                    -1
            );

        const data = renderData || [];
        this.exportData = [];
        return this.state.dataItems.length !== 0 && data.length !== 0 ? (
            data.map(([datasetName, groupData], index) => {
                const shownDataset = datasetName;
                datasetName =
                    datasetName.slice(0, datasetName.indexOf(":")) +
                    "    Dataset: " +
                    datasetName.slice(
                        datasetName.indexOf(":") + 1,
                        datasetName.length
                    );
                const tableData = groupData.map(([_, latencyItems]) => {
                    return latencyItems.map(
                        (latencyItem) => latencyItem.latency
                    );
                });
                this.exportData.push([datasetName, tableData]);
                if (tableData) {
                    return (
                        <div
                            className="overview__detail"
                            key={`table_${datasetName}`}
                        >
                            <ExpandCard
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            >
                                <OverviewTable<Dictionary<any>>
                                    evalData={tableData}
                                    columns={columns}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    gapUnit={calculateUnit.Percentage}
                                    onItemInvoked={this._onItemInvoked}
                                    datasetName={shownDataset}
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                            </ExpandCard>
                        </div>
                    );
                } else {
                    return <NoDataTip>No Latency Metrics Generated</NoDataTip>;
                }
            })
        ) : (
            <NoDataTip>No Latency Metrics Generated </NoDataTip>
        );
    }

    private _renderAsChart() {
        const { crossLanguage } = this.props;
        const { renderData, selectedColumns = [] } = this.state;

        const data = renderData || [];

        return this.state.dataItems.length !== 0 && data.length !== 0 ? (
            data.map(([datasetName, groupData], index) => {
                if (!crossLanguage) {
                    datasetName =
                        datasetName.slice(0, datasetName.indexOf(":")) +
                        "    Dataset: " +
                        datasetName.slice(
                            datasetName.indexOf(":") + 1,
                            datasetName.length
                        );
                }

                const totalLatencyItem =
                    groupData.find(
                        (group) => group[0] === this.defaultCategory
                    ) ?? undefined;

                let chartData: IGroupedVerticalBarChartData[] = [];
                if (!!totalLatencyItem) {
                    const latencyItems = totalLatencyItem[1];

                    let selectedColInCharts = selectedColumns.filter((col) => {
                        const lowerColName = col.toLowerCase();
                        return (
                            !lowerColName.endsWith("count") &&
                            lowerColName !== "category"
                        );
                    });
                    if (
                        [
                            Workspaces.Ocr,
                            Workspaces.ReleaseTest,
                            Workspaces.OcrCheckbox,
                        ].includes(this.workSpace)
                    ) {
                        selectedColInCharts = selectedColInCharts.filter(
                            (col) => {
                                const lowerColName = col.toLowerCase();
                                return lowerColName !== "page_cnt";
                            }
                        );
                    }

                    chartData = selectedColInCharts.map((col) => {
                        const series = latencyItems.map((item, index) => {
                            const fieldName =
                                this._getColFieldNameByColKey(col);
                            let val = item.latency[fieldName];
                            if (!val) {
                                val = 0;
                            }
                            return {
                                key: `series_${index}`,
                                data: val,
                                color: getColorByIndex(index),
                                legend: this.props.records[index].name,
                            };
                        });

                        return {
                            name: ShortenMetricKeyName(col),
                            series: series,
                        } as IGroupedVerticalBarChartData;
                    });
                }

                return (
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card"
                        type={DocumentCardType.compact}
                    >
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                className="overview__title"
                                title={datasetName}
                            />
                            <VerticalBarChart
                                id={"vBarChart" + index}
                                data={chartData}
                                height={500}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </DocumentCardDetails>
                    </DocumentCard>
                );
            })
        ) : (
            <NoDataTip>No Latency Metrics Generated</NoDataTip>
        );
    }

    private _prepareRenderData(
        matchDatasetVersion: boolean,
        crossLanguage: boolean
    ) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const { defaultCrossLanguage = "Unknown" } = this.props;

        const dataRecordNames = Array.from(
            new Set(
                datasets.map((dataset) => {
                    if (!crossLanguage) {
                        return matchDatasetVersion
                            ? dataset.displayFullName
                            : dataset.displayName;
                    } else {
                        // use script name instead of dataset name
                        let language = dataset.displayFullName.split(":")[0];
                        return (
                            languageToScript[language] ?? defaultCrossLanguage
                        );
                    }
                })
            )
        );

        const data = dataRecordNames.map((dataRecordName) => {
            const items = this.props.records.map((_, recordIndex) => {
                // all item in this list will be merged into one single item
                let itemList = tryFindMatchedItems(
                    this.state.dataItems,
                    crossLanguage,
                    recordIndex,
                    matchDatasetVersion,
                    dataRecordName,
                    defaultCrossLanguage
                );
                // use the first item as merged target, use cloned data incase dup merge
                let mergedItem: any = itemList[0];
                if (crossLanguage) {
                    mergedItem = mergeDataItems(
                        itemList,
                        this.workSpace,
                        this.defaultCategory,
                        LatencyType.Overview
                    );
                }
                return mergedItem;
            });

            const categories = [this.defaultCategory];
            const groupData = categories.map((category) => {
                // retrieve latency data
                const latencyItems = items.map((item) => {
                    let latency: Dictionary<any> | undefined;
                    if (item && item.metrics && item.metrics.category_latency) {
                        if (item.metrics.category_latency instanceof Array) {
                            latency = item.metrics.category_latency.find(
                                (latency: any) =>
                                    latency["category"] === category
                            );
                        } else {
                            latency = item.metrics.category_latency;
                        }

                        if (latency) {
                            latency.datasetName = matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName;
                        }
                    }

                    if (latency === undefined) {
                        latency = initLatency(this.workSpace, category);
                    }
                    return {
                        data: item,
                        latency: latency,
                    } as ILatencyDataItem;
                });
                return [category, latencyItems] as [string, ILatencyDataItem[]];
            });

            return [dataRecordName, groupData] as [
                string,
                [string, ILatencyDataItem[]][]
            ];
        });
        hidePrepareRenderDataLoading();
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _getColFieldNameByColKey(key: string): string {
        const target = this.state.allColumns.find((col) => col.key === key);
        return target ? target.fieldName! : "";
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        if (this.props.crossLanguage) {
            return;
        }
        const values = item as any[];
        if (values?.length >= 2) {
            const linkData: { [key: string]: string | undefined } = {};
            const rowData = values[1];
            if (Array.isArray(rowData)) {
                const defData = rowData.find((d) => d);
                defData && (linkData.toSelectLanguage = defData.datasetName);
            }
            if (this.props.deepLinkHandler) {
                this.props.deepLinkHandler("ByImage", linkData);
            }
        }
    }

    private _generateColumn(col: TableColumn): TableColumn {
        return {
            key: col.key,
            name: col.name,
            valueType:
                col.key === "category"
                    ? ColumnValueType.String
                    : ColumnValueType.Number,
            minWidth: 100,
            maxWidth: col.key === "category" ? 100 : 400,
            distinctStr: col.key === "category",
        };
    }

    private _getSettingSaveKey = () => {
        return `${
            this.props.saveSetKey ??
            `${this.workSpace}_${Typename.LatencyMetrics}`
        }_${this.state.viewType}_Overview`;
    };
}
