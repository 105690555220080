import React from "react";
import { Record } from "../../DataContract";
import { Consumer } from "../../Layout";
import { EvalInfoCard } from "../../Controls";
import { OrderType } from "../../Controls/Common/ChangeOrderBar";
import { IconButton, Label, Stack } from "@fluentui/react";

interface IProps {
    records: Record[];
    workspace: string;
    selectedRecordIds: Set<string>;
    selectedAlgoArr: string[];
    visibleCalloutId: string;
    onAdd: () => void;
    removeRecord: (index: number) => void;
    onRecordOptionChanged: (index: number, name: string, value: string) => void;
    onChangevisibleCalloutId: (id: string) => void;
    onChangeOrder: (type: OrderType, recordId: string) => void;
    onCalloutDismiss: () => void;
    setRecordTag: (record: Record, tags: string[], itemId: string) => void;
    onSelected: (id: string, isChecked: boolean) => void;
}
interface IState {
    show: boolean;
}

export class RecordsSelector extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);
        this.state = {
            show: true,
        };
    }
    public render() {
        const { show } = this.state;
        const {
            records,
            workspace,
            selectedRecordIds,
            selectedAlgoArr,
            visibleCalloutId,
            onAdd,
            removeRecord,
            onRecordOptionChanged,
            onChangevisibleCalloutId,
            onChangeOrder,
            onCalloutDismiss,
            setRecordTag,
            onSelected,
        } = this.props;
        const ids = records
            .filter((r) => selectedRecordIds.has(r.id))
            .map((r) => r.id);
        return (
            <>
                {records && records.length > 0 && (
                    <div>
                        <Stack
                            horizontal
                            verticalAlign="center"
                            horizontalAlign="start"
                            tokens={{ childrenGap: 10, padding: 10 }}
                        >
                            <Label
                                style={{ fontSize: "20px" }}
                            >{`Select ${records.length} Records`}</Label>
                            <IconButton
                                iconProps={{
                                    iconName: show
                                        ? "DoubleChevronUp"
                                        : "DoubleChevronDown",
                                }}
                                onClick={() =>
                                    this.setState((preS) => {
                                        return { show: !preS.show };
                                    })
                                }
                            />
                        </Stack>
                        {show && (
                            <>
                                {" "}
                                {records.map((record, recordIndex) => {
                                    return (
                                        <Consumer
                                            key={`mainConsumerKey_${recordIndex}`}
                                        >
                                            {(value) => {
                                                return (
                                                    <EvalInfoCard
                                                        key={`evalinfo_${recordIndex}`}
                                                        checked={selectedRecordIds.has(
                                                            record.id
                                                        )}
                                                        showCheckbox={
                                                            records.length > 1
                                                        }
                                                        isDarkTheme={value}
                                                        recordIndex={ids.indexOf(
                                                            record.id
                                                        )}
                                                        record={record}
                                                        selectedAlgo={
                                                            selectedAlgoArr[
                                                                recordIndex
                                                            ]
                                                        }
                                                        showLabel={
                                                            recordIndex === 0
                                                        }
                                                        showAddButton={
                                                            recordIndex ===
                                                            records.length - 1
                                                        }
                                                        showup={
                                                            recordIndex !== 0
                                                        }
                                                        showdown={
                                                            recordIndex !==
                                                            records.length - 1
                                                        }
                                                        showRemoveButton={
                                                            records.length > 1
                                                        }
                                                        onAdd={onAdd}
                                                        onRemove={() => {
                                                            removeRecord(
                                                                recordIndex
                                                            );
                                                        }}
                                                        onOptionChange={(
                                                            name,
                                                            value
                                                        ) => {
                                                            onRecordOptionChanged(
                                                                recordIndex,
                                                                name,
                                                                value
                                                            );
                                                        }}
                                                        workspace={workspace}
                                                        onChangevisibleCalloutId={
                                                            onChangevisibleCalloutId
                                                        }
                                                        onChangeOrder={
                                                            onChangeOrder
                                                        }
                                                        visibleCalloutId={
                                                            visibleCalloutId
                                                        }
                                                        onCalloutDismiss={
                                                            onCalloutDismiss
                                                        }
                                                        setRecordTag={
                                                            setRecordTag
                                                        }
                                                        onSelected={onSelected}
                                                    />
                                                );
                                            }}
                                        </Consumer>
                                    );
                                })}
                            </>
                        )}
                    </div>
                )}
            </>
        );
    }
}
