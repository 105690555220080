import { IconButton, Stack } from "@fluentui/react";
import React from "react";
import "./FullScreen.scss";
import { store } from "../../../store";
import { updateFullscreenAction } from "../../../store/reducers/fullscreen";
import { Unsubscribe } from "redux";
import { Consumer } from "../../Layout";

interface IProps {
    style?: React.CSSProperties;
}

interface IState {
    isFullscreen: boolean;
}

export class FullScreen extends React.Component<IProps, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(prop: IProps) {
        super(prop);
        this.state = {
            isFullscreen: store.getState().fullscreenReducer.isFullscreen,
        };
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(() => {
            const reducer = store.getState().fullscreenReducer;

            this.setState({
                isFullscreen: reducer.isFullscreen,
            });
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render(): React.ReactNode {
        const { isFullscreen } = this.state;
        const {
            style = {
                width: "100%",
                height: "100%",
            },
        } = this.props;
        const iconName = isFullscreen ? "BackToWindow" : "FullScreen";

        const fullStyle = (dark: boolean): React.CSSProperties => {
            return {
                position: "absolute",
                left: "0",
                top: "0",
                bottom: "0",
                right: "0",
                backgroundColor: dark ? "black" : "white",
                padding: "20px",
            }
        };
        return (
            <Consumer>
                {(value) => {
                    return (<div
                        className="fullScreen"
                        style={isFullscreen ? fullStyle(value) : style}
                    >
                        <Stack horizontal horizontalAlign="end">
                            <IconButton
                                className="expandIcon"
                                iconProps={{ iconName: iconName }}
                                onClick={() => {
                                    store.dispatch(
                                        updateFullscreenAction(!isFullscreen)
                                    );
                                }}
                            ></IconButton>
                        </Stack>
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                overflow: "hidden",
                            }}
                        >
                            {this.props.children}
                        </div>
                    </div>)
                }}
            </Consumer>
        );
    }
}
