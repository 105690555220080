import * as React from "react";
import "./TableList.scss";
import _ from "lodash";
import {
    // ContextualMenu
    ContextualMenuItemType,
    IContextualMenuProps,

    // DetailsList
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IGroup,
    SelectionMode,

    // Stack
    Stack,

    // ContextualMenu
    IconButton,
    IContextualMenuItem,
    IIconProps,

    //Sticky
    ConstrainMode,

    //tootip
    DirectionalHint,
    ITooltipHostStyles,
    TooltipHost,

    // others
    Label,
    Link,
    mergeStyleSets,
    Toggle,
    DetailsRow,
    getTheme,
    IDetailsListStyles,
    IDetailsRowStyles,
} from "@fluentui/react";

import CopyToClipboard from "react-copy-to-clipboard";
import {
    IMetricUnit,
    IMergedMetrics,
    Sortability,
    COLUMN_KEY_SEPARATOR,
    Workspaces,
    RGB_Color,
} from "../DataContract";
import { DetailsList_onRenderDetailsHeader_FreezeHeader } from "../Utils/LibraryOverwriteAndExtension";
import { FilterBar } from ".";
import { from } from "linq-to-typescript";
import { optimizeDecimalPlaces } from "../Utils";
import { Consumer } from "../Layout";
import { NoDataTip } from "./NoDataTip";
import { store } from "../../store";
import { DownloadButton } from "./Common/DownloadButton";
import { GROUP_SYMBOL_SEPARATOR } from "../Views/Common/ImageView";
import AuthImage from "../AuthComponent/AuthImage";
import { ExternalHyperlink, Paragraph, TableCell, TextRun } from "docx";
import { saveTableCellsToDocx } from "../Utils/ExportFile";

export enum ColumnValueType {
    String = 0,
    StringWithCopyBtn,
    Number,
    ImageUrl,
    Url,
    Tuple,
    List,
    Placeholder,
    Download,
}

export enum NumContrastPolicy {
    PositiveRed_NegativeGreen,
    PositiveGreen_NegativeRed,
}

export enum NumberFormat {
    Standard,
    Percentage,
}

export interface TableColumn extends IColumn {
    valueType: ColumnValueType;
    contrastPolicy?: NumContrastPolicy;
    distinctStr?: boolean;
    filterable?: boolean;
    filterName?: string;
    ignoreWhenComparing?: boolean;
    isKey?: boolean;
    numberFormat?: NumberFormat;
    maxDecimalPlaces?: number;
    type?: ColumnValueType;
    supportsDoubleClick?: boolean;
}

type ITableItem<T extends IMetricUnit> = [string, (T | null)[]];

interface FilterConfiguration {
    options: string[];
    sortBy?: TableColumn;
    sortOrder?: boolean;
}

interface IProps<T extends IMetricUnit> {
    evalDataCount: number;
    evalData: IMergedMetrics<T> | Map<string, (T | null)[]>;
    columns: TableColumn[];
    disableFreezeHeader?: boolean;
    disableVirtualize?: boolean;
    disableSorting?: boolean;
    freezeHeight?: number;
    groupSymbolSeparator?: string;
    hideHeader?: boolean;
    showFilterBar?: boolean;
    highlightKey?: string[];
    initialFilterDict?: Map<string | number, any[]>;
    isFullFilterMenu?: boolean;
    isWiderCell?: boolean;
    keepFilterOption?: boolean;
    tableTitle?: string;
    downloadTableTitle?: string;
    queryString?: string;
    workspace?: string;
    isDarkTheme?: boolean;
    verticalFill?: boolean;
    layoutMode?: DetailsListLayoutMode;
    className?: string;
    onFilterOptionChanged?: (
        filterDict: Map<string | number, any[]> | undefined
    ) => void;
    onItemInvoked?: (item: ITableItem<T>, index?: number) => void;
    renderTableHeader?: () => JSX.Element;
    getDisplayEvalData?: (displayItems: ITableItem<T>[]) => void;
}

interface IState<T extends IMetricUnit> {
    columns: TableColumn[];
    displayItems: ITableItem<T>[];
    filterConfig: FilterConfiguration;
    filterDict: Map<string | number, any[]>;
    queryString: string;
    keepFilterOption: boolean;
    groups?: IGroup[];
}

const compareModeDefaultOptions = ["left", "right", "identical", "different"];

const menuIcon: IIconProps = {
    iconName: "Filter",
    style: {
        fontSize: 18,
    },
};

const calloutProps = {
    gapSpace: 0,
    isBeakVisible: false,
    directionalHint: DirectionalHint.bottomAutoEdge,
};
const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block", marginTop: "6px" },
};

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: "scroll",
        width: "100%",
        height: "100%",
        selectors: {
            "& [role=grid]": {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                height: "100%",
                width: "100%",
            },
        },
    },
    headerWrapper: {
        flex: "0 0 auto",
    },
    contentWrapper: {
        flex: "1 1 auto",
        overflowX: "hidden",
    },
};

function formartNumber(
    num: any,
    defaultValue = Sortability.MIN_SAFE_VALUE
): number {
    return num === null || num === undefined || Number.isNaN(num)
        ? defaultValue
        : (num as number);
}

function computeNumericDiff<T>(data: (T | null)[], field: string): number {
    const left = data[0];
    const right = data[1];

    let diff: number = 0;
    if (left === null || left === undefined) {
        diff = Sortability.MIN_SAFE_VALUE;
    } else if (right === null || right === undefined) {
        diff = Sortability.MAX_SAFE_VALUE;
    } else {
        const pre = formartNumber(Number(left[field as keyof T]));
        const cur = formartNumber(Number(right[field as keyof T]));
        diff = pre - cur;
    }
    return diff;
}

const theme = getTheme();
const freezeStyles = mergeStyleSets({
    wrapper: {
        width: "100%",
        height: "100%",
        bottom: 0,
        position: "relative",
        top: 0,
        overflow: "hidden",
        //backgroundColor: "white",
    },
    wrapperModal: {
        width: "100%",
        height: "100%",
        bottom: 0,
        position: "relative",
        top: 0,
        //backgroundColor: "white",
    },
});

export class TableList<IMetric extends IMetricUnit> extends React.Component<
    IProps<IMetric>,
    IState<IMetric>
> {
    private isSelectImageIds: Set<string>;
    constructor(props: IProps<IMetric>) {
        super(props);
        this.isSelectImageIds = new Set();
        this._renderList = this._renderList.bind(this);
        this._onRenderRow = this._onRenderRow?.bind(this);
        this._updateSourceItemsAndFilterRules =
            this._updateSourceItemsAndFilterRules.bind(this);
        const columns = props.columns.map((col) => {
            const newCol = {
                ...col,
                fieldName: col.key,
                onRender: this._onColumnRender,
                isResizable: true,
            };

            if (!!props.disableSorting) {
                return newCol;
            } else {
                return {
                    ...newCol,
                    onColumnClick: this._onColumnClick,
                };
            }
        });

        let groups: IGroup[] | undefined;

        let displayItems = this._parseDataSource(props.evalData);
        if (props.groupSymbolSeparator) {
            [displayItems, groups] = this._sortItemsAndCalcGroups(
                props.groupSymbolSeparator,
                displayItems
            );
        }
        if (this.props.getDisplayEvalData) {
            this.props.getDisplayEvalData(displayItems);
        }
        this.state = {
            columns: columns,
            filterDict:
                props.initialFilterDict ?? new Map<string | number, any[]>(),
            queryString: this.props.queryString ?? "",
            displayItems: displayItems,
            groups: groups,
            keepFilterOption: !!props.keepFilterOption,
            filterConfig: {
                options: compareModeDefaultOptions,
            },
        };
    }

    public render() {
        const {
            columns,
            displayItems,
            filterConfig,
            filterDict,
            keepFilterOption,
            queryString,
        } = this.state;
        const {
            evalData,
            evalDataCount,
            hideHeader,
            showFilterBar,
            tableTitle,
            downloadTableTitle,
            verticalFill = true,
            onFilterOptionChanged,
            renderTableHeader,
        } = this.props;
        const allItems = this._parseDataSource(evalData);
        let readyItems = displayItems;
        if (this.props.queryString === queryString) {
            readyItems = FilterBar.filterItemsByColumnEnumAndQueryString(
                displayItems,
                columns,
                new Map<string | number, any[]>(),
                queryString
            );
        }

        return (
            <Stack verticalFill={verticalFill}>
                {!hideHeader && (
                    <>
                        <Stack
                            className="table-header"
                            horizontal
                            horizontalAlign="space-between"
                            verticalAlign="center"
                        >
                            <Stack horizontal horizontalAlign="start">
                                {tableTitle && <Label>{tableTitle}</Label>}
                                {renderTableHeader && renderTableHeader()}
                                {evalDataCount >= 2 && (
                                    <IconButton
                                        className="table-header__button"
                                        menuProps={this._getContextualMenuProps(
                                            filterConfig.options
                                        )}
                                        menuIconProps={menuIcon}
                                    />
                                )}

                                {onFilterOptionChanged && (
                                    <Toggle
                                        className="filterToggle"
                                        label={"Keep filter options"}
                                        onText="On"
                                        offText="Off"
                                        inlineLabel
                                        checked={keepFilterOption}
                                        onChange={(_event, checked) => {
                                            if (checked !== undefined) {
                                                const {
                                                    filterDict,
                                                    queryString,
                                                } = this.state;
                                                filterDict.clear();
                                                const toDisplayItems =
                                                    FilterBar.filterItemsByColumnEnumAndQueryString(
                                                        allItems,
                                                        columns,
                                                        filterDict,
                                                        queryString
                                                    );
                                                this.setState(
                                                    {
                                                        keepFilterOption:
                                                            checked,
                                                        filterDict: filterDict,
                                                        displayItems:
                                                            toDisplayItems,
                                                    },
                                                    () =>
                                                        !checked &&
                                                        onFilterOptionChanged &&
                                                        onFilterOptionChanged(
                                                            undefined
                                                        )
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack>
                            <Stack>
                                {this._renderDownloadTableButton(
                                    columns,
                                    readyItems,
                                    downloadTableTitle ?? "Table"
                                )}
                            </Stack>
                        </Stack>
                        <Stack>
                            <span
                                style={{
                                    color: this.props.isDarkTheme
                                        ? theme.palette.white
                                        : theme.palette.black,
                                }}
                            >
                                Total Count: {readyItems.length}
                            </span>
                        </Stack>
                        <Stack>
                            <FilterBar
                                columns={columns}
                                allItems={allItems}
                                filterDict={filterDict}
                                queryString={queryString}
                                updateDisplayItemsAndFilterDict={
                                    this._updateSourceItemsAndFilterRules
                                }
                                isDarkTheme={this.props.isDarkTheme}
                            ></FilterBar>
                        </Stack>
                    </>
                )}

                {hideHeader && (
                    <Stack horizontal horizontalAlign="end">
                        {this._renderDownloadTableButton(
                            columns,
                            readyItems,
                            downloadTableTitle ?? "Table"
                        )}
                    </Stack>
                )}
                {hideHeader && showFilterBar && (
                    <Stack>
                        <FilterBar
                            columns={columns}
                            allItems={allItems}
                            filterDict={filterDict}
                            queryString={queryString}
                            hiddenSearch={showFilterBar}
                            updateDisplayItemsAndFilterDict={
                                this._updateSourceItemsAndFilterRules
                            }
                            isDarkTheme={this.props.isDarkTheme}
                        ></FilterBar>
                    </Stack>
                )}

                {readyItems.length !== 0 ? (
                    this._renderList(readyItems)
                ) : (
                    <NoDataTip>No Data Generated</NoDataTip>
                )}
            </Stack>
        );
    }
    public componentWillUpdate(
        nextProps: IProps<IMetric>,
        nextState: IState<IMetric>
    ): void {
        if (nextState.displayItems !== this.state.displayItems) {
            if (this.props.getDisplayEvalData) {
                this.props.getDisplayEvalData(nextState.displayItems);
            }
        }
    }
    public componentDidUpdate(prevProps: IProps<IMetric>) {
        const {
            columns,
            disableSorting = false,
            evalData,
            initialFilterDict = new Map<string | number, any[]>(),
            queryString = "",
        } = this.props;

        if (!_.isEqual(columns, prevProps.columns)) {
            const newColumns = columns.map((col) => {
                const newCol = {
                    ...col,
                    fieldName: col.key,
                    onRender: this._onColumnRender,
                    isResizable: true,
                };

                if (disableSorting) {
                    return newCol;
                } else {
                    return {
                        ...newCol,
                        onColumnClick: this._onColumnClick,
                    };
                }
            });
            this.setState({
                columns: newColumns,
            });
        }

        if (!_.isEqual(evalData, prevProps.evalData)) {
            this.setState({ filterDict: initialFilterDict }, () => {
                this._getDisplayItems(
                    this.state.filterConfig,
                    queryString
                ).then(([items, groups]) =>
                    this.setState({
                        queryString: queryString,
                        displayItems: items,
                        groups: groups,
                    })
                );
            });
        }

        if (!_.isEqual(this.props.isDarkTheme, prevProps.isDarkTheme)) {
            this._renderList(this.state.displayItems);
        }
    }

    private _generateNumericTableCellInDiffMode(
        data: IMetric[],
        fieldName: string,
        contrastPolicy: NumContrastPolicy = NumContrastPolicy.PositiveRed_NegativeGreen,
        maxDecimalPlaces: number | undefined = 1,
        numberFormat: NumberFormat | undefined = NumberFormat.Standard
    ): TableCell {
        const left = data[0];
        const right = data[1];

        const val1 = left ? Number(left[fieldName as keyof IMetric]) : NaN;
        const val2 = right ? Number(right[fieldName as keyof IMetric]) : NaN;
        const diff = Number(val1) - Number(val2);

        const formatVal1 = this._parseNumberToString(
            Number(val1),
            numberFormat,
            maxDecimalPlaces
        );

        const formatVal2 = this._parseNumberToString(
            Number(val2),
            numberFormat,
            maxDecimalPlaces
        );

        const formatDiff = this._parseNumberToString(
            Number(diff),
            numberFormat,
            maxDecimalPlaces
        );

        if (isNaN(diff) || diff === 0) {
            return new TableCell({
                children: [
                    new Paragraph(`${formatVal1}`),
                    new Paragraph(`/`),
                    new Paragraph(`${formatVal2} -`),
                ],
            });
        } else if (diff > 0) {
            return new TableCell({
                children: [
                    new Paragraph(`${formatVal1}`),
                    new Paragraph(`/`),
                    new Paragraph({
                        children: [
                            new TextRun(`${formatVal2}`),
                            new TextRun({
                                text: `+${formatDiff}`,
                                color:
                                    contrastPolicy ===
                                    NumContrastPolicy.PositiveRed_NegativeGreen
                                        ? RGB_Color.Red
                                        : RGB_Color.Green,
                            }),
                        ],
                    }),
                ],
            });
        } else {
            return new TableCell({
                children: [
                    new Paragraph(`${formatVal1}`),
                    new Paragraph(`/`),
                    new Paragraph({
                        children: [
                            new TextRun(`${formatVal2}`),
                            new TextRun({
                                text: `${formatDiff}`,
                                color:
                                    contrastPolicy ===
                                    NumContrastPolicy.PositiveRed_NegativeGreen
                                        ? RGB_Color.Green
                                        : RGB_Color.Red,
                            }),
                        ],
                    }),
                ],
            });
        }
    }

    private _generateNumericTableCellInNormalMode(
        data: IMetric[],
        fieldName: string,
        maxDecimalPlaces: number | undefined = 1,
        numberFormat: NumberFormat | undefined = NumberFormat.Standard
    ): TableCell {
        const values = data.map((d) => {
            return d ? d[fieldName as keyof IMetric] : NaN;
        }) as number[];
        const min = values.length === 1 ? NaN : Math.min(...values);
        const max = values.length === 1 ? NaN : Math.max(...values);

        return new TableCell({
            children: values.map((value, index) => {
                let color = RGB_Color.Default;
                if (value === min) {
                    color = RGB_Color.Green;
                } else if (value === max) {
                    color = RGB_Color.Red;
                }
                const numberText = isNaN(value)
                    ? "NaN"
                    : this._parseNumberToString(
                          value,
                          numberFormat,
                          maxDecimalPlaces
                      );
                return new Paragraph({
                    children: [new TextRun({ text: numberText, color: color })],
                });
            }),
        });
    }

    private async _downloadTableAsDocx(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        columns: TableColumn[],
        displayItems: ITableItem<IMetric>[],
        title: string
    ) {
        const defaultDecimalPlaces = 1;
        const visibleColumns = columns.filter(
            (col) => col.valueType !== ColumnValueType.Placeholder
        );
        const tableHeader = visibleColumns.map(
            (col) =>
                new TableCell({
                    children: [new Paragraph(col.name)],
                })
        );
        const tableBody = displayItems.map((item) =>
            visibleColumns.map((col) => {
                let tableCell: TableCell = new TableCell({
                    children: [new Paragraph("")],
                });
                if (!!col.isKey) {
                    tableCell = new TableCell({
                        children: [
                            new Paragraph(
                                item[0].split(COLUMN_KEY_SEPARATOR)[0]
                            ),
                        ],
                    });
                } else if (col.fieldName) {
                    const metrics = item[1] as IMetric[];
                    if (col.valueType === ColumnValueType.Number) {
                        const maxDecimalPlaces =
                            col.maxDecimalPlaces ?? defaultDecimalPlaces;

                        tableCell =
                            this.props.evalDataCount === 2
                                ? this._generateNumericTableCellInDiffMode(
                                      metrics,
                                      col.fieldName,
                                      col.contrastPolicy,
                                      maxDecimalPlaces,
                                      col.numberFormat
                                  )
                                : this._generateNumericTableCellInNormalMode(
                                      metrics,
                                      col.fieldName,
                                      maxDecimalPlaces,
                                      col.numberFormat
                                  );
                    } else {
                        const itemValues = metrics.map((metric) =>
                            String(
                                metric
                                    ? metric[col.fieldName as keyof IMetric]
                                    : ""
                            )
                        );

                        if (
                            [
                                ColumnValueType.Url,
                                ColumnValueType.ImageUrl,
                                ColumnValueType.Download,
                            ].includes(col.valueType)
                        ) {
                            tableCell = new TableCell({
                                children: itemValues.map((item) =>
                                    item && item !== "undefined"
                                        ? new Paragraph({
                                              children: [
                                                  new ExternalHyperlink({
                                                      link: item
                                                          .toLocaleLowerCase()
                                                          .startsWith("http")
                                                          ? item
                                                          : window.location
                                                                .origin + item,
                                                      children: [
                                                          new TextRun({
                                                              text: "LINK",
                                                              style: "Hyperlink",
                                                          }),
                                                      ],
                                                  }),
                                              ],
                                          })
                                        : new Paragraph("N/A")
                                ),
                            });
                        } else {
                            tableCell = !!col.distinctStr
                                ? (tableCell = new TableCell({
                                      children: [...new Set(itemValues)].map(
                                          (item) => new Paragraph(item)
                                      ),
                                  }))
                                : new TableCell({
                                      children: itemValues.map(
                                          (item) => new Paragraph(item)
                                      ),
                                  });
                        }
                    }
                }

                return tableCell;
            })
        );

        tableBody.unshift(tableHeader);
        await saveTableCellsToDocx(tableBody, title);
    }

    private async _getDisplayItems(
        config: FilterConfiguration,
        queryString: string
    ): Promise<[ITableItem<IMetric>[], IGroup[] | undefined]> {
        const { evalData, groupSymbolSeparator } = this.props;
        const { columns, filterDict } = this.state;
        const { sortBy, sortOrder: isSortedDescending, options } = config;

        // filter items
        let toDisplayItems = this._parseDataSource(evalData);
        if ((filterDict && filterDict.size > 0) || queryString) {
            toDisplayItems = FilterBar.filterItemsByColumnEnumAndQueryString(
                toDisplayItems,
                columns,
                filterDict,
                queryString
            );
        }

        // apply filter options on displayItems
        toDisplayItems = this._applyFilterOptions(options, toDisplayItems);

        // sort items
        let groups: IGroup[] | undefined;
        [toDisplayItems, groups] = this._sortDisplayItems(
            sortBy,
            isSortedDescending,
            toDisplayItems,
            groupSymbolSeparator
        );
        return [toDisplayItems, groups];
    }

    private _applyFilterOptions(
        options: string[],
        displayItems: ITableItem<IMetric>[]
    ): ITableItem<IMetric>[] {
        const dataOptions = options.filter(
            (opt) => !compareModeDefaultOptions.includes(opt)
        );
        const includeLeftOnly = options.includes("left");
        const includeRightOnly = options.includes("right");
        const includeIdentical = options.includes("identical");
        const includeDifferent = options.includes("different");

        const hasDiffOption =
            !includeLeftOnly ||
            !includeRightOnly ||
            !includeIdentical ||
            !includeDifferent;

        if (hasDiffOption || dataOptions.length > 0) {
            displayItems = (displayItems as ITableItem<IMetric>[]).filter(
                (item) => {
                    const values = item[1];
                    if (hasDiffOption) {
                        if (
                            !includeLeftOnly &&
                            values[0] !== null &&
                            values[1] === null
                        ) {
                            return false;
                        }
                        if (
                            !includeRightOnly &&
                            values[0] === null &&
                            values[1] !== null
                        ) {
                            return false;
                        }
                        if (!includeIdentical || !includeDifferent) {
                            const { columns } = this.state;
                            const comparisonColumns = columns.filter(
                                (col) =>
                                    col.valueType !== ColumnValueType.Url &&
                                    !!!col.ignoreWhenComparing
                            );

                            const areSame =
                                comparisonColumns.length > 0
                                    ? values.every((value) => {
                                          return comparisonColumns.every(
                                              (col) => {
                                                  const fileName =
                                                      col.fieldName!;
                                                  return _.isEqual(
                                                      values[0]
                                                          ? (values[0] as any)[
                                                                fileName
                                                            ]
                                                          : null,
                                                      value
                                                          ? (value as any)[
                                                                fileName
                                                            ]
                                                          : null
                                                  );
                                              }
                                          );
                                      })
                                    : values.every((value) => {
                                          return _.isEqual(values[0], value);
                                      });

                            if (areSame && !includeIdentical) {
                                return false;
                            }

                            if (!areSame && !includeDifferent) {
                                return false;
                            }
                        }
                    }

                    for (const key of dataOptions) {
                        if (
                            values.every(
                                (value) =>
                                    value === null ||
                                    !value[key as keyof IMetric]
                            )
                        ) {
                            return false;
                        }
                    }
                    return true;
                }
            );
        }
        return displayItems;
    }

    private _sortDisplayItems(
        sortBy: TableColumn | undefined,
        isSortedDescending: boolean | undefined,
        displayItems: ITableItem<IMetric>[],
        groupSymbolSeparator?: string
    ): [ITableItem<IMetric>[], IGroup[] | undefined] {
        let groups: IGroup[] | undefined;
        if (sortBy) {
            if (sortBy.isKey) {
                // sort by key
                if (groupSymbolSeparator) {
                    [displayItems, groups] = this._sortItemsAndCalcGroups(
                        groupSymbolSeparator,
                        displayItems,
                        isSortedDescending,
                        sortBy,
                        this._sortItemsByKey
                    );
                } else {
                    displayItems = this._sortItemsByKey(
                        displayItems,
                        isSortedDescending,
                        sortBy
                    );
                }
            } else {
                if (
                    this.props.evalDataCount === 2 &&
                    sortBy.valueType === ColumnValueType.Number
                ) {
                    // sort by diff number when there are 2 records
                    if (groupSymbolSeparator) {
                        [displayItems, groups] = this._sortItemsAndCalcGroups(
                            groupSymbolSeparator,
                            displayItems,
                            isSortedDescending,
                            sortBy,
                            this._sortItemsByDiffNumWhenCompare2Records
                        );
                    } else {
                        displayItems =
                            this._sortItemsByDiffNumWhenCompare2Records(
                                displayItems,
                                isSortedDescending,
                                sortBy
                            );
                    }
                } else {
                    // sort by 1st record's value when there are 1 or 2+ records
                    if (groupSymbolSeparator) {
                        [displayItems, groups] = this._sortItemsAndCalcGroups(
                            groupSymbolSeparator,
                            displayItems,
                            isSortedDescending,
                            sortBy,
                            this._sortItems
                        );
                    } else {
                        displayItems = this._sortItems(
                            displayItems,
                            isSortedDescending,
                            sortBy
                        );
                    }
                }
            }
        }

        if (
            store.getState().globalReducer.workSpace === Workspaces.OcrBarcode
        ) {
            const overallItem = displayItems.filter((item) =>
                item[1].some((v: any) => v["category"] === "overall")
            );
            const OtherItem = displayItems.filter((item) =>
                item[1].some((v: any) => v["category"] !== "overall")
            );
            displayItems = [...OtherItem, ...overallItem];
        }

        return [displayItems, groups];
    }

    private _sortItemsAndCalcGroups(
        groupSymbolSeparator: string,
        displayItems: ITableItem<IMetric>[],
        isSortedDescending?: boolean,
        sortBy?: TableColumn,
        compareFn?: (
            toSortItems: ITableItem<IMetric>[],
            isSortedDescending: boolean | undefined,
            sortBy: TableColumn
        ) => ITableItem<IMetric>[]
    ): [ITableItem<IMetric>[], IGroup[]] {
        const groupCountMap = new Map<string, number>();
        displayItems.forEach((item) => {
            const groupKey = item[0].split(groupSymbolSeparator)[0];
            let count = groupCountMap.get(groupKey);
            if (count) {
                count += 1;
            } else {
                count = 1;
            }
            groupCountMap.set(groupKey, count);
        });

        let groupStartIndex = 0;
        const groupArr: IGroup[] = [];
        const sortedItems: ITableItem<IMetric>[] = [];
        const toSortItems = _.cloneDeep(displayItems);
        groupCountMap.forEach((count, groupKey) => {
            let groupItems = toSortItems.splice(0, count);

            if (sortBy && compareFn) {
                groupItems = compareFn(groupItems, isSortedDescending, sortBy);
            }
            sortedItems.push(...groupItems);

            const group: IGroup = {
                key: groupKey,
                name: groupKey,
                startIndex: groupStartIndex,
                count: count,
            };
            groupArr.push(group);

            groupStartIndex += count;
        });

        return [sortedItems, groupArr];
    }

    private _sortItemsByKey(
        toSortItems: ITableItem<IMetric>[],
        isSortedDescending: boolean | undefined,
        _: TableColumn
    ): ITableItem<IMetric>[] {
        const keySelector = (item: ITableItem<IMetric>) => item[0];

        const itemsIEnumerable = from(toSortItems);
        const sortedIEnumerable = !!isSortedDescending
            ? itemsIEnumerable.orderByDescending(keySelector)
            : itemsIEnumerable.orderBy(keySelector);

        return sortedIEnumerable.toArray();
    }
    private iComparer = (pre: any, cur: any) => {
        return (pre as number) - (cur as number);
    };

    private _sortItemsByDiffNumWhenCompare2Records(
        toSortItems: ITableItem<IMetric>[],
        isSortedDescending: boolean | undefined,
        sortBy: TableColumn
    ): ITableItem<IMetric>[] {
        const keySelector = (item: ITableItem<IMetric>) => {
            const defItemArr = item[1];
            const diff = computeNumericDiff<IMetric>(defItemArr, sortBy.key);
            return diff;
        };

        const itemsIEnumerable = from(toSortItems);
        const sortedIEnumerable = !!isSortedDescending
            ? itemsIEnumerable.orderByDescending(keySelector, this.iComparer)
            : itemsIEnumerable.orderBy(keySelector, this.iComparer);

        return sortedIEnumerable.toArray();
    }

    private _sortItems(
        toSortItems: ITableItem<IMetric>[],
        isSortedDescending: boolean | undefined,
        sortBy: TableColumn
    ): ITableItem<IMetric>[] {
        const keySelector = (item: ITableItem<IMetric>) => {
            const defItem = item[1][0];
            const orderValue =
                defItem !== null &&
                defItem !== undefined &&
                sortBy.key in defItem
                    ? defItem[sortBy.key as keyof IMetric]
                    : null;

            if (sortBy.valueType === ColumnValueType.Number) {
                if (orderValue === null) {
                    return Sortability.MIN_SAFE_VALUE;
                } else if (Number.isNaN(orderValue)) {
                    return Sortability.MIN_SAFE_VALUE + 1;
                }
            }

            return orderValue;
        };

        const itemsIEnumerable = from(toSortItems);
        if (sortBy.valueType === ColumnValueType.Number) {
            const sortedIEnumerable = !!isSortedDescending
                ? itemsIEnumerable.orderByDescending(
                      keySelector,
                      this.iComparer
                  )
                : itemsIEnumerable.orderBy(keySelector, this.iComparer);

            return sortedIEnumerable.toArray();
        } else {
            const sortedIEnumerable = !!isSortedDescending
                ? itemsIEnumerable.orderByDescending(keySelector)
                : itemsIEnumerable.orderBy(keySelector);

            return sortedIEnumerable.toArray();
        }
    }

    private _getContextualMenuProps(selection: string[]): IContextualMenuProps {
        const { columns, evalDataCount, isFullFilterMenu } = this.props;
        let options: IContextualMenuItem[] = columns
            .filter((col) => !!col.filterName)
            .map((col) => {
                return {
                    key: col.key,
                    text: col.filterName,
                    canCheck: true,
                };
            });

        if (evalDataCount === 2 && !!isFullFilterMenu) {
            options.push(
                {
                    key: "divider_1",
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: "left",
                    text: "Show Left-Only lines",
                    canCheck: true,
                },
                {
                    key: "right",
                    text: "Show Right-Only lines",
                    title: "imageMode",
                    canCheck: true,
                }
            );
        }

        if (this.props.evalDataCount >= 2) {
            options.push(
                {
                    key: "divider_2",
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: "identical",
                    text: "Show Identical lines",
                    canCheck: true,
                },
                {
                    key: "different",
                    text: "Show Different lines",
                    canCheck: true,
                }
            );
        }

        for (let item of options) {
            item.isChecked = selection.includes(item.key);
        }

        return {
            items: options,
            shouldFocusOnMount: true,
            onItemClick: this._onRenderFilterItem,
        };
    }

    private _onColumnRender = (
        item?: any,
        _index?: number | undefined,
        column?: IColumn | undefined
    ): any => {
        const { isWiderCell } = this.props;
        const [key, value] = item;
        const metricColumn = column as TableColumn;
        let cellClass = this.props.highlightKey?.includes(column?.key || "")
            ? "text_highlight"
            : "table__item";
        if (!!isWiderCell) {
            cellClass += " widerCell";
        }
        if (!!metricColumn.isKey) {
            const k = key.split(COLUMN_KEY_SEPARATOR)[0];
            return (
                <div className="itemkey">
                    <span className="itemkey__text">{k}</span>
                    <CopyToClipboard text={k}>
                        <IconButton
                            className="itemkey__copy"
                            iconProps={{ iconName: "Copy" }}
                            title="copy"
                            ariaLabel="copy"
                        />
                    </CopyToClipboard>
                </div>
            );
        } else {
            switch (metricColumn.valueType) {
                case ColumnValueType.Number:
                    return this._renderNumericColumn(
                        value as IMetric[],
                        metricColumn,
                        cellClass
                    );
                case ColumnValueType.ImageUrl:
                    return this._renderImageColumn(
                        value as IMetric[],
                        metricColumn.fieldName!
                    );
                case ColumnValueType.Url:
                    return this._renderUrlColumn(
                        value as IMetric[],
                        metricColumn.fieldName!
                    );
                case ColumnValueType.Placeholder:
                    return <></>;
                case ColumnValueType.StringWithCopyBtn:
                    return this._renderStringColumn(
                        value as IMetric[],
                        metricColumn.fieldName!,
                        metricColumn.distinctStr,
                        true
                    );
                case ColumnValueType.Download:
                    return this._renderDownloadColumn(
                        value as IMetric[],
                        metricColumn.fieldName!
                    );
                case ColumnValueType.String:
                default:
                    return this._renderStringColumn(
                        value as IMetric[],
                        metricColumn.fieldName!,
                        metricColumn.distinctStr
                    );
            }
        }
    };

    private _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        const { columns, filterConfig, queryString } = this.state;

        const newColumns = columns.slice();
        const currColumn = newColumns.filter(
            (currCol) => column.key === currCol.key
        )[0];
        newColumns.forEach((newCol) => {
            if (newCol === currColumn && newCol.key !== "ocrToyUrl") {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = !column.isSortedDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const config = {
            ...filterConfig,
            sortBy: column as TableColumn,
            sortOrder: !column.isSortedDescending,
        };

        this._getDisplayItems(config, queryString).then(([items, groups]) => {
            this.setState({
                columns: newColumns,
                filterConfig: config,
                displayItems: items,
                groups: groups,
            });
        });
    };

    private _onRenderFilterItem = (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        menuItem?: IContextualMenuItem
    ): void => {
        ev!.preventDefault();

        if (menuItem) {
            const { filterConfig, queryString } = this.state;
            let newOptions: string[] = [];
            if (filterConfig.options) {
                newOptions = filterConfig.options.slice();
            }

            if (newOptions.includes(menuItem.key)) {
                newOptions = newOptions.filter((opt) => opt !== menuItem.key);
            } else {
                newOptions.push(menuItem.key);
            }

            const config = {
                ...filterConfig,
                options: newOptions,
            };

            this.setState({ filterConfig: config });

            this._getDisplayItems(config, queryString).then(
                ([items, groups]) => {
                    if (config === this.state.filterConfig) {
                        this.setState({ displayItems: items, groups: groups });
                    }
                }
            );
        }
    };

    private _onPaneScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        const viewPort = e.target as HTMLElement;
        if (
            viewPort.parentNode &&
            viewPort.parentNode.previousSibling &&
            viewPort.scrollLeft !== null &&
            viewPort.scrollLeft !== undefined
        ) {
            const tableHeader = viewPort.parentNode
                .previousSibling as HTMLElement;
            tableHeader.style.left = `${0 - viewPort.scrollLeft}px`;
        }
    };

    private _renderNumericColumn(
        data: IMetric[] | null,
        column: TableColumn,
        cellClass: string = ""
    ): any {
        if (!data) {
            return;
        }
        if (this.props.evalDataCount === 2) {
            return this._renderNumericColumnInDiffMode(
                data,
                column.fieldName!,
                column.contrastPolicy,
                column.maxDecimalPlaces,
                column.numberFormat,
                cellClass
            );
        } else {
            return this._renderNumericColumnInNormalMode(
                data,
                column.fieldName!,
                column.maxDecimalPlaces,
                column.numberFormat,
                cellClass
            );
        }
    }

    private _renderNumericColumnInNormalMode(
        data: IMetric[],
        fieldName: string,
        maxDecimalPlaces: number | undefined = 1,
        numberFormat: NumberFormat | undefined = NumberFormat.Standard,
        cellClass: string = ""
    ): any {
        const values = data.map((d) => {
            return d ? d[fieldName as keyof IMetric] : NaN;
        }) as number[];
        const min = values.length === 1 ? NaN : Math.min(...values);
        const max = values.length === 1 ? NaN : Math.max(...values);
        return (
            <Stack tokens={{ childrenGap: 10 }}>
                {values.map((value, index) => {
                    let color = this.props.isDarkTheme ? "white" : "grey";
                    if (value === min) {
                        color = "green";
                    } else if (value === max) {
                        color = "red";
                    }
                    return (
                        <span
                            key={`value_${index}`}
                            style={{ color: `${color}` }}
                            className={cellClass}
                        >
                            {isNaN(value)
                                ? "NaN"
                                : this._parseNumberToString(
                                      value,
                                      numberFormat,
                                      maxDecimalPlaces
                                  )}
                        </span>
                    );
                })}
            </Stack>
        );
    }

    private _renderNumericColumnInDiffMode(
        data: IMetric[],
        fieldName: string,
        contrastPolicy: NumContrastPolicy = NumContrastPolicy.PositiveRed_NegativeGreen,
        maxDecimalPlaces: number | undefined = 1,
        numberFormat: NumberFormat | undefined = NumberFormat.Standard,
        cellClass: string = ""
    ): any {
        const left = data[0];
        const right = data[1];

        const val1 = left ? Number(left[fieldName as keyof IMetric]) : NaN;
        const val2 = right ? Number(right[fieldName as keyof IMetric]) : NaN;
        const diff = Number(val1) - Number(val2);

        const formatVal1 = this._parseNumberToString(
            Number(val1),
            numberFormat,
            maxDecimalPlaces
        );

        const formatVal2 = this._parseNumberToString(
            Number(val2),
            numberFormat,
            maxDecimalPlaces
        );

        const formatDiff = this._parseNumberToString(
            Number(diff),
            numberFormat,
            maxDecimalPlaces
        );

        if (isNaN(diff) || diff === 0) {
            return (
                <>
                    <span className={cellClass}>
                        {formatVal1} / {formatVal2}
                    </span>
                    <span>-</span>
                </>
            );
        } else if (diff > 0) {
            return (
                <>
                    <span className={cellClass}>
                        {formatVal1} / {formatVal2}
                    </span>
                    <b>
                        <span
                            className={cellClass}
                            style={{
                                color:
                                    contrastPolicy ===
                                    NumContrastPolicy.PositiveRed_NegativeGreen
                                        ? "red"
                                        : "green",
                            }}
                        >
                            +{formatDiff}
                        </span>
                    </b>
                </>
            );
        } else {
            return (
                <>
                    <span className={cellClass}>
                        {formatVal1} / {formatVal2}
                    </span>
                    <b>
                        <span
                            className={cellClass}
                            style={{
                                color:
                                    contrastPolicy ===
                                    NumContrastPolicy.PositiveRed_NegativeGreen
                                        ? "green"
                                        : "red",
                            }}
                        >
                            {formatDiff}
                        </span>
                    </b>
                </>
            );
        }
    }

    private _renderImageColumn(data: IMetric[], fieldName: string): any {
        const url = (data as any[]).find((d) => d)![fieldName as keyof IMetric];

        return (
            <Link
                href={url}
                target="_blank"
                styles={{
                    root: {
                        color: "#75b6e7",
                    },
                }}
            >
                <AuthImage url={url} height={"30px"} />
            </Link>
        );
    }

    private _renderUrlColumn(data: IMetric[], fieldName: string): any {
        let urls = data.map((d) => {
            return d && d[fieldName as keyof IMetric]
                ? String(d[fieldName as keyof IMetric])
                : undefined;
        });
        return (
            <Stack
                style={{ padding: "6px 10px 0 0" }}
                tokens={{ childrenGap: 10 }}
            >
                {urls.map((url, index) => {
                    let imgId: any;
                    if (url) {
                        const parts = url.split("/");

                        imgId = parts[parts.length - 1];
                    } else {
                        imgId = 0;
                    }
                    return url ? (
                        <Link
                            key={`url_${index}`}
                            onClick={() => this._onImgeItemClick(imgId)}
                            href={url}
                            target="_blank"
                            className="link_item"
                            styles={{
                                root: {
                                    color: "#75b6e7",
                                },
                            }}
                        >
                            LINK
                        </Link>
                    ) : (
                        <div>N/A</div>
                    );
                })}
            </Stack>
        );
    }

    private _renderDownloadColumn(data: IMetric[], fieldName: string): any {
        let urls = data.map((d) => {
            return d && d[fieldName as keyof IMetric]
                ? String(d[fieldName as keyof IMetric])
                : undefined;
        });

        return (
            <Stack
                style={{ padding: "6px 10px 0 0" }}
                tokens={{ childrenGap: 10 }}
            >
                {urls.map((url, index) => {
                    return (
                        <DownloadButton
                            key={index}
                            downloadUrl={url}
                        ></DownloadButton>
                    );
                })}
            </Stack>
        );
    }

    private _renderStringColumn(
        data: IMetric[] | null,
        fieldName: string,
        distinctStr: boolean = false,
        showCopyBtn: boolean = false
    ): any {
        if (!data) {
            return;
        }

        let values = data.map((d) => {
            return String(d ? d[fieldName as keyof IMetric] : "");
        });
        if (distinctStr) {
            values = [...new Set(values)];
        }

        return !!showCopyBtn
            ? this._renderWithTooltipAndCopyBtn(values)
            : this._renderWithTooltip(values);
    }

    private _renderWithTooltip(values: string[]) {
        return (
            <TooltipHost
                content={
                    <div>
                        {values.map((value, index) => {
                            return <span key={`value_${index}`}>{value}</span>;
                        })}
                    </div>
                }
                calloutProps={calloutProps}
                styles={hostStyles}
            >
                <Stack
                    className="strValBox"
                    tokens={{ childrenGap: 10 }}
                    horizontal
                >
                    {values.map((value, index) => {
                        return (
                            <span
                                className="stringNormal"
                                key={`value_${index}`}
                            >
                                {value}
                            </span>
                        );
                    })}
                </Stack>
            </TooltipHost>
        );
    }

    private _renderWithTooltipAndCopyBtn(values: string[]) {
        return (
            <Stack
                className="strValBox"
                tokens={{ childrenGap: 10 }}
                horizontal
            >
                {values.map((value, index) => {
                    const disableCopyBtn = value ? false : true;
                    return (
                        <Stack horizontal verticalAlign="center">
                            <CopyToClipboard text={String(value)}>
                                <IconButton
                                    disabled={disableCopyBtn}
                                    className="itemId__copy"
                                    iconProps={{ iconName: "Copy" }}
                                    title={disableCopyBtn ? undefined : "copy"}
                                    ariaLabel={
                                        disableCopyBtn ? undefined : "copy"
                                    }
                                />
                            </CopyToClipboard>
                            <TooltipHost
                                content={value}
                                calloutProps={calloutProps}
                                styles={hostStyles}
                            >
                                <span
                                    key={`value_${index}`}
                                    className="stringNowrap"
                                >
                                    {value}
                                </span>
                            </TooltipHost>
                        </Stack>
                    );
                })}
            </Stack>
        );
    }

    private _renderDownloadTableButton(
        columns: TableColumn[],
        displayItems: ITableItem<IMetric>[],
        title: string
    ) {
        return displayItems.length > 0 ? (
            <IconButton
                iconProps={{
                    iconName: "WordLogoInverse16",
                    style: { color: "#0078d4" },
                }}
                title="Download table as docx"
                ariaLabel="Download table as docx"
                onClick={async (
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                    await this._downloadTableAsDocx(
                        event,
                        columns,
                        displayItems,
                        title
                    );
                }}
            ></IconButton>
        ) : (
            <></>
        );
    }

    private _renderList(readyItems: ITableItem<IMetric>[]) {
        const {
            disableFreezeHeader = false,
            disableVirtualize,
            freezeHeight,
            layoutMode = DetailsListLayoutMode.justified,
            className = freezeStyles.wrapper,
        } = this.props;
        const { columns, groups } = this.state;

        if (disableFreezeHeader) {
            return (
                <Stack verticalFill className={className}>
                    <Consumer>
                        {(value) => {
                            return (
                                <DetailsList
                                    key={String(value)}
                                    className="table-list"
                                    items={readyItems}
                                    columns={columns}
                                    groups={groups}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={layoutMode}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderRow={(props) =>
                                        this._onRenderRow(props, "", value)
                                    }
                                    onItemInvoked={(
                                        item: ITableItem<IMetric>,
                                        index
                                    ) => {
                                        const imgId = item[0];

                                        if (this.props.onItemInvoked) {
                                            this._onImgeItemClick(imgId);
                                            this.props.onItemInvoked(
                                                item,
                                                index
                                            );
                                        }
                                    }}
                                    onShouldVirtualize={
                                        disableVirtualize === undefined
                                            ? undefined
                                            : () => !disableVirtualize
                                    }
                                />
                            );
                        }}
                    </Consumer>
                </Stack>
            );
        } else {
            const freezeStyles = mergeStyleSets({
                wrapper: {
                    width: "100%",
                    height: `${freezeHeight ? freezeHeight + "px" : "100%"}`,
                    bottom: 0,
                    position: "relative",
                    top: 0,
                    backgroundColor: "transparent",
                    overflow: "hidden",
                },
            });

            return (
                <Stack verticalFill className={freezeStyles.wrapper}>
                    <Consumer>
                        {(value) => {
                            return (
                                <DetailsList
                                    styles={gridStyles}
                                    key={String(value)}
                                    className="table-list"
                                    items={readyItems}
                                    columns={columns}
                                    groups={groups}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={layoutMode}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={
                                        DetailsList_onRenderDetailsHeader_FreezeHeader
                                    }
                                    onRenderRow={(props) =>
                                        this._onRenderRow(props, "", value)
                                    }
                                    onItemInvoked={(
                                        item: ITableItem<IMetric>,
                                        index,
                                        ev
                                    ) => {
                                        const imageId = item[0];

                                        if (this.props.onItemInvoked) {
                                            this._onImgeItemClick(imageId);
                                            this.props.onItemInvoked(
                                                item,
                                                index
                                            );
                                        }
                                    }}
                                    onShouldVirtualize={
                                        disableVirtualize === undefined
                                            ? undefined
                                            : () => !disableVirtualize
                                    }
                                />
                            );
                        }}
                    </Consumer>
                </Stack>
            );
        }
    }

    private _parseDataSource(
        srcData: IMergedMetrics<IMetric> | Map<string, (IMetric | null)[]>
    ): ITableItem<IMetric>[] {
        return srcData instanceof Map
            ? Array.from(srcData)
            : Object.entries(srcData);
    }

    private _parseNumberToString(
        num: number,
        numFormat: NumberFormat,
        decimalPlaces: number | undefined = 1
    ): string {
        if (typeof num !== "number") {
            //Robustness against customer data
            num = Number.parseFloat(num);
        }

        if (Number.isNaN(num)) {
            return "NaN";
        }

        if (numFormat === NumberFormat.Percentage) {
            return optimizeDecimalPlaces(num * 100, decimalPlaces).toString();
        } else {
            return decimalPlaces !== undefined
                ? optimizeDecimalPlaces(num, decimalPlaces).toString()
                : num.toString();
        }
    }

    private _updateSourceItemsAndFilterRules(
        items: any[],
        filterDict: Map<string | number, any[]>,
        queryString: string
    ): void {
        const { groupSymbolSeparator, onFilterOptionChanged } = this.props;
        const { filterConfig, keepFilterOption } = this.state;
        const { sortBy, sortOrder } = filterConfig;

        const [toDisplayItems, groups] = this._sortDisplayItems(
            sortBy,
            sortOrder,
            items,
            groupSymbolSeparator
        );

        this.setState(
            {
                filterDict: filterDict,
                queryString: queryString,
                displayItems: toDisplayItems,
                groups: groups,
            },
            () => {
                keepFilterOption &&
                    onFilterOptionChanged &&
                    onFilterOptionChanged(filterDict);
            }
        );
    }

    private _onRenderRow = (props: any, defaultRender: any, value: any) => {
        let customStyles: Partial<IDetailsRowStyles> = {};
        customStyles.root = {
            "&:focus": {
                ".ms-DetailsRow-check": {
                    opacity: "none",
                },
            },
        };
        if (props) {
            const imageId = props.item[0];

            const isSelect = this.isSelectImageIds.has(this.getKey(imageId));
            const selectColor = value ? "grey" : "#dad6d6";

            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                const normalColor = value
                    ? theme.palette.neutralDark
                    : theme.palette.neutralLighterAlt;
                customStyles.root = {
                    backgroundColor: isSelect ? selectColor : normalColor,
                };
            } else {
                const normalColor = value
                    ? theme.palette.neutralPrimary
                    : theme.palette.white;
                customStyles.root = {
                    backgroundColor: isSelect ? selectColor : normalColor,
                };
            }
            if (isSelect) {
                customStyles.root["&:hover"] = {
                    backgroundColor: selectColor,
                };
            }

            return (
                <DetailsRow
                    key={imageId}
                    {...props}
                    styles={customStyles}
                    id={`detailsRow_${this.getKey(imageId)}`}
                />
            );
        }
        return null;
    };
    private _onImgeItemClick = (index: any) => {
        if (this.byImageOcrToyUrl()) {
            const key = this.getKey(index);
            this.isSelectImageIds.add(key);
            let listDom: HTMLElement | null = document.getElementById(
                `detailsRow_${key}`
            );
            if (listDom) {
                listDom.className = this.props.isDarkTheme ? "see_drak" : "see";
            }
        }
    };

    getKey = (id: string) => {
        let key = id;
        if (this.byImageOcrToyUrl() && id.includes(GROUP_SYMBOL_SEPARATOR)) {
            key = id.split(GROUP_SYMBOL_SEPARATOR)[1];
        }
        return key.toString().replace(/[^\w]/g, "");
    };

    byImageOcrToyUrl = () => {
        const selectSubPivot = store.getState().globalReducer.selectSubPivot;
        const selectedKey = store.getState().globalReducer.selectedKey;
        return (
            ["ByImage", "LogicalByImage"].includes(selectSubPivot) ||
            ["docClassifierByImageMetrics"].includes(selectedKey)
        );
    };
}
