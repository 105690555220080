import React from "react";
import {
    BarChartMetricsBasePage,
    IBarChartMetricsProps,
} from "../Common/BarChartMetricsBasePage";
import { MetricDefinition } from "../../DataContract";
import {
    ColumnValueType,
    IGroupedBarChartData,
    TableColumn,
    TableList,
    VerticalBarChart,
} from "../../Controls";
import _ from "lodash";
import { Dropdown, IDropdownOption, Label, Stack } from "@fluentui/react";
import {
    DEFINITION_NAME,
    OVERIVEW_DEFAULT_FIELD,
    POD_OVERVIEW_METRICS_DEFINITIONS,
    READING_ORDER_METRIC_FEFAULT_FIELD,
} from "./OcrPodDataInterface";
import { CalloutTable, formartTarget } from "../../Controls/CalloutTable";

interface DetectMeasureScore {
    precision: number;
    recall: number;
    f1: number;
    "# gt": number;
    "# det": number;
    "# match": number;
    average_edit_distance: number;
}

interface ObjDetection {
    [objName: string]: DetectMeasureScore;
}

interface PodMetrics {
    region_grouping: ObjDetection;
    logical_role: ObjDetection;
    reading_order: ObjDetection;
}

export class OcrPodGeneralMetrics extends BarChartMetricsBasePage<PodMetrics> {
    get MetricDefinitionArray(): MetricDefinition[] {
        return POD_OVERVIEW_METRICS_DEFINITIONS;
    }
    percentageKey = [
        "f1",
        "precision",
        "recall",
        "similarity_score",
        "matched_group_similarity_score",
        "ignore_gt_figure_similarity_score",
        "ignore_gt_table_similarity_score",
        "ingore_gt_figure_table_similarity_score",
        "textline_order_similarity_socre",
    ];

    compatibleWithOldData = (data: any) => {
        const oldFields = ["average_edit_distance", "average_similarity_score"];
        if (data) {
            Object.keys(data).forEach((key) => {
                if (oldFields.includes(key)) {
                    data["similarity_score"] = data[key];
                }
            });
        }
    };

    constructor(prop: IBarChartMetricsProps) {
        super(prop);
        this._onCrossLanguagesChange = this._onCrossLanguagesChange.bind(this);
    }

    queryMetricsResult(): void {
        this._queryMetricsResult("basic_metrics.json");
    }

    renderSingleChart(
        id: string,
        chartData: IGroupedBarChartData[],
        title: string
    ): JSX.Element {
        return (
            <VerticalBarChart
                id={id}
                data={chartData}
                height={400}
                labelPrecision={3}
                xAxisName={title}
                yAxisName="%"
                yAxisMin={0}
                yAxisTicksCount={10}
                isDarkTheme={this.props.isDarkTheme}
            />
        );
    }
    onItemInvoked = (data: any) => {
        this.props.onItemInvoked && this.props.onItemInvoked(data);
    };

    renderAsOverview = (data: any[][], definitionName: string) => {
        if (data) {
            const { records } = this.props;
            const { selectedCrossLanguages, target, selectedColumns } =
                this.state;
            const columns = OVERIVEW_DEFAULT_FIELD.map((colStr) => {
                return {
                    key: colStr,
                    name: this.getName(colStr),
                    fieldName: colStr,
                    valueType: ColumnValueType.Number,
                    minWidth: 150,
                    maxWidth: 300,
                    maxDecimalPlaces: 3,
                    isResizable: true,
                } as TableColumn;
            });
            let overviewColumns = _.cloneDeep(columns);
            if (definitionName === DEFINITION_NAME.reading_order) {
                overviewColumns = READING_ORDER_METRIC_FEFAULT_FIELD.map(
                    (field) => {
                        return {
                            key: field,
                            name: this.getName(field),
                            fieldName: field,
                            valueType: ColumnValueType.Number,
                            minWidth: 150,
                            maxWidth: 300,
                            maxDecimalPlaces: 3,
                            isResizable: true,
                        };
                    }
                );
            }
            overviewColumns.unshift({
                key: "name",
                name: "name",
                fieldName: "name",
                valueType: ColumnValueType.String,
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                isKey: true,
            });

            if (selectedColumns) {
                overviewColumns = overviewColumns.filter(
                    (value) =>
                        selectedColumns?.findIndex(
                            (col) => col === value.key
                        ) !== -1
                );
            }

            let popColumns: any[] = [];
            if (target?.startsWith(DEFINITION_NAME.reading_order)) {
                popColumns = [
                    "textline_order_similarity_socre",
                    "similarity_score",
                    "matched_group_similarity_score",
                    "# gt",
                    "# det",
                    "# match",
                ].map((colStr) => {
                    return {
                        key: colStr,
                        name: this.getName(colStr),
                        fieldName: colStr,
                        valueType: ColumnValueType.Number,
                        minWidth: 150,
                        maxWidth: 300,
                        maxDecimalPlaces: 3,
                        isResizable: true,
                    } as TableColumn;
                });
            } else {
                popColumns = _.cloneDeep(columns);
            }

            popColumns.unshift({
                key: "dataSet",
                name: "dataSet",
                fieldName: "dataSet",
                valueType: ColumnValueType.String,
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                isKey: true,
            });

            const oriData = _.cloneDeep(data);
            const allCrossLanguages = oriData.map(([lang, _]) => lang);
            const crossLanguagesOptions: IDropdownOption[] =
                allCrossLanguages.map((lang) => {
                    const isSelected =
                        selectedCrossLanguages === undefined
                            ? true
                            : selectedCrossLanguages.includes(lang);

                    return { key: lang, text: lang, selected: isSelected };
                });

            let dataToRender: any[][] = oriData;
            if (selectedCrossLanguages !== undefined) {
                dataToRender = oriData.filter(([lang, _]) =>
                    selectedCrossLanguages.includes(lang)
                );
            }

            const evalData =
                dataToRender.length > 0
                    ? this._calculateCrossMetricsData(
                          _.cloneDeep(dataToRender),
                          records.length,
                          definitionName
                      )
                    : new Map();

            let popData: any = undefined;
            if (target) {
                popData = this.getPopData(_.cloneDeep(dataToRender));
            }

            return (
                <div style={{ paddingBottom: "15px" }}>
                    {target && popData && (
                        <CalloutTable
                            tableTitle={target.split("-")[1]}
                            targetId={`detailsRow_${formartTarget(
                                target.split("-")[1]
                            )}`}
                            columns={popColumns}
                            evalData={popData}
                            message="Double click to check the POD data by dataset"
                            width={document.body.clientWidth * 0.9}
                            onItemInvoked={(item) => {
                                const dataSet = item[0];
                                const { target } = this.state;
                                const [definitionname, subType] =
                                    target!.split("-");
                                this.props.onItemInvoked &&
                                    this.props.onItemInvoked([
                                        definitionname,
                                        dataSet,
                                        subType,
                                    ]);
                            }}
                            onDisMiss={() => {
                                this.setState({
                                    target: undefined,
                                });
                            }}
                            evalDataCount={this.props.records.length}
                        />
                    )}

                    {!_.isEmpty(evalData) && (
                        <>
                            <Label>
                                <h2>Cross dataset metrics</h2>
                            </Label>
                            <Stack horizontal>
                                <Label>
                                    Datasets Calculated In Cross Language Table:
                                </Label>
                                <Dropdown
                                    multiSelect={true}
                                    options={crossLanguagesOptions}
                                    onChange={(
                                        _: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption | undefined
                                    ) => {
                                        this._onCrossLanguagesChange(
                                            allCrossLanguages,
                                            option
                                        );
                                    }}
                                    style={{
                                        marginLeft: 8,
                                        width: 900,
                                    }}
                                ></Dropdown>
                            </Stack>

                            <TableList
                                evalData={evalData}
                                evalDataCount={this.props.records.length}
                                columns={overviewColumns}
                                downloadTableTitle="Cross_dataset_metrics"
                                disableFreezeHeader
                                hideHeader
                                verticalFill={false}
                                onItemInvoked={(item: any) => {
                                    this.crossOnItemInvoked(item);
                                }}
                            />
                        </>
                    )}
                </div>
            );
        }
        return <></>;
    };

    crossOnItemInvoked = (item: any) => {
        let subType = item[0];

        const filterItems = item[1].filter((v: any) => v);
        const definitionname = filterItems[0]["definitionName"];
        this.setState({
            target: `${definitionname}-${subType}`,
        });
    };

    getPopData = (datas: any[][]) => {
        const { target } = this.state;
        if (datas.length > 0 && target) {
            const [definitionname, subType] = target!.split("-");
            const popData = new Map();
            datas.forEach((data) => {
                const [dataSet, item] = data;
                const definitionData = item[definitionname];
                if (definitionData) {
                    const subTypeData = definitionData.get(subType);
                    if (subTypeData) {
                        popData.set(dataSet, subTypeData);
                    }
                }
            });
            return popData;
        }
        return undefined;
    };

    calculateMetrics = (metric: any, definitionName?: string) => {
        metric["recall"] =
            metric["# gt"] === 0 ? 0 : metric["# match"] / metric["# gt"];
        metric["precision"] =
            metric["# det"] === 0 ? 0 : metric["# match"] / metric["# det"];
        metric["f1"] =
            metric["recall"] + metric["precision"] === 0
                ? 0
                : (2.0 * metric["recall"] * metric["precision"]) /
                  (metric["recall"] + metric["precision"]);

        if (
            metric["similarity_score"] !== undefined &&
            metric["dataCountSum"]
        ) {
            metric["similarity_score"] =
                metric["similarity_score"] / (metric["dataCountSum"] * 100);
        } else {
            metric["similarity_score"] = NaN;
        }

        if (
            metric["textline_order_similarity_socre"] !== undefined &&
            metric["textlineCountSum"]
        ) {
            metric["textline_order_similarity_socre"] =
                metric["textline_order_similarity_socre"] /
                (metric["textlineCountSum"] * 100);
        } else {
            metric["textline_order_similarity_socre"] = NaN;
        }
        if (definitionName) {
            metric["definitionName"] = definitionName;
        }

        return metric;
    };

    getValues = (values: any[]) => {
        const temp = _.cloneDeep(values);
        temp.forEach((value: any) => {
            for (const key of Object.keys(value)) {
                value[key] = this.getValue(key, value[key]);
            }
        });
        return temp;
    };

    private _calculateCrossMetricsData(
        dataToRender: any[][],
        recordsCount: number,
        definitionName: string
    ) {
        const evalData = new Map();
        const metricViews = dataToRender.map(([_, metricView]) => metricView);

        // const regionGroupings = metricViews.map(
        //     (metricView) => metricView[definitionName]
        // );
        // const typeAgnostics = regionGroupings.map((regionGrouping) =>
        //     regionGrouping.get("type_agnostic")
        // );
        // const typeAgnosticReduce = typeAgnostics
        //     .filter((typeAgnostic) => typeAgnostic)
        //     .reduce((pers: any[], curs: any[]) => {
        //         return pers.map((per, index) => {
        //             const keys = Object.keys(per);
        //             for (const key of keys) {
        //                 per[key] += curs[index][key] ?? 0;
        //             }
        //             return per;
        //         });
        //     });

        // const aggregated_region_grouping = typeAgnosticReduce.map((t: any) =>
        //     this.calculateMetrics(t, DEFINITION_NAME.region_grouping)
        // );
        // evalData.set(
        //     "region_grouping",
        //     this.getValues(aggregated_region_grouping)
        // );

        if (definitionName === DEFINITION_NAME.reading_order) {
            const readingOrderTuples = metricViews
                .map((metricView) => [
                    metricView["reading_order"],
                    metricView["data_count"],
                ])
                .filter(([r, __]) => !_.isEmpty(r));

            if (readingOrderTuples.length > 0) {
                const names: string[] = Array.from(
                    new Set(
                        readingOrderTuples.flatMap(([v, _]) =>
                            Array.from(v.keys())
                        )
                    )
                );

                const dataCountArrList = readingOrderTuples.map(
                    ([_, dataCountArr]) => dataCountArr
                );

                names.forEach((name) => {
                    const nameValues = readingOrderTuples
                        .map(([readingorder, _]) => readingorder.get(name))
                        .filter((v) => v);

                    const sumObjArr: any[] = Array.from(
                        { length: recordsCount },
                        () => {
                            return { dataCountSum: 0, textlineCountSum: 0 };
                        }
                    );

                    nameValues.forEach((pers: any[], datasetIndex) => {
                        pers.forEach((per, index) => {
                            let dataCount = 1;
                            if (
                                dataCountArrList &&
                                dataCountArrList.length > datasetIndex &&
                                dataCountArrList[datasetIndex].length > index &&
                                dataCountArrList[datasetIndex][index] !==
                                    undefined
                            ) {
                                dataCount =
                                    dataCountArrList[datasetIndex][index];
                            }

                            const keys = Object.keys(per);
                            for (const key of keys) {
                                if (sumObjArr[index][key] === undefined) {
                                    sumObjArr[index][key] = 0;
                                }
                                const val = _.isNumber(per[key])
                                    ? per[key]
                                    : Number(per[key]);

                                if (key === "similarity_score") {
                                    sumObjArr[index][key] += val * dataCount;
                                    sumObjArr[index]["dataCountSum"] +=
                                        dataCount;
                                } else {
                                    sumObjArr[index][key] += val ?? 0;
                                }
                                if (key === "textline_order_similarity_socre") {
                                    sumObjArr[index][key] += val * dataCount;
                                    sumObjArr[index]["textlineCountSum"] +=
                                        dataCount;
                                } else {
                                    sumObjArr[index][key] += val ?? 0;
                                }
                            }
                        });
                    });

                    const aggregatedResult = sumObjArr.map((n: any) =>
                        this.calculateMetrics(n, DEFINITION_NAME.reading_order)
                    );
                    evalData.set(name, this.getValues(aggregatedResult));
                });
            }
        }

        if (
            definitionName === DEFINITION_NAME.logical_role ||
            definitionName === DEFINITION_NAME.region_grouping
        ) {
            const logicalRoles = metricViews
                .map((metricView) => metricView[definitionName])
                .filter((r) => !_.isEmpty(r));
            if (logicalRoles.length > 0) {
                const names: string[] = Array.from(
                    new Set(logicalRoles.flatMap((v) => Array.from(v.keys())))
                );
                names.forEach((name) => {
                    const nameValues = logicalRoles
                        .map((logicalRole) => logicalRole.get(name))
                        .filter((v) => v);
                    const nameValuesReduce = nameValues.reduce(
                        (pers: any[], curs: any[]) => {
                            return pers.map((per, index) => {
                                const keys = Object.keys(per);
                                for (const key of keys) {
                                    per[key] += curs[index][key] ?? 0;
                                }
                                return per;
                            });
                        }
                    );

                    const aggregatedResult = nameValuesReduce.map((n: any) =>
                        this.calculateMetrics(n, definitionName)
                    );
                    evalData.set(name, this.getValues(aggregatedResult));
                });
            }
        }

        return evalData;
    }

    private _onCrossLanguagesChange(
        allCrossLanguages: string[],
        option?: IDropdownOption | undefined
    ) {
        if (option) {
            let { selectedCrossLanguages: crossLanguages } = this.state;
            if (crossLanguages === undefined) {
                crossLanguages = allCrossLanguages;
            }

            const optionKey = option.key as string;
            if (option.selected === true) {
                !crossLanguages.includes(optionKey) &&
                    crossLanguages.push(optionKey);
            } else {
                if (crossLanguages.includes(optionKey)) {
                    crossLanguages = crossLanguages.filter(
                        (lang) => lang !== optionKey
                    );
                }
            }

            this.setState({
                selectedCrossLanguages: crossLanguages,
            });
        }
    }
}
