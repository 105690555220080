import React, { Component } from "react";
import {
    Dropdown,
    IDropdownOption,
    IconButton,
    IIconProps,
    Stack,
    Label,
    Sticky,
    StickyPositionType,
    getTheme,
} from "@fluentui/react";
import { stackTokens, stackVisualizationTokens } from "./Pagination.style";

interface IState {
    currentPage: number;
}

interface IProps {
    pageCount: number;
    totalCount: number;
    currentPage?: number;
    paginationType?: string;
    isDarkTheme?: boolean;
    onPageChange: (page: any) => void;
}

const FIRST_IOCN: IIconProps = {
    iconName: "DoubleChevronLeftMed",
    style: {
        fontSize: 14,
    },
};
const LAST_ICON: IIconProps = {
    iconName: "DoubleChevronLeftMedMirrored",
    style: {
        fontSize: 14,
    },
};

const PER_ICON: IIconProps = {
    iconName: "ChevronLeft",
    style: {
        fontSize: 14,
    },
};

const NEXT_ICON: IIconProps = {
    iconName: "ChevronRight",
    style: {
        fontSize: 14,
    },
};

const theme = getTheme();

export class Pagination extends Component<IProps, IState> {
    count = 0;

    constructor(props: IProps) {
        super(props);
        this._getDropdownOption = this._getDropdownOption.bind(this);
        this._firstPage = this._firstPage.bind(this);
        this._prePage = this._prePage.bind(this);
        this._nextPage = this._nextPage.bind(this);
        this._lastPage = this._lastPage.bind(this);
        this._onChange = this._onChange.bind(this);
        this._pageChange = this._pageChange.bind(this);
        this.state = {
            currentPage: this.props.currentPage ?? 0,
        };
    }

    public render() {
        const dropdownOptions = this._getDropdownOption();
        const { currentPage } = this.state;
        const { paginationType } = this.props;

        return (
            <Sticky stickyPosition={StickyPositionType.Footer}>
                <Stack
                    horizontal
                    tokens={
                        paginationType ? stackVisualizationTokens : stackTokens
                    }
                    styles={{
                        root: {
                            background: this.props.isDarkTheme
                                ? theme.palette.neutralDark
                                : theme.palette.neutralLighterAlt,
                        },
                    }}
                >
                    {!paginationType && (
                        <IconButton
                            iconProps={FIRST_IOCN}
                            disabled={currentPage === 0}
                            onClick={this._firstPage}
                        />
                    )}
                    <IconButton
                        iconProps={PER_ICON}
                        disabled={currentPage === 0}
                        onClick={this._prePage}
                    />
                    {!paginationType && (
                        <Dropdown
                            styles={{
                                root: {
                                    width: 70,
                                },
                                title: {
                                    marginTop: "0px !important",
                                    height: "100% !important",
                                    backgroundColor: `${
                                        this.props.isDarkTheme
                                            ? theme.palette.neutralDark
                                            : theme.palette.neutralLighterAlt
                                    }!important`,
                                },
                            }}
                            options={dropdownOptions}
                            selectedKey={currentPage}
                            onChange={this._onChange}
                        />
                    )}
                    {!paginationType && (
                        <Label
                            style={{
                                color: this.props.isDarkTheme
                                    ? "white"
                                    : "black",
                            }}
                        >{`of ${this.count}`}</Label>
                    )}
                    {paginationType && (
                        <Label
                            style={{
                                color: this.props.isDarkTheme
                                    ? "white"
                                    : "black",
                            }}
                        >{`${currentPage + 1}/${this.props.totalCount}`}</Label>
                    )}
                    {paginationType && (
                        <IconButton
                            iconProps={NEXT_ICON}
                            onClick={this._nextPage}
                            disabled={currentPage + 1 === this.props.totalCount}
                        />
                    )}
                    {!paginationType && (
                        <IconButton
                            iconProps={NEXT_ICON}
                            disabled={currentPage === this.count}
                            onClick={this._nextPage}
                        />
                    )}

                    {!paginationType && (
                        <IconButton
                            iconProps={LAST_ICON}
                            disabled={currentPage === this.count}
                            onClick={this._lastPage}
                        />
                    )}
                </Stack>
            </Sticky>
        );
    }

    static getDerivedStateFromProps(
        nextProps: Readonly<IProps>,
        prevState: IState
    ): Partial<IState> | null {
        if (nextProps.currentPage !== prevState.currentPage) {
            return { currentPage: nextProps.currentPage };
        }
        return null;
    }

    private _getDropdownOption(): IDropdownOption[] {
        const { pageCount, totalCount } = this.props;
        this.count = Math.ceil(totalCount / pageCount);

        let options = [];
        for (let index = 0; index < this.count; index++) {
            const key = index + 1;
            const op: IDropdownOption = { key, text: key.toString() };
            options.push(op);
        }

        return options;
    }

    private _firstPage() {
        this.setState({
            currentPage: 1,
        });
        this._pageChange(1);
    }
    private _prePage() {
        this.setState((preS) => {
            if (this.props.paginationType) {
                this._pageChange(preS.currentPage - 1);
                return { currentPage: preS.currentPage - 1 };
            }
            if (preS.currentPage > 1) {
                this._pageChange(preS.currentPage - 1);
                return { currentPage: preS.currentPage - 1 };
            }
        });
    }
    private _nextPage() {
        this.setState((preS) => {
            if (this.props.paginationType) {
                if (preS.currentPage < this.props.totalCount) {
                    this._pageChange(preS.currentPage + 1);
                    return { currentPage: preS.currentPage + 1 };
                }
            } else {
                if (preS.currentPage < this.count) {
                    this._pageChange(preS.currentPage + 1);
                    return { currentPage: preS.currentPage + 1 };
                }
            }
        });
    }
    private _lastPage() {
        this.setState({
            currentPage: this.count,
        });
        this._pageChange(this.count);
    }

    private _onChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) {
        if (option) {
            this.setState({
                currentPage: Number(option.key),
            });
            this._pageChange(Number(option.key));
        }
    }

    private _pageChange(page: number) {
        this.props.onPageChange(page);
    }
}
