import React from "react";
import { IDropdownStyles, Label } from "@fluentui/react";
import { connect } from "react-redux";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { VerticalMetrics } from "../../Pages/Scenarios/VerticalBasePage";
import { mapDispatchToProps, mapStateToProps } from "../../../store/mapFuncs";
import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
    TableList,
} from "../../Controls";
import {
    IDataItem,
    IMergedMetrics,
    IMetricUnit,
    IMetrics,
    RecordDetail,
} from "../../DataContract";
import "../Common/MetricStyle.scss";
import _ from "lodash";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";
import AuthLink from "../../AuthComponent/AuthLink";

const CLIENT_WIDTH = document.documentElement.clientHeight - 133;
const KEY_OF_ERROR_TAG = "errorTaggingUrl";

// prettier-ignore
const VERTICAL_ERROR_COLUMNS: TableColumn[] = [
    { key: "DocId",                          name: "DocId",                          fieldName: "DocId",                          isKey: false,  valueType: ColumnValueType.String,                       minWidth: 80,   maxWidth: 350,                           isResizable: true,  filterable: true,  distinctStr: true},
    { key: "PredictionFieldName",            name: "PredictionFieldName",            fieldName: "PredictionFieldName",            isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  },
    { key: "GroundTruthFieldNames",          name: "GroundTruthFieldNames",          fieldName: "GroundTruthFieldNames",          isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  },
    { key: "MatchingName",                   name: "MatchingName",                   fieldName: "MatchingName",                   isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  filterable: true},
    { key: "PredictionDocumentId",           name: "PredictionDocumentId",           fieldName: "PredictionDocumentId",           isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  },
    { key: "GroundTruthDocumentId",          name: "GroundTruthDocumentId",          fieldName: "GroundTruthDocumentId",          isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  },
    { key: "EvaluateStrategy",               name: "EvaluateStrategy",               fieldName: "EvaluateStrategy",               isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  filterable: true},
    { key: "CompareType",                    name: "CompareType",                    fieldName: "CompareType",                    isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  filterable: true,  distinctStr: true},
    { key: "CompareNote",                    name: "CompareNote",                    fieldName: "CompareNote",                    isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  },
    { key: "AlignmentStrategy",              name: "AlignmentStrategy",              fieldName: "AlignmentStrategy",              isKey: false,  valueType: ColumnValueType.String,                       minWidth: 100,  maxWidth: 350,                           isResizable: true,  filterable: true},
    { key: "TruePositive",                   name: "TruePositive",                   fieldName: "TruePositive",                   isKey: false,  valueType: ColumnValueType.Number,                       minWidth: 80,   maxWidth: 350,                           isResizable: true,  filterable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "TrueNegative",                   name: "TrueNegative",                   fieldName: "TrueNegative",                   isKey: false,  valueType: ColumnValueType.Number,                       minWidth: 90,   maxWidth: 350,                           isResizable: true,  filterable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "FalsePositive",                  name: "FalsePositive",                  fieldName: "FalsePositive",                  isKey: false,  valueType: ColumnValueType.Number,                       minWidth: 80,   maxWidth: 350,                           isResizable: true,  filterable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "FalsePositiveEmptyGroundTruth",  name: "FalsePositiveEmptyGroundTruth",  fieldName: "FalsePositiveEmptyGroundTruth",  isKey: false,  valueType: ColumnValueType.Number,                       minWidth: 90,   maxWidth: 350,                           isResizable: true,  filterable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "FalseNegative",                  name: "FalseNegative",                  fieldName: "FalseNegative",                  isKey: false,  valueType: ColumnValueType.Number,                       minWidth: 90,   maxWidth: 350,                           isResizable: true,  filterable: true, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "PredictionIndex",                name: "PredictionIndex",                fieldName: "PredictionIndex",                isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "GroundTruthIndex",               name: "GroundTruthIndex",               fieldName: "GroundTruthIndex",               isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "PredictionText",                 name: "PredictionText",                 fieldName: "PredictionText",                 isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "GroundTruthText",                name: "GroundTruthText",                fieldName: "GroundTruthText",                isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "PredictionValue",                name: "PredictionValue",                fieldName: "PredictionValue",                isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "GroundTruthValue",               name: "GroundTruthValue",               fieldName: "GroundTruthValue",               isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "GroundTruthStates",              name: "GroundTruthStates",              fieldName: "GroundTruthStates",              isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "PredictionCount",                name: "PredictionCount",                fieldName: "PredictionCount",                isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "GroundTruthCount",               name: "GroundTruthCount",               fieldName: "GroundTruthCount",               isKey: false,  valueType: ColumnValueType.String,                       minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "VisualLink",                     name: "VisualLink",                     fieldName: "VisualLink",                     isKey: false,  valueType: ColumnValueType.Url||ColumnValueType.String,  minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "OneDocLink",                     name: "OneDocLink",                     fieldName: "OneDocLink",                     isKey: false,  valueType: ColumnValueType.Url||ColumnValueType.String,  minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "ErrorTagLink",                   name: "ErrorTagLink",                   fieldName: "ErrorTagLink",                   isKey: false,  valueType: ColumnValueType.Url||ColumnValueType.String,  minWidth: 90,   maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
];

enum DataSrc {
    SplittedFile,
    ErrorAnalysisJson,
}

interface VerticalErrorMetric extends IMetrics<IMetricUnit> {
    fileName: string;
    DocId: string;
    FieldName: string;
    CompareType: string;
    TruePositive: number;
    TrueNegative: number;
    FalsePositive: number;
    FalsePositiveEmptyGT: number;
    FalseNegative: number;
    GTIndex: number;
    PdIndex: number;
    GTText: string;
    PdText: string;
    GTValue: string;
    PdValue: string;
    GTState: string;
    ErrorType: string;
    VisualLink: string;
    OneDoclink: string;
    datasetName: string;
}

interface MetricsV2 {
    EvaluateStrategy: string;
    CompareType: string;
    CompareNote: string;
    AlignmentStrategy: string;
    TruePositive: number;
    TrueNegative: number;
    FalsePositive: number;
    FalsePositiveEmptyGroundTruth: number;
    FalseNegative: number;
}

interface VerticalErrorMetricV2 extends IMetrics<IMetricUnit> {
    PredictionIndex: number;
    PredictionText: string;
    PredictionValue: string;
    PredictionCount: number;
    GroundTruthDocumentId: number;
    GroundTruthFieldNames: string;
    GroundTruthIndex: number;
    GroundTruthText: string;
    GroundTruthValue: string;
    GroundTruthStates: string;
    GroundTruthCount: number;
    MatchingName: string;
    VisualLink: string;
    OneDocLink: string;
    ErrorTagLink: string;
    PredictionDocumentId: number;
    Metrics: MetricsV2[];
}

interface IProps extends IMetricProps {
    metricItems: IDataItem<VerticalMetrics>[];
    toSelectLanguage?: string;
    toSelectField?: string;
    VerticalImageViewDeepLinkHandler?: (key: string, linkData: any) => void;
}

type PropsType = IProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IState extends IMetricState<VerticalErrorMetric> {
    evalData: any;
    entityList: string[]; // fieldList actually
    errorTagUrls: string[];
    selectLanguage?: string;
    selectField?: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" },
};

class VerticalErrorView extends MetricsView<
    PropsType,
    IState,
    VerticalErrorMetric
> {
    private filterDict?: Map<string | number, any[]>;
    private matchDatasetVersion = false;
    constructor(props: PropsType) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);

        const languagesList = this.getLanguageList(
            this.matchDatasetVersion
        ).sort();
        const selectLanguage = this.loadSelectedLanguage(
            props.toSelectLanguage,
            languagesList
        );

        const fieldNames = this.GetFieldNamesBySelectedLanguage(
            this.matchDatasetVersion,
            selectLanguage,
            props.metricItems
        );

        let selectField = props.toSelectField;

        if (!selectField && fieldNames && fieldNames.length > 0) {
            selectField = fieldNames[0];
        }

        this.state = {
            evalData: {},
            entityList: fieldNames,
            errorTagUrls: [],
            selectLanguage: props.toSelectLanguage,
            selectField: selectField,
        };
    }

    public render() {
        const { records } = this.props;
        const {
            entityList,
            evalData,
            selectedColumns,
            selectField,
            selectLanguage,
        } = this.state;
        const columns: TableColumn[] = VERTICAL_ERROR_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.name) !== -1
        );

        const field =
            selectField && entityList.includes(selectField)
                ? selectField
                : entityList[0];
        let entityDict: any = {};
        let keys: string[] = [];
        if (evalData[field]) {
            evalData[field].forEach((keyArray: any) => {
                if (keyArray) {
                    keyArray.forEach((keyList: any) => {
                        keys = keys.concat(Object.keys(keyList));
                    });
                }
            });

            [...keys].forEach((key: string) => {
                entityDict[key] = evalData[field].map((metric: any) => {
                    let metricList: any;
                    if (metric) {
                        metric.forEach((metricVal: any) => {
                            if (key in metricVal) {
                                metricList = metricVal[key];
                            }
                        });
                    }
                    return metricList;
                });
            });
        }

        return (
            <FullScreen>
                {selectLanguage && (
                    <>
                        <TableList<VerticalErrorMetric>
                            columns={columns}
                            evalDataCount={records.length}
                            evalData={entityDict}
                            downloadTableTitle={selectLanguage}
                            renderTableHeader={this._renderTableHeader}
                            onItemInvoked={this._onItemInvoked}
                            initialFilterDict={this.filterDict}
                            keepFilterOption={true}
                            isDarkTheme={this.props.isDarkTheme}
                            onFilterOptionChanged={(
                                filterDict:
                                    | Map<string | number, any[]>
                                    | undefined
                            ) => {
                                this.filterDict = filterDict;
                            }}
                        />
                    </>
                )}

                {!!!selectLanguage && (
                    <NoDataTip>No Entity Metrics Generated</NoDataTip>
                )}
            </FullScreen>
        );
    }

    public componentDidMount(): void {
        const { selectField } = this.state;
        const { disableSetting, toSelectLanguage, updateSettingOff } =
            this.props;
        const languages = this.getLanguageList(this.matchDatasetVersion);

        if (
            selectField &&
            (toSelectLanguage === undefined ||
                (languages.length > 0 && languages.includes(toSelectLanguage)))
        ) {
            this._onQueryButtonClicked(toSelectLanguage, selectField);
        } else {
            this.setState({ selectLanguage: undefined }, () => {
                if (!disableSetting) {
                    updateSettingOff(true);
                }
            });
        }

        store.dispatch(
            updateStateAction({
                saveKey: `${this.props.saveSetKey}`,
                vertical: true,
                columns: VERTICAL_ERROR_COLUMNS,
            })
        );
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(prevProps.toSelectField, this.props.toSelectField) ||
            !_.isEqual(
                prevProps.toSelectLanguage,
                this.props.toSelectLanguage
            ) ||
            !_.isEqual(prevProps.metricItems, this.props.metricItems)
        ) {
            const { toSelectLanguage, toSelectField, metricItems } = this.props;
            const languagesList = this.getLanguageList(
                this.matchDatasetVersion
            ).sort();
            const selectLanguage = this.loadSelectedLanguage(
                toSelectLanguage,
                languagesList
            );

            const fieldNames = this.GetFieldNamesBySelectedLanguage(
                this.matchDatasetVersion,
                selectLanguage,
                metricItems
            );

            const selectField =
                toSelectField ?? (fieldNames && fieldNames.length > 0)
                    ? fieldNames[0]
                    : undefined;

            this.setState(
                {
                    entityList: fieldNames,
                    selectLanguage: selectLanguage,
                    selectField: selectField,
                },
                () => {
                    const { disableSetting, updateSettingOff } = this.props;
                    if (selectLanguage) {
                        if (disableSetting) {
                            updateSettingOff(false);
                        }
                        selectField &&
                            this._onQueryButtonClicked(
                                selectLanguage,
                                selectField
                            );
                    } else {
                        !disableSetting && updateSettingOff(true);
                    }
                }
            );
        }
    }

    public componentWillUnmount() {
        const { disableSetting, updateSettingOff } = this.props;
        disableSetting && updateSettingOff(false);
    }

    protected getEntityList(
        evalData: IMergedMetrics<VerticalErrorMetric>
    ): string[] {
        return Object.keys(evalData).sort();
    }

    protected showEvaluationResult(
        recordDetails: RecordDetail[],
        metricName: string,
        onSuccess?: () => void,
        slice?: boolean,
        dataSrc: DataSrc = DataSrc.SplittedFile
    ) {
        const dataReady = recordDetails.every((detail) => detail);
        if (dataReady) {
            const checkResponseData = this._checkResponseData;
            const versionArr: string[] = [];
            Promise.all(
                recordDetails.map((detail) => {
                    const ver = detail.getRawProp<string>("storageVersion");
                    versionArr.push(ver ? ver.toLowerCase() : "");

                    return this.queryEvaluationResult(detail, metricName);
                })
            )
                .then((data) => {
                    const ready = checkResponseData(data);
                    if (ready) {
                        const [errTagUrls, merged] =
                            this._mergeVerticalErrorMetrics<VerticalErrorMetric>(
                                data,
                                versionArr
                            );
                        if (DataSrc.SplittedFile === dataSrc) {
                            this.setState({
                                evalData: merged,
                                errorTagUrls: errTagUrls,
                            });
                        } else {
                            const entityList = this.getEntityList(merged);
                            this.setState({
                                evalData: merged,
                                errorTagUrls: errTagUrls,
                                entityList: entityList,
                            });
                        }
                    } else if (DataSrc.SplittedFile === dataSrc) {
                        this.showEvaluationResult(
                            recordDetails,
                            "error_analysis.json",
                            onSuccess,
                            true,
                            DataSrc.ErrorAnalysisJson
                        );
                    }
                })
                .catch((_err) => {
                    if (DataSrc.SplittedFile === dataSrc) {
                        this.showEvaluationResult(
                            recordDetails,
                            "error_analysis.json",
                            onSuccess,
                            true,
                            DataSrc.ErrorAnalysisJson
                        );
                    } else {
                        this.setState({
                            evalData: {},
                            entityList: [],
                        });
                    }
                });
        }
    }

    //#region Subclass implementation
    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<VerticalErrorMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<VerticalErrorMetric>>(metricName)
            .then(([record, metrics]) => {
                return metrics;
            });
    }

    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged(this.matchDatasetVersion);
    }
    //#endregion

    //#region Event handler
    private _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { toSelectLanguage, toSelectField } = this.props;
        const languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            const selectLangKey = this.loadSelectedLanguage(
                toSelectLanguage,
                languageList
            );

            const stateSettings: { [key: string]: string | undefined } =
                toSelectField
                    ? {
                          selectLanguage: selectLangKey,
                          selectField: toSelectField,
                      }
                    : { selectLanguage: selectLangKey };

            this._onOptionsChanged(stateSettings);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { VerticalImageViewDeepLinkHandler } = this.props;

        if (VerticalImageViewDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as VerticalErrorMetric[];
                if (fieldMetricsArr?.length > 0) {
                    const fieldMetric = fieldMetricsArr[0];
                    const lowerDatasetName =
                        fieldMetric.datasetName?.toLowerCase();
                    if (!lowerDatasetName?.includes("combined")) {
                        let linkData: { [key: string]: string | undefined } =
                            {};
                        linkData.toSelectLanguage = fieldMetric.datasetName;
                        linkData.queryString = fieldMetric.fileName;
                        VerticalImageViewDeepLinkHandler(
                            "overviewVerticalDetailMetrics",
                            linkData
                        );
                    }
                }
            }
        }
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | string[] | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? (newOptions["selectLanguage"] as string)
                : this.state.selectLanguage;

        const selectField =
            "selectField" in newOptions
                ? (newOptions["selectField"] as string)
                : this.state.selectField;

        const entityList =
            "entityList" in newOptions
                ? (newOptions["entityList"] as string[])
                : this.state.entityList;

        this.setState({
            entityList: entityList,
            selectLanguage: selectLanguage,
            selectField: selectField,
        });
        this._onQueryButtonClicked(selectLanguage, selectField);
    }

    private _onQueryButtonClicked = (
        selectLanguage?: string,
        selectField?: string
    ) => {
        const { records } = this.props;
        if (records.length > 0) {
            if (!selectLanguage) {
                const languages = this.getLanguageList(
                    this.matchDatasetVersion
                ).sort();
                selectLanguage =
                    languages && languages.length > 0 ? languages[0] : "";
            }

            if (!selectField) {
                selectField = this.state.selectField;
            }

            const details = this.filterRecordDetails(
                selectLanguage,
                this.matchDatasetVersion
            );

            this.showEvaluationResult(
                details,
                selectField + "_error_analysis.json"
            );

            if (selectLanguage) {
                this.setState({
                    selectLanguage: selectLanguage,
                    selectField: selectField,
                });
            } else {
                this.setState({
                    selectField: selectField,
                });
            }
        }
    };
    //#endregion

    //#region Help function
    private GetFieldNamesBySelectedLanguage(
        matchDatasetVersion: boolean,
        selectLangKey: string,
        metricItems: IDataItem<VerticalMetrics>[]
    ) {
        const fieldNameSet = new Set<string>();
        metricItems.forEach((item) => {
            const datasetName = matchDatasetVersion
                ? item.recordDetail.dataset.displayFullName
                : item.recordDetail.dataset.displayName;
            if (datasetName === selectLangKey && item?.metrics?.fieldMetrics) {
                return item.metrics.fieldMetrics.forEach((metric) => {
                    if (!fieldNameSet.has(metric.FieldName)) {
                        fieldNameSet.add(metric.FieldName);
                    }
                });
            }
        });

        return Array.from(fieldNameSet).sort();
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, entityList, errorTagUrls, selectField } =
            this.state;
        const languages = this.getLanguageList(this.matchDatasetVersion).sort();
        const keepRightDistance = {
            marginRight: 15,
            fontSize: 14,
            fontWeight: 400,
        };
        const imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage,
                styles: dropdownStyles,
                onChange: (language) => {
                    const option: {
                        [key: string]: string | string[];
                    } = {
                        selectLanguage: language.key,
                    };

                    const fieldNames = this.GetFieldNamesBySelectedLanguage(
                        this.matchDatasetVersion,
                        language.key,
                        this.props.metricItems
                    );

                    if (fieldNames && fieldNames.length > 0) {
                        option.entityList = fieldNames;
                        option.selectField = fieldNames[0];
                    }

                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged(option);
                },
            },
            {
                key: "fields",
                text: "PredictionFieldName:",
                options: entityList,
                selectedKey:
                    selectField && entityList.includes(selectField)
                        ? selectField
                        : entityList[0],
                onChange: (field) => {
                    this._onOptionsChanged!({
                        selectField: field!.text,
                    });
                },
            },
        ];
        return (
            <>
                <TableHeader
                    options={imageConfigurations}
                    onQueryButtonClick={this._onQueryButtonClicked}
                />

                {errorTagUrls && errorTagUrls.length > 0 && (
                    <>
                        <Label style={{ marginLeft: 5, marginRight: 10 }}>
                            <b>Error Tagging:</b>
                        </Label>
                        {errorTagUrls.map((url, idx) => {
                            return url ? (
                                <AuthLink
                                    key={`errTag_${idx}`}
                                    url={url}
                                    target="_blank"
                                    style={keepRightDistance}
                                    styles={{
                                        root: {
                                            color: "#75b6e7",
                                        },
                                    }}
                                >
                                    Link
                                </AuthLink>
                            ) : (
                                <Label style={keepRightDistance}>N/A</Label>
                            );
                        })}
                    </>
                )}
            </>
        );
    }

    private _mergeVerticalErrorMetrics<T extends IMetricUnit>(
        metrics: IMetrics<T>[],
        versionArr: string[] = []
    ): [Array<string>, IMergedMetrics<T>] {
        let errTagUrls: string[] = [];
        const merged: IMergedMetrics<T> = {};
        const keysSet = new Set(
            metrics.flatMap((metric) => Object.keys(metric))
        );

        keysSet.forEach((key) => {
            if (key === KEY_OF_ERROR_TAG) {
                errTagUrls = metrics.map((metric) => {
                    return key in metric
                        ? (metric[
                              key as keyof IMetrics<T>
                          ] as unknown as string)
                        : "";
                });
            } else {
                merged[key] = metrics.map((metric, index) => {
                    if (key in metric) {
                        if (
                            versionArr.length > index &&
                            versionArr[index] === "v2"
                        ) {
                            return this._parseErrorMetricV2ToV1(metric, key);
                        } else {
                            return metric[key as keyof IMetrics<T>];
                        }
                    } else {
                        return null;
                    }
                });
            }
        });

        return [errTagUrls, merged];
    }

    private _checkResponseData(data: IMetrics<VerticalErrorMetric>[]): boolean {
        let ready = true;
        if (data && data.length === 1) {
            ready = JSON.stringify(data[0]) !== "{}";
        }

        return ready;
    }

    private _parseErrorMetricV2ToV1<T extends IMetricUnit>(
        metric: IMetrics<T>,
        key: string
    ): T {
        const v2Object = metric[key as keyof IMetrics<T>];
        const v2Items = Object.entries(v2Object);
        const errorMetricArr = v2Items.flatMap(([fileName, metricV2Arr]) => {
            return (metricV2Arr as VerticalErrorMetricV2[]).flatMap(
                (metricV2, metricIndex) => {
                    return metricV2.Metrics.map((m) => {
                        const errorMetric: any = {};
                        errorMetric[
                            `${fileName}-${m.EvaluateStrategy}-${m.CompareType}-${m.AlignmentStrategy}-${metricIndex}`
                        ] = {
                            DocId: fileName,
                            PredictionFieldName: key,
                            GroundTruthFieldNames:
                                metricV2.GroundTruthFieldNames,
                            EvaluateStrategy: m.EvaluateStrategy,
                            CompareType: m.CompareType,
                            CompareNote: m.CompareNote,
                            AlignmentStrategy: m.AlignmentStrategy,
                            TruePositive: m.TruePositive,
                            TrueNegative: m.TrueNegative,
                            FalsePositive: m.FalsePositive,
                            FalsePositiveEmptyGroundTruth:
                                m.FalsePositiveEmptyGroundTruth,
                            FalseNegative: m.FalseNegative,
                            PredictionIndex: metricV2.PredictionIndex,
                            GroundTruthDocumentId:
                                metricV2.GroundTruthDocumentId,
                            GroundTruthIndex: metricV2.GroundTruthIndex,
                            PredictionText: metricV2.PredictionText,
                            GroundTruthText: metricV2.GroundTruthText,
                            PredictionValue: metricV2.PredictionValue,
                            GroundTruthValue: metricV2.GroundTruthValue,
                            GroundTruthStates: metricV2.GroundTruthStates,
                            PredictionCount: metricV2.PredictionCount,
                            PredictionDocumentId: metricV2.PredictionDocumentId,
                            GroundTruthCount: metricV2.GroundTruthCount,
                            MatchingName: metricV2.MatchingName,
                            VisualLink: metricV2.VisualLink,
                            OneDocLink: metricV2.OneDocLink,
                            ErrorTagLink: metricV2.ErrorTagLink,
                        };

                        return errorMetric;
                    });
                }
            );
        });

        return errorMetricArr as unknown as T;
    }
    //#region
}

export const VerticalErrorViewWithRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(VerticalErrorView);
