import React from "react";
import { Stack } from "@fluentui/react";
import { Record, Workspaces } from "../../DataContract";
import { OcrFigureMetrics } from "../../Views/OcrFigure/OcrFigureMetrics";
import { LatencyMetrics } from "../../Views/Latency/LatencyMetrics";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class OcrFigurePage extends React.Component<IProps, IState> {
    public render() {
        const { isDarkTheme, records, selectedKey } = this.props;
        return (
            <Stack verticalFill>
                {selectedKey === "basicFigureMetrics" && (
                    <OcrFigureMetrics
                        records={records}
                        isDarkTheme={isDarkTheme}
                    />
                )}

                {selectedKey === "ocrFigureLatencyMetrics" && (
                    <LatencyMetrics
                        saveSetKey={Workspaces.OcrFigure}
                        records={records}
                        isDarkTheme={isDarkTheme}
                        defaultCrossLanguage="Overall"
                    ></LatencyMetrics>
                )}
            </Stack>
        );
    }
}
