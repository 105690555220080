import React from "react";
import "./EntityMetrics.scss";
import {
    //Pivot
    Pivot,
    PivotLinkFormat,
    PivotItem,

    //Others
    IChoiceGroupOption,
} from "@fluentui/react";

import { ICommonProps } from "../Common/CommonMetrics";

import { EntityImageVIew } from "./EntityImageVIew";
import { EntityLineMetricView } from "./EntityLineView";
import _ from "lodash";
import { EntityErrorDistributionView } from "./EntityErrorDistributionView";
import { EntityPredictionMetricsView } from "./EntityPredictionMetricsView";
import { EntityPredictionLineView } from "./EntityPredictionLineView";
import { store } from "../../../store";
import { EntityMetricsOverview } from "./EntityMetricsOverview";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

export interface OcrEntityStateInSession {
    selectLanguage?: string;
}

interface IState {
    selectedPivot: string;
}

// prettier-ignore
export const METRICS_LVL_OPTIONS: IChoiceGroupOption[] = [
    { key: "lv0",     text: "Level 0: Ignore Space/Punc/Symbol errors", },
    { key: "lv1",     text: "Level 1: Ignore Space errors" },
    { key: "lv2",     text: "Level 2: Default, counting all errors" },
    { key: "lv2_ci",  text: "Level 2 - Case Insensitive: Counting all errors while ignoring error caused by case difference",},
];

export class EntityMetrics extends React.Component<ICommonProps, IState> {
    private _lineselectLanguage?: string;
    private _lineselectEntityName?: string;
    private _tags?: string;
    private _lineselectClmStatus?: string;
    private _entityPrediction?: string;
    private intersectLanguages: string[] = [];
    private linkData?: any;

    constructor(props: ICommonProps) {
        super(props);
        this.state = {
            selectedPivot: "Overview",
        };
    }

    public render() {
        const { selectedPivot } = this.state;
        return (
            <>
                <Pivot
                    linkFormat={PivotLinkFormat.links}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                    onLinkClick={(pivotprops) => {
                        this._lineselectLanguage = undefined;
                        this._lineselectEntityName = undefined;
                        this._tags = undefined;
                        this._entityPrediction = undefined;
                        this._onClickPivot(pivotprops?.props.itemKey!);
                    }}
                    selectedKey={selectedPivot}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                        alwaysRender={true}
                    >
                        <EntityMetricsOverview
                            {...this.props}
                            selectedPivot={selectedPivot}
                            setSelectedSubPivot={this.setSelectedSubPivot}
                            getIntersectLanguages={(intersectLanguages) => {
                                if (
                                    [
                                        "ByImage",
                                        "line",
                                        "predictionline",
                                    ].includes(selectedPivot) &&
                                    !_.isEqual(
                                        this.intersectLanguages,
                                        intersectLanguages
                                    )
                                ) {
                                    this.intersectLanguages =
                                        intersectLanguages;
                                    this.forceUpdate();
                                }
                            }}
                        />
                    </PivotItem>

                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="Error Distribution"
                            className="displayFlex"
                            itemKey="distribution"
                        >
                            <EntityErrorDistributionView
                                records={this.props.records}
                                onClick={(
                                    dataset: string,
                                    entityName: string,
                                    tags: string | undefined
                                ) => {
                                    this._lineselectLanguage = dataset;
                                    this._lineselectEntityName = entityName;
                                    this._tags = tags;
                                    this._onClickPivot("line");
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By Image"
                            className="displayFlex"
                            alwaysRender={false}
                            itemKey="ByImage"
                        >
                            <EntityImageVIew
                                records={this.props.records}
                                availableLanguages={this.intersectLanguages}
                                linkData={this.linkData}
                                saveSetKey={this.props.saveSetKey}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By Entity Line"
                            className="displayFlex"
                            alwaysRender={false}
                            itemKey="line"
                        >
                            <EntityLineMetricView
                                records={this.props.records}
                                availableLanguages={this.intersectLanguages}
                                selectEntityName={_.cloneDeep(
                                    this._lineselectEntityName
                                )}
                                selectLanguage={_.cloneDeep(
                                    this._lineselectLanguage
                                )}
                                selectTags={_.cloneDeep(this._tags)}
                                saveSetKey={this.props.saveSetKey}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="Prediction Metrics​"
                            className="displayFlex"
                            itemKey="prediction"
                        >
                            <EntityPredictionMetricsView
                                records={this.props.records}
                                onClick={(
                                    dataset: string | undefined,
                                    entityName: string | undefined,
                                    entityPrediction: string | undefined,
                                    clmStatus: string | undefined
                                ) => {
                                    this._lineselectLanguage = dataset;
                                    this._lineselectEntityName = entityName;
                                    this._entityPrediction = entityPrediction;
                                    this._lineselectClmStatus = clmStatus;
                                    this._onClickPivot("predictionline");
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By Prediction Line​"
                            className="displayFlex"
                            itemKey="predictionline"
                        >
                            <EntityPredictionLineView
                                records={this.props.records}
                                availableLanguages={this.intersectLanguages}
                                selectEntityName={_.cloneDeep(
                                    this._lineselectEntityName
                                )}
                                selectLanguage={_.cloneDeep(
                                    this._lineselectLanguage
                                )}
                                selectEntityPrediction={_.cloneDeep(
                                    this._entityPrediction
                                )}
                                selectClmStatus={_.cloneDeep(
                                    this._lineselectClmStatus
                                )}
                                saveSetKey={this.props.saveSetKey}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                </Pivot>
            </>
        );
    }

    private _onClickPivot(pivotname: string) {
        this.changeSelectedSubPivot(pivotname);
    }

    setSelectedSubPivot = (selectPivot: string, linkData?: any) => {
        this.linkData = linkData;
        this.changeSelectedSubPivot(selectPivot);
    };

    changeSelectedSubPivot = (selectPivot: string) => {
        this.setState({
            selectedPivot: selectPivot,
        });

        store.dispatch(updateSelectSubpivoteAction(selectPivot));
    };
}
