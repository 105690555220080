import React from "react";
import { ENTITY_GROUP_CONFIG } from "./EntityConstants";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { LINECOLUMNS } from "./EntityConstants";
import {
    ImageVisualizer,
    OcrPolygon,
    TableColumn,
    TableList,
} from "../../Controls";
import {
    IMetrics,
    IMergedMetrics,
    IMetricUnit,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import _ from "lodash";
import { Modal } from "@fluentui/react";
import { METRICS_LVL_OPTIONS, OcrEntityStateInSession } from "./EntityMetrics";
import { exportTableListData } from "../../Utils/ExportFile";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

export interface TextlineCharMetric extends IMetricUnit {
    textline_id: string;
    category: string;
    insert_error: number;
    delete_error: number;
    subs_error: number;
    total_error: number;
    ref: string;
    hyp: string;
    eval: string;
    textline_url: string;
    entity_name: string;
    tags: string;
    imageUrl: string;
    recordDetail: RecordDetail;
}

interface IState extends IMetricState<TextlineCharMetric> {
    categoryList: string[];
    diffmode: string;
    entityList: string[];
    selectedCategories: string[];
    selectEntity?: string;
    selectLanguage?: string;
    selectImageId?: string;
    category?: any;
    filterDict?: Map<string | number, any[]>;
    imageUrl?: string;
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    availableLanguages: string[];
    selectLanguage?: string;
    selectEntityName?: string;
    selectTags?: string;
    onDismiss?: (imageId: any) => void;
}

type TextlineDiffFilter = (
    textline_a: TextlineCharMetric,
    textline_b: TextlineCharMetric
) => boolean;
type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<TextlineCharMetric[]>;

const is_textline_correct = (textline: TextlineCharMetric) => {
    if (textline == null) {
        return true;
    }
    return (
        textline.insert_error === 0 &&
        textline.delete_error === 0 &&
        textline.subs_error === 0
    );
};

const Regression: TextlineDiffFilter = (
    textline_a: TextlineCharMetric,
    textline_b: TextlineCharMetric
) => {
    return !is_textline_correct(textline_a) && is_textline_correct(textline_b);
};

const Fixed: TextlineDiffFilter = (
    textline_a: TextlineCharMetric,
    textline_b: TextlineCharMetric
) => {
    return is_textline_correct(textline_a) && !is_textline_correct(textline_b);
};

const ErrorDiff: TextlineDiffFilter = (
    textline_a: TextlineCharMetric,
    textline_b: TextlineCharMetric
) => {
    return (
        !is_textline_correct(textline_a) &&
        !is_textline_correct(textline_b) &&
        textline_a.hyp !== textline_b.hyp
    );
};

const ErrorSame: TextlineDiffFilter = (
    textline_a: TextlineCharMetric,
    textline_b: TextlineCharMetric
) => {
    return (
        !is_textline_correct(textline_a) &&
        !is_textline_correct(textline_b) &&
        textline_a.hyp === textline_b.hyp
    );
};

const CompareTextlineFilterConfig: { [key: string]: TextlineDiffFilter } = {
    Regression: Regression,
    Fixed: Fixed,
    ErrorDiff: ErrorDiff,
    ErrorSame: ErrorSame,
    All: (a: TextlineCharMetric, b: TextlineCharMetric) => true,
};

export class EntityLineMetricView extends MetricsView<
    IProps,
    IState,
    TextlineCharMetric
> {
    constructor(props: IProps) {
        super(props);
        const { selectEntityName, selectTags } = props;

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        const filterDict = new Map<string | number, any[]>();
        if (selectEntityName) {
            filterDict.set("entity_name", [selectEntityName]);
        }
        if (selectTags !== undefined) {
            filterDict.set("tags", [selectTags]);
        }

        let selectLanguage = props.selectLanguage;
        if (selectLanguage === undefined) {
            const stateKey = this.getSessionStateKey(
                props.records,
                Workspaces.Ocr,
                Typename.EntityMetrics
            );
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(
                    stateStr
                ) as OcrEntityStateInSession;

                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }
            }
        }

        this.state = {
            evalData: {},
            categoryList: [],
            entityList: ["All"],
            diffmode: "All",
            filterDict: filterDict,
            selectedCategories: [],
            selectEntity: this.props.selectEntityName,
            selectLanguage: selectLanguage,
            matchDatasetVersion: true,
            level: "lv2",
        };
    }

    public render() {
        const { records } = this.props;
        const {
            selectEntity,
            evalData,
            diffmode,
            filterDict,
            selectedCategories,
            selectImageId,
            imageVisItems,
            selectedColumns,
        } = this.state;
        const filterColumns = ["category", "entity_name", "tags"];
        const columns: TableColumn[] = _.cloneDeep(LINECOLUMNS)
            .filter(
                (value) =>
                    selectedColumns?.findIndex((col) => col === value.key) !==
                    -1
            )
            .map((col) => {
                if (filterColumns.includes(col.fieldName!)) {
                    col.filterable = true;
                }

                return col;
            });

        let filteredEvalData: IMergedMetrics<TextlineCharMetric> = {};
        const filterFunc = CompareTextlineFilterConfig[diffmode];

        const experiments = records.map((record) => record.name);
        let clickNumber: number = 0;
        Object.entries(evalData).forEach(([textlineId, value]) => {
            if (value.length === 2 && !filterFunc(value[0]!, value[1]!)) {
                return;
            }

            if (
                value.find((item) =>
                    item?.category
                        ? !selectedCategories.includes(item.category)
                        : false
                )
            ) {
                return;
            }
            if (
                !!value.find(
                    (item) => !value.every((item) => item?.total_error === 0)
                )
            ) {
                filteredEvalData[textlineId] = value;
            }
        });
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        this.exportData = filteredEvalData;
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    <ImageVisualizer
                        entity={selectEntity}
                        experiments={experiments}
                        fileId={selectImageId}
                        evalList={imageItemsForVis}
                        onLoadVisualizer={(imageId) => {
                            if (imageId) {
                                const imageIds = Object.keys(evalData);
                                const id = imageIds.find((id) =>
                                    id.includes(imageId)
                                );
                                if (
                                    id &&
                                    evalData[id] &&
                                    evalData[id].length > 0
                                ) {
                                    return evalData[id][0]!.imageUrl;
                                } else {
                                    return "";
                                }
                            } else {
                                return "";
                            }
                        }}
                        setImageMark={this.setImageMark}
                        onRequestPolygons={(imageId?: string) =>
                            this._requestTextlinePolygons(imageId!)
                        }
                        onRequestMetrics={(imageId?: string) =>
                            this._requestTextlineMetrics(imageId!)
                        }
                        onDismiss={(imageId: string) => this.onDismiss(imageId)}
                        clickNumber={this.state.clickNumber}
                    />
                </Modal>
                <FullScreen>
                    <TableList<TextlineCharMetric>
                        key={this.state.selectLanguage}
                        evalDataCount={this.props.records.length}
                        evalData={filteredEvalData}
                        columns={columns}
                        downloadTableTitle={this.state.selectLanguage}
                        isFullFilterMenu={true}
                        initialFilterDict={filterDict}
                        keepFilterOption={true}
                        renderTableHeader={this._renderTableHeader}
                        onFilterOptionChanged={(
                            filterDict: Map<string | number, any[]> | undefined
                        ) => {
                            this.setState({ filterDict: filterDict });
                        }}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [textlineId] = item;
                            const imageId = textlineId.substring(
                                0,
                                textlineId.indexOf(".xml")
                            );
                            this.setState({
                                selectImageId: imageId,
                                clickNumber: clickNumber,
                            });
                        }}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </FullScreen>
            </>
        );
    }

    exportAction = () => {
        exportTableListData(
            this.exportData,
            LINECOLUMNS,
            "EntityByLineMetrics"
        );
    };

    componentWillMount(): void {
        this.loadStateFromReduxStore();
        super.componentWillMount();
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<TextlineCharMetric>> {
        const { selectedCategories } = this.state;
        return Promise.all(
            selectedCategories.map((category) => {
                return Promise.all([
                    recordDetail.fetchMetricsWithCamelCasing<
                        IMetrics<TextlineCharMetric[]>
                    >(`${category}/${metricName}`),
                    recordDetail.dataset.fetchImageListByCategory(category!),
                ]).then(([records, imageList]) => {
                    let items: IMetrics<TextlineCharMetric> = {};
                    for (const textlines of Object.values(records)) {
                        textlines.forEach(
                            (textline) => (textline.recordDetail = recordDetail)
                        );
                        const grouped_textlines = _.mapValues(
                            _.groupBy(textlines, "textline_id")
                        );
                        for (const textline_id in grouped_textlines) {
                            const textlines_by_entity = _.mapValues(
                                _.groupBy(
                                    grouped_textlines[textline_id],
                                    "entity_name"
                                )
                            );
                            for (const entity_name in textlines_by_entity) {
                                const textlines_cur_entity =
                                    textlines_by_entity[entity_name].sort(
                                        (a, b) => {
                                            if (a.ref > b.ref) {
                                                return 1;
                                            }
                                            if (a.ref < b.ref) {
                                                return -1;
                                            }
                                            return 0;
                                        }
                                    );
                                textlines_cur_entity.forEach(
                                    (entity_line, idx) => {
                                        const entity_id = `${textline_id}_${entity_name}_${idx}`;
                                        entity_line.category = category;
                                        entity_line.total_error =
                                            entity_line.delete_error +
                                            entity_line.insert_error +
                                            entity_line.subs_error;
                                        items[entity_id] = entity_line;
                                    }
                                );
                            }
                        }
                    }
                    Object.entries(items).forEach(([key, _]) => {
                        let imageId = key.substring(0, key.indexOf(".xml"));
                        const imageName =
                            imageList.find((image) =>
                                image
                                    .toLowerCase()
                                    .includes(imageId.toLowerCase())
                            ) ?? `${key}.jpg`;
                        items[key].imageUrl = recordDetail.dataset.getImageUrl(
                            `${category}/${imageName}`
                        );
                    });
                    return items;
                });
            })
        ).then((textmetrics) => {
            return textmetrics.reduce((prev, cur) =>
                Object.assign({}, prev, cur)
            );
        });
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: LINECOLUMNS,
                levels: METRICS_LVL_OPTIONS,
                selectedLevel: "lv2",
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.EntityMetrics
                    }`
                }_line`,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.level !== prevState.level) {
            this.onEvaluationRecordChanged();
        }
        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const languageList = this.getLanguageList(matchDatasetVersion);
        let options: { [key: string]: string | undefined } = {};
        const language =
            this.state.selectLanguage ||
            (!!languageList ? languageList[0] : undefined);
        const entity =
            this.props.selectEntityName ||
            (!!this.state.entityList ? this.state.entityList[0] : undefined);
        if (!!language) {
            options["selectLanguage"] = language;
        }
        if (!!entity) {
            options["selectEntity"] = entity;
        }
        this._onOptionsChanged(options, matchDatasetVersion);
    }

    getLanguageList(matchDatasetVersion: boolean): string[] {
        const { availableLanguages } = this.props;
        return availableLanguages.length > 0
            ? availableLanguages
            : super.getLanguageList(matchDatasetVersion);
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, matchDatasetVersion } = this.state;

        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );
            const lvl_str =
                this.state.level === "lv2" ? "" : `_${this.state.level}`;
            this.showEvaluationResult(
                details,
                `textline_entity_metrics${lvl_str}.json`
            );
        }
    };

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion: boolean
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        const selectEntity =
            "selectEntity" in newOptions
                ? newOptions["selectEntity"]
                : this.state.selectEntity;

        const categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );

        this.setState(
            {
                selectLanguage: selectLanguage,
                selectEntity: selectEntity,
                categoryList: categories,
                selectedCategories: categories,
            },
            () => {
                const stateKey = this.getSessionStateKey(
                    this.props.records,
                    Workspaces.Ocr,
                    Typename.EntityMetrics
                );
                const stateInSession: OcrEntityStateInSession = {
                    selectLanguage: selectLanguage,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                this._onQueryButtonClicked();
            }
        );
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, matchDatasetVersion } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);

        let textlineOptions: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (selectLanguage) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!(
                        {
                            selectLanguage: selectLanguage!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
        ];

        if (this.props.records.length === 2) {
            textlineOptions.push({
                key: "diff",
                text: "DiffFilter",
                options: Object.keys(CompareTextlineFilterConfig).sort(),
                selectedKey: this.state.diffmode,
                onChange: (selectedDiffFilter) => {
                    this.setState({
                        diffmode: selectedDiffFilter!.text,
                    });
                },
            });
        }

        return (
            <TableHeader
                options={textlineOptions}
                onQueryButtonClick={this._onQueryButtonClicked}
                onToggle={(checked: boolean) => {
                    this.setState({
                        matchDatasetVersion: checked,
                    });
                    this._onEvaluationRecordChanged(checked);
                }}
            />
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<TextlineCharMetric>
    ): string[] {
        const entityList = Array.from(
            new Set([
                ...Object.values(evalData).flatMap((item) => {
                    return item.find((i) => i?.entity_name)!.entity_name;
                }),
            ])
        );

        const entityUnion = ENTITY_GROUP_CONFIG.filter(
            (entity_group) =>
                entity_group.children.filter((entity) =>
                    entityList.includes(entity)
                ).length > 0
        ).map((group) => group.name);

        return ["All"].concat(entityUnion).concat(entityList).sort();
    }

    private _requestTextlinePolygons(imageId: string): Promise<OcrPolygons[]> {
        const { selectLanguage, selectImageId, evalData, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        const imageIds = Object.keys(evalData);
        const id = imageIds.find((id) => id.includes(imageId));
        const category = evalData[id!][0]?.category;

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_alignment_polygons.json"
                        : "alignment_polygons.json";

                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${category}/${filename}`
                );
            })
        );
    }
    private _requestTextlineMetrics(imageId: string): Promise<any[]> {
        const { selectLanguage, evalData, selectImageId, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );
        const imageIds = Object.keys(evalData);
        const id = imageIds.find((id) => id.includes(imageId));
        const category = evalData[id!][0]?.category;

        let newEvalData: any = {};
        Object.entries(evalData).forEach(([key, val]) => {
            newEvalData[
                key.substring(key.indexOf("_") + 4, key.lastIndexOf("_"))
            ] = val;
        });

        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;
        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_textline_word_metrics.json"
                        : "textline_word_metrics.json";
                return Promise.all([
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${category}/textline_entity_metrics${lvl_str}.json`
                    ),
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${category}/${filename}`
                    ),
                ]).then((results) => {
                    let entity_metrics: OcrMetrics = results[0];
                    const general_metrics: OcrMetrics = results[1];
                    for (const key in entity_metrics) {
                        entity_metrics[key] = entity_metrics[key]
                            .concat(general_metrics[key])
                            .filter((data) => data !== undefined);
                    }
                    return entity_metrics;
                });
            })
        );
    }
    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }
    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
