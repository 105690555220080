import * as React from "react";
import {
    IButtonStyles,
    IconButton,
    IIconProps,
    Stack,
    TooltipHost,
} from "@fluentui/react";

export enum OrderType {
    up = -1,
    down = 1,
}

interface IProps {
    onChangeOrder: (type: OrderType) => void;
    showup?: boolean;
    showdown?: boolean;
    existence?: boolean;
}

interface IState {}

const UP_ICON: IIconProps = {
    iconName: "ChevronUpMed",
    styles: {
        root: {
            fontWeight: "bold",
        },
    },
};

const DOWN_ICON: IIconProps = {
    iconName: "ChevronDownMed",
    styles: {
        root: {
            fontWeight: "bold",
        },
    },
};

export class ChangeOrderBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this._changeOrder = this._changeOrder.bind(this);
    }

    public render() {
        const { showup = true, showdown = true } = this.props;
        return (
            <Stack horizontal>
                <TooltipHost content="move down">
                    <IconButton
                        styles={this.button_style(showdown)}
                        iconProps={DOWN_ICON}
                        onClick={() => this._changeOrder(OrderType.down)}
                        primary
                    />
                </TooltipHost>
                <TooltipHost content="move up">
                    <IconButton
                        styles={this.button_style(showup)}
                        iconProps={UP_ICON}
                        onClick={() => this._changeOrder(OrderType.up)}
                        primary
                    />
                </TooltipHost>
            </Stack>
        );
    }

    private _changeOrder(type: OrderType) {
        this.props.onChangeOrder(type);
    }

    private button_style = (show: boolean): Partial<IButtonStyles> => {
        const { existence = false } = this.props;
        if (existence) {
            return {
                root: {
                    visibility: show ? "" : "hidden",
                },
            };
        }
        return {
            root: {
                display: show ? "block" : "none",
            },
        };
    };
}
