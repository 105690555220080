import React from "react";
import { ENTITY_GROUP_CONFIG, PREDICTIONLINECOLUMNS } from "./EntityConstants";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import {
    ImageVisualizer,
    MetricsType,
    OcrPolygon,
    TableColumn,
    TableList,
} from "../../Controls";
import {
    IMetrics,
    IMergedMetrics,
    IMetricUnit,
    RecordDetail,
} from "../../DataContract";
import _ from "lodash";
import { Modal } from "@fluentui/react";
import { exportTableListData } from "../../Utils/ExportFile";
import { FullScreen } from "../Common/FullScreen";

export interface PredictionlineMetric extends IMetricUnit {
    textline_id: string;
    category: string;
    ref: string;
    hyp: string;
    eval: string;
    entity_name: string;
    entity_pred_result: string;
    imageUrl: string;
    recordDetail: RecordDetail;
    clm_status: string;
}

interface IState extends IMetricState<PredictionlineMetric> {
    categoryList: string[];
    diffmode: string;
    entityList: string[];
    selectedCategories: string[];
    selectEntity?: string;
    selectLanguage?: string;
    selectImageId?: string;
    category?: any;
    filterDict?: Map<string | number, any[]>;
    imageUrl?: string;
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    availableLanguages: string[];
    selectLanguage?: string;
    selectEntityName?: string;
    selectEntityPrediction?: string;
    selectClmStatus?: string;
    onDismiss?: (imageId: any) => void;
}

type TextlineDiffFilter = (
    textline_a: PredictionlineMetric,
    textline_b: PredictionlineMetric
) => boolean;
type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<PredictionlineMetric[]>;

const ErrorDiff: TextlineDiffFilter = (
    textline_a: PredictionlineMetric,
    textline_b: PredictionlineMetric
) => {
    return textline_a?.hyp !== textline_b?.hyp;
};

const ErrorSame: TextlineDiffFilter = (
    textline_a: PredictionlineMetric,
    textline_b: PredictionlineMetric
) => {
    return textline_a?.hyp === textline_b?.hyp;
};

const CompareTextlineFilterConfig: { [key: string]: TextlineDiffFilter } = {
    ErrorDiff: ErrorDiff,
    ErrorSame: ErrorSame,
    All: (a: PredictionlineMetric, b: PredictionlineMetric) => true,
};

export class EntityPredictionLineView extends MetricsView<
    IProps,
    IState,
    PredictionlineMetric
> {
    constructor(props: IProps) {
        super(props);
        const { selectEntityName, selectEntityPrediction, selectClmStatus } =
            props;

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        const filterDict = new Map<string | number, any[]>();
        if (selectEntityName) {
            filterDict.set("entity_name", [selectEntityName]);
        }
        if (selectEntityPrediction !== undefined) {
            filterDict.set("entity_pred", [selectEntityPrediction]);
        }

        if (selectClmStatus !== undefined) {
            filterDict.set("clm_status", [selectClmStatus]);
        }

        this.state = {
            evalData: {},
            categoryList: [],
            entityList: ["All"],
            diffmode: "All",
            filterDict: filterDict,
            selectedCategories: [],
            selectEntity: this.props.selectEntityName,
            selectLanguage: this.props.selectLanguage,
            matchDatasetVersion: true,
        };
    }

    public render() {
        const { records } = this.props;
        const {
            selectEntity,
            evalData,
            diffmode,
            filterDict,
            selectedCategories,
            selectImageId,
            imageVisItems,
        } = this.state;
        const filterColumns = [
            "category",
            "entity_name",
            "entity_pred",
            "clm_status",
        ];
        const columns: TableColumn[] = _.cloneDeep(PREDICTIONLINECOLUMNS).map(
            (col) => {
                if (filterColumns.includes(col.fieldName!)) {
                    col.filterable = true;
                }

                return col;
            }
        );

        let filteredEvalData: IMergedMetrics<PredictionlineMetric> = {};
        const filterFunc = CompareTextlineFilterConfig[diffmode];

        const experiments = records.map((record) => record.name);
        let clickNumber: number = 0;
        Object.entries(evalData).forEach(([textlineId, value]) => {
            if (value.length === 2 && !filterFunc(value[0]!, value[1]!)) {
                return;
            }

            if (
                value.find((item) =>
                    item?.category
                        ? !selectedCategories.includes(item.category)
                        : false
                )
            ) {
                return;
            }

            filteredEvalData[textlineId] = value;
        });
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        this.exportData = filteredEvalData;
        return (
            <>
                {selectImageId && (
                    <Modal
                        styles={{
                            main: {
                                width: "100%!important",
                                height: "100%!important",
                            },
                        }}
                        isOpen={!!selectImageId}
                        containerClassName="modal"
                        onDismiss={() =>
                            this.setState({ selectImageId: undefined })
                        }
                    >
                        <ImageVisualizer
                            entity={selectEntity}
                            experiments={experiments}
                            fileId={selectImageId}
                            evalList={imageItemsForVis}
                            metricsType={MetricsType.predictionlineMetrics}
                            onLoadVisualizer={(imageId) => {
                                if (imageId) {
                                    const imageIds = Object.keys(evalData);
                                    const id = imageIds.find((id) =>
                                        id.includes(imageId)
                                    );
                                    if (
                                        id &&
                                        evalData[id] &&
                                        evalData[id].length > 0
                                    ) {
                                        const mettic = evalData[id].find(
                                            (data) => data && data.imageUrl
                                        );
                                        return mettic ? mettic.imageUrl : "";
                                    } else {
                                        return "";
                                    }
                                } else {
                                    return "";
                                }
                            }}
                            setImageMark={this.setImageMark}
                            onRequestPolygons={(imageId?: string) =>
                                this._requestTextlinePolygons(imageId!)
                            }
                            onRequestMetrics={(imageId?: string) =>
                                this._requestTextlineMetrics(imageId!)
                            }
                            onDismiss={(imageId: string) =>
                                this.onDismiss(imageId)
                            }
                            clickNumber={this.state.clickNumber}
                        />
                    </Modal>
                )}
                <FullScreen>
                    <TableList<PredictionlineMetric>
                        evalDataCount={this.props.records.length}
                        evalData={filteredEvalData}
                        columns={columns}
                        downloadTableTitle="EntityPredictionLine"
                        isFullFilterMenu={true}
                        initialFilterDict={filterDict}
                        keepFilterOption={true}
                        renderTableHeader={this._renderTableHeader}
                        onFilterOptionChanged={(
                            filterDict: Map<string | number, any[]> | undefined
                        ) => {
                            this.setState({ filterDict: filterDict });
                        }}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [textlineId] = item;
                            const imageId = textlineId.substring(
                                0,
                                textlineId.indexOf(".xml")
                            );
                            this.setState({
                                selectImageId: imageId,
                                clickNumber: clickNumber,
                            });
                        }}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </FullScreen>
            </>
        );
    }

    exportAction = () => {
        exportTableListData(
            this.exportData,
            PREDICTIONLINECOLUMNS,
            "EntityByPredictionLineMetrics"
        );
    };

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<PredictionlineMetric>> {
        const { selectedCategories } = this.state;
        return Promise.all(
            selectedCategories.map((category) => {
                return Promise.all([
                    recordDetail.fetchMetricsWithCamelCasing<
                        IMetrics<PredictionlineMetric[]>
                    >(`${category}/${metricName}`),
                    recordDetail.dataset.fetchImageListByCategory(category!),
                ]).then(([records, imageList]) => {
                    let items: IMetrics<PredictionlineMetric> = {};
                    for (const textlines of Object.values(records)) {
                        textlines.forEach((textline) => {
                            textline.recordDetail = recordDetail;
                            textline.clm_status = textline.clm_status || "";
                        });
                        const grouped_textlines = _.mapValues(
                            _.groupBy(textlines, "textline_id")
                        );
                        for (const textline_id in grouped_textlines) {
                            const textlines_by_entity = _.mapValues(
                                _.groupBy(
                                    grouped_textlines[textline_id],
                                    "entity_name"
                                )
                            );
                            for (const entity_name in textlines_by_entity) {
                                const textlines_cur_entity =
                                    textlines_by_entity[entity_name].sort(
                                        (a, b) => {
                                            if (a.ref > b.ref) {
                                                return 1;
                                            }
                                            if (a.ref < b.ref) {
                                                return -1;
                                            }
                                            return 0;
                                        }
                                    );
                                textlines_cur_entity.forEach(
                                    (entity_line, idx) => {
                                        const entity_id = `${textline_id}_${entity_name}_${idx}`;
                                        entity_line.category = category;
                                        items[entity_id] = entity_line;
                                    }
                                );
                            }
                        }
                    }
                    Object.entries(items).forEach(([key, _]) => {
                        let imageId = key.substring(0, key.indexOf(".xml"));
                        const imageName =
                            imageList.find((image) =>
                                image
                                    .toLowerCase()
                                    .includes(imageId.toLowerCase())
                            ) ?? `${key}.jpg`;
                        items[key].imageUrl = recordDetail.dataset.getImageUrl(
                            `${category}/${imageName}`
                        );
                    });
                    return items;
                });
            })
        ).then((textmetrics) => {
            return textmetrics.reduce((prev, cur) =>
                Object.assign({}, prev, cur)
            );
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(true);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const languageList = this.getLanguageList(matchDatasetVersion);
        let options: { [key: string]: string | undefined } = {};
        const language =
            this.props.selectLanguage ||
            (!!languageList ? languageList[0] : undefined);
        const entity =
            this.props.selectEntityName ||
            (!!this.state.entityList ? this.state.entityList[0] : undefined);
        if (!!language) {
            options["selectLanguage"] = language;
        }
        if (!!entity) {
            options["selectEntity"] = entity;
        }
        this._onOptionsChanged(options, matchDatasetVersion);
    }

    getLanguageList(matchDatasetVersion: boolean): string[] {
        const { availableLanguages } = this.props;
        return availableLanguages.length > 0
            ? availableLanguages
            : super.getLanguageList(matchDatasetVersion);
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, matchDatasetVersion } = this.state;

        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );

            this.showEvaluationResult(
                details,
                `textline_entity_prediction.json`
            );
        }
    };

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion: boolean
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        const selectEntity =
            "selectEntity" in newOptions
                ? newOptions["selectEntity"]
                : this.state.selectEntity;

        const categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );

        this.setState(
            {
                selectLanguage: selectLanguage,
                selectEntity: selectEntity,
                categoryList: categories,
                selectedCategories: categories,
            },
            () => this._onQueryButtonClicked()
        );
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, matchDatasetVersion } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);

        let textlineOptions: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (selectLanguage) => {
                    this._onOptionsChanged!(
                        {
                            selectLanguage: selectLanguage!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
        ];

        if (this.props.records.length === 2) {
            textlineOptions.push({
                key: "diff",
                text: "DiffFilter",
                options: Object.keys(CompareTextlineFilterConfig).sort(),
                selectedKey: this.state.diffmode,
                onChange: (selectedDiffFilter) => {
                    this.setState({
                        diffmode: selectedDiffFilter!.text,
                    });
                },
            });
        }

        return (
            <TableHeader
                options={textlineOptions}
                onQueryButtonClick={this._onQueryButtonClicked}
                onToggle={(checked: boolean) => {
                    this.setState({
                        matchDatasetVersion: checked,
                    });
                    this._onEvaluationRecordChanged(checked);
                }}
            />
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<PredictionlineMetric>
    ): string[] {
        const entityList = Array.from(
            new Set([
                ...Object.values(evalData).flatMap((item) => {
                    return item.find((i) => i?.entity_name)!.entity_name;
                }),
            ])
        );

        const entityUnion = ENTITY_GROUP_CONFIG.filter(
            (entity_group) =>
                entity_group.children.filter((entity) =>
                    entityList.includes(entity)
                ).length > 0
        ).map((group) => group.name);

        return ["All"].concat(entityUnion).concat(entityList).sort();
    }

    private _requestTextlinePolygons = (
        imageId: string
    ): Promise<OcrPolygons[]> => {
        const { selectLanguage, selectImageId, evalData } = this.state;
        const details = this.filterRecordDetails(selectLanguage!, true);
        const imageIds = Object.keys(evalData);
        const id = imageIds.find((id) => id.includes(imageId));
        const metric = evalData[id!].find((v) => v);
        const category = metric?.category;
        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_alignment_polygons.json"
                        : "alignment_polygons.json";

                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${category}/${filename}`
                );
            })
        );
    };
    private _requestTextlineMetrics = (imageId: string): Promise<any[]> => {
        const { selectLanguage, evalData, selectImageId } = this.state;
        const details = this.filterRecordDetails(selectLanguage!, true);
        const imageIds = Object.keys(evalData);
        const id = imageIds.find((id) => id.includes(imageId));
        const metric = evalData[id!].find((v) => v);
        const category = metric?.category;

        let newEvalData: any = {};
        Object.entries(evalData).forEach(([key, val]) => {
            newEvalData[
                key.substring(key.indexOf("_") + 4, key.lastIndexOf("_"))
            ] = val;
        });

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_textline_word_metrics.json"
                        : "textline_word_metrics.json";
                return Promise.all([
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${category}/textline_entity_prediction.json`
                    ),
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${category}/${filename}`
                    ),
                ]).then((results) => {
                    let entity_metrics: OcrMetrics = results[0];
                    const general_metrics: OcrMetrics = results[1];
                    for (const key in entity_metrics) {
                        entity_metrics[key] = entity_metrics[key]
                            .concat(general_metrics[key])
                            .filter((data) => data !== undefined);
                    }
                    return entity_metrics;
                });
            })
        );
    };
    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }
    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
