import {
    CommandBarButton,
    DirectionalHint,
    getTheme,
    IIconProps,
    ITooltipHostStyles,
    TooltipHost,
} from "@fluentui/react";
import React from "react";
import { Consumer } from "../../Layout";
import { Workspaces } from "../../DataContract";
import { SubscribeHoc } from "./SubscribeHoc";
import { store } from "../../../store";
import { updateExpandAction } from "../../../store/reducers";

interface IState {
    expandAll?: boolean;
}

interface IProps {
    expandText?: string;
    foldText?: string;
    expandAll?: boolean;
    workspace?: string;
    selectedKey?: string;
    selectSubPivot?: string;
    viewType?: string;
    onChange?: (value: boolean) => void;
}

const foldIcon: IIconProps = {
    iconName: "DoubleChevronDown",
};

const expandIcon: IIconProps = {
    iconName: "DoubleChevronUp",
};

const theme = getTheme();

const CALLOUTPROPS = { gapSpace: 0 };
const HOSTSTYLES: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
};

export class ExpandButton extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);
        this.state = {
            expandAll:
                prop.expandAll ?? store.getState().globalReducer.expandAll,
        };
    }

    showButton = () => {
        const { selectSubPivot, selectedKey, workspace, viewType } = this.props;
        let showExpand = true;
        if (selectSubPivot && selectedKey && workspace && viewType) {
            const ocrExpand =
                workspace === Workspaces.Ocr &&
                ((selectedKey === "generalMetrics" &&
                    viewType === "Table" &&
                    selectSubPivot === "Overview") ||
                    (selectedKey === "entityMetrics" &&
                        selectSubPivot === "Overview") ||
                    (selectedKey === "latencyMetrics" &&
                        viewType === "Table" &&
                        selectSubPivot === "Overview") ||
                    (selectedKey === "detectionMetrics" &&
                        selectSubPivot === "Overview") ||
                    selectedKey === "langMetrics" ||
                    (selectedKey === "wordAlignMetrics" &&
                        selectSubPivot === "Overview"));
            const invoiceExpand =
                workspace === Workspaces.Invoice &&
                selectedKey === "overviewInvoiceMetrics" &&
                viewType === "Table";
            const kvpExpand =
                workspace === Workspaces.Kvp &&
                selectedKey === "overviewKvpMetrics" &&
                viewType === "Table";
            const mathExpand =
                workspace === Workspaces.OcrMath &&
                selectedKey === "latencyMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Table";

            const barcodeExpand =
                workspace === Workspaces.OcrBarcode &&
                ["ocrBarcodeGeneralMetrics", "ocrBarcodePerfMetrics"].includes(
                    selectedKey!
                ) &&
                selectSubPivot === "Overview";

            showExpand =
                ocrExpand ||
                invoiceExpand ||
                kvpExpand ||
                barcodeExpand ||
                mathExpand;
        }
        return showExpand;
    };

    public render() {
        const { expandAll = false } = this.props;
        const {
            onChange,
            expandText = "Fold All",
            foldText = "Expand All",
        } = this.props;
        const showText = expandAll ? expandText : foldText;
        const btnText = expandAll ? expandText : foldText;
        return (
            <>
                {this.showButton() ? (
                    <Consumer>
                        {(value) => {
                            return (
                                <TooltipHost
                                    content={showText}
                                    calloutProps={CALLOUTPROPS}
                                    styles={HOSTSTYLES}
                                    directionalHint={
                                        DirectionalHint.bottomCenter
                                    }
                                >
                                    <CommandBarButton
                                        styles={{
                                            root: {
                                                height: "45px",
                                                marginLeft: "5px",
                                                backgroundColor: "transparent",
                                            },
                                            flexContainer: {
                                                color: value
                                                    ? theme.palette.white
                                                    : theme.palette.black,
                                            },
                                        }}
                                        text={
                                            this.props.workspace !== "ocr"
                                                ? btnText
                                                : ""
                                        }
                                        iconProps={
                                            expandAll ? expandIcon : foldIcon
                                        }
                                        onClick={() => {
                                            store.dispatch(
                                                updateExpandAction(!expandAll)
                                            );
                                            if (onChange) {
                                                onChange(!expandAll);
                                            }
                                        }}
                                    />
                                </TooltipHost>
                            );
                        }}
                    </Consumer>
                ) : (
                    <></>
                )}
            </>
        );
    }
}

const Expandbutton = SubscribeHoc(ExpandButton);
export default Expandbutton;
