import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
} from "../../Controls";
import { IDataItem, Record } from "../../DataContract";
import { CommonView, ICommonState } from "../../Views/Common/CommonMetrics";

const CLIENT_WIDTH = document.documentElement.clientHeight - 133;
// prettier-ignore
export const VERTICAL_COLUMNS: TableColumn[] = [
    { key: "FieldName",                             name: "FieldName",                          fieldName: "FieldName",                             valueType: ColumnValueType.String,  minWidth: 165,  maxWidth: 200,       distinctStr: true,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, filterable: true },
    { key: "CompareType",                           name: "CompareType",                        fieldName: "CompareType",                           valueType: ColumnValueType.String,  minWidth: 165,  maxWidth: 200,       distinctStr: true,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, filterable: true },
    { key: "MicroOrMacro",                          name: "MicroOrMacro",                       fieldName: "MicroOrMacro",                          valueType: ColumnValueType.String,  minWidth: 165,  maxWidth: 150,       isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, filterable: true },
    { key: "TruePositive",                          name: "TruePositive",                       fieldName: "TruePositive",                          valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "TrueNegative",                          name: "TrueNegative",                       fieldName: "TrueNegative",                          valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "FalsePositive",                         name: "FalsePositive",                      fieldName: "FalsePositive",                         valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "FalsePositiveEmptyGroundTruth",         name: "FalsePositiveEmptyGroundTruth",      fieldName: "FalsePositiveEmptyGroundTruth",         valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "FalseNegative",                         name: "FalseNegative",                      fieldName: "FalseNegative",                         valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "Accuracy",                              name: "Accuracy",                           fieldName: "Accuracy",                              valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "Precision",                             name: "Precision",                          fieldName: "Precision",                             valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "Recall",                                name: "Recall",                             fieldName: "Recall",                                valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "F1",                                    name: "F1",                                 fieldName: "F1",                                    valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
];

// prettier-ignore
export const VERTICAL_REPORT_COLUMNS: TableColumn[] = [
    { key: "FieldName",                             name: "FieldName",                          fieldName: "FieldName",                             valueType: ColumnValueType.String,  minWidth: 200,  maxWidth: 300,       distinctStr: true,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, filterable: true },
    { key: "CompareType",                           name: "CompareType",                        fieldName: "CompareType",                           valueType: ColumnValueType.String,  minWidth: 165,  maxWidth: 200,       distinctStr: true,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed, filterable: true },
    { key: "MicroOrMacro",                          name: "MicroOrMacro",                       fieldName: "MicroOrMacro",                          valueType: ColumnValueType.String,  minWidth: 165,  maxWidth: 150,       isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
    { key: "F1",                                    name: "F1",                                 fieldName: "F1",                                    valueType: ColumnValueType.Number,  minWidth: 120,  maxWidth: Number(`${CLIENT_WIDTH/12}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed},
];

export interface VerticalFieldMetrics {
    FieldName: string;
    CompareType: string;
    TruePositive: number;
    TrueNegative: number;
    FalsePositive: number;
    FalseNegative: number;
    Accuracy: number;
    Precision: number;
    Recall: number;
    F1: number;
    DatasetName?: string;
    Key?: string;
    MicroOrMacro?: string;
}

export interface VerticalMetrics {
    fieldMetrics: Array<VerticalFieldMetrics>;
}

export interface IVerticalBaseState extends ICommonState<VerticalMetrics> {
    dataItems: IDataItem<VerticalMetrics>[];
}

export interface IVerticalBaseProps {
    records: Record[];
    selectedKey: string;
    isForReport: boolean;
    linkData?: any;
    isDarkTheme?: boolean;
    deepLinkHandler?: (key: string, linkData: any) => void;
}

export abstract class VerticalBasePage extends CommonView<
    IVerticalBaseProps,
    IVerticalBaseState,
    VerticalMetrics
> {
    constructor(props: IVerticalBaseProps) {
        super(props);

        this.state = {
            dataItems: [],
        };
    }

    queryMetricsResult() {
        this._queryMetricsResult("evaluation_result.json");
    }

    protected _getFieldNames(
        dataItems: IDataItem<VerticalMetrics>[]
    ): string[] {
        let fieldNames: string[] = [];
        if (dataItems && dataItems.length > 0) {
            const allFileNames = dataItems.flatMap((item) => {
                return item.metrics.fieldMetrics.map((metric) => {
                    return metric.FieldName;
                });
            });

            fieldNames = [...new Set(allFileNames)];
        }

        return fieldNames;
    }
}
