import {
    CommandBarButton,
    DirectionalHint,
    IIconProps,
    ITooltipHostStyles,
    TooltipHost,
} from "@fluentui/react";
import React from "react";

import exportExcelObservable from "./ExportExcelObservable";
import { SubscribeHoc } from "./SubscribeHoc";

interface IState {}

interface IProps {
    disableExportingExcel?: boolean;
    workspace?: string;
    selectedKey?: string;
    selectSubPivot?: string;
    viewType?: string;
}

const exportIcon: IIconProps = {
    iconName: "ExcelLogoInverse",
    style: { color: "#0078d4" },
};

const CALLOUTPROPS = { gapSpace: 0 };
const HOSTSTYLES: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
};

export class ExportExcel extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);
        this.state = {};
    }

    showButton = () => {
        const { selectedKey, selectSubPivot, viewType } = this.props;
        if (selectedKey && selectSubPivot && viewType) {
            return (
                (selectSubPivot === "Overview" &&
                    viewType === "Table" &&
                    ["generalMetrics", "latencyMetrics"].includes(
                        selectedKey
                    )) ||
                (selectSubPivot === "Overview" &&
                    [
                        "ocrMathGeneralMetrics",
                        "ocrBarcodeGeneralMetrics",
                        "ocrBarcodePerfMetrics",
                    ].includes(selectedKey)) ||
                (viewType === "Table" &&
                    ["highlightMetrics", "overviewInvoiceMetrics"].includes(
                        selectedKey
                    )) ||
                [
                    "entityMetrics",
                    "detectionMetrics",
                    "wordAlignMetrics",
                    "overviewReceiptMetrics",
                    "overviewBusinessCardMetrics",
                    "overviewIdCardMetrics",
                    "overviewIndustryMetrics",
                    "overviewHealthMetrics",
                    "overviewExperimentalPrebuiltMetrics",
                    "basicCheckboxMetrics",
                    "basicFigureMetrics",
                ].includes(selectedKey)
            );
        }
        return true;
    };

    public render() {
        return (
            <>
                {this.showButton() && (
                    <TooltipHost
                        content={"Export All"}
                        calloutProps={CALLOUTPROPS}
                        styles={HOSTSTYLES}
                        directionalHint={DirectionalHint.bottomCenter}
                    >
                        <CommandBarButton
                            disabled={this.props.disableExportingExcel}
                            styles={{
                                root: {
                                    height: "45px",
                                    marginLeft: "5px",
                                    backgroundColor: "transparent",
                                },
                            }}
                            text={
                                this.props.workspace !== "ocr"
                                    ? "Export All"
                                    : ""
                            }
                            iconProps={exportIcon}
                            onClick={(e) => {
                                exportExcelObservable.next();
                            }}
                        />
                    </TooltipHost>
                )}
            </>
        );
    }
}

const ExportExcelbutton = SubscribeHoc(ExportExcel);
export default ExportExcelbutton;
