import { ILinkProps } from "@fluentui/react";
import React from "react";
import { AuthHoc, AuthUrlType } from "./AuthHoc";
import { Link } from "@fluentui/react/lib/Link";

export class CustomLink extends React.Component<ILinkProps> {
    public render() {
        return <Link {...this.props}>{this.props.children}</Link>;
    }
}

const AuthLink = AuthHoc(CustomLink, AuthUrlType.link);
export default AuthLink;
