import React from "react";
import "../Common/MetricStyle.scss";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import {
    TableColumn,
    TableList,
    ColumnValueType,
    NumContrastPolicy,
    NumberFormat,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { optimizeDecimalPlaces } from "../../Utils";
import { from } from "linq-to-typescript";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface CustFormArguments {
    trainingDocCount: number;
    trainingDocIndex: number;
}

interface CustomFormImageMetric extends IMetrics<IMetricUnit> {
    DocName: string;
    Accuracy: string;
    InferenceLatency: string;
    ocrToyUrl: string;
}

interface IState extends IMetricState<CustomFormImageMetric> {
    selectLanguage?: string;
    selectTrainingDocCount?: string;
    selectTrainingDocIndex?: string;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
    toSelectDocCount?: string;
    toSelectDocIndex?: string;
}

// prettier-ignore
const CUSTOMFORM_IMAGE_COLUMNS: TableColumn[] = [
    { key: "DocName",          name: "DocName",          fieldName: "DocName",          isKey: true,  valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "Accuracy",         name: "Accuracy (%)",     fieldName: "Accuracy",         isKey: false, valueType: ColumnValueType.Number, minWidth: 250, maxWidth: 350, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen, numberFormat: NumberFormat.Percentage, maxDecimalPlaces: 2 },
    { key: "InferenceLatency", name: "InferenceLatency", fieldName: "InferenceLatency", isKey: false, valueType: ColumnValueType.Number, minWidth: 250, maxWidth: 250, isResizable: true, maxDecimalPlaces: 2 },
    { key: "ocrToyUrl",        name: "OcrToyUrl",        fieldName: "ocrToyUrl",        isKey: false, valueType: ColumnValueType.Url,    minWidth: 200, maxWidth: 250, isResizable: true, },
];

export class CustFormDetailView extends MetricsView<
    IProps,
    IState,
    CustomFormImageMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            selectLanguage: props.toSelectLanguage,
            selectTrainingDocCount: props.toSelectDocCount,
            selectTrainingDocIndex: props.toSelectDocIndex,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.CustomForm}_${Typename.DetailView}`,
                columns: CUSTOMFORM_IMAGE_COLUMNS,
            })
        );
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectedColumns } = this.state;

        const columns: TableColumn[] = CUSTOMFORM_IMAGE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const tablekey = `${this.state.selectLanguage}_${this.state.selectTrainingDocCount}_${this.state.selectTrainingDocIndex}`;
        return (
            <FullScreen>
                <TableList<CustomFormImageMetric>
                    key={tablekey}
                    evalDataCount={records.length}
                    evalData={evalData}
                    columns={columns}
                    downloadTableTitle={tablekey}
                    renderTableHeader={this._renderTableHeader}
                    isWiderCell={true}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </FullScreen>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<CustomFormImageMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<CustomFormImageMetric>>(metricName)
            .then(([record, metrics]) => {
                let items: any = {};
                Object.entries(metrics).forEach(([_, val]) => {
                    const evalAlgo =
                        recordDetail.getRawProp<string>("algorithm");
                    const argu =
                        recordDetail.getRawProp<CustFormArguments>("arguments");
                    const evaluator = `${evalAlgo}(${argu.trainingDocCount},${argu.trainingDocIndex})`;
                    val.Accuracy = optimizeDecimalPlaces(
                        Number(val.Accuracy),
                        4
                    ).toString();
                    val.InferenceLatency = optimizeDecimalPlaces(
                        Number(val.InferenceLatency),
                        3
                    ).toString();
                    val.ocrToyUrl =
                        val.DocName.endsWith(".tif") ||
                        val.DocName.endsWith(".tiff") ||
                        val.DocName.endsWith(".pdf")
                            ? `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.page1.html`
                            : `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.html`;
                    items[val.DocName] = val;
                });
                return items;
            });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged();
    }

    _onEvaluationRecordChanged() {
        const { toSelectLanguage, toSelectDocCount, toSelectDocIndex } =
            this.props;
        const languageList = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        if (languageList.length > 0) {
            const language =
                toSelectLanguage && languageList.includes(toSelectLanguage)
                    ? toSelectLanguage
                    : languageList[0];

            const trainingDocCountList = this._getTrainingArgumentList(
                language,
                "TrainingDocCount"
            );
            const trainingDocIndexList = this._getTrainingArgumentList(
                language,
                "TrainingDocIndex",
                trainingDocCountList[0]!
            );

            let stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: language,
                selectTrainingDocCount:
                    toSelectDocCount ?? trainingDocCountList[0],
                selectTrainingDocIndex:
                    toSelectDocIndex ?? trainingDocIndexList[0],
            };

            this._onOptionsChanged(stateSettings);
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _getTrainingArgumentList(
        selectLanguage: string,
        metricsName: string,
        selectTrainingDocCount: string = ""
    ) {
        const trainingArguements = this._getTrainingArguments(selectLanguage);
        const distinctArgues = trainingArguements.flatMap(
            (trainingArgue) => trainingArgue
        );

        if (metricsName === "TrainingDocCount") {
            return Array.from(
                new Set(
                    distinctArgues.map((argue) =>
                        String(argue.trainingDocCount)
                    )
                )
            );
        } else if (metricsName === "TrainingDocIndex") {
            const argueListWithGivenDocCount = distinctArgues.filter(
                (argue) =>
                    String(argue.trainingDocCount) === selectTrainingDocCount
            );

            return from(
                new Set(
                    argueListWithGivenDocCount.map(
                        (argue) => argue.trainingDocIndex
                    )
                )
            )
                .orderBy((i) => i)
                .toArray()
                .map((i) => String(i));
        }
        return [];
    }

    private _getTrainingArguments(selectLanguage: string) {
        const filteredRecordDetails = this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) => detail.dataset.name === selectLanguage);
        });
        return filteredRecordDetails.map((details) => {
            return details.map((d) => {
                return d.getRawProp<CustFormArguments>("arguments");
            });
        });
    }

    private _onQueryButtonClicked = () => {
        const {
            selectTrainingDocCount,
            selectTrainingDocIndex,
            selectLanguage,
        } = this.state;
        const { records } = this.props;
        if (records.length > 0 && selectLanguage) {
            const details = this._filterCustomFormRecordDetails(
                selectLanguage,
                selectTrainingDocCount!,
                selectTrainingDocIndex!
            );
            this.showEvaluationResult(details, "doc_results.json");
        }
    };

    private _filterCustomFormRecordDetails(
        selectLanguage: string,
        selectTrainingDocCount: string,
        selectTrainingDocIndex: string
    ): RecordDetail[] {
        return this.props.records.map((record) => {
            return record.getDetails().filter((detail) => {
                const argu = detail.getRawProp<CustFormArguments>("arguments");
                return (
                    detail.dataset.name === selectLanguage &&
                    String(argu.trainingDocCount) === selectTrainingDocCount &&
                    String(argu.trainingDocIndex) === selectTrainingDocIndex
                );
            })[0];
        });
    }

    private _onOptionsChanged(
        newOptions: {
            [key: string]: string | undefined;
        },
        timeout: number = 1000
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        let selectTrainingDocCount =
            "selectTrainingDocCount" in newOptions
                ? newOptions["selectTrainingDocCount"]
                : this.state.selectTrainingDocCount;
        let selectTrainingDocIndex =
            "selectTrainingDocIndex" in newOptions
                ? newOptions["selectTrainingDocIndex"]
                : this.state.selectTrainingDocIndex;
        setTimeout(() => {
            if (
                selectTrainingDocIndex === this.state.selectTrainingDocIndex &&
                selectTrainingDocCount === this.state.selectTrainingDocCount &&
                selectLanguage === this.state.selectLanguage
            ) {
                this._onQueryButtonClicked();
            }
        }, timeout);
        this.setState({
            selectTrainingDocIndex: selectTrainingDocIndex,
            selectTrainingDocCount: selectTrainingDocCount,
            selectLanguage: selectLanguage,
        });
    }

    private _renderTableHeader(): JSX.Element {
        let languages = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        const trainingDocCountList = this._getTrainingArgumentList(
            this.state.selectLanguage ?? languages[0],
            "TrainingDocCount"
        );
        const trainingDocIndexList = this._getTrainingArgumentList(
            this.state.selectLanguage ?? languages[0],
            "TrainingDocIndex",
            this.state.selectTrainingDocCount ?? trainingDocCountList[0]
        );
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "DatasetFullName:",
                options: languages,
                selectedKey: this.state.selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: "trainingDocCount",
                text: "TrainingDocCount:",
                options: trainingDocCountList,
                selectedKey:
                    this.state.selectTrainingDocCount ??
                    trainingDocCountList[0],
                onChange: (docCount) => {
                    this._onOptionsChanged!({
                        selectTrainingDocCount: docCount!.text,
                    });
                },
            },
            {
                key: "trainingDocIndex",
                text: "TrainingDocIndex:",
                options: trainingDocIndexList,
                selectedKey:
                    this.state.selectTrainingDocIndex ??
                    trainingDocIndexList[0],
                onChange: (docIndex) => {
                    this._onOptionsChanged!({
                        selectTrainingDocIndex: docIndex!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }
}
