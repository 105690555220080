import "./LineChartWithXScaleTime.scss";
import React, { Component } from "react";
import * as d3 from "d3";
import { DocumentCardDetails, DocumentCardTitle, Stack } from "@fluentui/react";
import { Consumer } from "../../Layout";
import { colorByTheme } from "../../Utils";

export interface ILineChartDataPoint {
    x: Date;
    y: number;
    name: string;
    recordId: string;
    datasetVer: string;
    recordPath: string;
    groupSequence?: number;
    lineSequence?: number;
}

export interface ILineChartData {
    legend: string;
    color: string;
    data: ILineChartDataPoint[];
}

export interface IGroupedLineChartData {
    chartTitle: string;
    lineChartData: ILineChartData[];
}

const xValue = (d: ILineChartDataPoint): Date => d.x;
const yValue = (d: ILineChartDataPoint): number => d.y;

const CHART_MARGIN = { top: 30, right: 20, bottom: 60, left: 50 };

interface IProps {
    id: string;
    data: IGroupedLineChartData;
    height: number;
    width?: number;
    keyIndex?: number;
    yMin?: number;
    yMax?: number;
    yAxisName?: string;
    ySpecifier?: string;
    isDarkTheme?: boolean;
}
export class LineChartWithXScaleTime extends Component<IProps> {
    private timeoutId: NodeJS.Timeout | null = null;

    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);
        this.state = {
            legendPoints: [],
            visibleCalloutId: -1,
        };
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }

    public render() {
        const { data, id, isDarkTheme, keyIndex } = this.props;
        return (
            <DocumentCardDetails
                key={`document_${keyIndex}`}
                className={"documentcard"}
                styles={{ root: { justifyContent: "none" } }}
            >
                <DocumentCardTitle
                    className="overview_lineChart_title"
                    title={`${data.chartTitle}`}
                />
                <Stack className="overview_detail" horizontal>
                    <div className="seriesDesc">
                        {this.props.data.lineChartData.map((series, index) => {
                            return (
                                <div key={`legend_${index}`} className="desc">
                                    <i
                                        style={{
                                            backgroundColor: series.color,
                                            display: "none",
                                        }}
                                    ></i>
                                    <span
                                        style={{
                                            color: colorByTheme(isDarkTheme),
                                            display: "none",
                                        }}
                                    >
                                        {series.legend}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </Stack>
                <Stack style={{ backgroundColor: "transparent" }}>
                    <Consumer>
                        {(value) => {
                            return (
                                <div
                                    className="drawingContainer"
                                    style={{ color: value ? "white" : "black" }}
                                >
                                    <div id={id} className="drawingBoard"></div>
                                </div>
                            );
                        }}
                    </Consumer>
                </Stack>
            </DocumentCardDetails>
        );
    }
    private _drawChart(): void {
        const that = this;
        //#region Define & calculate basic variable
        const { id, isDarkTheme, yMax, yMin, yAxisName, ySpecifier } =
            this.props;
        const height = this.props.height ?? 500;
        const lineChartData = this.props.data.lineChartData;
        const lineCount = lineChartData.length;

        lineChartData.forEach((_charData, i, chartDataArr) => {
            chartDataArr[i].data.sort((a, b) => a.x.getTime() - b.x.getTime());
            chartDataArr[i].data.forEach((_point, j, pointArr) => {
                pointArr[j].groupSequence = i;
                pointArr[j].lineSequence = j;
            });
        });

        const translateMarginLeftAndTop = `translate(${CHART_MARGIN.left},${CHART_MARGIN.top})`;

        let drawingBoard = document.getElementById(id);
        if (drawingBoard && drawingBoard.hasChildNodes()) {
            drawingBoard.innerHTML = "";
        }

        let width = this.props.width;
        if (width === undefined) {
            const container = drawingBoard?.parentElement;
            if (container && container?.offsetWidth) {
                width = container.offsetWidth - 1;
            } else {
                width = CHART_MARGIN.left + CHART_MARGIN.right;
            }
        }

        const innerWidth = width - CHART_MARGIN.left - CHART_MARGIN.right,
            innerHeight = height - CHART_MARGIN.top - CHART_MARGIN.bottom;

        const lineDataArr: ILineChartDataPoint[] = lineChartData.flatMap(
            (item) => {
                return item.data;
            }
        );

        const maxDate = d3.max(lineDataArr, xValue);
        const maxX = maxDate
            ? new Date(maxDate).setMonth(maxDate.getMonth() + 1)
            : 0;
        const minX = d3.min(lineDataArr, xValue) || 0;

        const maxYvalue = d3.max(lineDataArr, yValue);
        const minYvalue = d3.min(lineDataArr, yValue);
        const minY = yMin ?? (minYvalue ? minYvalue - (minYvalue - 0) / 20 : 0);
        const maxY =
            yMax ?? (maxYvalue ? maxYvalue + (maxYvalue - minY) / 10 : 0);
        //#endregion

        //#region d3 drawing
        const scale_x = d3
            .scaleTime()
            .domain([minX, maxX])
            .range([0, innerWidth]);
        const scale_y = d3
            .scaleLinear()
            .domain([maxY, minY])
            .range([0, innerHeight]);

        const line = d3
            .line<ILineChartDataPoint>()
            .x((d: ILineChartDataPoint) => {
                return scale_x(xValue(d)) || 0;
            })
            .y((d: ILineChartDataPoint) => {
                return scale_y(yValue(d) || 0);
            });

        const tip = d3
            .select("body")
            .append("div")
            .attr("class", "tip")
            .style("display", "none");

        const svg = d3
            .select("#" + id)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        //drawing background grid
        const xInner = d3
            .axisBottom(scale_x)
            .tickSize(-height)
            .tickFormat(() => {
                return "";
            });

        const yInner = d3
            .axisLeft(scale_y)
            .tickSize(-width)
            .tickFormat(() => {
                return "";
            });

        svg.append("g")
            .attr(
                "transform",
                `translate(${CHART_MARGIN.left},${height - CHART_MARGIN.top})`
            )
            .style("opacity", 0.08)
            .call(xInner);

        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .style("opacity", 0.08)
            .call(yInner);

        //drawing X/Y ray.
        const xAxis = d3
            .axisBottom(scale_x)
            .ticks(d3.timeMonth.every(1), "%Y-%m");

        const yAxis = d3.axisLeft(scale_y).ticks(4, ySpecifier);

        svg.append("g")
            .attr(
                "transform",
                `translate(${CHART_MARGIN.left},${
                    height - CHART_MARGIN.bottom
                })`
            )
            .call(xAxis);

        svg.append("g")
            .attr("transform", translateMarginLeftAndTop)
            .call(yAxis)
            .append("text")
            .text(yAxisName ?? "Value")
            .attr("transform", "rotate(-90)")
            .attr("text-anchor", "end")
            .attr("dy", "-2.5em")
            .attr("style", `fill:${colorByTheme(isDarkTheme)}`)
            .attr("font-size", 12)
            .attr("font-weight", "700");

        //drawing line
        for (let i = 0; i < lineCount; i++) {
            svg.append("g")
                .data(lineChartData)
                .attr("transform", translateMarginLeftAndTop)
                .append("path")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr("stroke", function () {
                    return lineChartData[i].color;
                })
                .attr("d", function () {
                    return line(lineChartData[i].data) || "";
                });
        }

        //drawing point
        const eachPoint = svg
            .append("g")
            .attr("transform", translateMarginLeftAndTop)
            .selectAll("dot")
            .attr("transform", translateMarginLeftAndTop)
            .data(lineDataArr)
            .enter();

        eachPoint
            .append("circle")
            .attr("r", 5)
            .attr("cx", function (d) {
                return scale_x(xValue(d)) || 0;
            })
            .attr("cy", function (d) {
                return scale_y(d.y);
            })
            .style("fill", function (d) {
                const data = d as ILineChartDataPoint;
                return lineChartData[data.groupSequence ?? 0].color;
            })
            .on("mouseover", function (e: any, d: any) {
                const event = e as MouseEvent;
                const data = d as ILineChartDataPoint;
                const diffY = LineChartWithXScaleTime._calculateDiffInPointY(
                    d,
                    lineChartData
                );

                const backgroundColor =
                    lineChartData[data.groupSequence ?? 0].color;

                tip.style("top", event.pageY + "px")
                    .style("left", event.pageX + "px")
                    .style("background-color", backgroundColor)
                    .style("display", "block")
                    .html(
                        `<div style="color:${backgroundColor}; background-color: currentColor">
                        <table class="tipText">
                        <tr><td>Record Id</td><td>: <a href="${
                            data.recordPath
                        }" style="color: currentColor" target="_blank">${
                            data.recordId
                        }</a></td></tr>
                        <tr><td>Dataset Ver</td><td>: ${
                            data.datasetVer
                        }</td></tr>
                        <tr><td>${data.name}</td><td>: ${data.y}</td></tr>
                        <tr><td>Time</td><td>: ${
                            data.x instanceof Date
                            ? d3.timeFormat("%y-%m-%d %H:%M")(data.x)
                            : data.x
                        }</td></tr>
                        <tr><td>Trend</td><td>: ${
                            diffY === 0
                            ? "-"
                            : diffY > 0
                                ? diffY.toFixed(2) + " ↑"
                                : diffY.toFixed(2) + " ↓"
                        }</td></tr>
                        </table>
                        </div>`
                    )
                    .on("mouseenter", function () {
                        if (that.timeoutId) {
                            clearTimeout(that.timeoutId);
                            that.timeoutId = null;
                        }
                    })
                    .on("mouseleave", function () {
                        tip.style("display", "none");
                    });
            })
            .on("mouseout", function () {
                that.timeoutId = setTimeout(() => {
                    tip.style("display", "none");
                    that.timeoutId = null;
                }, 500);
            })
            .on("click", function (e: any, d: any) {
                const data = d as ILineChartDataPoint;
                window.open(data.recordPath);
            });

        eachPoint
            .append("text")
            .style("font-size", "10px")
            .style("font-weight", "bold")
            .attr("transform", function (d) {
                return `translate(${
                    (scale_x(xValue(d)) || 0) - 10
                    },${scale_y(d.y) - 10})`;
            })
            .attr("fill", function (d) {
                const diffY = LineChartWithXScaleTime._calculateDiffInPointY(
                    d,
                    lineChartData
                );

                return diffY > 0 ? "red" : "green";
            })
            .text(function (d) {
                const diffY = LineChartWithXScaleTime._calculateDiffInPointY(
                    d,
                    lineChartData
                );

                return diffY === 0
                    ? ""
                    : diffY > 0
                        ? diffY.toFixed(2) + " ↑"
                        : diffY.toFixed(2) + " ↓";
            });
        //#endregion
    }

    private static _calculateDiffInPointY(
        data: ILineChartDataPoint,
        sortedLineChartData: ILineChartData[]
    ) {
        let diffY = 0;
        if (
            data.groupSequence !== undefined &&
            data.lineSequence !== undefined &&
            data.lineSequence > 0
        ) {
            const prePoint =
                sortedLineChartData[data.groupSequence].data[
                data.lineSequence - 1
                ];

            diffY = data.y - prePoint.y;
        }

        return diffY;
    }
}
