import React from "react";
import "../Common/MetricStyle.scss";

import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,

    //Others,
    Stack,
} from "@fluentui/react";

import { TableHeader } from "../../Controls/TableHeader";
import { ImagePerfView } from "./ImagePerfView/ImagePerfView";
import { ComponentLatency } from "./ComponentLatency";
import { Record, Workspaces } from "../../DataContract";
import { LatencyMetricsOverview } from "./LatencyMetricsOverview";
import { store } from "../../../store";
import { MetricAnalysis } from "./MetricAnalysis";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { FullScreen } from "../Common/FullScreen";

export interface IProps {
    records: Record[];
    defaultCrossLanguage?: string;
    saveSetKey?: string;
    isReport?: boolean;
    alwaysShow?: boolean;
    isDarkTheme?: boolean;
    onItemInvoked?: (data: any) => void;
}

export interface IState {
    includesLongTail: boolean;
    crossLanguage: boolean;
    selectedPivot: string;
}

export class LatencyMetrics extends React.Component<IProps, IState> {
    private dataItems?: any;
    private linkData?: any;
    private workSpace = store.getState().globalReducer.workSpace;
    constructor(props: IProps) {
        super(props);

        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.deepLinkHandler = this.deepLinkHandler.bind(this);

        this.state = {
            includesLongTail: true,
            crossLanguage: true,
            selectedPivot: "Overview",
        };
    }

    public render() {
        const { includesLongTail, crossLanguage } = this.state;

        return (
            <div className="overview">
                <Pivot
                    onLinkClick={this.onPivotItemChanged}
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <FullScreen>
                            <div
                                style={{
                                    overflow: "hidden",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <TableHeader
                                        toggleLabel="Cross script show result"
                                        toggleTooltips="Whether show result cross language"
                                        isToggleChecked={crossLanguage}
                                        onToggle={(checked: boolean) => {
                                            this.setState({
                                                crossLanguage: checked,
                                            });
                                        }}
                                    />
                                </Stack>
                                <LatencyMetricsOverview
                                    records={this.props.records}
                                    getDataItems={(dataItems) => {
                                        this.dataItems = dataItems;
                                    }}
                                    saveSetKey={this.props.saveSetKey}
                                    deepLinkHandler={this.deepLinkHandler}
                                    crossLanguage={this.state.crossLanguage}
                                    defaultCrossLanguage={
                                        this.props.defaultCrossLanguage
                                    }
                                    isDarkTheme={this.props.isDarkTheme}
                                ></LatencyMetricsOverview>
                            </div>
                        </FullScreen>
                    </PivotItem>
                    <PivotItem
                        headerText="MetricAnalysis"
                        className="displayFlex"
                        itemKey="MetricAnalysis"
                    >
                        <FullScreen>
                            <div
                                style={{
                                    overflow: "hidden",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <TableHeader
                                        toggleLabel="Includes Long Tail"
                                        toggleTooltips="Whether includes long tail in the chart"
                                        isToggleChecked={includesLongTail}
                                        onToggle={(checked: boolean) => {
                                            this.setState({
                                                includesLongTail: checked,
                                            });
                                        }}
                                    />
                                    <TableHeader
                                        toggleLabel="Cross script show result"
                                        toggleTooltips="Whether show result cross language"
                                        isToggleChecked={crossLanguage}
                                        onToggle={(checked: boolean) => {
                                            this.setState({
                                                crossLanguage: checked,
                                            });
                                        }}
                                    />
                                </Stack>
                                <MetricAnalysis
                                    records={this.props.records}
                                    dataItems={this.dataItems}
                                    includesLongTail={
                                        this.state.includesLongTail
                                    }
                                    crossLanguage={this.state.crossLanguage}
                                    defaultCrossLanguage={
                                        [
                                            Workspaces.Ocr,
                                            Workspaces.OcrCheckbox,
                                            Workspaces.ReleaseTest,
                                        ].includes(this.workSpace)
                                            ? this.props.defaultCrossLanguage
                                            : undefined
                                    }
                                />
                            </div>
                        </FullScreen>
                    </PivotItem>
                    <PivotItem
                        headerText="ComponentLatency"
                        className="displayFlex"
                        itemKey="ComponentLatency"
                    >
                        <FullScreen>
                            <div
                                style={{
                                    overflow: "hidden",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <TableHeader
                                    toggleLabel="Cross script show result"
                                    toggleTooltips="Whether includes detailed categories"
                                    isToggleChecked={crossLanguage}
                                    onToggle={(checked: boolean) => {
                                        this.setState({
                                            crossLanguage: checked,
                                        });
                                    }}
                                />
                                <ComponentLatency
                                    records={this.props.records}
                                    dataItems={this.dataItems}
                                    includesLongTail={true}
                                    crossLanguage={this.state.crossLanguage}
                                    isDarkTheme={this.props.isDarkTheme}
                                    defaultCrossLanguage={
                                        [
                                            Workspaces.Ocr,
                                            Workspaces.OcrCheckbox,
                                            Workspaces.ReleaseTest,
                                        ].includes(this.workSpace)
                                            ? this.props.defaultCrossLanguage
                                            : undefined
                                    }
                                />
                            </div>
                        </FullScreen>
                    </PivotItem>
                    <PivotItem
                        headerText="By Image"
                        className="displayFlex"
                        itemKey="ByImage"
                    >
                        <ImagePerfView
                            records={this.props.records}
                            linkData={this.linkData}
                            saveSetKey={this.props.saveSetKey}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    public onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }
        this.linkData = undefined;
        this.pivotItemChanged(item!.props.itemKey!);
    }

    private deepLinkHandler(key: string, linkData: any): void {
        this.linkData = linkData;
        this.pivotItemChanged(key);
    }

    private pivotItemChanged = (key: string) => {
        this.setState({
            selectedPivot: key,
        });
        store.dispatch(updateSelectSubpivoteAction(key));
    };
}
