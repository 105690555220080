import { IObjectWithKey } from "@fluentui/react";
import _ from "lodash";

export class BucketFieldAggregation {
    public FieldName: string;
    public GroupName: string;
    public Appearance: number;
    public RequestCount: number;

    constructor(
        fieldName: string,
        appearance: number = 0,
        requestCount: number = 0,
        groupName: string = ""
    ) {
        this.FieldName = fieldName;
        this.Appearance = appearance;
        this.RequestCount = requestCount;
        this.GroupName = groupName;
    }

    public get Mean() {
        const mean =
            this.RequestCount === 0 ? 0 : this.Appearance / this.RequestCount;
        return _.isInteger(mean) ? mean : +mean.toFixed(2);
    }

    public get FieldNameWithGroup() {
        return `${this.GroupName} : ${this.FieldName}`;
    }
}

export class FilterItem implements IObjectWithKey {
    public key: string;
    public Name: string;
    public RequestCount: number;
    public TotalRequestCount: number;
    public GroupIndex: number;
    public Category: string;
    public CustomerName: string;

    constructor(
        name: string,
        requestCount: number,
        totalRequestCount: number,
        groupIndex: number,
        category: string,
        customerName: string = ""
    ) {
        this.Name = name;
        this.RequestCount = requestCount;
        this.TotalRequestCount = totalRequestCount;
        this.GroupIndex = groupIndex;
        this.Category = category;
        this.CustomerName = customerName;
        this.key = name;
    }

    public get RequestPercentage() {
        const requestPer =
            this.TotalRequestCount === 0
                ? 0
                : this.RequestCount / this.TotalRequestCount;
        return _.isInteger(requestPer) ? requestPer : +requestPer.toFixed(2);
    }
}
