import React from "react";

import {
    IDataItem,
    IMergedMetrics,
    IMetrics,
    RecordDetail,
} from "../../DataContract";
import { CustFormArguments } from "./CustFormDataInterface";
import { from } from "linq-to-typescript";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import _ from "lodash";

export interface CustomFormErrorAnalysisMetric {
    "doc type": string;
    doc: string;
    "field name": string;
    "field type": string;
    "gt text": string;
    "pred text": string;
    "pred value": string;
    "error type": string;
    "system query token": number;
    "user query token": number;
    "answer tokens": number;
    "inference latency (second)": number;
    description: any;
}

export interface IState extends IMetricState<CustomFormErrorAnalysisMetric> {
    dataItems: IDataItem<CustomFormErrorAnalysisMetric>[];
    selectLanguage?: string;
    selectTrainingDocCount?: string;
    selectTrainingDocIndex?: string;

    selectErrorType?: string[];
    selectDoc?: string[];
    selectFieldType?: string[];
    selectFieldName?: string[];
    showEvalData?: IMergedMetrics<CustomFormErrorAnalysisMetric>;
}

export abstract class CustFormErrorAnalysis extends MetricsView<
    IMetricProps,
    IState,
    any
> {
    render(): React.ReactNode {
        return (
            <FullScreen>
                <div className="displayFlex">
                    {this.renderTableHeader()}
                    {!_.isEmpty(this.state.evalData) ? (
                        this.renderDataView()
                    ) : (
                        <NoDataTip>No Error Analysis Data</NoDataTip>
                    )}
                </div>
            </FullScreen>
        );
    }

    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged();
    }
    componentDidUpdate(prevProps: IMetricProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<CustomFormErrorAnalysisMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<CustomFormErrorAnalysisMetric[]>(metricName)
            .then(([record, datas]) => {
                const items: any = {};
                datas.forEach((data, index) => {
                    const key = `${data.doc}_${data["field name"]}`;
                    items[key] = data;
                });

                return items;
            });
    }

    _onEvaluationRecordChanged = () => {
        const languageList = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        if (languageList.length > 0) {
            const language = languageList[0];

            const trainingDocCountList = this.getTrainingArgumentList(
                language,
                "TrainingDocCount"
            );
            const trainingDocIndexList = this.getTrainingArgumentList(
                language,
                "TrainingDocIndex",
                trainingDocCountList[0]
            );

            const stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: language,
                selectTrainingDocCount: trainingDocCountList[0],
                selectTrainingDocIndex: trainingDocIndexList[0],
            };

            this._onOptionsChanged(stateSettings);
        } else {
            this.setState({
                evalData: {},
            });
        }
    };

    _onOptionsChanged = (newOptions: { [key: string]: string | undefined }) => {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        let selectTrainingDocCount =
            "selectTrainingDocCount" in newOptions
                ? newOptions["selectTrainingDocCount"]
                : this.state.selectTrainingDocCount;
        let selectTrainingDocIndex =
            "selectTrainingDocIndex" in newOptions
                ? newOptions["selectTrainingDocIndex"]
                : this.state.selectTrainingDocIndex;

        this.setState(
            {
                selectTrainingDocIndex: selectTrainingDocIndex,
                selectTrainingDocCount: selectTrainingDocCount,
                selectLanguage: selectLanguage,
            },
            () => {
                if (
                    selectTrainingDocIndex ===
                        this.state.selectTrainingDocIndex &&
                    selectTrainingDocCount ===
                        this.state.selectTrainingDocCount &&
                    selectLanguage === this.state.selectLanguage
                ) {
                    this._onQueryButtonClicked();
                }
            }
        );
    };

    _onQueryButtonClicked = () => {
        const {
            selectTrainingDocCount,
            selectTrainingDocIndex,
            selectLanguage,
        } = this.state;
        const { records } = this.props;
        if (records.length > 0 && selectLanguage) {
            const details = this._filterCustomFormRecordDetails(
                selectLanguage,
                selectTrainingDocCount!,
                selectTrainingDocIndex!
            );
            this.showEvaluationResult(
                details,
                "error_analysis.json",
                undefined,
                false
            );
        }
    };

    _filterCustomFormRecordDetails = (
        selectLanguage: string,
        selectTrainingDocCount: string,
        selectTrainingDocIndex: string
    ): RecordDetail[] => {
        return this.props.records.map((record) => {
            return record.getDetails().filter((detail) => {
                const argu = detail.getRawProp<CustFormArguments>("arguments");
                return (
                    detail.dataset.name === selectLanguage &&
                    String(argu.trainingDocCount) === selectTrainingDocCount &&
                    String(argu.trainingDocIndex) === selectTrainingDocIndex
                );
            })[0];
        });
    };

    _getTrainingArguments = (selectLanguage: string) => {
        const filteredRecordDetails = this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) => detail.dataset.name === selectLanguage);
        });
        return filteredRecordDetails.map((details) => {
            return details.map((d) => {
                return d.getRawProp<CustFormArguments>("arguments");
            });
        });
    };

    getTrainingArgumentList = (
        selectLanguage: string,
        metricsName: string,
        selectTrainingDocCount: string = ""
    ) => {
        const trainingArguements = this._getTrainingArguments(selectLanguage);
        const distinctArgues = trainingArguements.flatMap(
            (trainingArgue) => trainingArgue
        );

        if (metricsName === "TrainingDocCount") {
            return Array.from(
                new Set(
                    distinctArgues.map((argue) =>
                        String(argue.trainingDocCount)
                    )
                )
            );
        } else if (metricsName === "TrainingDocIndex") {
            const argueListWithGivenDocCount = distinctArgues.filter(
                (argue) =>
                    String(argue.trainingDocCount) === selectTrainingDocCount
            );

            return from(
                new Set(
                    argueListWithGivenDocCount.map(
                        (argue) => argue.trainingDocIndex
                    )
                )
            )
                .orderBy((i) => i)
                .toArray()
                .map((i) => String(i));
        }
        return [];
    };

    renderTableHeader = () => {
        const {
            selectLanguage,
            selectTrainingDocCount,
            selectTrainingDocIndex,
        } = this.state;
        let languages = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        const trainingDocCountList = this.getTrainingArgumentList(
            selectLanguage ?? languages[0],
            "TrainingDocCount"
        );
        const trainingDocIndexList = this.getTrainingArgumentList(
            selectLanguage ?? languages[0],
            "TrainingDocIndex",
            selectTrainingDocCount ?? trainingDocCountList[0]
        );

        const imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "DatasetFullName:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
            {
                key: "trainingDocCount",
                text: "TrainingDocCount:",
                options: trainingDocCountList,
                selectedKey: selectTrainingDocCount ?? trainingDocCountList[0],
                onChange: (docCount) => {
                    this._onOptionsChanged({
                        selectTrainingDocCount: docCount.text,
                    });
                },
            },
            {
                key: "trainingDocIndex",
                text: "TrainingDocIndex:",
                options: trainingDocIndexList,
                selectedKey: selectTrainingDocIndex ?? trainingDocIndexList[0],
                onChange: (docIndex) => {
                    this._onOptionsChanged({
                        selectTrainingDocIndex: docIndex.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    };

    abstract renderDataView(): React.ReactNode;
}
