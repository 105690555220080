import React from "react";

import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../../DataContract";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../../Common/CommonMetrics";

import _ from "lodash";
import { OverviewTable } from "../../../Controls/OverviewTable";
import {
    ColumnValueType,
    NumberFormat,
    TableColumn,
} from "../../../Controls/TableList";
import { exportOverviewListData } from "../../../Utils/ExportFile";
import { FullScreen } from "../../Common/FullScreen";
import { NoDataTip } from "../../../Controls/NoDataTip";
import {
    DetailsListLayoutMode,
    MessageBar,
    MessageBarType,
    Stack,
} from "office-ui-fabric-react";
import {
    sortItems,
    sortItemsByDiffNumWhenCompare2Records,
} from "../../../Controls/Common/DiffSort";
import {
    CalloutTable,
    CalloutType,
    formartTarget,
} from "../../../Controls/CalloutTable";
import { store } from "../../../../store";
import { updateStateAction } from "../../../../store/reducers/setting";
import { Toggle } from "@fluentui/react";

// prettier-ignore
export const PREDICTION_DES_COLUMNS: TableColumn[] = [
    { key: "prediction",              name: "Prediction​",    fieldName: "prediction",    valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250,  isResizable: true,  isIconOnly: false ,  distinctStr:true,},
    { key: "dataset",                 name: "Dataset",       fieldName: "dataset",       valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 300,  isResizable: true,  isIconOnly: false,   distinctStr:true, },
    { key: "count",                   name: "Count",         fieldName: "count",         valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180,  isResizable: true,  isIconOnly: false},
];

interface OcrDataset {
    prediction: string;
    count: number;
    dataset: string;
    group: string;
}

interface LogicalMetrics {
    [key: string]: any;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: LogicalMetrics;
}

interface IState extends ICommonState<LogicalMetrics> {
    dataItems: IDataItem[];
    target?: string;
    crossData?: LogicalMetrics[][];
    popData?: any;
    showPercentage?: boolean;
}

export class LogicalOverview extends CommonView<
    ICommonProps,
    IState,
    LogicalMetrics
> {
    private crossColumns?: TableColumn[];
    private crossPerColumns?: TableColumn[];
    constructor(props: ICommonProps) {
        super(props);

        this.state = {
            dataItems: [],
            matchDatasetVersion: true,
        };
        store.dispatch(
            updateStateAction({
                matchDatasetVersion: true,
            })
        );
    }

    public render() {
        return (
            <div className="overview">
                <FullScreen>
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderAsTable()}
                    </div>
                </FullScreen>
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount();
    }
    exportAction = () => {
        const exportD = _.cloneDeep(this.exportData);
        if (this.crossColumns) {
            exportOverviewListData(
                exportD,
                this.crossColumns,
                `OcrPodLogicalOverviewMetrics`
            );
        }
    };

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: IState
    ): void {
        super.componentDidUpdate(prevProps, prevState);

        if (!_.isEqual(prevState.dataItems, this.state.dataItems)) {
            const crossData = this._prepareRenderCrossData();
            this.exportData = crossData;
            this.setState({ crossData: crossData });
        }
        if (
            !_.isEqual(prevState.target, this.state.target) ||
            (!_.isEqual(
                prevState.matchDatasetVersion,
                this.state.matchDatasetVersion
            ) &&
                this.state.target)
        ) {
            const popData = this._prepareRenderDatasetData();
            this.setState({ popData: popData });
        }
    }

    queryMetricsResult() {
        this._queryMetricsResult("basic_metrics.json");
    }

    private _renderAsTable() {
        const { target, popData, crossData, selectedColumns, showPercentage } =
            this.state;
        let showColumns =
            (showPercentage ? this.crossPerColumns : this.crossColumns) || [];
        if (selectedColumns) {
            showColumns = showColumns.filter(
                (value) =>
                    selectedColumns?.findIndex(
                        (col) => col === value.key || value.key.startsWith(col)
                    ) !== -1
            );
        }

        return (
            <Stack>
                <Toggle
                    label="show percentage"
                    inlineLabel
                    checked={showPercentage}
                    onChange={this.onpercentageChange}
                />
                {crossData && crossData.length > 0 && this.crossColumns ? (
                    <>
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            messageBarIconProps={{
                                iconName: "InfoSolid",
                            }}
                            styles={{
                                root: {
                                    backgroundColor: "#eff6fc",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                    width: "99%",
                                },
                                icon: {
                                    color: "#0078d4",
                                },
                            }}
                        >
                            <b>
                                Double click to check the logical role data by
                                dataset
                            </b>
                        </MessageBar>

                        <OverviewTable<any>
                            evalData={crossData}
                            columns={showColumns}
                            targetKeys={["gt"]}
                            calloutType={CalloutType.cell}
                            tableTitle={"Logical Role Error Distribution"}
                            downloadTableTitle={
                                "Logical Role Error Distribution"
                            }
                            prefixIcon={false}
                            layoutMode={DetailsListLayoutMode.justified}
                            onCellDoubleClick={(item, column, ev) => {
                                if (item.length === 2 && column) {
                                    const data = item[1];
                                    const gt = data[0].gt;
                                    const prediction = column.key;
                                    this.setState({
                                        target: `${gt}-${prediction}`,
                                    });
                                }
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </>
                ) : (
                    <NoDataTip>No Logical Role Data</NoDataTip>
                )}

                <>
                    {target && popData && (
                        <CalloutTable
                            tableTitle={target.split("-")[0]}
                            targetId={formartTarget(target)}
                            columns={PREDICTION_DES_COLUMNS}
                            evalData={popData}
                            message="Double click to check by logical role metrics for given dataset and logical role"
                            evalDataCount={this.props.records.length}
                            onItemInvoked={this._calloutItemInvoked}
                            onDisMiss={() => {
                                this.setState({
                                    target: undefined,
                                });
                            }}
                        />
                    )}
                </>
            </Stack>
        );
    }

    onpercentageChange = (
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) => {
        this.setState({ showPercentage: checked });
    };

    private _calloutItemInvoked = (item?: any, index?: number, ev?: Event) => {
        const { onItemInvoked } = this.props;
        if (item && item.length === 2 && item[1].length > 0 && onItemInvoked) {
            const clm = item[1][0];
            const dataset = clm.dataset;
            const groupName = clm.group;
            const predictionName = clm.prediction;

            onItemInvoked([dataset, groupName, predictionName]);
        }
    };

    private _prepareRenderCrossData = () => {
        const all_items = this.props.records.flatMap((_, recordIndex) => {
            const item = this.state.dataItems.filter(
                (item) => item.recordIndex === recordIndex
            );
            return item;
        });

        const all_gts = Array.from(
            new Set(
                all_items.flatMap((item) => {
                    const metrics =
                        item?.metrics?.logical_role_distribution || {};
                    return Object.keys(metrics);
                })
            )
        ).sort();

        const all_predictions = Array.from(
            new Set(
                all_gts.flatMap((group) => {
                    const predictions = Array.from(
                        new Set(
                            all_items.flatMap((item) => {
                                const distribution =
                                    item?.metrics?.logical_role_distribution?.[
                                        group
                                    ] || {};
                                return Object.keys(distribution);
                            })
                        )
                    ).filter((en) => en);
                    return predictions;
                })
            )
        ).sort();

        const allColumns: TableColumn[] = all_predictions.map((prediction) => {
            return {
                key: prediction,
                name: prediction,
                fieldName: prediction,
                valueType: ColumnValueType.Number,
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                isIconOnly: false,
                supportsDoubleClick: true,
            };
        });
        const allPerColumns: TableColumn[] = all_predictions.map(
            (prediction) => {
                return {
                    key: `${prediction}_per`,
                    name: prediction,
                    fieldName: prediction,
                    valueType: ColumnValueType.Number,
                    minWidth: 100,
                    maxWidth: 200,
                    isResizable: true,
                    isIconOnly: false,
                    supportsDoubleClick: true,
                    maxDecimalPlaces: 2,
                    numberFormat: NumberFormat.Percentage,
                };
            }
        );
        allColumns.splice(0, 0, {
            key: "gt",
            name: "gt",
            fieldName: "gt",
            valueType: ColumnValueType.String,
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isIconOnly: false,
            distinctStr: true,
        });
        allPerColumns.splice(0, 0, {
            key: "gt",
            name: "gt",
            fieldName: "gt",
            valueType: ColumnValueType.String,
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isIconOnly: false,
            distinctStr: true,
        });

        this.crossColumns = allColumns;
        this.crossPerColumns = allPerColumns;

        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrPod}_${Typename.GeneralMetrics}_LogicalOverview`,
                selectedItems: allColumns.map((col) => col.key),
                columns: allColumns,
            })
        );

        const groupData = all_gts
            .map((gt) => {
                const result = this.props.records
                    .map((r, recordIndex) => {
                        const items = all_items.filter(
                            (item) => item.recordIndex === recordIndex
                        );
                        const gtDistributions = items
                            .flatMap((item) => {
                                return item?.metrics
                                    ?.logical_role_distribution?.[gt];
                            })
                            .filter((dis) => !_.isEmpty(dis));

                        let gtReduce: any = {};
                        // if (gtDistributions.length === 1) {
                        //     gtReduce = gtDistributions[0];
                        // }
                        if (gtDistributions.length > 0) {
                            gtReduce = gtDistributions.reduce((pre, cur) => {
                                for (const key of all_predictions) {
                                    pre[key] =
                                        (pre[key] ?? 0) + (cur[key] ?? 0);
                                }
                                return pre;
                            }, {});
                        }

                        return gtReduce;
                    })
                    .filter((r) => !_.isEmpty(r))
                    .map((v) => {
                        const total = Object.values<number>(v).reduce(
                            (a, b) => a + b
                        );
                        const keys = Object.keys(v);
                        keys.forEach((key) => {
                            const newKey = `${key}_per`;
                            v[newKey] = (v[key] * 100) / total;
                        });
                        return v;
                    })
                    .map((v) => {
                        v["gt"] = gt;
                        return v;
                    });
                return result;
            })
            .filter((g) => g.length > 0);

        return groupData as LogicalMetrics[][];
    };

    private _prepareRenderDatasetData = () => {
        const { target, dataItems, matchDatasetVersion, showPercentage } =
            this.state;
        if (!target || dataItems.length === 0) {
            return undefined;
        }
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );
        let [group, prediction] = target.split("-");
        if (showPercentage) {
            prediction = prediction.replace("_per", "");
        }

        const data = allDatasetNames.map((datasetName) => {
            const all_items = this.props.records.flatMap((_, recordIndex) => {
                const item = this.state.dataItems.filter(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const popData = all_items.map((item) => {
                return {
                    prediction: prediction,
                    dataset: datasetName,
                    group: group,
                    count:
                        item?.metrics?.logical_role_distribution?.[group]?.[
                            prediction
                        ] ?? NaN,
                };
            });

            return popData;
        });

        const sortData = this._sortByCount(
            (data as any[][]).filter((datasetItem) => {
                return datasetItem.some((item) => !isNaN(item.count));
            })
        );
        const resultArray = this._supplementDefaultValue(sortData);
        const result: any = {};
        resultArray.forEach((value, index) => {
            result[index] = value;
        });

        return result;
    };

    private _sortByCount = <T extends unknown>(data: T[][] = []) => {
        if (this.props.records.length === 2) {
            return data.sort((a: T[], b: T[]) =>
                sortItemsByDiffNumWhenCompare2Records<T>(
                    a,
                    b,
                    "count" as keyof T
                )
            );
        } else {
            return data.sort((a: T[], b: T[]) =>
                sortItems<T>(a, b, "count" as keyof T)
            );
        }
    };

    private _supplementDefaultValue = (data: OcrDataset[][]) => {
        const temp = _.cloneDeep(data);
        data.forEach((datasetItem, index) => {
            const diffCount = this.props.records.length - datasetItem.length;
            if (diffCount > 0) {
                for (let i = 0; i < diffCount; i++) {
                    const defaultValue = _.cloneDeep(datasetItem[0]);
                    defaultValue.count = NaN;
                    temp[index] = temp[index].concat(defaultValue);
                }
            }
        });
        return temp;
    };
}
