import React from "react";
import { BenchmarkTrendPanel } from "./BenchmarkTrendPanel";
import { IconButton } from "@fluentui/react";

interface IProps {}

interface IState {
    trendPanelArr: JSX.Element[];
}

export class BenchmarkTrendPage extends React.Component<IProps, IState> {
    private _trendIndex = 0;
    constructor(props: IProps) {
        super(props);
        this._incrementIndex = this._incrementIndex.bind(this);
        this._onCloseTrendPanel = this._onCloseTrendPanel.bind(this);

        const trendPanelIndex = this._incrementIndex();
        this.state = {
            trendPanelArr: [
                <BenchmarkTrendPanel
                    key={trendPanelIndex}
                    panelIndex={trendPanelIndex}
                    closeHandler={this._onCloseTrendPanel}
                    hideCloseBtn={true}
                />,
            ],
        };
    }

    render(): React.ReactNode {
        const { trendPanelArr } = this.state;
        return (
            <div style={{ height: "100%", overflow: "auto" }}>
                {trendPanelArr &&
                    trendPanelArr.length > 0 &&
                    trendPanelArr.map((trendPanel) => trendPanel)}

                <IconButton
                    style={{
                        width: 60,
                        height: 60,
                        marginLeft: 70,
                    }}
                    iconProps={{
                        iconName: "AppIconDefaultAdd",
                        styles: { root: { fontSize: 60 } },
                    }}
                    onClick={() => {
                        const newIndex = this._incrementIndex();
                        trendPanelArr.push(
                            <BenchmarkTrendPanel
                                panelIndex={newIndex}
                                key={newIndex}
                                closeHandler={this._onCloseTrendPanel}
                            />
                        );

                        this.setState({ trendPanelArr: trendPanelArr });
                    }}
                ></IconButton>
            </div>
        );
    }

    private _onCloseTrendPanel(panelIndex: number) {
        const { trendPanelArr } = this.state;
        this.setState({
            trendPanelArr: trendPanelArr.filter(
                (p) => p.key !== panelIndex.toString()
            ),
        });
    }

    private _incrementIndex() {
        return this._trendIndex++;
    }

    public componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
}
