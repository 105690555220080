import { Dataset, EvalInfoKeys, PAGE_SIZE, RecordDetail } from ".";
import { vdiRequest } from "../Utils/fetch";
import {
    IRecordDetailResponse,
    IRecordRequest,
    IRecordResponse,
    RecordDetailImpl,
    RecordImpl,
} from "./RecordImpl";

export interface IVerticalTlmRecordDetail extends IRecordDetailResponse {
    alias: string;
    type: string;
    vertical: string;
    algorithm: string;
    startDate: number;
    endDate: number;
    storageRoot: string;
    creator: string;
    lastUpdateTimestamp: number;
}

class VerticalTlmRecordDetail extends RecordDetailImpl<
    VerticalTlmRecord,
    IVerticalTlmRecordDetail
> {
    get alias(): string {
        return this._detail.alias;
    }

    get type(): string {
        return this._detail.type;
    }

    get vertical(): string {
        return this._detail.vertical;
    }

    get algorithm(): string {
        return this._detail.algorithm;
    }

    get startDate(): number {
        return this._detail.startDate;
    }

    get endDate(): number {
        return this._detail.endDate;
    }

    get storageRoot(): string {
        return this._detail.storageRoot;
    }

    get creator(): string {
        return this._detail.creator;
    }

    get lastUpdateTimestamp(): number {
        return this._detail.lastUpdateTimestamp;
    }

    get dataset(): Dataset {
        throw new Error("Not supported currently");
    }

    get name(): string {
        return this._record.id;
    }

    async fetchFileList(name: string): Promise<string[]> {
        throw new Error("Not supported currently");
    }

    composeMetricUrl(name: string): string {
        throw new Error("Not supported currently");
    }
}

export class VerticalTlmRecord extends RecordImpl<IVerticalTlmRecordDetail> {
    private _details: VerticalTlmRecordDetail[] = [];
    private _defaultDetail: VerticalTlmRecordDetail | undefined;
    private _favorite: boolean = false;
    private _initDetails: boolean = false;

    constructor(data: IRecordResponse<IVerticalTlmRecordDetail>) {
        super(data);
        this.initDetails();
    }

    get favorite(): boolean {
        return this._favorite;
    }
    set favorite(fav: boolean) {
        this._favorite = fav;
    }

    get name(): string {
        return this.id;
    }

    get alias(): string {
        return this._defaultDetail?.alias ?? "";
    }

    get type(): string {
        return this._defaultDetail?.type ?? "";
    }

    get vertical(): string {
        return this._defaultDetail?.vertical ?? "";
    }

    get algorithm(): string {
        return this._defaultDetail?.algorithm ?? "";
    }

    get timeRange(): string {
        if (this._defaultDetail) {
            return `${new Date(this._defaultDetail?.startDate)
                .toISOString()
                .slice(0, -17)} - ${new Date(this._defaultDetail?.endDate)
                .toISOString()
                .slice(0, -17)}`;
        } else {
            return "";
        }
    }

    get storageRoot(): string {
        return this._defaultDetail?.storageRoot ?? "";
    }

    get creator(): string {
        return this._defaultDetail?.creator ?? "";
    }

    get lastUpdateTimestamp(): number {
        return this._defaultDetail?.lastUpdateTimestamp ?? 0;
    }

    getDetails(): RecordDetail[] {
        this._assertInit();

        if (this._detailsFilter !== undefined) {
            return this._details.filter(this._detailsFilter);
        } else {
            return this._details;
        }
    }

    getDatasets(): [] {
        return [];
    }

    async initDetails(): Promise<boolean> {
        if (!this._initDetails) {
            this._details = this._data.details
                .filter((detail) => detail)
                .map((detail) => {
                    return new VerticalTlmRecordDetail(detail, this);
                });

            this._defaultDetail = this._details.find((detail) => detail);
            this._initDetails = true;
        }

        return true;
    }

    asEvalInfoCard(): {
        name: string;
        value: string | string[];
        width: number;
    }[] {
        // prettier-ignore
        return [
            { name: EvalInfoKeys.DatasetName,     value: this.alias,           width: 200 },
            { name: EvalInfoKeys.DatasetType,     value: this.type,            width: 90  },
            { name: EvalInfoKeys.RuntimeVersion,  value: this.runtimeVersion,  width: 120 },
            { name: EvalInfoKeys.TimeRange,       value: this.timeRange,       width: 140 },
            { name: EvalInfoKeys.BlobPath,        value: this.storageRoot,     width: 120 },
            { name: EvalInfoKeys.Creator,         value: this.creator,         width: 100 },
            { name: EvalInfoKeys.CreateTime,      value: this.createTime,      width: 150 },
            { name: EvalInfoKeys.Tag,             value: this.tags,            width: 260 },
        ];
    }

    static async fetchAll(
        workspace: string,
        pageIndex: number = 1,
        searchs: string = "",
        limit: number = PAGE_SIZE,
        favRecordIds: string[] | null = null
    ): Promise<VerticalTlmRecord[]> {
        let url = `/api/eval/${workspace}/records?${searchs}&pageSize=${limit}&pageIndex=${pageIndex}`;
        if (favRecordIds && favRecordIds.length > 0) {
            url += `&favRecordIds=${favRecordIds.join(",")}`;
        }

        return vdiRequest(url, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IVerticalTlmRecordDetail>[]) => {
                return data.map((item) => new VerticalTlmRecord(item));
            });
    }

    static async fetch(
        workspace: string,
        id: string
    ): Promise<VerticalTlmRecord> {
        const url = `/api/eval/${workspace}/records/${id}`;
        return vdiRequest(url, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IVerticalTlmRecordDetail>) => {
                return new VerticalTlmRecord(data);
            });
    }

    static async addRecord(
        workspace: string,
        recordRequest: IRecordRequest
    ): Promise<VerticalTlmRecord> {
        const url = `/api/eval/${workspace}/records`;
        return vdiRequest(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(recordRequest),
        })
            .then((response) => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.name = response.status.toString();
                    throw error;
                }
                return response.json();
            })
            .then((data: IRecordResponse<IVerticalTlmRecordDetail>) => {
                return new VerticalTlmRecord(data);
            });
    }

    static async addRecordDetails(
        id: string,
        workspace: string,
        recordDetails: any[]
    ): Promise<VerticalTlmRecord> {
        const url = `/api/eval/${workspace}/records/${id}/details`;
        return vdiRequest(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(recordDetails),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IVerticalTlmRecordDetail>) => {
                return new VerticalTlmRecord(data);
            });
    }

    private _assertInit() {
        if (!this._initDetails) {
            throw new Error("Vertical Telemetry Record is not initialized.");
        }
    }
}
