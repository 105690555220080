import React from "react";
import "../Common/MetricStyle.scss";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import {
    TableColumn,
    TableList,
    ColumnValueType,
    NumContrastPolicy,
    NumberFormat,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { optimizeDecimalPlaces } from "../../Utils";
import { from } from "linq-to-typescript";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import _ from "lodash";
import { Options } from "./OverviewConfig";

interface ImageMetric extends IMetrics<IMetricUnit> {
    DocName: string;
    Accuracy: string;
    InferenceLatency: string;
    ocrToyUrl: string;
}

interface IState extends IMetricState<ImageMetric> {
    selectLanguage?: string;
    selectOption1?: string;
    selectOption2?: string;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
    toSelectOption1?: string;
    toSelectOption2?: string;
}

// prettier-ignore
const CUSTOMFORM_IMAGE_COLUMNS: TableColumn[] = [
    { key: "DocName",           name: "DocName",            fieldName: "DocName",           isKey: true,    valueType: ColumnValueType.String,   minWidth: 300,  maxWidth: 350, isResizable: true, },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "Accuracy",          isKey: false,   valueType: ColumnValueType.Number,   minWidth: 250,  maxWidth: 350, isResizable: true, contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  numberFormat:NumberFormat.Percentage,  maxDecimalPlaces:2  },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "InferenceLatency",  isKey: false,   valueType: ColumnValueType.Number,   minWidth: 250,  maxWidth: 250, isResizable: true, maxDecimalPlaces:2 },
    { key: "ocrToyUrl",         name: "OcrToyUrl",          fieldName: "ocrToyUrl",         isKey: false,   valueType: ColumnValueType.Url,      minWidth: 200,  maxWidth: 250, isResizable: true, },
];

export class DetailView extends MetricsView<IProps, IState, ImageMetric> {
    private optionKey1 = Options[this.workSpace].option1;
    private optionKey2 = Options[this.workSpace].option2;
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            selectLanguage: props.toSelectLanguage,
            selectOption1: props.toSelectOption1,
            selectOption2: props.toSelectOption2,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.CustomForm}_${Typename.DetailView}`,
                columns: CUSTOMFORM_IMAGE_COLUMNS,
            })
        );
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectedColumns } = this.state;

        const columns: TableColumn[] = CUSTOMFORM_IMAGE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const tableKey = `${this.state.selectLanguage}_${this.state.selectOption1}_${this.state.selectOption2}`;
        return (
            <FullScreen>
                <TableList<ImageMetric>
                    key={tableKey}
                    evalDataCount={records.length}
                    evalData={evalData}
                    columns={columns}
                    downloadTableTitle={tableKey}
                    renderTableHeader={this._renderTableHeader}
                    isWiderCell={true}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </FullScreen>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<ImageMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<ImageMetric>>(metricName)
            .then(([record, metrics]) => {
                let items: any = {};
                Object.entries(metrics).forEach(([_, val]) => {
                    const evalAlgo =
                        recordDetail.getRawProp<string>("algorithm");
                    const argu = recordDetail.getRawProp<any>("arguments");

                    const evaluator = `${evalAlgo}(${argu[this.optionKey1]},${
                        argu[this.optionKey2]
                    })`;
                    val.Accuracy = optimizeDecimalPlaces(
                        Number(val.Accuracy),
                        4
                    ).toString();
                    val.InferenceLatency = optimizeDecimalPlaces(
                        Number(val.InferenceLatency),
                        3
                    ).toString();
                    val.ocrToyUrl =
                        val.DocName.endsWith(".tif") ||
                        val.DocName.endsWith(".tiff") ||
                        val.DocName.endsWith(".pdf")
                            ? `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.page1.html`
                            : `http://visual-invoice.westus3.cloudapp.azure.com:5000/customform/${record.id}/${recordDetail.dataset.name}/${evaluator}/${val.DocName}/${val.DocName}.html`;
                    items[val.DocName] = val;
                });
                return items;
            });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged();
    }

    _onEvaluationRecordChanged() {
        const {
            toSelectLanguage,
            toSelectOption1: toSelectDocCount,
            toSelectOption2: toSelectDocIndex,
        } = this.props;
        const languageList = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        if (languageList.length > 0) {
            const language =
                toSelectLanguage && languageList.includes(toSelectLanguage)
                    ? toSelectLanguage
                    : languageList[0];

            const trainingDocCountList = this._getTrainingArgumentList(
                language,
                this.optionKey1
            );
            const trainingDocIndexList = this._getTrainingArgumentList(
                language,
                this.optionKey2,
                trainingDocCountList[0]!
            );

            let stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: language,
                selectOption1: toSelectDocCount ?? trainingDocCountList[0],
                selectOption2: toSelectDocIndex ?? trainingDocIndexList[0],
            };

            this._onOptionsChanged(stateSettings);
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _getTrainingArgumentList(
        selectLanguage: string,
        metricsName: string,
        selectOption1: string = ""
    ) {
        const trainingArguements = this._getTrainingArguments(selectLanguage);
        const distinctArgues = trainingArguements.flatMap(
            (trainingArgue) => trainingArgue
        );

        if (metricsName === this.optionKey1) {
            return Array.from(
                new Set(
                    distinctArgues.map((argue) =>
                        String(argue[this.optionKey1])
                    )
                )
            );
        } else if (metricsName === this.optionKey2) {
            const argueListWithGivenDocCount = distinctArgues.filter(
                (argue) => String(argue[this.optionKey1]) === selectOption1
            );

            return from(
                new Set(
                    argueListWithGivenDocCount.map(
                        (argue) => argue[this.optionKey2]
                    )
                )
            )
                .orderBy((i) => i)
                .toArray()
                .map((i) => String(i));
        }
        return [];
    }

    private _getTrainingArguments(selectLanguage: string) {
        const filteredRecordDetails = this.props.records.map((record) => {
            return record
                .getDetails()
                .filter((detail) => detail.dataset.name === selectLanguage);
        });
        return filteredRecordDetails.map((details) => {
            return details.map((d) => {
                return d.getRawProp<any>("arguments");
            });
        });
    }

    private _onQueryButtonClicked = () => {
        const { selectOption1, selectOption2, selectLanguage } = this.state;
        const { records } = this.props;
        if (records.length > 0 && selectLanguage) {
            const details = this._filterCustomFormRecordDetails(
                selectLanguage,
                selectOption1!,
                selectOption2!
            );
            this.showEvaluationResult(details, "doc_results.json");
        }
    };

    private _filterCustomFormRecordDetails(
        selectLanguage: string,
        selectOption1: string,
        selectOption2: string
    ): RecordDetail[] {
        return this.props.records.map((record) => {
            return record.getDetails().filter((detail) => {
                const argu = detail.getRawProp<any>("arguments");
                return (
                    detail.dataset.name === selectLanguage &&
                    String(argu[this.optionKey1]) === selectOption1 &&
                    String(argu[this.optionKey2]) === selectOption2
                );
            })[0];
        });
    }

    private _onOptionsChanged(
        newOptions: {
            [key: string]: string | undefined;
        },
        timeout: number = 1000
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        let selectOption1 =
            "selectOption1" in newOptions
                ? newOptions["selectOption1"]
                : this.state.selectOption1;
        let selectOption2 =
            "selectOption2" in newOptions
                ? newOptions["selectOption2"]
                : this.state.selectOption2;
        setTimeout(() => {
            if (
                selectOption2 === this.state.selectOption2 &&
                selectOption1 === this.state.selectOption1 &&
                selectLanguage === this.state.selectLanguage
            ) {
                this._onQueryButtonClicked();
            }
        }, timeout);
        this.setState({
            selectOption2: selectOption2,
            selectOption1: selectOption1,
            selectLanguage: selectLanguage,
        });
    }

    private _renderTableHeader(): JSX.Element {
        let languages = this.getLanguageList(false).map(
            (language) => language.split(":")[1]
        );
        const trainingDocCountList = this._getTrainingArgumentList(
            this.state.selectLanguage ?? languages[0],
            this.optionKey1
        );
        const trainingDocIndexList = this._getTrainingArgumentList(
            this.state.selectLanguage ?? languages[0],
            this.optionKey2,
            this.state.selectOption1 ?? trainingDocCountList[0]
        );
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "DatasetFullName:",
                options: languages,
                selectedKey: this.state.selectLanguage ?? languages[0],
                onChange: (language) => {
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: this.optionKey1,
                text: `${_.upperFirst(this.optionKey1)}:`,
                options: trainingDocCountList,
                selectedKey:
                    this.state.selectOption1 ?? trainingDocCountList[0],
                onChange: (docCount) => {
                    this._onOptionsChanged!({
                        selectOption1: docCount!.text,
                    });
                },
            },
            {
                key: this.optionKey2,
                text: `${_.upperFirst(this.optionKey2)}:`,
                options: trainingDocIndexList,
                selectedKey:
                    this.state.selectOption2 ?? trainingDocIndexList[0],
                onChange: (docIndex) => {
                    this._onOptionsChanged!({
                        selectOption2: docIndex!.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }
}
