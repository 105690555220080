import {
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    IIconProps,
    IconButton,
    Label,
    PrimaryButton,
    Stack,
    TextField,
} from "@fluentui/react";
import React from "react";
import { Workspaces } from "../DataContract";
import { vdiRequest } from "../Utils/fetch";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};
export class RedisClearPage extends React.Component<any, any> {
    private workSpacesOptions: IDropdownOption[];
    constructor(props: any) {
        super(props);
        this.workSpacesOptions = Object.values(Workspaces)
            .sort()
            .map((v) => {
                return { key: v, text: v };
            });
        this.state = {
            selectedKey: this.workSpacesOptions[0].key,
            resultText: "",
        };
    }
    render() {
        const { selectedKey, resultText } = this.state;
        const clearIcon: IIconProps = { iconName: "Clear" };

        return (
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: "50px" }}>
                <h1>clear cache</h1>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    styles={{
                        root: {
                            width: 500,
                        },
                    }}
                >
                    <TextField
                        label="Record Id"
                        styles={{
                            root: {
                                width: "100%",
                            },
                            wrapper: {
                                display: "flex",
                                width: "100%",
                                Label: {
                                    width: "100px",
                                },
                            },
                        }}
                        value={this.state.recordId}
                        onChange={(e, newValue) =>
                            this.setState({
                                recordId: newValue,
                                resultText: "",
                            })
                        }
                    />
                    <IconButton
                        iconProps={clearIcon}
                        title="clear RecordId"
                        onClick={() =>
                            this.setState({ recordId: "", resultText: "" })
                        }
                    />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        styles={dropdownStyles}
                        options={this.workSpacesOptions}
                        selectedKey={selectedKey}
                        onChange={this._onChange}
                    />

                    <PrimaryButton text="Clear" onClick={this._alertClicked} />
                </Stack>
                <Label>{resultText}</Label>
            </Stack>
        );
    }

    _onChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) => {
        if (option) {
            this.setState({
                selectedKey: option.key,
                resultText: "",
            });
        }
    };

    _alertClicked = () => {
        const { recordId } = this.state;
        let api = `/api/eval/${this.state.selectedKey}/records/clearcache`;
        if (recordId) {
            api = `${api}?id=${recordId}`;
        }
        vdiRequest(api).then((reault) => {
            reault.text().then((t: any) =>
                this.setState({
                    resultText: t,
                })
            );
        });
    };
}
