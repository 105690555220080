import React from "react";
import {
    DatasetSet,
    IDataItem,
    Typename,
    Workspaces,
} from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
    TableList,
} from "../../Controls";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { FullScreen } from "../Common/FullScreen";

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",          name: "Category",                      fieldName: "category",           valueType: ColumnValueType.String,             minWidth: 120,   maxWidth: 140, isResizable: true, distinctStr: true },
    { key: "barcodeCount",      name: "Barcode Count",                 fieldName: "barcodeCount",       valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
    { key: "correct",           name: "Correct",                       fieldName: "correct",            valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 140, isResizable: true, maxDecimalPlaces: 3, contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,},
    { key: "deletion",          name: "Deletion Error",                fieldName: "deletion",           valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
    { key: "insertion",         name: "Insertion Error",               fieldName: "insertion",          valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
    { key: "substitute_format", name: "Substitution Error (Type)",     fieldName: "substitute_format",  valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 180, isResizable: true, maxDecimalPlaces: 3 },
    { key: "substitute_content",name: "Substitution Error (Content)",  fieldName: "substitute_content", valueType: ColumnValueType.Number,             minWidth: 120,   maxWidth: 180, isResizable: true, maxDecimalPlaces: 3 },
    { key: "note",              name: "Note",                          fieldName: "note",               valueType: ColumnValueType.StringWithCopyBtn,  minWidth: 80,    maxWidth: 120, isResizable: true, },
];

interface BarcodeAccuracyMetric {
    category: string;
    correct: number;
    deletion: number;
    insertion: number;
    substitute_format: number;
    substitute_content: number;
    note: string;
    barcodeCount?: number;
    datasetName?: string;
}

interface IState extends ICommonState<BarcodeAccuracyMetric> {
    dataItems: IDataItem<BarcodeAccuracyMetric[]>[];
}

export class OcrBarcodeGeneralMetricsOverview extends CommonView<
    ICommonProps,
    IState,
    BarcodeAccuracyMetric
> {
    constructor(props: ICommonProps) {
        super(props);

        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.state = {
            dataItems: [],
            matchDatasetVersion: true,
            expandItem: new Map<number, boolean>(),
        };
    }

    render(): React.ReactNode {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "hidden auto" }}>
                    {this._renderOverviewTable()}
                </div>
            </FullScreen>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("accuracy/barcode-accuracy-metrics.json");
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrBarcode}_${Typename.GeneralMetrics}_Overview`,
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            OVERVIEW_COLUMNS,
            "BarcodeGeneralMetricsOverview"
        );
    };

    private _renderOverviewTable(): React.ReactNode {
        const { isDarkTheme, records } = this.props;
        const { expandItem, matchDatasetVersion, selectedColumns } = this.state;
        const data = this._prepareOverviewRenderData(matchDatasetVersion!);
        const columns: TableColumn[] = OVERVIEW_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        this.exportData = [];

        const elementsToRender: JSX.Element[] = [];
        if (data && data.length > 0) {
            const overviewTables = data.map(
                ([datasetName, dataMetrics], index) => {
                    let overallMetrics: BarcodeAccuracyMetric[] | undefined;
                    const evalData = new Map<string, BarcodeAccuracyMetric[]>();

                    dataMetrics.forEach(([category, metrics]) => {
                        if (category === "overall") {
                            overallMetrics = metrics;
                        } else {
                            evalData.set(category, metrics);
                        }
                    });

                    overallMetrics && evalData.set("overall", overallMetrics);
                    const evalValues = Array.from(evalData).map(([k, v]) => v);
                    this.exportData.push([datasetName, evalValues]);

                    return (
                        <React.Fragment key={datasetName}>
                            <ExpandCard
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={isDarkTheme}
                            >
                                <TableList<BarcodeAccuracyMetric>
                                    evalDataCount={records.length}
                                    evalData={evalData}
                                    columns={columns}
                                    isDarkTheme={isDarkTheme}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    onItemInvoked={this._onItemInvoked}
                                    hideHeader
                                    disableFreezeHeader
                                    disableVirtualize
                                />
                            </ExpandCard>
                        </React.Fragment>
                    );
                }
            );

            elementsToRender.push(...overviewTables);
        }

        return elementsToRender;
    }

    private _prepareOverviewRenderData(matchDatasetVersion: boolean) {
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);
        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        let metrics = item?.metrics;
                        if (metrics) {
                            if (!(metrics instanceof Array)) {
                                metrics = [metrics];
                            }
                            return metrics.map((metric) => metric.category);
                        }
                        return [];
                    })
                )
            );

            const groupData = categories.map((category) => {
                const categoryMetrics = items.map((item) => {
                    let accuracyMetric: BarcodeAccuracyMetric | undefined;
                    if (item && item.metrics) {
                        let { metrics } = item;
                        if (!(metrics instanceof Array)) {
                            metrics = [metrics];
                        }
                        accuracyMetric = metrics.find(
                            (metric) => metric.category === category
                        );
                        if (accuracyMetric) {
                            accuracyMetric.barcodeCount =
                                accuracyMetric.correct +
                                accuracyMetric.deletion +
                                accuracyMetric.substitute_format +
                                accuracyMetric.substitute_content;
                            accuracyMetric.datasetName = matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName;
                        }
                    }

                    if (accuracyMetric === undefined) {
                        accuracyMetric = {
                            category: category,
                            barcodeCount: NaN,
                            correct: NaN,
                            deletion: NaN,
                            insertion: NaN,
                            substitute_format: NaN,
                            substitute_content: NaN,
                            note: "",
                            datasetName: "",
                        };
                    }

                    return accuracyMetric;
                });
                return [category, categoryMetrics] as [
                    string,
                    BarcodeAccuracyMetric[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [string, BarcodeAccuracyMetric[]][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const fieldMetricsArr = values[1] as BarcodeAccuracyMetric[];
            const keyFieldMetric = fieldMetricsArr.find(
                (fieldMetric) => fieldMetric.category && fieldMetric.datasetName
            );
            if (keyFieldMetric) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = keyFieldMetric.datasetName;
                linkData.toSelectCategory =
                    keyFieldMetric.category !== "overall"
                        ? keyFieldMetric.category
                        : undefined;
                this.deepLinkHandler("ByImage", linkData);
            }
        }
    }

    private deepLinkHandler(key: string, linkData: any): void {
        const { setSelectedSubPivot } = this.props;
        store.dispatch(updateSelectSubpivoteAction(key));
        setSelectedSubPivot && setSelectedSubPivot(key, linkData);
    }
}
