import React, { Component } from "react";
import * as d3 from "d3";
import {
    Callout,
    DocumentCardDetails,
    DocumentCardTitle,
    getTheme,
    IconButton,
    IIconProps,
    ITextFieldStyles,
    Label,
    Slider,
    Stack,
    TextField,
} from "@fluentui/react";
import "./VerticalLineChart.scss";

interface ILineChartDataPoint {
    x: number | Date;
    y: number;
}
interface ILineChartData {
    legend: string;
    data: ILineChartDataPoint[];
    color: string;
}

interface IState {
    legendPoints: any[];
    showScrollX: boolean;
    visibleCalloutId: number;
}
export interface IGroupedLineChartData {
    chartTitle: string;
    lineChartData: ILineChartData[];
}
interface IProps {
    id: string;
    data: IGroupedLineChartData;
    height: number;
    width?: number;
    keyIndex?: number;
    xRange?: number;
    yAxisFrom?: number;
    yAxisTo?: number;
    YMax?: number;
    isDarkTheme?: boolean;
    sliderOnChange?: (slideVal: number) => void;
    changeYAxisRange?: (from: number, to: number) => void;
}

const CHART_MARGIN = {
    left: 45,
    top: 40,
    right: 10,
    bottom: 90,
};

const menuIcon: IIconProps = {
    iconName: "Edit",
    style: {
        fontSize: 18,
    },
};

const narrowTextFieldStyles: Partial<ITextFieldStyles> = {
    root: { padding: 5 },
    wrapper: { width: 100 },
    fieldGroup: { width: 100 },
};

const theme = getTheme();

export class VerticalLineChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._drawChart = this._drawChart.bind(this);

        this.state = {
            legendPoints: [],
            showScrollX: false,
            visibleCalloutId: -1,
        };
    }
    public componentDidMount() {
        this._drawChart();
        window.addEventListener("resize", (e) => this._drawChart());
    }
    public componentWillUnmount() {
        window.removeEventListener("resize", (e) => this._drawChart());
    }
    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this._drawChart();
        }
    }
    private _calloutTarget: HTMLElement | null | undefined;
    public render() {
        const { keyIndex = 0 } = this.props;
        let xRange = this.props.data.lineChartData[keyIndex].data.map(
            (d) => d.x as number
        );
        let xNumber = Math.max(...xRange);

        return (
            <DocumentCardDetails
                key={`document_${keyIndex}`}
                className={"documentcard_Latency"}
            >
                <DocumentCardTitle
                    className="overview__title_latency"
                    title={`${this.props.data.chartTitle}`}
                />
                <Stack className="overview__mani_latency" horizontal>
                    <div className="seriesDesc">
                        {this.props.data.lineChartData.map((series, index) => {
                            return (
                                <div
                                    key={`legend_${index}`}
                                    className="desc"
                                    style={{
                                        color: this.props.isDarkTheme
                                            ? "white"
                                            : "black",
                                    }}
                                >
                                    <i
                                        style={{
                                            backgroundColor: series.color,
                                        }}
                                    ></i>
                                    <span>{series.legend}</span>
                                </div>
                            );
                        })}
                    </div>
                    <Stack horizontal verticalAlign="center">
                        <div
                            style={{
                                marginRight: "10px",
                                color: "grey",
                            }}
                        >
                            |
                        </div>
                        <Label>X Range:</Label>
                        <Slider
                            step={10}
                            min={0}
                            max={xNumber}
                            defaultValue={xNumber}
                            styles={{
                                root: {
                                    width: "280px",
                                    marginRight: "0",
                                },
                                valueLabel: {
                                    width: "auto",
                                },
                            }}
                            onChange={(value: number) =>
                                this.props.sliderOnChange!(value)
                            }
                        />
                    </Stack>
                </Stack>
                <Stack
                    style={{
                        backgroundColor: this.props.isDarkTheme
                            ? theme.palette.neutralDark
                            : theme.palette.neutralLighterAlt,
                    }}
                    key={`latency_dropdown_${keyIndex}`}
                >
                    <IconButton
                        menuIconProps={menuIcon}
                        className="editYIcon"
                        onClick={(evt) => {
                            this._calloutTarget = evt.target as HTMLElement;
                            this.setState({
                                visibleCalloutId: keyIndex,
                            });
                        }}
                    />
                    {this.state.visibleCalloutId === keyIndex &&
                        this._onCalloutRender(keyIndex)}
                    <div
                        className="drawingContainer"
                        style={{
                            color: this.props.isDarkTheme ? "white" : "black",
                        }}
                    >
                        <div
                            id={this.props.id}
                            className="drawingBoard"
                            style={{
                                color: this.props.isDarkTheme
                                    ? "white"
                                    : "black",
                            }}
                        ></div>
                    </div>
                </Stack>
            </DocumentCardDetails>
        );
    }
    private _drawChart(): void {
        const { keyIndex = 0 } = this.props;
        let drawingBoard = document.getElementById(this.props.id);
        if (drawingBoard && drawingBoard.hasChildNodes()) {
            drawingBoard.innerHTML = "";
        }
        let width = this.props.width;
        if (width === undefined) {
            width = CHART_MARGIN.left + CHART_MARGIN.right;
            const container = drawingBoard?.parentElement;
            if (container && container?.offsetWidth > width) {
                width = container.offsetWidth - 1;
            }
        }
        const lineData = this.props.data.lineChartData.map((item) => {
            let itemWithFilter = item.data.filter((it) => {
                return (
                    it.x >= 0 &&
                    it.x <= this.props.xRange! &&
                    it.y >= this.props.yAxisFrom! &&
                    it.y <= this.props.yAxisTo!
                );
            });
            return {
                color: item.color,
                legend: item.legend,
                data: itemWithFilter,
            };
        });
        const lineChartData: ILineChartDataPoint[] =
            this.props.data.lineChartData.flatMap((item) => {
                return item.data;
            });

        let height = 250;
        let margin = { top: 30, right: 20, bottom: 20, left: 50 },
            innerWidth = width - margin.left - margin.right,
            innerHeight = height - margin.top - margin.bottom;
        const xValue = (d: ILineChartDataPoint): number | Date => d.x;
        const yValue = (d: ILineChartDataPoint): number => d.y;

        let minX = Number(d3.min(lineData[keyIndex].data, xValue)) - 1 || 0;
        let maxX = this.props.xRange || 0;
        let minY = this.props.yAxisFrom || 0;
        let maxY = this.props.yAxisTo || 0;
        let scale_x = d3
            .scaleLinear()
            .domain([minX, maxX])
            .range([0, innerWidth]);
        let scale_y = d3
            .scaleLinear()
            .domain([maxY, minY])
            .range([0, innerHeight]);

        let line = d3
            .line<ILineChartDataPoint>()
            .x((d: ILineChartDataPoint) => {
                return scale_x(xValue(d) || 0);
            })
            .y((d: ILineChartDataPoint) => {
                return scale_y(yValue(d) || 0);
            });

        let tip = d3
            .select("body")
            .append("div")
            .attr("class", "tip")
            .style("border", "1px solid steelblue")
            .style("padding", "5px")
            .style("position", "absolute")
            .style("display", "none");

        const svg = d3
            .select("#" + this.props.id)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const lineCount = lineData.length;

        for (let i = 0; i < lineCount; i++) {
            svg.append("g")
                .data(lineData)
                .attr("transform", "translate(50,30)")
                .append("path")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr("stroke", function () {
                    return lineData[i].color;
                })
                .attr("d", function () {
                    return line(lineData[i].data) || "";
                });
        }

        svg.append("g")
            .attr("transform", "translate(50,30)")
            .selectAll("dot")
            .attr("transform", "translate(50,30)")
            .data(lineChartData)
            .enter()
            .append("circle")
            .attr("r", 3)
            .attr("fill", this.props.isDarkTheme ? "white" : "black")
            .attr("cx", function (d, i) {
                return scale_x(d.x);
            })
            .attr("cy", function (d, i) {
                return scale_y(d.y);
            })
            .on("mouseover", function (e: any, d: any) {
                const event = e as MouseEvent;
                const data = d as ILineChartDataPoint;
                tip.style("top", event.pageY + "px")
                    .style("left", event.pageX + "px")
                    .style("display", "block")
                    .style("background-color", lineData[0].color)
                    .style("color", "yellow")
                    .html(data.y.toString());
            })
            .on("mouseout", function (d, i) {
                tip.style("display", "none");
            });

        let xAxis = d3.axisBottom(scale_x);
        let yAxis = d3.axisLeft(scale_y).ticks(20, "s");

        svg.append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" +
                    margin.left +
                    "," +
                    (height - margin.bottom) +
                    ")"
            )
            .call(xAxis);

        svg.append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" + (margin.left + 5) + "," + margin.top + ")"
            )
            .call(yAxis);

        let yInner = d3.axisLeft(scale_y).tickSize(-width);

        svg.append("g")
            .attr("class", "inner_line inner_line_y")
            .attr("transform", "translate(" + margin.left + ",30)")
            .style("opacity", 0.08)
            .call(yInner);
        let xInner = d3.axisBottom(scale_x).tickSize(-height);
        svg.append("g")
            .attr("div", "inner_line_x")
            .attr(
                "transform",
                "translate(" +
                    margin.left +
                    "," +
                    (height - margin.top + 15) +
                    ")"
            )
            .style("opacity", 0.08)
            .call(xInner);
    }
    private _onCalloutRender = (index: any): any => {
        return (
            <Callout
                key={`callout_${index}`}
                target={this._calloutTarget!}
                onDismiss={this._onCalloutDismiss}
            >
                <Stack horizontal verticalAlign="center">
                    <TextField
                        value={String(this.props.yAxisFrom!)}
                        onChange={(_event, val) => {
                            this.props.changeYAxisRange!(
                                Number(val),
                                this.props.yAxisTo!
                            );
                        }}
                        borderless={true}
                        underlined={true}
                        styles={narrowTextFieldStyles}
                        placeholder={"0"}
                    />
                    ~
                    <TextField
                        value={String(this.props.yAxisTo!)}
                        onChange={(_event, val) => {
                            this.props.changeYAxisRange!(
                                this.props.yAxisFrom!,
                                Number(val)
                            );
                        }}
                        borderless={true}
                        underlined={true}
                        styles={narrowTextFieldStyles}
                        placeholder={String(this.props.YMax)}
                    />
                </Stack>
            </Callout>
        );
    };

    private _onCalloutDismiss = (): void => {
        this.setState({
            visibleCalloutId: -1,
        });
    };
}
