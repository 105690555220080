import * as React from "react";
import { IColumn, Pivot, PivotItem } from "@fluentui/react";
import { Catalog } from "../Controls";
import {
    Record,
    URL_PARAM_NAME_VIEWTYPE,
    WorkspaceGroups,
    Workspaces,
} from "../DataContract";
import { setParamsToUrl } from "../Utils";
import { VerticalTrend } from "../Views/Vertical/VerticalTrend";
import { BenchmarkTrendPage } from "../Views/Ocr/Trend/BenchmarkTrendPage";
import { capitalize } from "lodash";
import { LoadingCoverIcon } from "../Controls/LoadingcoverIcon";

// prettier-ignore
const OcrColumns: IColumn[] = [
    { key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 20, isResizable: true },
    { key: "modelInfo", name: "ModelInfo", fieldName: "modelInfo", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "runtimeVersion", name: "RuntimeVersion", fieldName: "runtimeVersion", minWidth: 100, maxWidth: 150, isResizable: true },
    { key: "algos", name: "Algorithm", fieldName: "algos", minWidth: 50, maxWidth: 400, isResizable: true },
    { key: "langs", name: "Languages", fieldName: "langs", minWidth: 100, maxWidth: 300, isResizable: true },
    { key: "buildSource", name: "BuildSource", fieldName: "buildSource", minWidth: 50, maxWidth: 300, isResizable: true },
    { key: "testType", name: "TestType", fieldName: "testType", minWidth: 50, maxWidth: 100, isResizable: true },
    { key: "dateTime", name: "DateTime", fieldName: "dateTime", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "tags", name: "Tags", fieldName: "tags", minWidth: 120, maxWidth: 450, isResizable: true },
    { key: "favorite", name: "", fieldName: "favorite", minWidth: 50, maxWidth: 100, isResizable: true },
];

// prettier-ignore
const CustFormColumns: IColumn[] = [
    { key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 20, isResizable: true },
    { key: "modelInfo", name: "ModelInfo", fieldName: "modelInfo", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "algos", name: "Algorithm", fieldName: "algos", minWidth: 50, maxWidth: 400, isResizable: true },
    { key: "langs", name: "Languages", fieldName: "langs", minWidth: 100, maxWidth: 300, isResizable: true },
    { key: "testType", name: "TestType", fieldName: "testType", minWidth: 50, maxWidth: 100, isResizable: true },
    { key: "dateTime", name: "DateTime", fieldName: "dateTime", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "tags", name: "Tags", fieldName: "tags", minWidth: 120, maxWidth: 450, isResizable: true },
    { key: "favorite", name: "", fieldName: "favorite", minWidth: 50, maxWidth: 100, isResizable: true },
];

// prettier-ignore
const QueryFieldColumns: IColumn[] = [
    { key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 20, isResizable: true },
    { key: "modelInfo", name: "ModelInfo", fieldName: "modelInfo", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "algos", name: "Algorithm", fieldName: "algos", minWidth: 50, maxWidth: 400, isResizable: true },
    { key: "langs", name: "Languages", fieldName: "langs", minWidth: 100, maxWidth: 300, isResizable: true },
    { key: "testType", name: "TestType", fieldName: "testType", minWidth: 50, maxWidth: 100, isResizable: true },
    { key: "dateTime", name: "DateTime", fieldName: "dateTime", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "tags", name: "Tags", fieldName: "tags", minWidth: 120, maxWidth: 450, isResizable: true },
    { key: "favorite", name: "", fieldName: "favorite", minWidth: 50, maxWidth: 100, isResizable: true },
];

// prettier-ignore
const VerticalColumns: IColumn[] = [
    { key: "expRunId", name: "ExpRunId", fieldName: "expRunId", minWidth: 80, maxWidth: 120, isResizable: true },
    { key: "modelInfo", name: "ModelInfo", fieldName: "modelInfo", minWidth: 20, maxWidth: 70, isResizable: true },
    { key: "runtimeVersion", name: "RuntimeVersion", fieldName: "runtimeVersion", minWidth: 80, maxWidth: 200, isResizable: true },
    { key: "algos", name: "Algorithm", fieldName: "algos", minWidth: 80, maxWidth: 180, isResizable: true },
    { key: "dataset", name: "Dataset", fieldName: "dataset", minWidth: 220, maxWidth: 400, isResizable: true },
    { key: "stepRunId", name: "StepRunId", fieldName: "stepRunId", minWidth: 180, maxWidth: 200, isResizable: true },
    { key: "langs", name: "Languages", fieldName: "langs", minWidth: 70, maxWidth: 80, isResizable: true },
    { key: "buildSource", name: "BuildSource", fieldName: "buildSource", minWidth: 80, maxWidth: 150, isResizable: true },
    { key: "createTime", name: "CreateTime", fieldName: "createTime", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "tags", name: "Tags", fieldName: "tags", minWidth: 150, maxWidth: 400, isResizable: true },
    { key: "favorite", name: "", fieldName: "favorite", minWidth: 30, maxWidth: 30, isResizable: true },
];

// prettier-ignore
const VerticalTlmColumns: IColumn[] = [
    { key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 20, isResizable: true },
    { key: "alias", name: "Dataset Name", fieldName: "alias", minWidth: 100, maxWidth: 180, isResizable: true },
    { key: "type", name: "Dataset Type", fieldName: "type", minWidth: 80, maxWidth: 100, isResizable: true },
    { key: "vertical", name: "Vertical", fieldName: "vertical", minWidth: 60, maxWidth: 80, isResizable: true },
    { key: "algorithm", name: "Algorithm", fieldName: "algorithm", minWidth: 60, maxWidth: 100, isResizable: true },
    { key: "timeRange", name: "Time Range", fieldName: "timeRange", minWidth: 100, maxWidth: 150, isResizable: true },
    { key: "storageRoot", name: "Blob Path", fieldName: "storageRoot", minWidth: 150, maxWidth: 400, isResizable: true },
    { key: "creator", name: "Creator", fieldName: "creator", minWidth: 60, maxWidth: 100, isResizable: true },
    { key: "createTimestamp", name: "Create Time", fieldName: "createTime", minWidth: 100, maxWidth: 150, isResizable: true },
    { key: "tags", name: "Tags", fieldName: "tags", minWidth: 120, maxWidth: 250, isResizable: true },
];

// prettier-ignore
const ReleaseColumns: IColumn[] = [
    { key: "id", name: "Id", fieldName: "id", minWidth: 350, },
    { key: "tags", name: "Tag", fieldName: "tags", minWidth: 400, }
];

interface IProps {
    workspace: string;
    initSearchString?: string | null;
    onItemClicked: (item: Record) => void;
    isModal?: boolean;
    viewType?: string;
    isDarkTheme?: boolean;
}

export class Workspace extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.onPivotLinkClick = this.onPivotLinkClick.bind(this);
        this.updateTabTitle = this.updateTabTitle.bind(this);
    }

    public render() {
        const { isModal, isDarkTheme, viewType, workspace } = this.props;
        let displayComlumns: IColumn[];
        let evalTitle: string;
        let trendTitle: string = "";

        switch (workspace) {
            case Workspaces.Ocr: {
                displayComlumns = OcrColumns;
                evalTitle = "OCR Evaluation Records";
                trendTitle = "OCR Benchmark Trend";
                break;
            }
            case Workspaces.ReleaseTest: {
                displayComlumns = ReleaseColumns;
                evalTitle = "OCR Core Engine Release Test";
                break;
            }
            case Workspaces.OcrTable: {
                displayComlumns = OcrColumns;
                evalTitle = "Table Evaluation Records";
                break;
            }
            case Workspaces.OcrCheckbox: {
                displayComlumns = OcrColumns;
                evalTitle = "Checkbox Evaluation Records";
                break;
            }
            case Workspaces.OcrBackend: {
                displayComlumns = OcrColumns;
                evalTitle = "OCR Backend Evaluation Records";
                break;
            }
            case Workspaces.Invoice: {
                displayComlumns = OcrColumns;
                evalTitle = "Invoice Evaluation Records";
                break;
            }
            case Workspaces.Kvp: {
                displayComlumns = OcrColumns;
                evalTitle = "KVP Evaluation Records";
                break;
            }
            case Workspaces.CustomForm: {
                displayComlumns = CustFormColumns;
                evalTitle = "Custom Form Evaluation Records";
                break;
            }
            case Workspaces.QueryField: {
                displayComlumns = QueryFieldColumns;
                evalTitle = "Query Field Evaluation Records";
                break;
            }
            case Workspaces.CustomDocReleaseTest: {
                displayComlumns = ReleaseColumns;
                evalTitle = "Custom Document Release Test";
                break;
            }
            case Workspaces.Receipt: {
                displayComlumns = VerticalColumns;
                evalTitle = "Receipt Evaluation Records";
                trendTitle = "Receipt Trend";
                break;
            }
            case Workspaces.BusinessCard: {
                displayComlumns = VerticalColumns;
                evalTitle = "BusinessCard Evaluation Records";
                trendTitle = "BusinessCard Trend";
                break;
            }
            case Workspaces.IdCard: {
                displayComlumns = VerticalColumns;
                evalTitle = "IdCard Evaluation Records";
                trendTitle = "IdCard Trend";
                break;
            }
            case Workspaces.IndustryPrebuilt: {
                displayComlumns = VerticalColumns;
                evalTitle = "IndustryPrebuilt Evaluation Records";
                trendTitle = "IndustryPrebuilt Trend";
                break;
            }
            case Workspaces.HealthDoc: {
                displayComlumns = VerticalColumns;
                evalTitle = "HealthDoc Evaluation Records";
                trendTitle = "HealthDoc Trend";
                break;
            }
            case Workspaces.ExperimentalPrebuilt: {
                displayComlumns = VerticalColumns;
                evalTitle = "ExperimentalPrebuilt Evaluation Records";
                trendTitle = "ExperimentalPrebuilt Trend";
                break;
            }
            case Workspaces.VerticalTelemetry: {
                displayComlumns = VerticalTlmColumns;
                evalTitle = "Vertical Telemetry Records";
                break;
            }
            case Workspaces.OcrPod: {
                displayComlumns = OcrColumns;
                evalTitle = "OCR POD Records";
                break;
            }
            case Workspaces.OcrMath: {
                displayComlumns = OcrColumns;
                evalTitle = "OCR MATH Records";
                break;
            }
            case Workspaces.OcrBarcode: {
                displayComlumns = OcrColumns;
                evalTitle = "OCR Barcode Records";
                break;
            }
            case Workspaces.DocClassifier: {
                displayComlumns = OcrColumns;
                evalTitle = "Doc Classifier Records";
                break;
            }
            case Workspaces.OcrFigure: {
                displayComlumns = OcrColumns;
                evalTitle = "Figure Evaluation Records";
                break;
            }
            default: {
                displayComlumns = VerticalColumns;
                evalTitle = "Vertical Evaluation Records";
            }
        }

        if (
            !!!isModal &&
            (Workspaces.Ocr === workspace ||
                WorkspaceGroups.Taipei.includes(workspace))
        ) {
            if (!!!viewType) {
                const newUrl = setParamsToUrl([
                    [URL_PARAM_NAME_VIEWTYPE, "list"],
                ]);

                window.history.replaceState(null, "", newUrl);
                this.updateTabTitle(false);
            } else {
                this.updateTabTitle(viewType === "trend");
            }

            return (
                <Pivot
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                    defaultSelectedKey={viewType}
                    onLinkClick={this.onPivotLinkClick}
                >
                    <PivotItem
                        className="displayFlex"
                        headerText={evalTitle}
                        itemKey="list"
                    >
                        {this._renderCatalog(displayComlumns, "")}
                    </PivotItem>
                    <PivotItem
                        className="displayFlex"
                        headerText={trendTitle}
                        itemKey="trend"
                    >
                        <>
                            <LoadingCoverIcon />
                            {Workspaces.Ocr === workspace && (
                                <BenchmarkTrendPage />
                            )}
                            {WorkspaceGroups.Taipei.includes(workspace) && (
                                <VerticalTrend
                                    workspace={workspace}
                                    isDarkTheme={isDarkTheme}
                                ></VerticalTrend>
                            )}
                        </>
                    </PivotItem>
                </Pivot>
            );
        } else {
            document.title = `${capitalize(workspace)} Records`;
            return this._renderCatalog(displayComlumns, evalTitle);
        }
    }

    private _renderCatalog(
        displayComlumns: IColumn[],
        title: string
    ): JSX.Element {
        const { isModal, initSearchString, workspace, onItemClicked } =
            this.props;
        return (
            <Catalog
                workspace={workspace}
                columns={displayComlumns}
                initSearchString={initSearchString}
                title={title}
                onItemClicked={onItemClicked}
                isModal={isModal ?? false}
                isDarkTheme={this.props.isDarkTheme}
                clearCache={true}
            />
        );
    }

    private onPivotLinkClick(item?: PivotItem | undefined): void {
        if (item) {
            const newUrl = setParamsToUrl([
                [URL_PARAM_NAME_VIEWTYPE, item.props.itemKey],
            ]);

            window.history.replaceState({}, item.props.itemKey!, newUrl);

            this.updateTabTitle(item.props.itemKey === "trend");
        }
    }

    private updateTabTitle(isTrend: boolean): void {
        const { workspace } = this.props;
        if (isTrend) {
            document.title = `${capitalize(workspace)} Trend`;
        } else {
            document.title = `${capitalize(workspace)} Records`;
        }
    }
}
