import React from "react";

import { CustFormOverviewTableMetrics } from "../../Views";

import { Record, Typename, Workspaces } from "../../DataContract";
import { CustFormTableDetailMetrics } from "../../Views/CustForm/CustFormTableDetailMetrics";
import { DetailView } from "../../Views/QueryField/DetailView";
import { Overview } from "../../Views/QueryField/Overview";
import { CustFormErrorAnalysisOverall } from "../../Views/CustForm/CustFormErrorAnalysisOverall";
import { CustFormErrorAnalysisDetail } from "../../Views/CustForm/CustFormErrorAnalysisDetail";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    linkData?: any;
    deepLinkHandler?: (key: string, linkData: any) => void;
    isDarkTheme?: boolean;
}

export class CustFormPage extends React.Component<IProps, IState> {
    public render() {
        const { deepLinkHandler, linkData, records, selectedKey } = this.props;

        const linkSetting = linkData as {
            [key: string]: string | undefined;
        };
        return (
            <>
                {selectedKey === "overviewCustFormMetrics" && (
                    <Overview
                        records={records}
                        imageViewDeepLinkHandler={deepLinkHandler}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "overviewCustFormDetailMetrics" && (
                    <DetailView
                        saveSetKey={`${Workspaces.CustomForm}_${Typename.DetailView}`}
                        records={records}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectOption1={linkSetting?.toSelectOption1}
                        toSelectOption2={linkSetting?.toSelectOption2}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}

                {selectedKey === "custFormOverviewTableMetrics" && (
                    <CustFormOverviewTableMetrics
                        records={records}
                        customFormOverviewTableDeepLinkHandler={deepLinkHandler}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "custFormTableDetailsMetrics" && (
                    <CustFormTableDetailMetrics
                        saveSetKey={`${Workspaces.CustomForm}_${Typename.CustFormTableDetailMetrics}`}
                        records={records}
                        toSelectLanguage={linkSetting?.toSelectLanguage}
                        toSelectDocCount={linkSetting?.toSelectDocCount}
                        toSelectDocIndex={linkSetting?.toSelectDocIndex}
                        toSelectTableName={linkSetting?.toSelectTableName}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}

                {selectedKey === "custFormErrAnalysisOverall" && (
                    <CustFormErrorAnalysisOverall
                        records={records}
                    ></CustFormErrorAnalysisOverall>
                )}

                {selectedKey === "custFormErrAnalysisDetail" && (
                    <CustFormErrorAnalysisDetail
                        records={records}
                    ></CustFormErrorAnalysisDetail>
                )}
            </>
        );
    }
}
