import React from "react";
import { store } from "../../store";
import { Unsubscribe } from "redux";
import { Label, getTheme } from "@fluentui/react";
import { Consumer } from "../Layout";

interface IState {
    show: boolean;
}
const theme = getTheme();

export class NoDataTip extends React.Component<{}, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(props: any) {
        super(props);
        this.state = {
            show: true,
        };
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(() => {
            const reducer = store.getState().loadingReducer;

            this.setState({
                show: !(
                    reducer.isPrepareRenderData || reducer.isNetworkRequest
                ),
            });
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render() {
        const { show } = this.state;

        return (
            <>
                {show && (
                    <Consumer>
                        {(value) => {
                            return (
                                <Label
                                    style={{
                                        color: value
                                            ? theme.palette.white
                                            : theme.palette.black,
                                    }}
                                >
                                    {this.props.children}
                                </Label>
                            );
                        }}
                    </Consumer>
                )}
            </>
        );
    }
}
