import { Dictionary } from "lodash";

interface IPivotItem {
    headerText: string;
    alwaysRender: boolean;
    itemKey: string;
}

export const SCENARIO_PIVOTS: Dictionary<Array<IPivotItem>> = {
    ocr: [
        {
            headerText: "Highlight Metrics",
            alwaysRender: true,
            itemKey: "highlightMetrics",
        },
        {
            headerText: "General Metrics",
            alwaysRender: false,
            itemKey: "generalMetrics",
        },
        {
            headerText: "Entity Metrics",
            alwaysRender: false,
            itemKey: "entityMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "latencyMetrics",
        },
        {
            headerText: "Language Metrics",
            alwaysRender: false,
            itemKey: "langMetrics",
        },
        {
            headerText: "Detection Metrics",
            alwaysRender: false,
            itemKey: "detectionMetrics",
        },
        {
            headerText: "Word Align Metrics",
            alwaysRender: false,
            itemKey: "wordAlignMetrics",
        },
    ],
    ocrtable: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "basicTableMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "ocrTableLatencyMetrics",
        },
    ],
    ocrcheckbox: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "basicCheckboxMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "checkboxLatencyMetrics",
        },
    ],
    invoice: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewInvoiceMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewImageMetrics",
        },
    ],
    kvp: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewKvpMetrics",
        },
        {
            headerText: "By Document Metrics",
            alwaysRender: false,
            itemKey: "overviewKvpDocumentMetrics",
        },
        {
            headerText: "By line Metrics",
            alwaysRender: false,
            itemKey: "overviewKvpDetailMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "ocrKvpLatencyMetrics",
        },
    ],
    customform: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewCustFormMetrics",
        },
        {
            headerText: "Detail Metrics",
            alwaysRender: false,
            itemKey: "overviewCustFormDetailMetrics",
        },
        {
            headerText: "Overview Table Metrics",
            alwaysRender: false,
            itemKey: "custFormOverviewTableMetrics",
        },
        {
            headerText: "Table Detail Metrics",
            alwaysRender: false,
            itemKey: "custFormTableDetailsMetrics",
        },
        {
            headerText: "Overall Error Analysis",
            alwaysRender: false,
            itemKey: "custFormErrAnalysisOverall",
        },
        {
            headerText: "Detail Error Analysis",
            alwaysRender: false,
            itemKey: "custFormErrAnalysisDetail",
        },
    ],
    queryfield: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewQueryFieldMetrics",
        },
        {
            headerText: "Detail Metrics",
            alwaysRender: false,
            itemKey: "overviewQueryFieldDetailMetrics",
        },
    ],
    ocrbackend: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "basicBEMetrics",
        },
    ],
    releasetest: [
        {
            headerText: "Overview",
            alwaysRender: true,
            itemKey: "overviewReleaseMetrics",
        },
        {
            headerText: "Sanity Test",
            alwaysRender: false,
            itemKey: "sanityReleaseMetrics",
        },
        {
            headerText: "Vertical Sanity Test",
            alwaysRender: false,
            itemKey: "verSanityReleaseMetrics",
        },
        {
            headerText: "Form Recognizer 30 Sanity Test",
            alwaysRender: false,
            itemKey: "formRecognizer30SanityReleaseMetrics",
        },
        {
            headerText: "Cogs Test",
            alwaysRender: false,
            itemKey: "cogsReleaseMetrics",
        },
        {
            headerText: "Reliability Test",
            alwaysRender: false,
            itemKey: "reliaReleaseMetrics",
        },
        {
            headerText: "Load Test",
            alwaysRender: false,
            itemKey: "loadReleaseMetrics",
        },
        {
            headerText: "Perf Test",
            alwaysRender: false,
            itemKey: "perfReleaseMetrics",
        },
        {
            headerText: "Accuracy Test",
            alwaysRender: false,
            itemKey: "accurReleaseMetrics",
        },
    ],
    customdocreleasetest: [
        {
            headerText: "Overview",
            alwaysRender: true,
            itemKey: "overviewReleaseMetrics",
        },
        {
            headerText: "Reliability Test",
            alwaysRender: false,
            itemKey: "reliaReleaseMetrics",
        },
        {
            headerText: "Load Test",
            alwaysRender: false,
            itemKey: "loadReleaseMetrics",
        },
        {
            headerText: "Baseline Test",
            alwaysRender: false,
            itemKey: "baselineReleaseMetrics",
        },
    ],
    receipt: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewReceiptMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewReceiptFieldMetrics",
        },
    ],
    businesscard: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewBusinessCardMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewBusinessCardFieldMetrics",
        },
    ],
    idcard: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewIdCardMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewIdCardFieldMetrics",
        },
    ],
    industryprebuilt: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewIndustryMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewIndustryFieldMetrics",
        },
    ],
    healthdoc: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewHealthMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewHealthFieldMetrics",
        },
    ],
    experimentalprebuilt: [
        {
            headerText: "Overview Metrics",
            alwaysRender: true,
            itemKey: "overviewExperimentalPrebuiltMetrics",
        },
        {
            headerText: "By Field Metrics",
            alwaysRender: false,
            itemKey: "overviewExperimentalPrebuiltFieldMetrics",
        },
    ],
    verticaltlm: [
        {
            headerText: "Report detail page",
            alwaysRender: true,
            itemKey: "overviewVerticalTLM",
        },
    ],
    ocrpod: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "ocrPodGeneralMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "ocrPodLatencyMetrics",
        },
    ],
    ocrmath: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "ocrMathGeneralMetrics",
        },
        {
            headerText: "Symbol Metrics",
            alwaysRender: true,
            itemKey: "ocrMathSymbolMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "latencyMetrics",
        },
    ],
    ocrbarcode: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "ocrBarcodeGeneralMetrics",
        },
        {
            headerText: "Performance Metrics",
            alwaysRender: true,
            itemKey: "ocrBarcodePerfMetrics",
        },
    ],
    docclassifier: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "docClassifierGeneralMetrics",
        },
        {
            headerText: "By Image Metrics",
            alwaysRender: false,
            itemKey: "docClassifierByImageMetrics",
        },
    ],
    ocrfigure: [
        {
            headerText: "General Metrics",
            alwaysRender: true,
            itemKey: "basicFigureMetrics",
        },
        {
            headerText: "Latency Metrics",
            alwaysRender: false,
            itemKey: "ocrFigureLatencyMetrics",
        },
    ],
};
