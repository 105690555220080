import { vdiRequest } from "../Utils/fetch";

export interface IMetricUnit {}

export interface IMetrics<T extends IMetricUnit> {
    [key: string]: T;
}

export interface IMergedMetrics<T extends IMetricUnit> {
    [key: string]: (T | null)[];
}

export function mergeMetrics<T>(metrics: IMetrics<T>[]): IMergedMetrics<T> {
    var merged: IMergedMetrics<T> = {};
    const keys = new Set(metrics.flatMap((metric) => Object.keys(metric)));
    [...keys].forEach((key) => {
        merged[key] = metrics.map((metric) => {
            return key in metric ? metric[key as keyof IMetrics<T>] : null;
        });
    });
    return merged;
}

export async function fetchMetricsFromBE<T>(
    workspace: string,
    MetricsNameList: string[]
): Promise<T[]> {
    const beMetricsUrl = `/api/eval/${workspace}/metrics`;
    let response = await vdiRequest(beMetricsUrl, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(MetricsNameList),
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    } else {
        const result = JSON.parse(await response.text());
        return result as T[];
    }
}
