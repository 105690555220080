import React from "react";
import {
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import {
    ColumnValueType,
    ImageVisualizer,
    MetricsType,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import CSV from "csvtojson";
import _ from "lodash";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations } from "../../Controls/TableHeader";
import {
    downloadAsFileWithAssurance,
    FileType,
    GetFileName,
    resetScrollBarOfScrollablePane,
} from "../../Utils";
import { Modal } from "@fluentui/react";
import {
    OcrBarcodePage,
    OcrMetrics,
    OcrPolygons,
} from "../../Pages/Scenarios/OcrBarcodePage";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

const DEF_COL_NAMES = ["Filename", "Category"];
// prettier-ignore
const DEF_PERF_BY_IMAGE_COLUMNS: TableColumn[] = [
    { key: "filename",                                                           name: "Filename",                                                          fieldName: "filename",                                                          valueType: ColumnValueType.String, minWidth: 300, maxWidth: 430, isResizable: true, distinctStr: true, filterable: true },
    { key: "category",                                                           name: "Category",                                                          fieldName: "category",                                                          valueType: ColumnValueType.String, minWidth: 120, maxWidth: 140, isResizable: true, distinctStr: true, filterable: true },
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",                name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",               fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.TotalMs",               valueType: ColumnValueType.Number, minWidth: 120, maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",   name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",  fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.DetectedBarcodeCount",  valueType: ColumnValueType.Number, minWidth: 120, maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
    { key: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount", name: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount",fieldName: "_ocrNativePerf|NativeMetrics.BarcodeMetrics.RecognizedBarcodeCount",valueType: ColumnValueType.Number, minWidth: 120, maxWidth: 140, isResizable: true, maxDecimalPlaces: 3 },
];

interface IProps extends IMetricProps {
    isReport: boolean | undefined;
    linkData?: {
        toSelectLanguage: string;
    };
}

interface IState extends IMetricState<any> {
    matchDatasetVersion: boolean;
    clickNumber?: number;
    filterDict?: Map<string | number, any[]>;
    imageVisItems?: any[];
    linkData?: any;
}

export class OcrBarcodePerfByImageView extends MetricsView<
    IProps,
    IState,
    any
> {
    private perfOverivewColumns: TableColumn[] = [];

    constructor(props: IProps) {
        super(props);

        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._reflectPerfOverviewColumns =
            this._reflectPerfOverviewColumns.bind(this);

        const selectLanguage = props.linkData?.toSelectLanguage;

        this.state = {
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
            evalData: {},
            filterDict: new Map<string | number, any[]>(),
            selectLanguage: selectLanguage,
        };
    }

    render(): React.ReactNode {
        return this._renderImageTable();
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.setState(
                {
                    selectLanguage: this.getLanguageList(
                        this.state.matchDatasetVersion
                    )[0],
                },
                () => {
                    this.onEvaluationRecordChanged();
                }
            );
        }
    }

    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion);
    }

    queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<any>> {
        return recordDetail.fetchRawMetrics(metricName).then((metricsCSV) => {
            return CSV({ flatKeys: true })
                .fromString(metricsCSV)
                .then((metricArr) => {
                    if (this.perfOverivewColumns.length === 0) {
                        this._reflectPerfOverviewColumns(metricArr);
                    }
                    const perfImgMetricArr = metricArr.map((perfImgMetric) => {
                        perfImgMetric.imageUrl =
                            recordDetail.dataset.getImageUrl(
                                `${perfImgMetric.category}/${perfImgMetric.filename}.jpg`
                            );

                        return perfImgMetric;
                    });

                    return perfImgMetricArr;
                });
        });
    }

    showEvaluationResult(
        recordDetails: RecordDetail[],
        metricName: string,
        onSuccess?: () => void
    ) {
        Promise.all(
            recordDetails.map((detail) =>
                this.queryEvaluationResult(detail, metricName)
            )
        )
            .then((respArr) => {
                const respLen = respArr.length;
                const imgMap = new Map<string, any[]>();
                respArr.forEach((resp: any, rIndex) => {
                    const imgRawArr = resp as any[];
                    imgRawArr.forEach((imgRaw) => {
                        const key = `${imgRaw.filename}|${imgRaw.category}`;
                        const valArr = imgMap.has(key)
                            ? imgMap.get(key)!
                            : Array.from({ length: respLen }, () => {
                                  return {
                                      filename: imgRaw.filename,
                                      category: imgRaw.category,
                                  };
                              });

                        valArr[rIndex] = imgRaw;
                        imgMap.set(key, valArr);
                    });
                });

                const evalData: any = {};
                imgMap.forEach((v, k) => {
                    evalData[k] = v;
                });

                this.setState(
                    {
                        evalData: evalData,
                        entityList: [],
                    },
                    () => onSuccess && onSuccess()
                );
            })
            .catch((_err) =>
                this.setState({
                    evalData: {},
                    entityList: [],
                })
            );
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language =
                selectLanguage && languageList.includes(selectLanguage)
                    ? selectLanguage
                    : languageList[0];
            this._onOptionsChanged({
                selectLanguage: language,
            });
        }
    }

    private _requestPolygons(
        imageId?: string,
        category?: string
    ): Promise<OcrPolygons[]> {
        const { selectImageId, selectLanguage, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        const imgId = imageId ?? selectImageId;
        return OcrBarcodePage.fetchPolygons(details, imgId, category);
    }

    private _requestMetrics(
        imageId?: string,
        category?: string
    ): Promise<OcrMetrics[]> {
        const { selectImageId, selectLanguage, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        const imgId = imageId ?? selectImageId;
        return OcrBarcodePage.fetchMetrics(details, imgId, category);
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const perfImgMetricsArr = values[1] as any[];
            const perfImgMetric = perfImgMetricsArr.find(
                (imgMetric) => imgMetric.imageUrl
            );

            if (perfImgMetric) {
                const imageName = GetFileName(perfImgMetric.imageUrl);
                downloadAsFileWithAssurance(
                    perfImgMetric.imageUrl,
                    imageName,
                    FileType.Image
                );
            }
        }
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        let selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this.setState(
            {
                selectLanguage: selectLanguage,
            },
            () => {
                this._onQueryButtonClicked();
            }
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, matchDatasetVersion } = this.state;
        if (records && records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion
            );

            this.showEvaluationResult(
                details,
                "performance/barcode-perf-byimage.csv"
            );
        }
    };

    private _reflectPerfOverviewColumns(dataItems: any[]): void {
        let sampleMetric = dataItems.find(
            (dataItem) => dataItem && dataItem.category && dataItem.filename
        );
        if (sampleMetric) {
            const metricKeys = Object.keys(sampleMetric);
            const defColKeys = DEF_PERF_BY_IMAGE_COLUMNS.map((col) => col.key);
            this.perfOverivewColumns = _.cloneDeep(DEF_PERF_BY_IMAGE_COLUMNS);
            metricKeys.forEach((metricKey) => {
                if (!defColKeys.includes(metricKey)) {
                    this.perfOverivewColumns.push({
                        key: metricKey,
                        name: metricKey,
                        fieldName: metricKey,
                        valueType: ColumnValueType.Number,
                        maxDecimalPlaces: 3,
                        minWidth: 120,
                        maxWidth: 140,
                        isResizable: true,
                    });
                }
            });
        }

        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrBarcode}_${Typename.PerformanceMetrics}_Byimage`,
                columns: this.perfOverivewColumns,
                selectedItems: this._loadSelectedColumns(),
            })
        );
    }

    private _loadSelectedColumns(): string[] {
        const selectedColumns = DEF_PERF_BY_IMAGE_COLUMNS.map((val) => val.key);

        DEF_COL_NAMES.forEach((name) => {
            if (!selectedColumns.includes(name)) {
                selectedColumns.push(name);
            }
        });

        return selectedColumns;
    }

    private _renderImageTable(): React.ReactNode {
        const { isDarkTheme, records } = this.props;
        const {
            evalData,
            filterDict,
            imageVisItems,
            selectedColumns,
            selectImageId,
        } = this.state;

        let clickNumber: number = 0;
        const experiments = records.map((record) => record.name);
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );

        const columns: TableColumn[] = this.perfOverivewColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const categoryMap = new Map<string, string>();
        const dataMap = new Map<string, any[]>();
        const dataArr = Object.values(evalData) as any[][];
        if (dataArr && dataArr.length > 0) {
            dataArr.forEach((imageRawArr) => {
                const keyImageRaw = imageRawArr.find(
                    (imgRaw) => imgRaw?.filename
                );
                if (keyImageRaw) {
                    if (!dataMap.has(keyImageRaw.filename)) {
                        dataMap.set(keyImageRaw.filename, imageRawArr);
                    }

                    if (!categoryMap.has(keyImageRaw.filename)) {
                        categoryMap.set(
                            keyImageRaw.filename,
                            keyImageRaw.category
                        );
                    }
                }
            });
        }

        return (
            <>
                <FullScreen>
                    <TableList<any>
                        key={this.state.selectLanguage}
                        evalDataCount={records.length}
                        evalData={dataMap}
                        columns={columns}
                        initialFilterDict={filterDict}
                        isDarkTheme={isDarkTheme}
                        downloadTableTitle="BarcodeImage"
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageKey] = item;

                            this.setState({
                                selectImageId: imageKey as string,
                                clickNumber: clickNumber,
                            });
                        }}
                        getDisplayEvalData={(displayItems: any) => {
                            this.setState({
                                imageVisItems: displayItems,
                            });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        disableVirtualize
                    />
                </FullScreen>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    <ImageVisualizer
                        experiments={experiments}
                        fileId={selectImageId}
                        evalList={imageItemsForVis}
                        onLoadVisualizer={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                if (category) {
                                    return OcrBarcodePage.fetchImageUrl(
                                        dataMap,
                                        category,
                                        imageId
                                    );
                                }
                            }

                            return "";
                        }}
                        setImageMark={this.setImageMark}
                        onDismiss={(imageId: string) => {
                            this.setState({
                                selectImageId: imageId,
                            });
                        }}
                        clickNumber={this.state.clickNumber}
                        metricsType={MetricsType.barcodeMetrics}
                        onRequestPolygons={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                return this._requestPolygons(imageId, category);
                            } else {
                                return Promise.resolve([]);
                            }
                        }}
                        onRequestMetrics={(imageId?: string | undefined) => {
                            if (imageId) {
                                const category = categoryMap.get(imageId);
                                return this._requestMetrics(imageId, category);
                            } else {
                                return Promise.resolve([]);
                            }
                        }}
                    />
                </Modal>
            </>
        );
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, matchDatasetVersion } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion);
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }
}
