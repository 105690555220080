import { AnyAction, Dispatch } from "redux";
import { ActionType } from "./reducers";
import { rootState } from ".";

export const mapStateToProps = (state: rootState) => {
    return { disableSetting: state.globalReducer.disableSetting };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        updateSettingOff: (disableSetting: boolean) => {
            const action: AnyAction = {
                type: ActionType.UpdateSettingOff,
                value: disableSetting,
            };
            dispatch(action);
        },
    };
};
