import React from "react";
import { Record } from "../../DataContract";
import { TelemetryOverview } from "../../Views/Vertical/TelemetryOverview";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class VerticalTelemetry extends React.Component<IProps, IState> {
    render(): React.ReactNode {
        const { records, selectedKey } = this.props;

        return (
            <>
                {selectedKey === "overviewVerticalTLM" && (
                    <TelemetryOverview records={records} />
                )}
            </>
        );
    }
}
