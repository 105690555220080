import { AnyAction } from "redux";

const initialState = {
    isFullscreen: false,
};

export interface FullscreenState {
    isFullscreen: boolean;
}

export class ActionType {
    public static readonly UpdateFullscreen = "UPDATE_FULLSCREEN";
}

export const fullscreenReducer = (
    state: FullscreenState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case ActionType.UpdateFullscreen:
            return { ...state, ...{ isFullscreen: action.value } };

        default:
            return state;
    }
};

export const updateFullscreenAction = (state: any) => {
    return {
        type: ActionType.UpdateFullscreen,
        value: state,
    };
};
