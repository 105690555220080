import * as React from "react";
import {
    Dropdown,
    Label,
    Stack,
    IDropdownStyles,
    Toggle,
    TooltipHost,
} from "@fluentui/react";
import { Consumer } from "../Layout";
import { ShortenMetricKeyName } from "../Views/Latency/Interface";

interface ITableConfiguration {
    key: string;
    text: string;
    options: string[];
    multiSelect?: boolean;
    selectedKey?: string;
    selectedKeys?: string[];
    styles?: Partial<IDropdownStyles>;
    shortName?: boolean;
    onChange?: (option: any) => void;
}
export type ITableConfigurations = ITableConfiguration[];
interface IProps {
    options?: ITableConfigurations;
    toggleLabel?: string;
    toggleTooltips?: string;
    isToggleChecked?: boolean;
    onQueryButtonClick?: () => void;
    onToggle?: (checked: boolean) => void;
}

const calloutProps = { gapSpace: 0 };

export class TableHeader extends React.Component<IProps> {
    public render() {
        const { options, toggleLabel } = this.props;
        return (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {options &&
                    options.map((opt, optionIndex) => {
                        return (
                            opt.options && (
                                <Stack
                                    key={`tableheader_${optionIndex}`}
                                    horizontal
                                    verticalAlign="center"
                                    className="tableHeader"
                                    tokens={{ childrenGap: 1 }}
                                >
                                    <Label>
                                        <b>{opt.text}</b>
                                    </Label>
                                    <Consumer>
                                        {(value) => {
                                            return (
                                                <Dropdown
                                                    options={opt.options!.map(
                                                        (val) => {
                                                            return {
                                                                key: val,
                                                                text: opt.shortName
                                                                    ? ShortenMetricKeyName(
                                                                          val
                                                                      )
                                                                    : val,
                                                            };
                                                        }
                                                    )}
                                                    onChange={(
                                                        _event,
                                                        option
                                                    ) => {
                                                        opt.onChange!(option);
                                                    }}
                                                    multiSelect={
                                                        opt.multiSelect
                                                    }
                                                    selectedKey={
                                                        opt.selectedKey
                                                    }
                                                    selectedKeys={
                                                        opt.selectedKeys
                                                    }
                                                    styles={{
                                                        dropdown: {
                                                            width: 200,
                                                        },
                                                        dropdownOptionText: {
                                                            color: value
                                                                ? "white"
                                                                : "black",
                                                        },
                                                    }}
                                                />
                                            );
                                        }}
                                    </Consumer>
                                </Stack>
                            )
                        );
                    })}
                {toggleLabel && (
                    <Stack
                        horizontal
                        verticalAlign={"center"}
                        styles={{ root: { margin: "10px" } }}
                    >
                        <TooltipHost
                            content={this.props.toggleTooltips}
                            // Give the user more time to interact with the tooltip before it closes
                            closeDelay={500}
                            calloutProps={calloutProps}
                            styles={{ root: { display: "inline-block" } }}
                        >
                            <Toggle
                                label={toggleLabel}
                                inlineLabel
                                checked={this.props.isToggleChecked}
                                onChange={(_event, checked) =>
                                    this.props.onToggle!(checked!)
                                }
                            />
                        </TooltipHost>
                    </Stack>
                )}
            </Stack>
        );
    }
}
