import React from "react";
import {
    IChoiceGroupOption,
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { OcrMathOverview } from "../../Views/OcrMath/OcrMathOverview";
import { OcrMathImageView } from "../../Views/OcrMath/OcrMathImageView";
import { Consumer } from "../../Layout";
import { OcrMathSymbolErrorOverview } from "../../Views/OcrMath/OcrMathSymbolErrorOverview";
import { LatencyMetrics } from "../../Views/Latency/LatencyMetrics";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

interface IState {}

interface IProps {
    records: Record[];
    selectedKey: string;
    storageVersion?: string;
    display?: boolean;
    isDarkTheme?: boolean;
}

export const DEFAULT_OCRMATH_COLUMNS = [
    "category",
    "formula_delete_rate",
    "formula_insert_rate",
    "formula_subs_rate",
    "formula_error_rate",
    "fsym_delete_rate",
    "fsym_insert_rate",
    "fsym_subs_rate",
    "fsym_error_rate",
];

// prettier-ignore
export const METRICS_LVL_OPTIONS: IChoiceGroupOption[] = [
    { key: "lv0", text: "Level 0: Ignore formula kind && ignore spaces", },
    { key: "lv1", text: "Level 1: Ignore formula kind" },
    { key: "lv2", text: "Level 2: Default, count all errors" },
];

export class OcrMathPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._onPivotItemChanged = this._onPivotItemChanged.bind(this);
    }

    public render() {
        const { records, selectedKey } = this.props;
        return (
            <>
                {selectedKey === "ocrMathGeneralMetrics" && (
                    <Pivot
                        linkFormat={PivotLinkFormat.links}
                        className="displayFlex"
                        styles={{
                            root: {
                                padding: "10px",
                            },
                            itemContainer: {
                                height: "100%",
                                overflow: "hidden",
                            },
                        }}
                        onLinkClick={this._onPivotItemChanged}
                    >
                        <PivotItem
                            itemKey="Overview"
                            headerText="Overview"
                            className="displayFlex"
                        >
                            <Consumer>
                                {(value) => {
                                    return (
                                        <OcrMathOverview
                                            key={`ocrmath_`}
                                            saveSetKey={`${Workspaces.OcrMath}_${Typename.GeneralMetrics}_Overview`}
                                            records={records}
                                            isDarkTheme={value}
                                        />
                                    );
                                }}
                            </Consumer>
                        </PivotItem>

                        <PivotItem
                            itemKey="ByImage"
                            headerText="By Image"
                            className="displayFlex"
                        >
                            <Consumer>
                                {(value) => {
                                    return (
                                        <OcrMathImageView
                                            saveSetKey={`${Workspaces.OcrMath}_${Typename.GeneralMetrics}_BYImage`}
                                            records={records}
                                            isDarkTheme={value}
                                        />
                                    );
                                }}
                            </Consumer>
                        </PivotItem>
                    </Pivot>
                )}
                {selectedKey === "ocrMathSymbolMetrics" && (
                    <Pivot
                        linkFormat={PivotLinkFormat.links}
                        className="displayFlex"
                        styles={{
                            itemContainer: {
                                height: "100%",
                                overflow: "hidden",
                            },
                        }}
                        onLinkClick={this._onPivotItemChanged}
                    >
                        <PivotItem
                            itemKey="Overview"
                            headerText="Overview"
                            className="displayFlex"
                        >
                            <Consumer>
                                {(value) => {
                                    return (
                                        <OcrMathSymbolErrorOverview
                                            saveSetKey={`${Workspaces.OcrMath}_${Typename.MATHSYMBOLMetrics}`}
                                            records={records}
                                            isDarkTheme={value}
                                        />
                                    );
                                }}
                            </Consumer>
                        </PivotItem>
                    </Pivot>
                )}
                {selectedKey === "latencyMetrics" && (
                    <LatencyMetrics
                        saveSetKey={`${Workspaces.OcrMath}_${Typename.LatencyMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    ></LatencyMetrics>
                )}
            </>
        );
    }

    private _onPivotItemChanged(item?: PivotItem): void {
        store.dispatch(updateSelectSubpivoteAction(item?.props.itemKey));
    }
}
