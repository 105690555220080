import React from "react";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Label,
} from "@fluentui/react";
import { IGroupedBarChartData, VerticalBarChart } from "../../Controls";
import {
    DatasetSet,
    IDataItem,
    MetricCompactDefinition,
} from "../../DataContract";
import { getColorByIndex, trimAll } from "../../Utils";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { FullScreen } from "../Common/FullScreen";

const DEFAULT_FIELD = ["F1", "Precision", "Recall"];
const DOC_LVL_SPLIT_CLASS_DEFINITION: MetricCompactDefinition = {
    name: "DocLvlSplitClass",
    displayName: "Doc level split and classification",
    props: DEFAULT_FIELD,
};
const DOC_LVL_SPLIT_DEFINITION: MetricCompactDefinition = {
    name: "DocLvlSplit",
    displayName: "Doc level split",
    props: DEFAULT_FIELD,
};

interface IState<T> extends ICommonState<T> {
    dataItems: IDataItem<T>[];
}

export class DocClassifierOverview<T> extends CommonView<
    ICommonProps,
    IState<T>,
    T
> {
    get MetricDefinitionArray(): MetricCompactDefinition[] {
        return [DOC_LVL_SPLIT_CLASS_DEFINITION, DOC_LVL_SPLIT_DEFINITION];
    }

    constructor(prop: ICommonProps) {
        super(prop);

        this.state = {
            dataItems: [],
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="overview">
                <FullScreen>
                    <div style={{ overflow: "hidden auto", height: "100%" }}>
                        {this._renderAsChart()}
                    </div>
                </FullScreen>
            </div>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("basic_metrics.json");
    }

    private _prepareRenderData() {
        const { records } = this.props;
        const { dataItems } = this.state;
        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );
        const datasetFullNames = Array.from(
            new Set(datasets.map((dataset) => dataset.fullName))
        );

        const data = datasetFullNames.map((fullName) => {
            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.fullName === fullName
                    );
                });
                return item;
            });
            const tableData: any = {};
            this.MetricDefinitionArray.forEach((metricDef) => {
                const data: any[] = [];
                const tableArr: any = {};
                items.forEach((item) => {
                    if (!!item && (item.metrics as any)[metricDef.name]) {
                        const { metrics } = item;
                        let metricItem = (metrics as any)[metricDef.name]
                            ? (metrics as any)[metricDef.name]
                            : {};
                        for (const key in metricItem) {
                            if (metricDef.props.includes(key)) {
                                if (!tableArr[key]) {
                                    tableArr[key] = new Array(
                                        records.length
                                    ).fill(undefined);
                                }
                                tableArr[key][item.recordIndex] =
                                    metricItem[key];
                            }
                        }
                    }
                    data.push(item);
                });
                const categoryItems = {
                    data: data,
                    table: tableArr,
                };
                tableData[metricDef.name] = categoryItems;
            });
            return [fullName, tableData];
        });
        return data;
    }

    private _renderAsChart() {
        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderData();
            return (
                <>
                    {this.MetricDefinitionArray.map(
                        (definition, definitionIndex) => {
                            const jsxElementArr = data
                                .map(([datasetname, metricView], index) => {
                                    if (!!metricView[definition.name].table) {
                                        const subCharts =
                                            this._renderChartByMetricDefinition(
                                                trimAll(
                                                    definition.displayName +
                                                        index
                                                ),
                                                definition,
                                                metricView[definition.name]
                                            );

                                        if (subCharts.length > 0) {
                                            return (
                                                <div key={index}>
                                                    <Label>{datasetname}</Label>
                                                    <div
                                                        key={`barChartMetrics_${definition.name}_container`}
                                                    >
                                                        <DocumentCard
                                                            className="overview__card"
                                                            key={`barChartMetrics_${definition.name}`}
                                                            type={
                                                                DocumentCardType.compact
                                                            }
                                                        >
                                                            {subCharts}
                                                        </DocumentCard>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }

                                    return <></>;
                                })
                                .filter((element) => element);

                            return jsxElementArr && jsxElementArr.length > 0 ? (
                                <div key={`defition_${definitionIndex}`}>
                                    <Label>
                                        <h2>{definition.displayName}</h2>
                                    </Label>
                                    {jsxElementArr}
                                </div>
                            ) : (
                                <></>
                            );
                        }
                    )}
                </>
            );
        }
    }

    private _renderChartByMetricDefinition(
        id: string,
        definition: MetricCompactDefinition,
        metrics: any
    ) {
        let subCharts: JSX.Element[] = [];
        if (!!metrics) {
            const chartData: IGroupedBarChartData[] = [];
            definition.props.forEach((prop) => {
                const values = metrics.table[prop] as any[];
                if (values && values.some((value) => !isNaN(value))) {
                    const table = values.map((value, recordIndex) => {
                        return {
                            key: `metricDef_${id}_${recordIndex}`,
                            data: value ? (value as number) : 0,
                            color: getColorByIndex(recordIndex),
                            legend: this.props.records[recordIndex].name,
                        };
                    });

                    chartData.push({
                        name: prop,
                        series: table,
                    });
                }
            });

            if (chartData && chartData.length > 0) {
                const subChart = (
                    <DocumentCardDetails
                        key={`barChartMetrics_${definition.name}`}
                    >
                        {this._renderSingleChart(
                            id,
                            chartData,
                            definition.displayName
                        )}
                    </DocumentCardDetails>
                );

                subCharts.push(subChart);
            }
        }

        return subCharts;
    }

    private _renderSingleChart(
        id: string,
        chartData: IGroupedBarChartData[],
        title: string
    ) {
        return (
            <>
                <DocumentCardTitle className="overview__title" title={title} />
                <VerticalBarChart
                    id={id}
                    data={chartData}
                    height={400}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </>
        );
    }
}
