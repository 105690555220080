import "../Common/MetricStyle.scss";
import React from "react";

import {
    //Group&DetailList
    DetailsList,
    IColumn,
    SelectionMode,
} from "@fluentui/react";

import { Record, RecordDetail } from "../../DataContract";
import { FullScreen } from "../Common/FullScreen";

interface CogsResult {
    result: {
        [key: string]: { [key: string]: any };
    };
    reports: string[];
}

interface IState {
    data?: [RecordDetail, CogsResult];
    reports?: string[];
}

interface IProps {
    records: Record[];
}

// prettier-ignore
const COLUMNS: IColumn[] = [
    { key: "expName",           name: "expName",            fieldName: "expName",           minWidth: 300,  maxWidth: 500,  isResizable: true },
    { key: "cogs",              name: "cogs",               fieldName: "cogs",              minWidth: 100,  maxWidth: 100,  isResizable: true },
    { key: "latency",           name: "latency",            fieldName: "latency",           minWidth: 100,  maxWidth: 100,  isResizable: true },
    { key: "tps",               name: "tps",                fieldName: "tps",               minWidth: 100,  maxWidth: 100,  isResizable: true },
    { key: "averageCpu",        name: "averageCpu",         fieldName: "averageCpu",        minWidth: 100,  maxWidth: 100,  isResizable: true },
    { key: "averageMemory",     name: "averageMemory",      fieldName: "averageMemory",     minWidth: 100,  maxWidth: 100,  isResizable: true },
    { key: "requestedCpu",      name: "requestedCpu",       fieldName: "requestedCpu",      minWidth: 100,  maxWidth: 100,  isResizable: true },
];

export class CogsDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }
    public render() {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "auto" }}>
                    {this._onRenderTableDom()}
                </div>
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryMetricsResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryMetricsResult();
        }
    }

    public _queryMetricsResult() {
        const { records } = this.props;
        if (records.length > 0) {
            const details = records[0].getDetails();
            if (details && details.length > 0) {
                details[0]
                    .fetchMetricsWithRecordDetail<CogsResult>("cogs")
                    .then((data) => {
                        this.setState({
                            data: data,
                            reports: [],
                        });
                    });
            }
        }
    }

    private _onRenderTableDom() {
        const { data } = this.state;

        if (data) {
            const cogs = data[1]["result"];

            if (cogs !== undefined && Object.keys(cogs).length !== 0) {
                let datasetNameList = (Object.entries(cogs)[0][1] as any)[
                    "cogs"
                ].map((item: any) => item["name"]);

                let items: any = [];
                let groups: any = [];
                let startIndex: number = 0;
                let count: number = 0;
                datasetNameList.forEach((datasetName: string) => {
                    startIndex += count;
                    count = 0;
                    Object.entries(cogs).forEach(([expName, value]) => {
                        if (value && "cogs" in value) {
                            let cogsRecord = value["cogs"].filter(
                                (item: any) => item["name"] === datasetName
                            )[0];
                            items.push({
                                expName: expName,
                                cogs: cogsRecord["cogs"],
                                latency: cogsRecord["latency"],
                                tps: cogsRecord["tps"],
                                averageCpu: cogsRecord["average cpu"],
                                averageMemory: cogsRecord["average memory"],
                                requestedCpu: cogsRecord["requested cpu"],
                            });
                            count++;
                        }
                    });
                    groups.push({
                        startIndex: startIndex,
                        count: count,
                        key: datasetName,
                        name: datasetName,
                    });
                });

                return (
                    <DetailsList
                        columns={COLUMNS}
                        items={items}
                        groups={groups}
                        selectionMode={SelectionMode.none}
                    />
                );
            }
        }
    }
}
