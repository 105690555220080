import React from "react";
import { Label, Pivot, PivotItem, PivotLinkFormat } from "@fluentui/react";
import {
    OcrCheckboxImageView,
    OcrCheckboxOverview,
    LatencyMetrics,
} from "../../Views/index";
import {
    CANNOTCOMPARE,
    Record,
    Typename,
    Workspaces,
} from "../../DataContract";
import { store } from "../../../store";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

interface IState {}

interface IProps {
    records: Record[];
    selectedKey: string;
    storageVersion?: string;
    display?: boolean;
    isDarkTheme?: boolean;
}

export class OcrCheckboxPage extends React.Component<IProps, IState> {
    public render() {
        const { records, selectedKey, storageVersion, display } = this.props;
        return (
            <>
                {display ? (
                    <>
                        {selectedKey === "basicCheckboxMetrics" && (
                            <Pivot
                                linkFormat={PivotLinkFormat.links}
                                className="displayFlex"
                                styles={{
                                    itemContainer: {
                                        height: "100%",
                                        overflow: "hidden",
                                    },
                                }}
                                onLinkClick={(pivotprops) => {
                                    store.dispatch(
                                        updateSelectSubpivoteAction(
                                            pivotprops?.props.itemKey!
                                        )
                                    );
                                }}
                            >
                                <PivotItem
                                    itemKey="Overview"
                                    headerText="Overview"
                                    className="displayFlex"
                                >
                                    <OcrCheckboxOverview
                                        storageVersion={storageVersion}
                                        records={records}
                                        isDarkTheme={this.props.isDarkTheme}
                                    />
                                </PivotItem>
                                {storageVersion === "v2" && (
                                    <PivotItem
                                        itemKey="ByImage"
                                        headerText="By Image"
                                        className="displayFlex"
                                    >
                                        <OcrCheckboxImageView
                                            storageVersion={storageVersion}
                                            records={records}
                                            isDarkTheme={this.props.isDarkTheme}
                                        />
                                    </PivotItem>
                                )}
                            </Pivot>
                        )}
                        {selectedKey === "checkboxLatencyMetrics" && (
                            <LatencyMetrics
                                saveSetKey={`${Workspaces.OcrCheckbox}_${Typename.LatencyMetrics}`}
                                records={records}
                                defaultCrossLanguage="Overall"
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        )}
                    </>
                ) : (
                    <Label>{CANNOTCOMPARE}</Label>
                )}
            </>
        );
    }
}
