import { IIconProps, IconButton } from "@fluentui/react";
import React from "react";
import "../Pages.scss";
import { SubscribeHoc } from "./SubscribeHoc";
import { WorkspaceGroups, Workspaces } from "../../DataContract";

interface IState {}

interface IProps {
    disableSetting: boolean;
    workspace: string;
    selectedKey: string;
    selectSubPivot: string;
    viewType: string;
    onClick: () => {};
}

const settingIcon: IIconProps = {
    iconName: "Settings",
};

const WORKSPACES_ENABLE_SETTING = [
    Workspaces.Ocr,
    Workspaces.Invoice,
    Workspaces.Kvp,
    Workspaces.CustomForm,
    Workspaces.QueryField,
    Workspaces.ReleaseTest,
    Workspaces.CustomDocReleaseTest,
    Workspaces.Receipt,
    Workspaces.BusinessCard,
    Workspaces.IdCard,
    Workspaces.IndustryPrebuilt,
    Workspaces.HealthDoc,
    Workspaces.ExperimentalPrebuilt,
    Workspaces.OcrCheckbox,
    Workspaces.OcrBarcode,
    Workspaces.OcrMath,
    Workspaces.OcrTable,
    Workspaces.OcrPod,
    Workspaces.OcrFigure,
];

export class SettingButton extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);
        this.state = {};
    }

    disabledButton = () => {
        const {
            selectedKey,
            selectSubPivot,
            viewType,
            disableSetting,
            workspace,
        } = this.props;
        return (
            disableSetting ||
            !WORKSPACES_ENABLE_SETTING.includes(workspace) ||
            WorkspaceGroups.Taipei.includes(workspace) ||
            ["ComponentLatency"].includes(selectSubPivot) ||
            [
                "custFormOverviewTableMetrics",
                "overviewCustFormMetrics",
            ].includes(selectedKey) ||
            (workspace === Workspaces.ReleaseTest &&
                selectedKey === "accurReleaseMetrics" &&
                viewType === "Chart") ||
            (workspace === Workspaces.ReleaseTest &&
                selectedKey !== "accurReleaseMetrics") ||
            workspace === Workspaces.CustomDocReleaseTest ||
            (workspace === Workspaces.Invoice &&
                selectedKey !== "overviewImageMetrics" &&
                viewType === "Chart") ||
            (workspace === Workspaces.Kvp &&
                selectedKey === "overviewKvpMetrics" &&
                viewType === "Chart") ||
            (selectedKey === "highlightMetrics" && viewType === "Chart") ||
            (selectedKey === "checkboxLatencyMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Chart") ||
            (selectedKey === "latencyMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Chart") ||
            (selectedKey === "generalMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Chart") ||
            (selectedKey === "entityMetrics" &&
                ["distribution", "prediction", "predictionline"].includes(
                    selectSubPivot
                )) ||
            (workspace === Workspaces.OcrTable &&
                selectedKey === "basicTableMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Chart") ||
            (workspace === Workspaces.OcrPod &&
                selectedKey === "ocrPodGeneralMetrics" &&
                selectSubPivot === "Overview" &&
                viewType === "Chart") ||
            (workspace === Workspaces.QueryField &&
                selectedKey === "overviewQueryFieldMetrics") ||
            (workspace === Workspaces.CustomForm &&
                (selectedKey === "custFormErrAnalysisOverall" ||
                    selectedKey === "custFormErrAnalysisDetail"))
        );
    };

    public render() {
        return (
            <IconButton
                disabled={this.disabledButton()}
                className={"settingIcon"}
                iconProps={settingIcon}
                style={{
                    color: "#75b6e7",
                }}
                onClick={this.props.onClick}
            />
        );
    }
}

const SettinbButton = SubscribeHoc(SettingButton);
export default SettinbButton;
