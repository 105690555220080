import React from "react";

import { Modal, IDropdownOption } from "@fluentui/react";

import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";

import { ColumnValueType, TableColumn, TableList } from "../../Controls";
import { IMetricUnit, IMetrics, RecordDetail } from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { Consumer } from "../../Layout";
import AuthImage from "../../AuthComponent/AuthImage";

export interface BackendTextlineCharMetric extends IMetricUnit {
    textline_id: string;
    gt: string;
    fp: string;
    category: string;
    text_direction: string;
    is_curve: boolean;
    cer: string;
    wer: string;
}

interface IState extends IMetricState<BackendTextlineCharMetric> {
    selectLanguage?: string;
    selectTextLine?: string;
    selectTextLineUrl?: string;
    selectedColumns: string[];
}

// prettier-ignore
let textlineColumns: TableColumn[] = [
    { key: "textline_id",       name: "TextlineID",         isKey: true,  valueType: ColumnValueType.String,    minWidth: 200, maxWidth: 400, },
    { key: "gt",                name: "Ground Truth",       isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "fp",                name: "First Pass",         isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "category",          name: "Category",           isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "text_direction",    name: "Text Direction",     isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "is_curve",          name: "Is Curve",           isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "cer",               name: "CER",                isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
    { key: "wer",               name: "WER",                isKey: false, valueType: ColumnValueType.String,    minWidth: 100, maxWidth: 200, },
];

export class BackendTextlineView extends MetricsView<
    IMetricProps,
    IState,
    BackendTextlineCharMetric
> {
    constructor(props: IMetricProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            evalData: {},
            selectedColumns: textlineColumns.map((val) => val.name),
            matchDatasetVersion: true,
        };
    }

    public render() {
        const { selectTextLine, selectTextLineUrl, selectedColumns } =
            this.state;
        let columns: TableColumn[] = textlineColumns.filter(
            (value) =>
                selectedColumns.findIndex((col) => col === value.name) !== -1
        );
        return (
            <div>
                <Modal
                    isOpen={!!selectTextLine}
                    onDismiss={() =>
                        this.setState({
                            selectTextLine: undefined,
                        })
                    }
                    containerClassName="modal"
                >
                    <div className="header">
                        <div className="modal__title">
                            <Consumer>
                                {(value) => {
                                    return (
                                        <span
                                            style={{
                                                color: value
                                                    ? "white"
                                                    : "black",
                                            }}
                                        >
                                            {selectTextLine}
                                        </span>
                                    );
                                }}
                            </Consumer>
                        </div>
                        <div className="modal__imagecontainer">
                            <AuthImage
                                url={selectTextLineUrl}
                                className="modal__image"
                            />
                        </div>
                    </div>
                </Modal>
                <div>
                    <TableList<BackendTextlineCharMetric>
                        evalDataCount={this.props.records.length}
                        evalData={this.state.evalData}
                        columns={columns}
                        downloadTableTitle="TextLine"
                        renderTableHeader={this._renderTableHeader}
                        onItemInvoked={(item) => {
                            const textlineId = item[0];
                            this.setState({
                                selectTextLine: textlineId,
                            });
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </div>
            </div>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<BackendTextlineCharMetric>> {
        return recordDetail
            .fetchMetricsWithCamelCasing<IMetrics<BackendTextlineCharMetric>>(
                metricName
            )
            .then((records) => {
                let items: IMetrics<BackendTextlineCharMetric> = {};
                for (let textlines in records) {
                    items[textlines] = records[textlines];
                    items[textlines].textline_id = textlines;
                }
                return items;
            });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const languageList = this.getLanguageList(matchDatasetVersion);
        if (languageList.length > 0) {
            const language = languageList[0];
            this._onOptionsChanged(
                {
                    selectLanguage: language,
                },
                500
            );
        }
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, matchDatasetVersion } = this.state;

        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion
            );

            this.showEvaluationResult(details, "textline_metrics.json");
        }
    };

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        timeout: number = 1000
    ) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this.setState({
            selectLanguage: selectLanguage,
        });

        setTimeout(() => {
            if (selectLanguage === this.state.selectLanguage) {
                this._onQueryButtonClicked();
            }
        }, timeout);
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, selectedColumns, matchDatasetVersion } =
            this.state;
        let languages = this.getLanguageList(matchDatasetVersion);

        let textlineOptions: ITableConfigurations = [
            {
                key: "languages",
                text: "Language:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (selectLanguage) => {
                    this._onOptionsChanged!(
                        {
                            selectLanguage: selectLanguage!.text,
                        },
                        1000
                    );
                },
            },
            {
                key: "columns",
                text: "Columns:",
                options: textlineColumns.map((val) => val.name),
                multiSelect: true,
                selectedKeys: selectedColumns,
                onChange: (option) => this._onColumnDropDownChange(option),
            },
        ];

        return (
            <TableHeader
                options={textlineOptions}
                onQueryButtonClick={this._onQueryButtonClicked}
                onToggle={(checked: boolean) => {
                    this.setState({
                        matchDatasetVersion: checked,
                    });
                    this._onEvaluationRecordChanged(checked);
                }}
            />
        );
    }

    private _onColumnDropDownChange(option?: IDropdownOption): void {
        const newSelectedItems = this.state.selectedColumns.slice();

        if (option) {
            if (option.selected) {
                // add the option if it's checked
                newSelectedItems.push(option.text);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option.text);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }

            this.setState({ selectedColumns: newSelectedItems });
        }
    }
}
