import React from "react";
import "../Common/MetricStyle.scss";
import {
    //Pivot
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";

import { ImageMetricView } from "./ImageView";
import { TextlineMetricView } from "./TextlineView";

import { store } from "../../../store";
import { GeneralMetricsOverivew, IProps } from "./GeneralMetricsOverview";
import { updateSelectSubpivoteAction } from "../../../store/reducers";

export interface OcrGeneralStateInSession {
    selectLanguage?: string;
    selectCategory?: string;
}

interface IState {
    selectedPivot: string;
}

export class GeneralMetrics extends React.Component<IProps, IState> {
    private linkData?: any;
    constructor(props: IProps) {
        super(props);

        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            selectedPivot: "Overview",
        };
    }

    public render() {
        return (
            <div className="overview">
                <Pivot
                    onLinkClick={this.onPivotItemChanged}
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <GeneralMetricsOverivew
                            {...this.props}
                            setSelectedSubPivot={this.setSelectedSubPivot}
                        />
                    </PivotItem>
                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By Image"
                            className="displayFlex"
                            itemKey="ByImage"
                        >
                            <ImageMetricView
                                records={this.props.records}
                                linkData={this.linkData}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}

                    {!store.getState().settingReducer.isReport && (
                        <PivotItem
                            headerText="By TextLine"
                            itemKey="ByTextline"
                            className="displayFlex"
                        >
                            <TextlineMetricView
                                records={this.props.records}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </PivotItem>
                    )}
                </Pivot>
            </div>
        );
    }

    public onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }
        this.linkData = undefined;
        this.changeSelectedSubPivot(item?.props.itemKey!);
    }

    setSelectedSubPivot = (selectPivot: string, linkData?: any) => {
        this.linkData = linkData;
        this.changeSelectedSubPivot(selectPivot);
    };

    changeSelectedSubPivot = (selectPivot: string) => {
        this.setState({
            selectedPivot: selectPivot,
        });

        store.dispatch(updateSelectSubpivoteAction(selectPivot));
    };
}
