import React from "react";

import {
    //Pivot
    Pivot,
    PivotItem,

    //Others
    Stack,
} from "@fluentui/react";

import { Record, Typename, Workspaces } from "../../DataContract";

import { CogsDetails } from "../../Views/OcrRelease/CogsDetails";
import { PerfDetails } from "../../Views/OcrRelease/PerfDetails";
import { LoadDetails } from "../../Views/OcrRelease/LoadDetails";
import { ReliDetails } from "../../Views/OcrRelease/ReliDetails";
import { SanityDetails } from "../../Views/OcrRelease/SanityDetails";
import { ReleaseTestOverview } from "../../Views/OcrRelease/OcrReleaseOverview";
import { ReleaseRecord } from "../../DataContract/ReleaseRecord";
import {
    GeneralMetrics,
    EntityMetrics,
    LatencyMetrics,
    CharMetrics,
} from "../../Views";
import { MetricsAnalysis } from "../../Views/Ocr/OcrDataContract";
import { Consumer } from "../../Layout";
import { store } from "../../../store";
import { updateReleaseTestSelectSubpivoteAction } from "../../../store/reducers";
import { OcrRecord } from "../../DataContract/OcrRecord";

interface IState {
    generalMetricsAnalysisOn: boolean;
}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class ReleaseTestPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._updateAnalysisState = this._updateAnalysisState.bind(this);
        this.state = {
            generalMetricsAnalysisOn: false,
        };
    }

    public render() {
        const { records, selectedKey } = this.props;
        const readRecords = records.map(
            (record) => (record as ReleaseRecord).readAccuracyRecord
        );
        const layoutRecords = records.map(
            (record) => (record as ReleaseRecord).layoutAccuracyRecord
        );
        const slimReadRecords = records.map(
            (record) => (record as ReleaseRecord).slimReadAccuracyRecord
        );
        const customRecords = records.map(
            (record) => (record as ReleaseRecord).customAccuracyRecord
        );

        return (
            <>
                {selectedKey === "overviewReleaseMetrics" && (
                    <ReleaseTestOverview records={records} />
                )}
                {selectedKey === "sanityReleaseMetrics" && (
                    <SanityDetails
                        records={records}
                        metricName="sanity"
                        reportName="sanityTestReports"
                        reportPath="SanityTest"
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "verSanityReleaseMetrics" && (
                    <SanityDetails
                        records={records}
                        metricName="verSanity"
                        reportName="verticalSanityTestReports"
                        reportPath="VerticalSanityTest"
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "formRecognizer30SanityReleaseMetrics" && (
                    <SanityDetails
                        records={records}
                        metricName="formRecognizer30Sanity"
                        reportName="formRecognizer30SanityTestReports"
                        reportPath="FormRecognizer30SanityTest"
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "cogsReleaseMetrics" && (
                    <CogsDetails records={records} />
                )}
                {selectedKey === "reliaReleaseMetrics" && (
                    <ReliDetails records={records} />
                )}
                {selectedKey === "loadReleaseMetrics" && (
                    <LoadDetails records={records} />
                )}
                {selectedKey === "perfReleaseMetrics" && (
                    <PerfDetails records={records} />
                )}
                {selectedKey === "accurReleaseMetrics" && (
                    <Stack verticalFill style={{ overflow: "auto" }}>
                        <Pivot>
                            {this.subPages(readRecords, "OCRForReadAPI")}
                            {this.subPages(layoutRecords, "OCRForLayoutAPI")}
                            {this.subPages(
                                slimReadRecords,
                                "OCRForSlimReadAPI"
                            )}

                            {this.subPages(
                                customRecords,
                                "OCRForCustomLayoutAPI"
                            )}
                        </Pivot>
                    </Stack>
                )}
            </>
        );
    }

    subPages = (records: OcrRecord[], key: string) => {
        const { generalMetricsAnalysisOn } = this.state;
        return (
            <PivotItem headerText={`${key} (DevTesting)`}>
                <Pivot onLinkClick={this.onPivotItemChanged}>
                    <PivotItem
                        headerText="General Metrics"
                        alwaysRender={false}
                        style={{ width: "100%" }}
                        itemKey="generalMetrics"
                    >
                        <Consumer>
                            {(value) => {
                                return (
                                    <GeneralMetrics
                                        saveSetKey={`${Workspaces.ReleaseTest}_${key}_${Typename.GeneralMetrics}`}
                                        records={records}
                                        isDarkTheme={value}
                                        alwaysShow
                                        analysisOn={generalMetricsAnalysisOn}
                                        updateAnalysisState={
                                            this._updateAnalysisState
                                        }
                                    />
                                );
                            }}
                        </Consumer>
                    </PivotItem>

                    <PivotItem
                        headerText="Entity Metrics"
                        alwaysRender={false}
                        style={{ width: "100%" }}
                        itemKey="entityMetrics"
                    >
                        <EntityMetrics
                            saveSetKey={`${Workspaces.ReleaseTest}_${key}_${Typename.EntityMetrics}`}
                            records={records}
                            alwaysShow
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="Latency Metrics"
                        alwaysRender={false}
                        style={{ width: "100%" }}
                        itemKey="latencyMetrics"
                    >
                        <LatencyMetrics
                            saveSetKey={`${Workspaces.ReleaseTest}_${key}_${Typename.LatencyMetrics}`}
                            records={records}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </PivotItem>

                    <PivotItem
                        headerText="Language Metircs"
                        alwaysRender={false}
                        style={{ width: "100%" }}
                        itemKey="langMetrics"
                    >
                        <CharMetrics
                            saveSetKey={`${Workspaces.ReleaseTest}_${key}_${Typename.CharMetrics}`}
                            records={records}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </PivotItem>
                </Pivot>
            </PivotItem>
        );
    };

    public onPivotItemChanged(item?: PivotItem): any {
        store.dispatch(
            updateReleaseTestSelectSubpivoteAction(item?.props.itemKey)
        );
    }

    private _updateAnalysisState(
        metricsAnalysis: MetricsAnalysis,
        isOn: boolean
    ): void {
        if (metricsAnalysis === MetricsAnalysis.General) {
            this.setState({ generalMetricsAnalysisOn: isOn });
        }
    }
}
