import React from "react";
import moment from "moment";
import "./DatasetCreationPanel.scss";
import {
    ActionButton,
    DatePicker,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Label,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TextField,
} from "@fluentui/react";
import { Record } from "../../DataContract/Record";
import { VerticalTlmRecord } from "../../DataContract/VerticalTlmRecord";
import { fetchDataByPath } from "../../Utils";
import { HttpStatusCode } from "../../DataContract";

interface IProps {
    isOpen: boolean;
    workspace: string;
    onCloseModal: () => void;
    onAddRecord: (record: Record) => void;
}
interface IState {
    alias: string;
    vertical: string;
    type: string;
    algorithm: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    creator: string;
    verticalOptions: string[];
    algorithmOptions: string[];
    algorithmMap: Map<string, string[]>;
}

export class DatasetCreationPanel extends React.Component<IProps, IState> {
    private typeOptions: IDropdownOption[] = [
        { key: "runtime", text: "Customer" },
    ];

    constructor(props: IProps) {
        super(props);
        this.state = {
            alias: "",
            vertical: "",
            type: this.typeOptions[0].key.toString(),
            algorithm: "",
            startDate: undefined,
            endDate: undefined,
            creator: "",
            verticalOptions: [],
            algorithmOptions: [],
            algorithmMap: new Map<string, string[]>(),
        };

        this._clearForm = this._clearForm.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onVerticalChange = this._onVerticalChange.bind(this);
    }

    public componentDidMount() {
        this._fetchConfig();
    }

    render(): React.ReactNode {
        const { isOpen, onCloseModal } = this.props;
        const {
            alias,
            vertical,
            type,
            algorithm,
            startDate,
            endDate,
            creator,
            verticalOptions,
            algorithmOptions,
        } = this.state;
        const lastDaytimeOfThisMonth = moment().endOf("month").toDate();

        return (
            <Modal
                isOpen={isOpen}
                onDismiss={() => {
                    this._clearForm();
                    onCloseModal();
                }}
            >
                <Stack className="mainContainer">
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        verticalAlign="center"
                    >
                        <Text variant="xLarge">Create Base Dataset</Text>
                        <ActionButton
                            iconProps={{
                                iconName: "Cancel",
                            }}
                            onClick={() => {
                                this._clearForm();
                                onCloseModal();
                            }}
                        ></ActionButton>
                    </Stack>

                    <Stack horizontal horizontalAlign="space-evenly">
                        <Stack className="formCol">
                            <Label htmlFor={"name"}>Name</Label>
                            <TextField
                                id={"name"}
                                className="formInput"
                                value={alias}
                                placeholder="Field format should include an @"
                                onChange={(e, newValue) => {
                                    if (
                                        newValue !== null &&
                                        newValue !== undefined
                                    ) {
                                        this.setState({
                                            alias: newValue,
                                        });
                                    }
                                }}
                            />

                            <Label htmlFor={"vertical"}>Vertical</Label>
                            <Dropdown
                                id={"vertical"}
                                placeholder="Select a vertical"
                                options={verticalOptions.map((option) => {
                                    return { key: option, text: option };
                                })}
                                onChange={this._onVerticalChange}
                                selectedKey={vertical}
                            ></Dropdown>

                            <Label htmlFor={"algorithm"}>Algorithm</Label>
                            <Dropdown
                                id={"algorithm"}
                                placeholder="Select an algorithm"
                                options={algorithmOptions.map((option) => {
                                    return { key: option, text: option };
                                })}
                                onChange={(e, option) => {
                                    option &&
                                        typeof option.key === "string" &&
                                        this.setState({
                                            algorithm: option.key,
                                        });
                                }}
                                selectedKey={algorithm}
                            ></Dropdown>
                        </Stack>
                        <Stack className="formCol">
                            <Label htmlFor={"type"}>Type</Label>
                            <Dropdown
                                id={"type"}
                                options={this.typeOptions}
                                onChange={(e, option) => {
                                    option &&
                                        typeof option.key === "string" &&
                                        this.setState({
                                            type: option.key,
                                        });
                                }}
                                selectedKey={type}
                            ></Dropdown>

                            <Label htmlFor={"timeRange"}>Time Range</Label>
                            <Stack horizontal horizontalAlign="space-between">
                                <DatePicker
                                    className="timeRangePicker"
                                    placeholder="Select a date..."
                                    ariaLabel="Select a date"
                                    value={startDate}
                                    maxDate={
                                        endDate &&
                                        endDate < lastDaytimeOfThisMonth
                                            ? endDate
                                            : lastDaytimeOfThisMonth
                                    }
                                    formatDate={(date?: Date): string => {
                                        return date
                                            ? moment(date).format("MMM YYYY")
                                            : "";
                                    }}
                                    calendarProps={{
                                        strings: null,
                                        isDayPickerVisible: false,
                                    }}
                                    onSelectDate={(
                                        date: Date | null | undefined
                                    ) => {
                                        if (date) {
                                            date &&
                                                this.setState({
                                                    startDate: date,
                                                });
                                        }
                                    }}
                                />

                                <DatePicker
                                    className="timeRangePicker"
                                    placeholder="Select a date..."
                                    ariaLabel="Select a date"
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={lastDaytimeOfThisMonth}
                                    formatDate={(date?: Date): string => {
                                        return date
                                            ? moment(date).format("MMM YYYY")
                                            : "";
                                    }}
                                    calendarProps={{
                                        strings: null,
                                        isDayPickerVisible: false,
                                    }}
                                    onSelectDate={(
                                        date: Date | null | undefined
                                    ) => {
                                        date &&
                                            this.setState({
                                                endDate: date,
                                            });
                                    }}
                                />
                            </Stack>

                            <Label htmlFor={"creator"}>Creator</Label>
                            <TextField
                                id={"creator"}
                                className="formInput"
                                value={creator}
                                placeholder="Input your alias"
                                onChange={(e, newValue) => {
                                    if (
                                        newValue !== null &&
                                        newValue !== undefined
                                    ) {
                                        this.setState({
                                            creator: newValue,
                                        });
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        horizontal
                        horizontalAlign="end"
                        verticalAlign="center"
                        className="btnLayout"
                    >
                        <PrimaryButton text="Create" onClick={this._onSubmit} />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <DefaultButton text="Reset" onClick={this._clearForm} />
                    </Stack>
                </Stack>
            </Modal>
        );
    }

    private _onSubmit() {
        const AliasParamCount = 2;
        const { workspace, onCloseModal, onAddRecord } = this.props;
        const {
            alias,
            vertical,
            algorithm,
            type,
            startDate,
            endDate,
            creator,
        } = this.state;
        if (
            workspace &&
            alias &&
            type &&
            vertical &&
            algorithm &&
            startDate &&
            endDate &&
            creator
        ) {
            const dataArr = alias.split("@");
            const isNotNullOrWhiteSpace = dataArr.every((d) => d);
            if (dataArr.length === AliasParamCount && isNotNullOrWhiteSpace) {
                const startUtcMoment = moment(startDate).utc().startOf("month");
                const endUtcMoment = moment(endDate).utc().endOf("month");

                const recordItem = {
                    scenario: workspace,
                    modelInfo: dataArr[0],
                    runtimeVersion: dataArr[1],
                    buildSource: "",
                    testType: "",
                    detailPartitionKey: `TimeRange[${startUtcMoment.format(
                        "yyyy-MM"
                    )},${endUtcMoment.format("yyyy-MM")}]`,
                };

                VerticalTlmRecord.addRecord(workspace, recordItem)
                    .then((record) => {
                        if (record) {
                            const detail = {
                                alias: alias,
                                type: type,
                                vertical: vertical,
                                algorithm: algorithm,
                                startDate: startUtcMoment.toDate(),
                                endDate: endUtcMoment.toDate(),
                                storageRoot: `verticaltlm_evaluation_data/${alias}@${startUtcMoment.format(
                                    "yyyyMM"
                                )}@${endUtcMoment.format("yyyyMM")}`,
                                creator: creator,
                                lastUpdateTimestamp: Date.now(),
                            };

                            VerticalTlmRecord.addRecordDetails(
                                record.id,
                                workspace,
                                [detail]
                            )
                                .then((recordWithDetail) => {
                                    this._clearForm();
                                    onCloseModal();
                                    onAddRecord(recordWithDetail);

                                    return recordWithDetail;
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(error);

                        if (
                            error.name ===
                            HttpStatusCode.UNPROCESSABLE_ENTITY.toString()
                        ) {
                            alert("This record has existed already");
                        }
                    });
            } else {
                alert(
                    `The "Name" field requires a @ character as the data splitter`
                );
            }
        } else {
            alert("Complete the form please");
        }
    }

    private _onVerticalChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined
    ): void {
        const { algorithmMap } = this.state;
        if (option && typeof option.key === "string") {
            if (algorithmMap.has(option.key)) {
                const algoOptions = algorithmMap.get(option.key)!;
                this.setState({
                    vertical: option.key,
                    algorithmOptions: algoOptions,
                });
            } else {
                this.setState({
                    vertical: option.key,
                });
            }
        }
    }

    private async _fetchConfig() {
        const configData = await fetchDataByPath(
            "/api/eval/blobs/verticaltlm_evaluation_data/config/telemetry_vertical_algo.json"
        );

        if (configData) {
            const config = JSON.parse(configData);
            const algorithmMap = new Map<string, string[]>();
            const verticalOptions: string[] = [];
            const keyValuePairs = Object.entries(config);
            keyValuePairs.forEach(([key, val]) => {
                if (!algorithmMap.has(key)) {
                    algorithmMap.set(key, val as string[]);
                    verticalOptions.push(key);
                }
            });

            this.setState({
                verticalOptions: verticalOptions,
                algorithmMap: algorithmMap,
            });
        }
    }

    private _clearForm() {
        this.setState({
            alias: "",
            vertical: "",
            type: this.typeOptions[0].key.toString(),
            algorithm: "",
            algorithmOptions: [],
            startDate: undefined,
            endDate: undefined,
        });
    }
}
