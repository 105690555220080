import React from "react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { TableColumn, TableList, ColumnValueType } from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    IMergedMetrics,
    Workspaces,
    Typename,
} from "../../DataContract";
import "../Common/MetricStyle.scss";
import { IDropdownStyles } from "@fluentui/react";
import { KvpStateInSession } from "../../Pages/Scenarios";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import _ from "lodash";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";

interface KvpTextlineMetric extends IMetrics<IMetricUnit> {
    fileName: string;
    rootDir: string;
    ocrToyUrl: string;
    gtKeyText: string;
    recoKeyText: string;
    gtValueText: string;
    recoValueText: string;
    instanceHash: string;
    page: number;
    predictionIndex: number;
    gtKeyIndex: string;
    gtValueIndex: number;
    keyTextDistance: number;
    keyIOU: number;
    valueIOU: number;
    valueTextDistance: 1;
}

interface IState extends IMetricState<KvpTextlineMetric> {
    selectLanguage?: string;
    queryString?: string;
}

interface IProps extends IMetricProps {
    toSelectLanguage?: string;
    queryString?: string;
}

// prettier-ignore
const KVP_TEXTLINE_COLUMNS: TableColumn[] = [
    { key: "fileName",            name: "File Name",             fieldName: "fileName",            isKey: false, valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "predictionKeyText",   name: "Key Prediction Text",   fieldName: "predictionKeyText",   isKey: false, valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "gtKeyText",           name: "Key GT Text",           fieldName: "gtKeyText",           isKey: false, valueType: ColumnValueType.String, minWidth: 300, maxWidth: 350, isResizable: true, },
    { key: "predictionValueText", name: "Value Prediction Text", fieldName: "predictionValueText", isKey: false, valueType: ColumnValueType.String, minWidth: 200, maxWidth: 250, isResizable: true, },
    { key: "gtValueText",         name: "Value GT Text",         fieldName: "gtValueText",         isKey: false, valueType: ColumnValueType.String, minWidth: 200, maxWidth: 250, isResizable: true, },
    { key: "ocrToyUrl",           name: "OcrToyUrl",             fieldName: "ocrToyUrl",           isKey: false, valueType: ColumnValueType.Url,    minWidth: 200, maxWidth: 250, isResizable: true, },
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" },
};

export class KvpTextlineView extends MetricsView<
    IProps,
    IState,
    KvpTextlineMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        let selectLanguage = props.toSelectLanguage;
        if (selectLanguage === undefined) {
            const stateKey = this.getSessionStateKey(
                props.records,
                Workspaces.Kvp
            );
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(stateStr) as KvpStateInSession;
                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }
            }
        }

        this.state = {
            evalData: {},
            entityList: [],
            selectLanguage: selectLanguage,
            queryString: this.props.queryString,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: KVP_TEXTLINE_COLUMNS,
                saveKey: `${Workspaces.Kvp}_${Typename.KvpTextlineView}`,
            })
        );
    }
    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: IMetricState<any>
    ) {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(
                this.state.matchDatasetVersion,
                prevState.matchDatasetVersion
            )
        ) {
            this.onEvaluationRecordChanged();
        }
    }

    public render() {
        const { records } = this.props;
        const { evalData, queryString, selectedColumns } = this.state;

        const columns: TableColumn[] = KVP_TEXTLINE_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        return (
            <FullScreen>
                {Object.keys(evalData).length > 0 && (
                    <TableList<KvpTextlineMetric>
                        key={this.state.selectLanguage}
                        columns={columns}
                        evalDataCount={records.length}
                        evalData={evalData}
                        downloadTableTitle={this.state.selectLanguage}
                        queryString={queryString}
                        renderTableHeader={this._renderTableHeader}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}

                {Object.keys(evalData).length === 0 && (
                    <NoDataTip>No KVP data generated</NoDataTip>
                )}
            </FullScreen>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<KvpTextlineMetric>> {
        return recordDetail
            .fetchMetricsWithRecord<IMetrics<KvpTextlineMetric>>(metricName)
            .then(([record, metrics]) => {
                let items: any = {};
                Object.entries(metrics).forEach(([_, instanceMetrics]: any) => {
                    instanceMetrics.forEach((val: KvpTextlineMetric) => {
                        const modelInfo = record.modelInfo;
                        const datasetName = recordDetail.dataset.name;
                        const rootDir = val.rootDir;
                        val.ocrToyUrl = `http://visual-invoice.westus3.cloudapp.azure.com:5000/kvp/${modelInfo}/${datasetName}/${val.fileName.replace(
                            ".ocr.json",
                            ""
                        )}/${val.page}?root_dataset=${rootDir}`;
                        items[val.instanceHash] = val;
                    });
                });
                return items;
            });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { toSelectLanguage } = this.props;
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion).filter(
            (language) => !language.toLowerCase().includes("combined")
        );
        if (languageList.length > 0) {
            const selectLangKey = this.loadSelectedLanguage(
                selectLanguage ?? toSelectLanguage,
                languageList
            );

            const stateSettings: { [key: string]: string | undefined } = {
                selectLanguage: selectLangKey,
            };

            this._onOptionsChanged(stateSettings);
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );
            this.showEvaluationResult(details, "kvp-detailed.json");
        }
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this._onQueryButtonClicked(selectLanguage);
        this.setState(
            {
                selectLanguage: selectLanguage,
            },
            () => {
                const { records, toSelectLanguage, deepLinkHandler } =
                    this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Kvp
                );
                const stateInSession: KvpStateInSession = {
                    selectLanguage: selectLanguage,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                if (deepLinkHandler && toSelectLanguage !== undefined) {
                    deepLinkHandler("overviewKvpDetailMetrics", {
                        toSelectLanguage: undefined,
                    });
                }
            }
        );
    }

    private _renderTableHeader(): JSX.Element {
        const { selectLanguage, matchDatasetVersion } = this.state;
        const languages = this.getLanguageList(matchDatasetVersion!).filter(
            (language) => !language.toLowerCase().includes("combined")
        );

        const selectLangKey = this.loadSelectedLanguage(
            selectLanguage,
            languages
        );
        const imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                styles: dropdownStyles,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this.setState({ queryString: undefined }, () => {
                        this._onOptionsChanged({
                            selectLanguage: language!.text,
                        });
                    });
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<KvpTextlineMetric>
    ): string[] {
        return Object.keys(evalData).sort();
    }
}
