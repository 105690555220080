import { Workspaces } from ".";
import {
    CheckFileWhetherExistInAzureBlob,
    fetchDataByPath,
    toCamel,
} from "../Utils";
import { vdiRequest } from "../Utils/fetch";
import { Record, RecordDetail } from "./Record";

export interface ExpInfo {
    expRunId: string;
    name: string;
    stepRunId: string;
    url: string;
}

export interface IRecordRequest {
    scenario: string;
    modelInfo: string;
    runtimeVersion: string;
    buildSource: string;
    testType: string;
    detailPartitionKey: string;
}

// interfaces to real backend responses
export interface IRecordDetailResponse {}
export interface IRecordResponse<T extends IRecordDetailResponse> {
    id: string;
    scenario: string;
    modelInfo: string;
    runtimeVersion: string;
    buildSource: string;
    testType: string;
    details: T[];
    createTimestamp: number;
    lastUpdateTimestamp: number;
    tags?: string[];
}

export abstract class RecordImpl<
    T extends IRecordDetailResponse
> extends Record {
    protected _data: IRecordResponse<T>;
    protected _detailsFilter?: (recordDetail: RecordDetail) => boolean;

    constructor(data: IRecordResponse<T>) {
        super();

        this._data = data;
    }

    get id(): string {
        return this._data.id;
    }

    get scenario(): string {
        switch (this._data.scenario) {
            case "id_document":
                return Workspaces.IdCard;
            case "business_card":
                return Workspaces.BusinessCard;
            case "industry_prebuilt":
                return Workspaces.IndustryPrebuilt;
            case "health_doc":
                return Workspaces.HealthDoc;
            case "experimental_prebuilt":
                return Workspaces.ExperimentalPrebuilt;
            default:
                return this._data.scenario;
        }
    }

    get modelInfo(): string {
        return this._data.modelInfo;
    }

    get runtimeVersion(): string {
        return this._data.runtimeVersion;
    }

    get buildSource(): string {
        return this._data.buildSource;
    }

    get createTime(): string {
        return new Date(this._data.createTimestamp).toISOString().slice(0, -5);
    }

    get createTimestamp(): number {
        return this._data.createTimestamp;
    }

    get testType(): string {
        return this._data.testType;
    }

    get dateTime(): string {
        return new Date(this._data.lastUpdateTimestamp)
            .toISOString()
            .slice(0, -5);
    }

    get dataset(): string {
        const datasetNamesSet = new Set<string>();
        this.getDatasets().forEach((dataset) => {
            if (
                dataset &&
                dataset.displayFullName &&
                !datasetNamesSet.has(dataset.displayFullName)
            ) {
                datasetNamesSet.add(dataset.displayFullName);
            }
        });

        const datasetNamesArr = Array.from(datasetNamesSet);
        return datasetNamesArr.length > 0 ? datasetNamesArr.join(" ; ") : "";
    }

    get expRunId(): string {
        const expRunids = this._getExpInfoProps("expRunId");
        return expRunids.length > 0 ? expRunids.join(" ; ") : "";
    }

    get stepRunId(): string {
        const expRunids = this._getExpInfoProps("stepRunId");
        return expRunids.length > 0 ? expRunids.join(" ; ") : "";
    }

    get tags(): string[] {
        return this._data.tags ? this._data.tags : [];
    }

    setDetailsFilter(predicate: (recordDetail: RecordDetail) => boolean): void {
        this._detailsFilter = predicate;
    }

    onNavigateTo(): void {
        this._navigateTo(this.scenario, [this]);
    }

    onCompareWith(records: Record[]): void {
        this._navigateTo(this.scenario, [this, ...records]);
    }

    async setRecordTags(
        id: string,
        tags: string[],
        workspace: string
    ): Promise<any> {
        this._data.tags = tags;
        let response = await vdiRequest(`/api/eval/${workspace}/tags/${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(tags),
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    async setRecordError(
        language: string,
        dataset: string,
        category: string,
        imageId: string,
        textlineId: string,
        reference: string,
        description: string[],
        workspace: string
    ): Promise<void> {
        let errContent: any = {};
        errContent.language = language;
        errContent.dataset = dataset;
        errContent.category = category;
        errContent.imageId = imageId;
        errContent.textlineId = textlineId;
        errContent.reference = reference;
        errContent.description = description;
        console.log(errContent);
        let response = await vdiRequest(
            `/api/eval/${workspace}/records/errors`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(errContent),
            }
        );
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    private _getExpInfoProps(key: keyof ExpInfo): string[] {
        const keySet = new Set<string>();
        this.getDetails().forEach((detail) => {
            if (detail) {
                const expInfo = detail.getRawProp<ExpInfo>("expInfo");
                if (expInfo && expInfo[key] && !keySet.has(expInfo[key])) {
                    keySet.add(expInfo[key]);
                }
            }
        });

        return Array.from(keySet);
    }

    private _navigateTo(scenario: string, records: Record[]): void {
        const recordIds = records.map((r) => r.id);
        const url = `/eval/${scenario.toLowerCase()}/id/${recordIds.join(",")}`;

        window.open(url, "_self");
    }
}

export abstract class RecordDetailImpl<
    R extends Record,
    D extends IRecordDetailResponse
> extends RecordDetail {
    protected _record: R;
    protected _detail: D;

    constructor(detail: D, record: R) {
        super();

        this._detail = detail;
        this._record = record;
    }

    getRecord(): Record {
        return this._record;
    }

    getRawProp<T>(name: string): T {
        return this._detail[name as keyof IRecordDetailResponse] as T;
    }

    checkMetricsExistence(name: string): boolean {
        const url = this.composeMetricUrl(name);
        return CheckFileWhetherExistInAzureBlob(url);
    }

    async fetchRawMetrics(name: string): Promise<string> {
        const url = this.composeMetricUrl(name);
        return fetchDataByPath(url);
    }

    async fetchMetrics<T>(name: string): Promise<T> {
        return this.fetchRawMetrics(name).then((text) => JSON.parse(text) as T);
    }

    async fetchMetricsWithCamelCasing<T>(name: string): Promise<T> {
        return this.fetchRawMetrics(name).then(
            (text) => toCamel(JSON.parse(text)) as T
        );
    }

    async fetchMetricsWithRecordDetail<T>(
        name: string
    ): Promise<[RecordDetail, T]> {
        return this.fetchMetrics<T>(name).then(
            (metrics: T) => [this, metrics] as [RecordDetail, T]
        );
    }

    async fetchMetricsWithRecord<T>(name: string): Promise<[Record, T]> {
        return this.fetchMetrics<T>(name).then(
            (metrics: T) => [this._record, metrics] as [Record, T]
        );
    }
}
