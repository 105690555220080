import "../Common/MetricStyle.scss";
import React from "react";

import {
    //Group&DetailList
    DetailsList,
    IColumn,
    IDetailsGroupRenderProps,
    SelectionMode,

    //Others
    Link,
    FontIcon,
    Label,
    Stack,
    mergeStyleSets,
    IDetailsListProps,
    IDetailsRowStyles,
    DetailsRow,
    mergeStyles,
    getTheme,
    IDetailsHeaderProps,
    DetailsHeader,
    IGroup,
} from "@fluentui/react";

import {
    FileExt,
    HttpStatusCode,
    IdentifyType,
    Record,
    RecordDetail,
    URL_PARAM_NAME_IDENTIFY_INDEX,
    URL_PARAM_NAME_IDENTIFY_TYPE,
    URL_PARAM_NAME_OCR_RESULT_URL,
} from "../../DataContract";
import _ from "lodash";

import { Consumer } from "../../Layout";
import { vdiRequest } from "../../Utils/fetch";
import { FullScreen } from "../Common/FullScreen";
import AuthLink from "../../AuthComponent/AuthLink";

interface SanityOverallResults {
    result: {
        [key: string]: {
            OVERALL: string;
            Baseline: string;
            Consistency: string;
            Recognition: string;
        };
    };
    reports: string[];
}

interface SanityDetailResults {
    name: string;
    status: string;
    duration: string;
    failureRate: string;
    results: {
        id: string;
        status: string;
        duration: string;
        message: string;
        location: string;
    }[];
}

interface IState {
    data?: [RecordDetail, SanityOverallResults];
    selectOption?: string;
    selectOptionData?: any;
    groups?: IGroup[];
    selectOptionReport?: string;
}

interface IProps {
    records: Record[];
    metricName: string;
    reportName: string;
    reportPath: string;
    isDarkTheme?: boolean;
}

const classNames = mergeStyleSets({
    title: { marginLeft: "1%" },
    tip: { fontSize: "12px", color: "#0078d4" },
    headerAndFooter: {
        padding: 8,
        margin: "8px,0",
        // Overlay the sizer bars
        position: "relative",
        zIndex: 100,
    },
    headerTitle: [
        {
            display: "inline-flex",
        },
    ],

    iconClass: {
        fontSize: 10,
        height: 10,
        width: 10,
        margin: "0 25px",
    },
});

const CONFIG_COLUMNS: IColumn[] = [
    {
        key: "config",
        name: "CONFIG",
        fieldName: "name",
        minWidth: 110,
        maxWidth: 400,
        isResizable: true,
    },
    {
        key: "overall",
        name: "OVERALL",
        fieldName: "OVERALL",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "reco",
        name: "RECOGNITION",
        fieldName: "Recognition",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "baseline",
        name: "BASELINE",
        fieldName: "Baseline",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "consistency",
        name: "CONSISTENCY",
        fieldName: "Consistency",
        minWidth: 110,
        maxWidth: 250,
        isResizable: true,
    },
];

const DETAILS_COLUMNS: IColumn[] = [
    {
        key: "id",
        name: "id",
        fieldName: "id",
        minWidth: 300,
        maxWidth: 350,
        isResizable: true,
    },
    {
        key: "status",
        name: "status",
        fieldName: "status",
        minWidth: 20,
        maxWidth: 250,
        isResizable: true,
        onRender: (item) =>
            item.status && (
                <Stack horizontal>
                    {item.status === "Pass" ? (
                        <FontIcon
                            iconName="Completed"
                            className={iconClass}
                            style={{
                                color: "#00ad56",
                            }}
                        />
                    ) : (
                        <FontIcon
                            iconName="ErrorBadge"
                            className={iconClass}
                            style={{
                                color: "#d13438",
                            }}
                        />
                    )}
                    {item.status}
                </Stack>
            ),
    },
    {
        key: "duration",
        name: "duration",
        fieldName: "duration",
        minWidth: 20,
        maxWidth: 350,
        isResizable: true,
    },
    {
        key: "message",
        name: "message",
        fieldName: "message",
        minWidth: 20,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "link",
        name: "link",
        fieldName: "link",
        minWidth: 20,
        maxWidth: 350,
        isResizable: true,
        onRender: (item) =>
            item.location && (
                <>
                    {item.link.diff && (
                        <>
                            <AuthLink
                                url={item.link.diff}
                                target="_blank"
                                styles={{
                                    root: {
                                        color: "#75b6e7",
                                    },
                                }}
                            >
                                Diff
                            </AuthLink>
                            &nbsp;&nbsp;
                        </>
                    )}

                    {item.link.recog && (
                        <>
                            <Link
                                styles={{
                                    root: {
                                        color: "#75b6e7",
                                    },
                                }}
                                onClick={() => {
                                    window.open(item.link.recog);
                                    return false;
                                }}
                            >
                                Link
                            </Link>
                            &nbsp;&nbsp;
                        </>
                    )}

                    {item.link.base && (
                        <Link
                            styles={{
                                root: {
                                    color: "#75b6e7",
                                },
                            }}
                            onClick={() => {
                                window.open(item.link.base);
                                return false;
                            }}
                        >
                            Baseline
                        </Link>
                    )}
                </>
            ),
    },
];

const tipIconClass = mergeStyles({
    fontSize: 18,
    height: 18,
    width: 18,
    marginLeft: "10px",
});

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    marginRight: "10px",
});

const theme = getTheme();

export class SanityDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this._queryDetailsResult.bind(this);
        this.state = {};
    }

    public render() {
        const { selectOption } = this.state;

        return (
            <FullScreen>
                {" "}
                <div style={{ height: "100%", overflow: "auto" }}>
                    <Stack>
                        <Label className={classNames.title}>
                            <h3>Summary</h3>
                            <div className={classNames.tip}>
                                *Tip : double click to view details
                            </div>
                        </Label>
                        {this._onRenderConfigDom()}
                    </Stack>
                    <Stack>
                        <Label className={classNames.title}>
                            <h3>Details</h3>
                            <div> Current selected config: {selectOption}</div>
                        </Label>
                        {this._onRenderTableDom()}
                    </Stack>
                </div>
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryOverallResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryOverallResult();
        }
    }

    public _queryOverallResult() {
        const { records, metricName } = this.props;
        if (records.length > 0) {
            const details = records[0].getDetails();
            if (details && details.length > 0) {
                details[0]
                    .fetchMetricsWithRecordDetail<SanityOverallResults>(
                        metricName
                    )
                    .then((data) => {
                        this.setState({
                            data: data,
                            selectOption: undefined,
                        });
                    });

                return;
            }
        }

        this.setState({
            selectOption: undefined,
        });
    }

    public _queryDetailsResult(option: string) {
        const { data } = this.state;
        if (!data) {
            return;
        }

        const [recordDetail, sanityOverallResult] = data;
        const report = sanityOverallResult.reports.find((name) =>
            name.includes(option)
        );
        if (report) {
            recordDetail
                .fetchMetricsWithRecordDetail<SanityDetailResults[]>(report)
                .then((data) => {
                    this.setState(
                        {
                            selectOption: option,
                            selectOptionReport: report,
                        },
                        () => {
                            this._prepareRenderData(data);
                        }
                    );
                });
        }
    }

    private _onRenderConfigDom() {
        const { data } = this.state;
        if (data) {
            const items = Object.entries(data[1].result)
                .filter(([key, value]) => {
                    return key !== "Overall";
                })
                .map(([key, value]) => {
                    return {
                        name: key,
                        ...value,
                    };
                });

            return (
                <DetailsList
                    columns={CONFIG_COLUMNS}
                    items={items}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={(recordId) => {
                        this._queryDetailsResult(recordId!.name);
                    }}
                    onRenderRow={this._onRenderRow}
                    onRenderDetailsHeader={this._onRenderDetailsHeader}
                    onShouldVirtualize={() => false}
                />
            );
        }
    }

    private _prepareRenderData = (
        data: [RecordDetail, SanityDetailResults[]]
    ) => {
        const { reportPath } = this.props;
        const { selectOptionReport } = this.state;
        if (!selectOptionReport) {
            return;
        }

        const [recordDetail, metrics] = data;
        const baseLocation = selectOptionReport?.split(reportPath)[0];

        let curIdx = 0;
        const groups = metrics.map((data, idx) => {
            const count = data.results.length;
            const group = {
                count: count,
                key: `group_${data.name}_${idx}`,
                name: `${data.name} ( fail rate: ${data.failureRate} )`,
                startIndex: curIdx,
                level: 0,
            };
            curIdx += count;
            return group;
        });

        let recognizeRecogIndex = 0,
            baselineRecogIndex = 0,
            consistencyRecogIndex = 0;

        const recognizeRecogUrlArr: string[] = [],
            baselineRecogUrlArr: string[] = [],
            consistencyRecogUrlArr: string[] = [];

        const items = metrics.flatMap((groupData) => {
            return groupData.results.map((data) => {
                const link = recordDetail.composeMetricUrl(
                    groupData.name === "Baseline" ||
                        groupData.name === "Consistency"
                        ? this._getOcrResultUrl(
                              baseLocation,
                              reportPath,
                              data.location,
                              true
                          )
                        : this._getOcrResultUrl(
                              baseLocation,
                              reportPath,
                              data.location
                          )
                );

                let recogBlobPath = link;
                if (groupData.name === "Baseline") {
                    let baseLineBlobPath: string | undefined;
                    if (
                        data.status === "Fail" &&
                        link.toLowerCase().endsWith(`.${FileExt.Html}`)
                    ) {
                        baseLineBlobPath =
                            link
                                .replace("/diff_baseline/", "/baseline/")
                                .slice(0, -FileExt.Html.length) + FileExt.Json;

                        const recogMetric = metrics.find(
                            (m) =>
                                m.name === "Recognition" &&
                                m.results.some((r) => r.id === data.id)
                        );

                        if (recogMetric) {
                            const recogData = recogMetric.results.find(
                                (r) => r.id === data.id
                            )!;
                            recogBlobPath = recordDetail.composeMetricUrl(
                                this._getOcrResultUrl(
                                    baseLocation,
                                    reportPath,
                                    recogData.location
                                )
                            );
                        }
                    }

                    const recogJsonUrl = this._getDynamicOcrToyUrl(
                        recogBlobPath,
                        IdentifyType.BaselineRecog,
                        baselineRecogIndex
                    );
                    data.location &&
                        baselineRecogUrlArr.push(recogJsonUrl) &&
                        baselineRecogIndex++;
                    return {
                        ...data,
                        link: {
                            diff: link,
                            recog: recogJsonUrl,
                            baseBlobPath: baseLineBlobPath,
                            base: "",
                        },
                    };
                } else {
                    const recogJsonUrl =
                        groupData.name === "Recognition"
                            ? this._getDynamicOcrToyUrl(
                                  link,
                                  IdentifyType.RecognizeRecog,
                                  recognizeRecogIndex
                              )
                            : this._getDynamicOcrToyUrl(
                                  link,
                                  IdentifyType.ConsistencyRecog,
                                  consistencyRecogIndex
                              );

                    if (data.location) {
                        if (groupData.name === "Recognition") {
                            recognizeRecogUrlArr.push(recogJsonUrl) &&
                                recognizeRecogIndex++;
                        } else {
                            consistencyRecogUrlArr.push(recogJsonUrl) &&
                                consistencyRecogIndex++;
                        }
                    }
                    return {
                        ...data,
                        link: { recog: recogJsonUrl },
                    };
                }
            });
        });

        this.setState({ selectOptionData: items, groups: groups }, () => {
            const baselinks = items.map((item) => item.link.baseBlobPath);

            Promise.all(
                baselinks.map((baselink) => {
                    return this.AsyncCheckFileWhetherExistInAzureBlob(baselink);
                })
            ).then((isExistArr) => {
                const baselineBaseUrlArr: string[] = [];
                const deepItems = _.cloneDeep(items);
                let baselineBaseIndex = 0;

                deepItems.forEach((item, index) => {
                    const isExist = isExistArr[index];
                    if (isExist && item.link.baseBlobPath) {
                        const baseLine = this._getDynamicOcrToyUrl(
                            item.link.baseBlobPath,
                            IdentifyType.BaselineBase,
                            baselineBaseIndex
                        );
                        item.link.base = baseLine;
                        item.location &&
                            baselineBaseUrlArr.push(baseLine) &&
                            baselineBaseIndex++;
                    }
                });

                this.setState({ selectOptionData: deepItems }, () => {
                    sessionStorage.setItem(
                        IdentifyType.RecognizeRecog,
                        JSON.stringify(recognizeRecogUrlArr)
                    );

                    sessionStorage.setItem(
                        IdentifyType.BaselineRecog,
                        JSON.stringify(baselineRecogUrlArr)
                    );

                    sessionStorage.setItem(
                        IdentifyType.ConsistencyRecog,
                        JSON.stringify(consistencyRecogUrlArr)
                    );

                    sessionStorage.setItem(
                        IdentifyType.BaselineBase,
                        JSON.stringify(baselineBaseUrlArr)
                    );
                });
            });
        });
    };

    private AsyncCheckFileWhetherExistInAzureBlob = (
        blobFileLink: string | undefined
    ): Promise<boolean> => {
        if (blobFileLink) {
            let queryStr = "";
            const querySymbolIndex = blobFileLink.indexOf("?");
            if (querySymbolIndex > -1) {
                queryStr = blobFileLink.substring(querySymbolIndex);
                blobFileLink = blobFileLink.substring(0, querySymbolIndex);
            }

            const urlParams = queryStr
                ? new URLSearchParams(queryStr)
                : new URLSearchParams();

            urlParams.set("comp", "metadata");
            blobFileLink += `?${urlParams.toString()}`;

            return vdiRequest(blobFileLink, { method: "GET" }).then(
                (response) => {
                    if (response.status === HttpStatusCode.OK) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );
        } else {
            return Promise.resolve(false);
        }
    };

    private _onRenderTableDom() {
        const { groups, selectOptionData } = this.state;

        return (
            <>
                {groups && selectOptionData && (
                    <DetailsList
                        key={JSON.stringify(new Date())}
                        columns={DETAILS_COLUMNS}
                        items={selectOptionData}
                        groups={groups}
                        groupProps={{
                            onRenderHeader: this._onRenderGroupHeader,
                        }}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow}
                        onShouldVirtualize={() => false}
                    />
                )}
            </>
        );
    }

    private _onRenderGroupHeader: IDetailsGroupRenderProps["onRenderHeader"] = (
        props
    ) => {
        if (props) {
            const tipMark = props.group!.name.slice(
                props.group!.name.indexOf(":") + 1,
                props.group!.name.indexOf("/")
            );
            return (
                <div className={classNames.headerAndFooter}>
                    <div className={classNames.headerTitle}>
                        <Consumer>
                            {(value) => {
                                return (
                                    <Stack horizontal>
                                        <Link
                                            onClick={() =>
                                                props!.onToggleCollapse!(
                                                    props!.group!
                                                )
                                            }
                                            styles={{
                                                root: {
                                                    color: "#75b6e7",
                                                },
                                            }}
                                        >
                                            {props.group!.isCollapsed ? (
                                                <FontIcon
                                                    iconName="DoubleChevronUp"
                                                    className={
                                                        classNames.iconClass
                                                    }
                                                    style={{
                                                        color: value
                                                            ? "white"
                                                            : "black",
                                                    }}
                                                />
                                            ) : (
                                                <FontIcon
                                                    iconName="DoubleChevronDown"
                                                    className={
                                                        classNames.iconClass
                                                    }
                                                    style={{
                                                        color: value
                                                            ? "white"
                                                            : "black",
                                                    }}
                                                />
                                            )}
                                        </Link>
                                        {Number(tipMark) === 0 ? (
                                            <FontIcon
                                                iconName="CompletedSolid"
                                                className={tipIconClass}
                                                style={{
                                                    color: "#217346",
                                                    paddingRight: "15px",
                                                }}
                                            />
                                        ) : (
                                            <FontIcon
                                                iconName="AlertSolid"
                                                className={tipIconClass}
                                                style={{
                                                    color: "#a4373a",
                                                    paddingRight: "15px",
                                                }}
                                            />
                                        )}
                                    </Stack>
                                );
                            }}
                        </Consumer>
                        {props.group!.name}
                    </div>
                </div>
            );
        }
        return null;
    };

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        customStyles.root = {
            "&:focus": {
                ".ms-DetailsRow-check": {
                    opacity: "none",
                },
            },
        };
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                customStyles.root = {
                    backgroundColor: this.props.isDarkTheme
                        ? theme.palette.neutralDark
                        : theme.palette.neutralLighterAlt,
                };
            } else {
                customStyles.root = {
                    backgroundColor: this.props.isDarkTheme
                        ? theme.palette.neutralPrimary
                        : theme.palette.white,
                };
            }
            return (
                <Stack horizontal verticalAlign="center" styles={customStyles}>
                    {props?.item.OVERALL === "Fail" && (
                        <FontIcon
                            aria-label="Compass"
                            iconName="AlertSolid"
                            className={tipIconClass}
                            style={{
                                color: "#a4373a",
                            }}
                        />
                    )}
                    {props?.item.OVERALL === "Pass" && (
                        <FontIcon
                            aria-label="Compass"
                            iconName="CompletedSolid"
                            className={tipIconClass}
                            style={{
                                color: "#217346",
                            }}
                        />
                    )}
                    <DetailsRow {...props} styles={customStyles} />
                </Stack>
            );
        }
        return null;
    };

    private _getOcrResultUrl(
        baseLocation: string,
        reportPath: string,
        dataLocation: string,
        hasReportFolder: boolean = false
    ) {
        return hasReportFolder
            ? `${baseLocation}${reportPath}/_REPORT/${dataLocation}`
            : `${baseLocation}${reportPath}/${dataLocation}`;
    }

    private _getDynamicOcrToyUrl(
        url: string,
        identifyType: IdentifyType,
        index: number
    ) {
        return `/ocrtoy?${URL_PARAM_NAME_IDENTIFY_INDEX}=${index}&${URL_PARAM_NAME_IDENTIFY_TYPE}=${identifyType}&${URL_PARAM_NAME_OCR_RESULT_URL}=${encodeURIComponent(
            url
        )}`;
    }

    private _onRenderDetailsHeader(
        props: IDetailsHeaderProps | undefined
    ): JSX.Element {
        if (props) {
            return (
                <Consumer>
                    {(value) => {
                        return (
                            <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{
                                    root: {
                                        backgroundColor: value
                                            ? theme.palette.neutralDark
                                            : theme.palette.white,
                                    },
                                }}
                            >
                                <FontIcon
                                    aria-label="Compass"
                                    iconName="CompletedSolid"
                                    className={tipIconClass}
                                    style={{
                                        color: "transparent",
                                        display: "hidden",
                                    }}
                                />

                                <DetailsHeader {...props}></DetailsHeader>
                            </Stack>
                        );
                    }}
                </Consumer>
            );
        } else {
            return <></>;
        }
    }
}
