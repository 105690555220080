import React from "react";
import "../Common/MetricStyle.scss";

import { ILineChartDataPoint, LineChart } from "@uifabric/charting";

import {
    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,

    //others
    Label,
    Stack,
} from "@fluentui/react";

import { ICommonState } from "../Common/CommonMetrics";
import {
    IChartDataItem,
    OcrLatencyPerfMetrics,
    ShortenMetricKeyName,
} from "./Interface";

import { CommonAnalysisView, LatencyType } from "./CommonAnalysisView";
import { getColorByIndex } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { Typename } from "../../DataContract";
import { MetricAnalysisDefaultColumns } from "./MetricAnalysisColumn";
import { NoDataTip } from "../../Controls/NoDataTip";
import {
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../Utils/LoadingUtil";
import _ from "lodash";

interface IState extends ICommonState<OcrLatencyPerfMetrics> {
    renderData: [string, [string, IChartDataItem[]][]][];
}

export class MetricAnalysis extends CommonAnalysisView<
    IState,
    OcrLatencyPerfMetrics
> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedColumns: MetricAnalysisDefaultColumns[this.workSpace],
            dataItems: props.dataItems,
            renderData: [],
        };
        store.dispatch(
            updateStateAction({
                saveKey: `${this.workSpace}_${Typename.LatencyMetrics}_MetricAnalysis`,
                vertical: true,
            })
        );
    }

    queryMetricsResult(load = false) {
        if (load) {
            showPrepareRenderDataLoading();
            this._queryMetricsResult("overall_latency_metrics.json");
        }
    }

    public componentDidMount() {
        super.componentDidMount();
        this.setState({
            renderData: this._prepareAnalysisRenderData(
                true,
                this.props.crossLanguage,
                LatencyType.MetricAnalysis
            ),
        });
    }

    public componentDidUpdate(
        prevProps: any,
        prevState: ICommonState<OcrLatencyPerfMetrics>
    ): void {
        if (!_.isEqual(this.props.records, prevProps.records)) {
            this.queryMetricsResult(true);
        }
        if (
            !_.isEqual(this.props.crossLanguage, prevProps.crossLanguage) ||
            !_.isEqual(this.state.dataItems, prevState.dataItems)
        ) {
            showPrepareRenderDataLoading();
            const data = this._prepareAnalysisRenderData(
                true,
                this.props.crossLanguage,
                LatencyType.MetricAnalysis
            );
            hidePrepareRenderDataLoading();
            this.setState({ renderData: data });
        }
    }

    calculationChartPointX = (chartPoints: ILineChartDataPoint[]) => {
        const { includesLongTail } = this.props;
        let resultPoints = chartPoints.filter(
            (element) =>
                (element.x as number) <= (includesLongTail === true ? 100 : 90)
        );

        if (
            includesLongTail &&
            !chartPoints.find((point) => (point.x as number) > 90)
        ) {
            resultPoints = resultPoints.map((point) => {
                point.x =
                    (100 * (point.x as number)) /
                    (resultPoints[resultPoints.length - 1].x as number);
                return point;
            });
        }

        return resultPoints;
    };

    public render() {
        const { renderData } = this.state;

        let yMax3Before: number[] = [];
        return renderData.length !== 0 ? (
            <div style={{ height: "100%", overflow: "hidden auto" }}>
                {renderData.map(([datasetName, groupData], idx) => {
                    const filterGroupData = groupData.filter((gData) => {
                        if (this.state?.selectedColumns) {
                            return this.state.selectedColumns.includes(
                                gData[0]
                            );
                        }
                        return true;
                    });
                    const chartDatas = filterGroupData.map(
                        ([chartname, chartItems], index) => {
                            chartItems.map((chartItem, chartIndex) => {
                                return chartItem.chartData.chartYMaxAxis;
                            });
                            const points = chartItems.map(
                                (chartItem, chartIndex) => {
                                    return {
                                        legend:
                                            chartItem.data?.recordDetail.name ??
                                            "",
                                        data: this.calculationChartPointX(
                                            chartItem.chartData.chartPoints
                                        ),
                                        color: getColorByIndex(chartIndex),
                                    };
                                }
                            );
                            const chartYMaxAxiss = chartItems.map(
                                (chartItem) => {
                                    return chartItem.chartData.chartYMaxAxis;
                                }
                            );

                            const yMaxValue = Math.max.apply(
                                Math,
                                chartYMaxAxiss
                            );
                            return {
                                chartToDisplay: {
                                    chartTitle: ShortenMetricKeyName(chartname),
                                    lineChartData: points,
                                },
                                yMaxValue: yMaxValue,
                            };
                        }
                    );
                    let numArr: number[] = [];

                    let num = Math.max.apply(
                        Math,
                        chartDatas.map(function (ele, index) {
                            if (index < 3) {
                                ele.chartToDisplay.lineChartData.map(
                                    (lineData) =>
                                        numArr.push(
                                            Math.max(
                                                ...lineData.data.map((d) => d.y)
                                            )
                                        )
                                );

                                return Math.max(...numArr);
                            } else {
                                return 0;
                            }
                        })
                    );
                    yMax3Before.push(num);

                    return (
                        <div key={`dataset_${idx}`}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Label>{datasetName}</Label>
                            </Stack>
                            <DocumentCard
                                key={`chart_${datasetName}`}
                                className="overview__card"
                                type={DocumentCardType.compact}
                                style={{ width: `4000px` }}
                            >
                                {chartDatas.map((chartData, index) => {
                                    return (
                                        <DocumentCardDetails
                                            key={`document_${index}_${new Date()}`}
                                        >
                                            <DocumentCardTitle
                                                className="overview__title"
                                                title={`${chartData.chartToDisplay.chartTitle}`}
                                            />
                                            <div
                                                style={{
                                                    height: `600px`,
                                                }}
                                                id={`document_${index}`}
                                            >
                                                <LineChart
                                                    data={
                                                        chartData.chartToDisplay
                                                    }
                                                    yMaxValue={
                                                        index < 3
                                                            ? yMax3Before[idx]
                                                            : Math.max.apply(
                                                                  Math,
                                                                  chartDatas.map(
                                                                      function (
                                                                          ele
                                                                      ) {
                                                                          return ele.yMaxValue;
                                                                      }
                                                                  )
                                                              )
                                                    }
                                                />
                                            </div>
                                        </DocumentCardDetails>
                                    );
                                })}
                            </DocumentCard>
                        </div>
                    );
                })}
            </div>
        ) : (
            <NoDataTip>No Latency Metrics Generated</NoDataTip>
        );
    }
}
