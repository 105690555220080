import * as React from "react";
import "./PowerBIHomePage.scss";
import { RouteComponentProps } from "react-router-dom";
export class PowerBIHomePage extends React.Component<RouteComponentProps> {
    public render() {
        return (
            <div className="iframediv">
                <iframe
                    title="AML GPU Report"
                    style={{
                        border: "none",
                        margin: 0,
                        padding: 0,
                    }}
                    width="100%"
                    height="1150"
                    src="https://msit.powerbi.com/reportEmbed?reportId=ed2e2a0f-f3ba-4a09-823a-bff44f5b36ba&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&$filter=Month ge datetime'{{currentDateTimeOffset}}-8760:00:00' and Month le datetime'{{currentDateTimeOffset}}'"
                    allowFullScreen={true}
                ></iframe>
            </div>
        );
    }
}
