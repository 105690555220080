import { Dataset } from "./Dataset";
import { RecordDetail } from "./Record";
import { OcrRecord } from "./OcrRecord";
import {
    IRecordResponse,
    IRecordDetailResponse,
    RecordImpl,
    RecordDetailImpl,
} from "./RecordImpl";
import { EvalInfoKeys, PAGE_SIZE } from "./GlobalDataEntity";
import { vdiRequest } from "../Utils/fetch";

interface IReleaseRecordDetail extends IRecordDetailResponse {
    cogsTest: { overviewReport: string; detailReports: string[] };
    loadTest: { overviewReport: string; detailReports: string[] };
    perfTest: { overviewReport: string; detailReports: string[] };
    reliTest: { overviewReport: string; detailReports: string[] };
    sanityTest: { overviewReport: string; detailReports: string[] };
    baselineTest: { overviewReport: string; detailReports: string[] };
    verticalSanityTest: { overviewReport: string; detailReports: string[] };
    formRecognizer30SanityTest: {
        overviewReport: string;
        detailReports: string[];
    };
    accuracyTest: {
        read: any[];
        layout: any[];
        slimRead: any[];
        custom: any[];
    };
}

class DummyDataset extends Dataset {
    private _record: ReleaseRecord;

    constructor(record: ReleaseRecord) {
        super();

        this._record = record;
    }

    get name(): string {
        return "Sanity Dataset";
    }

    get version(): string {
        return "1";
    }

    get fullName(): string {
        return "Sanity Dataset";
    }

    get displayName(): string {
        return "Sanity Dataset";
    }

    get displayFullName(): string {
        return "Sanity Dataset";
    }

    get categories(): string[] {
        throw new Error("Not supported");
    }
    get language(): string {
        throw new Error("Not supported");
    }

    fetchImageListByCategory(category: string): Promise<string[]> {
        throw new Error("Not supported");
    }

    fetchImageList(): Promise<string[]> {
        throw new Error("Not supported");
    }

    getImageUrl(imageName: string): string {
        throw new Error("Not supported");
    }
}

interface IOverallMetrics {
    cogsResult: {};
    cogsOverallResult: {};
    loadResult: {};
    perfResult: {};
    reliResult: {};
    sanityResult: {};
    baselineResult: {};
    verticalSanityResult: {};
    formRecognizer30SanityResult: {};
    cpuUsages: {};
    memUsages: {};
    accuracyResult: {
        read: any[];
        layout: any[];
        slimRead: any[];
        custom: any[];
    };
}

interface JsonResult {
    [key: string]: { [key: string]: string };
}

class ReleaseRecordDetail extends RecordDetailImpl<
    ReleaseRecord,
    IReleaseRecordDetail
> {
    private _dataset: DummyDataset;
    private _overallMetrics!: IOverallMetrics;

    constructor(detail: IReleaseRecordDetail, record: ReleaseRecord) {
        super(detail, record);

        this._dataset = new DummyDataset(record);
    }

    get name(): string {
        return this._record.name;
    }

    get dataset(): Dataset {
        return this._dataset;
    }

    get overallMetrics(): IOverallMetrics {
        return this._overallMetrics;
    }

    _initOverallMetrics(): Promise<IOverallMetrics> {
        return Promise.all([
            this._detail.cogsTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.cogsTest.overviewReport
                  )
                : {},
            this._detail.loadTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.loadTest.overviewReport
                  )
                : {},
            this._detail.perfTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.perfTest.overviewReport
                  )
                : {},
            this._detail.sanityTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.sanityTest.overviewReport
                  )
                : {},
            this._detail.verticalSanityTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.verticalSanityTest.overviewReport
                  )
                : {},
            this._detail.formRecognizer30SanityTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.formRecognizer30SanityTest.overviewReport
                  )
                : {},
            this._detail.reliTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.reliTest.overviewReport
                  )
                : {},
            this._detail.baselineTest
                ? this.fetchMetrics<JsonResult>(
                      this._detail.baselineTest.overviewReport
                  )
                : {},
        ]).then(
            ([
                cogsResult,
                loadResult,
                perfResult,
                sanityResult,
                verSanityResult,
                formRecognizer30SanityResult,
                relirawResult,
                baselineResult,
            ]) => {
                //extract cpu/mem usages from reli result
                let reli: any = {};
                for (const filter of ["Max CPU", "Max Memory"]) {
                    reli[filter] = relirawResult["reli"]
                        ? relirawResult["reli"][filter]
                        : null;
                }
                let load: any = {};
                for (const filter of ["Max CPU", "Max Memory"]) {
                    load[filter] = relirawResult["load"]
                        ? relirawResult["load"][filter]
                        : null;
                }
                const reliResult = { load: load, reli: reli };
                let cpuUsages: any = {},
                    memUsages: any = {};
                if (relirawResult["load"]) {
                    Object.entries(relirawResult["load"]).forEach(
                        ([name, value]) => {
                            if (name.endsWith("cpu_usage")) {
                                cpuUsages[name] = value;
                            } else if (name.endsWith("mem_usage")) {
                                memUsages[name] = value;
                            }
                        }
                    );
                }
                // convert cogs format
                let cogsOverallResult: any = {};
                Object.entries(cogsResult).forEach(([expName, value]) => {
                    if (value && "cogs" in (value as any)) {
                        (value as any)["cogs"].forEach((item: any) => {
                            cogsOverallResult[item["name"]] =
                                cogsOverallResult[item["name"]] ?? {};
                            cogsOverallResult[item["name"]][expName] =
                                item["cogs"];
                        });
                    }
                });
                return {
                    cogsResult: cogsResult,
                    cogsOverallResult: cogsOverallResult,
                    loadResult: loadResult,
                    perfResult: perfResult,
                    reliResult: reliResult,
                    sanityResult: sanityResult,
                    verticalSanityResult: verSanityResult,
                    formRecognizer30SanityResult: formRecognizer30SanityResult,
                    cpuUsages: cpuUsages,
                    memUsages: memUsages,
                    accuracyResult: this._detail.accuracyTest,
                    baselineResult: baselineResult,
                };
            }
        );
    }

    _processRawMetrics(name: string, text: string): string {
        if (name.endsWith("kubectlPods.txt")) {
            const arr = text.replace("\r\n", "\n").split("\n");
            const keys = arr[0]
                .split(" ")
                .filter((item: string) => item.length > 0);
            const values: string[][] = arr
                .slice(1)
                .map((str: string) =>
                    str.split(" ").filter((item: string) => item.length > 0)
                );
            const objects = values.map((value: string[]) => {
                let result: any = {};
                for (let i = 0, len = keys.length; i < len; i++) {
                    result[keys[i].toLowerCase()] = value[i];
                }
                return result;
            });
            return JSON.stringify(objects);
        } else if (
            name.includes("SanityTest/") &&
            name.endsWith("report.json")
        ) {
            // we only care about the tests part
            const obj = JSON.parse(text);
            return JSON.stringify(obj.tests);
        }
        return text;
    }

    async fetchRawMetrics(name: string): Promise<string> {
        if (
            [
                "overall",
                "sanity",
                "verSanity",
                "formRecognizer30Sanity",
                "perf",
                "cogs",
                "baseline",
            ].includes(name)
        ) {
            if (!!this._overallMetrics) {
                return this.composeReleaseMetrics(name)!;
            } else {
                return this._initOverallMetrics().then((result) => {
                    this._overallMetrics = result;
                    return this.composeReleaseMetrics(name)!;
                });
            }
        }

        return super
            .fetchRawMetrics(name)
            .then((text) => this._processRawMetrics(name, text));
    }

    composeReleaseMetrics(name: string) {
        if (name === "overall") {
            return JSON.stringify(this._overallMetrics);
        } else if (name === "sanity") {
            return JSON.stringify({
                result: this._overallMetrics.sanityResult,
                reports: this._detail.sanityTest?.detailReports,
            });
        } else if (name === "verSanity") {
            return JSON.stringify({
                result: this._overallMetrics.verticalSanityResult,
                reports: this._detail.verticalSanityTest?.detailReports,
            });
        } else if (name === "formRecognizer30Sanity") {
            return JSON.stringify({
                result: this._overallMetrics.formRecognizer30SanityResult,
                reports: this._detail.formRecognizer30SanityTest?.detailReports,
            });
        } else if (name === "perf") {
            return JSON.stringify({
                result: this._overallMetrics.perfResult,
                reports: this._detail.perfTest?.detailReports,
            });
        } else if (name === "cogs") {
            return JSON.stringify({
                result: this._overallMetrics.cogsResult,
                reports: this._detail.cogsTest?.detailReports,
            });
        } else if (name === "baseline") {
            return JSON.stringify({
                result: this._overallMetrics.baselineResult,
                reports: this._detail.baselineTest?.detailReports,
            });
        }
    }

    composeMetricUrl(name: string): string {
        return `/api/eval/blobs/${name}`;
    }

    async fetchFileList(name: string): Promise<string[]> {
        throw new Error("Not supported");
    }
}

export class ReleaseRecord extends RecordImpl<IReleaseRecordDetail> {
    private _initDetails: boolean = false;

    private _details: ReleaseRecordDetail[] = [];
    private _favorite: boolean = false;

    private _readAccuracyRecord: OcrRecord;
    private _layoutAccuracyRecord: OcrRecord;
    private _slimReadAccuracyRecord: OcrRecord;
    private _customAccuracyRecord: OcrRecord;

    constructor(data: IRecordResponse<IReleaseRecordDetail>) {
        super(data);

        this._details = this._data.details.map((detail, i) => {
            return new ReleaseRecordDetail(detail, this);
        });

        this._favorite = false;

        this._readAccuracyRecord = new OcrRecord(
            {
                ...data,
                details: data.details[0]?.accuracyTest?.read ?? [],
            },
            false
        );
        this._layoutAccuracyRecord = new OcrRecord(
            {
                ...data,
                details: data.details[0]?.accuracyTest?.layout ?? [],
            },
            false
        );
        this._slimReadAccuracyRecord = new OcrRecord(
            {
                ...data,
                details: data.details[0]?.accuracyTest?.slimRead ?? [],
            },
            false
        );
        this._customAccuracyRecord = new OcrRecord(
            {
                ...data,
                details: data.details[0]?.accuracyTest?.custom ?? [],
            },
            false
        );
    }

    get name(): string {
        return this.id;
    }

    get favorite(): boolean {
        return this._favorite;
    }
    set favorite(fav: boolean) {
        this._favorite = fav;
    }

    get readAccuracyRecord(): OcrRecord {
        return this._readAccuracyRecord;
    }

    get layoutAccuracyRecord(): OcrRecord {
        return this._layoutAccuracyRecord;
    }

    get slimReadAccuracyRecord(): OcrRecord {
        return this._slimReadAccuracyRecord;
    }

    get customAccuracyRecord(): OcrRecord {
        return this._customAccuracyRecord;
    }

    getDatasets(): Dataset[] {
        return [new DummyDataset(this)];
    }

    getDetails(): RecordDetail[] {
        if (this._detailsFilter !== undefined) {
            return this._details.filter(this._detailsFilter);
        } else {
            return this._details;
        }
    }

    async initDetails(): Promise<boolean> {
        if (!this._initDetails) {
            // init accuracy test records
            await this._readAccuracyRecord.initDetails();
            await this._layoutAccuracyRecord.initDetails();
            await this._slimReadAccuracyRecord.initDetails();
            await this._customAccuracyRecord.initDetails();
        }

        this._initDetails = true;
        return true;
    }

    static async fetchAll(
        workspace: string,
        pageIndex: number = 1,
        searchs: string = "",
        limit: number = PAGE_SIZE,
        favRecordIds: string[] | null = null
    ): Promise<ReleaseRecord[]> {
        let url = `/api/eval/${workspace}/records?${searchs}&pageSize=${limit}&pageIndex=${pageIndex}`;
        if (favRecordIds && favRecordIds.length > 0) {
            url += `&favRecordIds=${favRecordIds.join(",")}`;
        }

        return vdiRequest(url, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IReleaseRecordDetail>[]) => {
                return data.map((item) => new ReleaseRecord(item));
            });
    }

    static async fetch(workspace: string, id: string): Promise<ReleaseRecord> {
        const url = `/api/eval/${workspace}/records/${id}`;
        return vdiRequest(url, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IReleaseRecordDetail>) => {
                return new ReleaseRecord(data);
            });
    }

    asEvalInfoCard(): {
        name: string;
        value: string | string[];
        width: number;
    }[] {
        // prettier-ignore
        return [
            { name: EvalInfoKeys.Id,  value: this.id,   width: 300 },
            { name: EvalInfoKeys.Tag, value: this.tags, width: 220 },
        ];
    }
}
