import {
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
} from "office-ui-fabric-react";
import React from "react";
import _ from "lodash";

const KEY_OF_SElECT_ALL: string = "~$@_all_@$~";

interface Iprops {
    label: string;
    filterkey: string;
    options: IDropdownOption[];
    selectAll?: boolean;
    onChage?: (selectKeys: Map<string, string[]>) => void;
}
interface IState {
    selectedKeys?: string[];
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { maxWidth: 300 },
};

class CustomDropdown extends React.Component<Iprops, IState> {
    constructor(p: Iprops) {
        super(p);
        this.state = {
            selectedKeys: this.getOptions().map((op) => op.key as string),
        };
    }
    public render() {
        const { label } = this.props;
        const { selectedKeys } = this.state;
        return (
            <Dropdown
                placeholder={`Select ${label}`}
                label={label}
                selectedKeys={selectedKeys}
                styles={dropdownStyles}
                multiSelect
                options={this.getOptions()}
                onChange={this.onChange}
            />
        );
    }

    componentDidUpdate(
        prevProps: Iprops,
        prevState: IState,
        snapshot?: any
    ): void {
        if (!_.isEqual(prevProps.options, this.props.options)) {
            this.setState({
                selectedKeys: this.getOptions().map((op) => op.key as string),
            });
        }
    }

    shouldComponentUpdate(
        nextProps: Iprops,
        nextState: IState,
        nextContext: any
    ): boolean {
        if (
            _.isEqual(nextProps.options, this.props.options) &&
            _.isEqual(nextState.selectedKeys, this.state.selectedKeys)
        ) {
            return false;
        }
        return true;
    }

    onChange = (
        evn: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        const { selectedKeys = [] } = this.state;
        const { onChage, filterkey } = this.props;
        const selected = option?.selected;

        let allKeys: string[] = [];
        if (option) {
            if (option?.key === KEY_OF_SElECT_ALL) {
                allKeys = !selected
                    ? []
                    : [
                          KEY_OF_SElECT_ALL,
                          ...this.props.options.map((o) => o.key as string),
                      ];
            } else {
                allKeys = selected
                    ? [...selectedKeys, option!.key as string]
                    : selectedKeys.filter((k) => k !== option.key);
                if (allKeys.length === this.props.options.length) {
                    allKeys.push(KEY_OF_SElECT_ALL);
                }
            }
            if (onChage) {
                const keys = allKeys.filter((key) => key !== KEY_OF_SElECT_ALL);
                const keyMap = new Map();
                keyMap.set(filterkey, keys);
                onChage(keyMap);
            }

            this.setState({ selectedKeys: allKeys });
        }
    };

    getOptions = () => {
        const { selectAll, options } = this.props;
        if (!selectAll) {
            return options;
        }

        const newOption = _.cloneDeep(options);

        newOption.splice(0, 0, {
            key: KEY_OF_SElECT_ALL,
            text: "Select All",
        });
        return newOption;
    };
}

interface ICustFormprops {
    labels: string[];
    keys: string[];
    evalData: any;
    onFilter?: (filterEvaldata: any) => void;
}

export class CustomDropdowns extends React.Component<ICustFormprops> {
    public render() {
        const { keys, labels } = this.props;
        return (
            <div>
                {keys.map((key, index) => {
                    return (
                        <div
                            key={key}
                            style={{
                                paddingRight: "20px",
                                display: "inline",
                                float: "left",
                            }}
                        >
                            <CustomDropdown
                                label={labels[index]}
                                filterkey={key}
                                options={this.getOptions(key)}
                                selectAll
                                onChage={this.onChage}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
    shouldComponentUpdate(
        nextProps: ICustFormprops,
        nextState: any,
        nextContext: any
    ): boolean {
        if (_.isEqual(nextProps.evalData, this.props.evalData)) {
            return false;
        }
        return true;
    }
    onChage = (selectKeys: Map<string, string[]>) => {
        const tempEvalData = _.cloneDeep(this.props.evalData);
        const filterEvalData: any = {};
        const allKeys = Array.from(selectKeys.keys());
        for (const key of Object.keys(tempEvalData)) {
            const values: any[] = tempEvalData[key];
            const filterValues = values.filter((value) => {
                return allKeys.every((selectKey) => {
                    const selectValue = selectKeys.get(selectKey);
                    return selectValue?.includes(String(value[selectKey]));
                });
            });
            if (filterValues.length > 0) {
                filterEvalData[key] = filterValues;
            }
        }

        if (this.props.onFilter) {
            this.props.onFilter(filterEvalData);
        }
    };

    getOptions = (key: string) => {
        const { evalData } = this.props;
        const datas = Object.values(evalData || {})
            .flatMap((v) => v)
            .filter((v) => v);

        const values = Array.from(new Set(datas.map((data: any) => data[key])));
        return values.map((v) => {
            return { key: String(v), text: String(v) };
        });
    };
}
