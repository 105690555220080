import * as React from "react";
import { Stack, Label } from "@fluentui/react";

export class NotFound extends React.Component {
    public render() {
        return (
            <Stack
                verticalFill
                verticalAlign="start"
                style={{ marginLeft: "2%" }}
            >
                <Label>
                    <h1>404 page not found</h1>
                </Label>
                <Label>The page you are looking for does not exist.</Label>
            </Stack>
        );
    }
}
