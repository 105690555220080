import React from "react";

import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import { OverviewTable } from "../../Controls/OverviewTable";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumContrastPolicy,
    TableColumn,
    VerticalBarChart,
} from "../../Controls";
import { getColorByIndex } from "../../Utils";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardType,
    Label,
} from "@fluentui/react";
import _ from "lodash";
import mainPageObservanle from "../../Pages/MainPageObservable";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

export interface KvpFieldMetrics {
    keyF1: number;
    pairF1: number;
    valueF1: number;
    keyPrecision: number;
    pairPrecision: number;
    valuePrecision: number;
    keyRecall: number;
    pairRecall: number;
    valueRecall: number;
    totalGTKeys: number;
    totalRecoKeys: number;
    correctKeys: number;
    partialMatchKeys: number;
    falsePositives: number;
    correctPairs: number;
    totalGTValues: number;
    totalRecoValues: number;
    correctValues: number;
    falsePosValues: number;
    textKeyF1: number;
    textPairF1: number;
    textValueF1: number;
    textKeyPrecision: number;
    textPairPrecision: number;
    textValuePrecision: number;
    textKeyRecall: number;
    textPairRecall: number;
    textValueRecall: number;
    textTotalGTKeys: number;
    textTotalRecoKeys: number;
    textCorrectKeys: number;
    textPartialMatchKeys: number;
    textFalsePositives: number;
    textCorrectPairs: number;
    textTotalGTValues: number;
    textTotalRecoValues: number;
    textCorrectValues: number;
    textFalsePosValues: number;
    cBTotalGTKeys: number;
    cBTotalRecoKeys: number;
    cBCorrectKeys: number;
    cBPartialMatchKeys: number;
    cBFalsePositives: number;
    cBCorrectPairs: number;
    cBKeyPrecision: number;
    cBKeyRecall: number;
    cBKeyF1: number;
    cBPairPrecision: number;
    cBPairRecall: number;
    cBPairF1: number;
    cBTotalGTValues: number;
    cBTotalRecoValues: number;
    cBCorrectValues: number;
    cBFalsePosValues: number;
    cBValuePrecision: number;
    cBValueRecall: number;
    cBValueF1: number;
    DatasetName?: string;

    precision?: number;
    recall?: number;
    f1?: number;
    xChunkPrecision?: number;
    xChunkRecall?: number;
    xChunkF1?: number;
    totalGT?: number;
    totalXchunkGT?: number;
    gtXchunkRatio?: number;
    name?: string;

    detect_val_to_wrong_key_numFiles?: number;
    detect_val_to_wrong_key_numKvps?: number;
    detect_missing_val_numFiles?: number;
    detect_missing_val_numKvps?: number;
    detect_key_maps_to_value_numFiles?: number;
    detect_key_maps_to_value_numKvps?: number;
    detect_n_keys_to_one_numFiles?: number;
    detect_n_keys_to_one_numKvps?: number;
    count?: number;

    [key: string]: any;
}

export interface KvpConnomNameMetrics {
    commonNameGoldenPrecision?: number;
    commonNamePrecision?: number;
    commonNameGoldenRecall?: number;
    commonNameRecall?: number;
    commonNameGoldenF1?: number;
    commonNameF1?: number;
    [key: string]: any;
}

interface IKvpDataItem {
    data?: IDataItem;
    fieldMetrics: KvpFieldMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: KvpFieldMetrics;
}

interface IState extends ICommonState<KvpFieldMetrics> {
    dataItems: IDataItem[];
}

interface IProps extends ICommonProps {
    kvpDocMetricsDeepLinkHandler?: (key: string, linkData: any) => void;
    storageVersion?: string;
}

const CLIENT_WIDTH = document.documentElement.clientHeight - 133;

// prettier-ignore
const KVP_COLUMNS: TableColumn[] = [
    { key: "name",                      name: "",                       fieldName: "name",                 valueType: ColumnValueType.String,  minWidth: 70,   maxWidth: 100,                            isResizable: true,  distinctStr: true, },
    { key: "precision",                 name: "Precision",              fieldName: "precision",            valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "recall",                    name: "Recall",                 fieldName: "recall",               valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "f1",                        name: "F1",                     fieldName: "f1",                   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "xChunkPrecision",           name: "Xchunk-Precision",       fieldName: "xChunkPrecision",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "xChunkRecall",              name: "Xchunk-Recall",          fieldName: "xChunkRecall",         valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "xChunkF1",                  name: "Xchunk-F1",              fieldName: "xChunkF1",             valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "totalGT",                   name: "Total GT",               fieldName: "totalGT",              valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "totalXchunkGT",             name: "Total Xchunk GT",        fieldName: "totalXchunkGT",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "gtXchunkRatio",             name: "GT Xchunk Ratio",        fieldName: "gtXchunkRatio",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
];

// prettier-ignore
const KVP_COLUMNS_OLD: TableColumn[] = [
    { key: "textKeyF1",                 name: "t.K.F1",                 fieldName: "textKeyF1",            valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textKeyPrecision",          name: "t.K.Precision",          fieldName: "textKeyPrecision",     valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "textKeyRecall",             name: "t.K.Recall",             fieldName: "textKeyRecall",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "textPairF1",                name: "t.P.F1",                 fieldName: "textPairF1",           valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textPairPrecision",         name: "t.P.Precision",          fieldName: "textPairPrecision",    valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },    
    { key: "textPairRecall",            name: "t.P.Recall",             fieldName: "textPairRecall",       valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textValueF1",               name: "t.V.F1",                 fieldName: "textValueF1",          valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textValuePrecision",        name: "t.V.Precision",          fieldName: "textValuePrecision",   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textValueRecall",           name: "t.V.Recall",             fieldName: "textValueRecall",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textTotalGTKeys",           name: "t.GTKeys",               fieldName: "textTotalGTKeys",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textTotalRecoKeys",         name: "t.RecoKeys",             fieldName: "textTotalRecoKeys",    valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textCorrectKeys",           name: "t.CorrectKeys",          fieldName: "textCorrectKeys",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textPartialMatchKeys",      name: "t.PartialMatchKeys",     fieldName: "textPartialMatchKeys", valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textFalsePositives",        name: "t.FalsePositives",       fieldName: "textFalsePositives",   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "textCorrectPairs",          name: "t.CorrectPairs",         fieldName: "textCorrectPairs",     valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textTotalGTValues",         name: "t.TotalGTValues",        fieldName: "textTotalGTValues",    valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textTotalRecoValues",       name: "t.TotalRecoValues",      fieldName: "textTotalRecoValues",  valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textCorrectValues",         name: "t.CorrectValues",        fieldName: "textCorrectValues",    valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "textFalsePosValues",        name: "t.FalsePosValues",       fieldName: "textFalsePosValues",   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "cBKeyF1",                   name: "cb.K.F1",                fieldName: "cBKeyF1",              valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBKeyPrecision",            name: "cb.K.Precision",         fieldName: "cBKeyPrecision",       valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBKeyRecall",               name: "cb.K.Recall",            fieldName: "cBKeyRecall",          valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBPairF1",                  name: "cb.P.F1",                fieldName: "cBPairF1",             valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBPairPrecision",           name: "cb.P.Precision",         fieldName: "cBPairPrecision",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBPairRecall",              name: "cb.P.Recall",            fieldName: "cBPairRecall",         valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBValueF1",                 name: "cb.V.F1",                fieldName: "cBValueF1",            valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBValuePrecision",          name: "cb.V.Precision",         fieldName: "cBValuePrecision",     valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBValueRecall",             name: "cb.V.Recall",            fieldName: "cBValueRecall",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBTotalGTKeys",             name: "cb.TotalGTKeys",         fieldName: "cBTotalGTKeys",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBTotalRecoKeys",           name: "cb.TotalRecoKeys",       fieldName: "cBTotalRecoKeys",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBCorrectKeys",             name: "cb.CorrectKeys",         fieldName: "cBCorrectKeys",        valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBPartialMatchKeys",        name: "cb.PartialMatchKeys",    fieldName: "cBPartialMatchKeys",   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBFalsePositives",          name: "cb.FalsePositives",      fieldName: "cBFalsePositives",     valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
    { key: "cBCorrectPairs",            name: "cb.CorrectPairs",        fieldName: "cBCorrectPairs",       valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBTotalGTValues",           name: "cb.TotalGTValues",       fieldName: "cBTotalGTValues",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBTotalRecoValues",         name: "cb.TotalRecoValues",     fieldName: "cBTotalRecoValues",    valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBCorrectValues",           name: "cb.CorrectValues",       fieldName: "cBCorrectValues",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "cBFalsePosValues",          name: "cb.FalsePosValues",      fieldName: "cBFalsePosValues",     valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: Number(`${CLIENT_WIDTH/10}`),  isResizable: true,  },
];

// prettier-ignore
const KVP_COMMONNAME_COLUMNS_OLD: TableColumn[] = [
    { key: "name",                            name: "",                                 fieldName: "name",                        valueType: ColumnValueType.String,  minWidth: 70,   maxWidth: 100,  isResizable: true,  distinctStr: true, },
    { key: "commonNameGoldenPrecision",       name: "CommonNameGoldenPrecision",        fieldName: "commonNameGoldenPrecision",   valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "commonNamePrecision",             name: "CommonNamePrecision",              fieldName: "commonNamePrecision",         valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "commonNameGoldenRecall",          name: "CommonNameGoldenRecall",           fieldName: "commonNameGoldenRecall",      valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "commonNameRecall",                name: "CommonNameRecall",                 fieldName: "commonNameRecall",            valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "commonNameGoldenF1",              name: "CommonNameGoldenF1",               fieldName: "commonNameGoldenF1",          valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    { key: "commonNameF1",                    name: "CommonNameF1",                     fieldName: "commonNameF1",                valueType: ColumnValueType.Number,  minWidth: 165,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
    
];

// prettier-ignore
const KVP_COUNT_COLUMNS: TableColumn[] = [
    { key: "name",                      name: "",                       fieldName: "name",                 valueType: ColumnValueType.String,  minWidth: 100,  maxWidth: 300,  isResizable: true,  distinctStr: true, },
    { key: "count",                     name: "Count",                  fieldName: "count",                valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed },
   
];

const DEFAULT_COLUMNS = [
    "t.RecoKeys",
    "t.K.F1",
    "t.K.Precision",
    "t.K.Recall",
    "t.V.F1",
    "t.V.Precision",
    "t.V.Recall",
    "cb.TotalRecoKeys",
    "cb.K.F1",
    "cb.K.Precision",
    "cb.K.Recall",
    "cb.V.F1",
    "cb.V.Precision",
    "cb.V.Recall",
];

export class KvpOverview extends CommonView<IProps, IState, KvpFieldMetrics> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: true,
            viewType: store.getState().globalReducer.viewType,
        };
    }

    public render() {
        const { viewType } = this.state;
        return (
            <div className="overview">
                <FullScreen>
                    {viewType === "Table" && (
                        <div
                            style={{ overflow: "hidden auto", height: "100%" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}

                    {viewType === "Chart" && (
                        <div
                            style={{ overflow: "hidden auto", height: "100%" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </FullScreen>
            </div>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();
        const { storageVersion } = this.props;
        store.dispatch(
            updateStateAction({
                columns: (storageVersion === "v2"
                    ? KVP_COLUMNS
                    : KVP_COLUMNS_OLD
                ).filter((column) => column.key !== "name"),
                saveKey: `${Workspaces.Kvp}_${Typename.KvpOverview}_${
                    storageVersion === "v2" ? "New" : "Old"
                }`,
            })
        );
    }

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<KvpFieldMetrics>
    ): void {
        super.componentDidUpdate(prevProps, prevState);

        if (!_.isEqual(prevState.dataItems, this.state.dataItems)) {
            if (this.state.dataItems.length > 0) {
                const { storageVersion = false } = this.props;

                const selectedColumns =
                    storageVersion === "v2"
                        ? KVP_COLUMNS.map((val) => val.key).filter(
                              (key) => key !== "name"
                          )
                        : DEFAULT_COLUMNS;

                if (storageVersion !== "v2") {
                    mainPageObservanle.next({
                        selectKey: "Table",
                        disabledKey: "Chart",
                    });
                }
                updateStateAction({
                    selectedItems: selectedColumns,
                    saveKey: `${Workspaces.Kvp}_${Typename.KvpOverview}_${
                        storageVersion === "v2" ? "New" : "Old"
                    }`,
                });
            }
        }
    }

    private _renderAsTable() {
        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;
        const { records, storageVersion = false } = this.props;
        const columns: TableColumn[] = (
            storageVersion === "v2" ? KVP_COLUMNS : KVP_COLUMNS_OLD
        ).filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1 ||
                value.key === "name"
        );
        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderData(matchDatasetVersion!);

            return data && data.length > 0 ? (
                data.map(
                    (
                        [datasetName, recordMetrics, countData, connomNameDate],
                        index
                    ) => {
                        const tableData = recordMetrics.map(
                            ([_, fieldMetricsItems]) => {
                                return fieldMetricsItems.map(
                                    (fieldMetricsItem) => {
                                        const fieldMetrics =
                                            fieldMetricsItem.fieldMetrics;
                                        if (fieldMetrics) {
                                            fieldMetrics.DatasetName =
                                                datasetName;
                                        }
                                        return fieldMetrics;
                                    }
                                );
                            }
                        );

                        return (
                            <ExpandCard
                                key={datasetName}
                                text={datasetName}
                                expandAll={
                                    expandItem?.get(index) ??
                                    store.getState().globalReducer.expandAll
                                }
                                onExpandChange={(expand: boolean) => {
                                    expandItem?.set(index, expand);
                                    this.setState(
                                        { expandItem: expandItem },
                                        () =>
                                            this._expandCountChange(data.length)
                                    );
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            >
                                <OverviewTable<KvpFieldMetrics>
                                    key={`kvp_${index}`}
                                    evalData={tableData}
                                    columns={columns}
                                    tableTitle={""}
                                    downloadTableTitle={datasetName}
                                    onItemInvoked={this._onItemInvoked.bind(
                                        this,
                                        datasetName
                                    )}
                                    isCompactStyle={storageVersion === "v2"}
                                    cellWidth={records.length > 1 ? 90 : 70}
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                                <>
                                    {countData && (
                                        <OverviewTable<KvpFieldMetrics>
                                            key={`kvp_${index}`}
                                            evalData={countData as any}
                                            columns={KVP_COUNT_COLUMNS}
                                            tableTitle={""}
                                            downloadTableTitle={`${datasetName}_count`}
                                            onItemInvoked={this._onItemInvoked.bind(
                                                this,
                                                datasetName
                                            )}
                                            isCompactStyle={
                                                storageVersion === "v2"
                                            }
                                            cellWidth={
                                                records.length > 1 ? 90 : 70
                                            }
                                            isDarkTheme={this.props.isDarkTheme}
                                        />
                                    )}
                                    {connomNameDate && (
                                        <OverviewTable<KvpConnomNameMetrics>
                                            key={`kvpcommonname__${index}`}
                                            evalData={connomNameDate}
                                            columns={KVP_COMMONNAME_COLUMNS_OLD}
                                            tableTitle={""}
                                            downloadTableTitle={`${datasetName}_common`}
                                            onItemInvoked={this._onItemInvoked.bind(
                                                this,
                                                datasetName
                                            )}
                                            isCompactStyle={
                                                storageVersion === "v2"
                                            }
                                            cellWidth={
                                                records.length > 1 ? 90 : 70
                                            }
                                            isDarkTheme={this.props.isDarkTheme}
                                        />
                                    )}
                                </>
                            </ExpandCard>
                        );
                    }
                )
            ) : (
                <NoDataTip>No KVP data generated</NoDataTip>
            );
        }
        return <NoDataTip>No KVP data generated</NoDataTip>;
    }

    private _renderAsChart() {
        const { matchDatasetVersion } = this.state;
        const { storageVersion = "" } = this.props;
        if (storageVersion === "v2") {
            const data = this._prepareRenderData(matchDatasetVersion!);

            return data && data.length > 0 ? (
                data.map(([datasetName, groupData], dataIndex) => {
                    datasetName =
                        datasetName.slice(0, datasetName.indexOf(":")) +
                        "    Dataset: " +
                        datasetName.slice(
                            datasetName.indexOf(":") + 1,
                            datasetName.length
                        );

                    return (
                        <div key={datasetName}>
                            <Label>{datasetName}</Label>
                            <DocumentCard
                                key={`chart_${datasetName}`}
                                className="overview__card"
                                type={DocumentCardType.compact}
                            >
                                {groupData
                                    .map(([fieldName, metricsItems]) => {
                                        const series =
                                            this._prepareRenderChartData(
                                                metricsItems
                                            );
                                        return {
                                            name: fieldName,
                                            series: series,
                                        };
                                    })
                                    .map((item, index) => {
                                        return (
                                            <DocumentCardDetails key={index}>
                                                <VerticalBarChart
                                                    id={`root${dataIndex.toString()}${index.toString()}`}
                                                    height={400}
                                                    data={item.series}
                                                    xAxisName={item.name}
                                                    yAxisMax={100}
                                                    yAxisMin={0}
                                                    yAxisTicksCount={5}
                                                    isDarkTheme={
                                                        this.props.isDarkTheme
                                                    }
                                                ></VerticalBarChart>
                                            </DocumentCardDetails>
                                        );
                                    })}
                            </DocumentCard>
                        </div>
                    );
                })
            ) : (
                <NoDataTip>No KVP data generated</NoDataTip>
            );
        }

        return (
            <>
                {this.props.records.length > 0 &&
                    this.props.records.every(
                        (record) => record.getDetails().length === 0
                    ) && <NoDataTip>No KVP data generated</NoDataTip>}
            </>
        );
    }
    private _prepareRenderChartData = (metricsItems: IKvpDataItem[]) => {
        const names = [
            "Precision",
            "Recall",
            "F1",
            "Xchunk-Precision",
            "Xchunk-Recalll",
            "Xchunk-F1",
        ];
        return names.map((name) => {
            const series = metricsItems.map((metricsItem, metricsIndex) => {
                const fieldMetrics = metricsItem.fieldMetrics;

                let metrics = 0;
                switch (name) {
                    case "Precision":
                        metrics = fieldMetrics?.precision ?? 0;
                        break;
                    case "Recall":
                        metrics = fieldMetrics?.recall ?? 0;
                        break;
                    case "F1":
                        metrics = fieldMetrics?.f1 ?? 0;
                        break;
                    case "Xchunk-Precision":
                        metrics = fieldMetrics?.xChunkPrecision ?? 0;
                        break;
                    case "Xchunk-Recalll":
                        metrics = fieldMetrics?.xChunkRecall ?? 0;
                        break;
                    case "Xchunk-F1":
                        metrics = fieldMetrics?.xChunkF1 ?? 0;
                        break;
                    default:
                        break;
                }

                return {
                    key: `series_${metricsIndex}`,
                    data: metrics,
                    color: getColorByIndex(metricsIndex),
                    legend: metricsItem.data?.recordDetail.name ?? "",
                };
            });
            return { name, series: series };
        });
    };

    private _getDefaultData = (items: (IDataItem | undefined)[]) => {
        const fields = [
            "Text Key",
            "Text Value",
            "Text Pair",
            "CB Key",
            "CB Value",
            "CB Pair",
        ];
        const groupData = fields.map((field) => {
            // retrieve field data
            const name_word = field.split(" ");
            let firstName = name_word[0];
            firstName = firstName.replace(
                firstName[0],
                firstName[0].toLocaleLowerCase()
            );
            const lastName = name_word[1];
            const prefix = `${firstName}${lastName}`;
            const metricsItems = items
                .map((item) => {
                    let fieldMetrics = _.cloneDeep(item?.metrics);
                    if (fieldMetrics) {
                        fieldMetrics.precision =
                            item?.metrics[`${prefix}Precision`];
                        fieldMetrics.recall = item?.metrics[`${prefix}Recall`];
                        fieldMetrics.f1 = item?.metrics[`${prefix}F1`];
                        fieldMetrics.xChunkPrecision =
                            item?.metrics[`${prefix}XChunkPrecision`];
                        fieldMetrics.xChunkRecall =
                            item?.metrics[`${prefix}XChunkRecall`];
                        fieldMetrics.xChunkF1 =
                            item?.metrics[`${prefix}XChunkF1`];
                        fieldMetrics.totalGT =
                            item?.metrics[`${firstName}TotalGT${lastName}s`];
                        fieldMetrics.totalXchunkGT =
                            item?.metrics[
                                `${firstName}TotalXChunkGT${lastName}s`
                            ];
                        fieldMetrics.gtXchunkRatio =
                            item?.metrics[`${prefix}XChunkRatio`];
                        fieldMetrics.name = field;
                    } else {
                        fieldMetrics = this._setEmptyFieldMetrics();
                    }

                    return {
                        data: item,
                        fieldMetrics: fieldMetrics,
                    } as IKvpDataItem;
                })
                .flatMap((item) => item);
            return [field, metricsItems] as [string, IKvpDataItem[]];
        });

        return groupData;
    };

    private _getConnomNameData = (items: (IDataItem | undefined)[]) => {
        const fields = ["text", "cB"];
        const groupData = fields.map((field) => {
            const prefix = field;
            const metricsItems = items
                .map((item) => {
                    let fieldMetrics = _.cloneDeep(item?.metrics);
                    let connomNameMetrics: KvpConnomNameMetrics = {};
                    if (fieldMetrics) {
                        connomNameMetrics.commonNameGoldenPrecision =
                            item?.metrics[`${prefix}CommonNameGoldenPrecision`];
                        connomNameMetrics.commonNamePrecision =
                            item?.metrics[`${prefix}CommonNamePrecision`];
                        connomNameMetrics.commonNameGoldenRecall =
                            item?.metrics[`${prefix}CommonNameGoldenRecall`];
                        connomNameMetrics.commonNameRecall =
                            item?.metrics[`${prefix}CommonNameRecall`];
                        connomNameMetrics.commonNameGoldenF1 =
                            item?.metrics[`${prefix}CommonNameGoldenF1`];
                        connomNameMetrics.commonNameF1 =
                            item?.metrics[`${prefix}CommonNameF1`];

                        connomNameMetrics.name = field;
                    }

                    return connomNameMetrics;
                })
                .flatMap((item) => item);
            return metricsItems;
        });

        return groupData;
    };

    private _prepareRenderData(matchDatasetVersion: boolean) {
        if (!this.state.dataItems || this.state.dataItems.length === 0) {
            return undefined;
        }
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = _.partition(
            this._filterDatasetsForReport(allDatasetNames),
            (dataName) => dataName.includes("en:Combined")
        ).flatMap((dataName) => dataName);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            let groupData: [string, IKvpDataItem[]][] = [];
            let countData: KvpFieldMetrics[][] | undefined = undefined;
            let connomNameDate: KvpConnomNameMetrics[][] | undefined =
                undefined;
            if (this.props.storageVersion === "v2") {
                const countFields = [
                    "detect_val_to_wrong_key_numFiles",
                    "detect_val_to_wrong_key_numKvps",
                    "detect_missing_val_numFiles",
                    "detect_missing_val_numKvps",
                    "detect_key_maps_to_value_numFiles",
                    "detect_key_maps_to_value_numKvps",
                    "detect_n_keys_to_one_numFiles",
                    "detect_n_keys_to_one_numKvps",
                ];
                countData = countFields.map((field) => {
                    const countItems = items.map((item) => {
                        let fieldMetrics = _.cloneDeep(item?.metrics);
                        if (fieldMetrics) {
                            fieldMetrics.count = fieldMetrics[field];
                            fieldMetrics.name = field;
                        } else {
                            fieldMetrics = {
                                ...this._setEmptyFieldMetrics(),
                                ...{ count: NaN, name: field },
                            };
                        }
                        return fieldMetrics;
                    });

                    return countItems;
                });

                const haveCount = countData.some((items) => {
                    return items.some(
                        (item) => item.count && !isNaN(item.count)
                    );
                });

                countData = haveCount ? countData : undefined;

                groupData = this._getDefaultData(items);
                connomNameDate = this._getConnomNameData(items);
            } else {
                const fields = ["total"];
                groupData = fields.map((field) => {
                    // retrieve field data
                    const metricsItems = items.map((item) => {
                        let fieldMetrics: KvpFieldMetrics | undefined;
                        if (item !== undefined) {
                            fieldMetrics = item.metrics;

                            if (
                                (!fieldMetrics?.textKeyF1 ||
                                    isNaN(fieldMetrics?.textKeyF1)) &&
                                !isNaN(fieldMetrics.keyF1)
                            ) {
                                fieldMetrics.textKeyF1 = fieldMetrics.keyF1;
                                fieldMetrics.textPairF1 = fieldMetrics.pairF1;
                                fieldMetrics.textValueF1 = fieldMetrics.valueF1;
                                fieldMetrics.textKeyPrecision =
                                    fieldMetrics.keyPrecision;
                                fieldMetrics.textPairPrecision =
                                    fieldMetrics.pairPrecision;
                                fieldMetrics.textValuePrecision =
                                    fieldMetrics.valuePrecision;
                                fieldMetrics.textKeyRecall =
                                    fieldMetrics.keyRecall;
                                fieldMetrics.textPairRecall =
                                    fieldMetrics.pairRecall;
                                fieldMetrics.textValueRecall =
                                    fieldMetrics.valueRecall;
                                fieldMetrics.textTotalGTKeys =
                                    fieldMetrics.totalGTKeys;
                                fieldMetrics.textTotalRecoKeys =
                                    fieldMetrics.totalRecoKeys;
                                fieldMetrics.textCorrectKeys =
                                    fieldMetrics.correctKeys;
                                fieldMetrics.textPartialMatchKeys =
                                    fieldMetrics.partialMatchKeys;
                                fieldMetrics.textFalsePositives =
                                    fieldMetrics.falsePositives;
                                fieldMetrics.textCorrectPairs =
                                    fieldMetrics.correctPairs;
                                fieldMetrics.textTotalGTValues =
                                    fieldMetrics.totalGTValues;
                                fieldMetrics.textTotalRecoValues =
                                    fieldMetrics.totalRecoValues;
                                fieldMetrics.textCorrectValues =
                                    fieldMetrics.correctValues;
                                fieldMetrics.textFalsePosValues =
                                    fieldMetrics.falsePosValues;
                            }
                        }

                        if (field === undefined) {
                            fieldMetrics = this._setEmptyFieldMetrics();
                        }

                        return {
                            data: item,
                            fieldMetrics: fieldMetrics,
                        } as IKvpDataItem;
                    });
                    return [field, metricsItems] as [string, IKvpDataItem[]];
                });
            }

            return [datasetName, groupData, countData, connomNameDate] as [
                string,
                [string, IKvpDataItem[]][],
                KvpFieldMetrics[][] | undefined,
                KvpConnomNameMetrics[][] | undefined
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    queryMetricsResult() {
        this._queryMetricsResult("per-dataset.json");
    }

    private _onItemInvoked(datasetName: string): void {
        const { kvpDocMetricsDeepLinkHandler } = this.props;
        if (kvpDocMetricsDeepLinkHandler && datasetName) {
            const lowerDatasetName = datasetName.toLowerCase();
            if (!lowerDatasetName.includes("combined")) {
                kvpDocMetricsDeepLinkHandler("overviewKvpDocumentMetrics", {
                    toSelectLanguage: datasetName,
                });
            }
        }
    }

    private _setEmptyFieldMetrics() {
        return {
            keyF1: NaN,
            pairF1: NaN,
            valueF1: NaN,
            keyPrecision: NaN,
            pairPrecision: NaN,
            valuePrecision: NaN,
            keyRecall: NaN,
            pairRecall: NaN,
            valueRecall: NaN,
            totalGTKeys: NaN,
            totalRecoKeys: NaN,
            correctKeys: NaN,
            partialMatchKeys: NaN,
            falsePositives: NaN,
            correctPairs: NaN,
            totalGTValues: NaN,
            totalRecoValues: NaN,
            correctValues: NaN,
            falsePosValues: NaN,
            textKeyF1: NaN,
            textPairF1: NaN,
            textValueF1: NaN,
            textKeyPrecision: NaN,
            textPairPrecision: NaN,
            textValuePrecision: NaN,
            textKeyRecall: NaN,
            textPairRecall: NaN,
            textValueRecall: NaN,
            textTotalGTKeys: NaN,
            textTotalRecoKeys: NaN,
            textCorrectKeys: NaN,
            textPartialMatchKeys: NaN,
            textFalsePositives: NaN,
            textCorrectPairs: NaN,
            textTotalGTValues: NaN,
            textTotalRecoValues: NaN,
            textCorrectValues: NaN,
            textFalsePosValues: NaN,
            cBTotalGTKeys: NaN,
            cBTotalRecoKeys: NaN,
            cBCorrectKeys: NaN,
            cBPartialMatchKeys: NaN,
            cBFalsePositives: NaN,
            cBCorrectPairs: NaN,
            cBKeyPrecision: NaN,
            cBKeyRecall: NaN,
            cBKeyF1: NaN,
            cBPairPrecision: NaN,
            cBPairRecall: NaN,
            cBPairF1: NaN,
            cBTotalGTValues: NaN,
            cBTotalRecoValues: NaN,
            cBCorrectValues: NaN,
            cBFalsePosValues: NaN,
            cBValuePrecision: NaN,
            cBValueRecall: NaN,
            cBValueF1: NaN,
        };
    }
}
