import * as React from "react";
import ImageMapper from "./ImageMapper";
import { TextArea } from "./ImageVisualizer";
import { Consumer } from "../../Layout";
import {
    VisualizerBase,
    VisualizerProps,
    VisualizerState,
} from "./VisualizerBase";
import "./CheckboxImageVisualizer.scss";

interface IProps extends VisualizerProps {
    fileId?: string;
    onLoadVisualizer: (imageId?: string) => string[];
    onRequestGtUrl?: (imageId?: string) => string;
    entity?: string;
    experiments?: string[];
}

interface IState extends VisualizerState {
    loading: boolean;
    selectedItem: string;
    textareas: TextArea[];
    hoveredArea: TextArea | null;
}

export class CheckboxImageVisualizer extends VisualizerBase<IProps, IState> {
    private _mapper: React.RefObject<ImageMapper>;

    constructor(props: IProps) {
        super(props);
        this._mapper = React.createRef();
        this.state = {
            loading: false,
            textareas: [],
            selectedItem: "",
            hoveredArea: null,
            fileUrl: "",
        };
    }

    protected renderVisualizer(): JSX.Element {
        const { textareas } = this.state;
        const { experiments, onRequestGtUrl } = this.props;

        const map = {
            name: "map",
            areas: textareas,
        };

        return (
            <div className="viewer">
                {this.props
                    .onLoadVisualizer(this.props.fileId)
                    ?.map((url, index) => {
                        return (
                            <div className="viewer__container" key={url}>
                                <Consumer>
                                    {(value) => {
                                        return (
                                            <h2
                                                style={{
                                                    wordBreak: "break-word",
                                                    color: value
                                                        ? "white"
                                                        : "black",
                                                }}
                                            >
                                                {" "}
                                                {experiments === undefined
                                                    ? "Recognition"
                                                    : experiments[index]}{" "}
                                            </h2>
                                        );
                                    }}
                                </Consumer>

                                <ImageMapper
                                    ref={this._mapper}
                                    src={url}
                                    map={map}
                                    width={800}
                                    lineWidth={4}
                                    strokeColor={"white"}
                                    imgWidth={
                                        this._mapper.current?.img?.naturalWidth
                                    }
                                    onClick={(area: any) =>
                                        this._clickImage(area)
                                    }
                                    onMouseEnter={(area: TextArea) =>
                                        this._enterArea(area)
                                    }
                                    onMouseLeave={(area: TextArea) =>
                                        this._leaveArea(area)
                                    }
                                />
                                {this.state.hoveredArea && (
                                    <span
                                        className="viewer__tooltip"
                                        style={{
                                            ...this._getTipPosition(
                                                this.state.hoveredArea
                                            ),
                                        }}
                                    >
                                        {this.state.hoveredArea &&
                                            this.state.hoveredArea.text}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                {onRequestGtUrl && (
                    <div className="viewer__container">
                        <h2>Ground Truth</h2>
                        <ImageMapper
                            ref={this._mapper}
                            src={onRequestGtUrl(this.props.fileId)}
                            map={map}
                            width={800}
                            lineWidth={4}
                            strokeColor={"white"}
                            imgWidth={this._mapper.current?.img?.naturalWidth}
                            onClick={(area: any) => this._clickImage(area)}
                            onMouseEnter={(area: TextArea) =>
                                this._enterArea(area)
                            }
                            onMouseLeave={(area: TextArea) =>
                                this._leaveArea(area)
                            }
                        />
                        {this.state.hoveredArea && (
                            <span
                                className="viewer__tooltip"
                                style={{
                                    ...this._getTipPosition(
                                        this.state.hoveredArea
                                    ),
                                }}
                            >
                                {this.state.hoveredArea &&
                                    this.state.hoveredArea.text}
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.refreshCache();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (
            this.props.fileId !== prevProps.fileId ||
            this.props?.entity !== prevProps?.entity
        ) {
            this.refreshCache();
        }
    }

    //#endregion

    refreshCache() {
        const imageId = this.props.fileId;
        if (!imageId) {
            return;
        }
        this.setState({
            textareas: [],
            selectedItem: "",
        });
    }

    private _enterArea(area: TextArea) {
        this.setState({
            hoveredArea: area,
        });
    }

    private _leaveArea(area: TextArea) {
        this.setState({
            hoveredArea: null,
        });
    }

    private _getTipPosition(area: TextArea) {
        return { top: `${area.center![1]}px`, left: `${area.center![0]}px` };
    }

    private _clickImage(area: TextArea) {
        let content = area.text;

        this.setState({
            selectedItem: content,
        });
    }
}
