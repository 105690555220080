import * as React from "react";
import {
    DirectionalHint,
    IconButton,
    ITooltipHostStyles,
    Stack,
    TooltipHost,
} from "@fluentui/react";
import { getTagColor } from "../../Utils";
import { Record } from "../../DataContract";
import { TagCallout } from "../TagCallout";

interface IProps {
    values: string[];
    visibleCalloutId: string;
    record: Record;
    setRecordTag: (tags: string[]) => void;
    onChangevisibleCalloutId: (id: string) => void;
    classSuffix?: string;
    existence?: boolean;
    edit?: boolean;
}

interface IState {
    showWarning: boolean;
    visibleCalloutId: string;
}

const calloutProps = { gapSpace: 5, isBeakVisible: false };

const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-flex", justifyContent: "middle" },
};

export class EditTagsBar extends React.Component<IProps, IState> {
    private _calloutTarget: HTMLElement | null | undefined;
    constructor(props: IProps) {
        super(props);
        this.state = {
            showWarning: false,
            visibleCalloutId: this.props.visibleCalloutId,
        };
    }

    render() {
        const {
            record,
            onChangevisibleCalloutId,
            classSuffix = "",
            values = [],
            edit = false,
            existence = false,
        } = this.props;
        const colors = getTagColor(values.length);
        return (
            <Stack horizontal verticalAlign="center">
                <>
                    {values.length === 0 && existence && (
                        <span
                            style={{
                                color: colors[0],
                            }}
                            className="record_tag_eval"
                        ></span>
                    )}

                    {values.length < 2 && values.length !== 0 && (
                        <TooltipHost
                            key={"tooltipHost"}
                            content={
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    style={{
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                        display: "flex",
                                        width: "350px",
                                        flexFlow: "wrap",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: colors[0],
                                        }}
                                        className={"tooltip_tag"}
                                    >
                                        {[...new Set(values)][0]}
                                    </span>
                                </Stack>
                            }
                            calloutProps={calloutProps}
                            styles={hostStyles}
                            directionalHint={DirectionalHint.bottomCenter}
                        >
                            <span
                                style={{
                                    color: colors[0],
                                }}
                                className={`record_tag${classSuffix}`}
                            >
                                {[...new Set(values)][0]}
                            </span>
                        </TooltipHost>
                    )}
                    {values.length >= 2 && (
                        <Stack horizontal verticalAlign="end">
                            <TooltipHost
                                key={"tooltipHost1"}
                                content={
                                    <Stack
                                        horizontal
                                        verticalAlign="center"
                                        style={{
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            display: "flex",
                                            width: "350px",
                                            flexFlow: "wrap",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: colors[0],
                                            }}
                                            className={"tooltip_tag"}
                                        >
                                            {[...new Set(values)][0]}
                                        </span>
                                    </Stack>
                                }
                                calloutProps={calloutProps}
                                styles={hostStyles}
                                directionalHint={DirectionalHint.bottomCenter}
                            >
                                <span
                                    style={{
                                        color: colors[0],
                                    }}
                                    className={`record_tag_more${classSuffix}`}
                                >
                                    {[...new Set(values)][0]}
                                </span>
                            </TooltipHost>

                            <TooltipHost
                                key={"tooltipHost2"}
                                content={
                                    <Stack
                                        horizontal
                                        verticalAlign="center"
                                        style={{
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            display: "flex",
                                            width: "350px",
                                            flexFlow: "wrap",
                                        }}
                                    >
                                        {[...new Set(values)].map(
                                            (tag, index) => {
                                                return (
                                                    <span
                                                        key={`keyTag_${index}`}
                                                        className="tooltip_tag"
                                                        style={{
                                                            color: colors[
                                                                index
                                                            ],
                                                        }}
                                                    >
                                                        {tag}
                                                    </span>
                                                );
                                            }
                                        )}
                                    </Stack>
                                }
                                calloutProps={calloutProps}
                                directionalHint={DirectionalHint.bottomCenter}
                            >
                                <IconButton
                                    className={`more_tag${classSuffix}`}
                                    styles={{
                                        root: {
                                            border: "1px solid #ffffff",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    iconProps={{
                                        iconName: "More",
                                        style: {
                                            fontSize: 14,
                                        },
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    )}
                </>

                {edit && (
                    <div className="tagItems">
                        <IconButton
                            className={`tagAdd${classSuffix}`}
                            styles={{
                                root: {
                                    border: "1px solid #ffffff",
                                    borderRadius: "5px",
                                },
                            }}
                            iconProps={{
                                iconName: "Add",
                                style: {
                                    fontSize: 14,
                                },
                            }}
                            onClick={(evt) => {
                                this._calloutTarget = evt.target as HTMLElement;
                                onChangevisibleCalloutId(record["id"]);
                                this.setState({
                                    showWarning: false,
                                });
                            }}
                        />

                        {this.state.visibleCalloutId === record["id"] &&
                            this._onCalloutRender()}
                    </div>
                )}
            </Stack>
        );
    }

    private _onCalloutRender = (): any => {
        const { record, setRecordTag } = this.props;
        return (
            <TagCallout
                key={`callout_${record.id}`}
                item={record}
                target={this._calloutTarget!}
                visibleCalloutId={this.state.visibleCalloutId}
                onDismiss={this._onCalloutDismiss}
                showWarning={this.state.showWarning}
                onSaveTags={(tags: string[]) => {
                    const existsInvalidTag = tags.some(
                        (t) => t.match(/^[ ]*$/) !== null
                    );
                    if (existsInvalidTag) {
                        this.setState({
                            showWarning: true,
                        });
                    } else {
                        setRecordTag(tags);
                    }
                }}
            />
        );
    };

    public _onCalloutDismiss = (): void => {
        const { visibleCalloutId } = this.state;
        if (visibleCalloutId !== "-1") {
            this.props.onChangevisibleCalloutId("-1");
            this.setState({
                showWarning: false,
            });
        }
    };

    public componentWillReceiveProps(props: IProps) {
        if (props.visibleCalloutId !== this.state.visibleCalloutId) {
            this.setState({ visibleCalloutId: props.visibleCalloutId });
        }
    }

    public async componentDidMount() {
        document.addEventListener("scroll", this._onCalloutDismiss);
    }

    public componentWillUnmount() {
        document.removeEventListener("scroll", this._onCalloutDismiss);
    }
}
