import React from "react";
import {
    CustFormErrorAnalysis,
    CustomFormErrorAnalysisMetric,
} from "./CustFormErrorAnalysis";
import {
    ColumnValueType,
    TableColumn,
    TableList,
} from "../../Controls/TableList";

// prettier-ignore
const CUSTOMFORM_ERROR_DETAIL_COLUMNS: TableColumn[] = [
    { key: "doc type",         name: "doc type",       fieldName: "doc type",      isKey: false,    valueType: ColumnValueType.String,   minWidth: 300,  maxWidth: 350, isResizable: true, filterable:true},
    { key: "doc",              name: "doc",            fieldName: "doc",           isKey: false,    valueType: ColumnValueType.String,   minWidth: 200,  maxWidth: 200, isResizable: true, filterable:true},
    { key: "field name",       name: "field name",     fieldName: "field name",    isKey: false,    valueType: ColumnValueType.String,   minWidth: 300,  maxWidth: 350, isResizable: true, filterable:true},
    { key: "field type",       name: "field type",     fieldName: "field type",    isKey: false,    valueType: ColumnValueType.String,   minWidth: 200,  maxWidth: 200, isResizable: true, filterable:true},
    { key: "gt text",          name: "gt text",        fieldName: "gt text",       isKey: false,    valueType: ColumnValueType.String,   minWidth: 100,  maxWidth: 200, isResizable: true, },
    { key: "pred text",        name: "pred text",      fieldName: "pred text",     isKey: false,    valueType: ColumnValueType.String,   minWidth: 100,  maxWidth: 200, isResizable: true, },
    { key: "pred value",       name: "pred value",     fieldName: "pred value",    isKey: false,    valueType: ColumnValueType.String,   minWidth: 100,  maxWidth: 200, isResizable: true, },
    { key: "error type",       name: "error type",     fieldName: "error type",    isKey: false,    valueType: ColumnValueType.String,   minWidth: 100,  maxWidth: 200, isResizable: true, filterable:true},

];

export class CustFormErrorAnalysisDetail extends CustFormErrorAnalysis {
    renderDataView(): React.ReactNode {
        const { records } = this.props;
        // const { selectedColumns } = this.state;
        // const columns: TableColumn[] = CUSTOMFORM_ERROR_DETAIL_COLUMNS.filter(
        //     (value) =>
        //         selectedColumns?.findIndex((col) => col === value.key) !== -1
        // );
        const dataMap = this._prepareData();
        const tableKey = `${this.state.selectLanguage}_${this.state.selectTrainingDocCount}_${this.state.selectTrainingDocIndex}`;
        return (
            <div className="displayFlex" style={{ paddingTop: "5px" }}>
                <TableList<CustomFormErrorAnalysisMetric>
                    key={tableKey}
                    evalDataCount={records.length}
                    evalData={dataMap}
                    columns={CUSTOMFORM_ERROR_DETAIL_COLUMNS}
                    downloadTableTitle={tableKey}
                    // renderTableHeader={this._renderTableHeader}
                    isWiderCell={true}
                    isDarkTheme={this.props.isDarkTheme}
                    hideHeader
                    showFilterBar
                    disableSorting
                    verticalFill
                />
            </div>
        );
    }

    private _prepareData(): Map<
        string,
        (CustomFormErrorAnalysisMetric | null)[]
    > {
        const { evalData = {} } = this.state;
        const dataMap = new Map<
            string,
            (CustomFormErrorAnalysisMetric | null)[]
        >();
        const keyValueArr = Object.entries(evalData);

        keyValueArr.forEach(([k, v]) => {
            if (!dataMap.has(k)) {
                dataMap.set(k, v);
            }
        });

        return dataMap;
    }
}
