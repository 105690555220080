import { ColumnValueType, TableColumn } from "../../Controls";
import { Workspaces } from "../../DataContract";

const CLIENT_WIDTH = document.documentElement.clientHeight - 133;

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",                                                 name: "Category",             fieldName: "category",                                                   valueType: ColumnValueType.String, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, distinctStr: true },
    { key: "page_cnt",                                                 name: "ImageCount",           fieldName: "page_cnt",                                                   valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.LineCount",                    name: "LineCount",            fieldName: "_ocrNativePerfNativeMetrics.LineCount",                      valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrMS",                                                   name: "OCRMS",                fieldName: "_ocrMS",                                                     valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.TotalMs",                      name: "Native.TotalMS",       fieldName: "_ocrNativePerfNativeMetrics.TotalMs",                        valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",      name: "Detector.TotalMS",     fieldName: "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",        valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.NormalizerMetrics.TotalMs",    name: "Normalizer.TotalMS",   fieldName: "_ocrNativePerfNativeMetrics.NormalizerMetrics.TotalMs",      valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.AnalyzerMetrics.TotalMs",      name: "AnalyzerTotalMS",      fieldName: "_ocrNativePerfNativeMetrics.AnalyzerMetrics.TotalMs",        valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs", name: "RecognizerWallMS",     fieldName: "_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs",   valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
];

// prettier-ignore
const CHECKBOX_OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",                                                                          name: "Category",                       fieldName: "category",                                                            valueType: ColumnValueType.String, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, distinctStr: true },
    { key: "page_cnt",                                                                          name: "ImageCount",                     fieldName: "page_cnt",                                                            valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxMS",                                                                       name: "checkboxMS",                     fieldName: "_checkboxMS",                                                         valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfOverheadMS",                                                     name: "checkboxNativePerfOverheadMS",   fieldName: "_checkboxNativePerfOverheadMS",                                       valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfPreprocessMS",                                                   name: "checkboxNativePerfPreprocessMS", fieldName: "_checkboxNativePerfPreprocessMS",                                     valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfTotalMS",                                                        name: "checkboxNativePerfTotalMS",      fieldName: "_checkboxNativePerfTotalMS",                                          valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.CheckboxImageResizeMS",                    name: "ResizeMS",                       fieldName: "_checkboxNativePerfCheckboxNativeMetrics.CheckboxImageResizeMS",      valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.InferenceModelMS",                         name: "InferenceModelMS",               fieldName: "_checkboxNativePerfCheckboxNativeMetrics.InferenceModelMS",           valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.InferenceOtherMS",                         name: "InferenceOtherMS",               fieldName: "_checkboxNativePerfCheckboxNativeMetrics.InferenceOtherMS",           valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.TotalMS",                                  name: "TotalMS",                        fieldName: "_checkboxNativePerfCheckboxNativeMetrics.TotalMS",                    valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_checkboxNativePerfCheckboxNativeMetrics.UnaccountedMS",                            name: "UnaccountedMS",                  fieldName: "_checkboxNativePerfCheckboxNativeMetrics.UnaccountedMS",              valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
];

// prettier-ignore
const KVP_OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "_kvpNativePerf.KVPNativeMetrics.TotalMs",                       name: "kvpNativePerf.KVPNative.TotalMs",    fieldName: "_kvpNativePerf.KVPNativeMetrics.TotalMs",                                valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.AnalyzeTotalMs",   name: "KVPNative.Value.AnalyzeTotalMs",     fieldName: "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.AnalyzeTotalMs",            valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.EncodeTotalMs",    name: "KVPNative.Value.EncodeTotalMs",      fieldName: "_kvpNativePerf.KVPNativeMetrics.ValueMetrics.EncodeTotalMs",             valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.AnalyzeTotalMs",     name: "KVPNative.Key.AnalyzeTotalMs",       fieldName: "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.AnalyzeTotalMs",              valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    { key: "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.EncodeTotalMs",      name: "KVPNative.Key.EncodeTotalMs",        fieldName: "_kvpNativePerf.KVPNativeMetrics.KeyMetrics.EncodeTotalMs",               valueType: ColumnValueType.Number, minWidth: 5, maxWidth: Number(`${CLIENT_WIDTH / 10}`), isResizable: true, },
    ];

export const LatencyColumns: { [key: string]: TableColumn[] } = {
    [Workspaces.Ocr]: OVERVIEW_COLUMNS,
    [Workspaces.ReleaseTest]: OVERVIEW_COLUMNS,
    [Workspaces.OcrCheckbox]: CHECKBOX_OVERVIEW_COLUMNS,
    [Workspaces.Kvp]: KVP_OVERVIEW_COLUMNS,
    [Workspaces.OcrMath]: [],
    [Workspaces.OcrTable]: [],
    [Workspaces.OcrPod]: [],
    [Workspaces.OcrFigure]: [],
};

type ColumnPrefixResult = (lowerColName: string) => boolean;

export const ColumnPrefix: {
    [key: string]: ColumnPrefixResult;
} = {
    [Workspaces.OcrTable]: (lowerColName: string) => {
        return (
            lowerColName.startsWith("tablenative") ||
            lowerColName === "category"
        );
    },
    [Workspaces.OcrPod]: (lowerColName: string) => {
        return (
            lowerColName.startsWith("podnative") ||
            lowerColName === "category" ||
            lowerColName === "hierarchyanalyzerms"
        );
    },
    [Workspaces.OcrMath]: (lowerColName: string) => {
        return (
            lowerColName === "category" ||
            lowerColName === "_ocrms" ||
            lowerColName === "_totalms" ||
            lowerColName.indexOf("mathformula") !== -1 ||
            lowerColName.indexOf("mathfm") !== -1
        );
    },
    [Workspaces.OcrFigure]: (lowerColName: string) => {
        return true;
    },
    [Workspaces.Kvp]: (lowerColName: string) => {
        return (
            lowerColName.startsWith("kvpnativePerf") ||
            lowerColName.startsWith("kvpnative") ||
            lowerColName === "category"
        );
    },
};
