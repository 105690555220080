import React from "react";
import {
    ColumnValueType,
    ITableConfigs,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import { IDataItem, MetricCompactDefinition } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import _ from "lodash";
import { Modal } from "@fluentui/react";
import { GetExtensionWithoutDot } from "../../Utils";
import {
    FileVisualizer,
    FileVizInfo,
} from "../../Controls/Visualizer/FileVisualizer";

const OFFICE_EXT_ARR = ["docx", "pptx", "xlsx"];
const FILE_EXT_ARR = [...OFFICE_EXT_ARR, "pdf"];

interface IState extends ICommonState<any> {
    dataItems: IDataItem<any>[];
    clickNumber?: number;
    selectLanguage?: string;
    selectGroup?: string;
    selectFileId?: string;
}

interface IProps extends ICommonProps {
    metricDefinition: MetricCompactDefinition;
}

export class DocClassifierImageView extends CommonView<IProps, IState, any> {
    constructor(props: IProps) {
        super(props);

        this._onOptionsChanged = this._onOptionsChanged.bind(this);
        this._renderTableHeader = this._renderTableHeader.bind(this);

        this.state = {
            dataItems: [],
            matchDatasetVersion: false,
            selectLanguage: this._getUnionDistinctLangList(false)[0],
        };
    }

    render() {
        const { isDarkTheme, metricDefinition, records } = this.props;
        const { selectFileId, clickNumber } = this.state;

        const columns: TableColumn[] = [
            {
                key: "image",
                name: "Image",
                fieldName: "image",
                isKey: true,
                valueType: ColumnValueType.String,
                minWidth: 300,
                maxWidth: 500,
                isResizable: true,
            },
        ];

        if (metricDefinition) {
            metricDefinition.props.forEach((p) => {
                const col = {
                    key: p,
                    name: p,
                    fieldName: p,
                    isKey: false,
                    valueType: ColumnValueType.Number,
                    maxDecimalPlaces: 2,
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                };
                columns.push(col);
            });
        }

        const [evalData, fileMetaDict] = this._prepareRenderData();
        const fileMetaArr = Array.from(fileMetaDict);

        return (
            <>
                {selectFileId && (
                    <Modal
                        styles={{
                            main: {
                                width: "100%!important",
                                height: "100%!important",
                            },
                        }}
                        isOpen={!!selectFileId}
                        containerClassName="modal"
                        onDismiss={() =>
                            this.setState({ selectFileId: undefined })
                        }
                    >
                        {selectFileId && (
                            <FileVisualizer
                                fileId={selectFileId}
                                onLoadVisualizer={(fileId?: string) => {
                                    if (fileId) {
                                        return fileMetaDict.get(fileId);
                                    } else {
                                        return undefined;
                                    }
                                }}
                                onDismiss={(fileId: string) =>
                                    this.onDismiss(fileId)
                                }
                                evalList={fileMetaArr}
                                clickNumber={clickNumber}
                            />
                        )}
                    </Modal>
                )}
                <TableList<any>
                    key={this.state.selectLanguage}
                    columns={columns}
                    evalData={evalData}
                    evalDataCount={records.length}
                    downloadTableTitle={this.state.selectLanguage}
                    renderTableHeader={this._renderTableHeader}
                    isDarkTheme={isDarkTheme}
                    onItemInvoked={(item: any, index?: number | undefined) => {
                        if (fileMetaDict && fileMetaDict.size > 0) {
                            const [fileId] = item;
                            const ext =
                                GetExtensionWithoutDot(fileId).toLowerCase();
                            if (FILE_EXT_ARR.includes(ext)) {
                                this.setState({
                                    selectFileId: fileId,
                                    clickNumber: index,
                                });
                            }
                        }
                    }}
                />
            </>
        );
    }

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<any>
    ) {
        if (!_.isEqual(this.props.records, prevProps.records)) {
            this.setState(
                {
                    selectLanguage: this._getUnionDistinctLangList(false)[0],
                },
                () => this.queryMetricsResult()
            );
        }
    }

    isInitializeWithReduxStore(): boolean {
        return false;
    }

    componentWillUnmount() {
        this.setState = () => false;
    }

    queryMetricsResult(): void {
        const { selectLanguage } = this.state;
        if (selectLanguage) {
            this._queryMetricsResult("detail_metrics.json", selectLanguage);
        }
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : undefined;
        if (selectLanguage) {
            this.setState(
                { selectLanguage: selectLanguage },
                this.queryMetricsResult
            );
        }
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const { matchDatasetVersion, selectLanguage } = this.state;
        const languages = this._getUnionDistinctLangList(matchDatasetVersion!);
        const selectLangKey = this._loadSelectedLanguage(
            selectLanguage,
            languages
        );

        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _prepareRenderData(): [
        Map<string, any[]>,
        Map<string, FileVizInfo>
    ] {
        const { records, metricDefinition } = this.props;
        const { dataItems, matchDatasetVersion, selectLanguage } = this.state;
        const itemsDict = new Map<string, any[]>();
        const fileMetaDict = new Map<string, FileVizInfo>();
        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0 &&
            selectLanguage
        ) {
            const colCount = records.length;
            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        selectLanguage ===
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName)
                    );
                });
                return item;
            });

            let defaultItem: any = {};
            items.forEach((item) => {
                if (item && item.metrics && item.recordDetail) {
                    const colIndex = item.recordIndex;
                    const metricsArr = Object.entries<any>(item.metrics);
                    metricsArr.forEach(([imgKey, obj]) => {
                        const dataItem = obj[metricDefinition.name];
                        if (dataItem) {
                            if (JSON.stringify(defaultItem) === "{}") {
                                Object.keys(dataItem).forEach((key) => {
                                    defaultItem[key] = "NaN";
                                });
                            }

                            let itemArr = itemsDict.has(imgKey)
                                ? itemsDict.get(imgKey)!
                                : Array.from({ length: colCount }, () => {
                                      return defaultItem;
                                  });
                            itemArr[colIndex] = dataItem;
                            itemsDict.set(imgKey, itemArr!);
                        }

                        if (
                            "FileMeta" in obj &&
                            obj["FileMeta"] != null &&
                            !fileMetaDict.has(imgKey)
                        ) {
                            const fileUrl = `/api/datasets/${item.recordDetail.dataset.name}/versions/${item.recordDetail.dataset.version}/tree/test/raw/${imgKey}`;
                            fileMetaDict.set(imgKey, {
                                fileUrl: fileUrl,
                                fileMeta: obj["FileMeta"],
                            });
                        }
                    });
                }
            });
        }

        return [itemsDict, fileMetaDict];
    }

    private onDismiss(imageId: any) {
        this.setState({
            selectFileId: imageId,
        });
    }
}
