import {
    getTheme,
    IconButton,
    IIconProps,
    Label,
    Stack,
} from "@fluentui/react";
import React from "react";
import "./ExpandCard.scss";

interface IState {
    expandAll?: boolean;
}

interface IProps {
    expandAll?: boolean;
    text?: string | JSX.Element;
    onExpandChange?: (value: boolean) => void;
    display?: boolean;
    isDarkTheme?: boolean;
}

const foldIcon: IIconProps = {
    iconName: "ChevronRightMed",
    styles: {
        root: {
            lineHeight: "30px",
            height: "15px",
        },
    },
};
const theme = getTheme();
export class ExpandCard extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);
        this.state = {
            expandAll: this.props.expandAll,
        };
    }

    public render() {
        const { expandAll = false } = this.state;
        return (
            <div
                style={{
                    height: expandAll ? "auto" : "32px",
                    overflow: "hidden",
                }}
            >
                {this._renderText()}
                <div>{this.props.children}</div>
            </div>
        );
    }

    private _renderText = () => {
        const { expandAll = false } = this.state;
        const { text = "", onExpandChange } = this.props;
        return (
            <Stack horizontal verticalAlign={expandAll ? "center" : "end"}>
                <IconButton
                    iconProps={foldIcon}
                    className={expandAll ? "iocn_expand" : "icon_fold"}
                    style={{
                        color: this.props.isDarkTheme
                            ? theme.palette.white
                            : theme.palette.black,
                    }}
                    styles={{
                        root: { lineHeight: "0px" },
                        flexContainer: { alignItems: "baseline" },
                    }}
                    onClick={() => {
                        this.setState(
                            (preState) => {
                                return {
                                    expandAll: !preState.expandAll,
                                };
                            },
                            () => {
                                if (onExpandChange) {
                                    onExpandChange(!expandAll);
                                }
                            }
                        );
                    }}
                />
                <Label>{text}</Label>
            </Stack>
        );
    };

    public componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.expandAll !== this.state.expandAll) {
            this.setState({ expandAll: nextProps.expandAll });
        }
    }
}
