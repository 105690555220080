import React from "react";

import { GroupedVerticalBarChart } from "@uifabric/charting";

import {
    //ChoiceGroup
    ChoiceGroup,
    IChoiceGroupOption,

    //DetailList
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,

    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,

    //Others
    Label,
    IDropdownOption,
    Stack,
} from "@fluentui/react";

import { Record, RecordDetail } from "../../DataContract";

import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { formatNumberDisplay, getColorByIndex } from "../../Utils";

interface IBackencMetricsObj {
    [key: string]: { [key: string]: CategoryScore[] };
}

interface CategoryScore {
    category: string;
    err_rate: number;
}

interface OcrBackendMetrics {
    category_cer: Array<CategoryScore>;
    category_wer: Array<CategoryScore>;
}

interface IState {
    data: [RecordDetail, OcrBackendMetrics][];
    viewType: string;
    selectedColumns: string[];
}

interface IProps {
    records: Record[];
}

// prettier-ignore
const backendOverviewColumns: IColumn[] = [
    { key: "category",          name: "Category",             fieldName: "category",          minWidth: 250,  maxWidth: 300, isResizable: false, },
    { key: "err_cnt",           name: "Error",                fieldName: "err_cnt",           minWidth: 200,  maxWidth: 250, isResizable: true, },
    { key: "total_cnt",         name: "Total",                fieldName: "total_cnt",         minWidth: 200,  maxWidth: 250, isResizable: true, },
    { key: "err_rate",          name: "Error Rate",           fieldName: "err_rate",          minWidth: 200,  maxWidth: 250, isResizable: true,  },
  
];

const OPTIONS: IChoiceGroupOption[] = [
    {
        key: "chart",
        text: "Chart",
        iconProps: { iconName: "BarChartVertical" },
    },
    {
        key: "table",
        text: "Table",
        iconProps: { iconName: "Table" },
    },
];

export class OcrBackendOverview extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this.state = {
            viewType: "Chart",
            selectedColumns: backendOverviewColumns.map((val) => val.name),
            data: [],
        };
    }

    public render() {
        const { viewType, data } = this.state;
        const evalData = this._mergeMetrics(data);
        return (
            <div className="overview">
                <ChoiceGroup
                    label="Pick View Type"
                    defaultSelectedKey="chart"
                    options={OPTIONS}
                    onChange={(_event, option) =>
                        this.setState({ viewType: option!.text })
                    }
                />
                {viewType === "Chart" && this._renderAsChart(evalData)}
                {viewType === "Table" && this._renderAsTable(evalData)}
            </div>
        );
    }

    public componentDidMount() {
        this._queryMetricsResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryMetricsResult();
        }
    }

    private _renderAsTable(evalData: IBackencMetricsObj) {
        const { selectedColumns } = this.state;
        const columns: IColumn[] = backendOverviewColumns.filter(
            (value) =>
                selectedColumns.findIndex((col) => col === value.name) !== -1
        );
        const options: ITableConfigurations = [
            {
                key: "columns",
                text: "Columns:",
                options: backendOverviewColumns.map((val) => val.name),
                multiSelect: true,
                selectedKeys: selectedColumns,
                onChange: (option) => {
                    this._onColumnDropDownChange(option);
                },
            },
        ];
        return Object.entries(evalData).map(([langInfo, evalResult]) => {
            const metrics = Object.values(evalResult);

            return (
                <div className="overview__detail" key={langInfo}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Label>Language: {langInfo}</Label>
                        <TableHeader options={options} />
                    </Stack>

                    <DetailsList
                        items={metrics}
                        columns={columns}
                        onRenderItemColumn={this._renderItemColumn}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                    />
                </div>
            );
        });
    }

    private _renderAsChart(evalData: IBackencMetricsObj) {
        const { data } = this.state;

        const legends = data.map(([recordDetail, _]) => recordDetail.name);

        return Object.entries(evalData).map(([langInfo, evalResult]) => {
            const data = Object.entries(evalResult).map(
                ([category, categoryResult]) => {
                    const series = categoryResult.map((metrics, index) => {
                        return {
                            key: `series${index}`,
                            data: isNaN(metrics.err_rate)
                                ? 0
                                : metrics.err_rate,
                            color: getColorByIndex(index),
                            legend: legends[index],
                        };
                    });
                    return {
                        name: category,
                        series: series,
                    };
                }
            );

            return (
                <DocumentCard
                    key={langInfo}
                    className="overview__card"
                    type={DocumentCardType.compact}
                >
                    <DocumentCardDetails>
                        <DocumentCardTitle
                            className="overview__title"
                            title={"Language: " + langInfo}
                        />
                        <GroupedVerticalBarChart
                            data={data}
                            width={1500}
                            height={400}
                            showYAxisGridLines
                            yAxisTickCount={10}
                        />
                    </DocumentCardDetails>
                </DocumentCard>
            );
        });
    }
    private _mergeMetrics(metricsList: [RecordDetail, OcrBackendMetrics][]) {
        let resultList: IBackencMetricsObj = {};

        const datasetNames = new Set(
            metricsList.map((data) => data[0].dataset.displayFullName)
        );
        const categories = new Set(
            metricsList.flatMap((data) =>
                data[1].category_wer.map((wer) => wer.category)
            )
        );

        // initialize placeholder entries
        datasetNames.forEach((name) => {
            resultList[name] = {};
            categories.forEach((category) => {
                resultList[name][category] = Array(metricsList.length).fill({
                    category: category,
                    err_rate: NaN,
                });
            });
        });

        for (const index in metricsList) {
            const [recordDetail, metrics] = metricsList[index];
            for (const cer of metrics.category_cer) {
                resultList[recordDetail.dataset.displayFullName][cer.category][
                    index
                ] = cer;
            }
        }
        return resultList;
    }

    private _queryMetricsResult() {
        const details = this.props.records.flatMap((record) =>
            record.getDetails()
        );

        Promise.all(
            details.map((detail) =>
                detail.fetchMetricsWithRecordDetail<OcrBackendMetrics>(
                    "basic_metrics.json"
                )
            )
        ).then((data) => {
            this.setState({ data: data });
        });
    }

    private _onColumnDropDownChange(option?: IDropdownOption): void {
        const newSelectedItems = this.state.selectedColumns.slice();

        if (option) {
            if (option.selected) {
                // add the option if it's checked
                newSelectedItems.push(option.text);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(option.text);
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }

            this.setState({ selectedColumns: newSelectedItems });
        }
    }

    private _renderItemColumn(
        item: CategoryScore[],
        index?: number,
        column?: IColumn
    ) {
        const key = column!.fieldName;
        if (key === "category") {
            return item.find(
                (result) =>
                    result[key as keyof CategoryScore].toString() !== "NaN"
            )![key as keyof CategoryScore];
        } else {
            const values = item.map((v) => {
                return v[key as keyof CategoryScore] as number;
            });
            const base = values[values.length - 1];
            const displayText = values.map((val, idx) => {
                if (val > base) {
                    return (
                        <span className="table__item" key={`${key}_${idx}`}>
                            {val}
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                <b>&nbsp;(+{(val - base).toFixed(1)})</b>
                            </span>
                        </span>
                    );
                }
                if (val < base) {
                    return (
                        <span className="table__item" key={`${key}_${idx}`}>
                            {val}
                            <span
                                style={{
                                    color: "green",
                                }}
                            >
                                <b>&nbsp;({(val - base).toFixed(1)})</b>
                            </span>
                        </span>
                    );
                }
                return (
                    <span className="table__item" key={`${key}_${idx}`}>
                        {formatNumberDisplay(val)}
                    </span>
                );
            });
            return displayText;
        }
    }
}
