import React from "react";
import "./DocClassifierImageMetrics.scss";
import { Pivot, PivotItem, PivotLinkFormat } from "@fluentui/react";
import { MetricCompactDefinition, Record } from "../../DataContract";
import { DocClassifierImageView } from "./DocClassifierImageView";
import { IPivotItemDarkStyles, IPivotItemStyles } from "../Common/CommonStyle";
import { FullScreen } from "../Common/FullScreen";

const DEFAULT_FIELD = [
    "F1",
    "Precision",
    "Recall",
    "TruePosCnt",
    "PdCnt",
    "GtCnt",
];
const DOC_LVL_SPLIT_CLASS_DEFINITION: MetricCompactDefinition = {
    name: "DocLvlSplitClass",
    displayName: "Doc level split and classification",
    props: DEFAULT_FIELD,
};
const DOC_LVL_SPLIT_DEFINITION: MetricCompactDefinition = {
    name: "DocLvlSplit",
    displayName: "Doc level split",
    props: DEFAULT_FIELD,
};

export const DOC_CLASSIFIER_IMAGE_METRICS_DEFINITIONS = [
    DOC_LVL_SPLIT_CLASS_DEFINITION,
    DOC_LVL_SPLIT_DEFINITION,
];

interface IProps {
    records: Record[];
    isDarkTheme?: boolean;
}

export class DocClassifierImageMetrics extends React.Component<IProps> {
    render() {
        return (
            <FullScreen>
                <Pivot
                    className="pivotContainer displayFlex"
                    linkFormat={PivotLinkFormat.links}
                    styles={
                        this.props.isDarkTheme
                            ? IPivotItemDarkStyles
                            : IPivotItemStyles
                    }
                >
                    {DOC_CLASSIFIER_IMAGE_METRICS_DEFINITIONS.map((metric) => {
                        return (
                            <PivotItem
                                key={`pivot_${metric.name}`}
                                className="displayFlex"
                                headerText={metric.displayName}
                                itemKey={metric.name}
                            >
                                <DocClassifierImageView
                                    key={`imgView_${metric.name}`}
                                    records={this.props.records}
                                    metricDefinition={metric}
                                    isDarkTheme={this.props.isDarkTheme}
                                ></DocClassifierImageView>
                            </PivotItem>
                        );
                    })}
                </Pivot>
            </FullScreen>
        );
    }
}
