import React from "react";
import { formatPercentDisplay } from "../../Utils";
import { IDataItem, Workspaces } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import { TableColumn, TableList } from "../../Controls";
import {
    VerticalFieldMetrics,
    VerticalMetrics,
    VERTICAL_COLUMNS,
    VERTICAL_REPORT_COLUMNS,
} from "../../Pages/Scenarios/VerticalBasePage";
import { exportOverviewListData } from "../../Utils/ExportFile";
import "../Common/MetricStyle.scss";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface IVerticalDataItem {
    fieldMetrics: VerticalFieldMetrics;
    data?: IDataItem<VerticalMetrics>;
}

interface IProps extends ICommonProps {
    workspace: string;
    dataItems: IDataItem<VerticalMetrics>[];
    pageName: string;
    isForReport?: boolean;
    VerticalImageViewDeepLinkHandler?: (key: string, linkData: any) => void;
}

interface IState extends ICommonState<VerticalMetrics> {
    dataItems: IDataItem<VerticalMetrics>[];
}

export class VerticalOverview extends CommonView<
    IProps,
    IState,
    VerticalMetrics
> {
    private matchDatasetVersion = false;
    constructor(props: IProps) {
        super(props);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.state = {
            dataItems: props.dataItems ?? [],
        };
    }

    public render() {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "hidden auto" }}>
                    {this._renderAsTable()}
                </div>
            </FullScreen>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();

        store.dispatch(
            updateStateAction({
                saveKey: `${this.props.saveSetKey}`,
                vertical: true,
                columns: this.props.isForReport
                    ? VERTICAL_REPORT_COLUMNS
                    : VERTICAL_COLUMNS,
            })
        );
    }

    exportAction = () => {
        const { isForReport, pageName } = this.props;
        const { selectedColumns } = this.state;
        const report_columns = isForReport
            ? VERTICAL_REPORT_COLUMNS
            : VERTICAL_COLUMNS;
        const columns: TableColumn[] = report_columns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.name) !== -1
        );

        exportOverviewListData(this.exportData, columns, pageName);
    };

    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            const { dataItems } = this.props;
            this.setState({
                dataItems: dataItems,
            });
        }
    }

    private _renderAsTable() {
        const { isForReport, records } = this.props;
        const { dataItems, selectedColumns } = this.state;
        const report_columns = isForReport
            ? VERTICAL_REPORT_COLUMNS
            : VERTICAL_COLUMNS;
        const columns: TableColumn[] = report_columns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.name) !== -1
        );
        this.exportData = [];
        if (dataItems.length > 0) {
            const data = this._prepareRenderData(this.matchDatasetVersion);
            return data.map(([datasetName, recordMetrics], index) => {
                const tableData = new Map<string, VerticalFieldMetrics[]>();
                recordMetrics.forEach(([_fieldName, fieldMetricsItems]) => {
                    let key = "";
                    let items = fieldMetricsItems.map((fieldMetricsItem) => {
                        let fieldMetrics = fieldMetricsItem.fieldMetrics;
                        if (fieldMetrics) {
                            fieldMetrics.DatasetName = datasetName;
                            fieldMetrics = formatPercentDisplay(fieldMetrics, [
                                "F1",
                                "Recall",
                                "Accuracy",
                                "Precision",
                            ]);
                        }

                        if (key === "") {
                            key = `${fieldMetrics.FieldName}-${fieldMetrics.CompareType}-${fieldMetrics.MicroOrMacro}`;
                        }

                        return fieldMetrics;
                    });

                    if (isForReport) {
                        items = items.filter(
                            (item) => item.MicroOrMacro === "Micro"
                        );
                    }

                    if (items && items.length > 0 && !tableData.has(key)) {
                        tableData.set(key, items);
                    }
                });

                if (tableData && tableData.size > 0) {
                    this.exportData.push([datasetName, tableData]);
                    return (
                        <TableList<VerticalFieldMetrics>
                            key={`vertical_${index}`}
                            evalDataCount={records.length}
                            evalData={tableData}
                            columns={columns}
                            tableTitle={datasetName}
                            downloadTableTitle={datasetName}
                            onItemInvoked={this._onItemInvoked}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    );
                } else {
                    return <></>;
                }
            });
        }
        return <></>;
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        let { dataItems } = this.state;
        dataItems = dataItems.map((item) => {
            if (
                item &&
                item.metrics &&
                item.metrics.fieldMetrics &&
                item.metrics.fieldMetrics.length > 0
            ) {
                item.metrics.fieldMetrics = item.metrics.fieldMetrics.map(
                    (metirc) => {
                        metirc.Key = `${metirc.FieldName}-${metirc.CompareType}-${metirc.MicroOrMacro}`;
                        return metirc;
                    }
                );
            }

            return item;
        });

        const allDatasetNames = Array.from(
            new Set(
                this.props.records.flatMap((record) =>
                    record
                        .getDetails()
                        .map((d) =>
                            matchDatasetVersion
                                ? d.dataset.displayFullName
                                : d.dataset.displayName
                        )
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const metricKeys = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const metrics = item?.metrics.fieldMetrics;
                        return metrics
                            ? metrics.map((metric) => metric.Key)
                            : [];
                    })
                )
            );

            const groupData = metricKeys.map((metricKey) => {
                // retrieve field data
                const werItems = items.map((item) => {
                    let fieldMetrics: VerticalFieldMetrics | undefined;
                    if (item !== undefined) {
                        const { metrics } = item;

                        if (metrics && metrics.fieldMetrics) {
                            fieldMetrics = metrics.fieldMetrics.find(
                                (f) => f.Key === metricKey
                            );
                        }
                    }

                    if (fieldMetrics === undefined) {
                        fieldMetrics = {
                            FieldName: "",
                            CompareType: "",
                            MicroOrMacro: "",
                            TruePositive: NaN,
                            TrueNegative: NaN,
                            FalsePositive: NaN,
                            FalseNegative: NaN,
                            Accuracy: NaN,
                            Precision: NaN,
                            Recall: NaN,
                            F1: NaN,
                            DatasetName: datasetName,
                            Key: metricKey,
                        };
                    }

                    return {
                        data: item,
                        fieldMetrics: fieldMetrics,
                    } as IVerticalDataItem;
                });
                return [metricKey, werItems] as [string, IVerticalDataItem[]];
            });

            return [datasetName, groupData] as [
                string,
                [string, IVerticalDataItem[]][]
            ];
        });

        return data;
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const { workspace, VerticalImageViewDeepLinkHandler } = this.props;
        if (VerticalImageViewDeepLinkHandler) {
            const values = item as any[];
            if (values?.length >= 2) {
                const fieldMetricsArr = values[1] as VerticalFieldMetrics[];
                if (fieldMetricsArr?.length > 0) {
                    let linkData: { [key: string]: string | undefined } = {};
                    linkData.toSelectLanguage = fieldMetricsArr[0].DatasetName;
                    linkData.toSelectField = fieldMetricsArr[0].FieldName;
                    let selectedKey: string;

                    switch (workspace) {
                        case Workspaces.Receipt: {
                            selectedKey = "overviewReceiptFieldMetrics";
                            break;
                        }
                        case Workspaces.BusinessCard: {
                            selectedKey = "overviewBusinessCardFieldMetrics";
                            break;
                        }
                        case Workspaces.IdCard: {
                            selectedKey = "overviewIdCardFieldMetrics";
                            break;
                        }
                        case Workspaces.IndustryPrebuilt: {
                            selectedKey = "overviewIndustryFieldMetrics";
                            break;
                        }
                        case Workspaces.HealthDoc: {
                            selectedKey = "overviewHealthFieldMetrics";
                            break;
                        }
                        case Workspaces.ExperimentalPrebuilt: {
                            selectedKey =
                                "overviewExperimentalPrebuiltFieldMetrics";
                            break;
                        }

                        default: {
                            selectedKey = "";
                        }
                    }

                    VerticalImageViewDeepLinkHandler(selectedKey, linkData);
                }
            }
        }
    }

    queryMetricsResult() {}
}
