export const SCRIPTS: { [key: string]: { [key: string]: any } } = {
    Latin: {
        Printed: [
            "en_US",
            "es_ES",
            "de_DE",
            "fr_FR",
            "it_IT",
            "pt_PT",
            "nl_NL",
            "cz_CZ",
            "da_DA",
            "hu_HU",
            "no_NO",
            "pl_PL",
            "sv_SV",
            "tr_TR",
            "single_char",
            "SYMBOL",
            "hr_HR",
            "ms_MY",
            "vi_VN",
            "ro_RO",
            "id_ID",
            "lt_LT",
            "lv_LV",
            "sr_RS_Latn",
            "sk_SK",
        ],
        Handwritten: [
            "en_US_hw",
            "es_ES_hw",
            "de_DE_hw",
            "fr_FR_hw",
            "it_IT_hw",
            "pt_PT_hw",
        ],
        Highlight: {
            English: "en_US",
            French: "fr_FR",
            Italian: "it_IT",
            German: "de_DE",
            Spanish: "es_ES",
            Portuguese: "pt_PT",
            SingleChar: "single_char",
            Symbol: "SYMBOL",
        },
    },
    CJK: {
        Printed: ["zh_CN", "ja_JP", "ko_KR", "zh_TW"],
        Handwritten: ["zh_CN_hw", "ja_JP_hw", "ko_KR_hw"],
        Highlight: {
            "Chinese Simplified": "zh_CN",
            Japanese: "ja_JP",
            Korean: "ko_KR",
        },
    },
    Cyrillic: {
        Printed: ["ru_RU", "uk_UA", "sr_RS_Cyrl"],
        Handwritten: [],
        Highlight: {
            Russian: "ru_RU",
        },
    },
    Devanagari: {
        Printed: ["hi_IN"],
        Handwritten: [],
        Highlight: {
            Hindi: "hi_IN",
        },
    },
    Arabic: {
        Printed: ["ar_SA"],
        Handwritten: [],
        Highlight: {
            Arabic: "ar_SA",
        },
    },
    Greek: {
        Printed: ["el_GR"],
        Handwritten: [],
        Highlight: {
            Greek: "el_GR",
        },
    },
    Thai: {
        Printed: ["th_TH"],
        Handwritten: [],
        Highlight: {
            Thai: "th_TH",
        },
    },
    Entity: {
        Printed: ["Entity_SerialNumberEval"],
        Handwritten: [],
        Highlight: {
            Cheque: "Entity_SerialNumberEval",
        },
    },
    Vertical: {
        Printed: [
            "Vertical_BizCard",
            "Vertical_IdDocument",
            "Vertical_Invoice",
            "Vertical_Receipt",
        ],
        Handwritten: [],
        Highlight: {
            BusinessCard: "Vertical_BizCard",
            IdDocument: "Vertical_IdDocument",
            Invoice: "Vertical_Invoice",
            Receipt: "Vertical_Receipt",
        },
    },
};

export const GENERAL_SCRIPTS = [
    "Latin",
    "CJK",
    "Cyrillic",
    "Devanagari",
    "Arabic",
    "Greek",
    "Thai",
    "Entity",
];

export const COVERAGE_SCRIPTS = [
    "Latin",
    "CJK",
    "Cyrillic",
    "Devanagari",
    "Arabic",
];

export const LATENCY_SCRIPTS = [
    "LatinV2",
    "CJK",
    "Cyrillic",
    "Devanagari",
    "Arabic",
    "Greek",
    "Thai",
    "Entity",
    "Vertical",
];

export const NON_DOC_TYPE_SEGMENTS = [
    "BookCover",
    "BusinessCard",
    "Menu",
    "Poster",
    "ProductLabel",
    "Slide",
    "StreetView",
    "HandwrittenNotes",
    "WhiteboardBlackboard",
];

export const DOC_TYPE_SEGMENTS = [
    "Document",
    "Invoice",
    "Receipt",
    "EyeChart",
    "Table",
    "CharacterBox",
    "BankChecks",
    "TravelerEntryForms",
    "BillsReceipts",
    "Applications",
    "MedicalPrescriptions",
    "Invoices",
    "Contracts",
    "Tables",
];

export const CAL_SEGMENTS = ["Documentation Categories", "Overall Categories"];

// We don't have 'Receipt_name' since v2.0-entity... why?
export const ENTITY_MAP: { [key: string]: string[] } = {
    Receipt_price: ["Subtotal", "Tip", "Total", "TotalPrice", "Price"],
    Receipt_date: ["TransactionDate"],
    Receipt_quantity: ["Quantity"],
    Receipt_quantity_unit: ["QuantityUnit"],
    Invoice_quantity: ["ItemsQuantity"],
    Invoice_date: [
        "DueDate",
        "InvoiceDate",
        "ItemsDate",
        "ServiceEndDate",
        "ServiceStartDate",
    ],
    Invoice_price: [
        "ItemsAmount",
        "ItemsDiscount",
        "ItemsTax",
        "ItemsUnitPrice",
        "PreviousUnpaidBalance",
        "SubTotal",
        "TotalAmountDue",
        "TotalCurrentCharges",
        "TotalTax",
    ],
    Invoice_name: [
        "BillingName",
        "Customer",
        "CustomerName",
        "RemittanceName",
        "ServiceName",
        "ShippingName",
        "Vendor",
        "VendorName_Other",
    ],
    Invoice_address: [
        "BillingAddress",
        "CustomerAddress_Other",
        "RemittanceAddress",
        "ServiceAddress",
        "ShippingAddress",
        "VendorAddress_Other",
    ],
    BusinessCard_address: ["Addresses"],
    BusinessCard_email: ["Emails"],
    BusinessCard_websites: ["Websites"],
    BusinessCard_name: ["FirstName", "LastName"],
    BusinessCard_phone_number: ["Faxes", "MobilePhones", "WorkPhones"],
    IdDocument_address: ["Address"],
    IdDocument_sex: ["Sex"],
    IdDocument_date: ["DateOfBirth", "DateOfExpiration"],
    IdDocument_name: ["FirstName", "LastName"],
    IdDocument_document_number: ["DocumentNumber"],
    IdDocument_mrz: ["Passport_MachineReadableZone"],
};

export const EER_SCRIPTS: { [key: string]: { [key: string]: string[] } } = {
    GeneralEntity: {
        PrintedHandwritten: [
            "TextAnalyticsAPI_DateTime_Date",
            "Number",
            "SingleNumberRestrictedOther",
            "SingleNumberRestrictedTable",
            "CurrencySymbol",
            "Price",
            "TextAnalyticsAPI_Address",
            "TextAnalyticsAPI_Email",
            "TextAnalyticsAPI_URL",
        ],
        PrintedOnly: ["SingleCharRestricted", "MICR_E13B", "MICR_CMC7"],
        HandwrittenOnly: ["DotUnderline"],
    },
    VerticalEntity: {
        Vertical_Invoice: [
            "Invoice_quantity",
            "Invoice_date",
            "Invoice_price",
            "Invoice_name",
            "Invoice_address",
        ],
        Vertical_Receipt: [
            "Receipt_date",
            "Receipt_price",
            "Receipt_quantity",
            "Receipt_quantity_unit",
        ],
        Vertical_IdDocument: ["IdDocument_mrz"],
        Vertical_BizCard: [
            "BusinessCard_phone_number",
            "BusinessCard_address",
            "BusinessCard_email",
            "BusinessCard_websites",
        ],
    },
};

export const LATENCY_METRICS = {
    OcrMS: "_ocrMS",
    DetectorMS: "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",
    RecognizerMS: "_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs",
};

export const FAKE_HIGHLIGHT_METRICS: { [key: string]: { [key: string]: any } } =
    {
        property: {
            LastUpdateTime: "2022-04-05 15:30:52",
        },
        language: {
            Latin: {
                Coverage: 335,
                Unofficial: 250,
            },
            CJK: {
                Coverage: 34855,
                Unofficial: null,
            },
            Cyrillic: {
                Coverage: 653,
                Unofficial: 232,
            },
            Devanagari: {
                Coverage: null,
                Unofficial: 830,
            },
            Arabic: {
                Coverage: null,
                Unofficial: 134,
            },
            Greek: {
                Coverage: null,
                Unofficial: null,
            },
            Thai: {
                Coverage: null,
                Unofficial: null,
            },
        },
        WER: {
            Latin: {
                Printed: {
                    Document: 2.2,
                    "Non-Document": 6.2,
                    Overall: 3.1,
                },
                Handwritten: {
                    Form: 11.6,
                    Note: 19.4,
                    Overall: 13.0,
                },
                Highlight: {
                    English: {
                        Printed: {
                            Document: 3.1,
                            "Non-Document": 6.9,
                            Overall: 5.4,
                        },
                        Handwritten: {
                            Form: 12.9,
                            Note: 19.2,
                            Overall: 14.5,
                        },
                    },
                    French: {
                        Printed: {
                            Document: 3.6,
                            "Non-Document": 6.9,
                            Overall: 4.5,
                        },
                        Handwritten: {
                            Form: 15.7,
                            Note: 21.8,
                            Overall: 16.9,
                        },
                    },
                    Italian: {
                        Printed: {
                            Document: 2.2,
                            "Non-Document": 5.7,
                            Overall: 3.1,
                        },
                        Handwritten: {
                            Form: 9.0,
                            Note: 18.0,
                            Overall: 10.3,
                        },
                    },
                    German: {
                        Printed: {
                            Document: 3.2,
                            "Non-Document": 6.8,
                            Overall: 4.0,
                        },
                        Handwritten: {
                            Form: 8.8,
                            Note: 18.6,
                            Overall: 10.0,
                        },
                    },
                    Spanish: {
                        Printed: {
                            Document: 3.2,
                            "Non-Document": 5.5,
                            Overall: 3.7,
                        },
                        Handwritten: {
                            Form: 13.8,
                            Note: 19.0,
                            Overall: 14.8,
                        },
                    },
                    Portuguese: {
                        Printed: {
                            Document: 3.6,
                            "Non-Document": 6.8,
                            Overall: 4.4,
                        },
                        Handwritten: {
                            Form: 11.4,
                            Note: 20.1,
                            Overall: 12.9,
                        },
                    },
                    SingleChar: {
                        Printed: {
                            Document: 1.3,
                            "Non-Document": null,
                            Overall: 1.3,
                        },
                        Handwritten: {
                            Form: null,
                            Note: null,
                            Overall: null,
                        },
                    },
                    Symbol: {
                        Printed: {
                            Document: 8.5,
                            "Non-Document": null,
                            Overall: 8.5,
                        },
                        Handwritten: {
                            Form: null,
                            Note: null,
                            Overall: null,
                        },
                    },
                },
            },
            CJK: {
                Printed: {
                    Document: 3.0,
                    "Non-Document": 7.2,
                    Overall: 4.5,
                },
                Handwritten: {
                    Form: 6.1,
                    Note: 7.1,
                    Overall: 6.4,
                },
                Highlight: {
                    "Chinese Simplified": {
                        Printed: {
                            Document: 3.5,
                            "Non-Document": 9.6,
                            Overall: 6.4,
                        },
                        Handwritten: {
                            Form: 6.9,
                            Note: 7.1,
                            Overall: 7.0,
                        },
                    },
                    Japanese: {
                        Printed: {
                            Document: 3.7,
                            "Non-Document": 7.6,
                            Overall: 4.9,
                        },
                        Handwritten: {
                            Form: 6.3,
                            Note: 8.1,
                            Overall: 6.6,
                        },
                    },
                    Korean: {
                        Printed: {
                            Document: 2.4,
                            "Non-Document": 6.3,
                            Overall: 3.9,
                        },
                        Handwritten: {
                            Form: 5.4,
                            Note: 6.2,
                            Overall: 5.5,
                        },
                    },
                },
            },
            Cyrillic: {
                Printed: {
                    Document: 1.8,
                    "Non-Document": 5.6,
                    Overall: 2.4,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
            Devanagari: {
                Printed: {
                    Document: 1.3,
                    "Non-Document": 5.2,
                    Overall: 2.0,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
            Arabic: {
                Printed: {
                    Document: 7.2,
                    "Non-Document": 11.5,
                    Overall: 8.1,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
            Greek: {
                Printed: {
                    Document: 2.1,
                    "Non-Document": 8.6,
                    Overall: 4.3,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
            Thai: {
                Printed: {
                    Document: 0.9,
                    "Non-Document": 6.7,
                    Overall: 3.1,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
            Entity: {
                Printed: {
                    Document: 9.0,
                    "Non-Document": null,
                    Overall: 9.0,
                },
                Handwritten: {
                    Form: null,
                    Note: null,
                    Overall: null,
                },
            },
        },
        EER: {
            "Vertical Entity": {
                Invoice: {
                    EER: 2.8,
                    IgnoreSpaceEER: 2.3,
                },
                Invoice_quantity: {
                    EER: 3.3,
                    IgnoreSpaceEER: 3.3,
                },
                Invoice_date: {
                    EER: 1.6,
                    IgnoreSpaceEER: 1.1,
                },
                Invoice_price: {
                    EER: 2.1,
                    IgnoreSpaceEER: 1.5,
                },
                Invoice_name: {
                    EER: 6.3,
                    IgnoreSpaceEER: 5.6,
                },
                Invoice_address: {
                    EER: 2.6,
                    IgnoreSpaceEER: 1.7,
                },
                Receipt: {
                    EER: 8.1,
                    IgnoreSpaceEER: 6.6,
                },
                Receipt_date: {
                    EER: 5.8,
                    IgnoreSpaceEER: 3.8,
                },
                Receipt_price: {
                    EER: 6.2,
                    IgnoreSpaceEER: 5.4,
                },
                Receipt_quantity: {
                    EER: 12.5,
                    IgnoreSpaceEER: 12.3,
                },
                IdDocument: {
                    EER: 7.3,
                    IgnoreSpaceEER: 5.9,
                },
                IdDocument_mrz: {
                    EER: 5.2,
                    IgnoreSpaceEER: 5.2,
                },
                BizCard: {
                    EER: 9.3,
                    IgnoreSpaceEER: 3.7,
                },
                BusinessCard_phone_number: {
                    EER: 20.2,
                    IgnoreSpaceEER: 1.8,
                },
                BusinessCard_address: {
                    EER: 11.1,
                    IgnoreSpaceEER: 2.4,
                },
                BusinessCard_email: {
                    EER: 4.4,
                    IgnoreSpaceEER: 2.6,
                },
                BusinessCard_websites: {
                    EER: 3.9,
                    IgnoreSpaceEER: 2.3,
                },
            },
            "General Entity": {
                TA_DateTime_Date: {
                    EER: 3.5,
                    IgnoreSpaceEER: 3.1,
                },
                Number: {
                    EER: 4.2,
                    IgnoreSpaceEER: 4.2,
                },
                SingleCharRestricted: {
                    EER: 33.8,
                    IgnoreSpaceEER: 33.8,
                },
                SingleNumberRestrictedOther: {
                    EER: 7.8,
                    IgnoreSpaceEER: 7.8,
                },
                SingleNumberRestrictedTable: {
                    EER: 7.8,
                    IgnoreSpaceEER: 7.8,
                },
                CurrencySymbol: {
                    EER: 4.7,
                    IgnoreSpaceEER: 4.7,
                },
                Price: {
                    EER: 6.2,
                    IgnoreSpaceEER: 5.5,
                },
                TA_Address: {
                    EER: 18.0,
                    IgnoreSpaceEER: 11.3,
                },
                TA_Email: {
                    EER: 15.3,
                    IgnoreSpaceEER: 6.9,
                },
                TA_URL: {
                    EER: 11.8,
                    IgnoreSpaceEER: 7.3,
                },
                MICR_E13B: {
                    EER: 13.4,
                    IgnoreSpaceEER: 12.9,
                },
                MICR_CMC7: {
                    EER: 9.0,
                    IgnoreSpaceEER: 9.0,
                },
                Hwr_TA_DateTime_Date: {
                    EER: 14.8,
                    IgnoreSpaceEER: 14.1,
                },
                Hwr_Number: {
                    EER: 9.9,
                    IgnoreSpaceEER: 9.9,
                },
                Hwr_SingleNumberRestrictedOther: {
                    EER: 15.4,
                    IgnoreSpaceEER: 15.4,
                },
                Hwr_SingleNumberRestrictedTable: {
                    EER: 15.4,
                    IgnoreSpaceEER: 15.4,
                },
                Hwr_CurrencySymbol: {
                    EER: 6.4,
                    IgnoreSpaceEER: 6.4,
                },
                Hwr_Price: {
                    EER: 13.1,
                    IgnoreSpaceEER: 11.9,
                },
                Hwr_TA_Address: {
                    EER: 34.8,
                    IgnoreSpaceEER: 29.9,
                },
                Hwr_TA_Email: {
                    EER: 43.5,
                    IgnoreSpaceEER: 32.9,
                },
                Hwr_TA_URL: {
                    EER: 38.7,
                    IgnoreSpaceEER: 29.9,
                },
                Hwr_DotUnderline: {
                    EER: 46.1,
                    IgnoreSpaceEER: 40.0,
                },
            },
        },
        Latency: {
            Vertical: {
                P50: {
                    OcrMS: 433.69,
                    DetectorMS: 272.67,
                    RecognizerMS: 63.08,
                },
                P90: {
                    OcrMS: 553.48,
                    DetectorMS: 327.14,
                    RecognizerMS: 139.3,
                },
                P99: {
                    OcrMS: 730.71,
                    DetectorMS: 361.32,
                    RecognizerMS: 290.05,
                },
            },
        },
    };
