import React from "react";
import { Modal } from "@fluentui/react";

import { CommonView, ICommonState } from "../Common/CommonMetrics";

import { IMetricProps } from "../Common/MetricView";
import {
    CheckboxImageVisualizer,
    ColumnValueType,
    ITableConfigs,
    TableColumn,
    TableHeader,
    TableList,
} from "../../Controls";
import {
    IMetricUnit,
    Record,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import {
    CHECKBOX_METRICS_DEFINITIONS,
    CheckboxMetricDefinition,
} from "./OcrCheckboxDataInterface";
import _ from "lodash";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { exportTableListData } from "../../Utils/ExportFile";
import { FullScreen } from "../Common/FullScreen";

interface CheckboxImageCharMetric extends IMetricUnit {
    mAP: number;
    mAR: number;
    mAF1: number;
    square: number;
    round: number;
    underline: number;
    grid: number;
    bracket: number;
    toggle: number;
    circlable: number;
    other: number;
    groundTruth: string;
    recognition: string;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: any;
}

interface ICheckboxState extends ICommonState<any> {
    dataItems: IDataItem[];
    selectLanguage?: string;
    inspectImgUrl: string;
    selectImageId?: string;
    columns?: TableColumn[];
}

enum CheckboxType {
    ground_truth,
    recognition,
}

const GROUP_SYMBOL_SEPARATOR = " | ";
const VISUALIZE_PATH = "visualize";
const GROUND_TRUTH_SYMBOL = "_gt";

interface CheckBoxFieldMetrics {
    [key: string]: number | string;
}

interface IProps extends IMetricProps {
    storageVersion?: string;
}

export class OcrCheckboxImageView extends CommonView<
    IProps,
    ICheckboxState,
    CheckboxImageCharMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        this.state = {
            dataItems: [],
            selectLanguage: this._getLanguageList(true)[0],
            inspectImgUrl: "",
            matchDatasetVersion: true,
        };
    }

    render() {
        const { storageVersion } = this.props;
        return (
            <div style={{ height: "100%", overflow: "hidden auto" }}>
                {storageVersion === "v2"
                    ? this._renderNewData()
                    : this._renderOldData()}
            </div>
        );
    }

    public componentDidUpdate(
        prevProps: IMetricProps,
        prevState: ICommonState<any>
    ): void {
        super.componentDidUpdate(prevProps, prevState);

        if (!_.isEqual(prevState.dataItems, this.state.dataItems)) {
            if (
                this.state.dataItems.length > 0 &&
                this.props.storageVersion === "v2"
            ) {
                const item = this.state.dataItems.filter(
                    (item) =>
                        !_.isEmpty(item.metrics) &&
                        item.metrics.header &&
                        item.metrics.header.length > 0
                );
                const columnNames = item[0].metrics.header;
                const columns = this._columnsUpdate(columnNames || []);
                if (columns.length > 0) {
                    this.setState({
                        columns: columns,
                    });
                    store.dispatch(
                        updateStateAction({
                            columns: columns,
                            saveKey: `${Workspaces.OcrCheckbox}_${Typename.CheckboxGeneralMetrics}_ByImage`,
                        })
                    );
                }
            }
        }
    }

    private _columnsUpdate(columnNames: string[]) {
        const columns = columnNames.map((col) => {
            let valueType = ColumnValueType.Number;
            let distinctStr = false;
            let isKey = false;
            if (col === "id") {
                valueType = ColumnValueType.String;
                distinctStr = true;
                isKey = true;
            }
            return {
                key: col.replaceAll("-", "_"),
                name: col,
                minWidth: 100,
                maxWidth: 180,
                fieldName: col.replaceAll("-", "_"),
                isResizable: true,
                valueType: valueType,
                distinctStr: distinctStr,
                maxDecimalPlaces: 3,
                isKey: isKey,
            };
        });

        return columns;
    }

    private _renderOldData = () => {
        const { records } = this.props;
        const { selectImageId } = this.state;
        const experiments = records.map((record) => record.name);
        const targetDefinition = CHECKBOX_METRICS_DEFINITIONS.find(
            (definition) => definition.name === "CheckboxCOCOMetrics"
        );
        if (targetDefinition) {
            const dataMap = this._prepareRenderData(targetDefinition);
            if (dataMap) {
                let evalData: any = {};
                let columns: TableColumn[] = [
                    {
                        key: "image",
                        name: "Image",
                        fieldName: "image",
                        isKey: true,
                        valueType: ColumnValueType.String,
                        minWidth: 300,
                        maxWidth: 500,
                        isResizable: true,
                    },
                ];
                const propKey = dataMap.keys().next().value;
                const propDefine = targetDefinition.props.find(
                    (p) => p.name === propKey
                );

                if (propDefine) {
                    propDefine.props.forEach((p) => {
                        const col = {
                            key: p,
                            name: p,
                            fieldName: p,
                            isKey: false,
                            valueType: ColumnValueType.Number,
                            minWidth: 100,
                            maxWidth: 150,
                            isResizable: true,
                        };
                        columns.push(col);
                    });
                }

                columns.push({
                    key: "groundTruth",
                    name: "Ground Truth",
                    fieldName: "groundTruth",
                    isKey: false,
                    valueType: ColumnValueType.Url,
                    minWidth: 150,
                    maxWidth: 200,
                    isResizable: true,
                });

                columns.push({
                    key: "recognition",
                    name: "Recognition",
                    fieldName: "recognition",
                    isKey: false,
                    valueType: ColumnValueType.Url,
                    minWidth: 150,
                    maxWidth: 200,
                    isResizable: true,
                });

                dataMap.forEach((evalDataMap, groupName) => {
                    const itemArr = Array.from(evalDataMap);
                    if (itemArr && itemArr.length > 0) {
                        itemArr.forEach(([key, values]) => {
                            evalData[groupName + GROUP_SYMBOL_SEPARATOR + key] =
                                values;
                        });
                    }
                });
                this.exportData = evalData;
                const showColumns: TableColumn[] = columns.filter(
                    (value) =>
                        this.state.selectedColumns?.findIndex(
                            (col) => col === value.name
                        ) !== -1
                );

                return (
                    <>
                        <Modal
                            isOpen={true}
                            styles={{
                                root: {
                                    display: !!selectImageId ? "flex" : "none",
                                },
                            }}
                            onDismiss={() =>
                                this.setState({ selectImageId: undefined })
                            }
                            containerClassName="modal"
                        >
                            <CheckboxImageVisualizer
                                experiments={experiments}
                                fileId={selectImageId}
                                onLoadVisualizer={(imageId) => {
                                    if (imageId && evalData[imageId]) {
                                        return evalData[imageId].flatMap(
                                            (data: any) => {
                                                let urls = [];
                                                urls.push(data.recognition);
                                                return urls;
                                            }
                                        );
                                    } else {
                                        return [];
                                    }
                                }}
                                onRequestGtUrl={(imageId) => {
                                    if (imageId && evalData[imageId]) {
                                        return evalData[imageId][0]!
                                            .groundTruth;
                                    } else {
                                        return "";
                                    }
                                }}
                                onDismiss={() => {
                                    this.setState({ selectImageId: undefined });
                                }}
                            />
                        </Modal>
                        <FullScreen>
                            <TableList<any>
                                key={this.state.selectLanguage}
                                columns={showColumns}
                                evalData={evalData}
                                evalDataCount={records.length}
                                groupSymbolSeparator={GROUP_SYMBOL_SEPARATOR}
                                downloadTableTitle={this.state.selectLanguage}
                                renderTableHeader={this._renderTableHeader}
                                isWiderCell={true}
                                onItemInvoked={(item) => {
                                    const [imageId] = item;
                                    this.setState({ selectImageId: imageId });
                                }}
                                isDarkTheme={this.props.isDarkTheme}
                            />
                        </FullScreen>
                    </>
                );
            }
        }

        return <></>;
    };
    exportAction = () => {
        exportTableListData(
            this.exportData,
            this.state.columns || [],
            "OcrCheckboxImageView"
        );
    };

    private _renderNewData() {
        const { records } = this.props;
        const experiments = records.map((record) => record.name);
        const { columns, selectImageId } = this.state;
        if (this.state.dataItems.length > 0 && columns) {
            const data = this._prepareRenderNewData();
            this.exportData = data;
            const showColumns: TableColumn[] = columns.filter(
                (value) =>
                    this.state.selectedColumns?.findIndex(
                        (col) => col === value.name
                    ) !== -1
            );
            return (
                <>
                    <Modal
                        isOpen={true}
                        styles={{
                            root: {
                                display: !!selectImageId ? "flex" : "none",
                            },
                        }}
                        onDismiss={() =>
                            this.setState({ selectImageId: undefined })
                        }
                        containerClassName="modal"
                    >
                        <CheckboxImageVisualizer
                            experiments={experiments}
                            fileId={selectImageId}
                            onLoadVisualizer={(imageId) => {
                                if (imageId && data[imageId]) {
                                    return data[imageId].map(
                                        (item: any) => item.imageUrl
                                    );
                                } else {
                                    return [];
                                }
                            }}
                            onDismiss={() => {
                                this.setState({ selectImageId: undefined });
                            }}
                        />
                    </Modal>
                    <FullScreen>
                        <TableList<CheckBoxFieldMetrics>
                            key={this.state.selectLanguage}
                            columns={showColumns}
                            evalData={data}
                            evalDataCount={records.length}
                            downloadTableTitle={this.state.selectLanguage}
                            renderTableHeader={this._renderTableHeader}
                            isWiderCell={true}
                            onItemInvoked={(item) => {
                                const [imageId] = item;

                                this.setState({
                                    selectImageId: imageId,
                                });
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        />
                    </FullScreen>
                </>
            );
        }
        return <></>;
    }

    queryMetricsResult(): void {
        this._queryMetricsResult("details.json");
    }

    private _prepareRenderData(
        targetDefinition: CheckboxMetricDefinition
    ): Map<string, Map<string, any[]>> {
        const { records } = this.props;
        const { dataItems, matchDatasetVersion, selectLanguage } = this.state;
        let dataDict = new Map<string, Map<string, any[]>>();
        if (
            records &&
            records.length > 0 &&
            dataItems &&
            dataItems.length > 0 &&
            selectLanguage &&
            targetDefinition
        ) {
            const colCount = records.length;
            const definitionNames = targetDefinition.props.map(
                (prop) => prop.name
            );

            const items = records.map((_, recordIndex) => {
                const item = dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        selectLanguage ===
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName
                                : item.recordDetail.dataset.displayName)
                    );
                });
                return item;
            });
            items.forEach((item) => {
                if (item && item.metrics) {
                    const colIndex = item.recordIndex;
                    const metricsArr = Object.entries<any>(item.metrics);

                    let url = this._fetchCheckboxVisualizedUrl(
                        records[colIndex],
                        item.recordDetail
                    );
                    url = item.recordDetail.composeMetricUrl(
                        url.replace(
                            `${item.recordDetail.getRawProp<string>(
                                "storageRoot"
                            )}/`,
                            ""
                        )
                    );
                    let subfolder = url.split("/").pop();
                    metricsArr.forEach(([imgKey, obj]) => {
                        definitionNames.forEach((key) => {
                            let dataItem = obj[key];
                            if (dataItem) {
                                let itemsDict = dataDict.get(key);
                                if (!itemsDict) {
                                    itemsDict = new Map<string, any[]>();
                                }

                                let itemArr = itemsDict.has(imgKey)
                                    ? itemsDict.get(imgKey)
                                    : new Array<any>(colCount).fill(null);
                                dataItem.groundTruth =
                                    this._generateCheckboxUrl(
                                        CheckboxType.ground_truth,
                                        imgKey,
                                        url,
                                        subfolder!
                                    );

                                dataItem.recognition =
                                    this._generateCheckboxUrl(
                                        CheckboxType.recognition,
                                        imgKey,
                                        url,
                                        subfolder!
                                    );
                                itemArr![colIndex] = dataItem;
                                itemsDict.set(imgKey, itemArr!);
                                dataDict.set(key, itemsDict);
                            }
                        });
                    });
                }
            });
        }

        return dataDict;
    }

    private _prepareRenderNewData = () => {
        const { matchDatasetVersion, selectLanguage } = this.state;

        const items = this.props.records.map((_, recordIndex) => {
            const item = this.state.dataItems.find((item) => {
                return (
                    item.recordIndex === recordIndex &&
                    selectLanguage ===
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName
                            : item.recordDetail.dataset.displayName)
                );
            });
            return item;
        });

        const ids = Array.from(
            new Set(
                items.flatMap((item) => {
                    const rows = item?.metrics.rows as string[];
                    return rows ? rows.map((row) => row[0]) : [];
                })
            )
        );
        const map: any = {};
        ids.forEach((id) => {
            // retrieve field data
            const metricsItems = items.map((item) => {
                let fieldMetrics: CheckBoxFieldMetrics = {};
                const rows = item?.metrics.rows as string[][];
                const header = item?.metrics.header as string[];
                if (rows && rows.length > 0) {
                    const row = rows.filter((row) => row[0] === id);
                    if (row.length > 0) {
                        for (const key of header) {
                            const keyIndex = header.indexOf(key);
                            fieldMetrics[key.replaceAll("-", "_")] =
                                this._formaterNumber(row[0][keyIndex]);
                        }
                        fieldMetrics.imageUrl = `${
                            item?.recordDetail.composeMetricUrl(
                                `visualize/${String(fieldMetrics.id)}`
                            ) || ""
                        }`;
                    }
                }

                return fieldMetrics;
            });
            map[id] = metricsItems;
        });

        return map;
    };

    private _formaterNumber(number: any) {
        if (Number(number) === -1) {
            return NaN;
        }
        return number;
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        const selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        this._onQueryButtonClicked(selectLanguage);
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const { matchDatasetVersion, selectLanguage } = this.state;
        const languages = this._getLanguageList(matchDatasetVersion!);
        const selectLangKey = this._loadSelectedLanguage(
            selectLanguage,
            languages
        );

        const imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLangKey,
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged({
                        selectLanguage: language.text,
                    });
                },
            },
        ];

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _fetchCheckboxVisualizedUrl(
        record: Record,
        detail: RecordDetail
    ): string {
        const algo = detail.getRawProp<string>("algorithm");
        let sub_folder = "oneocr_highres";
        if (record.modelInfo.toLowerCase().includes("amazon")) {
            sub_folder = "amazon";
        }
        return `checkbox_evaluation_data/${record.modelInfo}/${record.runtimeVersion}/${record.buildSource}/${algo}/${detail.dataset.name}/${detail.dataset.name}__${detail.dataset.version}/${VISUALIZE_PATH}/${sub_folder}`;
    }

    private _generateCheckboxUrl(
        checkboxType: CheckboxType,
        imgFileName: string,
        url: string,
        subfolder: string
    ): string {
        let suffix = "";
        if (checkboxType === CheckboxType.ground_truth) {
            suffix = GROUND_TRUTH_SYMBOL;
        }
        let visualizePngName = `${imgFileName}_${subfolder}${suffix}.png`;

        return `${url}/${visualizePngName}`;
    }
}
