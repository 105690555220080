import "./FileVisualizer.scss";
import React from "react";
import {
    VisualizerBase,
    VisualizerProps,
    VisualizerState,
} from "./VisualizerBase";
import {
    DetailsList,
    FontIcon,
    IColumn,
    Label,
    PrimaryButton,
    SelectionMode,
    Stack,
} from "@fluentui/react";
import { ColumnValueType, TableColumn, TableList } from "../TableList";
import {
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../Utils/LoadingUtil";
import { GetExtensionWithoutDot, isDevEnvironment } from "../../Utils";
import { PDFDocument } from "pdf-lib";
import { vdiRequest } from "../../Utils/fetch";

const OFFICE_EXT_ARR = ["docx", "pptx", "xlsx"];

export interface GroundTruthEntity {
    docType: string;
    pages: Number[];
}

export interface PredictionEntity extends GroundTruthEntity {
    confidence: Number;
}

export interface SplitProbabilities {
    Split: number;
    Combine: number;
}

export interface TypeProbabilities {
    [key: string]: number;
}

export interface PageProbabilities {
    Page: number;
    Probabilty: { Type: TypeProbabilities; Split: SplitProbabilities };
}

export interface FileMeta {
    GroundTruth: GroundTruthEntity[];
    Prediction: PredictionEntity[];
    Pages: PageProbabilities[] | null;
}

export const GROUND_TRUTH_COLUMNS: IColumn[] = [
    {
        key: "docType",
        name: "DocType",
        fieldName: "docType",
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: "pages",
        name: "Pages",
        fieldName: "pages",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item?: GroundTruthEntity) => {
            if (item && item.pages && item.pages.length > 0) {
                return item.pages.join(",");
            }

            return "";
        },
    },
];

export const PREDICTION_COLUMNS: IColumn[] = [
    ...GROUND_TRUTH_COLUMNS,
    {
        key: "confidence",
        name: "Confidence",
        fieldName: "confidence",
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        onRender: (item?: PredictionEntity) => {
            if (item && !Number.isNaN(item.confidence)) {
                return +item.confidence.toFixed(2);
            }

            return NaN;
        },
    },
];

export const PROBABILITIES_COLUMNS: TableColumn[] = [
    {
        key: "Type",
        name: "Type",
        fieldName: "Type",
        minWidth: 180,
        maxWidth: 250,
        isResizable: true,
        valueType: ColumnValueType.String,
    },
    {
        key: "Pr",
        name: "Probability",
        fieldName: "Pr",
        minWidth: 40,
        maxWidth: 100,
        isResizable: true,
        maxDecimalPlaces: 2,
        valueType: ColumnValueType.Number,
    },
];

export interface FileVizInfo {
    fileUrl: string;
    fileMeta: FileMeta;
}

interface IProps extends VisualizerProps {
    fileId: string;
    onLoadVisualizer: (fileId?: string) => FileVizInfo | undefined;
    evalList?: [string, FileVizInfo][];
}

interface IState extends VisualizerState {
    pageStartIndex: number;
    fileSrcArrToDisplay: string[];
    probabilitiesArr: (PageProbabilities | undefined)[];
}

export class FileVisualizer extends VisualizerBase<IProps, IState> {
    private fileAccessUrl: string;
    private fileExt: string;
    private srcPdfDoc: PDFDocument | null;
    private defIframeSize = { W: 550, H: 700 };

    protected readonly VIEW_COUNT = 2;
    protected filePageCount: number;
    protected fileVizInfo: FileVizInfo | undefined;
    constructor(props: IProps) {
        super(props);

        this.loadFileData = this.loadFileData.bind(this);
        this.filePageCount = NaN;

        const fileVizInfo = props.onLoadVisualizer(props.fileId);
        if (fileVizInfo) {
            this.fileVizInfo = fileVizInfo;
            this.filePageCount =
                fileVizInfo.fileMeta.Pages === null
                    ? NaN
                    : fileVizInfo.fileMeta.Pages.length;
        }

        this.state = {
            fileId: props.fileId,
            fileUrl: fileVizInfo ? fileVizInfo.fileUrl : "",
            fileSrcArrToDisplay: [],
            probabilitiesArr: [],
            pageStartIndex: 0,
            currentPage: this.props.clickNumber ?? 0,
        };

        this.srcPdfDoc = null;
        this.fileAccessUrl = "";
        this.fileExt = GetExtensionWithoutDot(props.fileId).toLowerCase();
        this._loadSizeOfFileViewArea();
    }

    public componentDidMount(): void {
        if (this.fileVizInfo) {
            this.loadFileData(this.fileVizInfo, 0);
        }
    }

    public componentDidUpdate(
        prevProps: VisualizerProps,
        prevState: VisualizerState
    ): void {}

    protected onPageChange(pageNum: number) {
        const { evalList } = this.props;
        if (evalList && evalList.length > 0) {
            const [newFileId, fileVizInfo] = evalList[pageNum];
            this.fileVizInfo = fileVizInfo;
            this.filePageCount =
                fileVizInfo.fileMeta.Pages === null
                    ? NaN
                    : fileVizInfo.fileMeta.Pages.length;

            this.setState({ fileId: newFileId }, () => {
                this.fileExt = GetExtensionWithoutDot(newFileId).toLowerCase();
                this._loadSizeOfFileViewArea();

                this.loadFileData(fileVizInfo, 0);
                this.debounceOnPageChange(pageNum);
            });
        }
    }

    protected refreshCache(paramObj: { clickedIndex: number }) {
        this.setState({
            currentPage: paramObj.clickedIndex,
        });
    }

    protected loadFileData(
        fileVizInfo: FileVizInfo,
        filePageNum: number
    ): void {
        if (this.fileExt === "pdf") {
            this._loadPdfData(fileVizInfo, filePageNum);
        } else if (OFFICE_EXT_ARR.includes(this.fileExt)) {
            this._loadOfficeData(fileVizInfo, filePageNum);
        }
    }

    protected renderVisualizer(): JSX.Element {
        const { fileSrcArrToDisplay, probabilitiesArr, pageStartIndex } =
            this.state;

        return (
            <Stack className="visualizerPanel">
                {fileSrcArrToDisplay && fileSrcArrToDisplay.length > 0 && (
                    <>
                        <Stack horizontal className="fileContainer">
                            {this.fileExt === "pdf" &&
                                fileSrcArrToDisplay.map((fileSrc, index) => (
                                    <embed
                                        key={`embed_${index}`}
                                        src={
                                            fileSrc +
                                            "#navpanes=0&scrollbar=0&toolbar=0&view=FitH,top"
                                        }
                                        width={400}
                                        height={550}
                                    />
                                ))}

                            {this.fileExt &&
                                OFFICE_EXT_ARR.includes(this.fileExt) &&
                                fileSrcArrToDisplay.map((fileSrc, index) => (
                                    <>
                                        <iframe
                                            key={`embed_${index}`}
                                            title={`Office Web Viewer Page ${index}`}
                                            src={fileSrc}
                                            width={this.defIframeSize.W}
                                            height={this.defIframeSize.H}
                                        ></iframe>
                                    </>
                                ))}
                        </Stack>

                        <Stack horizontal className="pageBar">
                            <PrimaryButton
                                onClick={async () => {
                                    const { pageStartIndex } = this.state;
                                    if (pageStartIndex > 0 && this.srcPdfDoc) {
                                        const newPageStartIndex =
                                            pageStartIndex - 1;
                                        if (this.fileVizInfo) {
                                            if (newPageStartIndex > 0) {
                                                if (this.fileExt === "pdf") {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        newPageStartIndex
                                                    );
                                                } else if (
                                                    this.fileExt &&
                                                    OFFICE_EXT_ARR.includes(
                                                        this.fileExt
                                                    )
                                                ) {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        newPageStartIndex
                                                    );
                                                }
                                            } else {
                                                if (this.fileExt === "pdf") {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        0
                                                    );
                                                } else if (
                                                    this.fileExt &&
                                                    OFFICE_EXT_ARR.includes(
                                                        this.fileExt
                                                    )
                                                ) {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        0
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }}
                                disabled={pageStartIndex === 0}
                            >
                                <FontIcon iconName="DoubleChevronLeft8" />
                            </PrimaryButton>
                            <Label>
                                {probabilitiesArr && probabilitiesArr.length > 0
                                    ? probabilitiesArr
                                          .map((p) => p?.Page)
                                          .join(",")
                                    : Array.from(
                                          { length: this.VIEW_COUNT },
                                          (_, index) => {
                                              return pageStartIndex + index + 1;
                                          }
                                      )
                                          .filter(
                                              (pageNum) =>
                                                  pageNum <
                                                  this.filePageCount + 1
                                          )
                                          .join(",")}
                            </Label>
                            <PrimaryButton
                                onClick={async () => {
                                    const { pageStartIndex } = this.state;
                                    const lastAvailableIndex =
                                        this.filePageCount - this.VIEW_COUNT;
                                    if (
                                        pageStartIndex < lastAvailableIndex &&
                                        this.srcPdfDoc
                                    ) {
                                        const newPageStartIndex =
                                            pageStartIndex + 1;

                                        if (this.fileVizInfo) {
                                            if (
                                                newPageStartIndex <
                                                lastAvailableIndex
                                            ) {
                                                if (this.fileExt === "pdf") {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        newPageStartIndex
                                                    );
                                                } else if (
                                                    this.fileExt &&
                                                    OFFICE_EXT_ARR.includes(
                                                        this.fileExt
                                                    )
                                                ) {
                                                    await this._prepareOfficePageData(
                                                        this.fileAccessUrl,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        newPageStartIndex
                                                    );
                                                }
                                            } else {
                                                if (this.fileExt === "pdf") {
                                                    await this._preparePdfPageData(
                                                        this.srcPdfDoc,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        lastAvailableIndex
                                                    );
                                                } else if (
                                                    this.fileExt &&
                                                    OFFICE_EXT_ARR.includes(
                                                        this.fileExt
                                                    )
                                                ) {
                                                    await this._prepareOfficePageData(
                                                        this.fileAccessUrl,
                                                        this.fileVizInfo,
                                                        this.filePageCount,
                                                        lastAvailableIndex
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }}
                                disabled={
                                    pageStartIndex + this.VIEW_COUNT >=
                                    this.filePageCount
                                }
                            >
                                <FontIcon iconName="DoubleChevronRight8" />
                            </PrimaryButton>
                        </Stack>
                    </>
                )}

                {this.fileVizInfo && (
                    <Stack horizontal className="fileMetaContainer">
                        {this.fileVizInfo.fileMeta.GroundTruth && (
                            <Stack className="fileMeta">
                                <Label className="tableTitle">
                                    File level ground truth
                                </Label>
                                <DetailsList
                                    key={"fileLvGT"}
                                    selectionMode={SelectionMode.none}
                                    columns={GROUND_TRUTH_COLUMNS}
                                    items={
                                        this.fileVizInfo.fileMeta.GroundTruth
                                    }
                                    onShouldVirtualize={() => {
                                        return false;
                                    }}
                                ></DetailsList>
                            </Stack>
                        )}

                        {this.fileVizInfo.fileMeta.Prediction && (
                            <Stack className="fileMeta">
                                <Label className="tableTitle">
                                    File level prediction
                                </Label>
                                <DetailsList
                                    key={"filelvPred"}
                                    selectionMode={SelectionMode.none}
                                    columns={PREDICTION_COLUMNS}
                                    items={this.fileVizInfo.fileMeta.Prediction}
                                    onShouldVirtualize={() => {
                                        return false;
                                    }}
                                ></DetailsList>
                            </Stack>
                        )}
                    </Stack>
                )}

                {probabilitiesArr && probabilitiesArr.length > 0 && (
                    <Stack horizontal className="pagePrContainer">
                        {probabilitiesArr.map((p, index) => {
                            if (p && p.Probabilty) {
                                const typePrProps = Object.entries(
                                    p.Probabilty.Type
                                );
                                const typePrMap = new Map<string, any[]>();
                                typePrProps.forEach(([key, val]) => {
                                    if (!typePrMap.has(key)) {
                                        typePrMap.set(key, [
                                            {
                                                Type: key,
                                                Pr: val,
                                            },
                                        ]);
                                    }
                                });

                                const SplitPrProps = Object.entries(
                                    p.Probabilty.Split
                                );
                                const splitPrMap = new Map<string, any[]>();
                                SplitPrProps.forEach(([key, val]) => {
                                    if (!splitPrMap.has(key)) {
                                        splitPrMap.set(key, [
                                            {
                                                Type: key,
                                                Pr: val,
                                            },
                                        ]);
                                    }
                                });
                                const typePrTitle = `Page ${p.Page} type probabilities`;
                                const splitTitle = `Page ${p.Page} split probabilities`;
                                return (
                                    <Stack
                                        horizontal
                                        key={`pagePrStack_${index}`}
                                        className="pagePr"
                                    >
                                        <Stack className="prTable">
                                            <Label className="tableTitle">
                                                {typePrTitle}
                                            </Label>
                                            <TableList
                                                key={`typePr_${index}`}
                                                columns={PROBABILITIES_COLUMNS}
                                                evalDataCount={1}
                                                evalData={typePrMap}
                                                downloadTableTitle={typePrTitle}
                                                hideHeader
                                                disableFreezeHeader
                                                disableVirtualize
                                            ></TableList>
                                        </Stack>

                                        <Stack className="prTable">
                                            <Label className="tableTitle">
                                                {splitTitle}
                                            </Label>
                                            <TableList
                                                key={`splitPr_${index}`}
                                                columns={PROBABILITIES_COLUMNS}
                                                evalDataCount={1}
                                                evalData={splitPrMap}
                                                downloadTableTitle={splitTitle}
                                                hideHeader
                                                disableFreezeHeader
                                                disableVirtualize
                                            ></TableList>
                                        </Stack>
                                    </Stack>
                                );
                            } else {
                                return <></>;
                            }
                        })}
                    </Stack>
                )}
            </Stack>
        );
    }

    private _loadPdfData(pdfVizInfo: FileVizInfo, pdfPageNum: number) {
        showPrepareRenderDataLoading();
        this.filePageCount =
            pdfVizInfo.fileMeta.Pages === null
                ? NaN
                : pdfVizInfo.fileMeta.Pages.length;

        const isDev = isDevEnvironment();
        const fileUrlToFetch = isDev
            ? "https://arxiv.org/pdf/quant-ph/0410100.pdf"
            : pdfVizInfo.fileUrl;
        vdiRequest(fileUrlToFetch)
            .then((resp) => {
                if (resp.ok) {
                    resp.blob()
                        .then((blob: Blob) => {
                            const respBlob = new Blob([blob], {
                                type: "application/pdf",
                            });
                            const reader = new FileReader();
                            reader.readAsDataURL(respBlob);
                            reader.onload = async () => {
                                if (reader.result) {
                                    this.srcPdfDoc = await PDFDocument.load(
                                        reader.result
                                    );
                                    if (this.srcPdfDoc) {
                                        if (Number.isNaN(this.filePageCount)) {
                                            this.filePageCount =
                                                this.srcPdfDoc.getPageCount();
                                        }

                                        await this._preparePdfPageData(
                                            this.srcPdfDoc,
                                            pdfVizInfo,
                                            this.filePageCount,
                                            pdfPageNum
                                        );
                                    }
                                }
                            };
                        })
                        .catch((err: any) => {
                            console.log(err);
                            hidePrepareRenderDataLoading();
                        });
                } else {
                    hidePrepareRenderDataLoading();
                }
            })
            .catch((err) => {
                console.log(err);
                hidePrepareRenderDataLoading();
            });
    }

    private _loadOfficeData(fileVizInfo: FileVizInfo, filePageNum: number) {
        showPrepareRenderDataLoading();
        this.filePageCount =
            fileVizInfo.fileMeta.Pages === null
                ? NaN
                : fileVizInfo.fileMeta.Pages.length;
        const isDev = isDevEnvironment();
        const fileUrlToFetch = isDev
            ? "https://vdi-mlops-beta.eastus.cloudapp.azure.com" +
              fileVizInfo.fileUrl
            : fileVizInfo.fileUrl;
        vdiRequest(fileUrlToFetch, { redirect: "follow" })
            .then((resp) => {
                if (resp.ok) {
                    if (resp.redirected) {
                        this.fileAccessUrl = resp.url;
                        if (this.fileAccessUrl) {
                            this._prepareOfficePageData(
                                resp.url,
                                fileVizInfo,
                                this.filePageCount,
                                filePageNum
                            );
                        }
                    }
                } else {
                    hidePrepareRenderDataLoading();
                }
            })
            .catch((err) => {
                console.log(err);
                hidePrepareRenderDataLoading();
            });
    }

    private _loadSizeOfFileViewArea() {
        switch (this.fileExt) {
            case "docx": {
                this.defIframeSize = { W: 550, H: 700 };
                break;
            }
            case "xlsx":
            case "pptx": {
                this.defIframeSize = { W: 900, H: 500 };
                break;
            }
        }
    }

    private async _preparePdfPageData(
        pdfDoc: PDFDocument | null,
        pdfVizInfo: FileVizInfo,
        pdfPageCount: number,
        pageStartIndex: number
    ): Promise<void> {
        try {
            showPrepareRenderDataLoading();
            let pdfB64Arr: string[] = [];
            let pageNumArr = Array.from(
                { length: this.VIEW_COUNT },
                (_, index) => {
                    return pageStartIndex + index;
                }
            ).filter((pageNum) => pageNum < pdfPageCount);

            if (pdfDoc) {
                const isDev = isDevEnvironment();
                if (isDev) {
                    const pdfActualPagesCount = pdfDoc.getPageCount();
                    if (pdfActualPagesCount > pdfPageCount) {
                        pageNumArr = pageNumArr.map((pageNum) => {
                            if (pageNum >= pdfActualPagesCount) {
                                const robustPageNum =
                                    pageNum % pdfActualPagesCount;
                                return robustPageNum;
                            } else {
                                return pageNum;
                            }
                        });
                    }
                }

                const pdfDocArrToDisplay = await Promise.all(
                    pageNumArr.map(() => PDFDocument.create())
                );

                const copiedPagesArr = await Promise.all(
                    pageNumArr.map((pageNum, index) =>
                        pdfDocArrToDisplay[index].copyPages(pdfDoc, [pageNum])
                    )
                );

                copiedPagesArr.forEach((pages, index) => {
                    pages.forEach((page) => {
                        pdfDocArrToDisplay[index].addPage(page);
                    });
                });

                pdfB64Arr = await Promise.all(
                    pdfDocArrToDisplay.map((pdf) =>
                        pdf.saveAsBase64({
                            dataUri: true,
                        })
                    )
                );
            }

            const pageProbabilitiesArr =
                pdfVizInfo.fileMeta.Pages === null
                    ? []
                    : pageNumArr.map((pageNum) => {
                          return pdfVizInfo.fileMeta.Pages!.find(
                              (page) => page.Page === pageNum + 1
                          );
                      });

            this.setState({
                pageStartIndex: pageStartIndex,
                fileSrcArrToDisplay: pdfB64Arr,
                probabilitiesArr: pageProbabilitiesArr,
            });
        } catch (err) {
            console.log(err);
        } finally {
            hidePrepareRenderDataLoading();
        }
    }

    private async _prepareOfficePageData(
        fileAccessUrl: string,
        fileVizInfo: FileVizInfo,
        filePageCount: number,
        pageStartIndex: number
    ): Promise<void> {
        try {
            showPrepareRenderDataLoading();
            const pageNumArr = Array.from(
                { length: this.VIEW_COUNT },
                (_, index) => {
                    return pageStartIndex + index;
                }
            ).filter((pageNum) => pageNum < filePageCount);

            const officeWebViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                fileAccessUrl
            )}&wdPrint=0&wdEmbedCode=0`;

            const urlArr =
                this.fileExt === "xlsx"
                    ? [officeWebViewerUrl]
                    : pageNumArr.map((pageNum) => {
                          return officeWebViewerUrl + `&wdStartOn=${pageNum}`;
                      });

            const pageProbabilitiesArr = fileVizInfo
                ? pageNumArr.map((pageNum) => {
                      return fileVizInfo.fileMeta.Pages === null
                          ? undefined
                          : fileVizInfo.fileMeta.Pages.find(
                                (page) => page.Page === pageNum + 1
                            );
                  })
                : [];

            this.setState({
                pageStartIndex: pageStartIndex,
                fileSrcArrToDisplay: urlArr,
                probabilitiesArr: pageProbabilitiesArr,
            });
        } catch (err) {
            console.log(err);
        } finally {
            hidePrepareRenderDataLoading();
        }
    }
}
