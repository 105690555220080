import React from "react";
import { store } from "../../store";
import { Unsubscribe } from "redux";
import { LoadingCoverIcon } from "./LoadingcoverIcon";

interface IState {
    isFullscreen: boolean;
}

export class Container extends React.Component<{}, IState> {
    private unsubscribe?: Unsubscribe;
    constructor(props: any) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount(): void {
        this.unsubscribe = store.subscribe(() => {
            const reducer = store.getState().fullscreenReducer;

            this.setState({
                isFullscreen: reducer.isFullscreen,
            });
        });
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render() {
        const { isFullscreen } = this.state;

        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: !isFullscreen ? "relative" : undefined,
                    overflow: "hidden",
                }}
            >
                <LoadingCoverIcon />
                {this.props.children}
            </div>
        );
    }
}
