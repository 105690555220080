export abstract class Dataset {
    abstract get name(): string;
    abstract get version(): string;
    abstract get fullName(): string;
    abstract get displayName(): string;
    abstract get displayFullName(): string;

    abstract get categories(): string[];
    abstract get language(): string;

    abstract fetchImageListByCategory(category?: string): Promise<string[]>;
    abstract fetchImageList(): Promise<string[]>;
    abstract getImageUrl(imageName: string): string;

    equals(other: Dataset): boolean {
        return this.name === other.name && this.version === other.version;
    }
}

export class DatasetSet extends Set<Dataset> {
    add(value: Dataset): this {
        let found = false;
        this.forEach((item) => {
            if (value.equals(item)) {
                found = true;
            }
        });

        if (!found) {
            super.add(value);
        }

        return this;
    }

    intersect(datasets: Dataset[], matchVersion: boolean): DatasetSet {
        let newSet = new DatasetSet();
        for (let dataset of this) {
            if (
                datasets.findIndex((d) =>
                    matchVersion ? d.equals(dataset) : d.name === dataset.name
                ) > -1
            ) {
                newSet.add(dataset);
            }
        }
        return newSet;
    }
}
