import { Dataset } from "./Dataset";

export abstract class RecordDetail {
    abstract get name(): string;

    abstract get dataset(): Dataset;

    abstract getRecord(): Record;
    abstract getRawProp<T>(name: string): T;

    abstract composeMetricUrl(name: string): string;

    abstract checkMetricsExistence(name: string): boolean;
    abstract fetchFileList(name: string): Promise<string[]>;
    abstract fetchRawMetrics(name: string): Promise<string>;
    abstract fetchMetrics<T>(name: string): Promise<T>;
    abstract fetchMetricsWithCamelCasing<T>(name: string): Promise<T>;
    abstract fetchMetricsWithRecordDetail<T>(
        name: string
    ): Promise<[RecordDetail, T]>;

    abstract fetchMetricsWithRecord<T>(name: string): Promise<[Record, T]>;
}

export abstract class Record {
    // properties
    // Note: properties should better return string,
    // otherwise it'd be difficult for TS compiler to perform
    // type check during dynamic property loading.
    abstract get id(): string;
    abstract get scenario(): string;
    abstract get modelInfo(): string;
    abstract get runtimeVersion(): string;
    abstract get buildSource(): string;
    abstract get dataset(): string;
    abstract get expRunId(): string;
    abstract get stepRunId(): string;
    abstract get testType(): string;
    abstract get dateTime(): string;
    abstract get createTime(): string;
    abstract get createTimestamp(): number;

    abstract get name(): string;

    abstract get favorite(): boolean;
    abstract set favorite(fav: boolean);

    abstract get tags(): string[];
    abstract setRecordTags(id: string, tags: string[], workspace: string): void;

    abstract setRecordError(
        language: string,
        dataset: string,
        category: string,
        imageId: string,
        textlineId: string,
        reference: string,
        description: string[],
        workspace: string
    ): void;
    // webpage navigation
    abstract onNavigateTo(): void;
    abstract onCompareWith(records: Record[]): void;

    // control rendering
    abstract asEvalInfoCard(): {
        name: string;
        value: string | string[];
        width: number;
    }[];

    abstract initDetails(): Promise<boolean>;
    abstract getDetails(): RecordDetail[];
    abstract getDatasets(): Dataset[];

    abstract setDetailsFilter(
        predicate: (recordDetail: RecordDetail) => boolean
    ): void;
}

export interface IDataItem<T> {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: T;
}

export function InitRecords(records: Record[]) {
    const initedRecordPromises = records.map(async (record) => {
        await record.initDetails();
        return record;
    });

    return Promise.all(initedRecordPromises);
}
