import React from "react";
import { ActionButton, IIconProps, Stack } from "@fluentui/react";
import { from } from "linq-to-typescript";
import { getRandomColor } from "../../Utils";
import {
    FilterConfigMaps,
    FilterConfigType,
    PARALLEL_SEPARATOR,
    VerticalFilterBoard,
} from "./VerticalFilterBoard";
import { ColumnValueType, TableColumn, TableList } from "../../Controls";
import {
    IMergedMetrics,
    IMetrics,
    IMetricUnit,
    Record,
    RecordDetail,
    RecordFactory,
} from "../../DataContract";
import {
    ILineChartData,
    ILineChartDataPoint,
    LineChartWithXScaleBand,
} from "../../Controls/D3/LineChartWithXScaleBand";
import {
    VerticalFieldMetrics,
    VerticalMetrics,
    VERTICAL_COLUMNS,
} from "../../Pages/Scenarios/VerticalBasePage";

const VERTICAL_ALGORITHM_FIELD_NAME = "algorithm";
const VERTICAL_ExpInfo_FIELD_NAME = "expInfo";

// prettier-ignore
const VERTICAL_TREND_COLUMNS: Array<TableColumn> = [
    { key: "stepRunId", name: "StepRunId", fieldName: "stepRunId", minWidth: 200, maxWidth: 250, isResizable: true, valueType: ColumnValueType.String },
    { key: "buildSource", name: "BuildSource", fieldName: "buildSource", minWidth: 80, maxWidth: 120, isResizable: true, valueType: ColumnValueType.String, filterable: true },
    { key: "modelInfo", name: "ModelInfo", fieldName: "modelInfo", minWidth: 80, maxWidth: 120, isResizable: true, valueType: ColumnValueType.String, filterable: true },
    { key: "algorithm", name: "Algorithm", fieldName: "algorithm", minWidth: 100, maxWidth: 120, isResizable: true, valueType: ColumnValueType.String, filterable: true },
    { key: "expName", name: "Exp Name", fieldName: "expName", minWidth: 100, maxWidth: 150, isResizable: true, valueType: ColumnValueType.String, filterable: true },
    { key: "dataset", name: "Dataset", fieldName: "dataset", minWidth: 150, maxWidth: 350, isResizable: true, valueType: ColumnValueType.String, filterable: true },
    { key: "runtimeVersion", name: "RuntimeVersion", fieldName: "runtimeVersion", minWidth: 150, maxWidth: 350, isResizable: true, valueType: ColumnValueType.String, filterable: true },
];

const FILTER_ICON_PROPS: IIconProps = {
    iconName: "Filter",
    style: {
        fontSize: 18,
    },
};

const LOADMORE_ICON_PROPS: IIconProps = {
    iconName: "Refresh",
    style: {
        fontSize: 16,
    },
};

const PAGE_SIZE = 10;

interface ExpInfo {
    expRunId: string;
    name: string;
    stepRunId: string;
    url: string;
}

interface RecordExpInfo extends IMetrics<IMetricUnit> {
    expRunId: string;
    stepRunId: string;
    buildSource: string;
    modelInfo: string;
    algorithm: string;
    expName: string;
    dataset: string;
    runtimeVersion: string;
}

interface RichVerticalFieldMetrics extends VerticalFieldMetrics {
    StepRunId: string;
    Datetime: Date;
}

interface IProps {
    workspace: string;
    isDarkTheme?: boolean;
}

interface IState {
    isFilterBoardOpen: boolean;
    filterConfigMaps: FilterConfigMaps;
    records: Array<Record>;
    richVerticalFieldMetrics: Array<RichVerticalFieldMetrics>;
}

export class VerticalTrend extends React.Component<IProps, IState> {
    private readonly filterableExpInfoColumns: Array<TableColumn>;
    private readonly filterableMetricColumns: Array<TableColumn>;

    private queryCount: number = PAGE_SIZE;
    private expDateMap: Map<string, Date> = new Map<string, Date>();
    constructor(props: IProps) {
        super(props);

        this.filterableExpInfoColumns = VERTICAL_TREND_COLUMNS.filter(
            (col) => col.filterable
        );

        this.filterableMetricColumns = VERTICAL_COLUMNS.filter(
            (col) => col.filterable
        );

        this.state = {
            isFilterBoardOpen: false,
            filterConfigMaps: {
                DropdownFilterConfigMap: new Map<string | number, string[]>(),
                CheckboxFilterConfigMap: new Map<string | number, string[]>(),
            },
            records: [],
            richVerticalFieldMetrics: [],
        };

        this._updateFilterConfig = this._updateFilterConfig.bind(this);
    }

    public render() {
        const {
            filterConfigMaps,
            isFilterBoardOpen,
            records,
            richVerticalFieldMetrics,
        } = this.state;

        const recordExpInfoArr = this._prepareExpData(records);

        let displayRecordExpInfoArr = recordExpInfoArr;
        if (filterConfigMaps.DropdownFilterConfigMap.size > 0) {
            const filterConfigArr = Array.from(
                filterConfigMaps.DropdownFilterConfigMap
            );

            displayRecordExpInfoArr = recordExpInfoArr.filter((expRecord) => {
                return filterConfigArr.every(([fieldName, selectedKeys]) => {
                    const val = expRecord[fieldName as keyof RecordExpInfo] as
                        | string
                        | number;

                    return selectedKeys.some((selectedKey) => {
                        if (
                            typeof selectedKey === "string" &&
                            typeof val === "string"
                        ) {
                            return val
                                .split(PARALLEL_SEPARATOR)
                                .some((v) => v === selectedKey);
                        } else if (
                            typeof selectedKey === "number" &&
                            typeof val === "number"
                        ) {
                            return val === selectedKey;
                        } else {
                            return false;
                        }
                    });
                });
            });
        }

        let tableEvalData: IMergedMetrics<RecordExpInfo> = {};
        const displayStepRunIdArr = displayRecordExpInfoArr.flatMap(
            (recordExpInfo) => {
                tableEvalData[recordExpInfo.stepRunId] = [recordExpInfo];
                return recordExpInfo.stepRunId.split(PARALLEL_SEPARATOR);
            }
        );

        let displayRichVerticalFieldMetrics = richVerticalFieldMetrics.filter(
            (metric) => displayStepRunIdArr.includes(metric.StepRunId)
        );

        if (filterConfigMaps.CheckboxFilterConfigMap.size > 0) {
            const filterConfigArr = Array.from(
                filterConfigMaps.CheckboxFilterConfigMap
            );

            displayRichVerticalFieldMetrics =
                displayRichVerticalFieldMetrics.filter((metric) => {
                    return filterConfigArr.every(
                        ([fieldName, selectedKeys]) => {
                            const val = metric[
                                fieldName as keyof RichVerticalFieldMetrics
                            ] as string | number;

                            return selectedKeys.includes(val);
                        }
                    );
                });
        }

        return (
            <div style={{ height: "100%", overflow: "auto" }}>
                <Stack
                    style={{
                        display: "flex",
                        position: "absolute",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        right: 10,
                        top: 20,
                    }}
                >
                    <ActionButton
                        iconProps={FILTER_ICON_PROPS}
                        onClick={() => {
                            this.setState({
                                isFilterBoardOpen: true,
                            });
                        }}
                    >
                        Filter
                    </ActionButton>
                    <ActionButton
                        iconProps={LOADMORE_ICON_PROPS}
                        onClick={() => {
                            this.queryCount += PAGE_SIZE;
                            this._loadData();
                        }}
                    >
                        Load more
                    </ActionButton>
                </Stack>
                <TableList<RecordExpInfo>
                    columns={VERTICAL_TREND_COLUMNS}
                    evalDataCount={1}
                    evalData={tableEvalData}
                    downloadTableTitle="Trend"
                    freezeHeight={300}
                    hideHeader={true}
                    verticalFill={false}
                    isDarkTheme={this.props.isDarkTheme}
                ></TableList>
                {["Accuracy", "F1", "Precision", "Recall"].map(
                    (fieldName, index) => {
                        const lineChartDict = new Map<
                            string,
                            [ILineChartDataPoint[], string]
                        >();

                        displayRichVerticalFieldMetrics.forEach((metric) => {
                            const legend = `${metric.FieldName}_${metric.CompareType}_${metric.MicroOrMacro}`;
                            if (lineChartDict.has(legend)) {
                                const chartData = lineChartDict.get(legend);
                                const pointArr =
                                    chartData![0] as ILineChartDataPoint[];
                                pointArr.push({
                                    x: metric.Datetime,
                                    y: metric[
                                        fieldName as keyof RichVerticalFieldMetrics
                                    ] as number,
                                    name: metric.StepRunId,
                                });

                                lineChartDict.set(legend, chartData!);
                            } else {
                                lineChartDict.set(legend, [
                                    [
                                        {
                                            x: metric.Datetime,
                                            y: metric[
                                                fieldName as keyof RichVerticalFieldMetrics
                                            ] as number,
                                            name: metric.StepRunId,
                                        },
                                    ],
                                    getRandomColor(),
                                ]);
                            }
                        });

                        if (lineChartDict.size > 0) {
                            const chartDataArr = Array.from(lineChartDict).map(
                                ([legend, [data, color]]) => {
                                    return {
                                        legend: legend,
                                        data: from(data)
                                            .orderBy((d) => {
                                                return d.x
                                                    ? (d.x as Date).getTime()
                                                    : 0;
                                            })
                                            .toArray(),
                                        color: color,
                                    } as ILineChartData;
                                }
                            );

                            const lineChartData = {
                                chartTitle: fieldName + " by Field",
                                lineChartData: chartDataArr,
                            };

                            return (
                                <LineChartWithXScaleBand
                                    key={"verticalLine_" + index.toString()}
                                    keyIndex={index}
                                    id={"vLineChart" + index.toString()}
                                    data={lineChartData}
                                    height={500}
                                    yMin={0}
                                    yMax={1}
                                    ySpecifier={"%"}
                                    isDarkTheme={this.props.isDarkTheme}
                                ></LineChartWithXScaleBand>
                            );
                        } else {
                            return <></>;
                        }
                    }
                )}
                <VerticalFilterBoard
                    isOpen={isFilterBoardOpen}
                    filterableColumnsForDropdown={this.filterableExpInfoColumns}
                    filterableColumnsForCheckbox={this.filterableMetricColumns}
                    dropdownDataItems={recordExpInfoArr}
                    checkboxDataItems={richVerticalFieldMetrics}
                    updateDisplayState={(isOpen: boolean) => {
                        this.setState({ isFilterBoardOpen: isOpen });
                    }}
                    updateFilterConfig={this._updateFilterConfig}
                    filteredConfigMaps={filterConfigMaps}
                ></VerticalFilterBoard>
            </div>
        );
    }

    public componentDidMount() {
        this._loadData();
    }

    private _updateFilterConfig(
        filterConfigType: FilterConfigType,
        filterConfigMap: Map<string | number, Array<string | number>>
    ): void {
        const { filterConfigMaps } = this.state;
        switch (filterConfigType) {
            case FilterConfigType.Checkbox: {
                filterConfigMaps.CheckboxFilterConfigMap = filterConfigMap;
                this.setState({ filterConfigMaps: filterConfigMaps });
                break;
            }
            case FilterConfigType.Dropdown: {
                filterConfigMaps.DropdownFilterConfigMap = filterConfigMap;
                this.setState({ filterConfigMaps: filterConfigMaps });
                break;
            }
        }
    }

    //#region Help function
    private async _loadData(): Promise<void> {
        this.expDateMap.clear();
        const pageIndex = 1;
        const recordsPromises = await RecordFactory.fetchAll(
            this.props.workspace,
            pageIndex,
            undefined,
            this.queryCount
        ).then((records) => {
            return records.map(async (record) => {
                await record.initDetails();
                return record;
            });
        });

        Promise.all(recordsPromises)
            .then((records) => {
                this.setState({ records: records });
                this._queryMetricsResult(records, "evaluation_result.json");
            })
            .catch((_error) => {
                console.error(_error);
                this.setState({
                    records: [],
                    richVerticalFieldMetrics: [],
                });
            });
    }

    private _prepareExpData(records: Array<Record>): RecordExpInfo[] {
        if (records && records.length > 0) {
            const recordExpInfoArr = records.map((record) => {
                let recordExpInfo: RecordExpInfo = {
                    expRunId: "",
                    stepRunId: "",
                    buildSource: record.buildSource,
                    modelInfo: record.modelInfo,
                    algorithm: "",
                    expName: "",
                    dataset: "",
                    runtimeVersion: record.runtimeVersion,
                };

                let algorithmArr: Array<string> = [],
                    displayFullNameArr: Array<string> = [],
                    expNameArr: Array<string> = [],
                    expRunIdArr: Array<string> = [],
                    stepRunIdArr: Array<string> = [];

                record
                    .getDetails()
                    .filter((d) => d)
                    .forEach((detail) => {
                        const algorithm = detail.getRawProp<string>(
                            VERTICAL_ALGORITHM_FIELD_NAME
                        );
                        if (algorithm && !algorithmArr.includes(algorithm)) {
                            algorithmArr.push(algorithm);
                        }

                        const expInfo = detail.getRawProp<ExpInfo>(
                            VERTICAL_ExpInfo_FIELD_NAME
                        );

                        if (expInfo) {
                            if (
                                expInfo.name &&
                                !expNameArr.includes(expInfo.name)
                            ) {
                                expNameArr.push(expInfo.name);
                            }

                            if (
                                expInfo.expRunId &&
                                !expRunIdArr.includes(expInfo.expRunId)
                            ) {
                                expRunIdArr.push(expInfo.expRunId);
                            }

                            if (
                                expInfo.stepRunId &&
                                !stepRunIdArr.includes(expInfo.stepRunId)
                            ) {
                                stepRunIdArr.push(expInfo.stepRunId);
                            }
                        }
                    });

                record
                    .getDatasets()
                    .filter((d) => d)
                    .forEach((dataset) => {
                        if (
                            dataset.displayFullName &&
                            !displayFullNameArr.includes(
                                dataset.displayFullName
                            )
                        ) {
                            displayFullNameArr.push(dataset.displayFullName);
                        }
                    });

                algorithmArr.length > 0 &&
                    (recordExpInfo.algorithm =
                        algorithmArr.join(PARALLEL_SEPARATOR));

                displayFullNameArr.length > 0 &&
                    (recordExpInfo.dataset =
                        displayFullNameArr.join(PARALLEL_SEPARATOR));

                expNameArr.length > 0 &&
                    (recordExpInfo.expName =
                        expNameArr.join(PARALLEL_SEPARATOR));

                expRunIdArr.length > 0 &&
                    (recordExpInfo.expRunId =
                        expRunIdArr.join(PARALLEL_SEPARATOR));

                stepRunIdArr.length > 0 &&
                    (recordExpInfo.stepRunId =
                        stepRunIdArr.join(PARALLEL_SEPARATOR));

                return recordExpInfo;
            });

            return recordExpInfoArr;
        } else {
            return [];
        }
    }

    private _queryMetricsResult(
        records: Record[],
        metricsFileName: string
    ): void {
        const detailsList = records.map((record) => {
            const recordDetailArr = record
                .getDetails()
                .filter((d) => d) as RecordDetail[];

            recordDetailArr.forEach((detail) => {
                const expInfo = detail.getRawProp<ExpInfo>(
                    VERTICAL_ExpInfo_FIELD_NAME
                );
                if (!this.expDateMap.has(expInfo.expRunId)) {
                    this.expDateMap.set(
                        expInfo.expRunId,
                        new Date(record.dateTime)
                    );
                }
            });

            return recordDetailArr;
        });

        Promise.all(
            detailsList.flatMap((details, index) => {
                return details.map((detail) =>
                    detail
                        .fetchMetricsWithRecordDetail<VerticalMetrics>(
                            metricsFileName
                        )
                        .then((metrics) => {
                            return {
                                recordIndex: index,
                                recordDetail: metrics[0],
                                metrics: metrics[1],
                            };
                        })
                        .catch((_error) => {
                            console.error(_error);
                            return undefined;
                        })
                );
            })
        )
            .then((dataItems) => {
                let { filterConfigMaps } = this.state;
                let richVerticalFieldMetrics = dataItems
                    .filter((item) => item)
                    .flatMap((item) => {
                        const expInfo = item!.recordDetail.getRawProp<ExpInfo>(
                            VERTICAL_ExpInfo_FIELD_NAME
                        );
                        const dateTime = this.expDateMap.get(expInfo.expRunId);

                        return item &&
                            item.metrics &&
                            item.metrics.fieldMetrics &&
                            item.metrics.fieldMetrics.length > 0
                            ? item!.metrics.fieldMetrics.map((metric) => {
                                  const richFieldMetrics: RichVerticalFieldMetrics =
                                      {
                                          ...metric,
                                          StepRunId: expInfo.stepRunId,
                                          Datetime: dateTime!,
                                      };
                                  return richFieldMetrics;
                              })
                            : [];
                    });

                richVerticalFieldMetrics = from(richVerticalFieldMetrics)
                    .orderBy((r) => r.Datetime)
                    .toArray();

                if (
                    richVerticalFieldMetrics &&
                    richVerticalFieldMetrics.length > 0
                ) {
                    const filterableFieldNames =
                        this.filterableMetricColumns.map(
                            (col) => col.fieldName
                        );

                    const defaultCheckedMetric = richVerticalFieldMetrics.find(
                        (metric) =>
                            filterableFieldNames.every(
                                (fieldName) =>
                                    metric[
                                        fieldName as keyof RichVerticalFieldMetrics
                                    ]
                            )
                    );

                    if (defaultCheckedMetric) {
                        this.filterableMetricColumns.forEach((col) => {
                            const fieldVal = defaultCheckedMetric![
                                col.fieldName! as keyof RichVerticalFieldMetrics
                            ] as string | number;

                            filterConfigMaps.CheckboxFilterConfigMap.set(
                                col.fieldName!,
                                [fieldVal]
                            );
                        });
                    }
                }

                this.setState({
                    filterConfigMaps: filterConfigMaps,
                    richVerticalFieldMetrics: richVerticalFieldMetrics,
                });
            })
            .catch((_error) => {
                console.error(_error);
                this.setState({
                    richVerticalFieldMetrics: [],
                });
            });
    }
    //#endregion
}
