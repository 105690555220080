import { Workspaces } from "../../DataContract";

export const MetricAnalysisDefaultColumns: {
    [key: string]: string[];
} = {
    [Workspaces.Ocr]: [
        "_ocrMS",
        "_ocrNativePerfNativeMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.NormalizerMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.AnalyzerMetrics.TotalMs",
        "'_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs",
    ],
    [Workspaces.ReleaseTest]: [
        "_ocrMS",
        "_ocrNativePerfNativeMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.DetectorMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.NormalizerMetrics.TotalMs",
        "_ocrNativePerfNativeMetrics.AnalyzerMetrics.TotalMs",
        "'_ocrNativePerfNativeMetrics.RecognizerMetrics.WallTimeMs",
    ],
    [Workspaces.OcrMath]: [
        "_ocrMS",
        "_totalMS",
        "_ocrNativePerf.NativeMetrics.MathformulaMetrics.NormalizerMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathformulaMetrics.DetectorMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathformulaMetrics.ParagraphMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathformulaMetrics.RecognizerMetrics.AccumulatedAttentionMs",
        "_ocrNativePerf.NativeMetrics.MathformulaMetrics.RecognizerMetrics.WallTimeMs",
        "_ocrNativePerf.NativeMetrics.MathFm.TotalMs",
        " _ocrNativePerf.NativeMetrics.MathFm.NormalizerMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathFm.DetectorMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathFm.ParagraphMetrics.TotalMs",
        "_ocrNativePerf.NativeMetrics.MathFm.RecognizerMetrics.AccumulatedTotalMs",
        "_ocrNativePerf.NativeMetrics.MathFm.RecognizerMetrics.WallTimeMs",
    ],
    [Workspaces.OcrTable]: [
        "_tableNativePerf.TableNativeMetrics.TotalMs",
        "_tableNativePerf.TableNativeMetrics.DetectorMs.TotalMs",
        "_tableNativePerf.TableNativeMetrics.RecognizerMs.TotalMs",
    ],
    [Workspaces.OcrPod]: [
        "_podNativePerf.PODNativeMetrics.TotalMs",
        "_podNativePerf.PODNativeMetrics.PodBackboneMetrics.TotalMs",
        "_podNativePerf.PODNativeMetrics.PodRegionGroupingMetrics.TotalMs",
        "_podNativePerf.PODNativeMetrics.PodReadingOrderMetrics.TotalMs",
        "_podNativePerf.PODNativeMetrics.FormMetrics.TotalMs",
    ],
    [Workspaces.OcrFigure]: [
        "_figureNativePerf.FigureNativeMetrics.TotalMs",
        "_figureNativePerf.FigureNativeMetrics.DetectorMs.TotalMs",
        "_figureNativePerf.FigureNativeMetrics.DetectorMs.PostProcessMs",
        "_figureNativePerf.FigureNativeMetrics.DetectorMs.ModelMs",
        "_figureNativePerf.FigureNativeMetrics.DetectorMs.PreprocessMs",
    ],
    [Workspaces.Kvp]: ["_totalMS", "_kvpNativePerf.KVPNativeMetrics.TotalMs"],
};
