import { AnyAction } from "redux";
import { IChoiceGroupOption, IColumn } from "@fluentui/react";
import _ from "lodash";
import { getFromLocalStorage } from "../../components/Utils";

const initialState = {
    columns: [],
    selectedItems: [],
    matchDatasetVersion: true,
    isReport: false,
    vertical: false,
};

export interface SettingState {
    columns: IColumn[];
    selectedItems: string[];
    matchDatasetVersion: boolean;
    isReport: boolean;
    vertical: boolean;
    saveKey?: string;
    levels?: IChoiceGroupOption[];
    selectedLevel?: string;
}

export class ActionType {
    public static readonly UpdateSettingSelectedItems =
        "UPDATE_SETTING_SELECTEDITEMS";
    public static readonly UpdateSettingState = "UPDATE_SETTING_STATE";
    public static readonly UpdateSettingMatchDataset =
        "UPDATE_SETTING_MATCHDATASET";
    public static readonly UpdateSettingLevel = "UPDATE_SETTING_LEVEL";
}

export const settingReducer = (
    state: SettingState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case ActionType.UpdateSettingSelectedItems:
            return { ...state, selectedItems: action.value };
        case ActionType.UpdateSettingState:
            return updateSelectedItems(state, action.value);
        case ActionType.UpdateSettingMatchDataset:
            return { ...state, matchDatasetVersion: action.value };
        case ActionType.UpdateSettingLevel:
            return { ...state, selectedLevel: action.value };
        default:
            return state;
    }
};

const updateSelectedItems = (state: SettingState, newState: SettingState) => {
    if (
        (newState.saveKey && !_.isEqual(state.saveKey, newState.saveKey)) ||
        (newState.columns && !_.isEqual(state.columns, newState.columns)) ||
        (newState.selectedItems &&
            !_.isEqual(state.selectedItems, newState.selectedItems))
    ) {
        const columns = newState.columns ?? state.columns;
        const saveKey = newState.saveKey ?? state.saveKey;
        const selectedItems =
            getFromLocalStorage(saveKey ?? "")?.split(",") ??
            newState.selectedItems ??
            columns.map((val) => val.key);
        newState.selectedItems = selectedItems;
    }
    return { ...state, ...newState };
};

export const updateStateAction = (state: any) => {
    return {
        type: ActionType.UpdateSettingState,
        value: state,
    };
};

export const updateStateLevel = (state: any) => {
    return {
        type: ActionType.UpdateSettingLevel,
        value: state,
    };
};

export const updateselectedItemsAction = (selectedItems: any) => {
    return {
        type: ActionType.UpdateSettingSelectedItems,
        value: selectedItems,
    };
};

export const updateMathDatasetAction = (matchDatasetVersion: any) => {
    return {
        type: ActionType.UpdateSettingMatchDataset,
        value: matchDatasetVersion,
    };
};
