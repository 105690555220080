import "../Common/MetricStyle.scss";
import React from "react";

import {
    //Group&DetailList
    DetailsList,
    IColumn,
    IDetailsGroupRenderProps,
    SelectionMode,

    //Others
    FontIcon,
    Link,
    mergeStyleSets,
} from "@fluentui/react";

import { Record, RecordDetail } from "../../DataContract";
import { Consumer } from "../../Layout";
import { FullScreen } from "../Common/FullScreen";
import AuthLink from "../../AuthComponent/AuthLink";

interface PerfResult {
    result: {
        [key: string]: { [key: string]: number };
    };
    reports: string[];
}

interface IState {
    data?: [RecordDetail, PerfResult];
    reports?: string[];
}

interface IProps {
    records: Record[];
}

const classNames = mergeStyleSets({
    headerAndFooter: {
        padding: 8,
        margin: "8px,0",
        // Overlay the sizer bars
        position: "relative",
        zIndex: 100,
    },
    headerTitle: [
        {
            padding: "4px 0",
        },
    ],
    headerLink: {
        margin: "0 8px",
    },
    iconClass: {
        fontSize: 10,
        height: 10,
        width: 10,
        margin: "0 25px",
    },
});

const COLUMNS: IColumn[] = [
    {
        key: "name",
        name: "name",
        fieldName: "name",
        minWidth: 500,
        maxWidth: 650,
        isResizable: true,
    },
    {
        key: "value",
        name: "Value",
        fieldName: "value",
        minWidth: 500,
        maxWidth: 650,
        isResizable: true,
    },
];

export class PerfDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }
    public render() {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "auto" }}>
                    {this._onRenderTableDom()}
                </div>
            </FullScreen>
        );
    }

    public componentDidMount() {
        this._queryMetricsResult();
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.records !== prevProps.records) {
            this._queryMetricsResult();
        }
    }

    public _queryMetricsResult() {
        const { records } = this.props;
        if (records && records.length > 0) {
            const details = records[0].getDetails();
            if (details && details.length > 0) {
                details[0]
                    .fetchMetricsWithRecordDetail<PerfResult>("perf")
                    .then((data) => {
                        this.setState({
                            data: data,
                            reports: [],
                        });
                    });

                return;
            }
        }
    }

    private _onRenderTableDom() {
        const { data } = this.state;

        if (data) {
            const [recordDetail, metrics] = data;
            const items = Object.values(metrics.result).flatMap((groupData) => {
                return Object.entries(groupData).map(([name, value]) => {
                    return {
                        name: name,
                        value: value,
                    };
                });
            });

            const reports = metrics.reports;

            let curIdx = 0;
            const groups = Object.entries(metrics.result).map(
                ([name, groupData], groupIdx) => {
                    const count = Object.keys(groupData).length;
                    const report = reports.find((report) =>
                        report.includes(name)
                    );
                    const url = recordDetail.composeMetricUrl(report!);
                    const group = {
                        count: count,
                        key: `${name}_${groupIdx}`,
                        name: name,
                        startIndex: curIdx,
                        level: 0,
                        data: url,
                    };
                    curIdx += count;
                    return group;
                }
            );

            return (
                <DetailsList
                    columns={COLUMNS}
                    items={items}
                    groups={groups}
                    groupProps={{
                        onRenderHeader: this._onRenderGroupHeader,
                    }}
                    selectionMode={SelectionMode.none}
                />
            );
        }
    }

    private _onRenderGroupHeader: IDetailsGroupRenderProps["onRenderHeader"] = (
        props
    ) => {
        if (props) {
            return (
                <div className={classNames.headerAndFooter}>
                    <div className={classNames.headerTitle}>
                        {props.group!.name}
                        <AuthLink
                            style={{ marginLeft: "10px", color: "#75b6e7" }}
                            url={props.group!.data}
                            download
                        >
                            DownLoad
                        </AuthLink>
                        <Consumer>
                            {(value) => {
                                return (
                                    <Link
                                        className={classNames.headerLink}
                                        onClick={() =>
                                            props!.onToggleCollapse!(
                                                props!.group!
                                            )
                                        }
                                        styles={{
                                            root: {
                                                color: "#75b6e7",
                                            },
                                        }}
                                    >
                                        {props.group!.isCollapsed ? (
                                            <FontIcon
                                                iconName="DoubleChevronUp"
                                                className={classNames.iconClass}
                                                style={{
                                                    color: value
                                                        ? "white"
                                                        : "black",
                                                }}
                                            />
                                        ) : (
                                            <FontIcon
                                                iconName="DoubleChevronDown"
                                                className={classNames.iconClass}
                                                style={{
                                                    color: value
                                                        ? "white"
                                                        : "black",
                                                }}
                                            />
                                        )}
                                    </Link>
                                );
                            }}
                        </Consumer>
                    </div>
                </div>
            );
        }
        return null;
    };
}
