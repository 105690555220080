import React from "react";
import { Dropdown, IDropdownOption, Label, Panel } from "@fluentui/react";
import { CheckboxList, ICheckableOption } from "../../Controls/CheckboxList";
import { TableColumn } from "../../Controls";

export enum FilterConfigType {
    Checkbox,
    Dropdown,
}

export const PARALLEL_SEPARATOR = " ; ";

export interface FilterConfigMaps {
    DropdownFilterConfigMap: Map<string | number, Array<string | number>>;
    CheckboxFilterConfigMap: Map<string | number, Array<string | number>>;
}

interface IProps<T_OfDropdownDataSource, T_OfCheckboxDataSource> {
    isOpen: boolean;
    filterableColumnsForDropdown: Array<TableColumn>;
    filterableColumnsForCheckbox: Array<TableColumn>;
    filteredConfigMaps: FilterConfigMaps;
    dropdownDataItems: Array<T_OfDropdownDataSource>;
    checkboxDataItems: Array<T_OfCheckboxDataSource>;
    updateDisplayState: (isOpen: boolean) => void;
    updateFilterConfig: (
        filterConfigType: FilterConfigType,
        filterConfigMap: Map<string | number, Array<string | number>>
    ) => void;
}

export class VerticalFilterBoard<
    T_OfDropdownDataSource,
    T_OfCheckboxDataSource
> extends React.Component<
    IProps<T_OfDropdownDataSource, T_OfCheckboxDataSource>
> {
    constructor(props: IProps<T_OfDropdownDataSource, T_OfCheckboxDataSource>) {
        super(props);
        this._checkboxListOptionsOnChange =
            this._checkboxListOptionsOnChange.bind(this);
    }

    render() {
        const {
            isOpen,
            filterableColumnsForDropdown,
            filterableColumnsForCheckbox,
            dropdownDataItems,
            checkboxDataItems,
            updateDisplayState,
        } = this.props;

        const filterExpInfoMap = new Map<TableColumn, string[]>();

        if (
            filterableColumnsForDropdown &&
            filterableColumnsForDropdown.length > 0 &&
            dropdownDataItems &&
            dropdownDataItems.length > 0
        ) {
            filterableColumnsForDropdown.forEach((col) => {
                const fieldValues = dropdownDataItems.map(
                    (item) =>
                        item[
                            col.fieldName as keyof T_OfDropdownDataSource
                        ] as unknown as string
                );

                const expandedValues = fieldValues.flatMap((fieldValue) =>
                    fieldValue.split(PARALLEL_SEPARATOR)
                );

                filterExpInfoMap.set(
                    col,
                    Array.from(new Set<string>(expandedValues))
                );
            });
        }

        const filterMetricMap = new Map<TableColumn, string[]>();
        if (
            filterableColumnsForCheckbox &&
            filterableColumnsForCheckbox.length > 0 &&
            checkboxDataItems &&
            checkboxDataItems.length > 0
        ) {
            filterableColumnsForCheckbox.forEach((col) => {
                const fieldValues = checkboxDataItems.map(
                    (item) =>
                        item[
                            col.fieldName as keyof T_OfCheckboxDataSource
                        ] as unknown as string
                );

                filterMetricMap.set(
                    col,
                    Array.from(new Set<string>(fieldValues))
                );
            });
        }

        return (
            <Panel
                isLightDismiss
                isOpen={isOpen}
                onDismiss={() => {
                    updateDisplayState(false);
                }}
                closeButtonAriaLabel="Close"
                headerText={"Filter"}
            >
                {this._renderDropdownFilterArea(filterExpInfoMap)}
                {this._renderCheckboxFilterArea(filterMetricMap)}
            </Panel>
        );
    }

    private _renderDropdownFilterArea(
        filterDataMap: Map<TableColumn, string[]>
    ): JSX.Element {
        const { filteredConfigMaps } = this.props;

        return (
            <>
                {filterDataMap &&
                    filterDataMap.size > 0 &&
                    Array.from(filterDataMap).map(([key, values]) => {
                        const selectedKeys =
                            filteredConfigMaps.DropdownFilterConfigMap.get(
                                key.fieldName!
                            );

                        const options = values.map((val) => {
                            return {
                                key: val,
                                text: val,
                                selected:
                                    selectedKeys && selectedKeys.includes(val),
                            } as IDropdownOption;
                        });

                        return (
                            <>
                                <Label>{key.name}</Label>
                                <Dropdown
                                    key={key.fieldName!}
                                    placeholder={"Select"}
                                    options={options}
                                    multiSelect={true}
                                    onChange={this._dropdownOptionsOnChange.bind(
                                        this,
                                        key.fieldName!
                                    )}
                                ></Dropdown>
                            </>
                        );
                    })}
            </>
        );
    }

    private _renderCheckboxFilterArea(
        filterDataMap: Map<TableColumn, string[]>
    ) {
        const { filteredConfigMaps } = this.props;

        return (
            <>
                {filterDataMap &&
                    filterDataMap.size > 0 &&
                    Array.from(filterDataMap).map(([key, values]) => {
                        const checkedKeys =
                            filteredConfigMaps.CheckboxFilterConfigMap.get(
                                key.fieldName!
                            );

                        const options = values.map((val) => {
                            return {
                                key: val,
                                text: val,
                                checked:
                                    checkedKeys && checkedKeys.includes(val),
                            } as ICheckableOption;
                        });

                        return (
                            <CheckboxList
                                key={key.fieldName!}
                                listKey={key.fieldName!}
                                title={key.name}
                                options={options}
                                optionsOnChange={
                                    this._checkboxListOptionsOnChange
                                }
                            ></CheckboxList>
                        );
                    })}
            </>
        );
    }

    private _dropdownOptionsOnChange(
        fileName: string,
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined
    ): void {
        const { filteredConfigMaps, updateFilterConfig } = this.props;
        const { DropdownFilterConfigMap } = filteredConfigMaps;

        if (option) {
            if (DropdownFilterConfigMap.has(fileName)) {
                let values = DropdownFilterConfigMap.get(fileName)!;
                if (!values.includes(option.key) && !!option.selected) {
                    values.push(option.key);
                    DropdownFilterConfigMap.set(fileName, values);
                } else if (values.includes(option.key) && !!!option.selected) {
                    values = values.filter((val) => val !== option.key);
                    if (values && values.length > 0) {
                        DropdownFilterConfigMap.set(fileName, values);
                    } else {
                        DropdownFilterConfigMap.delete(fileName);
                    }
                }
            } else {
                DropdownFilterConfigMap.set(fileName, [option.key]);
            }

            updateFilterConfig(
                FilterConfigType.Dropdown,
                DropdownFilterConfigMap
            );
        }
    }

    private _checkboxListOptionsOnChange(
        listKey: string,
        checkedOptionKeys: Array<string | number>
    ): void {
        const { filteredConfigMaps, updateFilterConfig } = this.props;
        const { CheckboxFilterConfigMap } = filteredConfigMaps;
        if (checkedOptionKeys && checkedOptionKeys.length > 0) {
            CheckboxFilterConfigMap.set(listKey, checkedOptionKeys);
        } else {
            CheckboxFilterConfigMap.delete(listKey);
        }

        updateFilterConfig(FilterConfigType.Checkbox, CheckboxFilterConfigMap);
    }
}
