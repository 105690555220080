import { HighlightWerMetrics } from "./Wer";
import { HighlightEerMetrics } from "./Eer";
import { HighlightLatencyMetrics } from "./Latency";
import { FAKE_HIGHLIGHT_METRICS, COVERAGE_SCRIPTS } from "./Config";
import { fetchValidDataByPath } from "../../../Utils";
import { markHighlightLoadingError } from "../../../Utils/LoadingUtil";

export enum HighlightKey {
    Language = "Language",
    WER = "WER",
    EER = "EER",
    Latency = "Latency",
}

export class Highlight {
    private _storageMap = new Map<string, string>();

    constructor(storageMap: Map<string, string>) {
        this._storageMap = storageMap;
    }

    getLanguageCoverageFile(language: string) {
        if (!this._storageMap.has(language)) {
            return null;
        }
        let compose_url = `/api/eval/blobs/${this._storageMap.get(language)}`;
        return `${compose_url}/char_coverage.json`;
    }

    async getMetricsByPath(file: any) {
        try {
            if (file) {
                let obj = await fetchValidDataByPath(file, 0);
                return obj ? JSON.parse(obj) : null;
            }
        } catch (error) {
            console.log(error);
            markHighlightLoadingError();
        }
        return null;
    }

    isValidCoverageCategory(category: string) {
        return !["overall", "special character overall"].includes(category);
    }

    isValidCoverageMetric(percentage: string) {
        return (
            percentage.endsWith("%") &&
            Number(percentage.substring(0, percentage.length - 1)) > 0
        );
    }

    getLanguageCoverageInfoWithMetric(metrics: any) {
        let count = 0;
        if (metrics && metrics["character_coverage"]) {
            for (let item of metrics["character_coverage"]) {
                if (item["category"] === "overall") {
                    let coverage = item["coverage"];
                    return Math.round(
                        (item["total"] *
                            Number(
                                coverage.substring(0, coverage.length - 1)
                            )) /
                            100
                    );
                }
            }
        }
        return count;
    }

    async getSingleScriptCoverageInfo(script: string) {
        let keys = ["Coverage", "Unofficial"];
        let res = [];
        for (let key of keys) {
            let lang = `${script}${key}`;
            let fC = this.getLanguageCoverageFile(lang);
            let metrics = await this.getMetricsByPath(fC);
            let count = metrics
                ? this.getLanguageCoverageInfoWithMetric(metrics)
                : null;
            res.push(count);
        }
        return {
            Coverage: res[0],
            Unofficial: res[1],
        };
    }

    async getLanguageCoverageInfo() {
        let coverageInfo: { [key: string]: {} } = {};
        await Promise.all(
            COVERAGE_SCRIPTS.flatMap((script) => {
                return this.getSingleScriptCoverageInfo(script);
            })
        ).then((result) => {
            COVERAGE_SCRIPTS.forEach((script, index) => {
                coverageInfo[script] = result[index];
            });
        });
        return coverageInfo;
    }

    async getFakeHighlightInfo() {
        return FAKE_HIGHLIGHT_METRICS;
    }

    async getHighlightInfoByTypeParallel(t: HighlightKey) {
        switch (t) {
            case HighlightKey.Language:
                return this.getLanguageCoverageInfo();
            case HighlightKey.WER:
                return new HighlightWerMetrics(
                    this._storageMap
                ).getWerInfoParallel();
            case HighlightKey.EER:
                return new HighlightEerMetrics(
                    this._storageMap
                ).getEerInfoParallel();
            case HighlightKey.Latency:
                return new HighlightLatencyMetrics(
                    this._storageMap
                ).getLatencyInfoParallel();
        }
    }

    async getHighligtInfo(key: HighlightKey) {
        const res = await this.getHighlightInfoByTypeParallel(key);

        return { [key]: res };
    }
}
