import { MetricDefinition } from "../../DataContract";

const DEFAULT_FIELD = ["f1", "precision", "recall"];
export const OVERIVEW_DEFAULT_FIELD = [
    "f1",
    "precision",
    "recall",
    "# gt",
    "# det",
    "# match",
];

export const DEFINITION_NAME = {
    region_grouping: "region_grouping",
    logical_role: "logical_role",
    reading_order: "reading_order",
    hdsa: "hdsa",
};

const REGION_GROUPING_METRIC_DEFINITION = (
    fields: string[] = DEFAULT_FIELD,
    defFields?: string[]
): MetricDefinition => {
    return {
        name: DEFINITION_NAME.region_grouping,
        displayName: "Region Grouping",
        tablefields: fields,
        defFields: defFields ?? fields,
        props: [
            {
                fieldName: "type_agnostic",
                displayName: "Type Agnostic",
            },
            {
                fieldName: "paragraph",
                displayName: "Paragraph",
            },
            {
                fieldName: "listItem",
                displayName: "List Item",
            },
            {
                fieldName: "keyValuePairGroup",
                displayName: "Key Value Pair Group",
            },
            {
                fieldName: "ChoiceItem",
                displayName: "Choice Item",
            },
        ],
    };
};

const LOGICAL_ROLE_METRIC_DEFINITION = (
    fields: string[] = DEFAULT_FIELD,
    defFields?: string[]
): MetricDefinition => {
    return {
        name: DEFINITION_NAME.logical_role,
        displayName: "Logical Role",
        tablefields: fields,
        defFields: defFields ?? fields,
        props: [
            {
                fieldName: "title",
                displayName: "Title",
            },
            {
                fieldName: "sectionHeading",
                displayName: "Section Heading",
            },
            {
                fieldName: "pageNumber",
                displayName: "Page Number",
            },
            {
                fieldName: "pageHeader",
                displayName: "Page Header",
            },
            {
                fieldName: "pageFooter",
                displayName: "Page Footer",
            },
            {
                fieldName: "footnote",
                displayName: "Foot Note",
            },
            {
                fieldName: "body/other",
                displayName: "Body/Other",
            },
            {
                fieldName: "tableFootnote",
                displayName: "Table Footnote",
            },
            {
                fieldName: "tableCaption",
                displayName: "Table Caption",
            },
            {
                fieldName: "figureFootnote",
                displayName: "Figure Footnote",
            },
            {
                fieldName: "figureCaption",
                displayName: "Figure Caption",
            },
            {
                fieldName: "title/heading",
                displayName: "Title & Header",
            },
            {
                fieldName: "tf_footnote",
                displayName: "Table/Figure Footnote",
            },
            {
                fieldName: "tf_caption",
                displayName: "Table/Figure Caption",
            },
            {
                fieldName: "figuretext",
                displayName: "Figure Text",
            },
        ],
    };
};

const READING_ORDER_METRIC_DEFINITION = (
    fields: string[] = DEFAULT_FIELD,
    defFields?: string[]
): MetricDefinition => {
    return {
        name: DEFINITION_NAME.reading_order,
        displayName: "Reading Order",
        tablefields: fields,
        defFields: defFields ?? fields,
        props: [
            {
                fieldName: "nextTextRegion",
                displayName: "Next Text Region",
            },
            {
                fieldName: "nextTextRegionAccuracy",
                displayName: "Next Text Region Accuracy",
            },
            {
                fieldName: "textRegionCrossCol",
                displayName: "Text Region Cross Col",
            },
            {
                fieldName: "caption",
                displayName: "Caption",
            },
            {
                fieldName: "footNote",
                displayName: "Foot Note",
            },
            {
                fieldName: "relationAgnosticOrder",
                displayName: "Relation Agnostic Order",
            },
        ],
    };
};

const HDSA_METRIC_DEFINITION = (
    fields: string[] = DEFAULT_FIELD,
    defFields?: string[]
): MetricDefinition => {
    return {
        name: "hdsa",
        displayName: "HDSA",
        tablefields: fields,
        defFields: defFields ?? fields,
        props: [
            {
                fieldName: "distance",
                displayName: "distance",
            },
            {
                fieldName: "gt_nodes",
                displayName: "gt nodes",
            },
            {
                fieldName: "pred_nodes",
                displayName: "pred nodes",
            },
            {
                fieldName: "teds",
                displayName: "teds",
            },
        ],
    };
};

const podField = [
    "textline_order_similarity_socre",
    "# textline_gt",
    "# textline_det",
    "similarity_score",
    "matched_group_similarity_score",
    "ignore_gt_figure_similarity_score",
    "ignore_gt_table_similarity_score",
    "ingore_gt_figure_table_similarity_score",
    ...OVERIVEW_DEFAULT_FIELD,
    "# figure_det",
    "# figure_gt",
    "# table_det",
    "# table_gt",
];

export const POD_IMAGE_METRICS_DEFINITIONS = [
    REGION_GROUPING_METRIC_DEFINITION(OVERIVEW_DEFAULT_FIELD),
    LOGICAL_ROLE_METRIC_DEFINITION(OVERIVEW_DEFAULT_FIELD),
    READING_ORDER_METRIC_DEFINITION(podField),
    HDSA_METRIC_DEFINITION(["distance", "gt_nodes", "pred_nodes", "teds"]),
];

export const READING_ORDER_METRIC_FEFAULT_FIELD = [
    "textline_order_similarity_socre",
    "# textline_gt",
    "# textline_det",
    "similarity_score",
    "# gt",
    "# det",
];

export const POD_OVERVIEW_METRICS_DEFINITIONS = [
    REGION_GROUPING_METRIC_DEFINITION(OVERIVEW_DEFAULT_FIELD),
    LOGICAL_ROLE_METRIC_DEFINITION(OVERIVEW_DEFAULT_FIELD),
    READING_ORDER_METRIC_DEFINITION(
        podField,
        READING_ORDER_METRIC_FEFAULT_FIELD
    ),
];
