import {
    SCRIPTS,
    NON_DOC_TYPE_SEGMENTS,
    CAL_SEGMENTS,
    GENERAL_SCRIPTS,
} from "./Config";

import { fetchValidDataByPath } from "../../../Utils";
import { markHighlightLoadingError } from "../../../Utils/LoadingUtil";

export class WerInfo {
    private _docErrorCount: number;
    private _docWordCount: number;
    private _nonDocErrorCount: number;
    private _nonDocWordCount: number;
    private _overallErrorCount: number;
    private _overallWordCount: number;

    constructor(
        docErrorCount: number,
        docWordCount: number,
        nonDocErrorCount: number,
        nonDocWordCount: number
    ) {
        this._docErrorCount = docErrorCount;
        this._docWordCount = docWordCount;
        this._nonDocErrorCount = nonDocErrorCount;
        this._nonDocWordCount = nonDocWordCount;
        this._overallErrorCount = docErrorCount + nonDocErrorCount;
        this._overallWordCount = docWordCount + nonDocWordCount;
    }

    dictInfo(isPrinted: boolean): any {
        let v = [
            this._docWordCount > 0
                ? Number(
                      (
                          (this._docErrorCount / this._docWordCount) *
                          100
                      ).toFixed(1)
                  )
                : null,
            this._nonDocErrorCount > 0
                ? Number(
                      (
                          (this._nonDocErrorCount / this._nonDocWordCount) *
                          100
                      ).toFixed(1)
                  )
                : null,
            this._overallWordCount > 0
                ? Number(
                      (
                          (this._overallErrorCount / this._overallWordCount) *
                          100
                      ).toFixed(1)
                  )
                : null,
        ];
        return isPrinted
            ? { Document: v[0], "Non-Document": v[1], Overall: v[2] }
            : { Form: v[0], Note: v[1], Overall: v[2] };
    }

    add(info: WerInfo) {
        this._docErrorCount += info._docErrorCount;
        this._docWordCount += info._docWordCount;
        this._nonDocErrorCount += info._nonDocErrorCount;
        this._nonDocWordCount += info._nonDocWordCount;
        this._overallErrorCount += info._overallErrorCount;
        this._overallWordCount += info._overallWordCount;
    }
}

export class HighlightWerMetrics {
    private _storageMap = new Map<string, string>();

    constructor(storageMap: Map<string, string>) {
        this._storageMap = storageMap;
    }

    isDocument(seg: string): boolean {
        return !(
            NON_DOC_TYPE_SEGMENTS.includes(seg) || CAL_SEGMENTS.includes(seg)
        );
    }

    isNonDocument(seg: string): boolean {
        return NON_DOC_TYPE_SEGMENTS.includes(seg);
    }

    getWerFile(language: string) {
        if (!this._storageMap.has(language)) {
            return null;
        }
        let compose_url = `/api/eval/blobs/${this._storageMap.get(language)}`;
        return `${compose_url}/basic_metrics.json`;
    }

    async getMetricsByPath(file: any) {
        try {
            if (file) {
                let obj = await fetchValidDataByPath(file, 0);
                return obj ? JSON.parse(obj) : null;
            }
        } catch(error) {
            console.log(error);
            markHighlightLoadingError();
        }
        return null;
    }

    async getWerInfoWithMetric(werMetrics: any) {
        let docErrorCount = 0;
        let docWordCount = 0;
        let nonDocErrorCount = 0;
        let nonDocWordCount = 0;
        if (werMetrics && werMetrics["category_wer"]) {
            for (let wer of werMetrics["category_wer"]) {
                if (this.isDocument(wer["category"])) {
                    docErrorCount += Math.round(
                        (wer["wordCount"] * wer["error"]) / 100
                    );
                    docWordCount += wer["wordCount"];
                } else if (this.isNonDocument(wer["category"])) {
                    nonDocErrorCount += Math.round(
                        (wer["wordCount"] * wer["error"]) / 100
                    );
                    nonDocWordCount += wer["wordCount"];
                } else {
                    if (!CAL_SEGMENTS.includes(wer["category"])) {
                        alert(`${wer["category"]} is not calculated.`);
                    }
                }
            }
        }
        return new WerInfo(
            docErrorCount,
            docWordCount,
            nonDocErrorCount,
            nonDocWordCount
        );
    }

    async getScriptWerInfoParallel(langs: string[]) {
        let totalWerInfo = new WerInfo(0, 0, 0, 0);
        await Promise.all(
            langs.map(async (lang) => {
                let fWer = this.getWerFile(lang);
                let metric = await this.getMetricsByPath(fWer);
                let info = await this.getWerInfoWithMetric(metric);
                totalWerInfo.add(info);
            })
        );
        return totalWerInfo;
    }

    async getSingleScriptWerInfoParallel(script: string) {
        let werPerScriptInfo: { [key: string]: {} } = {};
        let v = SCRIPTS[script];
        await Promise.all(
            ["Printed", "Handwritten"].map((key) => {
                return this.getScriptWerInfoParallel(v[key]);
            })
        ).then((result) => {
            werPerScriptInfo["Printed"] = result[0].dictInfo(true);
            werPerScriptInfo["Handwritten"] = result[1].dictInfo(false);
        });
        let totalCount = v["Printed"].length + v["Handwritten"].length;
        if (totalCount > 1) {
            let highlightInfo: { [key: string]: {} } = {};
            let highlightKeys: string[] = [];
            for (const langDes in v["Highlight"]) {
                highlightKeys.push(langDes);
            }
            await Promise.all(
                highlightKeys.flatMap(async (langDes) => {
                    let lang = v["Highlight"][langDes];
                    return {
                        Printed: (
                            await this.getScriptWerInfoParallel([lang])
                        ).dictInfo(true),
                        Handwritten: (
                            await this.getScriptWerInfoParallel([`${lang}_hw`])
                        ).dictInfo(false),
                    };
                })
            ).then((result) => {
                highlightKeys.forEach((key, index) => {
                    highlightInfo[key] = result[index];
                });
            });
            werPerScriptInfo["Highlight"] = highlightInfo;
        }
        return werPerScriptInfo;
    }

    async getWerInfoParallel() {
        let WER: { [key: string]: {} } = {};
        await Promise.all(
            GENERAL_SCRIPTS.map((script) => {
                return this.getSingleScriptWerInfoParallel(script);
            })
        ).then((result) => {
            GENERAL_SCRIPTS.forEach((script, index) => {
                WER[script] = result[index];
            });
        });
        return WER;
    }
}
