import React from "react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { OcrTableMetrics } from "../../Views/OcrTable/OctTableMetrics";
import { LatencyMetrics } from "../../Views/Latency/LatencyMetrics";

interface IState {}

interface IProps {
    selectedKey: string;
    records: Record[];
    isDarkTheme?: boolean;
}

export class OcrTablePage extends React.Component<IProps, IState> {
    public render() {
        const { isDarkTheme, records, selectedKey } = this.props;
        return (
            <>
                {selectedKey === "basicTableMetrics" && (
                    <OcrTableMetrics
                        records={records}
                        isDarkTheme={isDarkTheme}
                    />
                )}

                {selectedKey === "ocrTableLatencyMetrics" && (
                    <LatencyMetrics
                        saveSetKey={`${Workspaces.OcrTable}_${Typename.LatencyMetrics}`}
                        records={records}
                        isDarkTheme={isDarkTheme}
                        defaultCrossLanguage="Overall"
                    ></LatencyMetrics>
                )}
            </>
        );
    }
}
