import React from "react";
import {
    DatasetSet,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import _ from "lodash";
import { OverviewTable } from "../../Controls/OverviewTable";
import { ColumnValueType, TableColumn } from "../../Controls/TableList";
import { Consumer } from "../../Layout";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { METRICS_LVL_OPTIONS } from "../../Pages/Scenarios/OcrMathPage";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { NoDataTip } from "../../Controls/NoDataTip";
import { FullScreen } from "../Common/FullScreen";

interface MathMetrics {
    header: string[];
    rows: any[];
}

interface MathFieldMetrics {
    [key: string]: string | number;
}

interface IKvpDataItem {
    data?: IDataItem;
    fieldMetrics: MathFieldMetrics;
}

interface IDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: MathMetrics;
}

interface IState extends ICommonState<MathMetrics> {
    dataItems: IDataItem[];
    mathOverviewColumns: TableColumn[];
}

interface IProps extends ICommonProps {
    storageVersion?: string;
}

export class OcrMathOverview extends CommonView<IProps, IState, MathMetrics> {
    private exportColumns: TableColumn[] = [];
    constructor(props: IProps) {
        super(props);

        this.state = {
            dataItems: [],
            mathOverviewColumns: [],
            level: "lv2",
            matchDatasetVersion: true,
        };
    }

    public render() {
        return (
            <FullScreen>
                <div style={{ height: "100%", overflow: "hidden auto" }}>
                    {this._renderAsTable()}
                </div>
            </FullScreen>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                saveKey: `${Workspaces.OcrMath}_${Typename.GeneralMetrics}
                _Overview`,
                levels: METRICS_LVL_OPTIONS,
                selectedLevel: "lv2",
            })
        );
    }
    exportAction = () => {
        exportOverviewListData(
            this.exportData,
            this.exportColumns,
            "MathOcrOverview"
        );
    };

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        store.dispatch(
            updateStateAction({
                levels: undefined,
            })
        );
    }

    private _columnsUpdate(columnNames: string[]) {
        const columns = columnNames.map((col) => {
            let valueType = ColumnValueType.Number;
            let distinctStr = false;
            if (col === "category") {
                valueType = ColumnValueType.String;
                distinctStr = true;
            }
            return {
                key: col,
                name: col,
                minWidth: 100,
                maxWidth: 200,
                fieldName: col,
                isResizable: true,
                valueType: valueType,
                distinctStr: distinctStr,
                maxDecimalPlaces: 3,
            };
        });

        return columns as TableColumn[];
    }

    componentDidUpdate(prevProps: ICommonProps, prevState: IState) {
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            this.state.level !== prevState.level
        ) {
            this.queryMetricsResult();
        }
        if (!_.isEqual(this.state.dataItems, prevState.dataItems)) {
            const columns = this._columnsUpdate(
                Array.from(
                    new Set(
                        this.state.dataItems
                            .map((item) => {
                                const header = item?.metrics.header as string[];
                                return header ? header : [];
                            })
                            .flatMap((col) => col)
                    )
                )
            );
            this.setState({
                mathOverviewColumns: columns,
            });
            store.dispatch(
                updateStateAction({
                    columns: columns,
                    selectedItems: columns.map((column) => column.name),
                })
            );
        }
    }

    queryMetricsResult() {
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;
        this._queryMetricsResult(`basic_metrics${lvl_str}.json`);
    }

    private _renderAsTable() {
        const { dataItems, selectedColumns, mathOverviewColumns } = this.state;
        this.exportColumns = [];

        this.exportData = [];
        if (dataItems.length > 0) {
            const data = this._prepareRenderTableData();

            return data.length !== 0 ? (
                data.map(([datasetName, recordMetrics, columns], index) => {
                    const tableData = recordMetrics.map(
                        ([_, fieldMetricsItems]) => {
                            let category = "";
                            const fieldMetricsArr = fieldMetricsItems.map(
                                (fieldMetricsItem) => {
                                    const fieldMetrics =
                                        fieldMetricsItem.fieldMetrics;
                                    if (
                                        fieldMetrics &&
                                        fieldMetrics.category &&
                                        category === ""
                                    ) {
                                        category =
                                            fieldMetrics.category as string;
                                    }
                                    return fieldMetrics;
                                }
                            );

                            fieldMetricsArr.forEach((fieldMetric) => {
                                if (!fieldMetric.category) {
                                    fieldMetric.category = category;
                                }
                            });

                            return fieldMetricsArr;
                        }
                    );

                    if (tableData && tableData.length > 0) {
                        if (this.exportColumns.length === 0) {
                            this.exportColumns = columns;
                        }

                        this.exportData.push([datasetName, tableData]);
                    }

                    const showColumns: TableColumn[] =
                        mathOverviewColumns.filter(
                            (value) =>
                                selectedColumns?.findIndex(
                                    (col) => col === value.name
                                ) !== -1
                        );
                    return (
                        <>
                            <Consumer key={`consumerKey_${index}`}>
                                {(value) => {
                                    return (
                                        <div>
                                            <OverviewTable<MathFieldMetrics>
                                                key={`Math_${index}`}
                                                evalData={tableData}
                                                columns={showColumns}
                                                tableTitle={datasetName}
                                                downloadTableTitle={datasetName}
                                                cellWidth={100}
                                                displayNaN
                                                isDarkTheme={value}
                                            />
                                        </div>
                                    );
                                }}
                            </Consumer>
                        </>
                    );
                })
            ) : (
                <NoDataTip>No OCR Math Metrics Generated </NoDataTip>
            );
        } else {
            return <NoDataTip>No OCR Math Metrics Generated</NoDataTip>;
        }
    }

    private _prepareRenderTableData() {
        const { matchDatasetVersion } = this.state;
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    const dataset = matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName;
                    return (
                        item.recordIndex === recordIndex &&
                        dataset === datasetName
                    );
                });
                return item;
            });

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const rows = item?.metrics.rows;
                        return rows ? rows.map((row) => row[0]) : [];
                    })
                )
            );

            const groupData = entities.map((field, index) => {
                // retrieve field data
                const metricsItems = items
                    .map((item) => {
                        let fieldMetrics: MathFieldMetrics = {};
                        const rows = item?.metrics.rows;
                        const header = item?.metrics.header as string[];
                        if (rows && rows.length > 0) {
                            for (const key of header) {
                                const keyIndex = header.indexOf(key);
                                if (rows[index]) {
                                    fieldMetrics[key] = this._formaterNumber(
                                        rows[index][keyIndex]
                                    );
                                } else {
                                    fieldMetrics[key] = "";
                                }
                            }
                        }

                        return {
                            data: item,
                            fieldMetrics: fieldMetrics,
                        } as IKvpDataItem;
                    })
                    .filter((metricsItem) => !_.isEmpty(metricsItem));
                return [field, metricsItems] as [string, IKvpDataItem[]];
            });

            return [datasetName, groupData, this.state.mathOverviewColumns] as [
                string,
                [string, IKvpDataItem[]][],
                TableColumn[]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _formaterNumber(number: any) {
        if (Number(number) === -1) {
            return NaN;
        }
        return number;
    }
}
