import { vdiRequest } from "../Utils/fetch";
import { Dataset, DatasetSet } from "./Dataset";
import { EvalInfoKeys, PAGE_SIZE } from "./GlobalDataEntity";
import { RecordDetail } from "./Record";
import {
    ExpInfo,
    IRecordResponse,
    IRecordDetailResponse,
    RecordImpl,
    RecordDetailImpl,
} from "./RecordImpl";
import _ from "lodash";

interface IDataset {
    name: string;
    version: string;
}

export interface IOcrRecordDetail extends IRecordDetailResponse {
    algorithm: string;
    arguments: {
        trainingDocCount: number;
        trainingDocIndex: number;
    };
    dataset: IDataset;
    expInfo: ExpInfo;
    language: string;
    lastUpdateTimestamp: number;
    storageRoot: string;
    storageVersion: string;
}

export class OcrEvaluationDataset extends Dataset {
    private _name: string;
    private _version: string;
    private _language: string;
    private _categories: string[];

    constructor(
        name: string,
        version: string,
        language: string,
        categories: string[]
    ) {
        super();

        this._name = name;
        this._version = version;
        this._language = language;
        this._categories = categories;
    }

    get name(): string {
        return this._name;
    }

    get fullName(): string {
        return `${this._name}:${this._version}`;
    }

    get version(): string {
        return this._version;
    }

    get displayName(): string {
        return `${this._language}:${this._name}`;
    }

    get displayFullName(): string {
        return `${this._language}:${this._name}:${this._version}`;
    }

    get categories(): string[] {
        return this._categories;
    }

    get language(): string {
        return this._language;
    }

    fetchImageListByCategory(category: string): Promise<string[]> {
        let treeUrl = `/api/datasets/${this._name}/versions/${this._version}/tree/DevTesting/Images`;
        if (!_.isEmpty(category)) {
            treeUrl = `${treeUrl}/${category}`;
        }
        return vdiRequest(treeUrl, {
            method: "GET",
            headers: {
                "Cache-Control": "public, max-age=3600",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        // expect to return empty list rather than throw exception when not found
                        return undefined;
                    } else {
                        throw Error(response.statusText);
                    }
                }
                return response.json();
            })
            .then((tree: { files: { name: string }[] }) => {
                return tree ? tree.files.map((file) => file.name) : [];
            });
    }

    fetchImageList(): Promise<string[]> {
        const itemUrl = `/api/datasets/${this._name}/versions/${this._version}/items?itemCount=-1`;
        return vdiRequest(itemUrl, {
            method: "GET",
            headers: {
                "Cache-Control": "public, max-age=3600",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        // expect to return empty list rather than throw exception when not found
                        return undefined;
                    } else {
                        throw Error(response.statusText);
                    }
                }
                return response.json();
            })
            .then((fileList: { [key: string]: { filepath: string } }) => {
                return fileList
                    ? Object.values(fileList)
                          .filter((file) => !file.filepath.includes(".xml"))
                          .map((file) => {
                              const fileParts = file.filepath.split("/");
                              return fileParts[fileParts.length - 1];
                          })
                    : [];
            });
    }

    getImageUrl(imageName: string): string {
        return `/api/datasets/${this._name}/versions/${this._version}/tree/DevTesting/Images/${imageName}`;
    }

    static async fetchCategories(
        name: string,
        version: string
    ): Promise<string[]> {
        const url = `/api/datasets/${name}/versions/${version}/tree/DevTesting/Images`;
        return vdiRequest(url, {
            method: "GET",
            headers: {
                "Cache-Control": "public, max-age=3600",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        return [];
                    }
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then((tree: { folders: { name: string }[] }) =>
                tree.folders.map((folder) => folder.name)
            );
    }
}

class OcrRecordDetail extends RecordDetailImpl<OcrRecord, IOcrRecordDetail> {
    private _dataset: Dataset;

    constructor(detail: IOcrRecordDetail, record: OcrRecord, dataset: Dataset) {
        super(detail, record);

        this._dataset = dataset;
    }

    get name(): string {
        return this._record.name;
    }

    get dataset(): Dataset {
        return this._dataset;
    }

    async fetchFileList(name: string): Promise<string[]> {
        const url = `/api/eval/trees/${this._detail.storageRoot}/${name}`;
        const response = await vdiRequest(url, {
            method: "GET",
            headers: {
                "Cache-Control": "public, max-age=3600",
            },
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return (await response.json()) as string[];
    }

    composeMetricUrl(name: string): string {
        return `/api/eval/blobs/${this._detail.storageRoot}/${name}`;
    }
}

export class OcrRecord extends RecordImpl<IOcrRecordDetail> {
    private readonly _toInitializeCategory: boolean = true;
    private _initDetails: boolean = false;

    private _langs: string[] = [];
    private _algos: string[] = [];
    private _expLinks: string[] = [];

    private _details: OcrRecordDetail[] = [];
    private _datasetsMap: { [key: string]: OcrEvaluationDataset } = {};
    private _favorite: boolean = false;

    constructor(
        data: IRecordResponse<IOcrRecordDetail>,
        toInitializeCategory: boolean = true
    ) {
        super(data);

        this._langs = Array.from(
            new Set(this._data.details?.map((value) => value.language))
        );

        this._algos = Array.from(
            new Set(this._data.details?.map((value) => value.algorithm))
        );
        this._expLinks = Array.from(
            new Set(
                this._data.details?.map((value) =>
                    value.expInfo ? value.expInfo.url : ""
                )
            )
        );
        this._favorite = false;
        this._toInitializeCategory = toInitializeCategory;
    }

    getDatasets(): Dataset[] {
        this._assertInit();

        return Array.from(
            new DatasetSet(this._details?.map((detail) => detail.dataset))
        );
    }

    get name(): string {
        const meaningfullTag =
            this.tags?.find(
                (tag) =>
                    tag.toLowerCase().startsWith("official") ||
                    tag.toLowerCase().startsWith("baseline") ||
                    tag.toLowerCase().startsWith("release")
            ) ?? this.tags.join(" | ");
        return !!meaningfullTag
            ? meaningfullTag
            : `$${this.modelInfo}@${this.runtimeVersion}`;
    }

    get langs(): string[] {
        return this._langs;
    }

    get algos(): string[] {
        return this._algos;
    }

    get expLink(): string[] {
        return this._expLinks;
    }

    get favorite(): boolean {
        return this._favorite;
    }
    set favorite(fav: boolean) {
        this._favorite = fav;
    }

    getDetails(): RecordDetail[] {
        this._assertInit();

        if (this._detailsFilter !== undefined) {
            return this._details.filter(this._detailsFilter);
        } else {
            return this._details;
        }
    }

    async initDetails(): Promise<boolean> {
        if (!this._initDetails) {
            // prefetch dataset categories
            let datasets: {
                [key: string]: { dataset: IDataset; language: string };
            } = {};
            this._data.details?.forEach((value) => {
                const key = `${value.dataset.name}:${value.dataset.version}`;
                datasets[key] = {
                    dataset: value.dataset,
                    language: value.language,
                };
            });

            const categoriesList = await Promise.all(
                Object.entries(datasets).map(([key, { language, dataset }]) => {
                    if (
                        this._toInitializeCategory &&
                        dataset.name.startsWith("e2e_testset")
                    ) {
                        return OcrEvaluationDataset.fetchCategories(
                            dataset.name,
                            dataset.version
                        )
                            .then((categories) => {
                                return {
                                    key: key,
                                    dataset: dataset,
                                    language: language,
                                    categories: categories,
                                };
                            })
                            .catch((_err) => {
                                return {
                                    key: key,
                                    dataset: dataset,
                                    language: language,
                                    categories: [],
                                };
                            });
                    } else {
                        return Promise.resolve({
                            key: key,
                            dataset: dataset,
                            language: language,
                            categories: [],
                        });
                    }
                })
            );

            categoriesList.forEach(({ key, dataset, categories, language }) => {
                this._datasetsMap[key] = new OcrEvaluationDataset(
                    dataset.name,
                    dataset.version,
                    language,
                    categories
                );
            });

            this._details = this._data.details?.map((detail, i) => {
                const key = `${detail.dataset.name}:${detail.dataset.version}`;
                return new OcrRecordDetail(
                    detail,
                    this,
                    this._datasetsMap[key]
                );
            });
        }

        this._initDetails = true;
        return true;
    }

    private _assertInit() {
        if (!this._initDetails) {
            throw new Error("OcrRecord is not initialized.");
        }
    }

    static async fetchAll(
        workspace: string,
        pageIndex: number = 1,
        searchs: string = "",
        limit: number = PAGE_SIZE,
        favRecordIds: string[] | null = null,
        toInitializeCategory: boolean = true
    ): Promise<OcrRecord[]> {
        let url = `/api/eval/${workspace}/records?${searchs}&pageSize=${limit}&pageIndex=${pageIndex}`;
        if (favRecordIds && favRecordIds.length > 0) {
            url += `&favRecordIds=${favRecordIds.join(",")}`;
        }

        return vdiRequest(url, { method: "GET" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IOcrRecordDetail>[]) => {
                return data.map(
                    (item) => new OcrRecord(item, toInitializeCategory)
                );
            });
    }

    static async fetch(workspace: string, id: string): Promise<OcrRecord> {
        const url = `/api/eval/${workspace}/records/${id}`;
        return vdiRequest(url, { method: "GET" }, false)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data: IRecordResponse<IOcrRecordDetail>) => {
                return new OcrRecord(data);
            });
    }

    asEvalInfoCard(): {
        name: string;
        value: string | string[];
        width: number;
    }[] {
        // prettier-ignore
        return [
            { name: EvalInfoKeys.ModelInfo,       value: this.modelInfo,       width: 150 },
            { name: EvalInfoKeys.RuntimeVersion,  value: this.runtimeVersion,  width: 200 },
            { name: EvalInfoKeys.BuildSource,     value: this.buildSource,     width: 80  },
            { name: EvalInfoKeys.TestType,        value: this.testType,        width: 100 },
            { name: EvalInfoKeys.Algorithm,       value: this.algos,           width: 260 },
            { name: EvalInfoKeys.ExpLink,         value: this.expLink,         width: 70  },
            { name: EvalInfoKeys.Tag,             value: this.tags,            width: 220 },
        ];
    }
}
