import { store } from "../../../store";
import {
    ColumnValueType,
    NumContrastPolicy,
    NumberFormat,
    TableColumn,
} from "../../Controls";
import { Workspaces } from "../../DataContract";

interface Arguments {
    option1: string;
    option2: string;
}

export const Options: { [key: string]: Arguments } = {
    [Workspaces.CustomForm]: {
        option1: "trainingDocCount",
        option2: "trainingDocIndex",
    },
    [Workspaces.QueryField]: {
        option1: "queryCount",
        option2: "randomSeed",
    },
};

export enum ColumsType {
    normal,
    avg,
    final_avg,
}

// prettier-ignore
const QUERYFIELD_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "queryCount",        name: "queryCount",         fieldName: "queryCount",        valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "randomSeed",        name: "randomSeed",         fieldName: "randomSeed",        valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const AVG_QUERYFIELD_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "queryCount",        name: "queryCount",         fieldName: "queryCount",        valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const FINAL_AVG_QUERYFIELD_COLUMNS: TableColumn[] = [
    { key: "queryCount",        name: "queryCount",           fieldName: "queryCount",        valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true },
    { key: "Accuracy",          name: "Average Accuracy (%)", fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "_",                 name: "",                     fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const CUSTOMFORM_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "trainingDocCount",  name: "TrainingDocCount",   fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "trainingDocIndex",  name: "TrainingDocIndex",   fieldName: "trainingDocIndex",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const AVG_CUSTOMFORM_COLUMNS: TableColumn[] = [
    { key: "datasetFullName",   name: "DatasetFullName",    fieldName: "datasetFullName",   valueType: ColumnValueType.String,       minWidth: 165,  maxWidth: 250,  isResizable: true,  distinctStr: true },
    { key: "trainingDocCount",  name: "TrainingDocCount",   fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 200,  isResizable: true },
    { key: "Accuracy",          name: "Accuracy (%)",       fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "TrainingLatency",   name: "TrainingLatency",    fieldName: "trainingLatency",   valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "InferenceLatency",  name: "InferenceLatency",   fieldName: "inferenceLatency",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen,  maxDecimalPlaces:2, },
    { key: "_",                 name: "",                   fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

// prettier-ignore
const FINAL_AVG_CUSTOMFORM_COLUMNS: TableColumn[] = [
    { key: "trainingDocCount",  name: "TrainingDocCount",     fieldName: "trainingDocCount",  valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true },
    { key: "Accuracy",          name: "Average Accuracy (%)", fieldName: "accuracy",          valueType: ColumnValueType.Number,       minWidth: 165,  maxWidth: 250,  isResizable: true,  contrastPolicy: NumContrastPolicy.PositiveGreen_NegativeRed,  maxDecimalPlaces:2,  numberFormat:NumberFormat.Percentage },
    { key: "_",                 name: "",                     fieldName: "_",                 valueType: ColumnValueType.Placeholder,  minWidth: 0,    maxWidth: 200,  isResizable: true},
];

const queryFieldColumns = [
    QUERYFIELD_COLUMNS,
    AVG_QUERYFIELD_COLUMNS,
    FINAL_AVG_QUERYFIELD_COLUMNS,
];
const customFormColumns = [
    CUSTOMFORM_COLUMNS,
    AVG_CUSTOMFORM_COLUMNS,
    FINAL_AVG_CUSTOMFORM_COLUMNS,
];

const columns: { [key: string]: TableColumn[][] } = {
    [Workspaces.QueryField]: queryFieldColumns,
    [Workspaces.CustomForm]: customFormColumns,
};

export const getColumns = (type: ColumsType) => {
    const workppace = store.getState().globalReducer.workSpace;
    return columns[workppace][type];
};
