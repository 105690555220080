import {
    AuthenticatedTemplate,
    WithMsalProps,
    withMsal,
} from "@azure/msal-react";
import { Icon, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { loginRequest } from "../../authConfig";
import { Consumer } from "../Layout";

class SecurityLoginButton extends React.Component<WithMsalProps> {
    public render() {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <PrimaryButton
                    text="Please sign-in to see your profile information."
                    onClick={() => {
                        this.login();
                    }}
                />
            </div>
        );
    }

    login = () => {
        clearMsal();
        const msalInstance = this.props.msalContext.instance;
        msalInstance.loginRedirect({
            ...loginRequest,
            redirectUri: "/redirect",
        });
    };
}

const clearMsal = () => {
    for (var key in localStorage) {
        if (key.startsWith("msal")) localStorage.removeItem(key);
    }
};

export const MsalSecurityLoginButton = withMsal(SecurityLoginButton);

interface IState {}

class SecurityLogoutButton extends React.Component<WithMsalProps, IState> {
    appendLoginAliasToCookie = (alias: string) => {
        const aliasKey = "loginAlias";
        if (!document.cookie.includes(aliasKey)) {
            document.cookie =
                `${aliasKey}=${alias}; path=/; ` + document.cookie;
        }
    };
    public render() {
        return (
            <AuthenticatedTemplate>
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Consumer>
                        {(value) => {
                            return (
                                <Stack
                                    horizontal
                                    tokens={{ childrenGap: 10 }}
                                    onClick={() => {
                                        this.logout();
                                    }}
                                >
                                    <Icon
                                        iconName="SignOut"
                                        style={{ color: "white" }}
                                    />
                                    <label
                                        style={{
                                            color: "white",
                                            paddingRight: "20px",
                                        }}
                                    >
                                        {this.props.msalContext.accounts
                                            .length > 0 &&
                                            this.props.msalContext.accounts[0]
                                                .name}
                                    </label>
                                </Stack>
                            );
                        }}
                    </Consumer>
                </div>
            </AuthenticatedTemplate>
        );
    }

    logout = () => {
        const msalInstance = this.props.msalContext.instance;
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            account: msalInstance.getActiveAccount(),
        });
        clearMsal();
    };
}

export const MsalSecurityLogoutButton = withMsal(SecurityLogoutButton);
