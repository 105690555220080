import { Record } from "./Record";
import { OcrRecord } from "./OcrRecord";
import { ReleaseRecord } from "./ReleaseRecord";
import { VerticalTlmRecord } from "./VerticalTlmRecord";
import { PAGE_SIZE, Workspaces } from ".";

export class RecordFactory {
    static async fetch(workspace: string, id: string): Promise<Record> {
        switch (workspace) {
            case Workspaces.Ocr:
            case Workspaces.OcrBackend:
            case Workspaces.OcrTable:
            case Workspaces.OcrCheckbox:
            case Workspaces.OcrPod:
            case Workspaces.Invoice:
            case Workspaces.Kvp:
            case Workspaces.CustomForm:
            case Workspaces.QueryField:
            case Workspaces.Receipt:
            case Workspaces.BusinessCard:
            case Workspaces.IdCard:
            case Workspaces.IndustryPrebuilt:
            case Workspaces.HealthDoc:
            case Workspaces.ExperimentalPrebuilt:
            case Workspaces.OcrMath:
            case Workspaces.OcrBarcode:
            case Workspaces.DocClassifier:
            case Workspaces.OcrFigure:
                return OcrRecord.fetch(workspace, id).then(async (record) => {
                    // TODO:
                    // The current UI logic holds an assumption that each
                    // record contains record details from the same algorithm.
                    //
                    // However, "algorithm" is an OCR centric property and
                    // the latest data schema doesn't have such constraint.
                    //
                    // To workaround this issue, the generated "Record"
                    // instance is setting a details filter to filter record
                    // details from the first algorithm value.
                    record.setDetailsFilter((recordDetail) => {
                        return (
                            recordDetail.getRawProp("algorithm") ===
                            record.algos[0]
                        );
                    });

                    await record.initDetails();
                    return record;
                });
            case Workspaces.ReleaseTest:
                return ReleaseRecord.fetch(workspace, id).then(
                    async (record) => {
                        await record.initDetails();
                        return record;
                    }
                );
            case Workspaces.CustomDocReleaseTest:
                return ReleaseRecord.fetch(workspace, id).then(
                    async (record) => {
                        await record.initDetails();
                        return record;
                    }
                );
            case Workspaces.VerticalTelemetry:
                return VerticalTlmRecord.fetch(workspace, id);
            default:
                throw new Error(`Unrecognized workspace: ${workspace}`);
        }
    }

    static async fetchAll(
        workspace: string,
        pageIndex: number = 1,
        searchs: string = "",
        limit: number = PAGE_SIZE,
        favRecordIds: string[] | null = null,
        toInitializeCategory: boolean = true
    ): Promise<Record[]> {
        switch (workspace) {
            case Workspaces.Ocr:
            case Workspaces.OcrBackend:
            case Workspaces.OcrTable:
            case Workspaces.OcrCheckbox:
            case Workspaces.OcrPod:
            case Workspaces.Invoice:
            case Workspaces.Kvp:
            case Workspaces.Receipt:
            case Workspaces.BusinessCard:
            case Workspaces.IdCard:
            case Workspaces.IndustryPrebuilt:
            case Workspaces.HealthDoc:
            case Workspaces.ExperimentalPrebuilt:
            case Workspaces.CustomForm:
            case Workspaces.QueryField:
            case Workspaces.OcrMath:
            case Workspaces.OcrBarcode:
            case Workspaces.DocClassifier:
            case Workspaces.OcrFigure:
                return OcrRecord.fetchAll(
                    workspace,
                    pageIndex,
                    searchs,
                    limit,
                    favRecordIds,
                    toInitializeCategory
                );
            case Workspaces.ReleaseTest:
            case Workspaces.CustomDocReleaseTest:
                return ReleaseRecord.fetchAll(
                    workspace,
                    pageIndex,
                    searchs,
                    limit,
                    favRecordIds
                );
            case Workspaces.VerticalTelemetry:
                return VerticalTlmRecord.fetchAll(
                    workspace,
                    pageIndex,
                    searchs,
                    limit,
                    favRecordIds
                );
            default:
                throw new Error(`Unrecognized workspace: ${workspace}`);
        }
    }
}
